import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import Sider from "antd/es/layout/Sider";
import { ConfigProvider, Menu, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { isEmpty } from "lodash";
import { globalStore } from "../../stores/stores";
import { getDetailAdminInterface } from "../../service/admin-interface";
import { getContrastingColor } from "../../utils/functionUltils";
import { MenuProps } from "antd/lib";

interface SidebarProps {
  selectedMenu: any[];
  collapsedContent: boolean;
  isHideSider: boolean;
}

const SiderLayout: React.FC<SidebarProps> = ({
  selectedMenu,
  collapsedContent,
  isHideSider,
}) => {
  const navigate = useNavigate();
  const { reloadAdminInterface, personalInfo } = globalStore();

  const location = useLocation();
  const pathName = location.pathname;
  const defaultOpenKeys = location.pathname.split("/").filter((x) => x !== "");
  const defaultSelectedKeys = [
    `/` +
    `${defaultOpenKeys[0]}/` +
    `${!isEmpty(defaultOpenKeys[1]) ? defaultOpenKeys[1] : ""}`,
  ];
  const listOpenKeys = [`${defaultOpenKeys[0]}`, `${defaultOpenKeys[1]}`];

  const [loading, setLoading] = useState<boolean>(false);
  const [color, setColor] = useState<any>({});
  const [backgroundColor, setBackgroundColor] = useState<string>("");

  const getColor = async () => {
    if (personalInfo?.color) {
      setColor(personalInfo?.color);
    }
  };

  useEffect(() => {
    getColor();
  }, [reloadAdminInterface, personalInfo]);

  const getColorDetail = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id: any = urlParams.get("interfaceId");
    if (id) {
      await getDetailAdminInterface(id).then((res) => {
        if (res.status === 200) {
          setBackgroundColor(res.data?.data?.color?.menuColor);
        }
      });
    }
  };

  useEffect(() => {
    getColorDetail();
  }, [navigate, reloadAdminInterface]);
  const contrastingTextColor = getContrastingColor(color?.menuColor);

  const renderMenuItems = (menuItems: any[]): MenuProps["items"] => {
    return menuItems.map((item: any) => {
      const tooltipLabel =
        typeof item.label === "string"
          ? item.label
          : item.label?.props?.children || "Menu Item";

      return {
        ...item,
        label: (
          <Tooltip title={tooltipLabel} placement="right">
            <span>{tooltipLabel}</span>
          </Tooltip>
        ),
        key: item.key,
        children: item.children ? renderMenuItems(item.children) : undefined,
      };
    });
  };
  return (
    <>
      {!isHideSider ? (
        <ConfigProvider
          theme={{
            components: {
              Menu: { colorText: contrastingTextColor },
            },
          }}
        >
          <Sider
            breakpoint="lg"
            width="100%"
            style={{
              height: "100%",
              boxShadow: "unset",
              background: `${pathName.includes(routesConfig.detailAdminInterface)
                ? backgroundColor
                : !isEmpty(color) && !loading
                  ? color?.menuColor
                  : "#9F224E"
                }`,
            }}
            className="slider-bar-theme"
            collapsible={!isHideSider}
            collapsed={collapsedContent}
            trigger={null}
          >
            <Menu
              mode="inline"
              selectedKeys={[pathName]}
              defaultSelectedKeys={defaultSelectedKeys}
              defaultOpenKeys={listOpenKeys}
              items={renderMenuItems(selectedMenu)}
              className="menu-wrapper"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                paddingBottom: "48px",
                height: "100%",
                background: `${pathName.includes(routesConfig.detailAdminInterface)
                  ? backgroundColor
                  : !isEmpty(color) && !loading
                    ? color?.menuColor
                    : "#9F224E"
                  }`,
              }}
              onClick={(e) => {
                const baseUrl = "https://";
                if (e.key.startsWith(baseUrl)) {
                  window.location.href = e.key;
                } else {
                  navigate(e.key);

                }
              }}
            />
          </Sider>
        </ConfigProvider>
      ) : null}
    </>
  );
};

export default SiderLayout;
