import { Button, Flex, Form, message, Select, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { template1 } from "../../setting-content-course/certificate-page-builder/template-sample/template1";
import { createCertification } from "../../../../../service/content-course/contenCourseApi";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import './index.scss'
import { get } from "lodash";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import { getListCertificate } from "../../../../../service/certificate";

type TabCertificateProps = {
  courseId: number
  dataCertificateCourse?: any;
  isDirty: boolean,
  setDirty: (value: boolean) => void
  courseDetail: any
}

type CertificateOption = {
  label: string;
  value: number;
  code: number;
  template: string;
}

const TabCertificate = ({ courseId, dataCertificateCourse, isDirty, setDirty, courseDetail }: TabCertificateProps) => {
  const [form] = Form.useForm();
  const [htmlContent, setHtmlContent] = useState<any>();
  const contentRef = useRef<HTMLDivElement>(null);
  const originalSurname = "Name Surname";
  const [dataCertificateOption, setDataCertificateOption] = useState<CertificateOption[]>([])
  const [certificateChoose, setCertificateChoose] = useState<any>();

  const getCertificateList = async () => {
    const dataReq = { page: 1, size: 10000, status: 1 }
    try {
      const response = await getListCertificate(dataReq)
      const { data } = response.data

      if (response.status === 200) {
        setDataCertificateOption(data.templates.map((template: { id: number, name: string, template: string }) => ({
          label: template.name,
          code: template.id,
          template: template.template
        })))
      }

    } catch (e) {
      console.error(e);
    }
  }


  const createCertificateSample = async (dataReq: { courseId: number; template: any; }) => {
    try {
      const response = await createCertification(dataReq)
      const { status } = response
      if (status === 200) {
        message.success("Cài đặt thành công")
      }
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    getCertificateList()
  }, []);

  useEffect(() => {
    const handleInput = (e: any) => {
      const surnameDiv = contentRef.current?.querySelector('.surname');
      if (surnameDiv && surnameDiv.contains(e.target as Node)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const contentEditableDiv = contentRef.current;
    if (contentEditableDiv) {
      contentEditableDiv.addEventListener('input', handleInput);
    }

    return () => {
      if (contentEditableDiv) {
        contentEditableDiv.removeEventListener('input', handleInput);
      }
    };
  }, []);


  const onFinish = (values: any) => {
    setDirty(false)
    const dataReq = {
      courseId: courseId,
      template: htmlContent,
      certificateBankId: Number(certificateChoose?.code)
    }
    createCertificateSample(dataReq)
  };
  const handleDivChange = (e: any) => {
    const contentDiv = e.target;
    const surnameDiv = contentDiv.querySelector('.surname');
    setDirty(true)
    if (surnameDiv) {

      surnameDiv.innerHTML = originalSurname;
    }

    setHtmlContent(contentDiv.innerHTML);
  };

  const handleSelect = (value: number) => {
    const selectedTemplate = dataCertificateOption.find(it => it.code === value)
    setCertificateChoose(selectedTemplate)
    setHtmlContent(selectedTemplate && JSON.parse(selectedTemplate.template).html);
  }

  const onReset = () => {
    form.resetFields();
    if (Object.keys(dataCertificateCourse).length > 0 && Object.values(dataCertificateCourse).some(value => value !== null)) {
      setHtmlContent(get(dataCertificateCourse, 'template', ''))
    } else {
      setHtmlContent(template1)
    }
  }

  useEffect(() => {
    if (dataCertificateCourse?.template || certificateChoose?.code) {
      setHtmlContent(replaceContent(htmlContent || dataCertificateCourse?.template));
    }
    else {
      setHtmlContent(template1)
    }
  }, [htmlContent, dataCertificateCourse]);

  const replacements = [
    { componentClass: 'course-name-certificate', newText: courseDetail?.name }
  ];
  const replaceContent = (content: string) => {
    let updatedContent = content;

    replacements.forEach((replacement) => {
      const regex = new RegExp(`(<[^>]*component-class="${replacement.componentClass}"[^>]*>)(.*?)(</[^>]+>)`, 'g');
      updatedContent = updatedContent.replace(regex, (match, startTag, existingText, endTag) => {
        return `${startTag}${replacement.newText}${endTag}`;
      });
    });

    return updatedContent;
  };

  return (
    <div>
      <Form
        form={form}
        name="html_css_form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ htmlContent, certificateTemplate: dataCertificateCourse?.certificateBankId }}
      >
        <Flex align="center" gap={"middle"}>

          <div>
            <span>Chọn chứng chỉ mẫu</span>
          </div>
          <div> <Form.Item
            name="certificateTemplate"
            style={{ marginBottom: 0 }}
          >
            <FormItemDropdown
              placeholder="Chọn mẫu"
              options={dataCertificateOption}
              onChange={handleSelect} />
          </Form.Item></div>
        </Flex>


        <Form.Item
          name="htmlContent"
          hidden
        >
          <input type="hidden" value={htmlContent} />
        </Form.Item>
        <div
          ref={contentRef}
          contentEditable={true}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          onBlur={handleDivChange}
          className='flex justify-center py-4'
        />
        <div className="save-certification--sample">
          <div className="group-button">
            <Button
              className="ant-btn-primary"
              htmlType="submit"
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
            <Button className="cancel-btn" onClick={onReset}>
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TabCertificate;
