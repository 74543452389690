import {
  Checkbox,
  Col,
  Form,
  Input,
  Row
} from "antd";
import { FormInstance } from "antd/lib";
import { toInteger } from "lodash";
import React, {
  FunctionComponent,
  Key,
  useEffect,
  useState
} from "react";
import FormInput from "../../../../components/form-input/FormInput";
import FormRadio from "../../../../components/form-radio/FormRadio";
import {
  getPermissionsRoles
} from "../../../../service/role-management/roleManagementApi";
import { dataStatus } from "../../constants/data";

type RecordType = {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
};

const validate = {
  nameRole: {
    required: true,
    message: "Vui lòng nhập tên vai trò",
  },
  description: {
    required: true,
    message: "Vui lòng nhập mô tả.",
  },
  status: {
    required: true,
    message: "Vui lòng chọn trạng thái.",
  },
};

type InfoRoleFormProps = {
  form?: FormInstance;
  checkEdit?: boolean;
  checkIsFormEdit?: boolean;
  isOpened?: boolean;
  dataEdit?: any;
  resetPermissions?: any;
  checkedList: string[];
  setCheckedList: React.Dispatch<React.SetStateAction<string[]>>;
  defaultRole: any;
};

const InfoRoleGeneralForm: FunctionComponent<InfoRoleFormProps> = ({
  checkEdit,
  checkIsFormEdit,
  isOpened,
  dataEdit,
  form,
  checkedList,
  setCheckedList,
  defaultRole
}) => {
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const [dataTransfer, setDataTransfer] = useState<RecordType[]>([]);
  const [permissionsRole, setPermissionsRole] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(100);
  const [statusExists, setStatusExists] = useState(false);
  const [valueSearchLeft, setValueSearchLeft] = useState("");
  const [allPermissions, setAllPermissions] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(true);

  const fetchData = (dataSearch?: any) => {
    getPermissionsRoles(dataSearch).then((res) => {
      let { data } = res.data;
      data = mergeArraysDistinctByKey(data, allPermissions);
      setAllPermissions([...data]);
    });
  };

  useEffect(() => {
    // Map the selectedValue to the corresponding default state
    if (defaultRole !== undefined && defaultRole !== null)
      setCheckedList(defaultRole);
  }, [defaultRole]);

  useEffect(() => {
    if (checkIsFormEdit && dataEdit) {
      setStatusExists(true);
    }
  }, [dataEdit, statusExists]);

  useEffect(() => {
    if (!isOpened) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    if (!id) {
      setSelectedPermissions([]);
      setTargetKeys([]);
      setIsCreating(true);
      return;
    }
    setIsCreating(false);
    getPermissionsRoles({ id: toInteger(id), isExist: true }).then((res) => {
      let { data } = res.data;
      setSelectedPermissions(data);
    });
    fetchData({ pageSize: pageSize, keyword: "" });
  }, [isOpened]);

  useEffect(() => {
    fetchData({ pageSize: pageSize, keyword: valueSearchLeft });
  }, [pageSize, valueSearchLeft]);

  function mergeArraysDistinctByKey(arr1: any[], arr2: any[]) {
    const combined = [...arr1, ...arr2];
    const keySet = new Set();

    return combined.filter((item) => {
      if (keySet.has(item["id"])) {
        return false;
      }
      keySet.add(item["id"]);
      return true;
    });
  }

  const getNewData = () => {
    const newData: RecordType[] = mergeArraysDistinctByKey(
      allPermissions,
      selectedPermissions
    ).map((role: any) => {
      return {
        key: role.id.toString(),
        title: role.name,
        description: role.name,
        chosen: statusExists,
      };
    });
    setDataTransfer(newData);
    if (!isCreating) {
      const targetKey = selectedPermissions.map((permission: any) =>
        String(permission.id)
      );
      setTargetKeys([...targetKey]);
    }
  };

  useEffect(() => {
    getNewData();
  }, [permissionsRole, statusExists, selectedPermissions, allPermissions]);

  const handleCheckboxGroupChange = (values: string[]) => {
    setCheckedList(values);
    form?.setFieldValue("macDinh", values);
  };

  return (
    <>
      <Row gutter={16}>
        <Col className="col-form-class" span={12}>
          <Form.Item
            name="ten"
            rules={[validate.nameRole]}
            layout="vertical"
            label="Tên vai trò"
          >
            <FormInput placeholder="Nhập tên vai trò" disabled={checkEdit} />
          </Form.Item>
        </Col>
        <Col className="col-form-class" span={12}>
          <Form.Item
            label="Trạng thái"
            name="trangThai"
            rules={[validate.status]}
            layout="vertical"
          >
            <FormRadio data={dataStatus} value={true} disabled={checkEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="col-form-class-2" span={24}>
          <Form.Item
            layout="vertical"
            label="Mô tả"
            name="moTa"
            rules={[validate.description]}
          >
            <Input.TextArea
              placeholder="Nhập mô tả vai trò"
              disabled={checkEdit}
              showCount
              maxLength={100}
              autoSize={{ minRows: 2, maxRows: 3 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="col-form-class" span={24}>
          <Form.Item
            label={
              <p className="label-row">
                {"Vai trò"}&nbsp;
                <p className="label-special">
                  {" (Vai trò được gán mặc định khi thêm mới tài khoản)"}
                </p>
              </p>
            }
            name="defaultRole"
            layout="vertical"
          >
            <Checkbox.Group
              disabled={checkEdit}
              value={checkedList}
              onChange={handleCheckboxGroupChange}
            >
              <Row>
                <Col span={24}>
                  <Checkbox value="1">Quản trị cơ sở</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="2">Giảng viên</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="3">Sinh viên</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-4">
        <Form.Item
          label="Vai trò mặc định"
          name="defaultRoleMain"
          valuePropName="checked"
        >
          <Checkbox
            disabled={checkEdit}
            onChange={(e) => {
              form?.setFieldValue("defaultRoleMain", e.target.checked);
            }}
          />
        </Form.Item>

      </Row>
    </>
  );
};

export default InfoRoleGeneralForm;
