import { Button, Col, Collapse, Drawer, Flex, Form, Radio, Row, Space, Spin, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { get, isEmpty, size } from 'lodash'
import { getDetailListQuestion } from '../../../../../service/library/question-list'
import { QuestionTypeEnum } from '../../../../../constants/course-contruction'
import ChooseText from '../../../../course-content/learning-course/components/quiz-screen/quiz/choose-text/ChooseText'
import ChooseImage from '../../../../course-content/learning-course/components/quiz-screen/quiz/choose-image'
import ChooseVideo from '../../../../course-content/learning-course/components/quiz-screen/quiz/choose-video'
import TrueOrFalse from '../../../../course-content/learning-course/components/quiz-screen/quiz/true-or-false'
import DropDown from '../../../../course-content/learning-course/components/quiz-screen/quiz/drop-down'
import ChooseMultiText from '../../../../course-content/learning-course/components/quiz-screen/quiz/choose-multi-text'
import ChooseMultiImage from '../../../../course-content/learning-course/components/quiz-screen/quiz/choose-multi-image'
import ChooseMultiVideo from '../../../../course-content/learning-course/components/quiz-screen/quiz/choose-multi-video'
import Connect from '../../../../../pages/preview-quiz/structure/quiz/connect'
import ShortText from '../../../../../pages/preview-quiz/structure/quiz/short-text'
import LongText from '../../../../../pages/preview-quiz/structure/quiz/long-text'
import FileUpload from '../../../../../pages/preview-quiz/structure/quiz/file-upload'
import Video from '../../../../../pages/preview-quiz/structure/quiz/video'
import Mp3 from '../../../../../pages/preview-quiz/structure/quiz/mp3'
import FillText from '../../../../../pages/preview-quiz/structure/quiz/fill-text/FillText'
import { formatOriginTimev3 } from '../../../../../utils/converDate'
import DOMPurify from "dompurify";
import { getQuizById } from '../../../../../service/course-construct'
import { getDetailQuestionBankMinistry } from '../../../../../service/question-bank'

type Props = {
  open: boolean
  onClose: () => void
  idDetailData: number | undefined
  idUnitDetailData: number | undefined
  dataOptions: any
  type?: number
}

export default function DetailQuestion(props: Props) {
  const { open, onClose, idDetailData, dataOptions, idUnitDetailData, type } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataDetail, setDataDetail] = useState<any>({})
  const [dataQuestion, setDataQuestion] = useState<any>()
  const [detailDataQuiz, setDetailDataQuiz] = useState<any>({})

  // const data = {}

  const getDetail = async () => {
    await getDetailListQuestion(idDetailData || 0).then((res) => {
      if (res.status === 200) {
        setDataDetail(res.data?.data)
        const data = {
          ...res.data?.data,
          questions: res.data?.data?.answer !== undefined ? res.data?.data?.answer : "",
        };
        setDataQuestion(data)
      }
    })
  }

  const getDetailApprove = async () => {
    await getDetailQuestionBankMinistry(idDetailData || 0).then((res) => {
      if (res.status === 200) {
        setDataDetail(res.data?.data)
        const data = {
          ...res.data?.data,
          questions: res.data?.data?.answer !== undefined ? res.data?.data?.answer : "",
        };
        setDataQuestion(data)
      }
    })
  }

  const getDataQuiz = async (id: any) => {

    try {
      const response = await getQuizById(id.toString())
      const { data, status } = response
      if (status === 200) {
        setDetailDataQuiz(data.body)
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
    }

  }

  useEffect(() => {
    if (open) {
      if(type===1){
        getDetailApprove()
      } else {
        getDetail();
      }
      getDataQuiz(idUnitDetailData);
    }
  }, [open]);

  const handleChangeRadio = () => {
  };

  const arrLevel: any = dataOptions?.dataDifficulty?.filter((item: any) => item.id === dataDetail?.level)
  const arrType: any = dataOptions?.dataTopic?.filter((item: any) => item.id === dataDetail?.type)
  const arrStatus: any = dataOptions?.dataStatus?.filter((item: any) => item.id === dataDetail?.publishStatus)
  const arrTypeProduction: any = dataOptions?.dataTypeProduction?.filter((item: any) => item.id === dataDetail?.typeProduction)
  const { question, id } = detailDataQuiz

  return (
    <Drawer
      title={"Xem chi tiết"}
      open={open}
      onClose={onClose}
      className='detail-question-bank-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
        </Space>
      }
    >
      <Form
        className="detail-question-bank"
        layout={"vertical"}
        form={form}
        name="form-detail-question-bank"
      >
        <div>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              header="Nội dung câu hỏi"
              key="1"
              className="setting-chapter-title">
              <div className="preview-quiz--wrapper">
                {loading ? <Spin /> :
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }} />
                }

                <div className='preview-quiz--wrapper'>
                  {
                    get(detailDataQuiz, 'answers', []).length > 0 ? detailDataQuiz.answers.map((answer: any) => {
                      return (
                        <section key={answer.uuid}>
                          <Radio.Group
                            onChange={handleChangeRadio}
                            className="w-full"
                            disabled={true}
                          >
                            <section className='answer'>
                              <Radio className="mt-2" value={answer.isTrue}>
                                {(isEmpty(answer.filePath) ?
                                  <div dangerouslySetInnerHTML={{
                                    __html: isEmpty(answer.content)
                                      ? 'Chưa có câu trả lời'
                                      : typeof answer.content === 'object'
                                        ? `${JSON.stringify(answer.content.left.content)}-${JSON.stringify(answer.content.left.content)}`
                                        : answer.content
                                  }}></div>
                                  :
                                  <Flex align={'center'} gap="middle">
                                    {!answer.fileName.includes('mp4') ?
                                      <div className="answer-quiz--image">
                                        <img src={answer.filePath} alt="image-answer" style={{ width: '100%' }} />
                                      </div>
                                      : (<video src={answer.filePath} width="320" height="240" controls>
                                        <source src={answer.filePath} type="video/mp4" />
                                        <source src={answer.filePath} type="video/ogg" />
                                      </video>)
                                    }
                                    <span>{isEmpty(answer.content) ? "Chưa có câu trả lời" :
                                      typeof answer.content === 'object' &&
                                        answer.content.hasOwnProperty('left') &&
                                        answer.content.hasOwnProperty('right')
                                        ? `${JSON.stringify(answer.content.left.content)}-${JSON.stringify(answer.content.left.content)}`
                                        : answer.content}</span>
                                  </Flex>
                                )}
                              </Radio>
                            </section>
                          </Radio.Group>
                        </section>
                      )
                    }) : (
                      <>
                        <div>
                          Không có dữ liệu câu hỏi
                        </div>
                      </>
                    )
                  }
                </div>

              </div>
            </Panel>

            <Panel
              header="Thông tin"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <Row gutter={24}>
                  <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Mã câu hỏi</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.code}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Độ khó</Typography.Title> <p className='mt-0.5 ml-2'>{arrLevel?.[0]?.name}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Loại câu hỏi</Typography.Title> <p className='mt-0.5 ml-2'>{arrType?.[0]?.name}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Lượt dùng</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.numberOfUse}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Phân cấp</Typography.Title> <p className='mt-0.5 ml-2'>{arrTypeProduction?.[0]?.name}</p></span>
                  </Col>
                  <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nhãn</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.tags}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Trạng thái</Typography.Title> <p className='mt-0.5 ml-2'>{arrStatus?.[0]?.name}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Quyền truy cập</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.access}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày tạo</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.createdDate ? formatOriginTimev3(dataDetail?.createdDate) : null}</p></span>
                    <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Người tạo</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.createdBy}</p></span>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </Drawer>
  )
}
