// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-exam {
  overflow: auto;
  max-height: 250px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-library/personal-exam-repository/list-exam/create-exam/index.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBACF,EAAA","sourcesContent":[".list-exam {\n  overflow: auto;\n  max-height: 250px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
