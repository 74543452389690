import React, { useEffect, useRef, useState } from 'react';
import DrawerCustom from "../../../../drawer/Drawer";
import { Button, Col, Form, Radio, RadioChangeEvent, Row, Space, Spin, Tag, Typography } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import './index.scss'
import { IDataDetail } from '../../../../../pages/library/bank-exam/components/personal-exam-repository/list-exam';
import { get, isNull } from "lodash";
import { TweenOneGroup } from "rc-tween-one";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";

type EditPermissionsProps = {
  isOpened: boolean;
  closeForm: () => void;
  onFinish: (value: any) => void;
  dataDetail?: IDataDetail | null;
  dataOptions?: any;
  loading?: boolean;
}


const radioItemList = [
  {
    value: 'Công khai',
    title: "Công khai",
    describe: "Nội dung này cần Cấp bộ kiểm duyệt trước khi công khai",
  },
  {
    value: 'Nội bộ',
    title: "Nội bộ",
    describe: "Nội dung này cần QTCS kiểm duyệt trước khi công khai nội bộ",
  },
  {
    value: 'Giới hạn',
    title: "Giới hạn",
    describe: "Chỉ hiển thị nội dung với đối tượng được chia sẻ",
  },
];


const EditPermissions = ({ isOpened, closeForm, dataDetail, dataOptions, loading }: EditPermissionsProps) => {

  const [form] = Form.useForm()
  const [inputVisible, setInputVisible] = useState(false);
  const [permission, setPermission] = useState('')
  const handleChangePer = Form.useWatch("permissions", form);

  const handleCloseForm = () => {
    form.resetFields();
    closeForm();
  };

  // useEffect
  useEffect(() => {
    if (!isNull(dataDetail)) {
      if (!isNull(dataDetail?.access)) {
        form.setFieldValue('permissions', dataDetail?.access);
        if (dataDetail?.access === "Giới hạn") {
          form.setFieldValue('limit', dataDetail?.emailToAccess);
        }
      } else {
        form.setFieldsValue({
          permissions: 'Công khai',
          limit: [],
        })
      }
    }
  }, [dataDetail]);

  useEffect(() => {
    if (handleChangePer) {
      form.setFieldValue('permissions', handleChangePer);
      setPermission(handleChangePer);
    }
  }, [handleChangePer]);

  return (
    <>
      <DrawerCustom
        title={"Sửa quyền truy cập"}
        open={isOpened}
        onClose={handleCloseForm}
        extraNode={
          <Space>
            <Button
              onClick={closeForm}
              className="btn btn-outlined"
            >
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={() => form?.submit()}
            >
              <SaveOutlined />
              <Typography.Text>Gửi yêu cầu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        {loading ?
          <div className="edit-permission-loading flex">
            <Spin size="large" />
          </div>
          :
          <Form
            layout={"vertical"}
            form={form}
            name="editPermissions"
            initialValues={{ permissions: "Công khai" }}
          >
            <Row gutter={16}>
              <Col className="mb-24" span={24}>
                <div className="form-item--info_publish">
                  <Typography.Title level={5}>
                    Quyền truy cập
                  </Typography.Title>
                  <Form.Item
                    name="permissions"
                  >
                    <Radio.Group className="options-notification--type">
                      {radioItemList.map((item, index) => (
                        <div key={item.value} className="wrapper-radio">
                          <Radio value={item.value}>
                            <Typography.Text
                              className="setting-chapter-title"
                            >
                              {item.title}
                            </Typography.Text>
                          </Radio>
                          <div className="message">
                            <Typography.Text className="sub-title">
                              {item.describe}
                            </Typography.Text>
                          </div>
                        </div>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  {permission === 'Giới hạn' ? (
                    <div className="form-input" style={{ width: '100%' }}>
                      {!inputVisible && (
                        <Form.Item name="limit" style={{ width: '100%' }}>
                          <FormItemDropdown modeDropdown='multiple' placeholder="Chọn email"
                            options={get(dataOptions, 'dataLimit', []).map((item: any) => ({
                              code: item.name,
                              name: item.name
                            }))}
                          />
                        </Form.Item>
                      )}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Form>
        }
      </DrawerCustom>
    </>
  );
};

export default EditPermissions;