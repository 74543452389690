export enum Permission {
  //Group
  CanAddGroup = "add_group",
  CanChangeGroup = "change_group",
  CanDeleteGroup = "delete_group",
  CanViewGroup = "view_group",

  //User
  CanAddUser = "add_user",
  CanChangeUser = "change_user",
  CanDeleteUser = "delete_user",
  CanViewUser = "view_user",

  //Role
  CanAddRole = "add_role",
  CanChangeRole = "change_role",
  CanDeleteRole = "delete_role",
  CanViewRole = "view_role",

  /* ====== QUẢN LÝ TÀI KHOẢN ====== */
  //quản lí trường tham gia nền tảng
  SearchRequest = "search_request.view",
  ViewRequestDetails = "viewrequest_details.view",
  ProcessRequestUpdate = "process_request.update",

  //quản lí tài khoản cơ sở
  SearchFacilityAdministrationAccount = "search_fa_account.view",
  ViewFacilityAdministrationAccount = "view_fa_account.view",
  LockUnlockFAAccountUpdate = "lockunlock_fa_account.update",
  ChangeFAAccountUpdate = "change_fa_account.update",
  DeleteFAAccountUpdate = "delete_fa_account.update",
  CreateFAAccountUpdate = "create_fa_account.update",
  ExportFAA = "export_fa_account.view",

  //quản lí tài khoản giảng viên
  SearchLecturerAccount = "search_lecturer_account.view",
  ViewLecturerAccount = "view_lecturer_account.view",
  LockUnlockLecturerAccountUpdate = "lockunlock_lecturer_account.update",
  ChangeLecturerAccountUpdate = "change_lecturer_account.update",
  DeleteLecturerAccountUpdate = "delete_lecturer_account.update",
  CreateLecturerAccountUpdate = "create_lecturer_account.update",
  ExportLecturerAccount = "export_lecturer_account.view",

  //quản lí tài khoản người dùng cấp bộ
  SearchMUAccount = "search_mu_account.view",
  ViewMUAccount = "view_mu_account.view",
  LockUnlockMUAccountUpdate = "lockunlock_mu_account.update",
  ChangeMUAccountUpdate = "change_mu_account.update",
  DeleteMUAccountUpdate = "delete_mu_account.update",
  CreateMUAccountUpdate = "create_mu_account.update",
  ExportMUAccount = "export_mu_account.view",

  //quản lí tài khoản sinh viên
  SearchStudentAccount = "search_student_account.view",
  ViewStudentAccount = "view_student_account.view",
  LockUnlockStudentAccountUpdate = "lockunlock_student_account.update",
  ChangeStudentAccountUpdate = "change_student_account.update",
  DeleteStudentAccountUpdate = "delete_student_account.update",
  ApproveStudentAccountVerificationUpdate = "approve_student_account_verification.update",
  ReapproveStudentAccountVerificationUpdate = "reapprove_student_account_verification.update",
  ExportStudentAccountView = "export_student_account.view",
  CreateStudentAccountUpdate = "create_student_account.update",

  //Khảo sát
  CanParticipateSurvey = "participate_survey",

  //Khảo sát nhu cầu đào tạo
  CanViewSurvey = "view_survey", //quản lí đợt khảo sát, tổng hợp kết quả khảo sát
  CanAddSurvey = "add_survey",
  CanEditSurvey = "edit_survey",
  CanDeleteSurvey = "delete_survey",
  CanDuplicateSurvey = "duplicate_survey",
  CanSendApprovalSurvey = "send_approval_survey",
  CanPublishSurvey = "publish_survey",
  CanViewHistorySurvey = "view_history_survey",
  CanSettingSurvey = "setting_survey",
  CanViewApprovalSurvey = "view_approval_survey", //duyệt khảo sát
  CanApprovalSurvey = "approval_survey",
  CanViewApprovalHistorySurvey = "view_approval_history_survey",

  //Course-plan kế hoạch khóa học
  CanSearchCoursePlan = "can_search_course_plan",
  CanEditCoursePlan = "can_edit_course_plan",
  CanDeleteCoursePlan = "can_delete_course_plan",

  CanViewRegisterCoursePlan = "view_register_course_plan", //đăng ký kế hoạch
  CanViewApprovalRegisterCoursePlan = "view_approval_register_course_plan", //duyệt đăng ký kế hoạch
  CanViewCoursePlanAssignment = "view_course_plan_assignment", //phân công lập kế hoạch
  CanViewCoursePlanning = "view_course_planning", // lập kế hoạch khóa học
  CanViewApprovalCoursePlanning = "view_approval_course_planning", //duyệt lập kế hoạch
  CanViewSpecializedGroup = "can_view_specialized_group", //lập tổ chuyên môn
  CanViewAppraisalBoard = "can_view_appraisal_board", //lập hội đồng thẩm định
  CanViewApprovalCoursePlanningSending = "view_aprroval_course_planning_sending", //Gửi phê duyệt kế hoạch
  CanViewSubmitPlanAppraisal = "view_submit_plan_appraisal", //Gửi thẩm định kế hoạch
  CanApprovalCoursePlanning = "aprroval_course_planning", //Phê duyệt kế hoạch
  CanViewPlanAppraisal = "view_plan_appraisal", //Thẩm định kế hoạch

  //accessMenu
  //quản lý người dùng
  CanAccessUser = "mooc_access_user",

  //quản lý vai trò
  CanAccessRole = "mooc_access_role",

  //khảo sát
  CanAccessParticipateSurvey = "mooc_access_participate_survey",

  //khảo sát nhu cầu đào tạo
  CanAccessManageSurvey = "mooc_access_manage_survey",
  CanAccessApprovalSurvey = "mooc_access_approval_survey",
  CanAccessResultSurvey = "mooc_access_result_survey",

  //kế hoạch khóa học
  CanAccessRegisterCoursePlan = "mooc_access_register_course_plan", //đăng ký kế hoạch
  CanAccessApprovalRegisterCoursePlan = "mooc_access_approval_register_course_plan", //duyệt đăng ký kế hoạch
  CanAccessCoursePlanAssignment = "mooc_access_course_plan_assignment", //phân công lập kế hoạch
  CanAccessCoursePlanning = "mooc_access_course_planning", // lập kế hoạch khóa học
  CanAccessPlanningApproval = "mooc_access_planning_approval", //duyệt lập kế hoạch
  CanAccessSpecializedGroup = "mooc_access_specialized_group", //lập tổ chuyên môn
  CanAccessAppraisalBoard = "mooc_access_appraisal_board", //lập hội đồng thẩm định
  CanAccessApprovalCoursePlanningSending = "mooc_access_aprroval_course_planning_sending", //Gửi phê duyệt kế hoạch
  CanAccessSubmitPlanAppraisal = "mooc_access_submit_plan_appraisal", //Gửi thẩm định kế hoạch
  CanAccessApprovalCoursePlan = "mooc_access_aprroval_course_plan", //Phê duyệt kế hoạch
  CanAccessPlanAppraisal = "mooc_access_plan_appraisal", //Thẩm định kế hoạch

  //
  AddPermission = "add_permission", // danh cho cac menu khac vai tro giang vien
  ViewPermission = "view_permission", // menu danh cho giang vien

  //phân công xây dựng khóa học
  AssignCourseConstructionView = "assign_course_construction.view",
  AssignCourseConstructionUpdate = "assign_course_construction.update",

  // Xây dựng nội dung
  CanContentConstructionCourse = "mooc_content_construction_course",
  ManageCourseStructureView = "manage_course_structure.view",

  ManageCourseStructureUpdate = "manage_course_structure.update",
  SetWebCourseActivityView = "set_web_course_activity.view",
  SetWebCourseActivityUpdate = "set_web_course_activity.update",
  SetCourseTagsView = "set_course_tags.view",
  SetCourseTagsUpdate = "set_course_tags.update",
  EditCourseTagsView = "edit_course_tags.view",
  EditCourseTagsUpdate = "edit_course_tags.update",
  PreviewCoursePresentationInterfaceView = "preview_course_presentation_interface.view",
  PreviewCoursePresentationInterfaceUpdate = "preview_course_presentation_interface.update",
  SelectPresentationScreenView = "select_presentation_screen.view",
  SelectPresentationScreenUpdate = "select_presentation_screen.update",
  SaveCoursePresentationInterfaceView = "save_course_presentation_interface.view",
  SaveCoursePresentationInterfaceUpdate = "save_course_presentation_interface.update",
  CreateDraftPresentationInterfaceView = "create_draft_presentation_interface.view",
  CreateDraftPresentationInterfaceUpdate = "create_draft_presentation_interface.update",
  OperatePresentationPageView = "operate_presentation_page.view",
  OperatePresentationPageUpdate = "operate_presentation_page.update",

  //Xây dựng nội dung khóa học
  CreateLectureView = "create_lecture.view",
  CreateLectureUpdate = "create_lecture.update",
  CreateChapterView = "create_chapter.view",
  CreateChapterUpdate = "create_chapter.update",
  CreateLearningMaterialView = "create_learning_material.view",
  CreateLearningMaterialUpdate = "create_learning_material.update",
  CreateAssignmentTestExamView = "create_assignment_test_exam.view",
  CreateAssignmentTestExamUpdate = "create_assignment_test_exam.update",
  CreateAssignmentTestExamQuestionView = "create_assignment_test_exam_question.view",
  CreateAssignmentTestExamQuestionUpdate = "create_assignment_test_exam_question.update",

  //kiểm định nội dung khóa học
  ReviewCoursePresentationPageView = "review_course_presentation_page.view",
  ReviewCoursePresentationPageUpdate = "review_course_presentation_page.update",
  ApproveCourseContentView = "approve_course_content.view",
  ApproveCourseContentUpdate = "approve_course_content.update",
  AssessCourseContentView = "assess_course_content.view",
  AssessCourseContentUpdate = "assess_course_content.update",
  PublishCourseView = "publish_course.view",
  PublishCourseUpdate = "publish_course.update",

  // Kế hoạch khảo sát
  CourseSurveyPlanView = "course_survey_plan.view",
  CourseSurveyPlanUpdate = "course_survey_plan.update",
  CreateCourseSurveyPlanView = "create_course_survey_plan.view",
  CreateCourseSurveyPlanUpdate = "create_course_survey_plan.update",
  CreateCourseSurveyQuestionView = "create_course_survey_question.view",
  CreateCourseSurveyQuestionUpdate = "create_course_survey_question.update",

  //Cài đặt định danh
  MenuIdentitySettingUpdate = "menu_identity_setting.update",
  TabIdentityCaseSettingUpdate = "tab_identity_case_setting.update",

  //Cài đặt điểm danh
  TabIdentityDocumentSettingUpdate = "tab_identity_document_setting.update",
  MenuAttendanceSettingUpdate = "menu_attendance_setting.update",
  TabAttendanceReminderSettingUpdate = "tab_attendance_reminder_setting.update",
  ButtonViewAttendanceUpdate = "button_view_attendance.update",
  ToggleSwitchAttendanceModeUpdate = "toggle_switch_attendance_mode.update",
  MenuPermissionByCourseUpdate = "menu_permission_by_course.update",
  TabAttendancePermissionSettingUpdate = "tab_attendance_permission_setting.update",
  MenuCourseListUpdate = "menu_course_list.update",
  ButtonPerformAttendanceUpdate = "button_perform_attendance.update",
  TabAttendanceReportUpdate = "tab_attendance_report.update",
  ButtonExamMonitoringSettingUpdate = "button_exam_monitoring_setting.update",
  TabExamMonitoringCharacteristicsSettingUpdate = "tab_exam_monitoring_characteristics_setting.update",
  TabExamViolationNotificationSettingUpdate = "tab_exam_violation_notification_setting.update",

  //Kiểm soát chất lượng tiết học video
  MenuLessonQualityControlUpdate = "menu_lesson_quality_control.update",
  TabQualityAssessmentCriteriaSettingUpdate = "tab_quality_assessment_criteria_setting.update",
  TabViewCourseVideoAssessmentUpdate = "tab_view_course_video_assessment.update",
  CourseQualityControlUpdate = "course_quality_control.update",
  LectureStructureSettingUpdate = "lecture_structure_setting.update",
  CourseQualityAssessmentCriteriaSettingUpdate = "course_quality_assessment_criteria_setting.update",
  AutomatedExamGradingUpdate = "automated_exam_grading.update",
  AutomatedGradingPermissionsUpdate = "automated_grading_permissions.update",
  TabPerformAutomatedGradingUpdate = "tab_perform_automated_grading.update",
  GradeManagementUpdate = "grade_management.update",
  ButtonEditAttendanceReportUpdate = "button_edit_attendance_report.update",
  ButtonDeleteAttendanceReportUpdate = "button_delete_attendance_report.update",
  ButtonExportAttendanceReportUpdate = "button_export_attendance_report.update",
  ButtonShareAttendanceReportUpdate = "button_share_attendance_report.update",
  ButtonEvaluateAttendanceReportUpdate = "button_evaluate_attendance_report.update",
  ButtonViewAttendanceReportUpdate = "button_view_attendance_report.update",

  //Quản lý nhóm nội dung
  MenuContentGroupManagementUpdate = "menu_content_group_management.update",
  TabStudentInputInformationUpdate = "tab_student_input_information.update",
  TabStudentBehaviorInformationUpdate = "tab_student_behavior_information.update",
  MenuAnalysisSettingUpdate = "menu_analysis_setting.update",
  TabTrendAnalysisModelFactorsSettingUpdate = "tab_trend_analysis_model_factors_setting.update",
  TabTrainingResultUpdate = "tab_training_result.update",
  LearningResourcesSystemUpdate = "learning_resources_system.update",
  SummaryReportUpdate = "summary_report.update",
  ListOfStudentsWithSimilarInterestsUpdate = "list_of_students_with_similar_interests.update",

  //cổng thông tin
  PortalManagementAddNewPortalUpdate = "portal_management.add_new_portal.update",
  PortalManagementIntroductionPageView = "portal_management.introduction_page.view",
  PortalManagementIntroductionPageUpdate = "portal_management.introduction_page.update",
  PortalManagementCourseListPageView = "portal_management.course_list_page.view",
  PortalManagementCourseListPageUpdate = "portal_management.course_list_page.update",
  PortalManagementProgramListPageView = "portal_management.program_list_page.view",
  PortalManagementProgramListPageUpdate = "portal_management.program_list_page.update",

  //quản trị nội dung blog và diễn đàn
  BlogForumManagementBlogContentView = "blog_forum_management.blog_content.view",
  BlogForumManagementBlogContentUpdate = "blog_forum_management.blog_content.update",
  BlogForumManagementSetupInterfaceView = "blog_forum_management.setup_interface.view",
  BlogForumManagementSetupInterfaceUpdate = "blog_forum_management.setup_interface.update",
  BlogForumManagementForumTopicsView = "blog_forum_management.forum_topics.view",
  BlogForumManagementForumTopicsUpdate = "blog_forum_management.forum_topics.update",

  //quản lý thông báo
  NotificationManagementView = "notification_management.view",
  NotificationManagementUpdate = "notification_management.update",

  //quản lý chấm điểm
  GradingManagementView = "grading_management.view",
  GradingManagementUpdate = "grading_management.update",

  //Nhật kí người dùng
  MenuActivityLogUpdate = "menu_activity_log.update",

  //quản lí vai trò
  AddRoleUpdate = "add_role.update",
  EditRoleUpdate = "edit_role.update",
  DeleteRoleUpdate = "delete_role.update",
  AssignPermissionsUpdate = "assign_permissions.update",
  AssignAccountUpdate = "assign_account.update",

  //báo cáo đăng ký tài khoản
  StudentAccountOverviewView = "student_account_overview.view",

  //thiết lập giao diện và quản trị
  InterfaceSetupAdminView = "interface_setup_admin.view",
  InterfaceSetupAdminUpdate = "interface_setup_admin.update",

  //thiết lập chứng chỉ/chứng nhận
  CertificateSetupView = "certificate_setup.view",
  CertificateSetupUpdate = "certificate_setup.update",
  DataSharingHistoryView = "data_sharing_history.view",
  DataSharingHistoryUpdate = "data_sharing_history.update",

  //quản lí kết nối hệ thống
  DataSharingSetupView = "data_sharing_setup.view",

  DataSharingSetupUpdate = "data_sharing_setup.update",

  //quản lí danh mục
  CommonCategoryManagementView = "common_category_management.view",
  CommonCategoryManagementUpdate = "common_category_management.update",
  CategoryValueManagementView = "category_value_management.view",
  CategoryValueManagementUpdate = "category_value_management.update",
  UniversityManagementView = "university_management.view",
  UniversityManagementUpdate = "university_management.update",
  LecturerManagementView = "lecturer_management.view",
  LecturerManagementUpdate = "lecturer_management.update",
  StudentManagementView = "student_management.view",
  StudentManagementUpdate = "student_management.update",
  MinistryUserManagementView = "ministry_user_management.view",
  MinistryUserManagementUpdate = "ministry_user_management.update",
  DepartmentMajorManagementView = "department_major_management.view",
  DepartmentMajorManagementUpdate = "department_major_management.update",

  //báo cáo thống kê view
  LecturerStatisticsReportView = "lecturer_statistics_report.view",
  MinistryStatisticsReportView = "ministry_statistics_report.view",
  SchoolStatisticsReportView = "school_statistics_report.view",

  //cấu hình tài khoản
  AccountSettingsView = "account_settings.view",
  AccountSettingsUpdate = "account_settings.update",

  // nhật ký hệ thống
  SystemLogView = "system_log.view",
  SystemLogUpdate = "system_log.update",

  // xác nhận ghi danh
  TransactionManagementView = "payment_transaction.view",
  TransactionManagementUpdate = "payment_transaction.update",
  isQTCS = "is_qtcs",
  isQTHT = "is_qtht",
  isMinistry = "is_cap_bo",
  isLecturer = "is_giang_vien",

  // Thư viện
  // Kiểm duyệt nội dung cấp Trường
  UnitBankUniversityApprovedListView = "unit_bank_university_approved_list.view",
  UnitBankUniversityApprovedListUpdate = "unit_bank_university_approved_list.update",
  QuizBankUniversityApprovedListView = "quiz_bank_university_approved_list.view",
  QuizBankUniversityApprovedListUpdate = "quiz_bank_university_approved_list.update",

  // Kiểm duyệt nội dung cấp Bộ
  UnitBankMinistryApprovedListView = "unit_bank_ministry_approved_list.view",
  UnitBankMinistryApprovedListUpdate = "unit_bank_ministry_approved_list.update",
  QuizBankMinistryApprovedListView = "quiz_bank_ministry_approved_list.view",
  QuizBankMinistryApprovedListUpdate = "quiz_bank_ministry_approved_list.update",

  // Kho học liệu
  // Danh sách học liệu
  ViewForUnitLibraryMinistry = "unit_library_ministry.view",
  ViewForUnitLibraryUniversity = "unit_library_university.view",
  UpdateForUnitLibraryMinistry = "unit_library_ministry.update",
  UpdateForUnitLibraryUniversity = "unit_library_university.update",

  // kiểm duyệt nội dung cấp bộ
  ViewListApproveForUnitLibraryMinistry = "unit_library_ministry.view_list_approve",
  ApproveForUnitLibraryMinistry = "unit_library_ministry.approve",

  // kiểm duyệt nội dung cáp trường
  ViewListApproveForUnitLibraryUniversity = "unit_library_university.view_list_approve",
  ApproveForUnitLibraryUniversity = "unit_library_university.approve",

  //Hệ thống gợi ý khóa học
  LearningResourcesSystemView = "learning_resources_system.view",
  SummaryReportView = "summary_report.view",
  ListOfStudentsWithSimilarInterestsView = "list_of_students_with_similar_interests.view",

  //Quản lý trợ lý ảo
  Chatbot = "Chatbot.update",
}
