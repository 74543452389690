import { ReactNode } from "react";

export interface CoursePayload {
  keyword?: string;
  industryGroup?: number;
  universities?: string[];
  industries?: number[];
  stars?: number[];
  isFreeOptions?: number[];
  minCost?: number;
  maxCost?: number;
  courseScheduleType?: number[];
  courseType?: number[];
  sortByName?: string;
  sortByCreatedAt?: string;
  page?: number;
  size?: number;
  minStar?: number;
}

export interface Sections {
  totalTime?: number;
  videoNumber?: number;
  children?: Array<any>;
  id: number;
  name: string;
  completedPercentage: number;
  totalUnit: number;
}

export interface SubmitExamHistory {
  date: string;
  sessionId: string;
}

export interface ExamConfigs {
  blockId?: number;
  generalConfig: {
    id: number;
    courseBlockId: number;
    randomQuiz: boolean;
    sequentiallyQuiz: boolean;
    showQuizScore: boolean;
    showHintQuiz: boolean;
    showFeedback: number | string;
    showResultOfEvaluation: boolean;
    resultOfEvaluationOption: number | string;
    showFeedbackContent: boolean;
    feedbackContentOption: number | string;
    showCorrectAnswer: boolean;
    correctAnswerOption: number | string;
    formatQuiz: number | string;
  };
  actionConfig: {
    id: number;
    courseBlockId: number;
    numberActionEvaluate: number;
    timeBetweenExecutions: number;
    completionTime: number;
    executionTimeExpired: boolean;
    executionTimeExpiredOption: number | string;
    executionTimeExpiredValue: number | string;
    timeForQuiz: number | string;
  };
  evaluateConfig: {
    id: number;
    courseBlockId: number;
    automaticScoring: boolean;
    typeScore: number;
    minimumScore: number;
    scoreFactor: number;
    recordResults: number | string;
    showResultsCompletion: boolean;
    showResultsCompletionOption: any;
    showResultsCompletionOptions: any;
  };
}

export interface QuizGroupProps {
  blockId: number;
  description: string;
  groupId: number;
  isBegin: boolean;
  isEnd: boolean;
  orderNumber: number;
  quizDtos: any;
  quizzes: [];
  title: string;
}

export interface SectionInfo {
  completedPercentage: number;
  id: number;
  name: string;
  orderNumber: number;
  scheduleEndDate: string;
  scheduleStartDate: string;
  totalTime: number;
  videoNumber: number;
  children: SectionChildren[];
  totalDiscussion?: number;
}

export interface SectionChildren {
  completedPercentage: number;
  courseTeacherUserId: number | null;
  courseTeacherUserName: string | null;
  id: number;
  name: string;
  orderNumber: number;
  sectionId: number;
  totalTime: number;
  videoNumber: number;
  children: SectionYoungChildren[];
  totalDiscussion?: number;
}

export interface SectionYoungChildren {
  availableStatus: number;
  id: number;
  isAccessible: boolean;
  isCompleted: boolean;
  name: string;
  orderNumber: number;
  sequenceId: number;
  timeCompletedCondition: number;
  totalDiscussion: number;
  block: BlockInfo[];
}

export interface BlockInfo {
  description: string | null;
  iconType: number;
  id: number;
  module: string;
  orderNumber: number | null;
  title: string;
}

export interface CourseInfo {
  id?: number;
  name: string;
  cost: number;
  avgStar: null;
  slug: string;
  universityName: string;
  totalStudents: number;
  completedTime: number;
  teachers: TeacherInfo[] | string[];
  reviewDescription: {
    description?: string;
    universityName?: string;
    completedTime?: number;
    id?: number;
    name?: string;
    slug?: string;
  };
  isSaved: boolean;
  isRegistered: boolean;
  completedPercentage?: number;
  thumbnailUrl?: string;
}

export interface TeacherInfo {
  id: number;
  email: number;
  username: number;
  firstName: number;
  lastName: number;
}

export interface IndustryInfo {
  id: string | number;
  code?: string;
  name?: string;
  count?: number;
}

export interface UniversityInfo {
  uuid?: string;
  name?: string;
  count?: number;
  slug?: string;
}

export interface GroupIndustry {
  code: string;
  name: string;
  id: number;
  children: IndustryInfo[];
}
interface Assigners {
  uuid: string;
  name: string;
  slug: string;
  isSponsor: true;
}

interface Industries {
  id: number;
  name: string;
  code: number;
  industryGroupCode: number;
  status: boolean;
}
export interface CourseDetail {
  id?: number;
  name?: string;
  code?: string;
  description?: string;
  image?: string;
  template?: string;
  title?: string;
  courseUrl?: string;
  createdDate?: string;
  publicDate?: string;
  industries?: Industries[];
  cost?: number;
  assigners?: Assigners[];
  sections?: Array<any>;
  totalComment?: number;
  totalStudents?: number;
  isRegistered?: boolean;
  thumbnailUrl?: string;
  universityName?: string;
  slug?: string;
  time?: number;
  teachers?: Array<any>;
  avgStar?: number;
  isCommented?: boolean;
  completedPercentage?: number;
  isSaved?: boolean;
}

export interface Raiting {
  rate: number;
  count: number;
}

export interface TrainingLevel {
  name: string;
  id: string | number;
}

export interface MoocCourseProps {
  id?: number | string;
  name?: string;
  type?: number;
  time?: number;
  totalVideo?: number;
  progress?: number;
  children?: MoocCourseProps[];
}

export interface MoocLessonProps extends MoocCourseProps {
  id?: number | string;
  name?: string;
  type?: number;
  time?: number;
  icon?: ReactNode;
}

export interface BeQuizAnswer {
  content: string;
  orderNumber: number;
  point: number;
  response: string;
  isDisplayContent: boolean;
  filePath: string;
  fileName: string;
  name?: string;
  isTrue: boolean;
  isCorrect: boolean;
  criteria: boolean;
}

export interface QuestionProps {
  data?: QuizProps;
  timeTrain?: number;
  isCorrect?: boolean;
  isIncorrect?: boolean;
  answer?: number | string | any;
  initialData?: any;
  disabled?: boolean;

  onChange?: (v?: number | string) => void;
  configs?: ExamConfigs;
  pause?: boolean;
  onFinish?: (v?: any) => void;
}

export interface QuestionPropsV2 {
  data?: QuizPropsV2;
  timeTrain?: number;
  isCorrect?: boolean;
  isIncorrect?: boolean;
  answer?: number | string | any;
  initialData?: any;
  disabled?: boolean;

  onChange?: (v?: number | string) => void;
  configs?: ExamConfigs;
  pause?: boolean;
  onFinish?: (v?: any) => void;
}

// export new interface

export interface Answer {
  point: number | null;
  isTrue: boolean;
  content: string;
  fileName: string | null;
  filePath: string | null;
  response: string | null;
  orderNumber: number;
  isDisplayContent: boolean | null;
}

export interface Quiz {
  id: number;
  question: string;
  type: number;
  answers: string; // This is a stringified JSON array of Answer objects
}

export interface PartBlock {
  groupId: number;
  blockId: number;
  title: string;
  description: string | null;
  isBegin: boolean;
  isEnd: boolean;
  orderNumber: number;
  quizzes: Quiz[];
  quizDtos: any; // This is always null in the provided data
}

export interface QuestionBeProps {
  content: any;
  filePath: string | null;
  isTrue: boolean;
  orderNumber: number;
  point: number;
  uuid: any;
}

export interface SettingResponseProps {
  correct: string;
  incorrect: string;
  isDisplayPerResponse: boolean;
  notYet: string;
}

export interface QuizProps {
  answers: string;
  courseBlockGroupId: number;
  index?: number;
  id: number;
  isSubmitQuestions: boolean;
  mustAnswer: any;
  pauseInfo: any;
  question: string;
  quizOrder: number;
  result: any;
  settingPointDto: any;
  type: number;
  questions: QuestionBeProps[];
  settingGeneral: SettingGeneralProps;
  // settingGeneral: string;
  settingHint: SettingHintProps;
  settingResponse: SettingResponseProps;
  showHintOption: number | string;
  showTotalPointOption: number | string;
  showTrueQuestion: boolean;
  showTrueQuestionOption: number | string;
  timeToCompleted: number;
  totalPoints: number;
  isCurrentQuestion: boolean;
  isShowHint: boolean;
  isShowTotalPoint: boolean;
}

export interface QuizPropsV2 {
  answers: string;
  courseBlockGroupId: number;
  index?: number;
  id: number;
  isSubmitQuestions: boolean;
  mustAnswer: any;
  pauseInfo: any;
  question: string;
  quizOrder: number;
  result: any;
  settingPointDto: any;
  type: number;
  questions: QuestionBeProps[];
  settingGeneral: string;
  // settingGeneral: string;
  settingHint: SettingHintProps;
  settingResponse: SettingResponseProps;
  showHintOption: number | string;
  showTotalPointOption: number | string;
  showTrueQuestion: boolean;
  showTrueQuestionOption: number | string;
  timeToCompleted: number;
  totalPoints: number;
  isCurrentQuestion: boolean;
  isShowHint: boolean;
  isShowTotalPoint: boolean;
}

export interface SettingGeneralProps {
  displayScoreCriteria: boolean | null;
  fileCapacity: number;
  fileQuantity: number;
  haveCorrectAnswer: boolean;
  numberingType: number;
  numberingType2: number;
  quizCorrectKeyValues: any;
  requiredAnswer: boolean;
  showForViewer: boolean;
  shuffleQuestion: boolean;
}

export interface SettingHintProps {
  content: string | null;
  isDisplayInstruction: boolean;
  schema: string;
  uri: string;
}

export interface QuizRequest {
  type: number;
  quizId: number;
  questionType: string | number;
  answer: any;
}

export enum JSON_SUB_TYPE {
  ChoosingRequest = "ChoosingRequest",
  ConnectingRequest = "ConnectingRequest",
  ContentRequest = "ContentRequest",
}

export interface UnitInfoProps {
  complete: boolean;
  examType: number | null;
  iconType: number;
  id: number;
  isAbleToRetry: boolean;
  isShowResult: boolean;
  maxTimeCompleted: number | null;
  module: string;
  name: string;
  remainingTimeToRetry: number;
  timeCompletedCondition: number;
  answerSendTime: string;
  totalTimeTrain: number;
  submitType: 0;
  quizConfigDTO: ExamConfigs;
  remainingRetryNumber?: number;
  waitingTimeForNextExecution?: number;
}

export interface GroupsExam {
  [x: string]: any;
  courseUnitId: number;
  description: string;
  id: number;
  orderNumber: number;
  quizzes: QuizProps[];
  quiz: QuizProps[];
  title: string;
}
export interface QuizRequest {
  type: number;
  quizId: number;
  questionType: string | number;
  answer: any;
}
export interface ResultExam {
  createdTime: string;
  endTime: string;
  id: string;
  maxPoints: number;
  sessionId: string;
  startTime: string;
  totalPoints: number;
  totalQuestions: number;
  totalTimeComplete: number;
  trueQuestions: number;
  commentByTeacher?: string;
}

export interface FinishResult {
  answerSendTime: any;
  endTime: string | null;
  sessionId: string | null;
  startTime: string | null;
  time: number | null;
  isAbleToRetry: boolean;
  isShowResult: boolean;
  remainingTimeToRetry: number;
  totalTimeTrain?: number;
  totalTrainTime?: number;
  waitingTimeForNextExecution?: number;
  remainingRetryNumber?: number;
}

export enum ExamType {
  // 1 bai tap
  // 2 kiem tra
  // 3 bai thi
  EXERCISE = 1,
  TEST = 2,
  EXAM = 3,
}
