import {DownloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Dropdown} from "antd";
import {MenuProps} from "antd/lib";
import React, {ChangeEvent, KeyboardEvent, ReactNode, useEffect, useRef, useState} from "react";
import FormItemInput from "../../../../../../components/form-input/FormInput";

type ExportOption = {
  label: string;
  action: () => void;
};

type Props = {
  chartName: string;
  content: ReactNode;
  moreOption?: ReactNode;
  dropdownValues?: any;
  exportOptions?: ExportOption[];
  isChart?: boolean,
  searchValue?: string,
  handleChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch?: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch?: () => void,
};

const StatisticalReportCard = (props: Props) => {
  const {
    chartName,
    content,
    moreOption = false,
    dropdownValues = false,
    exportOptions = [],
    isChart,
    searchValue,
    handleChangeInput,
    handleSearch,
    handleSubmitSearch,

  } = props;
  const chartNameRef = useRef<HTMLDivElement>(null);
  const [isWrapped, setIsWrapped] = useState(false);

  const checkIfWrapped = () => {
    if (chartNameRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(chartNameRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3;
      if (chartNameRef.current.scrollHeight > maxHeight) {
        setIsWrapped(true);
      } else {
        setIsWrapped(false);
      }
    }
  };

  useEffect(() => {
    checkIfWrapped();

    const handleResize = () => {
      checkIfWrapped();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const items: MenuProps["items"] = exportOptions.map((option, index) => ({
    key: index.toString(),
    label: <div onClick={option.action}>{option.label}</div>,
  }));

  return (
    <div className="statistical-report-chart-card question-chart">
      <div className={`card-header ${isWrapped ? "wrapped" : ""}`}>
        <div className="chart-name">{chartName}</div>
        <div className="flex">
          {!isChart 
            ? 
              <div className="heading-item heading-search mr-2">
                <FormItemInput placeholder="Nhập từ khóa để tìm kiếm" value={searchValue}
                  onChange={handleChangeInput} onKeyPress={handleSearch}
                  afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
              </div>
            :
              <></>
          }
          
          <div className="flex">
            {moreOption && moreOption}
            {exportOptions.length === 1 ? (
              <Button
                onClick={exportOptions[0].action}
                className="chart-action"
                icon={<DownloadOutlined/>}
              >
                {exportOptions[0].label}
              </Button>
            ) : exportOptions.length > 1 ? (
              <Dropdown menu={{items}} placement="bottomRight">
                <Button
                  className="chart-action"
                  icon={<DownloadOutlined/>}
                ></Button>
              </Dropdown>
            ) : null}
          </div>
        </div>
      </div>
      <div className="hr-tag-item"></div>
      <div className="chart-content">{content}</div>
    </div>
  );
};

export default StatisticalReportCard;
