// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learning-material-detail {
  padding: 24px; }
  .learning-material-detail .description {
    font-size: 14px; }
  .learning-material-detail .view-detail {
    padding: 24px;
    margin: auto; }
  .learning-material-detail .detail-title li {
    display: flex;
    align-items: center;
    font-size: 20px; }
  .learning-material-detail .detail-title .name {
    font-size: 24px;
    font-weight: 500; }
`, "",{"version":3,"sources":["webpack://./src/pages/learning-materials/components/LearningMaterialDetail/LearningMaterialDetail.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAII,eAAe,EAAA;EAJnB;IAQI,aAAa;IACb,YAAY,EAAA;EAThB;IAeM,aAAa;IACb,mBAAmB;IACnB,eAAe,EAAA;EAjBrB;IAqBM,eAAe;IACf,gBAAgB,EAAA","sourcesContent":[".learning-material-detail {\n  padding: 24px;\n\n  .description {\n    font-size: 14px;\n  }\n\n  .view-detail {\n    padding: 24px;\n    margin: auto;\n  }\n\n  .detail-title {\n\n    li {\n      display: flex;\n      align-items: center;\n      font-size: 20px;\n    }\n\n    .name {\n      font-size: 24px;\n      font-weight: 500;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
