// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-options .option {
  display: flex;
  align-items: start;
  margin-bottom: 16px; }
  .share-options .option .ant-checkbox-wrapper {
    margin-right: 8px; }
  .share-options .option .option-text {
    display: flex;
    flex-direction: column; }
    .share-options .option .option-text .option-title {
      font-weight: bold;
      font-size: 16px; }
    .share-options .option .option-text .option-description {
      font-size: 14px;
      color: #555; }
`, "",{"version":3,"sources":["webpack://./src/pages/learning-materials/components/share-learning-material/ShareLearningMaterial.scss"],"names":[],"mappings":"AAAA;EAEE,aAAa;EACb,kBAAkB;EAClB,mBAAmB,EAAA;EAJrB;IAOG,iBAAiB,EAAA;EAPpB;IAWG,aAAa;IACb,sBAAsB,EAAA;IAZzB;MAeI,iBAAiB;MACjB,eAAe,EAAA;IAhBnB;MAoBI,eAAe;MACf,WAAW,EAAA","sourcesContent":[".share-options {\n\t.option {\n\t\tdisplay: flex;\n\t\talign-items: start;\n\t\tmargin-bottom: 16px;\n\n\t\t.ant-checkbox-wrapper {\n\t\t\tmargin-right: 8px;\n\t\t}\n\n\t\t.option-text {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\n\t\t\t.option-title {\n\t\t\t\tfont-weight: bold;\n\t\t\t\tfont-size: 16px;\n\t\t\t}\n\n\t\t\t.option-description {\n\t\t\t\tfont-size: 14px;\n\t\t\t\tcolor: #555;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
