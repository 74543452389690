export enum LESSON_TYPE {
  DONE = 1,
  VIDEO = 2,
  READ = 3,
}
export const DEFAULT_GENERAL_SETTING_BOOLEAN = true;
export const DEFAULT_TEXAREA_ROW = 1;
export const DEFAULT_MAX_LENGTH_COURSE_TEXTAREA = 512;
export const DEFAULT_MAX_LENGTH_QUESTION_BANK = 255;
export const DEFAULT_SCORE_QUESTION = 0;
export const TEACHER_DISCUSSION = 1;
export const LIKE_REACTION = 1;
export const DISLIKE_REACTION = 2;

export const initialQuestionSettingValue = {
  isVisibleToViewers: true,
  isRequiredAnswer: true,
  canEnterAnswer: true,
  isRandomOrder: true,
  markNumber: 1,
  displayScoreCriteria: true,
  markNumber2: 1,
  isSetScore4EachAns: false,
  totalScore: 0,
  scoreOfCorrect: 0,
  scoreOfInCorrect: 0,
  isShowGuide: false,
  isFeedback4EachAns: false,
  correctAns: "",
  incorrectAns: "",
  notAnswer: "",
  scores: [],
  feeds: [],
  hintContent: "",
  scoreCriteria: [],
  fileQuantity: 0,
  fileCapacity: 0,
  timeToPlay: 0,
  uri: "https://",
  schema: "",
};

export enum QuestionTypeEnum {
  CHOOSE_TEXT = 1,
  CHOOSE_IMAGE = 2,
  CHOOSE_VIDEO = 3,
  TRUE_OR_FALSE = 4,
  DROP_DOWN = 5,
  CHOOSE_MULTI_TEXT = 6,
  CHOOSE_MULTI_IMAGE = 7,
  CHOOSE_MULTI_VIDEO = 8,
  SHORT_TEXT = 9,
  LONG_TEXT = 10,
  CONNECT = 11,
  FILE_UPLOAD = 12,
  VIDEO = 13,
  MP3 = 14,
  FillText = 15,
}

export enum QuestionProgressModeClick {
  START = "start",
  INPROGRESS = "inprogress",
  END = "end",
}

export enum ModeQuestion {
  CREATE = "create",
  UPDATE = "update",
  VIEW = "view",
}

export enum TypeCreateQuestion {
  COURSEWARE = "courseware",
  SURVEY = "survey",
  QUESTIONBANK = "questionbank",
}

export const initialChooseTextQuestionValue = [
  {
    id: 1,
    value: "",
    key: 1,
    isTrue: false,
    point: 0,
    response: "",
  },
];

export const listItems = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: false,
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseImageQuestionValue = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: false,
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseVideoQuestionValue = [
  {
    id: 1,
    isTrue: false,
    filePath: "",
    isDisplayContent: false,
    value: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseMultiVideoQuestionValue = [
  {
    id: 1,
    isTrue: false,
    filePath: "",
    isDisplayContent: false,
    value: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseShortTextQuestionValue = [
  {
    id: 1,
    value: "",
    key: 1,
    isTrue: false,
    point: 0,
  },
];

export const initialChooseConnectTextQuestionValue = [
  {
    id: 1,
    content: {
      left: {
        content: "",
      },
      right: {
        content: "",
      },
    },
    orderNumber: 1,
    point: 0,
    response: "",
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    isTrue: true,
  },
  {
    id: 2,
    content: {
      left: {
        content: "",
      },
      right: {
        content: "",
      },
    },
    orderNumber: 2,
    point: 0,
    response: "",
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    isTrue: true,
  },
];

export const initialChooseVideoCriteriaValue = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: false,
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseMp3CriteriaValue = [
  {
    id: 1,
    value: "",
    key: 1,
    filePath: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseFileUploadQuestionValue = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: true,
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseFileUploadCriteriaValue = [
  {
    id: 1,
    value: "",
    key: 1,
    isTrue: true,
    point: 0,
  },
];

export const initialTrueFalseQuestionValue = [
  {
    id: 1,
    value: "Đúng",
    key: 1,
    isTrue: true,
  },
  {
    id: 2,
    value: "Sai",
    key: 2,
    isTrue: false,
  },
];

export const initialSettingUnitQuiz = {
  general: {
    randomQuiz: false,
    sequentiallyQuiz: false,
    showQuizScore: false,
    showHintQuiz: false,
    showFeedback: false,
    showResultOfEvaluation: false,
    resultOfEvaluationOption: null,
    showFeedbackContent: false,
    feedbackContentOption: null,
    showCorrectAnswer: false,
    correctAnswerOption: null,
    formatQuiz: 1,
  },
  action: {
    numberActionEvaluate: null,
    timeBetweenExecutions: null,
    completionTime: null,
    executionTimeExpired: null,
    executionTimeExpiredOption: null,
    executionTimeExpiredValue: null,
    timeForQuiz: null,
  },
  evaluate: {
    automaticScoring: false,
    typeScore: 0,
    minimumScore: 0,
    scoreFactor: null,
    recordResults: null,
    showResultsCompletion: false,
    showResultsCompletionOption: null,
  },
};

export const ListTypeQuestion = [
  {
    type: 1,
    title: "Nhiều lựa chọn",
    childs: [
      {
        type: QuestionTypeEnum.CHOOSE_TEXT,
        title: "Nhiều lựa chọn văn bản",
        image: "/images/choose-text.svg",
      },
      {
        type: QuestionTypeEnum.CHOOSE_IMAGE,
        title: "Nhiều lựa chọn ảnh",
        image: "/images/choose-image.svg",
      },
      {
        type: QuestionTypeEnum.CHOOSE_VIDEO,
        title: "Nhiều lựa chọn video",
        image: "/images/choose-video.svg",
      },
      {
        type: QuestionTypeEnum.TRUE_OR_FALSE,
        title: "Nhiều lựa chọn đúng sai",
        image: "/images/choose-true-false.svg",
      },
      {
        type: QuestionTypeEnum.DROP_DOWN,
        title: "Danh sách",
        image: "/images/choose-list.svg",
      },
    ],
  },
  {
    type: 2,
    title: "Nhiều câu trả lời",
    childs: [
      {
        type: QuestionTypeEnum.CHOOSE_MULTI_TEXT,
        title: "Nhiều đáp án văn bản",
        image: "/images/choose-multi-text.svg",
      },
      {
        type: QuestionTypeEnum.CHOOSE_MULTI_IMAGE,
        title: "Nhiều đáp án hình ảnh",
        image: "/images/choose-multi-image.svg",
      },
      {
        type: QuestionTypeEnum.CHOOSE_MULTI_VIDEO,
        title: "Nhiều đáp án video",
        image: "/images/choose-multi-video.svg",
      },
    ],
  },
  {
    type: 3,
    title: "Văn bản",
    childs: [
      {
        type: QuestionTypeEnum.SHORT_TEXT,
        title: "Câu trả lời ngắn",
        image: "/images/choose-short.svg",
      },
      {
        type: 992,
        title: "Điền vào chỗ trống",
        image: "/images/choose-fill.svg",
        disable: true,
      },
      {
        type: 993,
        title: "Trình soạn thảo",
        image: "/images/choose-textarea.svg",
        disable: true,
      },
    ],
  },
  {
    type: 4,
    title: "Kết nối",
    childs: [
      {
        type: QuestionTypeEnum.CONNECT,
        title: "Kết nối",
        image: "/images/choose-connect.svg",
      },
      {
        type: 994,
        title: "Sắp xếp",
        image: "/images/choose-sort.svg",
        disable: true,
      },
    ],
  },
  {
    type: 5,
    title: "Ghi âm/Ghi hình",
    childs: [
      {
        type: QuestionTypeEnum.MP3,
        title: "Ghi âm",
        image: "/images/choose-mp3.svg",
      },
      {
        type: QuestionTypeEnum.VIDEO,
        title: "Ghi hình",
        image: "/images/choose-record.svg",
      },
    ],
  },
  {
    type: 6,
    title: "File dính kèm",
    childs: [
      {
        type: QuestionTypeEnum.FILE_UPLOAD,
        title: "Đính kèm",
        image: "/images/choose-file.svg",
      },
    ],
  },
];
