import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Layout, MenuProps, Modal, Space, TableColumnsType, TableProps, } from "antd";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";

import { useWatch } from "antd/es/form/Form";
import { message } from "antd/lib";
import dayjs from "dayjs";
import { isEmpty, isNull } from "lodash";
import { useNavigate } from "react-router-dom";
import CreateExam from "../../../../../components/course-library/personal-exam-repository/list-exam/create-exam";
import DetailExam from "../../../../../components/course-library/personal-exam-repository/list-exam/detail-exam";
import EditPermission from "../../../../../components/course-library/personal-exam-repository/list-exam/edit-permission";
import HistoryCensorship from "../../../../../components/course-library/personal-exam-repository/list-exam/history-censorship";
import SchedulePublishModal from "../../../../../components/course-library/personal-exam-repository/list-exam/publish-exam/publishExam";
import SubmitReview from "../../../../../components/course-library/personal-exam-repository/list-exam/submit-review";
import TableData from "../../../../../components/table-data/TableData";
import { routesConfig } from "../../../../../config/routes";
import { ModeQuestion } from "../../../../../constants";
import { Permission } from "../../../../../layout/sidebar/permission.enum";
import { getPermission } from "../../../../../service/common";
import { postCourseSearch } from "../../../../../service/course-construct";
import { getSectionByCourse, getSequenceBySection, } from "../../../../../service/learning-course";
import {
  accessUnitBank,
  censorSendApprove,
  createUnitBank,
  editUnitBank,
  getAllProductionUnit,
  getAllUserSystem,
  getDataDetailUnitBank,
  getPageUnitBank,
  getPageUnitBankInStorage,
  getTopicFilter,
  publishUnitBank,
  removeStorage,
  removeUnitBank,
  unitQuestionBankApprovedHistory,
  updateUnitBank,
  useUnitBankToSequence,
} from "../../../../../service/library";
import { getAllmoocTeacherAccount } from "../../../../../service/list-account-teachers/listAccountTeachersApi";
import { getAllmoocFacilityAccount } from "../../../../../service/list-account-users/listAccountUsersApi";
import { getAllmoocMinisterialAccount } from "../../../../../service/list-acoount-ministry/listAccountMinistry";
import { globalStore, useAddLesson, useAuthStore } from "../../../../../stores/stores";
import { formatDateTime, formatOriginTimev3 } from "../../../../../utils/converDate";
import AddExamModal from "../bank-exam-list/AddExamModal";

export interface IDataDetail {
  id: number;
  code: string;
  title: string;
  topics: string;
  numberQuestion: number;
  description: string;
  tags: string;
  numberOfUse: number;
  authors: string;
  access: string;
  createdBy: string;
  productionUnit: string;
  typeProduction: string;
  emailToAccess: string[];
}

export interface IDataHistory {
  id: number;
  code: string;
  name: string;
  description: string;
  status: boolean;
  productUnitId: string;
  productUnitName: string;
  typeProduction: number;
  action: string;
  actionById: number;
  actionByName: string;
  approvedDate: string;
  publishDate: string;
  publishStatus: number;
  createdDate: string;
}

type Props = {
  filterData: any;
  searchValue: string;
  setFilterData: (value: any) => void;
  setSearchValue: (value: string) => void;
  filterApplied: boolean;
  tab: string;
  typeQuestion: boolean
};

const ListExamPersonal = (props: Props) => {
  const {
    filterData,
    searchValue,
    setFilterData,
    setSearchValue,
    filterApplied,
    tab,
    typeQuestion
  } = props
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);

  const { user } = useAuthStore();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedDetail, setIsOpenedDetail] = useState<boolean>(false);
  const [isOpenedPermission, setIsOpenedPermission] = useState<boolean>(false);
  const [isOpenedHistoryCensorship, setIsOpenedHistoryCensorship] =
    useState<boolean>(false);
  const [filtersCleared, setFiltersCleared] = useState(false);
  const [idDetailData, setIdDetailData] = useState<number | null>(null);
  const [idEdit, setIdEdit] = useState<number | null>(null);
  const [idUnit, setIdUnit] = useState<number | null>(null);
  const [idBank, setIdBank] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataDetail, setDataDetail] = useState<IDataDetail | null>(null);
  const [dataEdit, setDataEdit] = useState<any>({});
  const [idPublish, setIdPublish] = useState<string>("");
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isAddExamModalOpen, setIsAddExamModalOpen] = useState(false);
  const [isSubmitReview, setIsSubmitReview] = useState<boolean>(false);
  const [loadingReview, setLoadingReview] = useState<boolean>(false);
  const [selectedRecordStatus, setSelectedRecordStatus] = useState<string>("");
  const [courseOptions, setCourseOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [sequenceOptions, setSequenceOptions] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState<number | null>(null);
  const navigate = useNavigate();
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;
  const [arrNewApprove, setArrNewApprove] = useState<any>({})
  const [arrEditApprove, setArrEditApprove] = useState<any>({})
  const [dataAuthors, setDataAuthors] = useState<any>([]);
  const handleScheduleCancel = () => {
    setIsScheduleModalOpen(false);
  };

  const handleAddExamCancel = () => {
    setIsAddExamModalOpen(false);
  };
  const [dataOptions, setDataOptions] = useState({
    dataTopic: [],
    dataPermission: [
      {
        id: "Công khai",
        name: "Công khai",
      },
      {
        id: "Nội bộ",
        name: "Nội bộ",
      },
      {
        id: "Giới hạn",
        name: "Giới hạn",
      },
    ],
    dataAuthor: [],
    dataStatus: [],
    dataUsers: [],
    dataUsersMinistry: [],
    dataUsersFacility: [],
    dataUnit: [],
    dataLevel: [
      {
        id: 3,
        name: "Cấp Bộ",
      },
      {
        id: 2,
        name: "Cấp Trường",
      },
      {
        id: 1,
        name: "Cấp Giảng viên",
      },
    ],
    dataLimit: [],
  });
  const fetchPublishData = async (values: any) => {
    const dataReq = {
      id: idPublish,
      publishDate:
        values.select === 3
          ? dayjs(values.date)
            .set("hour", dayjs(values.time).hour())
            .set("minute", dayjs(values.time).minute())
            .set("second", dayjs(values.time).second())
            .toISOString()
          : null,
      scheduleType: values.select,
    };
    try {
      const res = await publishUnitBank(dataReq);
      if (res.status === 200) {
        message.success("Xuất bản đề thành công!");
        fetchDataUnit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFinish = (values: any) => {
    setIsAddExamModalOpen(false);
    setIsScheduleModalOpen(false);
    fetchPublishData(values);
  };
  console.log("filterData====", filterData);

  const fetchDataUnit = async () => {
    setLoading(true);
    try {
      if (typeQuestion) {
        const payload = {
          page: page,
          size: size,
          keyword: searchValue,
          topics: filterData.topics || [],
          access: filterData.permission ? filterData.permission : [],
          publicStatus: filterData.status ? filterData.status : [],
          type: "unit_quiz",
          isPublic: null,
          typeProductions: filterData.typeProduction
            ? filterData.typeProduction
            : [],
          productionUnits: filterData.productionUnit
            ? filterData.productionUnit
            : [],
          dateFrom: filterData?.createDate[0] ? dayjs(filterData?.createDate[0]).toISOString() : undefined,
          dateTo: filterData?.createDate[1] ? dayjs(filterData?.createDate[1]).toISOString() : undefined,
          sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder)
            ? ["updated_date, desc"]
            : [`${fieldSortOrder},${statusOrder}`],
        };
        const res = await getPageUnitBankInStorage(payload);
        const { data, success } = res.data;
        setLoading(false);
        if (success && !isNull(data)) {
          setTotalRecords(data.totalElements);
          setDataTable(data.content);
        } else {
          setTotalRecords((prevState) => prevState);
          setDataTable([]);
        }
      } else {
        const dataReq = {
          keyword: searchValue,
          page: page,
          size: size,
          access: filterData.permission ? filterData.permission : [],
          topics: filterData.topics || [],
          dateFrom: filterData?.createDate[0] ? dayjs(filterData?.createDate[0]).toISOString() : undefined,
          dateTo: filterData?.createDate[1] ? dayjs(filterData?.createDate[1]).toISOString() : undefined,
          publicStatus: filterData.status ? filterData.status : [],
          type: "unit_quiz",
          sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder)
            ? ["updated_date, desc"]
            : [`${fieldSortOrder},${statusOrder}`],
        };
        const response = await getPageUnitBank(dataReq);
        const { data, success } = response.data;
        setLoading(false);
        if (success && !isNull(data)) {
          setPageSize(data.size);
          setTotalRecords(data.totalElements);
          setDataTable(data.content);
        } else {
          setPageSize((prevState) => prevState);
          setTotalRecords((prevState) => prevState);
          setDataTable([]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleFinish = (values: any) => {
    setPage(1);
    setFilterData({
      ...filterData,
      topic: values.topic,
      typeProduction: values.level,
      productionUnit: values.unit,
      permission: values.permission,
      status: values.status,
      updatedDate: values?.updatedDate,
      dateFrom: values?.time ? (values?.time[0]).add(1, "day").toISOString() : null,
      dateTo: values?.time ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };

  const fetchDetailData = async (id: number) => {
    setLoadingReview(true);
    try {
      const response = await getDataDetailUnitBank(id);
      if (response.status === 200) {
        const { data } = response.data;
        setDataDetail(data);
        setTimeout(() => {
          setLoadingReview(false);
        }, 333);
      }
    } catch (e) {
      setLoadingReview(false);
      console.error(e);
    }
  };

  const fetchEditData = async (id: number) => {
    try {
      const response = await editUnitBank(id);
      const { data } = response.data;
      setDataEdit(data);
    } catch (error) {
    }
  };

  const getDetailHistoryDetail = async () => {
    const data = {
      questionBankId: idDetailData
    }
    await unitQuestionBankApprovedHistory(data).then((res) => {
      if (res.status === 200) {
        const { data } = res.data?.data
        const newApprove = data?.filter((item: any) => item.action === 'Duyệt mới')
        const editApprove = data?.filter((item: any) => item.action === 'Duyệt chỉnh sửa')
        setArrNewApprove(newApprove)
        setArrEditApprove(editApprove)
      }
    })
  }

  const showModal = (bankId: number) => {
    setSelectedBankId(bankId);
    fetchCourses();
    setIsAddExamModalOpen(true);
  };

  const handleGetActiveTopic = (topics: any) => {
    const activeTopics = topics.filter((item: { status: any }) => item.status);
    return activeTopics.map((value: { name: string; id: number }) => ({
      label: value.name,
      code: value.id,
    }));
  };

  const fetchDataOptions = async () => {
    try {
      const response = await Promise.allSettled([
        getAllmoocFacilityAccount(),
        getAllmoocTeacherAccount(),
        getAllmoocMinisterialAccount(),
        getTopicFilter({ code: "topic" }),
        getPermission(),
        getAllProductionUnit({ name: null }),
        getAllUserSystem({ name: null }),
      ]);

      if (response[1].status === "fulfilled" &&
        response[0].status === "fulfilled" &&
        Array.isArray(response[1]?.value?.data?.data) &&
        Array.isArray(response[0]?.value?.data?.data)
      ) {
        setDataAuthors([...response[1]?.value?.data?.data, ...response[0]?.value?.data?.data])
      }

      setDataOptions({
        ...dataOptions,
        dataTopic:
          response[3].status === "fulfilled"
            ? response[3].value.data.data.moocPortfolioValueData.map(
              (value: { name: string; id: number }) => ({
                label: value.name,
                code: value.id,
              })
            )
            : [],
        dataUsersMinistry:
          response[2].status === "fulfilled" &&
            permissions?.includes(Permission.isMinistry)
            ? response[2].value?.data?.data
              .filter((i: { unitId: string }) =>
                i.unitId && i.unitId === user?.chiTietDonViCongtac?.uuid)
              .map((value: any) => ({
                label: value.name,
                code: value.id,
              }))
            : [],
        dataUnit:
          response[5].status === "fulfilled" &&
          response[5].value.data.data.map((unit: any) => {
            return {
              id: unit.uuid,
              name: unit.name,
            };
          }),
        dataLimit:
          response[6].status === "fulfilled" &&
          response[6].value.data.data.map((unit: any) => {
            return {
              id: unit.id,
              name: unit.email,
            };
          },
          )
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (dataAuthors.length > 0) {
      setDataOptions((prev) => ({
        ...prev,
        dataUsers:
          dataAuthors &&
            (permissions?.includes(Permission.isLecturer) || permissions?.includes(Permission.isQTCS))
            ? dataAuthors
              .filter((i: { unitId: string }) =>
                i.unitId && i.unitId === user?.maDonViCongTac)
              .map((value: any) => ({
                label: value.name,
                code: value.id,
              }))
            : [],
      }));
    }
  }, [dataAuthors, permissions]);

  const deleteUnitBank = async (record: any) => {
    await removeUnitBank(record.id).then((res) => {
      if (res.status === 200) {
        message.success("Đề được xoá thành công");
        fetchDataUnit();
      }
    });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await callUseUnitBankToSequence(values);
      setIsAddExamModalOpen(false);
      setIsScheduleModalOpen(false);
    } catch (error) {
      message.error("Vui lòng kiểm tra lại các trường bắt buộc.");
    }
  };
  const fetchCourses = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 10,
    };
    try {
      await postCourseSearch(payload).then((res) => {
        if (res.status === 201) {
          setCourseOptions(res.data.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  const courseId = useWatch("course", form);
  useEffect(() => {
    form.setFieldsValue({
      section: null,
      sequence: null,
    });
    if (courseId) {
      fetchSection(courseId);
    }
  }, [courseId]);

  const fetchSection = async (id: number) => {
    try {
      await getSectionByCourse(id).then((res) => {
        if (res.status === 200) {
          setSectionOptions(res.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const sectionId = useWatch("section", form);
  useEffect(() => {
    form.setFieldsValue({
      sequence: null,
    });
    if (sectionId) {
      fetchSequence(sectionId);
    }
  }, [sectionId]);

  const fetchSequence = async (id: number) => {
    try {
      await getSequenceBySection(id).then((res) => {
        if (res.status === 200) {
          setSequenceOptions(res.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  const callUseUnitBankToSequence = async (values: any) => {
    const payload = {
      sequenceId: values.sequence,
      bankId: selectedBankId,
    };
    try {
      await useUnitBankToSequence(payload);
      message.success("Đề đã được thêm thành công!");
    } catch (err) {
      message.error("Có lỗi xảy ra khi thêm đề.");
      console.error(err);
    }
  };

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      ...(record.publishStatus === 0 ||
        record.publishStatus === 3 ||
        record.publicStatus === 4
        ? [
          {
            key: "0",
            label: "Gửi kiểm duyệt",
          },
        ]
        : []),
      ...(record?.publishStatus === 8 ?
        [{
          key: "7",
          label: "Gửi kiểm duyệt lại",
        },]
        : []),
      ...(record.publishStatus === 7 || record.publishStatus === 5
        ? [
          {
            key: "1",
            label: "Xuất bản",
          },
        ]
        : []),
      ...(record.publishStatus === 1 || record.publishStatus === 2
        ? []
        : [{
          key: "2",
          label: "Chỉnh sửa",
        },]),
      {
        key: "3",
        label: "Trình phát đề",
      },
      {
        key: "4",
        label: "Sửa quyền truy cập",
      },
      ...(record.publishStatus !== 0
        ? [
          {
            key: "5",
            label: "Lịch sử kiểm duyệt",
          },
        ]
        : []),
      {
        key: "6",
        label: "Xóa",
      },
    ];
    const options2: MenuProps["items"] = [
      {
        key: "0",
        label: "Thêm vào khoá học",
      },
      {
        key: "1",
        label: "Bỏ lưu trữ",
      },
    ];
    return typeQuestion ? options2 : options1;
  };

  const convertStatus = (status: number): string => {
    switch (status) {
      case 0:
        return "Bản nháp";
      case 1:
        return "Chờ QTCS duyệt";
      case 2:
        return "Chờ Cấp bộ duyệt";
      case 3:
        return "QTCS không duyệt";
      case 4:
        return "Cấp Bộ không duyệt";
      case 5:
        return "Đã lên lịch";
      case 6:
        return "Đã xuất bản";
      case 7:
        return "Chưa xuất bản";
      case 8:
        return "Đề xuất chỉnh sửa";
      default:
        return "";
    }
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder}</span>
        );
      },
    },
    {
      title: "Mã đề",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Tiêu đề",
      dataIndex: "question",
      key: "question",
      width: 15,
      sorter: true,
    },
    {
      title: "Số câu hỏi",
      dataIndex: "totalQuiz",
      key: "totalQuiz",
      width: 5,
      // sorter: true,
    },
    {
      title: "Chủ đề",
      dataIndex: "topic",
      key: "topic",
      width: 5,
    },
    ...(!typeQuestion
      ? [
        {
          title: "Lượt dùng",
          dataIndex: "numberOfUse",
          key: "numberOfUse",
          width: 5,
          render: (_: any, record: any) => {
            return <>{record?.numberOfUse ? record?.numberOfUse : 0}</>;
          },
        },
      ]
      : [
        {
          title: "Phân cấp",
          dataIndex: "typeProduction",
          key: "typeProduction",
          width: 5,
          render: (_: any, record: any) => {
            return (
              <>
                {record?.typeProduction === 1 ? (
                  <>Cấp giảng viên</>
                ) : record?.typeProduction === 2 ? (
                  <>Cấp trường</>
                ) : (
                  <>Cấp bộ</>
                )}
              </>
            );
          },
        },
      ]),
    ...(!typeQuestion
      ? [
        {
          title: "Quyền truy cập",
          dataIndex: "access",
          key: "access",
          width: 5,
        },
      ]
      : [
        {
          title: "Lượt dùng",
          dataIndex: "numberOfUse",
          key: "numberOfUse",
          width: 5,
          render: (_: any, record: any) => {
            return <>{record?.numberOfUse ? record?.numberOfUse : 0}</>;
          },
        },
      ]),

    ...(!typeQuestion
      ? [
        {
          title: "Trạng thái",
          dataIndex: "publishStatus",
          key: "publishStatus",
          width: 5,
          render: (_: any, record: any) => {
            return convertStatus(record.publishStatus);
          },
        },
      ]
      : [
        {
          title: "Đơn vị xuất bản",
          dataIndex: typeQuestion ? "productionUnitName" : "productUnitName",
          key: typeQuestion ? "productionUnitName" : "productUnitName",
          width: 10,
        },
      ]),

    {
      title: "Ngày cập nhật",
      dataIndex: "updateDate",
      key: "updateDate",
      sorter: true,
      width: 8,
      render: (_: any, record: any) => {
        return (
          <>
            {!isEmpty(record?.updateDate)
              ? formatOriginTimev3(record?.updateDate)
              : null}
          </>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "operation",
      width: 5,
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenedDetail(true);
                  setIdDetailData(record.id);
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: createOptions(record),
                  onClick: ({ key }) => {
                    if (key === "0") {
                      if (typeQuestion) {
                        showModal(record.id);
                      } else {
                        setIdDetailData(record.id);
                        handleSubmitReview(convertStatus(record.publishStatus));
                      }
                    }
                    if (key === "1") {
                      if (typeQuestion) {
                        handleRemoveStorage(record.id);
                      } else {
                        setIdPublish(record.id);
                        setIsScheduleModalOpen(true);
                      }
                    }
                    if (key === "2") {
                      //  change here
                      setIsOpened(true);
                      setIdEdit(record.id);
                      setIdUnit(record.unitId);
                      fetchEditData(record.id);
                    }
                    if (key === "3") {
                      //  change here
                      navigate(
                        `/${routesConfig.personalExamRepository}/${routesConfig.previewExam}?id=${record.id}`,
                        {
                          state: {
                            backUrl: `/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listExamRepository}`,
                            mode: "4",
                            titleBreadCrumb: "Danh sách ngân hàng đề của tôi",
                          },
                        }
                      );
                    }
                    if (key === "4") {
                      setIsOpenedPermission(true);
                      setIdBank(record.id);
                      setIdDetailData(record.id);
                    }
                    if (key === "7") {
                      if (typeQuestion) {
                        showModal(record.id);
                      } else {
                        setIdDetailData(record.id);
                        handleSubmitReview(convertStatus(record.publishStatus));
                      }
                    }
                    if (key === "5") {
                      setIsOpenedHistoryCensorship(true);
                      setIdDetailData(record.id)
                    }
                    if (key === "6") {
                      Modal.confirm({
                        title: `Xoá đề ${record.question}`,
                        content: `Đề đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá đề?`,
                        onOk: () => deleteUnitBank(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }

                    // else if (key === '2')handleCloseTask
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ];


  useEffect(() => {
    if (idDetailData) {
      fetchDetailData(idDetailData);
    }
  }, [idDetailData]);

  useEffect(() => {
    if (isOpenedHistoryCensorship) {
      getDetailHistoryDetail()
    }
  }, [isOpenedHistoryCensorship]);

  useEffect(() => {
    fetchDataOptions();
  }, []);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleSubmitReview = (status: string) => {
    setSelectedRecordStatus(status);
    setIsSubmitReview(true);
  };

  const closeForm = () => {
    setDataDetail(null);
    setIsOpened(false);
    setIsOpenedPermission(false);
    setIsSubmitReview(false);
    setIdDetailData(null);
    setIdBank(null);
    setIdEdit(null);
    form.resetFields();
  };

  const onFinishCreate = async (values: any) => {
    const dataForm = {
      code: values.code,
      authors: values.authors,
      title: values.title,
      topics: values.topics,
      description: values.description,
      tags: values.tags,
      courseUnitId: values.examId,
    };
    const dataFormUpdate = {
      id: idEdit,
      title: values.title,
      description: values.description,
      code: values.code,
      authors: values.authors,
      topics: values.topics,
      tags: values.tags,
    };
    if (idEdit) {
      try {
        const response = await updateUnitBank(dataFormUpdate);
        const { data, success } = response.data;
        if (success) {
          message.success(" Cập nhật đề thành công");
          closeForm();
          fetchDataUnit();
          navigate(`/course-library/personal-exam-repository/list-exam/${routesConfig.createQuestionExam}?unitId=${idUnit}`, {
            state: { id: idUnit ?? "", title: values.title },
          });
          setModeQuestion(ModeQuestion.UPDATE);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const response = await createUnitBank(dataForm);
        const { success, data } = response.data;
        if (success) {
          message.success("Thêm mới đề thành công");
          closeForm();
          fetchDataUnit();
          navigate(`/course-library/personal-exam-repository/list-exam/${routesConfig.createQuestionExam}?unitId=${data.unitId}`, {
            state: { id: data.unitId ?? "", title: values.title },
          });
          setModeQuestion(ModeQuestion.CREATE);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onFinishEdit = async (values: any) => {
    const dataForm = {
      bankId: idBank,
      access: values.permissions,
      shareUsers: values.permissions === "Giới hạn" ? values.limit : null,
    };
    try {
      const response = await accessUnitBank(dataForm);
      if (response.status === 200) {
        message.success("Sửa quyền truy cập thành công");
        closeForm();
        fetchDataUnit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveStorage = async (bankId: number) => {
    try {
      const res = await removeStorage(bankId, true);
      if (res.data.success === true) {
        message.success("Bỏ lưu trữ thành công");
        fetchDataUnit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const closeDetail = () => setIsOpenedDetail(false);
  const closeHistoryCensorship = () => setIsOpenedHistoryCensorship(false);

  const callSubmitReviewApi = async (values: any) => {
    let publishDate = null;

    if (values?.scheduleType === 3) {
      publishDate = formatDateTime(values.date, values.time)
    }

    const payload = {
      id: idDetailData,
      access: values?.access,
      contentEdit: values?.contentEdit,
      publishDate: publishDate,
      scheduleType: values?.scheduleType,
      shareUsers: values.access === "Giới hạn" ? values.shareUsers : [],
    };

    try {
      const res = await censorSendApprove(payload);
      if (res.status === 200) {
        message.success("Gửi kiểm duyệt thành công");
        setIsSubmitReview(false);
        fetchDataUnit();
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (tab === "1") fetchDataUnit();
  }, [page, size, typeQuestion, fieldSortOrder, statusOrder]);

  useEffect(() => {
    setPage(1)
    if (tab === "1") fetchDataUnit();
  }, [tab, filterApplied]);

  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createUnitBank") {
            const { createUnitBank } = forms;

            const data = createUnitBank.getFieldsValue([
              "code",
              "authors",
              "title",
              "topics",
              "description",
              "tags",
              "examId",
            ]);
            onFinishCreate(data);
          }
          if (name === "editPermissions") {
            const { editPermissions } = forms;
            const dataEditPermissions = editPermissions.getFieldsValue([
              "permissions", "limit"
            ]);
            onFinishEdit(dataEditPermissions);
          }
        }}
      >

        {/* <div className="table-header--wrapper">
            <div className="table-header--left">
              {!typeQuestion ? (
                <PageHeader title="Danh sách ngân hàng đề của tôi" />
              ) : (
                <PageHeader title="Danh sách đề lưu trữ" />
              )}
            </div>
            <FilterExamTable
              handleSearch={handleSearch}
              handleOpen={handleOpen}
              handleSubmitSearch={handleSubmitSearch}
              handleChangeInput={handleChange}
              handleOpenFilter={handleOpenFilter}
              searchValue={searchValue}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
            />
          </div> */}
        <div className="tabs-heading">
          <TableData
            dataColumns={dataColumns}
            dataTable={dataTable}
            setPageNumber={setPage}
            setPageSize={setPageSize}
            pageNumber={page}
            pageSize={size}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            pagination={true}
            handleTableChangeProps={handleTableChange}
          />
          <CreateExam
            isOpened={isOpened}
            id={idEdit}
            dataDetail={dataEdit}
            dataOptions={dataOptions}
            user={user}
            closeForm={closeForm}
          />
          <DetailExam
            isOpened={isOpenedDetail}
            dataDetail={dataDetail}
            closeForm={closeDetail}
          />
          <HistoryCensorship
            isOpened={isOpenedHistoryCensorship}
            arrNewApprove={arrNewApprove}
            arrEditApprove={arrEditApprove}
            dataOptions={dataOptions}
            closeForm={closeHistoryCensorship}
          />
          <EditPermission
            isOpened={isOpenedPermission}
            loading={loadingReview}
            dataDetail={dataDetail}
            dataOptions={dataOptions}
            closeForm={closeForm}
            onFinish={onFinishEdit}
          />
          <SubmitReview
            isOpened={isSubmitReview}
            loading={loadingReview}
            closeForm={closeForm}
            status={selectedRecordStatus}
            onFinish={callSubmitReviewApi}
            dataOptions={dataOptions}
            dataDetail={dataDetail}
          />
        </div>
      </Form.Provider>
      <SchedulePublishModal
        open={isScheduleModalOpen}
        onClose={handleScheduleCancel}
        onFinish={onFinish}
      />
      <AddExamModal
        onCancel={handleAddExamCancel}
        onFinish={handleOk}
        open={isAddExamModalOpen}
        courseOptions={courseOptions}
        sectionOptions={sectionOptions}
        sequenceOptions={sequenceOptions}
        form={form}
      />
    </Layout>
  );
};

export default ListExamPersonal;
