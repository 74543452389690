// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-major .ant-card-body {
  padding: 0 0 15px 0; }
  .card-major .ant-card-body .header-major-card {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 15px;
    padding: 20px; }
    .card-major .ant-card-body .header-major-card .card-major-title {
      justify-content: space-between; }
      .card-major .ant-card-body .header-major-card .card-major-title .card-major-title-right {
        align-content: center;
        flex-wrap: wrap; }
  .card-major .ant-card-body .table-wrapper {
    padding: 0; }
    .card-major .ant-card-body .table-wrapper .ant-table-wrapper {
      height: 72vh;
      overflow: auto; }
      .card-major .ant-card-body .table-wrapper .ant-table-wrapper td:has(.empty-table) {
        height: 66vh; }
    .card-major .ant-card-body .table-wrapper .ant-pagination {
      padding-right: 21px; }
`, "",{"version":3,"sources":["webpack://./src/components/category-management/department-major-management/card-major/card-major.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAmB,EAAA;EAFvB;IAKM,gCAAgC;IAChC,mBAAmB;IACnB,aAAa,EAAA;IAPnB;MAUQ,8BAA8B,EAAA;MAVtC;QAaU,qBAAqB;QACrB,eAAe,EAAA;EAdzB;IAmBM,UAAU,EAAA;IAnBhB;MAsBQ,YAAY;MACZ,cAAc,EAAA;MAvBtB;QA0BU,YAAY,EAAA;IA1BtB;MA+BQ,mBAAmB,EAAA","sourcesContent":[".card-major {\n  .ant-card-body {\n    padding: 0 0 15px 0;\n\n    .header-major-card {\n      border-bottom: 1px solid #f0f0f0;\n      margin-bottom: 15px;\n      padding: 20px;\n\n      .card-major-title {\n        justify-content: space-between;\n\n        .card-major-title-right {\n          align-content: center;\n          flex-wrap: wrap;\n        }\n      }\n    }\n    .table-wrapper {\n      padding: 0;\n\n      .ant-table-wrapper {\n        height: 72vh;\n        overflow: auto;\n\n        td:has(.empty-table) {\n          height: 66vh;\n        }\n      }\n\n      .ant-pagination {\n        padding-right: 21px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
