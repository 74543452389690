import { Button, Form, Layout, message, Tabs, Typography } from 'antd';
import { TabsProps } from 'antd/lib';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import './bankExam.scss';
import BankExamList from './components/bank-exam-list/BankExamList';
import ListExamPersonal from './components/personal-exam-repository/list-exam';
import PageHeader from '../../../components/page-header/PageHeader';
import FormFilter from './components/FormFilter/FormFilter';
import dayjs from 'dayjs';
import { getLearningMaterialList } from '../../../service/learning-material';
import { DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import FormInput from '../../../components/form-input/FormInput';
import { createUnitBank, getAllAuthor, getAllProductionUnit, getTopicFilter } from '../../../service/library';
import MyExamBank from './components/my-exam-bank/MyExamBank';
import SharedExamBank from './components/shared-exam-bank/SharedExamBank';
import CreateExam from '../../../components/course-library/personal-exam-repository/list-exam/create-exam';
import { useAddLesson, useAuthStore } from '../../../stores/stores';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '../../../config/routes';
import { ModeQuestion } from '../../../constants';

type BankExamProps = {
}


const BankExam = ({ }: BankExamProps) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [form] = Form.useForm();
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [typeQuestion, setTypeQuestion] = useState<boolean>(false);
  const { user } = useAuthStore();
  console.log("user", user);

  const [dataOptions, setDataOptions] = useState<any>({
    dataTopic: [],
    dataLevel: [
      {
        id: 3,
        name: "Cấp Bộ",
      },
      {
        id: 2,
        name: "Cấp Trường",
      },
      {
        id: 1,
        name: "Cấp Giảng viên",
      },
    ],
    dataUnit: [],
    dataAuthor: [],
  });

  const [filterData, setFilterData] = useState<any>({
    shareUniversity: [],
    shareUser: [],
    shareType: [],
    status: [],
    createDate: ["", ""],
    topics: []
  });
  const [filterApplied, setFilterApplied] = useState(false);

  const [tab, setTab] = useState<string>("1")
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Của tôi",
      children: (
        <MyExamBank
          filterData={filterData}
          searchValue={searchValue}
          setFilterData={setFilterData}
          setSearchValue={setSearchValue}
          filterApplied={filterApplied}
          tab={tab}
          typeQuestion={typeQuestion}
        />

      ),
    },
    ...(!typeQuestion ? [{
      key: "2",
      label: "Được chia sẻ",
      children:
        <SharedExamBank
          filterData={filterData}
          searchValue={searchValue}
          setFilterData={setFilterData}
          setSearchValue={setSearchValue}
          filterApplied={filterApplied}
          tab={tab}

        />
    }] : [])


  ];

  const handleCloseFilter = () => {
    setIsShowFilter(false);
  };

  const clearFilter = () => {
    setPageNumber(1);
    form.resetFields();
    setFilterData({
      ...filterData,
      shareUniversity: [],
      author: [],
      shareType: [],
      status: [],
      createDate: ["", ""],
      topics: undefined
    });
    setFilterApplied((prev) => !prev);

  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onFilterSubmit = () => {
    setPageNumber(1);
    setFilterApplied((prev) => !prev);
  };
  const handleShowFilter = () => {
    setIsShowFilter(!isShowFilter);
  };
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        size: pageSize,
        page: pageNumber,
        keyword: searchValue,
      };
      setPageNumber(1);
      setFilterApplied((prev) => !prev);

    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    setFilterApplied((prev) => !prev);

  };

  useEffect(() => {
    Promise.all([
      getTopicFilter({ code: "topic" }),
      getAllProductionUnit({ name: null }),
      getAllAuthor({ name: null }),
    ]).then((res) => {
      console.log("res", res);

      const listTopic = res[0].data.data.moocPortfolioValueData;
      const listUnit = res[1].data.data.map((unit: any) => {
        return ({
          id: unit.uuid,
          name: unit.name,
        })
      });
      const listAuthor = res[2].data.data;

      setDataOptions({
        ...dataOptions,
        dataTopic: listTopic?.map((it: any) => {
          return {
            ...it,
            value: it.id,
            label: it.name,
          }
        }),
        dataUnit: listUnit?.map((it: any) => {
          return {
            ...it,
            value: it.id,
            label: it.name,
          }
        }),
        dataAuthor: listAuthor?.map((it: any) => {
          return {
            ...it,
            value: it.id,
            label: it.name,
          }
        }),
      });
    });
  }, []);

  const handleOpen = () => {
    setIsOpened(true);
  };
  const navigate = useNavigate()
  const onFinishCreate = async (values: any) => {
    const dataForm = {
      code: values.code,
      authors: values.authors,
      title: values.title,
      topics: values.topics,
      description: values.description,
      tags: values.tags,
      courseUnitId: values.examId,
    };

    try {
      const response = await createUnitBank(dataForm);
      const { success, data } = response.data;
      if (success) {
        message.success("Thêm mới đề thành công");
        setIsOpened(false);
        setFilterApplied((prev) => !prev);
        navigate(`/course-library/personal-exam-repository/list-exam/${routesConfig.createQuestionExam}?unitId=${data.unitId}`, {
          state: { id: data.unitId ?? "", title: values.title },
        });
        setModeQuestion(ModeQuestion.CREATE);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Layout className="page-header-group unit-list-page">
      <div
        className={`page-header-group--layout ${showFilter ? "open-filter" : ""
          }`}
      >
        <div className="table-header--wrapper">
          <div className="table-header--left">
            {!typeQuestion ? (
              <PageHeader title="Danh sách ngân hàng đề " />
            ) : (
              <PageHeader title="Danh sách đề lưu trữ" />
            )}          </div>
          <div className="flex gap-8">
            <Button className="filter-button" onClick={handleShowFilter}>
              <Typography.Text>Bộ lọc</Typography.Text>
              <DownOutlined />
            </Button>
            <FormInput
              placeholder="Nhập mã, tên hoặc mô tả để tìm"
              value={searchValue}
              onChange={handleChangeInput}
              onKeyPress={handleSearch}
              afterPrefixIcon={
                <SearchOutlined onClick={handleSubmitSearch} />
              }
              style={{ width: "400px" }}
            />
            <Button onClick={handleOpen} className="btn btn-primary">
              <PlusOutlined />
              <Typography.Text>Thêm đề</Typography.Text>
            </Button>
            <Button className="btn" onClick={() =>
              setTypeQuestion(!typeQuestion)}>
              <Typography.Text>
                Đề lưu trữ
              </Typography.Text>
            </Button>
          </div>
          {isShowFilter ? (
            <FormFilter
              handleCloseFilter={handleCloseFilter}
              clearFilter={clearFilter}
              form={form}
              showFilter={isShowFilter}
              tab={tab}
              filterData={filterData}
              setFilterData={handleChangeFilter}
              onFinish={onFilterSubmit}
              dataOptions={dataOptions}
            />
          ) : null}
        </div>
        {/* <FilterForm
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={onFinish}
            showFilter={showFilter}
            form={form}
          /> */}
      </div>
      <Tabs items={items}
        onChange={tab => setTab(tab)}
      />
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createUnitBank") {
            const { createUnitBank } = forms;

            const data = createUnitBank.getFieldsValue([
              "code",
              "authors",
              "title",
              "topics",
              "description",
              "tags",
              "examId",
            ]);
            onFinishCreate(data);
          }
        }}
      >

        <CreateExam
          isOpened={isOpened}
          dataDetail={{}}
          dataOptions={dataOptions}
          user={user}
          closeForm={() => {
            setIsOpened(false);
          }}
        />
      </Form.Provider>

    </Layout>

  )
}

export default BankExam;
