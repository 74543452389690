import { axiosConfigV2 } from './../../config/api/configApiv2';
import { getAllEnterprise } from './../../pages/user-info/account/services';
import { IDataCreateUser } from "../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2OpenAPI } from '../../config/api/configOpenApiV2';


export const createUser: (user: IDataCreateUser) => Promise<AxiosResponse<any>> = (user) => {
  return axiosConfig.post(`/accounts/QTCS`, user)
}

export const getAccountDetail: (id: string) => Promise<AxiosResponse<any>> = (id: string) => {
  return axiosConfig.get(`/accounts/get-by-id/${id}`)
}

export const getAccountUsers: (data: any) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfig.post(`/accounts/QTCS/search`, data)
}

export const getAccountBasicAdminUsers: (data: any) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/accounts/QTCS/search`, data)
}

export const getAllEnterpriseCustomer: (data: any) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-facility-management/account/get-all-by-filter`, data)
}

export const getUnits: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/accounts/don-vi-cong-tac`)
}

export const getRoles: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/accounts/vai-tro`)
}

export const getOffices: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/accounts/chuc-vu`)
}

export const changeStatusAccount: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-facility-management/account/change-status`, data)
}

export const registerDeleteAccount: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.post(`/account/delete-account/request-del-account-of-teacher`, {})
}


export const deleteAccountUser: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-facility-management/account/${id}`)
}

export const changePasswordQTCS: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.put(`/mooc-facility-management/account/change-password`, data);
};

export const exportExcelData: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-facility-management/account/export-excel`, data, {
    responseType: "arraybuffer",
  });
};

// get all account mooc facility
export const getAllmoocFacilityAccount: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-facility-management/account/get-all`);
};

