import {useEffect, useState} from "react";
import {Button, Radio, RadioChangeEvent, Typography} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useAddLesson} from "../../../../stores/stores";
import {QuestionAnswerInfo, QuestionData, QuestionItemDetail,} from "../../../../types/course-construction";
import {initialChooseTextQuestionValue} from "../../../../constants";
import {SortableList} from "../../../SortableList";
import QuestionTextItem from "./QuestionTextItem";
import TextEditor from "../../../text-editor/TextEditor";
import {convertObjectToArray, findMaxIndexOfArray, mapAnsBEtoFE} from "../../../../utils/arrays";
import {renderQuestionDescriptionByType, renderQuestionNameByType} from "../../../../utils/functionUltils";
import {get, isArray} from "lodash";

function ChooseText({dataFromApi = {} as QuestionData,}: QuestionItemDetail) {
  const question = useAddLesson((state: any) => state.question);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);

  const [questionName, setQuestionName] = useState<string>("");
  const [answers, setAnswers] = useState(convertObjectToArray(currentQuestionInfo, "questionName") || []);
  const [radioValue, setRadioValue] = useState<number>(0);

  const resetAnswers = () => {
    const newAnswers = convertObjectToArray(currentQuestionInfo, "questionName");
    return newAnswers;
  }

  const handleChangeRadio = (e: RadioChangeEvent) => {
    const currentActiveRadioId = e.target.value;
    const newAnswers = resetAnswers();
    newAnswers.forEach((item) => (item.isTrue = false));
    newAnswers[currentActiveRadioId - 1] = {...newAnswers[currentActiveRadioId - 1], isTrue: true};
    setAnswers(newAnswers);
    setRadioValue(currentActiveRadioId);
  };

  const handleChangeText = (current: { id: number; value: string }) => {
    const newAnswers = resetAnswers();
    const indexOfChangeItem = newAnswers.findIndex(
      (item) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      newAnswers[indexOfChangeItem] = {
        ...newAnswers[indexOfChangeItem],
        value: current.value,
      };
      setAnswers(newAnswers);
    }
  };

  const handleRemoveOneChoose = (id: number) => {
    const newAnswers = resetAnswers();
    const activeRadioItem = newAnswers.find((item: QuestionAnswerInfo) => {
      return item.isTrue
    });
    if (activeRadioItem) {
      setRadioValue(0);
    }
    setAnswers(newAnswers.filter((item: QuestionAnswerInfo) => {
      return item.id !== id
    }));
  };

  const handleAddMoreChoose = () => {
    if (answers.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(answers) + 1,
        value: '',
        key: findMaxIndexOfArray(answers) + 1,
        isTrue: false,
        point: 0,
        response: ''
      };
      const newAnswers = resetAnswers();
      setAnswers(newAnswers.concat([newItem]));
    } else {
      setAnswers(initialChooseTextQuestionValue);
    }
  };

  useEffect(() => {
    setCurrentQuestionInfo({...answers, questionName: questionName,});
  }, [answers, questionName]);

  useEffect(() => {
    if (dataFromApi && isArray(get(dataFromApi, 'answers', [])) && get(dataFromApi, 'answers', []).length > 0) {
      setQuestionName(get(dataFromApi, 'question', ''));

      const feAnswer = mapAnsBEtoFE(get(dataFromApi, 'answers', []));
      setAnswers(feAnswer);

      const correctAns = feAnswer.find((quiz: any) => quiz.isTrue);
      if (correctAns) {
        setRadioValue(correctAns.id);
      }
    } else {
      setQuestionName(get(dataFromApi, 'question', ''));
      setAnswers([]);
      setRadioValue(0);
    }
  }, [dataFromApi]);


  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const handleDragChange = (value: any) => {
    setAnswers(value);
  };

  return (
    <section className='question-wrapper'>
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionName} onChange={handleChangeQuestionName}/>
      <div className="tree-course-construct list-answers choose-text">
        <Radio.Group
          name="question-text"
          onChange={handleChangeRadio}
          value={radioValue}
          className="w-full"
        >
          <SortableList
            items={answers}
            onChange={handleDragChange}
            renderItem={(record: any, index: number) => (
              <SortableList.Item id={record.id}>
                <SortableList.DragHandle/>
                <QuestionTextItem
                  dataItem={record}
                  valueRadio={record.id}
                  onChange={handleChangeText}
                  onDelete={handleRemoveOneChoose}
                />
              </SortableList.Item>
            )}
          />
        </Radio.Group>
        <Button
          icon={<PlusOutlined/>}
          className='btn-add-more mt-2'
          onClick={handleAddMoreChoose}
        >
          Thêm lựa chọn
        </Button>
      </div>
    </section>
  );
}

export default ChooseText;
