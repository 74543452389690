import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getGroup: (
) => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/system-log/groups`);
};

export const getLisTSystemLogs: ( data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/system-log/search`, data);
};
