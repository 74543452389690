import {
  LogoutOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Spin,
  TreeSelect,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SafeLink from "../../components/link/SafeLink";
import MyNotificationDrop from "../../components/my-notifycation-drop";
import { routesConfig } from "../../config/routes";
import { useViewport } from "../../hooks/useViewport";
import { getDetailAdminInterface } from "../../service/admin-interface";
import { globalStore, useAuthStore } from "../../stores/stores";
import { getContrastingColor } from "../../utils/functionUltils";
import "./header.scss";
import { postLogout } from "./services";

export type HeaderLayoutProps = {
  activeMenu: () => void;
};
interface DataNode {
  uuid: string;
  name: string;
  canManage: boolean;
  children: DataNode[];
  logo: string
}

const HeaderLayout = (props: HeaderLayoutProps) => {
  const logout = useAuthStore((state) => state.logout);

  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState<any>({});
  const [logoPersonal, setLogoPersonal] = useState<any>("");
  const [backgroundColor, setBackgroundColor] = useState<any>({});
  const [logo, setLogo] = useState<any>("");
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const navigate = useNavigate();
  const { reloadAdminInterface, personalInfo, setReload, reload } = globalStore();
  const location = useLocation();
  const pathname = location.pathname;
  const { width } = useViewport();

  useEffect(() => {
    if (width <= 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    getColor();
  }, [navigate, reloadAdminInterface, personalInfo]);

  useEffect(() => {
    getColorDetail();
  }, [navigate, reloadAdminInterface]);

  const logoutFNC = async () => {
    await postLogout().then((res) => {
      if (res.status === 200) {
        logout();
        setTimeout(() => {
          navigate(routesConfig.login);
          setLoading(false);
        }, 1000);
      }
    });
  };

  const getColor = async () => {
    setColor(personalInfo?.color);
    setLogoPersonal(personalInfo?.logo);
  };

  const getColorDetail = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id: any = urlParams.get("interfaceId");
    if (id) {
      await getDetailAdminInterface(id).then((res) => {
        if (res.status === 200) {
          setBackgroundColor(res.data?.data?.color?.backgroundColor);
          setLogo(res.data?.data?.logo);
        }
      });
    }
  };

  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const handleLogout = async () => {
    setLoading(true);
    try {
      logoutFNC();
    } catch (error) {
      logout();
      setLoading(false);
    }
  };

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{ color: "#1677FF", fontSize: "20px" }}>
            <LogoutOutlined />
          </div>
          <span>Xác nhận đăng xuất?</span>
        </div>
      ),
      //   centered: true,
      icon: null,
      okText: "Đăng xuất",
      cancelText: "Hủy",
      onOk() {
        handleLogout();
      },
      onCancel() { },
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <div className="account account-drop">
          <div className="account__avatar">
            <div className="account__avatar__wrapper">
              <a onClick={(e) => e.preventDefault()}>
                <Avatar src={getUserInfo?.anhDaiDien} icon={<UserOutlined />} />{" "}
              </a>
            </div>
          </div>
          <div className="account__name">
            <a
              className="account__name__wrapper"
              onClick={(e) => e.preventDefault()}
            >
              <span>{getUserInfo?.hoVaTen}</span>
              <span className="email">{getUserInfo?.email}</span>
            </a>
          </div>
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <SafeLink
          className="account__menu__item"
          rel="account"
          to={routesConfig.userInfo}
        >
          <UserOutlined />
          <span>Thông tin cá nhân</span>
        </SafeLink>
      ),
    },
    {
      key: "3",
      label: (
        <a className="account__menu__item" rel="settings">
          <SettingOutlined />
          <span>Cài đặt</span>
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a className="account__menu__item border-bottom" rel="helper">
          <QuestionCircleOutlined />
          <span>Trợ giúp</span>
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a className="account__menu__item" rel="log-out" onClick={showConfirm}>
          <LogoutOutlined />
          <span>Đăng xuất</span>
        </a>
      ),
    },
  ];


  const contrastingTextColor = getContrastingColor(color?.menuColor);
  const [value, setValue] = useState<string | undefined>(localStorage.getItem("schoolManagement") || personalInfo?.managedEnterprises?.[0]?.uuid || "");

  const generateTreeData = (nodes: DataNode[]): any[] =>
    nodes?.map((node) => ({
      title: <div> <Avatar shape="square" size="small" src={node?.logo} /> {node.name}</div>,
      value: node.uuid,
      // selectable: node.canManage,
      children: generateTreeData(node.children),
    }));

  useEffect(() => {
    if (!localStorage.getItem("schoolManagement") && personalInfo?.managedEnterprises?.length > 0) {
      localStorage.setItem("schoolManagement", personalInfo?.managedEnterprises[0]?.uuid)
    }
    else {
      localStorage.setItem("schoolManagement", "")
    }
  }, [personalInfo?.managedEnterprises])

  return (
    <>
      <div className="global-header">
        <div
          className="global-header1"
          style={{
            background: `${pathname.includes(routesConfig.detailAdminInterface)
              ? backgroundColor
              : color
                ? color?.backgroundColor
                : "#9F224E"
              }`,
            color: contrastingTextColor,
          }}
        >
          {isMobile ? (
            <Button className="btn-mobile" onClick={props.activeMenu}>
              <MenuOutlined />
            </Button>
          ) : null}
          <div
            className="logo-mooc2 cursor-pointer"
            onClick={() => navigate("/")}
          >
            {logo || logoPersonal ? (
              <img
                src={
                  pathname.includes(routesConfig.detailAdminInterface)
                    ? logo
                    : logoPersonal
                }
                className="w-8 h-8 rounded-lg object-cover"
                alt="logo-mooc"
              />
            ) : (
              <div className="shape2" />
            )}
            <h2 className="mooc2">
              {process.env.REACT_APP_PROJECT_NAME || ""}
            </h2>
          </div>
          <div className="menulegacy flex">
            <ul>
              <li>
                <Link to={"#"} className="active-link--menu">
                  <TableOutlined
                    style={{
                      color: contrastingTextColor,
                    }}
                  />
                  <Typography.Text
                    style={{
                      color: contrastingTextColor,
                      textAlign: "center",
                    }}
                  >

                    Quản lý
                  </Typography.Text>
                </Link>
              </li>

            </ul>
            {personalInfo?.managedEnterprises?.length > 0 &&
              <TreeSelect
                style={{ width: '400px' }}
                value={value}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={generateTreeData(personalInfo?.managedEnterprises)}
                placeholder="Please select"
                treeDefaultExpandAll
                onChange={(newValue) => {
                  setValue(newValue)
                  localStorage.setItem("schoolManagement", newValue);
                  setReload(!reload);
                }}
              />}
          </div>
          <div className="toolbar">
            <div className="icon-wrapper">
              <a href="#">
                <SearchOutlined
                  style={{
                    color: contrastingTextColor,

                    fontSize: "14px",
                  }}
                />
              </a>
            </div>
            <div className="icon-wrapper">
              <a href="#">
                <QuestionCircleOutlined
                  style={{
                    color: contrastingTextColor,
                    fontSize: "14px",
                  }}
                />
              </a>
            </div>
            <MyNotificationDrop />
            <Dropdown
              overlayClassName="header-account"
              trigger={["click"]}
              menu={{ items }}
            >
              <div className="account">
                <div className="account__avatar">
                  <div className="account__avatar__wrapper">
                    <a onClick={(e) => e.preventDefault()}>
                      <Avatar
                        src={getUserInfo?.anhDaiDien}
                        icon={<UserOutlined />}
                      />
                    </a>
                  </div>
                </div>
                <div className="account__name">
                  <a
                    className="account__name__wrapper"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div
                      style={{
                        color: contrastingTextColor,
                      }}
                    >
                      {getUserInfo?.hoVaTen}
                    </div>
                  </a>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        <Spin spinning={loading} fullscreen size="large" />
      </div>
    </>
  );
};

export default HeaderLayout;
