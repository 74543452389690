import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../../config/api/configApiv2";
import { axiosConfigV2OpenAPI } from "../../../config/api/configOpenApiV2";
import { axiosConfigToUpload } from "../../../config/api/configApiUpload";

export const getMoocUniversityById: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-university/${id}`);
};

export const getMoocUniversityType: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-university/get-university-type-by-name`);
};

export const getMoocUniversityTrainingType: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2.get(`/mooc-university/get-training-type-by-name`);
};

export const getMoocOrganization: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-university/get-organization-by-name`);
};

export const downloadUniversityTemplateFile: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2.get(`/mooc-university/download-template`, {
    responseType: "arraybuffer",
  });
};

export const getAddressProvinces: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2OpenAPI.get(`/address/provinces?code=4`);
};

export const getAddressDistrictsByProvinceId: (
  provinceId: string
) => Promise<AxiosResponse<any>> = (provinceId) => {
  return axiosConfigV2OpenAPI.get(`/address/districts?code=${provinceId}`);
};

export const getAddressWardsByDistrictId: (
  districtId: string
) => Promise<AxiosResponse<any>> = (districtId) => {
  return axiosConfigV2OpenAPI.get(`/address/wards?code=${districtId}`);
};

export const getMoocUniversityByFilter: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university/get-by-condition`, data);
};

export const getUniversityrDetailByFilter: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-history/university/get-all-by-filter`, data);
};

export const addMoocUniversity: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-university`, data);
};

export const exportExcelMoocUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university/export-excel`, data, {
    responseType: "arraybuffer",
  });
};

export const exportExcelMoocUniversityDetail: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`mooc-university/export-excel/${id}`, {
    responseType: "blob", // Đảm bảo nhận dữ liệu dưới dạng Blob để tạo file
  });
};

export const importExcelMoocUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.post(`/mooc-university/import-excel`, data, {
    responseType: "arraybuffer",
  });
};

export const updateMoocUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-university`, data);
};

export const changeStatusUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-university/change-status`, data);
};

export const deleteMoocUniversity: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-university/${id}`);
};

export const importApprovedUniversityMoocSynchronizationData: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(
    `/mooc-synchronization-data/approved/university`,
    data
  );
};

export const getUniversityMoocSynchronizationDataByFilter: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/mooc-synchronization-data/search/university`,
    data
  );
};

export const getAvailableUniversities: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-university/get-available-university-to-add-to-group`,
    payload
  );
};
