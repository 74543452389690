import {Button, Col, Form, message, Modal, RadioChangeEvent, Row, Space, TableColumnsType, Typography} from 'antd'
import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from 'react'
import FormInput from '../../form-input/FormInput'
import {CheckCircleOutlined, SearchOutlined} from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import {useNavigate} from 'react-router-dom'
import {renderStatusCourse} from '../../../utils/functionUltils'
import moment from 'moment'
import {getContentBrowseList} from '../../../service/course-construct'
import "./index.scss"
import {putPublishCourse} from '../../../service/content-course/contenCourseApi'

type Props = {
  open: boolean
  handleClose: () => void
  getListDataProps: () => void
}

export default function ReviewMultipleSlideContent(props: Props) {
  const {open, handleClose, getListDataProps} = props
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [listCourseBrowse, setListCourseBrowse] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);

  const navigate = useNavigate()

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const [form] = Form.useForm()

  const handleCheckCourse = (id: string) => {
    // submitData([id]);
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      sorter: true,
      key: "name",
      width: 40,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record?.name}</span>
              <span>{record?.enterpriseName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record) => {
        return (
          <>
            <span className="status-content">
              {renderStatusCourse(record?.slideStatus)}
            </span>
          </>
        );
      },
      width: 20,
    },
    {
      title: "Ngày gửi duyệt",
      dataIndex: "approveSendDate",
      key: "approveSendDate",
      render: (_: any, record) => {
        return (
          <>
            <span>{moment(record?.approveSendDate).format("DD/MM/yyyy")}</span>
          </>
        );
      },
      width: 40,
    },
  ];

  const getListData = () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
    };

    getContentBrowseList(data)
      .then((res) => {
        const {data} = res;
        setListCourseBrowse(data?.content);
        setTotalRecords(data.totalElements);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData()
    }
  };

  const handleSubmitSearch = () => {
    getListData()
  };

  const handleBulkCheckCourse = () => {
    const ids = selectedRowKeys.map((item: any) => item.id);
    submitData(ids);
  };

  const submitData = async (ids: string[]) => {
    const data = {
      courseIds: ids,
      type: 2,
      status: 1
    };
    await putPublishCourse(data).then((result) => {
      message.success(`Duyệt trang trình bày thành công`);
      getListDataProps();
      handleClose()
      setClearRowkey(true)
      setSelectedRowKeys([])
    });
  };

  useEffect(() => {
    getListData();
  }, [open, pageNumber, pageSize]);

  return (
    <div className='review-multiple-slide'>
      <Form.Provider
        onFormFinish={async (name, {values, forms}) => {
          if (name === 'review-multiple-content-modal') {
            if (selectedRowKeys.length >= 1) {
              Modal.confirm({
                title: `Xác nhận duyệt trang trình bày.`,
                content: `Xin vui lòng xác nhận việc duyệt trang trình bày.`,
                onOk: () => {
                  handleBulkCheckCourse();
                },
                okText: "Xác nhận",
                cancelText: "Đóng",
                centered: true,
                icon: <CheckCircleOutlined style={{color: "#1677FF"}}/>,
                footer: (_, {OkBtn, CancelBtn}) => (
                  <>
                    <CancelBtn/>
                    <OkBtn/>
                  </>
                ),
              });
            } else {
              message.warning(`Vui lòng chọn nhiều hóa học`);
            }
          }
        }}
      >
        <Modal
          className="review-multiple-slide-modal"
          title={"Duyệt nhiều trang trình bày khoá học"}
          closeIcon={true}
          centered
          open={open}
          onCancel={handleClose}
          footer={null}
          width={"80%"}
        >
          <Form form={form} name="review-multiple-content-modal">
            <Row gutter={40} className='p-6'>
              <Col
                md={{span: 7}}
                lg={{span: 7}}
                xl={{span: 7}}
                xs={{span: 7}}
              >
                <div className="heading-search">
                  <Typography.Title level={5}>Tìm kiếm khoá học</Typography.Title>
                  <FormInput
                    placeholder="Nhập từ khoá cần tìm"
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearch}
                    afterPrefixIcon={
                      <SearchOutlined onClick={handleSubmitSearch}/>
                    }
                  />
                </div>
              </Col>
              <Col md={{span: 17}} lg={{span: 17}} xl={{span: 17}} xs={{span: 17}} className=''>
              </Col>
              <Col md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xs={{span: 12}} className='mt-2'>
                <Typography.Title level={5} className='text-left'>Danh sách khoá học</Typography.Title>
              </Col>
              <Col md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xs={{span: 12}} className='mt-2'>
                <Typography.Title level={5} className='text-right'>Đã
                  chọn {selectedRowKeys.length}/{totalRecords}</Typography.Title>
              </Col>

              <Col md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xs={{span: 24}}>
                <TableData
                  dataColumns={dataColumns}
                  loadingTable={loading}
                  dataTable={listCourseBrowse}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  scroll={{x: 1500, y: 800}}
                  pagination={true}
                  hideSelection={true}
                  handleTableChangeProps={() => {
                  }}
                  setSelectedRowKeysProps={setSelectedRowKeys}
                  clearRowkey={clearRowkey}
                />
              </Col>
              <Col md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xs={{span: 24}}>

                <div className='mt-9'>
                  <Space className='float-right'>
                    <Button onClick={handleClose} className="btn btn-outlined">
                      <Typography.Text>
                        Hủy bỏ
                      </Typography.Text>
                    </Button>
                    <Button className="btn btn-primary" onClick={() => form.submit()}
                    >
                      <Typography.Text>
                        Xác nhận
                      </Typography.Text>
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Form.Provider>
    </div>
  )
}
