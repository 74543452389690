import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, TableColumnType, Typography } from 'antd';

import './filter-table.scss'
import { DownOutlined, PlusOutlined, SearchOutlined, SettingOutlined, UserAddOutlined } from "@ant-design/icons";

import FormInput from '../../form-input/FormInput';
import { Permission } from '../../../layout/sidebar/permission.enum';
import { CheckRole, getPermissions } from '../../../utils/checkRole';
import FormItemDropdown from '../../form-dropdown/FormItemDropdown';


type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  tab: number,
  handleChangeInput: (e: any) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  dataColumns: TableColumnType<any>[]
  selectedRowKeys: React.Key[]
  displayAddPortalPage: boolean
  dataOptions?: any;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
}


const FilterTableSystemLogs: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  tab,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  displayAddPortalPage,
  setFilterData,
  dataOptions
}) => {

  return (
    <>
      <div className="heading-extra">
        <Row gutter={8}>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
            <div className="heading-search">
              <FormItemDropdown className='w-[200px]' allowClear={true} placeholder="Chọn nhật ký" onChange={(option) => setFilterData(option, 'group')}
                options={dataOptions.dataGroup} />
            </div>
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa..." value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
        </Row>
      </div >
    </>
  );
};

export default FilterTableSystemLogs;
