import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState, } from "react";
import {
  Button,
  Dropdown,
  Form,
  FormInstance,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import { formatDateV2 } from "../../../utils/converDate";
import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  SaveOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import FilterSurveyPlans
  from "../../../components/course-construction/survey-plan/filter-survey-plans/FilterSurveyPlans";
import "./index.scss";
import FilterForm from "../../../components/course-construction/survey-plan/filter-form/FilterForm";
import { routesConfig } from "../../../config/routes";

import DrawerCustom from "../../../components/drawer/Drawer";
import {
  createDuplicateSurveyPlan,
  createSurveyPlan,
  deleteSurveyPlan,
  getDataSurveyPlan,
  getObjectSurveyLookup,
  getSurveyPlan,
  getTargetSurveyLookup,
  updateSurveyPlan,
} from "../../../service/course-construct/survey-plan";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import { validateFormCreate } from "./constants/data";
import { getCurriculum, getIndustry, getUniversity, } from "../../../service/common";
import { postCourseSearch } from "../../../service/course-construct";
import { convertArrNumToString, convertStringToArrayNum } from "../../../utils/arrays";
import FormCreateSurvey from "../../../components/course-construction/survey-plan/form-create-survey/FormCreateSurvey";
import { isEmpty } from "lodash";
import { globalStore } from "../../../stores/stores";
import { Permission } from "../../../layout/sidebar/permission.enum";

type SurveyPlanProps = {
  title?: string;
};


const SurveyPlan = ({ title }: SurveyPlanProps) => {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedDelete, setIsOpenedDelete] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  
  const [listSurveyPlans, setListSurveyPlan] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [idDelete, setIdDelete] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalData, setTotalData] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;
  const [dataDetail, setDataDetail] = useState({
    id: undefined,
    name: undefined,
    code: undefined,
    timeStart: undefined,
    timeEnd: undefined,
    surveyMethod: true,
    targetType: undefined,
    targetValue: undefined,
    targetDescription: undefined,
    applicatbleObjectKey: true,
    applicatbleObjectSchools: undefined,
    applicatbleCourseRegister: undefined,
    applicatbleCourseCompleted: undefined,
    applicatbleIndustry: undefined,
  });
  
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterData, setFilterData] = useState<any>({
    timeStart: null,
    timeEnd: null,
    currentDate: null,
    surveyTarget: null,
    applicatbleObjectKeys: null,
    statusSurveys: null,
  });

  const [dataOptions, setDataOption] = useState({
    dataSurveyTarget: [],
    dataSurveyCurriculum: [],
    dataSurveyObject: [],
    dataUniversity: [],
    dataCourseRegistration: [],
    dataCourseCompleted: [],
    dataSpecialized: [],
  });
  
  const options: MenuProps["items"] = [
    ...(permissions?.includes(Permission.CreateCourseSurveyPlanUpdate)
    ? [
        {
          key: "1",
          label: "Sửa khảo sát",
          icon: <EditOutlined />,
        },
      ]
    : []),
    
    // {
    //   key: "2",
    //   label: "Trình phát",
    //   icon: <PlayCircleOutlined/>,
    // },
    {
      key: "3",
      label: "Tạo bản sao",
      icon: <CopyOutlined />,
    },
    {
      key: "4",
      label: "Xóa",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const [dataOptionSurveyCourse, setDataOptionSurveyCourse] = useState([])

  const [form] = useForm();

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const getDataSurvey = () => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      surveyMethods: filterData.surveyTarget ? filterData.surveyTarget : [],
      applicatbleObjectKeys: filterData.applicatbleObjectKeys
        ? filterData.applicatbleObjectKeys
        : [],
      statusSurveys: filterData.statusSurveys ? filterData.statusSurveys : [],
      timeStart: filterData.timeStart !== null ? filterData.timeStart : null,
      timeEnd: filterData.timeEnd !== null ? filterData.timeEnd : null,
      sortBy: !isEmpty(statusOrder) ? statusOrder : ""
    };
    getDataSurveyPlan(dataReq)
      .then((res) => {
        const { data } = res.data;
        setListSurveyPlan(data.content);
        setTotalRecords(data.totalElements);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const getSurveyPlanUpdate = (id: number) => {
    getSurveyPlan(id)
      .then((res) => {
        const { data } = res.data;
        setDataDetail(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleReturnStatus = (statusCode: string) => {
    switch (statusCode) {
      case "in_process":
        return "Đang tiến hành";
      case "not_started":
        return "Chưa tiến hành";
      case "finish":
        return "Hết hạn";
      default:
        return "";
    }
  };
  const deleteSurvey = (id: number) => {
    if (id > 0) {
      deleteSurveyPlan(id)
        .then((res) => {
          const { success } = res.data;
          if (success) {
            message.success("Xoá kế hoạch khảo sát thành công!");
            getDataSurvey()
          }
          if (!success) {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setIsOpenedDelete(false);
    }
  };


  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      sorter: true,
      width: 2,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder}</span>
        );
      },
    },
    {
      title: "Tiêu đề",
      dataIndex: "name",
      key: "name",
      width: 12,
      sorter: true,
      ellipsis: true,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name cursor-pointer">
            <div>
              <a
                className="course-title"
                onClick={() =>
                  navigate(`${routesConfig.surveyPlanDetail}/${record?.id}`, {
                    state: {
                      mode: "edit",
                      record,
                      name: record.name,
                      idEdit: record?.id,
                    },
                  })
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "timeStart",
      key: "timeStart",
      width: 5,
      render: (_: any, record, index: number) => {
        return record.timeStart ? formatDateV2(record.timeStart) : null;
      },
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "timeEnd",
      key: "timeEnd",
      width: 5,
      render: (_: any, record, index: number) => {
        return record.timeEnd ? formatDateV2(record.timeEnd) : null;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "statusSurvey",
      key: "statusSurvey",
      sorter: true,
      width: 5,
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.statusSurvey === "in_process"
                    ? "#52C41A"
                    : record.statusSurvey === "not_started"
                      ? "#1677FF"
                      : record.statusSurvey === "finish"
                        ? "#D9D9D9"
                        : "white",
              }}
            ></div>
            {handleReturnStatus(record?.statusSurvey)}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: 5,
      render: (_: any, record) => {
        return (
          <>{record.createdDate ? formatDateV2(record.createdDate) : null}</>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  // xem trước khóa học

                  navigate(
                    `${routesConfig.surveyPlanDetail}/${location.id}`,
                    {
                      state: { record, mode: "view" },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: options,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      getSurveyPlanUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    // if (key === "2") {
                    //   setIsOpenSyncCourseModal(true);
                    //   setBrowseData(record);
                    // }
                    if (key === "3") {
                      Modal.confirm({
                        title: `Tạo bản sao kế hoạch`,
                        content: `Bạn chắc chắn muốn tạo bản sao kế hoạch khảo sát này?`,
                        // onOk: () => copyData(record),
                        okText: "Tạo bản sao",
                        cancelText: "Huỷ",
                        centered: true,
                        onOk: () => {
                          createDuplicateSurveyPlan(record.id)
                            .then((r) => {
                              const { success } = r.data;
                              if (success) {
                                message.success(
                                  "Tạo bản sao kế hoạch thành công!"
                                );
                              }
                              if (!success) {
                                message.error(r.data.message);
                              }
                              getDataSurvey();
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        },
                        icon: <CopyOutlined style={{ color: "#1677FF" }} />,
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "4") {
                      if (record.status !== 4) {
                        setIsOpenedDelete(true)
                        setIdDelete(record.id)
                      } else {
                        message.warning("Không thể xóa khảo sát đã xuất bản.");
                      }
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 3,
    },
  ];

  useEffect(() => {
    Promise.all([
      getObjectSurveyLookup(),
      getTargetSurveyLookup(),
      getUniversity(),
      getIndustry(),
      getCurriculum({}),
      postCourseSearch({})
    ])
      .then((res) => {
        const dataObj = res[0].data.data;
        const dataTarget = res[1].data.data;
        const dataUniversities = res[2].data.data;
        const dataIndustries = res[3].data.data;
        const dataCurriculum = res[4].data.data.curriculumsData;

        setTotalData(res[5].data.data.totalRecords);
        setDataOption({
          ...dataOptions,
          dataSurveyObject: dataObj,
          dataSurveyTarget: dataTarget,
          dataUniversity: dataUniversities,
          dataSpecialized: dataIndustries,
          dataSurveyCurriculum: dataCurriculum,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    getDataSurvey();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  useEffect(() => {
    postCourseSearch({ pageSize: totalData, status: [13] }).then((res) => {
      setDataOptionSurveyCourse(res.data.data.data);
    }).catch((err) => {
      console.error(err)
    })
  }, [totalData])

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "name,asc"
        : sorter.order === "descend"
          ? "name,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [editId, setEditId] = useState<null | number>(null);


  // biến để reset data của option khi tắt form
  const [checkCloseData, setCheckCloseData] = useState(false);

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);


  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        planTitle: dataDetail.name,
        planCode: dataDetail.code,
        executionTime: dataDetail.timeStart && dataDetail.timeEnd ? [dayjs(dataDetail.timeStart), dayjs(dataDetail.timeEnd)] : null,
        surveyMethod: dataDetail.surveyMethod,
        surveyTarget: dataDetail.targetType ? dataDetail.targetType : null,
        course:
          dataDetail.targetValue && dataDetail.targetType === 1
            ? convertStringToArrayNum(dataDetail.targetValue)
            : null,
        program:
          dataDetail.targetValue && dataDetail.targetType === 2
            ? convertStringToArrayNum(dataDetail.targetValue)
            : null,
        description: dataDetail.targetDescription,
        surveyObject: dataDetail.applicatbleObjectKey,
        universityCheckbox: !!dataDetail.applicatbleObjectSchools,
        universityList: dataDetail.applicatbleObjectSchools
          ? convertStringToArrayNum(dataDetail.applicatbleObjectSchools)
          : null,
        registerCourseCheckbox: !!dataDetail.applicatbleCourseRegister,
        registerCourseList: dataDetail.applicatbleCourseRegister
          ? convertStringToArrayNum(dataDetail.applicatbleCourseRegister)
          : null,
        completedCourseCheckbox: !!dataDetail.applicatbleCourseCompleted,
        completedCourseList: dataDetail.applicatbleCourseCompleted
          ? convertStringToArrayNum(dataDetail.applicatbleCourseCompleted)
          : null,
        specializedCheckbox: !!dataDetail.applicatbleIndustry,
        specializedList: dataDetail.applicatbleIndustry
          ? convertStringToArrayNum(dataDetail.applicatbleIndustry)
          : null,
      });
    }
  }, [dataDetail]);

  const handleOpenFilter = () => {
    setIsShowFilter((prevState) => !prevState);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  // Thêm mới kế hoạch
  const handleOpenCreate = () => {
    setIsOpened(true);
    setCheckCloseData(false);
  };

  const handleSubmitSearch = () => {
    getDataSurvey();
  };

  const onFinish = (values: any) => {
    setFilterData({
      ...filterData,
      timeStart: values?.rangeDate && values?.rangeDate[0] !== null
        ? dayjs(values?.rangeDate[0]).toISOString()
        : null,
      timeEnd: values?.rangeDate && values?.rangeDate[1] !== null
        ? dayjs(values?.rangeDate[1]).toISOString()
        : null,
      surveyTarget: values.surveyTarget,
      applicatbleObjectKeys: values.surveyObject,
      statusSurveys: values.statusSurveys,
    });
  };

  const onFinishCreate = (values: any) => {
    if (Object.keys(values).length > 0) {
      const dataPayload = {
        title: values.planTitle,
        code: values.planCode,
        surveyMethod: Boolean(values.surveyMethod),
        timeStart: values.executionTime
          ? values.executionTime[0].toISOString()
          : "",
        timeEnd: values.executionTime
          ? values.executionTime[1].toISOString()
          : "",
        targetType: values.surveyTarget,
        targetValue:
          values.course || values.program
            ? convertArrNumToString(values.course || values.program)
            : "",
        targetDescription: values.description ? values.description : "",
        objectKey: Boolean(values.surveyObject),
        objectSchools: values.universityCheckbox ? (values.universityList
          ? convertArrNumToString(values.universityList) : "")
          : "",
        courseRegister: values.registerCourseCheckbox ? (values.registerCourseList
          ? convertArrNumToString(values.registerCourseList) : "")
          : "",
        courseCompleted: values.completedCourseCheckbox ? (values.completedCourseList
          ? convertArrNumToString(values.completedCourseList) : "")
          : "",
        industry: values.specializedCheckbox ? (values.specializedList
          ? convertArrNumToString(values.specializedList) : "")
          : "",
      };
      if (isOpenedEdit && editId) {
        updateSurveyPlan(editId, dataPayload)
          .then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Câp nhật thành công");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại");
            }
            handleClose();
            getDataSurvey();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        createSurveyPlan(dataPayload)
          .then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới thành công");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại");
            }

            handleClose();
            getDataSurvey();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };
  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    setEditId(null);
    setCheckCloseData(true);
    form.resetFields();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getDataSurvey();
    }
  };
  // Chỉnh sửa kế hoạch
  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
    setCheckCloseData(false);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      timeStart: "",
      endStart: "",
      surveyTarget: [],
      surveyObject: [],
      statusSurveys: [],
    });
  };
  return (
    <Layout className="page-header-group survey-plan-index">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createSurveyPlan") {
            const { createSurveyPlan } = forms;
            const data = createSurveyPlan.getFieldsValue(validateFormCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterSurveyPlans
              handleOpen={handleOpenCreate}
              handleOpenFilter={handleOpenFilter}
              handleChangeInput={handleChangeInput}
              handleSubmitSearch={handleSubmitSearch}
              showFilter={isShowFilter}
              searchValue={searchValue}
              handleSearch={handleSearch}
            />
          </div>
          <FilterForm
            showFilter={isShowFilter}
            onFinish={onFinish}
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            filterData={filterData}
          />
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listSurveyPlans}
            dataColumns={newColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <DrawerCustom
          title={`${isOpenedEdit ? "Chỉnh sửa kế hoạch" : "Thêm mới kế hoạch"}`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <SaveOutlined />
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateSurvey
            checkCloseData={checkCloseData}
            form={form}
            dataOptionsSurvey={dataOptionSurveyCourse}
            dataOptions={dataOptions}
            dataDetail={dataDetail}
            isOpenEdit={isOpenedEdit}
          />
        </DrawerCustom>
      </Form.Provider>
      <Modal width={420} wrapClassName="modal-delete" open={isOpenedDelete} closable={false} title={
        <div className="title-delete">
          <WarningOutlined style={{ color: "#FF4D4F" }} />
          <Typography.Title level={5}>
            Xoá kế hoạch khảo sát
          </Typography.Title>
        </div>
      }
        okText={"Xác nhận"}
        okButtonProps={
          { className: "btn-filled--danger", }
        }
        cancelText="Huỷ"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
        onOk={() => deleteSurvey(idDelete)} onCancel={() => setIsOpenedDelete(false)}>
        <p>
          Dữ liệu đã xoá sẽ không thể khôi phục. Bạn chắc chắn muốn xoá kế hoạch khảo sát?
        </p>
      </Modal>
    </Layout>
  );
};

export default SurveyPlan;

