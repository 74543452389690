import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Row,
  Typography,
} from "antd";
import React, { FunctionComponent } from "react";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { get } from "lodash";

type FilterFormProps = {
  showFilter?: boolean;
  clearFilter: () => void;
  onFinish?: any;
  dataOptions?: any;
  form: FormInstance;
};

const FilterForm: FunctionComponent<FilterFormProps> = ({
    onFinish,
    showFilter,
    clearFilter,
    dataOptions,
    form,
  }) => {
  
    return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form name="filterForm" onFinish={onFinish} form={form}>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Chủ đề</Typography.Title>
              <Form.Item name="topic">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn chủ đề" options={get(dataOptions, 'dataTopic')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Phân cấp</Typography.Title>
              <Form.Item name="level">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn phân cấp" options={get(dataOptions, 'dataLevel')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Đơn vị xuất bản</Typography.Title>
              <Form.Item name="unit">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn đơn vị xuất bản" options={get(dataOptions, 'dataUnit')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Tác giả</Typography.Title>
              <Form.Item name="author">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn tác giả" options={get(dataOptions, 'dataAuthor')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Thời gian</Typography.Title>
              <Form.Item name="time">
                <DatePicker.RangePicker allowEmpty={[true, true]} placeholder={["Bắt đầu", "Kết thúc"]} format='DD/MM/YYYY' />
              </Form.Item>
            </Col>
            <Col xl={{span: 3}} md={{span: 9}} sm={{span: 9}} xs={{span: 24}} className="flex align-end">
              <div className="group-btn--filter">
                <Button className="btn btn-primary filter-btn" htmlType="submit">
                  <FilterOutlined/>
                  <Typography.Text> Lọc </Typography.Text>
                </Button>
                <Button className="btn btn-outlined clear-filter-btn" onClick={() => clearFilter()}>
                  <DeleteOutlined/>
                  <Typography.Text> Xóa bộ lọc </Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
    // </ConfigProvider>
  );
};

export default FilterForm;
