import { Form, Layout, TableColumnsType, TableProps, } from "antd";
import { KeyboardEvent, useEffect, useState, } from "react";
import PageHeader from "../../components/page-header/PageHeader";
import { useNavigate } from "react-router-dom";
import { formatOriginTimev3 } from "../../utils/converDate";
import { routesConfig } from "../../config/routes";
import TableData from "../../components/table-data/TableData";
import { CheckRole, getPermissions } from "../../utils/checkRole";
import { Permission } from "../../layout/sidebar/permission.enum";
import FilterTableSystemLogs from "../../components/system-logs/filter-table/FilterTable";
import { getGroup, getLisTSystemLogs } from "../../service/system-logs";
import "../../styles/_variables.scss";
import "./index.scss";
import { globalStore } from "../../stores/stores";

type ContentConstructionProps = {
  title?: string;
};

const SystemLogsPage = (props: ContentConstructionProps) => {
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listSystemLogs, setListSystemLogs] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [idDetail, setIdDetail] = useState(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const [filterData, setFilterData] = useState<any>({
    group: null,
  });

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const [dataOptions, setDataOptions] = useState({
    dataGroup: [],
  });

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [listPermission, setListPermission] = useState([]);
  const [displayAddPortalPage, setDisplayAddPortalPage] = useState(false);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params))
    setListPermission(listPermissionConvert)
  }


  useEffect(() => {
    getRoles()
  }, [])

  useEffect(() => {
    const roleAddPortalPage = [
      Permission.PortalManagementAddNewPortalUpdate,
    ]
    const checkAddPortalPage = CheckRole(roleAddPortalPage, listPermission)
    if (checkAddPortalPage.includes(true)) {
      setDisplayAddPortalPage(true)
    }
  }, [listPermission])

  const getListData = async () => {
    setLoading(true)
    const data = {
      keyword: searchValue,
      page: pageNumber,
      size: pageSize,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      groupId: filterData.group
    }
    await getLisTSystemLogs(data).then((res) => {
      if (res.status === 200) {
        setListSystemLogs(res?.data.data.data)
        setTotalRecords(res?.data.data.total)
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    }).catch((err) => {
      if (err.response?.status === 403) {
        navigate("/error/403");
      } else {
        console.error(err);
      }
      setLoading(false);
      console.error(err);
    }).finally(() => {
      setLoading(false);
    })
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
  };

  const getSystemLogGroup = async () => {
    await getGroup().then((res) => {
      if (res.status === 200) {
        setDataOptions({
          ...dataOptions,
          dataGroup: res?.data
        })
      }
    })
  }

  useEffect(() => {
    getSystemLogGroup()
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder, filterData.group]);

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const gotoDetail = (record: any) => {
    navigate(`${routesConfig.detailPortalAdministration}/${record?.enterpriseId}`, {
      state: { mode: "detail", name: record?.name },
    });
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "Số thứ tự",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder ?? 1}</span>
        );
      },
    },
    {
      title: "Ngày phát sinh",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 5,
      render: (_: any, record) => {
        return (
          <>{record.createdDate ? formatOriginTimev3(record.createdDate) : null}</>
        );
      },
    },
    {
      title: "Nhóm nhật ký",
      dataIndex: "logGroup",
      key: "logGroup",
      width: 13,
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      width: 5,
    },
    {
      title: "Đối tượng thực hiện",
      dataIndex: "user",
      key: "user",
      width: 5,
    },
    {
      title: "Mức độ quan trọng",
      dataIndex: "createdPortalDate",
      key: "createdPortalDate",
      width: 5,
      render: (_: any, record) => {
        return (
          <div className="flex items-center gap-2">
            <div className="table-status">
              <div
                className="color-icon"
                style={{
                  backgroundColor:
                    record.level === 'Trung bình'
                      ? "#1677FF"
                      : record.level === 'Không quan trọng'
                        ? "gray" : 
                        record.level === 'Quan trọng' ?
                        "orange" : 'red'
                }}
              ></div></div>
            {record?.level}
          </div>
        );
      },
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      width: 5,
    },
  ];

  const handleOpenCreate = () => {
    // add course here
    setIdDetail(0);
    setIsOpened(true);
  };

  const listBreadcrumb = [
    {
      title: 'Quản trị vai trò',
    },
  ]

  return (
    <Layout className="page-header-group portal-adminstration">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {

        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTableSystemLogs
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              displayAddPortalPage={displayAddPortalPage}
              tab={tab}
              setFilterData={handleChangeFilter}
              dataOptions={dataOptions}
            />
          </div>
          <div className={`wrapper-filter`}>
          </div>
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listSystemLogs}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      </Form.Provider>

    </Layout>
  );
};

export default SystemLogsPage;
