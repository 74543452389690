import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react';
import { Button, Space, Typography} from 'antd';
import { DownOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import FormInput from '../../../../../components/form-input/FormInput';
import './filter-table.scss';

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
}

const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
}) => {

  return (
    <>
      <div className="filter-table-academic-level">
        <div className="heading-item heading-filter">
          <Button className="filter-button" onClick={handleOpenFilter}>
            <Typography.Text> Bộ lọc </Typography.Text>
            <DownOutlined />
          </Button>
        </div>
        <div className="heading-item heading-search">
          <FormInput placeholder="Nhập tên để tìm kiếm" value={searchValue}
            onChange={handleChangeInput} onKeyPress={handleSearch}
            afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
        </div>
        <div className="heading-item heading-resigter">
          <Button className='btn-add-new' onClick={handleOpen}>
            <Space> Thêm mới <PlusOutlined /> </Space>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FilterTable;
