import {fetchPublicKey} from "../service/auth";
import forge from 'node-forge';
import {isEmpty} from "lodash";

export const encryptPassword = async (data: string): Promise<string> => {
  try {
    const publicKeyPem = await fetchPublicKey();

    // Parse the public key from PEM
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encrypted = publicKey.encrypt(data);

    if (isEmpty(encrypted)) {
      return ''
    }


    // Encode the encrypted data in Base64
    return forge.util.encode64(encrypted);
  } catch (error) {
    console.error('Encryption failed:', error);
    throw error;
  }
}
