import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface SimpleData {
  type: number;
  criteria: string;
  count: number;
  percentage: number;
}

interface ComplexValue {
  count: number;
  percentage: number;
  criteria: string;
}

interface ComplexData {
  type: number;
  criteria: string;
  values: ComplexValue[];
}

interface BarChartProps {
  title?: string;
  data: (SimpleData | ComplexData)[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  colors?: string[];
  showLegend?: boolean;
  seriesDataName?: string;
  hasTotal?: boolean;
  seriesTotal?: number[] | null;
  totalData?: number | null;
}

const BarChart = forwardRef((props: BarChartProps, ref) => {
  const {
    title,
    data,
    xAxisTitle,
    yAxisTitle,
    colors,
    showLegend = false,
    seriesDataName,
    seriesTotal,
    hasTotal = true,
    totalData,
  } = props;

  const isComplexData = (
    item: SimpleData | ComplexData
  ): item is ComplexData => {
    return (item as ComplexData)?.values !== undefined;
  };

  let categories: string[] = [];
  let seriesData: any[] = [];
  let totalCounts = 0;

  if (isComplexData(data[0])) {
    categories = data.map((item) => item.criteria);

    const seriesMap: { [key: string]: number[] } = {};

    data.forEach((item) => {
      if (isComplexData(item)) {
        item?.values.forEach((value) => {
          if (!seriesMap[value.criteria]) {
            seriesMap[value.criteria] = [];
          }
          seriesMap[value.criteria].push(value.count);
          totalCounts += value.count;
        });
      }
    });

    seriesData = Object.keys(seriesMap).map((key, index) => ({
      name: seriesTotal ? `${key} (${seriesTotal[index]})` : key,
      data: seriesMap[key],
      borderRadius: 4,
    }));
  } else {
    categories = data.map((item) => (item as SimpleData).criteria);
    seriesData = [
      {
        name: seriesDataName,
        data: data.map((item) => {
          totalCounts += (item as SimpleData).count;
          return (item as SimpleData).count;
        }),
        borderRadius: 4,
      },
    ];
  }

  const options: Highcharts.Options = {
    chart: {
      type: "bar",
      scrollablePlotArea: {
        minHeight: data?.length * 54,
        scrollPositionX: 1,
      },
    },
    title: {
      text: hasTotal
        ? `<div class="static-chart-total">Tổng số: <span class="static-chart-count">${totalData ?? totalCounts}</span></div`
        : title || "",
    },
    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: "12px",
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        useHTML: true,
        formatter: function () {
          return `<span title="${this.value}">${this.value}</span>`;
        },
      },
      allowDecimals: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxisTitle,
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
      allowDecimals: false,
    },
    tooltip: {
      formatter: function () {
        if (this.point) {
          const colorDot = `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${this.point.color};margin-right:5px;"></span>`;
          return `<b style="color: #000; font-size: 12px; font-weight: 500;opacity: 0.45;">${this.point.category}</b>
           <br/>
          <div style="color: #000; font-size: 12px; font-weight: 500;margin-top:6px;">
          ${colorDot}${this.point.y}</div>`;
        }
        return "";
      },
      backgroundColor: "#ffffff",
      borderColor: "#ccc",
      borderRadius: 6,
      borderWidth: 1,
      style: {
        color: "#333",
        fontSize: "12px",
      },
      shadow: false,
      useHTML: true,
      outside: true,
    },
    plotOptions: {
      bar: {
        pointWidth: 20,
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      itemStyle: { fontSize: "12px" },
      symbolHeight: 12,
      symbolWidth: 12,
      enabled: showLegend,
    },
    series: seriesData as Highcharts.SeriesOptionsType[],
    credits: {
      enabled: false,
    },
    colors: colors,
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          width: 1000,
        },
        yAxis: {
          labels: {
            useHTML: true,
          },
        },
      },
    },
  };

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useImperativeHandle(ref, () => ({
    getChart: () => chartRef.current?.chart,
  }));

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
});

export default BarChart;
