import React, { FunctionComponent, useEffect, useState } from "react";
import { GetProp, Pagination, PaginationProps, Table, TableColumnType, TablePaginationConfig, TableProps, } from "antd";

import "./table-data.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FilterValue, SorterResult, TableCurrentDataSource, } from "antd/es/table/interface";
import { FormInstance } from "antd/lib";

type TableDataProps = {
  dataTable?: Array<any>;
  dataColumns?: TableColumnType<any>[];
  totalRecords?: number;
  pageNumber?: number;
  pageSize?: number;
  scroll?: { x: number; y: number };
  loadingTable?: boolean;
  checkEdit?: boolean;
  hideSelection?: boolean;
  rowSelected?: any;
  pagination?: boolean;
  clearRowkey?: boolean;
  selectOneRow?: boolean;
  unit?: string;
  localeProp?: any;
  setSelectedRowKeysProps?: (selectedRow: React.Key[]) => void;
  setPageNumber?: (pageNumber: number) => void;
  setPageSize?: (pageNumber: number) => void;
  handleTableChangeProps: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => void;
  form?: FormInstance;
  searchValue?: string | null;
};

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

const locale = {
  emptyText: (
    <span>
      <p>
        <img
          className="image-empty-data"
          src="/images/empty-img-gray.png"
          alt="empty-img"
        ></img>
      </p>
      <p className="nodata-text">Không tìm thấy</p>
    </span>
  ),
  triggerDesc: "Sắp xếp theo thứ tự Z-A",
  triggerAsc: "Sắp xếp thứ tự A-Z",
  cancelSort: "Huỷ sắp xếp",
};

const TableData: FunctionComponent<TableDataProps> = ({
  dataTable,
  dataColumns,
  totalRecords,
  loadingTable,
  checkEdit,
  rowSelected,
  scroll,
  setPageNumber,
  setPageSize,
  pageNumber,
  handleTableChangeProps,
  pageSize,
  hideSelection,
  pagination,
  selectOneRow,
  unit,
  setSelectedRowKeysProps,
  clearRowkey,
  form,
  searchValue,
  localeProp,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [totalPage, setTotalPage] = useState(totalRecords);

  useEffect(() => {
    setTotalPage(totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    if (clearRowkey) {
      setSelectedRowKeys([])
    }
  }, [clearRowkey]);

  useEffect(() => {
    if (rowSelected && rowSelected.length === 0) {
      setSelectedRowKeys([])
    }
  }, [rowSelected])

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      // setSelectedRowKeys(selectedRowKeys)
      if (selectOneRow) {
        if (selectedRowKeys.length > 0 && newSelectedRowKeys.length > 0 && selectedRowKeys[0] === newSelectedRowKeys[0]) {
          newSelectedRowKeys = [];
          selectedRows = [];
        }
      }
      setSelectedRowKeys(newSelectedRowKeys);
      if (setSelectedRowKeysProps) {
        setSelectedRowKeysProps(selectedRows);
      }
      if (form) {
        form.setFieldsValue({ nguoiDungs: newSelectedRowKeys });
      }
    },

    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
    preserveSelectedRowKeys: true,
  };

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalPage(totalRecords);
    }
  };

  const locale = localeProp || {
    emptyText: (
      <span className="empty-table">
        <p>
          <img
            className="image-empty-data"
            src="/images/empty-img-gray.png"
            alt="empty-img"
          ></img>
        </p>
        <p className="nodata-text">
          Không tìm thấy{searchValue && ` kết quả với từ khóa "${searchValue}"`}
        </p>
      </span>
    ),
    triggerDesc: "Sắp xếp theo thứ tự Z-A",
    triggerAsc: "Sắp xếp thứ tự A-Z",
    cancelSort: "Huỷ sắp xếp",
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => {
    handleTableChangeProps(pagination, filters, sorter, extra);
  };

  const _dataSource = dataTable?.map((row, index) => {
    return {
      ...row,
      numericalOrder: pagination && pageSize && pageNumber
        ? pageSize * (pageNumber - 1) + index + 1
        : index + 1,
    };
  });

  return (
    <div className="table-wrapper">
      <Table
        size="middle"
        tableLayout="fixed"
        rowSelection={
          hideSelection
            ? { type: selectOneRow ? "radio" : "checkbox", ...rowSelection, columnWidth: 5 }
            : undefined
        }
        columns={dataColumns}
        loading={loadingTable}
        className="table-data"
        locale={locale}
        dataSource={_dataSource}
        pagination={false}
        scroll={scroll}
        rowKey={(obj) => obj.id}
        onChange={handleTableChange}
      />
      {pagination ? (
        <Pagination
          total={totalPage}
          showSizeChanger
          pageSize={pageSize}
          current={pageNumber}
          className="pagination-table"
          onChange={onChange}
          itemRender={itemRender}
          locale={{
            items_per_page: "/ trang",
            jump_to: "Đi đến trang",
            page: "",
          }}
          showQuickJumper
          showTotal={(total) =>
            (selectedRowKeys && selectedRowKeys.length) > 0
              ? `Đã chọn ${selectedRowKeys.length}/${total} ${unit ? unit : `tài khoản`}`
              : `Tổng số ${total} bản ghi`
          }
        />
      ) : null}
    </div>
  );
};

export default TableData;
