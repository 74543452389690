import { Layout, Tabs, TabsProps } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import SafeLink from "../../../components/link/SafeLink";
import PageHeader from "../../../components/page-header/PageHeader";
import { routesConfig } from "../../../config/routes";
import { useDirty } from "../../../contexts/DirtyProvider";
import { usePreventRouteChange } from "../../../hooks/usePreventRouteChange";
import { getDetailEnrolledClass } from "../../../service/course-construct";
import InfoTab from "./info/info";
import ListStudentTab from "./list-student/list-student";
import "./registration-class.scss";
import RouteTab from "./route/route";

const { Content } = Layout;
const imgPath = "/images/";

export type RegistrationClassTabProps = {
  currentTab: string;
  setCurrentTab?: React.Dispatch<React.SetStateAction<string>>
  dataDetail?: DetailRegistrationClassProps
  setCheckChange?: any
};

export type DetailRegistrationClassProps = {
  closedDate?: string;
  closedTime?: string;
  closingDate?: string;
  closingTime?: string;
  courseId?: number;
  createdDate?: string;
  enrolledDate?: string;
  enrolledTime?: string;
  expectedTime?: number;
  expectedTimeType?: number;
  form?: number;
  id?: number;
  instructorTeachers?: string;
  isDeleted?: string;
  name?: string;
  numberOfSession?: number;
  numberStudent?: number;
  openingDate?: string;
  openingTime?: string;
  status?: number;
  courseName?: string;
};

const RegistrationClassPage: FunctionComponent = () => {
  const { confirmNavigation } = usePreventRouteChange();
  const location = useLocation();
  const { classId } = useParams();
  const [dataDetail, setDataDetail] = useState<DetailRegistrationClassProps>({});
  const [tab, setTab] = useState<string>("1");
  const [nextTab, setNextTab] = useState<string>("");

  const [checkChange, setCheckChange] = useState(0)
  const { isDirty, setDirty } = useDirty();
  const listBreadcrumb = [
    {
      title: (<span>Xây dựng khóa học</span>),
    },
    {
      title: (
        <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}`}>
          <span>Xây dựng Nội dung</span>
        </SafeLink>
      ),
    },
    {
      title: (
        <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/${dataDetail?.courseId}`}>
          <span>{dataDetail?.courseName}</span>
        </SafeLink>
      ),
    },
    {
      title: (
        <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/${dataDetail?.courseId}?tab=3`}>
          <span>Lớp học ghi danh</span>
        </SafeLink>
      ),
    },
    {
      title: (<span>{dataDetail?.name}</span>),
    },
  ];

  const handleChange = (key: string) => {
    if (key !== "2" && checkChange > 0) {
      setNextTab(key);
      confirmNavigation({ link: "" }, true, () => {
        setDirty(true);
      });
    } else {
      setTab(key);
    }
  };
  useEffect(() => {
    if (isDirty) {
      confirmNavigation({ link: "" }, true, () => {
        setTab(nextTab);
      });
    }
  }, [isDirty, nextTab]);

  const tabItems: TabsProps["items"] = [
    {
      key: '1',
      label: 'Thông tin',
      children: (<InfoTab currentTab={tab} dataDetail={dataDetail} />),
    },
    {
      key: '2',
      label: 'Lộ trình',
      children: (<RouteTab currentTab={tab} setCurrentTab={setTab}
        dataDetail={dataDetail}
        setCheckChange={setCheckChange}
      />),
    },
    {
      key: '3',
      label: 'Danh sách học viên',
      children: (<ListStudentTab currentTab={tab} />),
    },
    // {
    //   key: '4',
    //   label: 'Cài đặt',
    //   children: (<SettingsTab currentTab={tab}/>),
    // },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDetailEnrolledClass(Number(classId))
      setDataDetail(res?.data)
    }
    fetchData()
  }, [])

  return (
    <>
      <Content>
        <PageHeader
          title={dataDetail?.name}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={false}
        />
        <div className="tabs-heading">
          <Tabs
            activeKey={tab}
            onChange={handleChange}
            items={tabItems}
            destroyInactiveTabPane={true}
          />
        </div>
      </Content>
    </>
  );
};

export default RegistrationClassPage;
