import { commonMedia } from "./grapesjs-config";
export const nameCategoryBlocks = "Khối giao diện";
export const nameCategoryTemplates = "Mẫu hoàn chỉnh";
export const nameContactBlock = "Khối liên hệ";
export const nameLinkedCourseBlock = "Khối khóa học mẫu";
export const nameLecturersBlock = "Khối giảng viên";
export const nameBasicBlock = "Giao diện cơ bản";
export const nameCountBlocks = "Khối thống kê mẫu";
export const nameRoadMapBlocks = "Khối lộ trình khóa học";
export const nameHeaderBlocks = "Giới thiệu chung";
export const nameRegisterBlocks = "Khối ghi danh";
export const nameConditionBlocks = "Khối điều kiện";
export const nameAffiliatedBlocks = "Khối liên kết";

export const generalHeaderIntroduction = (data?: any) => {
  const blockUI = {
    id: "header-block-id",
    label: "Mẫu chứng chỉ 1",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body>
  <div id="certificate-id" class="relative bg-white shadow-lg overflow-hidden h-[793.7px] w-[1122.52px]">
    <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-12-10T07%3A53%3A22.166960475Z/0e651f12-e7dc-4c8d-b071-7df99bcbafbdCertificate-Template-01.jpg" alt="Certificate Background" id="certificate-id" class="inset-0 w-full h-full absolute h-[793.7px] w-[1122.52px]"
    />
    <div id="iqce" class="relative text-center">
      <h1 id="iilc" class="font-sans text-base md:text-5xl lg:text-6xl lg:mt-18 md:mt-28 mt-10">CHỨNG NHẬN HOÀN THÀNH
      </h1>
      <h3 id="ir0ml" class="text-xs md:text-2xl lg:text-6xl lg:mt-6 md:mt-6">HỌC VIÊN</h3>
      <h3 id="ir0ml"  class="text-xs font-sans italic text-amber-600 md:text-2xl lg:text-8xl lg:mt-6 md:mt-16">
      <span component-class="student-name-certificate">Sarah J. Miller</span>
      </h3>
      <h2 id="i2ak" class=" font-light text-base lg:mt-12 md:mt-16 md:text-2xl lg:text-4xl ">Đã xuất sắc hoàn thành khoá học
      </h2>
      <p id="i76x1-rdtj7rhp" component-class="course-name-certificate" class="text-muted-foreground lg:mt-8 md:mt-4 md:text-2xl lg:text-6xl mt-12 text-[0.5rem] md:text-1xl lg:text-2xl tracking-widest">TÊN KHOÁ HỌC</p>

      <div id="iertx" class="gjs-row">
      </div>
      <div id="iauuq" class="flex justify-center mt-[1.75rem] gap-8 lg:gap-0 md:gap-0 lg:mt-32 lg:mb-16 md:mt-12 mb-[1.75rem]">
        <div id="i27mb" class="text-center">
          <p id="i2ifr" component-class="date-certificate" class="text-muted-foreground text-[0.4rem] md:text-base lg:text-lg lg:mr-48 md:mr-18 mr-0">29th September
          </p>
        </div>
        <div class="text-center mt">
          <p id="i781e" component-class="signature-teacher-certificate" class="text-muted-foreground text-[0.4rem] md:text-base lg:text-lg lg:ml-24 md:ml-24 ml-0">Amanda Miller
          </p>
        </div>
      </div>
    </div>
  </div>
</body>
  `;

  return blockUI;
};

export const generalContentIntroduction = (data?: any) => {
  const blockUI = {
    id: "content-block-id",
    label: "Mẫu chứng chỉ 2",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <div id="certificate-id" class="relative bg-white shadow-lg overflow-hidden">
    <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-12-10T07%3A53%3A49.968352989Z/7fe813ac-60b8-4a94-ae97-6c54f45055bbCertificate-Template-02.jpg" alt="Certificate Background" id="certificate-id" class="inset-0 w-full absolute"
      style="height: 210mm;"
    />
    <div id="iqce" class="relative p-8 text-center">
      <h1 id="iilc" class="font-bold  text-3xl md:text-5xl lg:text-6xl text-blue-900 lg:mt-16 tracking-widest">CHỨNG NHẬN HOÀN THÀNH
      </h1>
      <h2 id="i2ak" class=" font-light text-2xl md:text-3xl lg:text-4xl tracking-widest my-8">HỌC VIÊN
      </h2>
       <h3 id="ir0ml" class="text-xs font-sans italic text-blue-900 md:text-2xl lg:text-8xl lg:mt-16 md:mt-16">
       <span component-class="student-name-certificate">Sarah J. Miller</span>
       </h3>
      <p id="i76x1" class="text-muted-foreground lg:mt-14 text-lg md:text-1xl lg:text-2xl tracking-widest text-blue-900">Đã xuất sắc hoàn thành khoá học</p>
      <p id="i76x1-rdtj7rhp" component-class="course-name-certificate" class="text-muted-foreground text-lg  md:text-2xl lg:text-6xl text-blue-900 lg:mt-10">TÊN KHOÁ HỌC</p>
      
     <div id="iauuq" class="flex justify-center mt-24">
     <div  class="text-center">
        <p id="i1do7" component-class="date-certificate" class="text-muted-foreground text-sm md:text-base lg:text-lg mr-20 text-blue-900">JUNE 27</p>
      </div>
        <div id="i27mb" class="text-center">
          <p id="i2ifr" component-class="signature-teacher-certificate" class="text-muted-foreground text-sm md:text-base lg:text-lg mr-20 text-blue-900">Jessica Li
          </p>
        </div>
         
      </div>
    </div>
  </div>
</body>
  `;

  return blockUI;
};

export const generalFooterIntroduction = (data?: any) => {
  const blockUI = {
    id: "footer-block-id",
    label: "Mẫu chứng chỉ 3",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body id="ijzk">
  <div id="certificate-id" class="relative bg-white shadow-lg overflow-hidden">
    <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-10-23T10%3A18%3A35.475380022Z/7ac23d3e-0cd7-4876-a9fc-d9a8ecb7cda45535520-ai.png" alt="Certificate Background" id="certificate-id" class="inset-0 w-full object-cover absolute"
    style="height: 210mm;"
    />
    <div id="iqce-rdm28jft" class="relative text-center p-8">
      <h1 id="iilc-rdmgqyfj" class="font-bold  tracking-widest text-3xl md:text-5xl lg:text-6xl lg:mt-16">CHỨNG NHẬN HOÀN THÀNH
      </h1>
      <h2 id="i2ak-rdi9wonh" class=" font-light tracking-widest mt-4 text-base md:text-4xl lg:text-5xl">HỌC VIÊN
      </h2>
      <h3 id="ir0ml" class="text-xs font-sans italic text-blue-900 md:text-2xl lg:text-8xl lg:mt-16 md:mt-16">
      <span component-class="student-name-certificate">Sarah J. Miller</span>
      </h3>
      <p id="i1c4b-rdasclnr" class="text-muted-foreground text-sm md:text-2xl lg:text-3xl tracking-widest lg:mt-18 md:mt-14">Đã xuất sắc hoàn thành khoá học</p>
      <p id="i76x1-rdtj7rhp" component-class="course-name-certificate" class="text-muted-foreground tracking-widest lg:mt-20 md:mt-10 text-xl md:text-4xl lg:text-5xl">TÊN KHOÁ HỌC</p>
      <div id="iauuq-rd6cuvq9" class="flex justify-around lg:mt-16">
        <div id="i27mb-rdnnqxvn" class="text-center">
          <p id="i2ifr-rdek64iy" class="text-muted-foreground text-xl md:text-2xl lg:text-3xl font-bold">Ngày
          </p>
          <p component-class="date-certificate" id="ipsru-rdwjaz5s" class="text-muted-foreground text-xl md:text-2xl lg:text-3xl">dd/mm/yyyy
          </p>
        </div>
        <div id="i9wwfk-rds11n6e" class="text-center">
          <p id="i781e-rdcq2y43" class="text-muted-foreground text-xl md:text-2xl lg:text-3xl font-bold"> 
          </p>
          <p component-class="signature-director-certificate" id="islz5-rdpt7rsy" class="text-muted-foreground text-xl md:text-2xl lg:text-3xl">Alan Smith
          </p>
        </div>
      </div>
    </div>
  </div>
</body>`;

  return blockUI;
};
