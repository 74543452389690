import { IDataCreateUser } from "../../types/listUsers";
import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const createAccountTeacher: (
  user: IDataCreateUser
) => Promise<AxiosResponse<any>> = (user) => {
  return axiosConfig.post(`/accounts/giang-vien`, user);
};

export const getDataTeachers: (search: any) => Promise<AxiosResponse<any>> = (
  search: any
) => {
  return axiosConfig.post(`/accounts/giang-vien/search`, search);
};

export const getAllTeachers: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/course-planning-assignment/giang-vien/get-all`);
};

export const getAllAccountTeacher: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(
    `/mooc-university-teacher/account/get-all-by-filter`,
    data
  );
};

export const getAllTeacherByUniversity: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(
    `/mooc-university-teacher/get-all-teacher-by-university/${id}`
  );
};

export const getAllTeachersByUniversityNotPermission: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-university-teacher/get-all-teacher`);
};

export const getDetailTeacherAccount: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-university-teacher/account/${id}`);
};

export const changeStatusAccountTeacher: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(
    `/mooc-university-teacher/account/change-status`,
    data
  );
};

export const changePasswordGV: (data: any) => Promise<AxiosResponse<any>> = (
  data: any
) => {
  return axiosConfigV2.put(
    `/mooc-university-teacher/account/change-password`,
    data
  );
};

export const deleteAccountTeacher: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-university-teacher/account/${id}`);
};

export const exportExcelDataTeacher: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(
    `/mooc-university-teacher/account/export-excel`,
    data,
    {
      responseType: "arraybuffer",
    }
  );
};

export const createLecturerAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university-teacher/account`, data);
};

export const updateLecturerAccount: (
  id: string | undefined,
  data: any
) => Promise<AxiosResponse<any>> = (id: string | undefined, data: any) => {
  return axiosConfigV2.put(`/mooc-university-teacher/account/${id}`, data);
};

export const getTeacherOfCourse: (
  courseId: any
) => Promise<AxiosResponse<any>> = (courseId: number) => {
  return axiosConfigV2.get(`/mooc-courses/get-teachers-by-course/${courseId}`);
};

// get all account mooc teacher
export const getAllmoocTeacherAccount: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-university-teacher/account/get-all`);
};
