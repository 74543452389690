import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface ItemProps {
  id: string;
  className?: string;
  label?: string
}

export function Item(props: ItemProps) {
  const { id, className, label } = props;

  const style: React.CSSProperties = {
    width: "100%",
    height: 40,
    padding: "6px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid #f0f0f0",
    background: "white",
  };

  return(
    <div className={className} style={style} id={id}>
      {label}
    </div>
  );
}

export default function SortableItem(props: ItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item className={props.className} id={props.id} label={props.label} />
    </div>
  );
}
