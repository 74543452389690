import { Layout} from "antd";
import { FunctionComponent, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { useLocation } from "react-router-dom";
import "./university-info-management-detail.scss";

import UniversityInfoDetail from "../../../../components/category-management/management-detail/university-info-detail/uni-info-detail/UniversityInfoDetail";

const { Content } = Layout;
const imgPath = "/images/";

const UniversityInfoManagementDetail: FunctionComponent = () => {
  const location = useLocation();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const listBreadcrumb = [
    {
      title: "Quản lý danh mục",
    },
    {
      title: "Quản lý khác",
    },
    {
      title: location.state?.tabType === "1" ?
            "Loại hình trường" : location.state?.tabType === "2" ?
            "Loại trường" : "Cấp độ đào tạo",
    },
    {
      title: <span className="style-title">{location.state.name}</span>,
    },
  ];

  return (
    <>
      <Content>
        <PageHeader
          title={location.state?.name ? location.state?.name : ""}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <UniversityInfoDetail
            universityInfoDetail={location.state.record}
            loadingDetail={loadingDetail}
            textRendered={location.state?.textRendered}
          />
        </div>
      </Content>
    </>
  );
};

export default UniversityInfoManagementDetail;
