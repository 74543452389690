import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../../../config/api/configApiv2";

export const getMoocIndustryGroupById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-industry/group/${id}`);
}

export const getAllMoocIndustryGroupByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-industry/group/get-all-by-filter`, data);
}

export const createMoocIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-industry/group/create`, data);
}

export const updateMoocIndustryGroup: (data: any, id: number) => Promise<AxiosResponse<any>> = (data, id) => {
    return axiosConfigV2.put(`/mooc-industry/group/update/${id}`, data);
}

export const deleteMoocIndustryGroup: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/mooc-industry/group/${id}`)
}