import React, { useState, useRef, useEffect } from 'react';
import DrawerCustom from '../../../../drawer/Drawer';
import { Button, DatePicker, DatePickerProps, Form, Input, Radio, RadioChangeEvent, Space, TimePicker, TimePickerProps, Typography, Tag, Spin } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import './submitReview.scss';
import FormItemDropdown from '../../../../form-dropdown/FormItemDropdown';
import { get, isNull } from 'lodash';
import { viLocale } from '../../../../../utils/pickerType';
import { IDataDetail } from '../../../../../pages/library/bank-exam/components/personal-exam-repository/list-exam';

type SubmitReviewProps = {
    isOpened: boolean,
    closeForm: () => void,
    status: string
    onFinish: (values: any) => void;
    dataOptions?: any;
    dataDetail?: IDataDetail | null;
    loading?: boolean;
}
function SubmitReview({ isOpened, closeForm, status, onFinish, dataOptions, dataDetail, loading }: SubmitReviewProps) {
    const [form] = Form.useForm();
    const [access, setAccess] = useState<string>('');
    const [scheduleType, setScheduleType] = useState(1);
    const [publishDate, setPublishDate] = useState<string | null>(null);
    const [inputVisible, setInputVisible] = useState(false);

    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setPublishDate(dateString);
        }
    };

    const onChangeTime: TimePickerProps['onChange'] = (time, timeString) => {
        if (typeof timeString === 'string') {
            setPublishDate(prev => prev ? `${prev}T${timeString}` : timeString);
        }
    };

    const handleSubmit = () => {
        form.submit();
    };

    const handleCloseForm = () => {
        form.resetFields();
        setScheduleType(1);
        setPublishDate(null);
        setInputVisible(false);
        setAccess("Công khai");
        closeForm();
    };

    useEffect(() => {
        form.resetFields();
        setScheduleType(1);
        setPublishDate(null);
        setInputVisible(false);
        setAccess("Công khai");
    }, [isOpened])

    const handleFinish = (values: any) => {
        const dataToSubmit = {
            scheduleType: values.scheduleType,
            publishDate: scheduleTypeValue === 3 ? publishDate : null,
            contentEdit: values.contentEdit,
            access: values.access,
            shareUsers: values.access === "Giới hạn" ? values.limit : null,
        };
        onFinish(dataToSubmit);
    };

    const handleChangePermission = Form.useWatch("access", form);
    const scheduleTypeValue = Form.useWatch('scheduleType', form);

    // useEffect
    useEffect(() => {
        if (!isNull(dataDetail)) {
            if (!isNull(dataDetail?.access)) {
                form.setFieldValue('access', dataDetail?.access);
                if (dataDetail?.access === "Giới hạn") {
                    form.setFieldsValue({
                        limit: dataDetail?.emailToAccess,
                    })
                }
            }
        }
    }, [dataDetail]);

    useEffect(() => {
        setScheduleType(scheduleTypeValue);
    }, [scheduleTypeValue]);

    useEffect(() => {
        form.setFieldValue('access', handleChangePermission);
        setAccess(handleChangePermission);
    }, [handleChangePermission]);

    return (
        <div>

            <DrawerCustom
                title={'Gửi kiểm duyệt nội dung'}
                open={isOpened}
                onClose={handleCloseForm}
                extraNode={
                    <Space>
                        <Button
                            onClick={handleCloseForm}
                            className="btn btn-outlined"
                        >
                            <CloseOutlined />
                            <Typography.Text>Hủy</Typography.Text>
                        </Button>
                        <Button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >
                            <SaveOutlined />
                            <Typography.Text>Gửi yêu cầu</Typography.Text>
                        </Button>
                    </Space>
                }
                widthDrawer={736}
            >
                {loading ?
                    <div className="submit-review-loading flex">
                        <Spin size="large" />
                    </div>
                    :
                    <Form
                        form={form}
                        className="form-submit-review"
                        onFinish={handleFinish}
                        initialValues={{ scheduleType: 1, access: "Công khai" }}
                    >
                        {status === 'Đề xuất chỉnh sửa' && (
                            <div className="container-form">
                                <Typography className="container-form-title">Nội dung chỉnh sửa</Typography>
                                <Form.Item name="contentEdit">
                                    <Input placeholder="Nhập nội dung" />
                                </Form.Item>
                            </div>
                        )}
                        <div className="container-form">
                            <Typography className="container-form-title">Quyền truy cập</Typography>
                            <Form.Item name="access">
                                <Radio.Group defaultValue={'Công khai'}>
                                    <Space direction="vertical">
                                        <Radio value='Công khai'>
                                            Công khai
                                            <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này cần Cấp bộ kiểm duyệt trước khi công khai
                                            </Typography>
                                        </Radio>
                                        <Radio value='Nội bộ'>
                                            Nội bộ
                                            <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này cần QTCS kiểm duyệt trước khi công khai nội bộ
                                            </Typography>
                                        </Radio>
                                        <Radio value='Giới hạn'>
                                            Giới hạn
                                            <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Chỉ hiển thị nội dung với đối tượng được chia sẻ
                                            </Typography>
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            {access === 'Giới hạn' ? (
                                <div className="form-input" style={{ width: '100%' }}>
                                    {!inputVisible && (
                                        <Form.Item name="limit" style={{ width: '100%' }}>
                                            <FormItemDropdown modeDropdown='multiple' placeholder="Chọn email"
                                                options={get(dataOptions, 'dataLimit', []).map((item: any) => ({
                                                    code: item.name,
                                                    name: item.name
                                                }))} />
                                        </Form.Item>
                                    )}
                                </div>
                            ) : null}
                        </div>
                        <div className="container-form">
                            <Typography className="container-form-title">Lịch xuất bản dự kiến </Typography>
                            <Form.Item name='scheduleType'>
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value={1}>
                                            Không xuất bản
                                            <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này không xuất bản sau khi kiểm duyệt
                                            </Typography>
                                        </Radio>
                                        <Radio value={2}>
                                            Xuất bản ngay
                                            <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này sẽ xuất bản ngay sau khi kiểm duyệt thành công
                                            </Typography>
                                        </Radio>
                                        <Radio value={3}>
                                            Hẹn lịch
                                            <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung được kiểm duyệt thành công sẽ xuất bản theo lịch trình được thiết lập
                                            </Typography>
                                            {
                                                scheduleType === 3 ? (
                                                    <div className="time-input">
                                                        <Form.Item
                                                            name='date'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng chọn ngày xuất bản!',
                                                                },
                                                            ]}
                                                        >
                                                            <DatePicker locale={viLocale} onChange={onChangeDate} format="DD/MM/YYYY" placeholder="Chọn ngày" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name='time'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng chọn thời gian xuất bản!',
                                                                },
                                                            ]}
                                                        >
                                                            <TimePicker locale={viLocale} onChange={onChangeTime} placeholder="Chọn thời gian" />
                                                        </Form.Item>
                                                    </div>
                                                ) : null
                                            }
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Form>
                }
            </DrawerCustom>
        </div>
    )
}

export default SubmitReview;
