import {
  CheckOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  FormInstance,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Typography,
} from "antd";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDetailQuestionBank,
  getDetailQuestionBankMinistry,
  getQuestionBankDetailHistory,
  getQuestionDetail,
  getQuestionDetailHistory,
  putApproveQuestionBank,
} from "../../../../service/question-bank";
import { dataInfo } from "../../../../pages/detail/constants/dataInfo";
import TextArea from "antd/es/input/TextArea";
import moment, { Moment } from "moment";
import { LocationState } from "../..";
import dayjs, { Dayjs } from "dayjs";
import { viLocale } from "../../../../utils/pickerType";

const { Panel } = Collapse;

export type UpdateSettingsProps = {
  handelChangeForm?: (val: any, name: string, cb?: any) => void;
  handelUpdateSettings?: (val: any, name: string, cb?: any) => void;
  form?: FormInstance;
  mode?: string;
};

type QuestionBankDetail = {
  id: number;
  unitId: number;
  code: string;
  title: string;
  topics: string;
  numberQuestion: number;
  description: string | null;
  tags: string;
  numberOfUse: number;
  authors: string | string[];
  access: string;
  createdBy: string | null;
  publishingUnit: string | null;
  hierarchy: string | null;
  moocQuestionBankId: number;
  moocCourseUnitId: number;
  typeProduction: number | string;
  productionUnitId: number | null;
  productionUnitName: string | null;
  productUnitName: string | null;
  typeApproved: string;
  action: string;
  contentApprove: string;
  approvedSendDate: string;
  approvedDate: string;
  question: string;
  status: boolean;
  approvedBy: number;
  createdDate: string;
  approveByName: string;
  author: string | string[] | any[]; // Array, you may specify the exact type if needed
  topic: any[]; // Array, you may specify the exact type if needed
  approvedDescription: string;
  publishStatus: number;
  publicDate: string;
  scheduleType: number;
};

type Answer = {
  uuid: string;
  point: number;
  isTrue: boolean;
  content: string;
  fileName: string | null;
  filePath: string | null;
  response: string | null;
  orderNumber: number;
  isDisplayContent: boolean | null;
};

type QuestionDetail = {
  id: number;
  moocQuestionBankId: number;
  moocCourseUnitId: number | null;
  moocCourseBlockQuizId: number;
  typeQuestion: number;
  type: number;
  action: string;
  level: number;
  question: string;
  code: string;
  title: string | null;
  createdDate: string;
  typeProduction: number | string;
  productionUnitId: string;
  productionUnitName: string;
  productionUnit: string;
  typeApproved: string;
  createdBy?: string;
  approvedSendDate: string; // ISO date string
  approvedDate: string | null; // ISO date string
  status: boolean;
  approvedBy: string | null;
  approveByName: string | null;
  authors: string;
  topics: string;
  access: string;
  numberOfUse: number | null;
  description: string;
  tags: string;
  approvedDescription: string | null;
  contentApprove: string | null;
  publishStatus: number;
  publicDate: string | null; // ISO date string
  scheduleType: number;
};

const SettingExam = (props: UpdateSettingsProps) => {
  const [dataDetail, setDataDetail] = useState<QuestionBankDetail>();
  const [questionDetail, setQuestionDetail] = useState<QuestionDetail>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get("id");

  const getPublishStatus = (status: number): string => {
    switch (status) {
      case 0:
        return "Bản nháp";
      case 1:
        return "Chờ QTCS duyệt";
      case 2:
        return "Chờ Cấp bộ duyệt";
      case 3:
        return "QTCS không duyệt";
      case 4:
        return "Cấp Bộ không duyệt";
      case 5:
        return "Đã lên lịch";
      case 6:
        return "Đã xuất bản";
      case 7:
        return "Chưa xuất bản";
      case 8:
        return "Đề xuất chỉnh sửa";
      default:
        return "";
    }
  };

  const [approveRadio, setApproveRadio] = useState(1);
  const [comment, setComment] = useState("");
  const [publishMode, setPublishMode] = useState(1);
  const [selectDate, setSelectDate] = useState<Dayjs | null>(null);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const { state } = location as { state: LocationState };
  const [handlePressSave, setHandlePressSave] = useState(false);

  const mode = state.mode;
  const [showAllAuthors, setShowAllAuthors] = useState(false);

  const onChangeRadio = (e: RadioChangeEvent) => {
    setApproveRadio(e.target.value);
  };
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(state.backUrl);
  };

  const handleShowMore = () => {
    setShowAllAuthors(!showAllAuthors);
  };

  const renderAuthors = (authors: string[] | string | number[]) => {
    if (typeof authors === "string") {
      // If only one author (a single string)
      return <span>{authors}</span>;
    } else if (Array.isArray(authors)) {
      // If multiple authors (array of strings)
      if (authors.length <= 2) {
        return <span>{authors[0]}</span>;
      } else {
        return (
          <>
            {/* Display the first author and a "More" button if there are multiple authors */}
            <span>{authors[0]}</span>
            <span>{authors[1]}</span>
            {!showAllAuthors && authors.length > 2 && (
              <button
                style={{
                  color: "blue",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  marginLeft: 8,
                }}
                onClick={handleShowMore}
              >
                Xem thêm
              </button>
            )}
            {showAllAuthors && (
              <span>
                {authors
                  .slice(1)
                  .map((author: string | number, index: number) => (
                    <span key={index}> , {author}</span>
                  ))}
              </span>
            )}
          </>
        );
      }
    }
  };

  const renderAccess = (access: string | string[]) => {
    if (typeof access === "string") {
      return <span>{access}</span>;
    } else if (Array.isArray(access)) {
      if (access.length <= 2) {
        return <span>{access[0]}</span>;
      } else {
        return (
          <>
            <span>{access[0]}</span>
            <span>{access[1]}</span>
            {!showAllAuthors && access.length > 2 && (
              <button
                style={{
                  color: "blue",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  marginLeft: 8,
                }}
                onClick={handleShowMore}
              >
                Xem thêm
              </button>
            )}
            {showAllAuthors && (
              <span>
                {access.slice(1).map((item: string | number, index: number) => (
                  <span key={index}> , {item}</span>
                ))}
              </span>
            )}
          </>
        );
      }
    }
  };

  const renderType = (value: string) => {
    switch (Number(value)) {
      case 1:
        return "Nhiều lựa chọn văn bản";
      case 2:
        return "Nhiều lựa chọn ảnh";
      case 3:
        return "Nhiều lựa chọn video";
      case 4:
        return "Lựa chọn đúng sai";
      case 5:
        return "Danh sách";
      case 6:
        return "Nhiều đáp án văn bản";
      case 7:
        return "Nhiều đáp án hình ảnh";
      case 9:
        return "Câu trả lời ngắn";
      case 10:
        return "Điền vào chỗ trống";
      case 11:
        return "Trình soạn thảo";
      case 12:
        return "Kết nối";
      case 13:
        return "Sắp xếp";
      case 14:
        return "Ghi âm";
      case 15:
        return "Ghi hình";
      case 16:
        return "Đính kèm";
      default:
        return "Bản nháp";
    }
  };

  const handleGetDetail = async () => {
    try {
      await getDetailQuestionBank(Number(unitId)).then((result) => {
        if (result.data) {
          setDataDetail(result.data.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailMinistryContent = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setDataDetail(result.data.data);
          if (result.data.data.publicDate) {
            const parsedDateTime = dayjs(result.data.data?.publicDate).utc();
            setSelectDate(parsedDateTime);
          }
          setPublishMode(result.data.data?.scheduleType);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailUniversityContent = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setDataDetail(result.data.data);
          if (result.data.data.publicDate) {
            const parsedDateTime = dayjs(result.data.data?.publicDate).utc();
            setSelectDate(parsedDateTime);
          }
          setPublishMode(result.data.data?.scheduleType);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailQuestion = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setQuestionDetail(result.data.data);
          // handleGetQuizBank(result.data.data.moocQuestionBankId)
          if (result.data.data.publicDate) {
            const parsedDateTime = dayjs(result.data.data?.publicDate).utc();
            setSelectDate(parsedDateTime);
          }
          setPublishMode(result.data.data?.scheduleType);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleGetDetailHistoryQuestion = async () => {
    try {
      await getQuestionDetailHistory(Number(unitId)).then((result) => {
        if (result.data) {
          setQuestionDetail(result.data.data);
          if (result.data.data.publicDate) {
            const parsedDateTime = dayjs(result.data.data?.publicDate).utc();
            setSelectDate(parsedDateTime);
          }
          setPublishMode(result.data.data?.scheduleType);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailHistoryQuestionBank = async () => {
    try {
      await getQuestionBankDetailHistory(Number(unitId)).then((result) => {
        if (result.data) {
          setDataDetail(result.data.data);
          if (result.data.data.publicDate) {
            const parsedDateTime = dayjs(result.data.data?.publicDate).utc();
            setSelectDate(parsedDateTime);
          }
          setPublishMode(result.data.data?.scheduleType);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetQuizBank = async (id: string) => {
    try {
      await getQuestionDetail(id).then((result) => {
        // setQuestionInfo(result.data.data)
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (mode === "0" || mode === "4") handleGetDetail();
    else if (mode === "1") handleGetDetailMinistryContent();
    else if (mode === "2") handleGetDetailUniversityContent();
    // call question API here
    else if (mode === "5") handleGetDetailQuestion();
    else if (mode === "6") handleGetDetailQuestion();
    else if (state?.mode === "7") handleGetDetailHistoryQuestion();
    else if (state?.mode === "8") handleGetDetailHistoryQuestionBank();
  }, []);

  const [flag, setFlag] = useState<boolean>(true);
  const toggleFlag = () => {
    setFlag((prev) => !prev);
  };
  // const [mode, setMode] = useState("1");

  const handlePopupConfirm = () => {
    if (publishMode === 3 && !selectDate) {
      setHandlePressSave(true);
    } else {
      setIsVisibleConfirm(true);
    }
  };

  const handleConfirm = async () => {
    const params = [
      {
        id: unitId,
        status: approveRadio,
        description: comment,
        publishDate: selectDate?.utc().toISOString(),
        scheduleType: publishMode,
      },
    ];
    try {
      await putApproveQuestionBank(params).then((result) => {
        if (result.data) {
          message.success(`Lưu nội dung kiểm duyệt thành công`);
          setIsVisibleConfirm(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getDifficultyLabel = (level: number): string => {
    switch (level) {
      case 0:
        return "Dễ";
      case 1:
        return "Trung bình";
      case 2:
        return "Nâng cao";
      default:
        return "";
    }
  };

  console.log(dataDetail, questionDetail);

  const renderTypeProduction = (type: number) => {
    switch (type) {
      case 1:
        return <>Cấp giảng viên</>
      case 2:
        return <>Cấp trường</>
      case 3:
        return <>Cấp bộ</>
      default:
        break;
    }
  }

  const detailExam = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Mã đề:</div>
          {dataDetail?.code ? (
            <div>{dataDetail?.code || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Tiêu đề:</div>
          {dataDetail?.title ? (
            <div>{dataDetail?.title || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Chủ đề:</div>
          {dataDetail?.topics ? (
            <div>{dataDetail?.topics || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Số câu hỏi:</div>
          {dataDetail?.numberQuestion !== undefined &&
          dataDetail?.numberQuestion !== null ? (
            <div>{dataDetail?.numberQuestion}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Mô tả:</div>
          {dataDetail?.description ? (
            <div>{dataDetail?.description || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nhãn:</div>
          {dataDetail?.tags ? (
            <div>{dataDetail?.tags || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Lượt dùng:</div>
          {dataDetail?.numberOfUse !== undefined &&
          dataDetail?.numberOfUse !== null ? (
            <div>{dataDetail?.numberOfUse}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Phân cấp:</div>
          {dataDetail?.typeProduction !== undefined &&
          dataDetail?.typeProduction !== null ? (
            <div>{renderTypeProduction(Number(dataDetail?.typeProduction))}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Tác giả:</div>
          <div>{renderAuthors(dataDetail?.authors || "")}</div>
        </div>
        <div className="info-item">
          <div className="info-title">Đơn vị xuất bản:</div>
          {dataDetail?.productionUnitName || dataDetail?.productUnitName ? (
            <div>
              {dataDetail?.productionUnitName || dataDetail?.productUnitName}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Quyền truy cập:</div>
          {dataDetail?.access ? (
            <div>{dataDetail?.access || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        {mode === "0" ? (
          <div className="info-item">
            <div className="info-title">Ngày xuất bản:</div>
            {dataDetail?.publicDate ? (
              <div>
                {dayjs(dataDetail?.publicDate)
                  .utc()
                  .format("DD/MM/YYYY HH:mm:ss")}
              </div>
            ) : (
              <div className="no-info">Chưa có thông tin</div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const detailQuestion = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Mã câu hỏi:</div>
          {questionDetail?.code ? (
            <div>{questionDetail?.code || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Độ khó:</div>
          {questionDetail?.level !== undefined ? (
            <div>{getDifficultyLabel(questionDetail?.level)}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Loại câu hỏi:</div>
          {questionDetail?.typeQuestion || questionDetail?.type ? (
            <div>
              {renderType(
                `${questionDetail?.typeQuestion || questionDetail?.type}`
              )}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nhãn:</div>
          {questionDetail?.tags ? (
            <div>{questionDetail?.tags || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Lượt dùng:</div>
          {questionDetail?.numberOfUse !== undefined &&
          questionDetail?.numberOfUse !== null ? (
            <div>{questionDetail?.numberOfUse || ""}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Phân cấp:</div>
          {questionDetail?.typeProduction !== undefined &&
          questionDetail?.typeProduction !== null ? (
            <div>{renderTypeProduction(Number(questionDetail?.typeProduction))}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Tác giả:</div>
          {questionDetail?.authors ? (
            <div>{renderAuthors(questionDetail?.authors || "")}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Đơn vị xuất bản:</div>
          {questionDetail?.productionUnitName ||
          questionDetail?.productionUnit ? (
            <div>
              {questionDetail.productionUnitName ||
                questionDetail?.productionUnit}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Quyền truy cập:</div>
          <div>{renderAccess(questionDetail?.access || "")}</div>
        </div>
      </div>
    );
  };

  const checkInfo = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Nội dung gửi duyệt:</div>
          {dataDetail?.typeApproved || dataDetail?.action ? (
            <div>{dataDetail?.typeApproved || dataDetail?.action}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Ngày gửi duyệt:</div>
          {dataDetail?.approvedSendDate || dataDetail?.createdDate ? (
            <div>
              {dayjs(dataDetail?.approvedSendDate)
                .utc()
                .format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nội dung duyệt:</div>
          {dataDetail?.approvedDescription || questionDetail?.contentApprove ? (
            <div>
              {dataDetail?.approvedDescription ||
                questionDetail?.contentApprove}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Người duyệt:</div>
          {dataDetail?.approveByName || dataDetail?.createdBy ? (
            <div>{dataDetail?.approveByName || dataDetail?.createdBy}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Ngày duyệt:</div>
          {dataDetail?.approvedDate ? (
            <div>
              {moment(dataDetail?.approvedDate).format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
      </div>
    );
  };

  const checkInfoQuestion = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Nội dung gửi duyệt:</div>
          {questionDetail?.typeApproved || questionDetail?.action ? (
            <div>{questionDetail?.typeApproved || questionDetail?.action}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Ngày gửi duyệt:</div>
          {questionDetail?.approvedSendDate || questionDetail?.createdDate ? (
            <div>
              {dayjs(
                questionDetail?.approvedSendDate || questionDetail?.createdDate
              )
                .utc()
                .format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">
              Chưa có thông tin {dataDetail?.createdDate}{" "}
            </div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nội dung duyệt:</div>
          {questionDetail?.approvedDescription ||
          questionDetail?.contentApprove ? (
            <div>
              {questionDetail?.approvedDescription ||
                questionDetail?.contentApprove}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Người duyệt:</div>
          {questionDetail?.approveByName || questionDetail?.createdBy ? (
            <div>
              {questionDetail?.approveByName || questionDetail?.createdBy}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Ngày duyệt:</div>
          {questionDetail?.approvedDate ? (
            <div>
              {moment(questionDetail?.approvedDate).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
      </div>
    );
  };

  const publishInfo = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Ngày xuất bản:</div>
          {dataDetail?.publicDate ? (
            <div>
              {dayjs(dataDetail?.publicDate)
                .utc()
                .format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Tình trạng:</div>
          {dataDetail?.publishStatus ? (
            <div>{getPublishStatus(dataDetail?.publishStatus)}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
      </div>
    );
  };

  const publishInfoQuestion = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Ngày xuất bản:</div>
          {questionDetail?.publicDate ? (
            <div>
              {dayjs(questionDetail?.publicDate)
                .utc()
                .format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Tình trạng:</div>
          {questionDetail?.publishStatus ? (
            <div>{getPublishStatus(questionDetail?.publishStatus)}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
      </div>
    );
  };

  const infoApprove = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Ngày gửi:</div>
          {dataDetail?.approvedSendDate ? (
            <div>
              {dayjs(dataDetail?.approvedSendDate)
                .format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nội dung gửi duyệt:</div>
          {dataDetail?.typeApproved || dataDetail?.action ? (
            <div>{dataDetail?.typeApproved || dataDetail?.action}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="">
          <div className="info-title">Nội dung duyệt:</div>
          <Radio.Group
            className="radio-approve"
            onChange={onChangeRadio}
            value={approveRadio}
          >
            <Radio value={1}>Duyệt</Radio>
            <Radio value={0}>Không duyệt</Radio>
          </Radio.Group>
          <TextArea
            placeholder="Nhập ý kiến"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
          />
        </div>
      </div>
    );
  };

  const infoApproveQuestion = () => {
    return (
      <div className={`${mode === "4" ? "info" : ""}`}>
        <div className="info-item">
          <div className="info-title">Ngày gửi:</div>
          {questionDetail?.approvedSendDate ? (
            <div>
              {dayjs(questionDetail?.approvedSendDate)
                .format("DD/MM/YYYY HH:mm:ss")}
            </div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="info-item">
          <div className="info-title">Nội dung gửi duyệt:</div>
          {questionDetail?.typeApproved || questionDetail?.action ? (
            <div>{questionDetail?.typeApproved || questionDetail?.action}</div>
          ) : (
            <div className="no-info">Chưa có thông tin</div>
          )}
        </div>
        <div className="">
          <div className="info-title">Nội dung duyệt:</div>
          <Radio.Group
            className="radio-approve"
            onChange={onChangeRadio}
            value={approveRadio}
          >
            <Radio value={1}>Duyệt</Radio>
            <Radio value={0}>Không duyệt</Radio>
          </Radio.Group>
          <TextArea
            placeholder="Nhập ý kiến"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
          />
        </div>
      </div>
    );
  };

  const handleChangeSelectDate = (time: any) => {
    setSelectDate(time);
    setHandlePressSave(false);
  };

  const publishSetting = () => {
    return (
      <div>
        <Select
          disabled={approveRadio === 0}
          className="w-full mb-2"
          showSearch
          value={publishMode}
          placeholder="Chọn lịch xuất bản"
          optionFilterProp="label"
          onChange={(value) => {
            setHandlePressSave(false);
            setPublishMode(Number(value));
          }}
          // onSearch={onSearch}
        >
          <Select.Option value={1}>Không xuất bản</Select.Option>
          <Select.Option value={2}>Xuất bản ngay</Select.Option>
          <Select.Option value={3}>Hẹn lịch xuất bản</Select.Option>
        </Select>
        {handlePressSave ? (
          <Typography className="text-red-500">
            Vui lòng chọn ngày xuất bản
          </Typography>
        ) : (
          <></>
        )}

        <DatePicker
          status={handlePressSave ? "error" : ""}
          // locale={viLocale}
          showTime={{ format: "HH:mm:ss" }}
          value={selectDate ? selectDate : null}
          disabled={publishMode !== 3}
          format={"DD/MM/YYYY HH:mm:ss"}
          className="w-full"
          placeholder="Chọn ngày xuất bản"
          onChange={handleChangeSelectDate}
        />
      </div>
    );
  };

  const detailTab = [
    {
      title: "Thông tin đề",
      content: detailExam(),
    },
    {
      title: "Thông tin duyệt",
      content: checkInfo(),
    },
    {
      title: "Lịch xuất bản",
      content: publishInfo(),
    },
  ];

  const approveTab = [
    {
      title: "Thông tin đề",
      content: detailExam(),
    },
    {
      title: "Thông tin duyệt",
      content: infoApprove(),
    },
    {
      title: "Lịch xuất bản",
      content: publishSetting(),
    },
  ];

  const questionTab = [
    {
      title: "Thông tin câu hỏi",
      content: detailQuestion(),
    },
    {
      title: "Thông tin duyệt",
      content: checkInfoQuestion(),
    },
    {
      title: "Lịch xuất bản",
      content: publishInfoQuestion(),
    },
  ];

  const approveQuestion = [
    {
      title: "Thông tin câu hỏi",
      content: detailQuestion(),
    },
    {
      title: "Thông tin duyệt",
      content: infoApproveQuestion(),
    },
    {
      title: "Lịch xuất bản",
      content: publishSetting(),
    },
  ];

  const getComponent = (caseValue: string) => {
    switch (caseValue) {
      case "1":
        return (
          <Collapse defaultActiveKey={[0, 1, 2]}>
            {detailTab.map((el, index) => {
              return (
                <Panel key={index} header={el?.title}>
                  <div className="sub-content">{el?.content}</div>
                </Panel>
              );
            })}
          </Collapse>
        );
      case "8":
        return (
          <Collapse defaultActiveKey={[0, 1, 2]}>
            {detailTab.map((el, index) => {
              return (
                <Panel key={index} header={el?.title}>
                  <div className="sub-content">{el?.content}</div>
                </Panel>
              );
            })}
          </Collapse>
        );
      case "2":
        return (
          <Collapse defaultActiveKey={[0, 1, 2]}>
            {approveTab.map((el, index) => {
              return (
                <Panel key={index} header={el?.title}>
                  <div className="sub-content">{el?.content}</div>
                </Panel>
              );
            })}
          </Collapse>
        );
      case "5":
        return (
          <Collapse defaultActiveKey={[0, 1, 2]}>
            {questionTab.map((el, index) => {
              return (
                <Panel key={index} header={el?.title}>
                  <div className="sub-content">{el?.content}</div>
                </Panel>
              );
            })}
          </Collapse>
        );
      case "7":
        return (
          <Collapse defaultActiveKey={[0, 1, 2]}>
            {questionTab.map((el, index) => {
              return (
                <Panel key={index} header={el?.title}>
                  <div className="sub-content">{el?.content}</div>
                </Panel>
              );
            })}
          </Collapse>
        );
      case "6":
        return (
          <Collapse defaultActiveKey={[0, 1, 2]}>
            {approveQuestion.map((el, index) => {
              return (
                <Panel key={index} header={el?.title}>
                  <div className="sub-content">{el?.content}</div>
                </Panel>
              );
            })}
          </Collapse>
        );
      default:
        return <div>Default Component</div>;
    }
  };

  return (
    <>
      {mode === "4" ? (
        <></>
      ) : (
        <div className={`setting-exam ${flag ? "" : "tab-closed w-[50px]"}`}>
          <div className="setting-exam__header flex">
            <MenuUnfoldOutlined
              className="setting-exam__header__first"
              rotate={flag ? 0 : 180}
              onClick={toggleFlag}
            />
            <SettingOutlined className="setting-exam__header__second" />
            <div className="setting-exam__header__title">
              {mode === "0" ? "Thông tin đề" : "Thông tin duyệt"}
            </div>
          </div>
          <div
            className="setting-exam__content"
            style={{ visibility: flag ? "visible" : "hidden" }}
          >
            <div className={`${flag ? "info-container" : "info-container-v2"}`}>
              {mode === "0" ? detailExam() : getComponent(mode)}
            </div>
            {mode === "2" || mode === "6" ? (
              <div className="button-container">
                <Button
                  onClick={() => {
                    handlePopupConfirm();
                  }}
                  className="btn btn-primary width-button-100 ml-2"
                >
                  <CheckOutlined />
                  <Typography.Text>Lưu lại</Typography.Text>
                </Button>
                <Button onClick={navigateBack} className="btn width-button-100">
                  <CloseOutlined />
                  <Typography.Text>Hủy bỏ</Typography.Text>
                </Button>
              </div>
            ) : (
              <></>
            )}

            <Modal
              title="Xác nhận"
              open={isVisibleConfirm}
              onOk={handleConfirm}
              onCancel={() => {
                setIsVisibleConfirm(false);
              }}
              className="h-56"
              centered
              footer={[
                <div className="flex flex-row items-center w-full justify-evenly">
                  <Button
                    key="cancel"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setIsVisibleConfirm(false);
                    }}
                  >
                    Hủy bỏ
                  </Button>
                  <Button
                    key="ok"
                    icon={<CheckOutlined />}
                    type="primary"
                    onClick={handleConfirm}
                  >
                    Xác nhận
                  </Button>
                </div>,
              ]}
            >
              <div className="text-center h-20 flex flex-col justify-evenly">
                <p>Bạn chắc chắn muốn lưu lại nội dung kiểm duyệt?</p>
                {dataDetail?.access !== "Nội bộ" &&
                  dataDetail?.access !== "Giới hạn" &&
                  questionDetail?.access !== "Nội bộ" &&
                  questionDetail?.access !== "Giới hạn" && (
                    <p style={{ color: "red", fontStyle: "italic" }}>
                      Nội dung sẽ được gửi lên cấp Bộ sau khi duyệt.
                    </p>
                  )}
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingExam;
