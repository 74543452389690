import React from 'react';
import { useAuthStore } from "../../stores/stores";
import { StompSessionProvider } from "react-stomp-hooks";

const WebSocketComponent = (props: any) => {
    const backendBaseUrl = process.env.REACT_APP_BASE_API_URL;
    const accessToken = useAuthStore.getState().accessToken;
    const socketUrl = backendBaseUrl + 'v2/websocket?token=' + accessToken;

    return (
        <StompSessionProvider
            url={socketUrl}
            debug={(str: any) => { }}
            enabled={true}
        >
            {props.children}
        </StompSessionProvider>
    );
};

export default WebSocketComponent;