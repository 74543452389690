import dayjs, { Dayjs } from "dayjs";
import create from "zustand";
import { StateCreator } from "zustand";

export interface StaticReportState {
  staticReportType: string;
  searchQuery: string;
  filterValues: {
    lecturerIds: string[];
    universityIds: string[];
    industryGroupIds: string[];
    courseLevelIds: string[];
    courseIds: string[];
    timeUnit: string;
    timeType: string;
    dateFrom: Dayjs | string | null;
    dateTo: Dayjs | string | null;
    classId: number | null;
    searchKeywordModuleGroup: number | null;
    searchKeywordCourseStructureType: string;
    downloadModuleGroup: number | null;
    downloadCourseStructureType: string;
    viewModuleGroup: number | null;
    viewCourseStructureType: string;
    shareModuleGroup: number | null;
    shareCourseStructureType: string;
    moduleGroup: string | null;
    courseStructureType: string;
  };
  isSelectedUniversity?: boolean;
  isLoading: boolean;
  isZipLoading: boolean;
  tab: string;
  exportType: string;

  handleExportAllImagesAsZip: ((tabKey: string) => void) | null;
  setHandleExportAllImagesAsZip: (fn: (tabKey: string) => void) => void;
  callExportAllImages: (tabKey: string) => void;
  setFilterValues: (values: Partial<StaticReportState["filterValues"]>) => void;
  setStaticReportType: (type: string) => void;
  setIsLoading: (type: boolean) => void;
  setIsZipLoading: (loading: boolean) => void;
  setTab: (tab: string) => void;
  setExportType: (exportType: string) => void;

  setIsSelectedUniversity: (isSelected: boolean) => void;
}

export const staticReportSlice: StateCreator<StaticReportState> = (set, get) => ({
  staticReportType: "chart",
  searchQuery: "",
  filterValues: {
    lecturerIds: [],
    universityIds: [],
    industryGroupIds: [],
    courseLevelIds: [],
    courseIds: [],
    timeUnit: "day",
    timeType: "day",
    dateFrom: dayjs().subtract(7, "day"),
    dateTo: dayjs(),
    classId: null,
    searchKeywordModuleGroup: 3,
    searchKeywordCourseStructureType: "3",
    downloadModuleGroup: 3,
    downloadCourseStructureType: "3",
    viewModuleGroup: 3,
    viewCourseStructureType: "3",
    shareModuleGroup: 3,
    shareCourseStructureType: "3",
    moduleGroup: "3",
    courseStructureType: "3",
  },
  isSelectedUniversity: false,
  isLoading: false,
  isZipLoading: false,
  tab: "1",
  exportType: "",
  handleExportAllImagesAsZip: null,

  setFilterValues: (values) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        ...values,
      },
    }));
  },

  setIsSelectedUniversity: (isSelected: boolean) => {
    set({ isSelectedUniversity: isSelected });
  },

  setStaticReportType: (type: string) => {
    set({ staticReportType: type });
  },
  setIsLoading: (type: boolean) => {
    set({ isLoading: type });
  },
  setIsZipLoading: (loading: boolean) => {
    set({ isZipLoading: loading });
  },
  setTab: (tab: string) => {
    set({ tab: tab });
  },
  setExportType: (exportType: string) => {
    set({ exportType: exportType });
  },

  setHandleExportAllImagesAsZip: (fn: (tabKey: string) => void) => {
    set({ handleExportAllImagesAsZip: fn });
  },

  callExportAllImages: async (tabKey: string) => {
    const { handleExportAllImagesAsZip, setIsZipLoading } = get();

    if (handleExportAllImagesAsZip) {
      setIsZipLoading(true);
      await handleExportAllImagesAsZip(tabKey);
      setIsZipLoading(false);
    } else {
      console.warn("handleExportAllImagesAsZip chưa được khởi tạo");
    }
  },
});
