import React, { useState } from "react";
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { BlockMath } from "react-katex";
import "katex/dist/katex.min.css";
import {
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import Container from "./components/container";
import { Item } from "./components/SortableItem";

interface Variables {
  [key: string]: number;
}

type ItemsState = {
  [key: string]: string[];
};

const App: React.FC = () => {
  const [formulaParts, setFormulaParts] = useState<string[]>([]);
  const [variables, setVariables] = useState<Variables>({
    quiz1: 0,
    quiz2: 0,
  });
  const [items, setItems] = useState<ItemsState>({
    root: ["Quiz1", "Quiz2", "Quiz3", "Quiz4", "Quiz5"],
    container1: ["+", "-", "*", "/", "(", ")"],
    container2: [],
  });

  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const calculateResult = (): string => {
    try {
      const formula = formulaParts.join(" ");
      const formulaWithValues = formula.replace(
        /quiz\d+/g,
        (match) => variables[match]?.toString() || "0"
      );
      const result = eval(formulaWithValues);
      return result.toFixed(2);
    } catch {
      return "Lỗi công thức!";
    }
  };

  function findContainer(id: string): string | undefined {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveId(active.id as string);
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;

    console.log(event);

    if (!over) return;

    const activeId = active.id as string;
    const overId = over.id as string;

    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Xóa item khỏi container gốc
      const newActiveItems = activeItems.filter((item) => item !== activeId);

      // Thêm item vào container mới (ngay cả khi rỗng)
      const newOverItems = [...overItems, activeId];

      return {
        ...prev,
        [activeContainer]: newActiveItems,
        [overContainer]: newOverItems,
      };
    });
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) {
      setActiveId(null);
      return;
    }

    const activeId = active.id as string;
    const overId = over.id as string;

    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer) {
      setActiveId(null);
      return;
    }

    const activeIndex = items[activeContainer].indexOf(activeId);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((prev) => ({
        ...prev,
        [overContainer]: arrayMove(prev[overContainer], activeIndex, overIndex),
      }));
    }

    setActiveId(null);
  }

  return (
    <div className="math-recipe">
      <div style={{ padding: "16px", maxWidth: "800px", margin: "auto" }}>
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <Container
              id="root"
              items={items.root}
              strategy={horizontalListSortingStrategy}
            />
            <Container
              id="container1"
              items={items.container1}
              strategy={horizontalListSortingStrategy}
            />
            <Container
              id="container2"
              className="recipe-container"
              description="Kéo vào đây"
              items={items.container2}
              strategy={horizontalListSortingStrategy}
            />
          </DndContext>
        </div>

        {/* Hiển thị công thức */}
        <BlockMath math={formulaParts.join(" ")} />

        {/* Kết quả */}
        <h3>Kết quả:</h3>
        <p>{calculateResult()}</p>
      </div>
    </div>
  );
};

export default App;
