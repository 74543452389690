import { isEmpty, uniqueId } from "lodash";
import { QuestionTypeEnum } from "../constants";
import * as htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import { parse } from "mathjs";

export const renderStatusCourse = (status: any) => {
  switch (status) {
    case null:
      return (
        <>
          <div className="dot color-wait" />
          Chờ quản trị khoá học duyệt
        </>
      );
    case 3:
      return (
        <>
          <div className="dot color-wait" />
          Chờ quản trị khoá học duyệt
        </>
      );
    case 4:
      return (
        <>
          <div className="dot color-refuse" />
          Quản trị khoá học trả lại
        </>
      );
    case 5:
      return (
        <>
          <div className="dot color-wait" />
          Chờ hội đồng thẩm định duyệt
        </>
      );
    case 6:
      return (
        <>
          <div className="dot color-refuse" />
          Hội đồng thẩm định không duyệt
        </>
      );
    case 7:
      return (
        <>
          <div className="dot color-done" />
          Chờ tổ chuyên môn duyệt
        </>
      );
    // case 8:
    //   return (
    //     <>
    //       <div className="dot color-done" />
    //       Đã duyệt
    //     </>
    //   );
    // case 9:
    //   return (
    //     <>
    //       <div className="dot color-wait" />
    //       Chờ tổ chuyên phê duyệt
    //     </>
    //   );
    // case 10:
    //   return (
    //     <>
    //       <div className="dot color-done" />
    //       Chờ xuất bản
    //     </>
    //   );
    case 11:
      return (
        <>
          <div className="dot color-refuse" />
          Tổ chuyên môn trả lại
        </>
      );
    case 12:
      return (
        <>
          <div className="dot color-wait" />
          Chờ xuất bản
        </>
      );
    case 13:
      return (
        <>
          <div className="dot color-done" />
          Đã xuất bản
        </>
      );
    default:
      return "";
  }
};

export const renderQuestionNameByType = (type: number) => {
  switch (type) {
    case QuestionTypeEnum.CHOOSE_TEXT:
      return "Câu hỏi nhiều lựa chọn văn bản";
    case QuestionTypeEnum.CHOOSE_IMAGE:
      return "Câu hỏi nhiều lựa chọn ảnh";
    case QuestionTypeEnum.CHOOSE_VIDEO:
      return "Câu hỏi nhiều lựa chọn video";
    case QuestionTypeEnum.TRUE_OR_FALSE:
      return "Câu hỏi dạng đúng sai";
    case QuestionTypeEnum.DROP_DOWN:
      return "Câu hỏi dạng danh sách";
    case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
      return "Câu hỏi dạng nhiều câu trả lời văn bản";
    case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
      return "Câu hỏi dạng nhiều câu trả lời ảnh";
    case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
      return "Câu hỏi dạng nhiều câu trả lời video";
    case QuestionTypeEnum.CONNECT:
      return "Câu hỏi dạng kết nối";
    case QuestionTypeEnum.SHORT_TEXT:
      return "Câu hỏi trả lời dạng văn bản ngắn";
    case QuestionTypeEnum.FILE_UPLOAD:
      return "Câu hỏi trả lời bằng file đính kèm";
    case QuestionTypeEnum.VIDEO:
      return "Câu hỏi trả lời bằng ghi hình";
    case QuestionTypeEnum.MP3:
      return "Câu hỏi trả lời bằng ghi âm";
    default:
      break;
  }
};

export const renderQuestionDescriptionByType = (type: number) => {
  switch (type) {
    case QuestionTypeEnum.CHOOSE_TEXT:
      return "Là loại câu hỏi có nhiều lựa chọn, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_IMAGE:
      return "Là loại câu hỏi có nhiều lựa chọn hình ảnh kèm mô tả, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_VIDEO:
      return "Là loại câu hỏi có nhiều lựa chọn video kèm mô tả, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.TRUE_OR_FALSE:
      return "Là loại câu hỏi có 02 lựa chọn và chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.DROP_DOWN:
      return "Là loại câu hỏi có nhiều lựa chọn trong danh sách, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
      return "Là loại câu hỏi có nhiều lựa chọn, và có nhiều đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
      return "Là loại câu hỏi có nhiều lựa chọn hình ảnh kèm mô tả, và có nhiều đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
      return "Là loại câu hỏi có nhiều lựa chọn video kèm mô tả, và có nhiều đáp án là đúng";
    case QuestionTypeEnum.CONNECT:
      return "Là loại câu hỏi có nhiều cặp lựa chọn cần được kết nối với nhau";
    case QuestionTypeEnum.SHORT_TEXT:
      return "Là loại câu hỏi cho phép trả lời bằng cách nhập đoạn văn bản ngắn, có nhiều đáp án là đúng";
    case QuestionTypeEnum.FILE_UPLOAD:
      return "Là loại câu hỏi cho phép trả lời bằng cách gửi lên file đính kèm. hình(video)";
    case QuestionTypeEnum.VIDEO:
      return "Là loại câu hỏi cho phép trả lời bằng cách gửi lên file ghi hình(video)";
    case QuestionTypeEnum.MP3:
      return "Là loại câu hỏi cho phép trả lời bằng cách gửi lên file ghi âm";
    default:
      break;
  }
};

export function getPathnameFromUrl(url: string): string {
  try {
    const parsedUrl = new URL(url);

    return parsedUrl.pathname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return "";
  }
}

export const getContrastingColor = (backgroundColor: string) => {
  if (!/^#([0-9A-F]{3}){1,2}$/i.test(backgroundColor)) {
    console.warn(
      `Invalid background color: ${backgroundColor}. Defaulting to black.`
    );
    return "#FFFFFF";
  }
  const color = backgroundColor.slice(1);
  const expandedColor =
    color.length === 3
      ? color
          .split("")
          .map((char) => char + char)
          .join("")
      : color;

  const r = parseInt(expandedColor.substring(0, 2), 16);
  const g = parseInt(expandedColor.substring(2, 4), 16);
  const b = parseInt(expandedColor.substring(4, 6), 16);

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128 ? "#FFFFFF" : "#000000";
};

export const RenderUnitIcon = (type: string) => {
  switch (type) {
    case "video":
      return <img src={"/images/Cinema.png"} className="multimedia-img" />;
    case "mp3":
      return <img src={"/images/Audio.png"} className="multimedia-img" />;
    case "text":
      return <img src={"/images/Text.png"} className="multimedia-img" />;
    case "pdf":
      return <img src={"/images/PDF.png"} className="multimedia-img" />;

    case "scorm":
    case "xapi":
      return <img src={"/images/Frames.png"} className="multimedia-img" />;

    default:
      return "";
  }
};
export function downloadWordDocument(
  htmlContent: string,
  fileName: string = "document.docx"
): void {
  const docx = htmlDocx.asBlob(htmlContent);
  saveAs(docx, fileName);
}

const removeDiacritics = (str: string) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim();

export const filterOption = (
  input: string,
  option?: { label: string; value: string }
) =>
  removeDiacritics(option?.label ?? "")
    .toLowerCase()
    .includes(removeDiacritics(input).toLowerCase());

export const formatCurrencyToVND = (amount: number): string => {
  return (
    amount
      ?.toLocaleString("vi-VN", { style: "currency", currency: "VND" })
      .replace("₫", "")
      .trim() + " VNĐ"
  );
};

export const getFirstLetter = (words: string) => {
  return words?.length >= 2
    ? words[0][0].toUpperCase() + words[words.length - 1][0].toUpperCase()
    : "";
};

export const checkAndCountdown = (targetDate: Date): number => {
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const diffInTime = targetDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(diffInTime / oneDay);
  return daysDifference;
};

export const generateRandomId = (prefix: string = ""): string => {
  return uniqueId(prefix);
};

export function parseExpression(tokens: string[]) {
  if (!tokens.length) return;
  const results = [];

  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i]?.startsWith("id{")) {
      let blockId = tokens[i];
      let coefficient = 1.0;

      // Look ahead to find the coefficient for the current id
      for (let j = i + 1; j < tokens.length; j++) {
        if (tokens[j] === "*") {
          coefficient = parseFloat(tokens[j + 1]);
          j++; // Skip the number after '*'
          break;
        }
      }

      results.push({ blockId: blockId, coefficient: coefficient });
    }
  }

  return results;
}

export function parseBlockArray(array: any) {
  if (!array?.length) return [];

  return array.map((item: any) => {
    if (typeof item.blockId === "string" && item.blockId?.startsWith("id{")) {
      return {
        blockId: parseInt(item.blockId.match(/\d+/)[0]),
        coefficient: item.coefficient,
      };
    }
    return item;
  });
}

export function sanitizeExpression(str: string): string {
  // Bước 1: normalize chuỗi, sau đó remove các dấu kết hợp (diacritics)
  //         "đ" và "Đ" cũng được thay thế thành "d" và "D".
  let result = str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // xóa dấu
    .replace(/[đĐ]/g, (m) => (m === 'đ' ? 'd' : 'D'));

  // Bước 2: Thay thế mọi khoảng trắng bằng ký tự ''
  result = result.replace(/\s+/g, '');

  return result;
}

export const convertToLatex = (expression: string): string => {
  if(!expression) return ""
  try {
    const cleaned = sanitizeExpression(expression);
    const node = parse(cleaned);

    let latexString = node.toTex();
    latexString = latexString.replace(/\\cdot/g, "\\times");

    return latexString;
  } catch (error) {
    console.error("convertToLatex error:", error);
    return "";
  }
};
