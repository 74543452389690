import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getLearningMaterialDetail } from '../../../../service/learning-material'
import "./LearningMaterialDetail.scss"
import "../../components/view/View.scss"
import VideoPlayer from '../view/VideoPlayer'
import TextViewer from '../view/TextViewer'
import ScormXapiViewer from '../view/ScormXapiViewer'
import CustomAudioPlayer from '../view/AudioPlayer'
import { Breadcrumb } from 'antd'
type Props = {
  viewDetail?: boolean
}

const LearningMaterialDetail = (props: Props) => {
  const { viewDetail = true } = props
  const { id } = useParams()

  const [detail, setDetail] = useState<any>(null)
  useEffect(() => {
    const fetchLearningMaterialDetail = async () => {
      try {
        const res = await getLearningMaterialDetail(Number(id))
        setDetail(res?.data.data)
      } catch (error) {

      }
    }
    fetchLearningMaterialDetail()
  }, [id])

  const RenderDetail = (type: string) => {
    switch (type) {
      case 'video':
        return <VideoPlayer
          url={detail?.mainKey || detail?.mainKeyUrl}
        />;
      case 'mp3':
        return <CustomAudioPlayer
          url={detail?.mainKey}
        />;
      case 'text':
        return <TextViewer content={detail?.documentText} />;
      case 'pdf':
        return (
          <div className="view-wrapper">
            <iframe
              allow="fullscreen"
              scrolling="yes"
              src={detail?.mainKey}
            />
          </div>
        );
      case 'scorm':
      case 'xapi':
        return <ScormXapiViewer
          src={detail?.mainKey}
        />;

      default:
        return ""
    }
  }
  return (
    <div
      className='learning-material-detail'>
      {viewDetail &&
        <div className='detail-title'>
          <Breadcrumb
            items={[
              {
                title: 'Chi tiết học liệu',
              },
              {
                title: <div className="name">
                  {detail?.name}
                </div>,
              },
            ]}
          />

        </div>
      }
      <div className='description'>{detail?.shareEnterpriseName ? detail?.shareEnterpriseName : null + "/ " + detail?.shareUserFullName ? detail?.shareUserFullName : undefined}</div>
      <div className='view-detail'>
        {RenderDetail(detail?.module)}
      </div>
    </div>
  )
}

export default LearningMaterialDetail