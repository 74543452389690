import {AxiosResponse} from "axios";
import { axiosConfigV2 } from "../../../config/api/configApiv2";

export const getListQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-quiz-bank`, data);
};

export const getListQuestionStorage: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-quiz-bank-in-storage`, data);
};

export const getListQuestionBank: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-quiz-bank`, data);
};

export const getDetailListQuestion: (
  id: any
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/question-bank/get-quiz-bank/${id}`);
};

export const postSentPermissionQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/censor/send-approve`, data);
};

export const postEditPermissionQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/question-bank/update-access-unit-bank`, data);
};

export const postPublishQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/question-bank/censor/publish`, data);
};

export const getHistoryApproveQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-quiz-question-bank-approved-history`, data);
};

export const deleteQuestionBank: (
  id: number | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/question-bank/delete-unit-bank/${id}`);
};

export const deleteQuestionStorageBank: (
  id: number | undefined, isStorage: boolean
) => Promise<AxiosResponse<any>> = (id, isStorage) => {
  return axiosConfigV2.delete(`/question-bank/remove-storage/${id}/${isStorage}`);
};

export const getDetailSentPermission: (
  id: number | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/question-bank/censor/detail-send-approve-update/${id}`);
};

export const postAddTopicPersonalStorageQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/add-quiz-bank-to-unit-bank`, data);
};

export const postAddTopicToCourseQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/add-quiz-bank-to-course-unit`, data);
};

export const postAddNewTopicQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/add-quiz-bank-to-new-unit-bank`, data);
};

export const postSaveQuestionToStorage: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.post(`/question-bank/add-quiz-bank-to-storage?bankId=${id}`);
};

export const getQuizCourseList: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/get-quiz-by-course`, data);
};

