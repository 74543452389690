import { Flex, Form, Tag } from "antd";
import CustomCard from "../../../../custom-card/CustomCard";
import "./plan-info.scss";
import { useEffect, useState } from "react";
import { getAllObjectSurveyLookup } from "../../../../../service/course-construct/survey-plan";
import moment from "moment";
import {
  convertStringToArrayNum,
  findObjNameById,
} from "../../../../../utils/arrays";
import Link from "antd/es/typography/Link";
import { getUniversity } from "../../../../../service/common";
import {
  getIndustry,
  postCourseSearch,
} from "../../../../../service/course-construct";

type SurveyPlanInfoProps = {
  surveyPlan?: any;
};

const SurveyPlanInfo = ({ surveyPlan }: SurveyPlanInfoProps) => {
  const [viewDetail, setViewDetail] = useState<boolean>(false);
  const [dataOptions, setDataOption] = useState<any>({
    dataUniversity: [],
    dataCourseRegistration: [],
    dataCourseCompleted: [],
    dataSpecialized: [],
  });

  const getObjectSurvey = async () => {
    try {
      const { data } = await getAllObjectSurveyLookup();
      const listObject = data.data;
    } catch (error) {
      console.error();
    }
  };

  // convert data
  const handleReturnStatus = (statusSurvey: string) => {
    switch (statusSurvey) {
      case "not_started":
        return "Chưa tiến hành";
      case "in_process":
        return "Đang tiến hành";
      case "finish":
        return "Hết hạn";
      default:
        return "";
    }
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    return moment(dateString).format("DD/MM/YYYY");
  };

  // Render selected objects
  const objectRender = (title: string, object: string, options: any) => {
    return (
      <>
        {Array.isArray(convertStringToArrayNum(object)) ? (
          <div className="row-survey-content">
            <label className="label-survey">{title}</label>
            <Flex className="survey-content" gap="4px 0">
              {convertStringToArrayNum(object)?.map(
                (item: any, index: number) =>
                  // render 3 items
                  index < 3 && !viewDetail ? (
                    <Tag key={index}>{findObjNameById(options, item)}</Tag>
                  ) : viewDetail ? (
                    // render full items
                    <>
                      <Tag key={index}>{findObjNameById(options, item)}</Tag>
                    </>
                  ) : (
                    <></>
                  )
              )}
              {viewDetail ? (
                <></>
              ) : (
                // Number of remaining items
                <>
                  {convertStringToArrayNum(object).length > 3 ? (
                    <Tag>{convertStringToArrayNum(object).length - 3}+</Tag>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Flex>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderObjectsSelected = () => {
    if (surveyPlan && surveyPlan.applicatbleObjectKey) {
      return (
        <>
          {objectRender(
            "Theo trường",
            surveyPlan.applicatbleObjectSchools,
            dataOptions.dataUniversity
          )}
          {objectRender(
            "Theo khóa học đăng ký",
            surveyPlan.applicatbleCourseRegister,
            dataOptions.dataCourseRegistration
          )}
          {objectRender(
            "Theo khóa học hoàn thành",
            surveyPlan.applicatbleCourseCompleted,
            dataOptions.dataCourseCompleted
          )}
          {objectRender(
            "Theo chuyên ngành",
            surveyPlan.applicatbleIndustry,
            dataOptions.dataSpecialized
          )}

          <Link className="survey-content-more" onClick={handleClickMore}>
            {!viewDetail ? <>Xem chi tiết đối tượng</> : <>Thu gọn</>}
          </Link>
        </>
      );
    } else {
      return (
        <>
          <p className="survey-content">Bất kì ai có liên kết</p>
        </>
      );
    }
  };

  const handleClickMore = () => {
    setViewDetail(!viewDetail);
  };

  // useEffect
  useEffect(() => {
    Promise.all([getUniversity(), getIndustry(), postCourseSearch({})])
      .then((res) => {
        const dataUniversities = res[0].data.data;
        const dataIndustries = res[1].data.data;
        const dataCourse = res[2].data.data.data;
        setDataOption({
          ...dataOptions,
          dataUniversity: dataUniversities,
          dataCourseRegistration: dataCourse,
          dataCourseCompleted: dataCourse,
          dataSpecialized: dataIndustries,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getObjectSurvey();
  }, [surveyPlan]);

  return (
    <>
      <div className="wrapper-settings plan-info">
        <section className="setting-survey">
          <CustomCard
            className="survey-card"
            title="Thông tin kế hoạch khảo sát"
          >
            <div>
              <Form.Item className="setting-item" name="title" label="Tiêu đề">
                <p className="survey-content">{surveyPlan?.name}</p>
              </Form.Item>
              <Form.Item
                className="setting-item"
                name="planCode"
                label="Mã kế hoạch"
              >
                <p className="survey-content">{surveyPlan?.code}</p>
              </Form.Item>
              <Form.Item
                className="setting-item"
                name="surveyMethod"
                label="Phương pháp khảo sát"
              >
                <p className="survey-content">
                  {surveyPlan?.surveyMethod
                    ? "Phỏng vấn trực tiếp"
                    : "Phiếu khảo sát trực tuyến"}
                </p>
              </Form.Item>
              <Form.Item
                className="setting-item"
                name="executionTime"
                label="Thời gian thực hiện"
              >
                <p className="survey-content">
                  {formatDate(surveyPlan?.timeStart)} -{" "}
                  {formatDate(surveyPlan?.timeEnd)}
                </p>
              </Form.Item>
              <Form.Item
                className="setting-item"
                name="object"
                label="Đối tượng"
              >
                <div className="object-item-selected">
                  {renderObjectsSelected()}
                </div>
              </Form.Item>
              <Form.Item
                className="setting-item"
                name="status"
                label="Trạng thái"
              >
                <div className="table-status survey-content">
                  <div
                    className="color-icon"
                    style={{
                      backgroundColor:
                        surveyPlan?.statusSurvey === "in_process"
                          ? "#52C41A"
                          : surveyPlan?.statusSurvey === "not_started"
                            ? "#1677FF"
                            : surveyPlan?.statusSurvey === "finish"
                              ? "#D9D9D9"
                              : "white",
                    }}
                  ></div>
                  {handleReturnStatus(surveyPlan?.statusSurvey)}
                </div>
              </Form.Item>
            </div>
          </CustomCard>
        </section>
      </div>
    </>
  );
};

export default SurveyPlanInfo;
