import { BellOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import "./style.scss";

import {
  countUnviewed,
  editNotificationViewed,
  searchNotifications,
} from "../../service/notifications";
import { globalStore, useNotificationStore } from "../../stores/stores";
import {
  NotificationData,
  NotificationItemProps,
} from "../../types/notifications";
import EmptyComponent from "../empty";
import NotificationItem from "./NotificationItem";
import { getContrastingColor } from "../../utils/functionUltils";

function MyNotificationDrop() {
  const navigate = useNavigate();
  const { refetch, onRefetch } = useNotificationStore();
  const { reloadAdminInterface } = globalStore();

  const [open, setOpen] = useState(false);
  const [notifyData, setNotifyData] = useState<NotificationData>({
    totalElements: 0,
  });
  const [countOfUnViewed, setCountOfUnViewed] = useState<number>(0);
  const [color, setColor] = useState<any>({});
  const { personalInfo } = globalStore();

  const hide = async (it?: NotificationItemProps) => {
    if (it && !it.isViewed) {
      try {
        await editNotificationViewed(Number(it?.id), true);
        onRefetch();
      } catch (err: any) {
        console.error(err);
      }
    }
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const getNotifications = async () => {
      const res = await searchNotifications({});
      setNotifyData(res.data);
    };
    const getCountNotificationViewed = async () => {
      const res = await countUnviewed();
      setCountOfUnViewed(res.data.data);
    };
    getNotifications();
    getCountNotificationViewed();
  }, [refetch]);

  const renderContent = () => {
    const notifications = notifyData?.content || [];
    if (isEmpty(notifications)) {
      return (
        <div className="notification-list pb-2 pt-2">
          <EmptyComponent description="Không có thông báo" />
        </div>
      );
    }
    return (
      <div>
        <div className="notification-list">
          {notifications?.map((item: NotificationItemProps) => (
            <NotificationItem
              key={item.id}
              {...item}
              onClick={() => {
                navigate(`${routesConfig.myNotification}?id=${item.id}`);
                hide(item);
              }}
              active={!item.isViewed}
            />
          ))}
        </div>
        <div className="hr-tag"></div>

        <div
          className="btn-view-more w-full-100 center"
          onClick={() => {
            navigate(routesConfig.myNotification);
            hide();
          }}
        >
          <span className="text-primary font-size-14">
            <p>Xem tất cả</p>
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getColor();
  }, [navigate, reloadAdminInterface, personalInfo]);

  const getColor = async () => {
    setColor(personalInfo?.color);
  };
  const contrastingTextColor = getContrastingColor(color?.menuColor);

  return (
    <Popover
      content={renderContent()}
      title={
        <div className="flex align-center justify-space-between">
          <span className="font-size-20">
            Thông báo
            <span className="pl-2 notify-not-read">
              {countOfUnViewed > 0 ? `(${countOfUnViewed} chưa đọc)` : null}
            </span>
          </span>

          {/* <div className="pointer" onClick={hide}>
            <CloseOutlined color="#98A2B3" />
          </div> */}
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName="notification-popup"
    >
      <div className="badgeon-icon pointer">
        <div className="icon">
          <BellOutlined
            style={{
              color: contrastingTextColor,
              fontSize: "14px",
            }}
          />
        </div>
        <div className="badge-wrapper">
          <div className="offset">
            <div className="badgecount">
              {countOfUnViewed ? (
                <div className="badge">
                  <div className="number">{countOfUnViewed}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
}

export default MyNotificationDrop;
