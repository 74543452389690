import { DeleteOutlined, EyeOutlined, MinusCircleOutlined, MoreOutlined, PlusCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Layout, MenuProps, message, Space, TableColumnsType, TableProps } from 'antd';
import React, { ChangeEvent, useEffect, useMemo, useState, KeyboardEvent } from 'react';
import { formatOriginTimev3 } from "../../../utils/converDate";
import TableData from "../../../components/table-data/TableData";
import FilterExamTable
  from '../../../components/course-library/personal-exam-repository/bank-question/filter-table-exam';
import FilterGroup from "../../../components/course-library/personal-exam-repository/bank-question/filter-group";
import { useForm } from "antd/es/form/Form";
import { FilterData } from "../../../components/course-library/personal-exam-repository/bank-question/filter-group/filter";
import DetailQuestion from '../../../components/course-library/personal-exam-repository/list-question/detail-question';
import PageHeader from '../../../components/page-header/PageHeader';
import { deleteQuestionStorageBank, getListQuestionBank, getListQuestionStorage, postSaveQuestionToStorage } from '../../../service/library/question-list';
import { isEmpty } from 'lodash';
import { getAllProductionUnit } from '../../../service/library';
import BankQuestionStorageModal from '../../../components/modal/bank-question-storage';


type ListExamPersonalProps = {
  title: string,
}

const mockData = [
  {
    id: 1,
    code: '001',
    name: 'Test',
    numberOfQuestion: 30,
    topic: 'Kỹ thuật',
    usage: 2,
    permission: 'Nội bộ',
    status: 'Bản nháp',
    updatedDate: '20/12/2024',
    checked: false,
  },
  {
    id: 2,
    code: '002',
    name: 'Test2',
    numberOfQuestion: 30,
    topic: 'Kỹ thuật 2',
    usage: 2,
    permission: 'Nội bộ 2',
    status: 'Bản nháp 2',
    updatedDate: '20/12/2024',
    checked: false,
  }
]


const BankQuestion = ({ title }: ListExamPersonalProps) => {

  const [form] = useForm()

  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedDetail, setIsOpenedDetail] = useState<boolean>(false)
  const [isOpenedHistory, setIsOpenedHistory] = useState<boolean>(false)
  const [isOpenedEditPermission, setIsOpenedEditPermission] = useState<boolean>(false)
  const [isOpenedSentPermission, setIsOpenedSentPermission] = useState<boolean>(false)
  const [isOpenedPublishModal, setIsOpenedPublishModal] = useState<boolean>(false)
  const [isOpenBankStorageModal, setIsOpenBankStorageModal] = useState<boolean>(false)

  const [idDetailData, setIdDetailData] = useState<number | undefined>();
  const [idUnitDetailData, setIdUnitDetailData] = useState<number | undefined>();

  const [page, setPage] = useState<number>(1)
  const [size, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string>("")
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [typeQuestion, setTypeQuestion] = useState<boolean>(false) // false: danh sách câu hỏi, //true: câu hỏi lưu trũ

  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState<any>(mockData);

  const [countChoose, setCountChoose] = useState<any>([])
  const [dataOptions, setDataOptions] = useState({
    dataTopic: [
      { id: 1, name: "Nhiều lựa chọn văn bản" },
      { id: 2, name: "Nhiều lựa chọn ảnh" },
      { id: 3, name: "Nhiều lựa chọn video" },
      { id: 4, name: "Đúng/Sai" },
      { id: 5, name: "Danh sách" },
      { id: 6, name: "Nhiều đáp án văn bản" },
      { id: 7, name: "Nhiều đáp án hình ảnh" },
      { id: 8, name: "Nhiều đáp án video" },
      { id: 9, name: "Câu trả lời ngắn" },
      { id: 10, name: "Điển vào chỗ trống" },
      { id: 11, name: "Kết nối" },
      { id: 12, name: "Sắp xếp" },
      { id: 13, name: "Ghi âm" },
      { id: 14, name: "Ghi hình" },
      { id: 15, name: "File đính kèm" },
      { id: 16, name: "Trình soạn thảo" },
    ],
    dataHierarchy: [
      {
        id: 1,
        name: "Cấp Bộ"
      },
      {
        id: 2,
        name: "Cấp Trường"
      },
      {
        id: 3,
        name: "Cấp Giảng Viên"
      }
    ],
    dataDifficulty: [
      {
        id: 1,
        name: "Cơ bản"
      },
      {
        id: 2,
        name: "Trung bình"
      },
      {
        id: 3,
        name: "Nâng cao"
      }
    ],
    dataTypeProduction: [
      {
        id: 1,
        name: "Giảng viên"
      },
      {
        id: 2,
        name: "Trường"
      },
      {
        id: 3,
        name: "Cấp bộ"
      },
    ],
    dataUnit: []
  })

  const [filterData, setFilterData] = useState<FilterData>({
    questionId: null,
    typeProduction: null,
    unit: null,
    difficulty: null,
    permission: null,
    updatedDate: null,
    status: null,
  });

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      {
        key: "0",
        label: "Gửi kiểm duyệt",
      },
      {
        key: "1",
        label: "Xuất bản",
      },
      {
        key: "2",
        label: "Chỉnh sửa",
      },
      {
        key: "3",
        label: "Sửa quyền truy cập",
      },
      {
        key: "4",
        label: "Lịch sử kiểm duyệt",
      },
      {
        key: "5",
        label: "Xóa",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
    ];
    return options1;
  };

  const saveQuestionToStorage = async (id: string) => {
    await postSaveQuestionToStorage(id).then((res) => {
      if (res.status === 200) {
        message.success("Lưu trữ câu hỏi thành công")
        getListData()
      }
    })
  }

  const unSaveQuestionToStorage = async (id: string) => {
    await deleteQuestionStorageBank(Number(id), false).then((res) => {
      if (res.status === 200) {
        message.success("Xoá lưu trữ câu hỏi thành công")
        getListData()
      }
    })
  }

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Mã câu hỏi',
      dataIndex: "code",
      key: "code",
      width: 5,
    },
    {
      title: "Câu hỏi",
      dataIndex: "name",
      key: "name",
      width: 15,
      sorter: true,
      render: (_: any, record: any) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: record?.question }}></div>
        );
      },
    },
    {
      title: "Loại câu hỏi",
      dataIndex: "type",
      key: "type",
      width: 7,
      sorter: true,
      render: (_: any, record: any) => {
        const arr: any = dataOptions?.dataTopic?.filter((item: any) => item.id === record?.type)
        return (
          <p>{arr?.[0]?.name}</p>
        );
      },
    },
    {
      title: "Độ khó",
      dataIndex: "level",
      key: "level",
      width: 7,
      render: (_: any, record: any) => {
        const arr: any = dataOptions?.dataDifficulty?.filter((item) => item.id === record?.level)
        return (
          <p>{arr?.[0]?.name}</p>
        );
      },
    },
    {
      title: "Phân cấp",
      dataIndex: "usage",
      key: "usage",
      width: 5,
      render: (_: any, record: any) => {
        const arr: any = dataOptions?.dataTypeProduction?.filter((item) => item.id === record?.typeProduction)
        return (
          <p>{arr?.[0]?.name}</p>
        );
      },
    },
    {
      title: "Lượt dùng",
      dataIndex: "usage",
      key: "usage",
      width: 5,
    },
    {
      title: "Đơn vị xuất bản",
      dataIndex: "productUnitName",
      key: "productUnitName",
      width: 5,
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updatedDate",
      key: "updatedDate",
      sorter: true,
      width: 8,
      render: (_: any, record) => {
        return (
          <>{record.updatedDate ? formatOriginTimev3(record.updatedDate) : null}</>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 5,
      render: (_, record, index) => {
        const location = {
          id: `${record.id}`,
        };

        const handleChangeCount = (type: string) => {
          const arr = [...dataTable]
          arr[index].checked = !arr[index].checked
          setDataTable(arr)

          if (type === "add") {
            setCountChoose([...countChoose, ...[record]])
          } else {
            const findObj = countChoose.filter((item: any) => item.id !== record.id)
            setCountChoose(findObj)
          }
        }

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenedDetail(true)
                  setIdDetailData(record.id)
                  setIdUnitDetailData(record?.unitId)
                }}
              >
                <EyeOutlined />
              </a>
              <>
                {
                  !record?.checked ?
                    <Button onClick={() => handleChangeCount("add")}><PlusCircleOutlined /></Button> :
                    <Button onClick={() => handleChangeCount("")}><MinusCircleOutlined /></Button>
                }
                {
                  !record.storageStatus ?
                    <Button onClick={() => {
                      saveQuestionToStorage(record?.id)
                    }}><PushpinOutlined /></Button>
                    :
                    <Button onClick={() => unSaveQuestionToStorage(record?.id)}><DeleteOutlined className='text-red-500' /></Button>
                }
              </>
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ];

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
    }));
  }, [dataTable]);

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: page,
      size: size,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      keyword: searchValue.trim() || undefined,
      topics: [],
      access: [],
      level: filterData.difficulty,
      type: filterData.questionId,
      createdDateFrom: !isEmpty(filterData.updatedDate) && !isEmpty(filterData.updatedDate?.[0]) ? `${filterData.updatedDate?.[0]}T00:00:00.000Z` : null,
      createdDateTo: !isEmpty(filterData.updatedDate) && !isEmpty(filterData.updatedDate?.[1]) ? `${filterData.updatedDate?.[1]}T00:00:00.000Z` : null,
      publicStatus: [],
      isPublish: true,
      typeProduction: filterData.typeProduction ?? [],
      uuid: filterData.unit ?? [],
    }

    await getListQuestionBank(data).then((res) => {
      if (res.status === 200) {
        const { data, total } = res.data.data
        const arr = data?.map((item: any) => {
          return {
            ...item,
            checked: false
          }
        })
        setDataTable(arr)
        setTotalRecords(total)

        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    })
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  }

  const handleOpen = () => {
    setIsOpened(true)
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  }

  const handleSubmitSearch = () => {
    getListData()
  }

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      questionId: null,
      typeProduction: null,
      unit: null,
      difficulty: null,
      permission: null,
      updatedDate: null,
      status: null,
    })
  }

  const onFinish = () => {

  }

  const closeForm = () => {
    // setDetailData({});
    setIsOpened(false);
    // setIsOpenedEdit(false);
    form.resetFields();
    // setCountSchool([]);
    // setCountSchoolSponsor([]);
    // setError({});
  }

  const closeDetail = () => setIsOpenedDetail(false)
  const saveData = () => {
    form.submit();
  };

  useEffect(() => {
    Promise.all([getAllProductionUnit({ name: null })]).then((res) => {
      const listUnit = res[0].data.data.map((item: any) => {
        return {
          id: item?.uuid,
          name: item?.name,
        }
      });
      setDataOptions({
        ...dataOptions,
        dataUnit: listUnit,
      })
    })
  }, [])

  useEffect(() => {
    getListData()
  }, [page, size])

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === "filterForm") {
          getListData()
        }
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterExamTable
              handleSearch={handleSearch}
              handleOpen={handleOpen}
              handleSubmitSearch={handleSubmitSearch}
              handleChangeInput={handleChange}
              handleOpenFilter={handleOpenFilter}
              searchValue={searchValue}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
              countChoose={countChoose}
              setIsOpenBankStorageModal={setIsOpenBankStorageModal}
            />
          </div>
          <FilterGroup
            form={form}
            showFilter={showFilter}
            clearFilter={clearFilter}
            setFilterData={handleChangeFilter}
            filterData={filterData}
            dataOptions={dataOptions}
            onFinish={onFinish}
            typeQuestion={typeQuestion}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataColumns={newColumns}
            dataTable={dataTable}
            setPageNumber={setPage}
            setPageSize={setPageSize}
            pageNumber={page}
            pageSize={size}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            hideSelection={false}
            setSelectedRowKeysProps={setSelectedRowKeys}
            pagination={true}
            handleTableChangeProps={handleTableChange} />
          <DetailQuestion open={isOpenedDetail} onClose={() => setIsOpenedDetail(false)} idDetailData={idDetailData} dataOptions={dataOptions} idUnitDetailData={idUnitDetailData} />
        </div>
      </Form.Provider>

      <BankQuestionStorageModal
        open={isOpenBankStorageModal}
        closeModal={() => setIsOpenBankStorageModal(false)}
        countChoose={countChoose}
        getListData={getListData}
      />
    </Layout>
  );
};

export default BankQuestion;