// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certificate-modal {
  min-width: fit-content;
  overflow: auto; }
  .certificate-modal .ant-modal-content {
    padding: 24px; }
    .certificate-modal .ant-modal-content .certificate-loading {
      width: 297mm;
      height: 210mm;
      display: flex;
      align-items: center;
      justify-content: center; }
    .certificate-modal .ant-modal-content .certificate {
      width: 297mm;
      height: 210mm;
      margin: 0 auto;
      box-sizing: border-box;
      border: 1px solid #ccc;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
      @media (max-width: 1024px) {
        .certificate-modal .ant-modal-content .certificate {
          transform: scale(0.8);
          transform-origin: top center; } }
      @media (max-width: 768px) {
        .certificate-modal .ant-modal-content .certificate {
          transform: scale(0.6);
          transform-origin: top center; } }
      @media (max-width: 480px) {
        .certificate-modal .ant-modal-content .certificate {
          transform: scale(0.4);
          transform-origin: top center; } }
`, "",{"version":3,"sources":["webpack://./src/pages/course-management/course-student-list/components/certificate-modal/CertificateModal.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc,EAAA;EAFhB;IAKI,aAAa,EAAA;IALjB;MAQM,YAAY;MACZ,aAAa;MACb,aAAa;MACb,mBAAmB;MACnB,uBAAuB,EAAA;IAZ7B;MAgBM,YAAY;MACZ,aAAa;MACb,cAAc;MACd,sBAAsB;MACtB,sBAAsB;MACtB,gBAAgB;MAChB,uCAAuC,EAAA;MAEvC;QAxBN;UAyBQ,qBAAqB;UACrB,4BAA4B,EAAA,EAY/B;MATC;QA7BN;UA8BQ,qBAAqB;UACrB,4BAA4B,EAAA,EAO/B;MAJC;QAlCN;UAmCQ,qBAAqB;UACrB,4BAA4B,EAAA,EAE/B","sourcesContent":[".certificate-modal {\n  min-width: fit-content;\n  overflow: auto;\n\n  .ant-modal-content {\n    padding: 24px;\n\n    .certificate-loading {\n      width: 297mm;\n      height: 210mm;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n\n    .certificate {\n      width: 297mm;\n      height: 210mm;\n      margin: 0 auto;\n      box-sizing: border-box;\n      border: 1px solid #ccc;\n      background: #fff;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n\n      @media (max-width: 1024px) {\n        transform: scale(0.8);\n        transform-origin: top center;\n      }\n\n      @media (max-width: 768px) {\n        transform: scale(0.6);\n        transform-origin: top center;\n      }\n\n      @media (max-width: 480px) {\n        transform: scale(0.4);\n        transform-origin: top center;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
