import { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/page-header/PageHeader";
import { Button, Dropdown, Form, Layout, message, Popconfirm, Space, Switch, TableColumnsType, TableProps, UploadProps } from 'antd'
import FilterTable from "./components/filter-table/FilterTable";
import { DataType } from "../../types/listUsers";
import { CheckCircleOutlined, CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, InboxOutlined, KeyOutlined, MoreOutlined } from "@ant-design/icons";
import FormFilter from "./components/filter-group/FormFilter";
import { useSearchParams } from "react-router-dom";
import TableData from "../../components/table-data/TableData";
import { get, isEmpty } from "lodash"
import { validateFieldCreate } from "./constants/data";
import { createUser, getAccountUsers, } from "../../service/list-account-users/listAccountUsersApi";
import { HelpService } from "../../service/helper.service";
import { combineArray } from "../../utils/arrays";
import { formatOriginTime } from "../../utils/converDate";
import CommonModal from "../../components/modal/common";
import DetailStudentsAccountDrawer from "../../components/drawer/students-account";
import ConfirmVerifyDrawer from "../../components/drawer/students-account/confirm-request-participant";
import ChangePassStudentDrawer from "../../components/drawer/students-account/change-password";
import Dragger from "antd/es/upload/Dragger";
import ListStudentsAccountImportModal from "../../components/modal/list-students-account-import";
import { changeStatusStudentAccount, deleteAccountStudent, downloadExcelTemplate, exportExcelDataStudent, getAllStudentAccount, importExcelFile, saveApprovedAccount } from "../../service/list-account-student/listAccountStudent";
import "./list-user.scss";
import { globalStore } from "../../stores/stores";
import { Permission } from "../../layout/sidebar/permission.enum";
import { getAllEnterpriseUniversityVer2 } from "../../service/management-participate-platform";

type ListStudentsProps = {
  title?: string
}


const ListStudents: FunctionComponent<ListStudentsProps> = ({ title }) => {
  const helpService = new HelpService();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [IsOpenStudentsAccountAdmin, setIsOpenStudentsAccountAdmin] = useState<boolean>(false);
  const [isOpenChangePass, setIsOpenChangePass] = useState<boolean>(false);
  const [isOpenedConfirmDelete, setIsOpenedConfirmDelete] = useState<boolean>(false);
  const [isOpenConfirmVerify, setIsOpenConfirmVerify] = useState<boolean>(false);
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("desc");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("dateJoined");
  const [exportMode, setExportMode] = useState<boolean>(false);
  const [importMode, setImportMode] = useState<boolean>(false);
  const [isDisableSaveButton, setIsDisableSaveButton] = useState<boolean>(true);
  const [fileListLength, setFileListLength] = useState(0);
  const [fileList, setFileList] = useState<any>([]);

  const [detailData, setDetailData] = useState<any>();
  const [accountUsers, setAccountUsers] = useState<DataType[]>([]);
  const [listEnterPrise, setListEnterPrise] = useState([]);
  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: []
  });

  const [filterData, setFilterData] = useState<any>({
    universityId: null,
    status: null,
    approvedDate: null,
  });

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [form] = Form.useForm();
  const { personalInfo, reload } = globalStore();
  const permissions = personalInfo?.permissions;

  const exportExcel = (fileData: any, msg: string) => {
    const blob = new Blob([fileData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);

    downloadLink.download = msg;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    message.success("Tải xuống thành công!")
  }

  const getListData = async () => {
    setLoading(true);
    const university = listEnterPrise.find((item: any) => item?.uuid === personalInfo?.enterpriseUUID) as any;
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      keyword: searchValue.trim() || undefined,
      universityId: (permissions?.includes(Permission.isQTCS) || permissions?.includes(Permission.isLecturer)) ? university?.id : filterData.universityId ?? null,
      sourceRegister: null,
      typeAccountStudent: null,
      createdDateFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      createdDateTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      ekycStatus: filterData.status ? Number(filterData.status) : null,
    };

    if (exportMode) {
      await exportExcelDataStudent(data).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          const fileData = res.data;
          exportExcel(fileData, "Kết xuất tài khoản Sinh viên.xlsx")
        }
      })
    } else {
      await getAllStudentAccount(data)
        .then((res) => {
          const { data } = res.data
          setAccountUsers(data.data)
          setTotalRecords(data.total);

          setTimeout(() => {
            setLoading(false);
          }, 500);


        })
        .catch((err) => {
          setLoading(false);
        });
    }
    setExportMode(false)
  };

  useEffect(() => {
    getListData()
  }, [pageSize, pageNumber, statusOrder, exportMode, reload])

  const renderStatus = (value: number) => {
    switch (value) {
      case 0:
        return <p className="p-1 border border-gray-400 rounded-md text-gray-400">Chưa xác thực</p>
      case 1:
        return <p className="p-1 border border-blue-400 rounded-md text-blue-400">Chờ duyệt</p>
      case 2:
        return <p className="p-1 border border-green-400 rounded-md text-green-400">Hợp lệ</p>
      case 3:
        return <p className="p-1 border border-red-400 rounded-md text-red-400">Không hợp lệ</p>
      default:
        break;
    }
  }

  const options = [
    ...(permissions?.includes(Permission.ChangeStudentAccountUpdate)
      ? [
        {
          key: "1",
          label: "Đổi mật khẩu",
          icon: <KeyOutlined />,
        },
      ]
      : []),
    ...(permissions?.includes(Permission.ApproveStudentAccountVerificationUpdate)
      ? [
        {
          key: "2",
          label: "Duyệt xác thực",
          icon: <CheckOutlined />,
        },
      ]
      : []),
    ...(permissions?.includes(Permission.DeleteStudentAccountUpdate)
      ? [
        {
          key: "4",
          label: "Xoá tài khoản",
          icon: <DeleteOutlined />,
          className: "dropdown-delete",
          danger: true,
        },
      ]
      : []),
  ]

  const optionsVerified = [
    ...(permissions?.includes(Permission.ChangeStudentAccountUpdate)
      ? [
        {
          key: "1",
          label: "Đổi mật khẩu",
          icon: <KeyOutlined />,
        },
      ]
      : []),
    ...(permissions?.includes(Permission.ReapproveStudentAccountVerificationUpdate)
      ? [
        {
          key: "2",
          label: "Duyệt lại xác thực",
          icon: <CheckCircleOutlined />,
        },
      ]
      : []),
    ...(permissions?.includes(Permission.DeleteStudentAccountUpdate)
      ? [
        {
          key: "4",
          label: "Xoá tài khoản",
          icon: <DeleteOutlined />,
          className: "dropdown-delete",
          danger: true,
        },
      ]
      : []),
  ]

  const optionsNoVerify = [
    ...(permissions?.includes(Permission.ChangeStudentAccountUpdate)
      ? [
        {
          key: "1",
          label: "Đổi mật khẩu",
          icon: <KeyOutlined />,
        },
      ]
      : []),
    ...(permissions?.includes(Permission.DeleteStudentAccountUpdate)
      ? [
        {
          key: "4",
          label: "Xoá tài khoản",
          icon: <DeleteOutlined />,
          className: "dropdown-delete",
          danger: true,
        },
      ]
      : []),
  ]

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record: any, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      sorter: true,
      width: 30
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      key: 'name',
      width: 30
    },
    {
      title: 'Trường đại học',
      dataIndex: 'unitName',
      key: 'unitName',
      width: 20
    },
    {
      title: 'Số giấy tờ cá nhân',
      dataIndex: 'cardId',
      key: 'cardId',
      width: 20
    },
    {
      title: 'Trạng thái eKYC',
      dataIndex: 'phone',
      key: 'phone',
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            <div className="flex items-center gap-2">
              {renderStatus(record?.ekycStatus)}
            </div>
          </>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>{record?.dateJoined ? formatOriginTime(record?.dateJoined) : null}</>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      // shouldCellUpdate(record, prevRecord) {
      //   return true
      // },
      render: (_: any, record: any) => {
        const handleChange = (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
          record.isActive = checked
        }

        const confirm = (id: number) => {
          const data = {
            usedId: record?.id,
            isActive: record?.isActive
          }
          changeStatusStudentAccount(data).then((res) => {
            if (res.status === 200) {
              // record.isActive = !record?.isActive;
              helpService.successMessage(`${record?.isActive ? 'Mở khóa' : 'Khóa'} tài khoản thành công`)
              getListData()
            }
          })
        }

        const cancel = () => {
        }

        if (permissions?.includes(Permission.LockUnlockFAAccountUpdate)) {
          return (
            <Popconfirm
              description={`Bạn có chắc chắn muốn ${record.isActive ? 'khóa' : 'mở khóa'} tài khoản?`}
              onConfirm={() => confirm(get(record, 'id'))}
              onCancel={cancel}
              okText="Xác nhận"
              cancelText="Huỷ"
              title="">
              <Switch defaultChecked={record.isActive}
                checked={record.isActive}
                onChange={handleChange} />
            </Popconfirm>
          )
        } else {
          return (
            <Switch defaultChecked={record.isActive}
              checked={record.isActive}
              onChange={() => message.error("Không có quyền chỉnh sửa!")}
            />
          )
        }
      },
      width: 20
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'operation',
      render: (_, record: any) => {
        return (
          (
            <Space className="flex justify-center">
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenStudentsAccountAdmin(true)
                  setDetailData(record)
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: !record.fileIdPersonal ? optionsNoVerify : (Number(record?.ekycStatus) === 2 ? optionsVerified : options),
                  onClick: ({ key }) => {
                    if (key === '1') {
                      setIsOpenChangePass(true)
                      setDetailData(record)
                    }
                    if (key === '2') {
                      setIsOpenConfirmVerify(true)
                      setDetailData(record)
                    }
                    if (key === '3') {
                      setIsOpenConfirmVerify(true)
                      setDetailData(record)
                    }
                    if (key === '4') {
                      setIsOpenedConfirmDelete(true)
                      setDetailData(record)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          )
        )
      },
      fixed: 'right',
      width: 15,
    }
  ]

  const defaultCheckedList = dataColumns.map((item) => item.key as string)
  const [checkedList, setCheckedList] = useState(defaultCheckedList)

  const newColumns = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string)
    }))
  }, [checkedList])


  const handleOpenCreate = () => {
    setIsOpened(true)
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter)
  }


  const handleClose = () => {
    setIsOpened(false)
    form.resetFields()
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setParams({ [name]: value })
    setSearchValue(value)
    if (value.length === 0) {
      setSearchValue("")
      setParams()
    }
  }

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      getListData()
    }
  }

  const handleSubmitSearch = () => {
    getListData()
  }

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      universityId: null,
      status: null,
      approvedDate: null,
    });
  }

  const handleFinish = (values: any) => {
    setLoading(true)
    getAccountUsers({
      vaiTros: get(values, 'vaiTros', []),
      donVis: combineArray(dataOptions.dataUnits, get(values, 'donVis', [])),
      trangThai: get(values, 'trangThai', []),
    }).then(res => {
      const { data, totalRecords } = res.data.data
      setLoading(false)
      setAccountUsers(data)
      setTotalRecords(totalRecords)
    })
  }

  const onFinishCreate = (values: any) => {
    createUser(values).then(res => {
      const { data, statusCode } = res.data
      if (statusCode === 200) {
        helpService.successMessage(get(data, "message", "Tạo tài khoản thành công"))
        form.resetFields()
        getListData()
        handleClose()
      }
    }).catch(err => {
    })
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const listBreadcrumb = [
    {
      title: 'Quản trị cơ sở',
    },
  ]

  const deleteAccount = async () => {
    await deleteAccountStudent(detailData?.id).then((res) => {
      if (res.status === 200) {
        setIsOpenedConfirmDelete(false)
        message.success("Xóa tài khoản thành công")
        getListData()
      }
    })
  }

  const changeHandler = async (event: any) => {
    setFileListLength(event?.fileList?.length);

    if (isEmpty(event?.fileList)) {
      setFileList([]);
      return;
    }

    setFileList(event?.fileList);

    if (!isEmpty(event?.fileList?.[0]?.originFileObj)) {
      message.warning("Đang import File, vui lòng đợi...")
      const formData = new FormData();
      formData.append("file", event?.fileList?.[0]?.originFileObj);
      setImportMode(false);

      await importExcelFile(formData).then((res) => {
        if (res.status === 200) {
          message.success("Import File thành công!")
          setImportMode(true);
          setIsDisableSaveButton(false);
        }
      }).catch((err) => {
        console.error(err);
        setImportMode(true);
        setIsDisableSaveButton(true);
      })
    }
    // // Passing file data (event.target.files[0]) to parse using Papa.parse
  };

  const propsFile: UploadProps = {
    name: "file",
    // multiple: true,
    maxCount: 1,
    beforeUpload: (file) => {
      const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        message.error(`${file.name} không phải file Excel`);
      }
      return false;
    },
    onChange: changeHandler as any,
  };

  const handleConfirmFile = async () => {
    if (importMode) {
      setIsOpenForm(true)
    }
  };

  const downloadTemplate = async () => {
    await downloadExcelTemplate().then((res) => {
      if (res.status === 200) {
        exportExcel(res.data, "File mẫu.xlsx")
      }
    })
  }

  const saveAccount = async () => {
    const data = {
      isApproved: true
    }
    await saveApprovedAccount(data).then((res) => {
      if (res.status === 200) {
        message.success("Thêm sinh viên từ File thành công")
        setIsOpened(false)
        setIsOpenForm(false)
        getListData()
      }
    })
  }

  const getListEnterprise = async () => {
    await getAllEnterpriseUniversityVer2({}).then((res) => {
      if (res.status === 200) {
        const arr = res.data?.data?.map((item: any) => ({
          id: item.universityId,
          name: item.name,
          uuid: item.uuid,
        }))
        setListEnterPrise(arr)
      }
    })
  }

  useEffect(() => {
    getListEnterprise();
  }, [reload]);

  useEffect(() => {
    if (fileListLength === 0) {
      setIsDisableSaveButton(true);
    }
  }, [fileListLength]);

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === 'createUser') {
          const { createUser } = forms
          const data = createUser.getFieldsValue(validateFieldCreate)
          onFinishCreate(data)
        }
        if (name === "filterForm") {
          getListData()
        }
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              setExportMode={setExportMode}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            listEnterPrise={listEnterPrise}
          />
        </div>

        <TableData dataTable={accountUsers} dataColumns={newColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          handleTableChangeProps={handleTableChange}
        />

        <DetailStudentsAccountDrawer
          open={IsOpenStudentsAccountAdmin}
          onClose={() => setIsOpenStudentsAccountAdmin(false)}
          detailData={detailData}
        />

        <ChangePassStudentDrawer
          open={isOpenChangePass}
          onClose={() => setIsOpenChangePass(false)}
          detailData={detailData}
          fetchData={getListData}
        />

        <CommonModal
          open={isOpenedConfirmDelete}
          title={'Xác nhận xóa tài khoản'}
          desc={`Vui lòng xác nhận xóa tài khoản sinh viên`}
          closeIcon={true}
          okText={'Xóa tài khoản'}
          cancelText={"Huỷ"}
          icon={<ExclamationCircleOutlined style={{ color: "orange" }} />}
          confirm={deleteAccount}
          closeModal={() => setIsOpenedConfirmDelete(false)}
          className="modal-delete-common"
        />

        <ConfirmVerifyDrawer
          open={isOpenConfirmVerify}
          onClose={() => setIsOpenConfirmVerify(false)}
          detailData={detailData}
          fetchData={getListData}
        />

        <CommonModal
          open={isOpened}
          title={"Thêm tài khoản sinh viên từ file Excel"}
          desc={
            <div className="border-b pb-3">
              <Dragger {...propsFile}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Nhấn hoặc thả tệp lên từ máy tính của bạn{" "}
                  <span className="text-[#1677FF]">vào đây</span>
                </p>
                <p className="ant-upload-hint">
                  Định dạng tệp khả dụng: CSV (Tối đa 01 tệp - 10 MB)
                </p>
              </Dragger>
              <div className="pt-3">
                <span className="font-bold">
                  Hướng dẫn các bước thêm sinh viên từ file Excel:
                </span>
                <ol className="list-decimal px-4">
                  <li className="list-decimal">
                    Tải mẫu file Excel chuẩn mà bạn cần sử dụng{" "}
                    <span
                      className="text-[#1677FF] cursor-pointer"
                      onClick={downloadTemplate}
                    >
                      tại đây
                    </span>
                  </li>
                  <li className="list-decimal">
                    Nhấn hoặc thả tệp cần tải lên vào khu vực tải tệp
                  </li>
                  <li className="list-decimal">
                    Chọn nút “Lưu“ để tải file lên hệ thống
                  </li>
                </ol>
              </div>
            </div>
          }
          closeIcon={true}
          okText={"Lưu"}
          cancelText={"Huỷ"}
          icon=""
          loading={importMode}
          disableButton={isDisableSaveButton}
          confirm={handleConfirmFile}
          closeModal={() => setIsOpened(false)}
          className={"!w-full md:!w-[520px]"}
        />

        <ListStudentsAccountImportModal open={isOpenForm} handleClose={() => setIsOpenForm(false)} importMode={importMode} saveAccount={saveAccount} />

      </Form.Provider>
    </Layout>
  );
};

export default ListStudents;
