import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import "./filter-table.scss";
import locale from "antd/es/date-picker/locale/vi_VN";

type FormFilterProps = {
  handleCloseFilter: () => void;
  tab: string;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  dataOptions?: any;
  filterData: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  onFinish,
  form,
  showFilter,
  clearFilter,
  setFilterData,
}) => {
  const { RangePicker } = DatePicker;

  const registrationForm = [
    {
      id: 1,
      name: "Học trước",
    },
    {
      id: 2,
      name: "Mua khoá học",
    },
    {
      id: 3,
      name: "Học thử",
    },
  ];

  const learningStatus = [
    { id: 1, name: "Ghi danh" },
    { id: 2, name: "Đang học" },
    { id: 3, name: "Hoàn thành" },
    { id: 4, name: "Hết hạn" },
  ];

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
            <Col
              xl={{ span: 9 }}
              md={{ span: 9 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="registrationForm">
                <FormItemDropdown
                  placeholder="Hình thức"
                  onChange={(option) => setFilterData(option, "status")}
                  options={registrationForm}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 9 }}
              md={{ span: 9 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="learningStatus">
                <FormItemDropdown
                  placeholder="Trạng thái học tập"
                  onChange={(option) => setFilterData(option, "status")}
                  options={learningStatus}
                />
              </Form.Item>
            </Col>

            <Col
              xl={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary search-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]} className="pt-2">
            <Col
              xl={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Ngày ghi danh</Typography.Title>

              <Form.Item name="enrollmentDate">
                <RangePicker
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  locale={locale}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Ngày bắt đầu</Typography.Title>

              <Form.Item name="startDate">
                <RangePicker
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  locale={locale}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Ngày kết thúc</Typography.Title>

              <Form.Item name="endDate">
                <RangePicker
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  locale={locale}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
