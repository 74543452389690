import { Col, Form, Row, Spin} from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";

const validations = {
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên tổ chức không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên tổ chức"))
			}
			return Promise.resolve()
		}
	},
	code: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã tổ chức không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã tổ chức"))
			}
			return Promise.resolve()
		}
	},
	typeOrganization: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn loại tổ chức"))
			}
			return Promise.resolve()
		}
	},
}

type FormCreateOrganizationProps = {
	form: FormInstance,
	onFinish?: any,
	loadingForm: boolean,
}

const FormCreateOrganization: FunctionComponent<FormCreateOrganizationProps> = ({ form, onFinish, loadingForm }) => {
	const listOption = [
		{
			code: "Cơ quan bộ",
			name: "Cơ quan bộ",
		},
		{
			code: "Đơn vị khác",
			name: "Đơn vị khác",
		},
	]
    return (
        <>
            <Form layout={"vertical"} form={form} onFinish={onFinish} name="createOrganization" initialValues={{ status : true}}>
				{loadingForm ? 
					<div className="statistic-course-content statistic-course-loading">
						<Spin size="large" />
					</div> 
					: 
					<Row gutter={[16,16]}>
						<Col span={24}>
							<Form.Item name="code" rules={[validations.code]} label="Mã tổ chức">
								<FormInput placeholder="Nhập mã tổ chức" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="name" rules={[validations.name]} label="Tên tổ chức">
								<FormInput placeholder="Nhập tên tổ chức" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="typeOrganization" rules={[validations.typeOrganization]} label="Loại tổ chức">
								<FormItemDropdown options={listOption} placeholder="Chọn loại tổ chức" />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
        </>
    )
}

export default FormCreateOrganization;