import React, {useState} from 'react';
import {Col, Empty, Row, Select, Spin} from "antd";
import {BarStackedChartProps} from "../../../../../../types/static-report";
import StatisticalReportCard from '../../StatisticalReportCard';
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import {handleExportReport} from "../../../../../../utils/staticReport";
import {
  exportTableDiscussBySequence,
  exportTableRateUnitByCourse,
  exportTableRateUnitByModule,
  exportTableRateUnitBySequence,
  exportTableUnitDiscussByTime,
  exportTableUnitReviewAndAccess
} from "../../../../../../service/statistical-report";
import dayjs from "dayjs";
import {staticReportStore} from "../../../../../../stores/stores";
import {createTableData, rateTransformData} from "../../../../for-instructors/components/table/tableConvertData";

type Props = {
  loading: boolean,
  unitDiscussForMinistry: BarStackedChartProps[];
  discussCourseActionForMinistry: BarStackedChartProps[];
  unitReviewAndAccessForMinistry: Array<any>;
  rateUnitBySequenceForMinistry: BarStackedChartProps[];
  rateUnitByCourseForMinistry: BarStackedChartProps[];
  rateUnitByModuleForMinistry: BarStackedChartProps[];
  setModuleGroup: (value: any) => void
}


const FeedbackEvaluationTable = ({
                                   loading,
                                   unitDiscussForMinistry,
                                   discussCourseActionForMinistry,
                                   unitReviewAndAccessForMinistry,
                                   rateUnitBySequenceForMinistry,
                                   rateUnitByCourseForMinistry,
                                   rateUnitByModuleForMinistry,
                                   setModuleGroup
                                 }: Props) => {

  const {staticReportType, filterValues, tab} = staticReportStore();

  const [statusOrder, setStatusOrder] = useState("")
  const [fieldSortOrder, setFieldSortOrder] = useState("")
  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    courseStructureType: filterValues?.courseStructureType,
    universityIds: filterValues?.universityIds,
    moduleGroup: filterValues?.moduleGroup
  };


  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng phản hồi
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo thời gian"
            content={
              <Spin spinning={loading}>
                {unitDiscussForMinistry.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(unitDiscussForMinistry).dataSource}
                    columns={[
                      {
                        title: "Thời gian tạo",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(unitDiscussForMinistry, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: "right",
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitDiscussByTime,
                    "Thống kê số lượng phản hồi theo thời gian",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitDiscussByTime,
                    "Thống kê số lượng phản hồi theo thời gian",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading}>
                {discussCourseActionForMinistry.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(discussCourseActionForMinistry).dataSource}
                    columns={[
                      {
                        title: "Bài giảng",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(discussCourseActionForMinistry, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: 'right',
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}

                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableDiscussBySequence,
                    "Thống kê số lượng phản hồi theo bài giảng",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableDiscussBySequence,
                    "Thống kê số lượng phản hồi theo bài giảng",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng đánh giá
        </p>
      </div>
      <Row gutter={[{xs: 8, sm: 8, md: 16, lg: 16}, {xs: 8, sm: 8, md: 16, lg: 16}]}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt đánh giá vs Số lượt hoàn thành học liệu"
            content={
              <Spin spinning={loading}>
                {unitReviewAndAccessForMinistry.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={unitReviewAndAccessForMinistry?.map((it) => ({
                      ...it,
                      total: it?.columnValue + it?.lineValue,
                    }))}
                    columns={[
                      {
                        title: "Ngày đánh giá",
                        dataIndex: "criteria",
                        key: "criteria",
                      },
                      {
                        title: "Số lượt hoàn thành",
                        dataIndex: "lineValue",
                        key: "lineValue",
                        align: "right",
                      },
                      {
                        title: "Số lượt đánh giá",
                        dataIndex: "columnValue",
                        key: "columnValue",
                        align: "right",
                      },
                      {
                        title: "Tổng",
                        dataIndex: "total",
                        key: "total",
                        align: "right",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitReviewAndAccess,
                    "Số lượt đánh giá vs Số lượt hoàn thành học liệu",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitReviewAndAccess,
                    "Số lượt đánh giá vs Số lượt hoàn thành học liệu",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
            moreOption={
              <Select
                className="mr-2"
                defaultValue={1}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  setModuleGroup(value);
                }}
                options={[
                  {value: 1, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 3, label: 'Đa phương tiện'},
                  {value: 4, label: 'Bài giảng'},
                  {value: 5, label: 'Khoá học'},
                ]}
              />
            }
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá khóa học"
            content={
              <Spin spinning={loading}>
                {rateUnitByCourseForMinistry.length > 0 ? (
                  <TableWithTotals
                    hasTotal={false}
                    data={rateTransformData(rateUnitByCourseForMinistry)}
                    columns={[
                      {
                        title: "Khóa học",
                        dataIndex: "content",
                        key: "content",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(rateUnitByCourseForMinistry, 'right').columns,
                    ]}
                    keyColumn="criteria"
                    height={275}

                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableRateUnitByCourse,
                    "Tỷ lệ đánh giá khóa học",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableRateUnitByCourse,
                    "Tỷ lệ đánh giá khóa học",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá bài giảng"
            content={
              <Spin spinning={loading}>
                {rateUnitBySequenceForMinistry.length > 0 ? (
                  <TableWithTotals
                    hasTotal={false}
                    data={rateTransformData(rateUnitBySequenceForMinistry)}
                    columns={[
                      {
                        title: "Bài giảng",
                        dataIndex: "content",
                        key: "content",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(rateUnitBySequenceForMinistry, 'right').columns,
                    ]}
                    keyColumn="criteria"
                    height={275}

                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableRateUnitBySequence,
                    "Tỷ lệ đánh giá bài giảng",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableRateUnitBySequence,
                    "Tỷ lệ đánh giá bài giảng",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá học liệu"
            content={
              <Spin spinning={loading}>
                {rateUnitByModuleForMinistry.length > 0 ? (
                  <TableWithTotals
                    hasTotal={false}
                    data={rateTransformData(rateUnitByModuleForMinistry)}
                    columns={[
                      {
                        title: "Học liệu",
                        dataIndex: "content",
                        key: "content",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(rateUnitByModuleForMinistry, 'right').columns,
                    ]}
                    keyColumn="criteria"
                    height={275}

                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableRateUnitByModule,
                    "Tỷ lệ đánh giá học liệu",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableRateUnitByModule,
                    "Tỷ lệ đánh giá học liệu",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />

        </Col>
      </Row>
    </section>
  </>;
};

export default FeedbackEvaluationTable;
