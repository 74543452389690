import { Layout} from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import AcademicLevelDetail from "../../../../components/category-management/management-detail/academic-level-info/academic-level-detail/AcademicLevelDetail";
import "./academic-level-management-detail.scss";

const { Content } = Layout;
const imgPath = "/images/";

const AcademicLevelManagementDetail: FunctionComponent = () => {
  const location = useLocation();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const listBreadcrumb = [
    {
      title: "Quản lý danh mục",
    },
    {
      title: "Quản lý khác",
    },
    {
      title: (
        <SafeLink
          to={`/${routesConfig.categoryManagement}/${routesConfig.otherCategories}/${routesConfig.academicLevelManagement}`}
        >
          <span>Học vấn</span>
        </SafeLink>
      ),
    },
    {
      title: <span className="style-title">{location.state.name}</span>,
    },
  ];

  return (
    <>
      <Content>
        <PageHeader
          title={location.state?.name ? location.state?.name : ""}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <AcademicLevelDetail
            academicLevelDetail={location.state.record}
            loadingDetail={loadingDetail}
          />
        </div>
      </Content>
    </>
  );
};

export default AcademicLevelManagementDetail;
