import React, { useState } from 'react';
import locale from "antd/es/locale/vi_VN";
import { Modal, Radio, Typography, Form, DatePicker, TimePicker, RadioChangeEvent, Button, Row, Col, Space, ConfigProvider } from 'antd';
import "./publish-exam.scss";
import { CloseOutlined, UpCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
type SchedulePublishModalProps = {
  onClose: () => void;
  open: boolean;
  onFinish: (values: any) => void;
};

const SchedulePublishModal: React.FC<SchedulePublishModalProps> = ({  onClose,open,onFinish }) => {
  const [form] = Form.useForm();
  const [publishType, setPublishType] = useState('2');
  const onOk = () => {
    form.submit();
  }
  const handleRadioChange = (e: RadioChangeEvent) => {
    setPublishType(e.target.value);
    if (e.target.value === '2') {
      form.setFieldsValue({
        date: null,
        time: null
      });
    }
  };
  const selectedDate = Form.useWatch("date", form);
  return (
    <ConfigProvider locale={locale}>
<Modal
      title="Xuất bản nội dung"
      onCancel={onClose}
      open={open}
      onOk={onOk}
      className='publish-exam-library'
      footer={
        <div className='footer'>
        <Button
          icon={<CloseOutlined />}
          onClick={onClose}
          style={{ marginRight: '10px' }}>
          Hủy
        </Button>
        <Button type="primary"
          icon={<UpCircleOutlined />}
          onClick={onOk}
          >
          Xuất bản
        </Button>
      </div>
      }>
      <Form form={form} onFinish={onFinish} initialValues={{ select: '2' }}>
        <Form.Item name='select'>
          <Radio.Group onChange={handleRadioChange}>
            <Space direction="vertical">
            <Radio value="2">
              <Typography.Text>Xuất bản ngay</Typography.Text>
            </Radio>
            <Radio value="3">
              <Typography.Text>Hẹn lịch</Typography.Text>
            </Radio>
            </Space>   
          </Radio.Group>
        </Form.Item>
        <div className='dateTime'>
        <Form.Item name="date" className='date-time-picker' rules={[
              {
          required: publishType === '3',
          message: 'Vui lòng chọn ngày!'
              }
            ]}>
          <DatePicker format="DD/MM/YYYY" style={{ marginRight: '10px' }} placeholder='Ngày' disabled={publishType === '2'} disabledDate={(current: any) => (
            current && current < dayjs().startOf('day')
          )}  />
        </Form.Item>
        <Form.Item name="time" rules={[
              {
          required: publishType === '3',
          message: 'Vui lòng chọn giờ!'
              }
            ]}>
          <TimePicker format="HH:mm:ss" placeholder='Giờ' disabled={publishType === '2'}
          disabledTime={(current: any) => {
            const currentHour = dayjs().hour();
            const currentMinute = dayjs().minute();
            const currentSecond = dayjs().second();
            if(!selectedDate || selectedDate > dayjs().startOf('day')) {
              return {
                disabledHours: () => [],
                disabledMinutes: () => [],
                disabledSeconds: () => [],
              }
            } else {
              return {
                disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter(hour => hour < currentHour),
                disabledMinutes: (selectedHour: number) => selectedHour === currentHour ? Array.from({ length: 60 }, (_, i) => i).filter(minute => minute < currentMinute) : [],
                disabledSeconds: (selectedHour: number, selectedMinute: number) => selectedHour === currentHour && selectedMinute === currentMinute ? Array.from({ length: 60 }, (_, i) => i).filter(second => second < currentSecond) : []
              };
            }
            
            
          }} />
        </Form.Item>
        </div>
        
      </Form>
      
    </Modal>
    </ConfigProvider>
    
  );
};

export default SchedulePublishModal;
