import {
  Button,
  Col,
  Form,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  TableColumnsType,
  TableProps,
  Typography
} from "antd";
import {CopyOutlined, PlusOutlined, SaveOutlined, SearchOutlined, WarningOutlined} from "@ant-design/icons";
import {ChangeEvent, KeyboardEvent, useEffect, useState, useTransition} from "react";
import PageHeader from "../../../../page-header/PageHeader";
import "./create-question.scss";
import SafeLink from "../../../../link/SafeLink";
import {routesConfig} from "../../../../../config/routes";
import AddQuestionCourseModal from "../../../../modal/add-question-course";
import QuestionItem from "../../../../course-construction/question-item";
import {UniqueIdentifier} from "@dnd-kit/core";
import {ItemsDraggerMulti, MultipleContainers} from "../../../../multiple-containers/examples/MultipleContainers";
import {rectSortingStrategy} from '@dnd-kit/sortable';
import {
  addQuizToQuestionBank,
  addUnitQuizFormBank,
  changeOrderGroupNumberQuiz,
  changeOrderNumberQuiz,
  createMoocCourseBlockQuiz,
  deleteMoocCourseBlockQuiz,
  getAllQuizByUnit,
  updateMoocCourseBlockQuiz
} from "../../../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz";
import {Quiz, UnitQuiz} from "../../../../../types/course-construction";
import FormInput from "../../../../form-input/FormInput";
import {saveToIndexDB} from "../../../../../pages/page-builder/grapesjs-service";
import {convertQuillToString} from "../../../../../utils/convertQuillToString";
import {
  getQuizBank,
  getQuizById,
  postCreateGroup,
  updateGroupQuiz,
  uploadFileMoodleQuiz
} from "../../../../../service/course-construct";
import {useAddLesson} from "../../../../../stores/stores";
import {mapAnsFEtoBE} from "../../../../../utils/arrays";
import {
  DEFAULT_MAX_LENGTH_QUESTION_BANK,
  DEFAULT_TEXAREA_ROW,
  ModeQuestion,
  QuestionProgressModeClick,
  QuestionTypeEnum
} from "../../../../../constants";
import {useDirty} from "../../../../../contexts/DirtyProvider";
import {get, isEmpty, isNull} from "lodash";
import {FileType} from "../../../../../utils/uploadImage";
import TableData from "../../../../table-data/TableData";
import TextArea from "antd/es/input/TextArea";
import HtmlToText from "../../../../html-to-text";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

var indexDB = require("idbcache").default;

const imgPath = "/images/";
const {Content} = Layout;
const {Option} = Select;

const renderType = (value: string) => {
  switch (Number(value)) {
    case 1:
      return "Nhiều lựa chọn văn bản"
    case 2:
      return "Nhiều lựa chọn ảnh"
    case 3:
      return "Nhiều lựa chọn video"
    case 4:
      return "Nhiều lựa chọn đúng sai"
    case 5:
      return "Danh sách"
    case 6:
      return "Nhiều đáp án văn bản"
    case 7:
      return "Nhiều đáp án hình ảnh"
    case 9:
      return "Câu trả lời ngắn"
    case 10:
      return "Điền vào chỗ trống"
    case 11:
      return "Trình soạn thảo"
    case 12:
      return "Kết nối"
    case 13:
      return "Sắp xếp"
    case 14:
      return "Ghi âm"
    case 15:
      return "Ghi hình"
    case 16:
      return "Đính kèm"
    default:
      return "Bản nháp"
  }
}


const CreateQuestionExam = () => {

  const setTypeQuestionChoose = useAddLesson((state) => state.setTypeQuestionChoose);
  const question = useAddLesson((state) => state.question);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const onRefreshQuizBlock = useAddLesson((state) => state.onRefreshQuizBlock);
  const refreshQuizBlock = useAddLesson((state) => state.refreshQuizBlock);
  const setQuestion = useAddLesson((state) => state.setQuestion);
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);
  const setModeClick = useAddLesson((state) => state.setModeClick);
  const currentIdQuestionAdd = useAddLesson((state) => state.currentIdQuestionAdd);

  const {setDirty} = useDirty();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);
  const [courseBlockGroupId, setCourseBlockGroupId] = useState<number>(0);
  const [selectedQuestionType, setSelectedQuestionType] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<number>(-1);
  const [itemsRender, setItemsRender] = useState<ItemsDraggerMulti>({});
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusOrder, setStatusOrder] = useState<string>('');
  const [dataFiltered, setDataFiltered] = useState<any>([]);
  const [addQuestionBankModal, setAddQuestionBankModal] = useState<boolean>(false);
  const [courseBlockQuestionBank, setCourseBlockQuestionBank] = useState<number>(0);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [idQuizToAdd, setIdQuizToAdd] = useState<number>(0);
  const [bankCodeValue, setBankCodeValue] = useState<string>('');
  const [isDuplicateModalOpen, setDuplicateIsModalOpen] = useState<boolean>(false);
  const [dataClone, setDataClone] = useState<any>({});
  const [editGroupId, setEditGroupId] = useState<number>(1);
  const [openEditPartModal, setEditPartModal] = useState<boolean>(false);
  const [editGroupName, setEditGroupName] = useState<string>("");
  const [openAddPartModal, setAddPartModal] = useState<boolean>(false);
  const [dataIdOpenCollapse, setDataIdOpenCollapse] = useState<any[]>([]);
  const [loadingQuizBlock, setLoadingQuizBlock] = useState<boolean>(false);
  const [fieldSortOrder, setFieldSortOrder] = useState<string>('');
  const [dataTableQuestion, setDataTableQuestion] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueQuestion, setSearchValueQuestion] = useState<string>('');
  const [namePart, setNamePart] = useState<string>('');


  const [totalIdOpenCollapse, setTotalIdOpenCollapse] = useState<any[]>([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const unitId = searchParams.get("unitId");
  const {state} = useLocation()
  const [isPending, startTransition] = useTransition();


  useEffect(() => {
    setQuestion({});
    setModeQuestion("");
    setTypeQuestionChoose("");
  }, [navigate]);

  useEffect(() => {
    getAllQuizByUnitId();
  }, [refreshQuizBlock]);

  useEffect(() => {
    if (addQuestionBankModal) {
      getQuestionsBank();
    }
  }, [addQuestionBankModal, pageSize, pageNumber, statusOrder]);


  useEffect(() => {
    if (modeQuestion === ModeQuestion.CREATE && dataFiltered.length > 0) {
      let currentQuestionAdd: any;
      dataFiltered.some((ele: any) => {
        currentQuestionAdd = ele.quizzes.find((x: any) => {
          return x.id === currentIdQuestionAdd;
        });
        if (currentQuestionAdd) {
          return true;
        }
        return false;
      });
      if (currentQuestionAdd) {
        getQuizLesson({
          ...currentQuestionAdd,
          type: currentQuestionAdd.type,
          courseBlockGroupId: currentQuestionAdd.groupId,
        });
      }
    }
  }, [dataFiltered]);


  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      sorter: true,
      width: 5,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder}</span>
        );
      },
    },
    {
      title: "Câu hỏi",
      dataIndex: "question",
      key: "question",
      width: 20,
      sorter: true,
      ellipsis: true,
      render: (_: any, record, index: number) => {
        return (
          <><HtmlToText HtmlContent={record?.question}/></>
        );
      },
    },
    {
      title: "Loại câu hỏi",
      dataIndex: "quizType",
      key: "quizType",
      sorter: true,
      ellipsis: true,
      width: 15,
      render: (_: any, record, index: number) => {
        return (
          <div>{renderType(record?.quizType)}</div>
        );
      },
    }
  ]

  const listBreadcrumb = [
    {
      title: "Thư viện",
    },


    
    {
      title: "Kho đề cá nhân",
    },
    {
      title:
        <SafeLink
          to={`/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listExamRepository}`}>
          <span>Danh sách đề</span>
        </SafeLink>
    },
    {
      title: get(state, 'title', 'Chưa có tên'),
    },
  ]

  const getAllQuizByUnitId = async (searchKeyWord?: string) => {
    if (!unitId) {
      return;
    }


    try {
      setLoadingQuizBlock(true);


      let bodyData = {
        id: Number(unitId),
        keyword: '',
      };

      if (searchKeyWord === '') {
        bodyData.keyword = searchKeyWord.trim();
      } else {
        bodyData.keyword = searchValue.trim();
      }
      setModeClick(QuestionProgressModeClick.START);

      const response = await getAllQuizByUnit(bodyData);
      indexDB.set("courseBlockId", JSON.stringify(response.data[0].blockId), 1440);


      const customDataFiltered = response.data.filter((quizUnit: UnitQuiz) => {
        if (!quizUnit.isBegin && !quizUnit.isEnd) {
          quizUnit.id = quizUnit.groupId;
          return quizUnit;
        }
      });

      let customItemsRender: any = {};
      let customAllIdGroup: any = [];
      response.data.forEach((quizUnit: UnitQuiz, index: number) => {
        if (!quizUnit.isBegin && !quizUnit.isEnd) {
          customAllIdGroup.push('quiz-' + quizUnit.groupId);
          let arrayQuestion: UniqueIdentifier[] = [];
          quizUnit.quizzes.forEach((item: Quiz) => {
            arrayQuestion.push(convertQuillToString(item.id));
          });
          customItemsRender = {...customItemsRender, ['quiz-' + quizUnit.groupId]: arrayQuestion};
        }
      });
      let createDataIdOpenCollapse = response.data.map((quizUnit: UnitQuiz) => {
        return JSON.stringify(quizUnit.groupId);
      });

      let orderCustomItemsRender = orderObjectKeys(customItemsRender, customAllIdGroup);

      setDataFiltered(customDataFiltered);
      setItemsRender(orderCustomItemsRender);
      setDataIdOpenCollapse(createDataIdOpenCollapse);
      setTotalIdOpenCollapse(createDataIdOpenCollapse);
      saveToIndexDB('course-block-quiz', customDataFiltered);
    } catch (error: any) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoadingQuizBlock(false);
      }, 1000);
    }
  };


  const handleBeforeUpload = (file: FileType) => {
    const isXMLFile = file.type === 'text/xml';
    if (!isXMLFile) {
      message.error('Bạn phải tải lên file XML !');
    }
    return isXMLFile;
  };


  const handleCustomRequestUpload = async (options: any) => {
    const file = options.file;
    if (file.type === 'text/xml') {
      const formData = new FormData();
      formData.append('unitId', JSON.stringify(Number(unitId)));
      formData.append('groupId', JSON.stringify(editGroupId));
      formData.append('xmlFile', file);
      await uploadFileMoodleQuiz(formData).then((res) => {
        if (res.status === 200) {
          message.success(res.data.message);
          onRefreshQuizBlock();
        }
      });
    }
  }

  const getQuestionsBank = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValueQuestion.trim(),
      typeBank: 'quiz',
      sortBy: statusOrder
    }
    startTransition(() => {
      getQuizBank(dataReq).then(res => {
        const {data} = res.data
        setDataTableQuestion(data.content.map((content: any) => ({
          id: content.quizId,
          quizId: content.quizId,
          codeQuiz: content.codeQuiz,
          question: content.question,
          quizType: content.quizType
        })))
        setPageSize(data.size)
        setTotalRecords(data.totalElements)
        // setTotalRecords(data.totalElements)
      }).catch(err => console.error(err))
    })

  }

  const saveQuizFromBank = async () => {
    const dataReq = {
      codeQuizBank: selectedRowKeys[0]?.codeQuiz,
      blockQuizGroupId: courseBlockQuestionBank
    }
    try {
      const response = await addUnitQuizFormBank(dataReq)
      const {status, data} = response
      if (status === 200) {
        message.success("Thêm câu hỏi thành công")
        setAddQuestionBankModal(false)
        setSelectedRowKeys([])
        getAllQuizByUnitId();
      }
    } catch (err) {
      console.error(err)
    }
  }

  // Delete
  const deleteQuiz = async (id: number) => {
    try {
      await deleteMoocCourseBlockQuiz(id);
      message.success("Xoá câu hỏi thành công!");
      getAllQuizByUnitId();
    } catch (err) {
      console.error(err);
    }
  };

  const stripHtml = (html: string) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const orderObjectKeys = (obj: any, keyOrder: any) => {
    let orderedObject: any = {};
    for (let key of keyOrder) {
      if (obj.hasOwnProperty(key)) {
        orderedObject[key] = obj[key];
      }
    }
    return orderedObject;
  }

  const handleAddQuestion = (courseBlockGroupId: number) => {
    setAddQuestionModal(true)
    setCourseBlockGroupId(courseBlockGroupId)
  }


  const handleAddQuestionFormBank = (courseBlockGroupId: number) => {
    setAddQuestionBankModal(true)
    setCourseBlockQuestionBank(courseBlockGroupId)
  }

  const handelChangePositionParent = async (value: any, cb?: any) => {
    const newOrderParent = value.map((x: any, i: number) => {
      return {id: Number(x.replace('quiz-', '')), orderNumber: i + 1};
    });
    const requestOrderParent = await changeOrderGroupNumberQuiz(newOrderParent);
    if (requestOrderParent.status === 200) {
      message.success(requestOrderParent.data.message);
    } else {
      message.error(requestOrderParent.data.message);
    }
  }

  const handelChangePositionChild = async (value: any, cb?: any) => {
    let newOrderChild: any = [];
    Object.entries(value).forEach((key: any, i: number) => {
      newOrderChild = key[1].map((x: any, i: number) => {
        return {id: Number(x), orderNumber: i + 1, groupId: Number(key[0].replace('quiz-', '').replaceAll('"', ''))};
      });
    });
    const requestOrderChild = await changeOrderNumberQuiz(newOrderChild);
    if (requestOrderChild.status === 200) {
      message.success(requestOrderChild.data.message);
    } else {
      message.error(requestOrderChild.data.message);
    }
  }
  const getQuizLesson = async (quiz: Quiz) => {
    console.log(quiz)
    setModeQuestion(ModeQuestion.UPDATE);
    setModeClick(QuestionProgressModeClick.INPROGRESS);
    setQuestion(quiz);
    setAddQuestionModal(false);
    setActiveTab(quiz?.id as number);
    setCourseBlockGroupId(quiz.courseBlockGroupId);
    setDirty(true);
  };


  // Modal save question
  const showSaveModal = (id: number) => {
    setIsSaveModalOpen(true);
    setIdQuizToAdd(id);
    setBankCodeValue('');
  };

  const handleChangeText = (e: any) => {
    setBankCodeValue(e.target.value);
  };

  const handleSaveQuizToBankOk = async () => {
    if (bankCodeValue) {
      try {
        const response = await addQuizToQuestionBank(idQuizToAdd, bankCodeValue);
        message.success(response.data.message);
        setIsSaveModalOpen(false);
      } catch (err) {
        console.error(err);
      }
    } else {
      message.error("Vui lòng nhập mã câu hỏi!");
      return Promise.reject();
    }
  };

  const handleChildDropDown = (key: string, record: any) => {
    if (key === "1") {
      setDuplicateIsModalOpen(true);
      setDataClone(record);
    }
    if (key === "2") {
      showSaveModal(record.id);
    }
    if (key === "3") {
      Modal.confirm({
        title: `Xóa câu hỏi`,
        content: `Dữ liệu đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa câu hỏi ?`,
        onOk: () => deleteQuiz(record.id),
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        okButtonProps: {
          className: "ant-btn-dangerous",
        },
        icon: (
          <WarningOutlined style={{color: "#FF4D4F"}}/>
        ),
        footer: (_, {OkBtn, CancelBtn}) => (
          <>
            <CancelBtn/>
            <OkBtn/>
          </>
        ),
      });
    }
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "question,asc"
        : sorter.order === "descend"
          ? "question,desc"
          : '';
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValueQuestion(value);
  };

  const handleSubmitSearch = () => {
    startTransition(() => {
      getQuestionsBank()
      setPageNumber(1)
    })
  }

  const handleSearchQuestionBank = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      startTransition(() => {
        getQuestionsBank()
        setPageNumber(1)
      })
    }
  };

  const handleEnterSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getAllQuizByUnitId();
    }
  };

  const onChangeName = (e: any) => {
    if (openEditPartModal) {
      setEditGroupName(e.target.value);
    } else {
      setNamePart(e.target.value);
    }
  };

  const closeAddPartModal = () => {
    setNamePart('');
    setAddPartModal(false);
    setEditPartModal(false);
  };

  const handleSubmit = async () => {
    if (openEditPartModal) {
      if (editGroupName !== '') {
        let reqData = {
          title: editGroupName,
        }
        updateGroupQuiz(editGroupId, reqData).then((res) => {
          closeAddPartModal();
          getAllQuizByUnitId();
          message.success("Sửa tên phần thành công!");
        })
      } else {
        message.error("Chưa nhập tên phần");
      }
    } else {
      if (namePart !== '') {
        const data = {
          unitId: Number(unitId),
          title: namePart,
          orderNumber: dataFiltered.length + 1 || 1,
        };
        try {
          const response = await postCreateGroup(data)
          if (response) {
            getAllQuizByUnitId();
            closeAddPartModal();
          }
        } catch (e) {
          console.error(e)
        }


      } else {
        message.error("Chưa nhập tên phần");
      }
    }
  };

  // Modal duplicate question
  const handleDuplicateOk = () => {
    setDuplicateIsModalOpen(false);
    getDetailQuestion(dataClone.id);
  };

  const getDetailQuestion = async (cloneFromId: any) => {
    const quizDetail = await getQuizById(cloneFromId);
    const quizClone = quizDetail.data.body;
    quizClone.question = "Bản sao " + quizClone.question;
    quizClone.id = undefined;
    createQuiz(quizClone);
  };
  const createQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      const response = await createMoocCourseBlockQuiz(dataReq);
      const {data} = response
      message.success(data.body.message);
      onRefreshQuizBlock();
    } catch (err: any) {
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  };

  const updateQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      await updateMoocCourseBlockQuiz(dataReq);

      message.success("Cập nhật câu hỏi thành công");
      onRefreshQuizBlock();
    } catch (err: any) {
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  }

  const handleSave = () => {
    const payload = {
      id: question.id,
      orderNumber: 1,
      courseBlockGroupId: question.courseBlockGroupId,
      type: question.type,
      question: currentQuestionInfo.questionName,
      questionValue: convertQuillToString(currentQuestionInfo.questionName),
      answers: mapAnsFEtoBE(currentQuestionInfo, question.type as number),
      reminderText: currentQuestionInfo.reminderText,
    };
    const strippedQuestionName = stripHtml(currentQuestionInfo.questionName).trim();
    const checkArr = payload.answers.some((i: any) => (isEmpty(i.filePath)));
    if ((QuestionTypeEnum.CHOOSE_VIDEO === payload.type ||
      QuestionTypeEnum.CHOOSE_MULTI_VIDEO === payload.type ||
      QuestionTypeEnum.CHOOSE_IMAGE === payload.type ||
      QuestionTypeEnum.CHOOSE_MULTI_IMAGE === payload.type) && checkArr && payload.answers.length !== 0) {
      message.error('Không được bỏ trống các giá trị file và nội dung');
    } else if (strippedQuestionName.length === 0) {
      message.error("Không được bỏ trống nội dung câu hỏi")
    } else {
      setDirty(false);
      if (modeQuestion === ModeQuestion.CREATE) {
        createQuiz(payload)
      }
      if (modeQuestion === ModeQuestion.UPDATE) {
        updateQuiz(payload)
      }
    }

  }


  return (
    <>
      <Content className='add-question'>
        <PageHeader
          title={get(state, 'title', 'Chưa có tên')}
          headerGroupButton={true}
          positionItem="column"
          listBreadcrumb={listBreadcrumb}
          breadcrumb={true}
          arrowLeft={imgPath + 'arrowLeft.svg'}
          childrenGroupButton={
            <div className='button-container'>
              <Button
                type='primary'
                icon={<SaveOutlined/>}
                onClick={handleSave}
                loading={loadingSubmit}
                disabled={modeQuestion === ''}
              >
                Lưu
              </Button>
            </div>
          }
        />
        <div className="editor-course">
          <Row className="body-create-question">
            <Col span={5}>
              <div className="editor-question-editing flex flex-col">
                <div className="pv-16">
                  <div className="mt-2">
                    <FormInput
                      placeholder="Tìm câu hỏi"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        if (e.target.value === '') {

                        }
                      }}
                      onKeyPress={handleEnterSearch}
                      afterPrefixIcon={
                        <SearchOutlined onClick={() => {
                        }}
                        />
                      }
                    />
                  </div>
                </div>
                <section className="mt-2">
                  <Spin spinning={loadingQuizBlock}>
                    <div className="dragger-multiple-containers multiple-quizes">
                      <MultipleContainers
                        collapsible={true}
                        activeQuestion={activeTab}
                        strategy={rectSortingStrategy}
                        vertical
                        dataItemsRender={itemsRender}
                        titleButton={'Chọn từ thư viện'}
                        dataBase={dataFiltered}
                        handelChangePostionParent={handelChangePositionParent}
                        handelChangePostionChild={handelChangePositionChild}
                        handle={true}
                        trashable={true}
                        handleAddQuestionFromBank={handleAddQuestionFormBank}
                        handleAddGroupQuestion={handleAddQuestion}
                        handleClickChild={(id) => {
                          let record: any = {};
                          dataFiltered.map((x: UnitQuiz) => {
                            x.quizzes.map((y: Quiz) => {
                              if (y.id === id) {
                                record = y;
                              }
                            });
                          });
                          getQuizLesson({
                            ...record,
                            type: record.type,
                            courseBlockGroupId: record.groupId,
                          });
                        }}
                        handleClickDropDownChild={(key, id) => {
                          let record: any = {};
                          dataFiltered.map((x: UnitQuiz) => {
                            x.quizzes.map((y: Quiz) => {
                              if (y.id === id) {
                                record = y;
                              }
                            });
                          });
                          handleChildDropDown(key, record);
                        }}
                        handleEditNameGroup={(id) => {
                          setEditGroupId(Number(id.split('-')[1]));
                          setEditPartModal(true);
                          const groupName = dataFiltered.find((item: any) => item.groupId === Number(id.split('-')[1])).title;
                          setEditGroupName(groupName);
                        }}
                        handleDeleteGroup={(id) => {
                          Modal.confirm({
                            title: `Xóa phần`,
                            content: `Dữ liệu đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa phần này ?`,
                            onOk: () => {

                            },
                            okText: "Xác nhận",
                            cancelText: "Huỷ",
                            centered: true,
                            okButtonProps: {
                              className: "ant-btn-dangerous",
                            },
                            icon: (
                              <WarningOutlined style={{color: "#FF4D4F"}}/>
                            ),
                            footer: (_, {OkBtn, CancelBtn}) => (
                              <>
                                <CancelBtn/>
                                <OkBtn/>
                              </>
                            ),
                          });
                        }}
                        handleAddGroupId={(id) => {
                          setEditGroupId(id);
                        }}
                        uploadMoodle={{
                          showUploadList: false,
                          accept: '.xml',
                          beforeUpload(file) {
                            handleBeforeUpload(file)
                          },
                          customRequest(options) {
                            handleCustomRequestUpload(options)
                          },
                        }}
                      />
                    </div>
                  </Spin>
                </section>
                <Button className="cursor-pointer add-question p-1 add-part"
                        onClick={() => {
                          setAddPartModal(true);
                        }}>
                  <PlusOutlined className="mr-2"/>
                  Thêm phần
                </Button>
              </div>
            </Col>
            <Col span={16} style={{backgroundColor: "#grey", marginLeft: '100px'}}>
              {Object.values(question).length > 0 || !isNull(selectedQuestionType) ? (<QuestionItem/>) : <>
                <section className='question-empty'>
                  <Typography.Title level={5} className="question-title">Xin vui
                    lòng chọn câu hỏi</Typography.Title></section>
              </>}
            </Col>
          </Row>
        </div>

        <Modal
          title={openEditPartModal ? `Sửa phần` : `Thêm phần`}
          open={openEditPartModal ? openEditPartModal : openAddPartModal}
          okText={openEditPartModal ? `Sửa` : `Thêm`}
          cancelText="Hủy"
          onOk={handleSubmit}
          onCancel={closeAddPartModal}
        >
          <Form.Item required={true} label="Tên phần:">
            <FormInput value={openEditPartModal ? editGroupName : namePart} onChange={onChangeName}/>
          </Form.Item>
        </Modal>
        <AddQuestionCourseModal
          courseBlockGroupIdClick={courseBlockGroupId}
          open={addQuestionModal}
          title={"Thêm câu hỏi"}
          closeIcon={true}
          okText={"Xác nhận"}
          cancelText={"Huỷ"}
          icon={null}
          closeModal={() => setAddQuestionModal(false)}
        />
        <Modal
          className="add-question-modal"
          title="Thêm từ thư viện câu hỏi"
          open={addQuestionBankModal}
          onOk={saveQuizFromBank}
          onCancel={() => {
            setAddQuestionBankModal(false);
            setSelectedRowKeys([])

          }}
          okText="Lưu lại"
          cancelText="Hủy bỏ"
          width={800}
        >
          <div>
            <div className="modal-filter-table">
              <div className="filter-input">
                <FormInput placeholder={"Nhập từ khóa cần tìm"} onChange={handleChangeInput}
                           value={searchValueQuestion}
                           onKeyPress={handleSearchQuestionBank}
                           afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch}/>}/>
              </div>
            </div>
            <TableData
              hideSelection={true}
              selectOneRow={true}
              dataColumns={dataColumns}
              pageNumber={pageNumber}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              loadingTable={isPending}
              totalRecords={totalRecords}
              rowSelected={selectedRowKeys}
              setSelectedRowKeysProps={setSelectedRowKeys}
              pagination={true}
              dataTable={dataTableQuestion}
              handleTableChangeProps={handleTableChange}/>
          </div>
        </Modal>
        {/* Modal save question */}
        <Modal
          className="save-modal"
          title="Lưu vào ngân hàng câu hỏi"
          open={isSaveModalOpen}
          onOk={handleSaveQuizToBankOk}
          onCancel={() => {
            setIsSaveModalOpen(false);
          }}
          okText="Xác nhận"
          cancelText="Hủy"
        >
          <div className="save-modal-input">
            <label>Mã câu hỏi: </label>
            <TextArea
              placeholder="Nhập mã vào đây"
              maxLength={DEFAULT_MAX_LENGTH_QUESTION_BANK}
              rows={DEFAULT_TEXAREA_ROW}
              showCount
              autoSize={{minRows: 1, maxRows: 10}}
              onChange={handleChangeText}
              value={bankCodeValue}
            />
          </div>
        </Modal>
        <Modal
          confirmLoading={loadingQuizBlock}
          className="duplicate-modal"
          title={
            <span>
            <CopyOutlined
              style={{marginRight: 15, fontSize: 24, color: "#1677FF"}}
            />
            Tạo bản sao câu hỏi
          </span>
          }
          open={isDuplicateModalOpen}
          onOk={handleDuplicateOk}
          onCancel={() => {
            setDuplicateIsModalOpen(false);
          }}
          okText="Tạo bản sao"
          cancelText="Hủy"
          closable={false}
        >
          <p>Bạn chắc chắn muốn tạo bản sao cho câu hỏi ?</p>
        </Modal>
      </Content>

    </>
  )
};


export default CreateQuestionExam;