import { Avatar, Button, Modal, Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAvailableUniversities } from '../../../../../service/category-management/university-management';

type Props = {
	open: boolean;
	handleCancel: () => void;
	setMemberUniversity: (universities: University[]) => void;
	memberUniversity: any;
};

type University = {
	id: number;
	name: string;
	code: string;
	englishName?: string;
};

const MemberUniversityModal = (props: Props) => {
	const { handleCancel, open, setMemberUniversity, memberUniversity } = props;
	const [availableUniversities, setAvailableUniversities] = useState<University[]>([]);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const [loading, setLoading] = useState<boolean>(false);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	useEffect(() => {
		const fetchAvailableUniversities = async () => {
			setLoading(true);
			const payload = {
				page: pageNumber,
				size: pageSize,
			};
			try {
				const res = await getAvailableUniversities(payload);
				setAvailableUniversities(
					res?.data.data.content.map((it: any) => {
						return {
							...it,
							id: it.uuid,
						};
					})
				);
				setTotalRecords(res?.data.data.totalElements);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		if (open) fetchAvailableUniversities();
	}, [open, pageNumber, pageSize]);

	const columns: TableColumnsType<any> = [
		{
			title: 'STT',
			dataIndex: 'numericalOrder',
			key: 'numericalOrder',
			width: 50,
			render: (_: any, record, index: number) => {
				return <div>{index + 1 + (pageNumber - 1) * pageSize}</div>;
			},
		},
		{
			title: 'Logo',
			dataIndex: 'logo',
			key: 'logo',
			width: 50,
			render: (_: any, record, index: number) => {
				return <Avatar shape="square" size="small" src={record?.logo} />;
			},
		},
		{
			title: 'Tên trường',
			dataIndex: 'name',
			key: 'name',
			width: 300,
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: 'Mã trường',
			dataIndex: 'code',
			key: 'code',
			width: 200,
		},
		{
			title: 'Tên tiếng Anh',
			dataIndex: 'nameEn',
			key: 'nameEn',
			width: 200,
		},
	];

	const rowSelection = {
		selectedRowKeys,
		onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
			setSelectedRowKeys(newSelectedRowKeys);
			setSelectedRows(selectedRows);
		},
	};

	const handleTableChange = (pagination: any) => {
		setPageNumber(pagination.current);
		setPageSize(pagination.pageSize);
	};

	useEffect(() => {
		if (memberUniversity && memberUniversity.length > 0) {
			setSelectedRowKeys(memberUniversity.map((it: any) => it?.enterpriseId || it?.uuid));
		}
	}, [open, memberUniversity]);

	return (
		<Modal
			title="Trường đại học thành viên"
			centered
			width={1200}
			onCancel={handleCancel}
			open={open}
			footer={
				<div className="flex gap-8">
					<Button onClick={handleCancel}>Huỷ bỏ</Button>
					<Button
						type="primary"
						onClick={() => {
							setMemberUniversity(selectedRows);
							handleCancel();
						}}
					>
						Xác nhận
					</Button>
				</div>
			}
		>
			<Table
				rowSelection={rowSelection}
				columns={columns}
				dataSource={availableUniversities}
				loading={loading}
				pagination={{
					current: pageNumber,
					pageSize: pageSize,
					total: totalRecords,
					showSizeChanger: true,
				}}
				rowKey="id"
				onChange={handleTableChange}
				scroll={{ x: 1000, y: 600 }}
			/>
		</Modal>
	);
};

export default MemberUniversityModal;
