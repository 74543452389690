import { DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React, { ChangeEvent, KeyboardEvent } from 'react';
import FormInput from '../../../../form-input/FormInput';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '../../../../../config/routes';

type FilterExamTableProps = {
  handleOpen: () => void,
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  setTypeQuestion: (value: boolean) => void,
  setIsOpenBankStorageModal: (value: boolean) => void,
  typeQuestion: boolean,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  countChoose: any,
}


const FilterExamTable = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  typeQuestion,
  setTypeQuestion,
  countChoose,
  setIsOpenBankStorageModal
}: FilterExamTableProps) => {
  const navigate = useNavigate();
  const handleAddQuestion = () => {
    navigate(`${routesConfig.createQuestionRepository}`,{state: {mode: 'backState'}, });
  }
  return (
    <>
      <div className="heading-extra">
        <Row gutter={8} wrap={false}>
          <Col md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} xs={{ span: 4 }}>
            <div className="heading-filter">
              <Button className="filter-button w-100" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xs={{ span: 19 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khoá cần tìm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 21 }}>
            <div className="heading-resigter">
              <Button onClick={handleAddQuestion} className="btn btn-primary">
                <PlusOutlined />
                <Typography.Text>Thêm câu hỏi</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 21 }}>
            <div className="heading-resigter">
              <Button onClick={() => setTypeQuestion(!typeQuestion)} className={`w-100 ${typeQuestion ? 'btn btn-primary' : ""}`}>
                <Typography.Text>{typeQuestion ? "Danh sách câu hỏi" : "Câu hỏi lưu trữ"}</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} xs={{ span: 21 }}>
            <div className="heading-resigter">
              <Button className="btn" onClick={() => setIsOpenBankStorageModal(true)}>
                <Typography.Text>Đã chọn ({countChoose?.length})</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterExamTable;