import { DownloadOutlined, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Row, Typography } from "antd";
import { MenuProps } from "antd/lib";
import { FunctionComponent } from "react";

type FilterTableProps = {
    isRendering: boolean,
    handleOpenFilter: () => void,
    setExportType: (data: string) => void,
    reportType: string,
    handleExportAll: (data: string) => void,
  }

const FilterTable: FunctionComponent<FilterTableProps> = ({
    isRendering,
    handleOpenFilter,
    setExportType,
    reportType,
    handleExportAll
}) => {

  const items: MenuProps["items"] =
    reportType === "1"
      ? [
          {
            key: "1",
            label: (
              <div
                onClick={() => {
                  setExportType("png");
                  handleExportAll("png");
                }}
              >
                Hình ảnh (PNG)
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  setExportType("pdf");
                  handleExportAll("pdf");
                }}
              >
                PDF
              </div>
            ),
          },
        ]
      : [
          {
            key: "1",
            label: (
              <div
                onClick={() => {
                  setExportType("excel");
                  handleExportAll("excel");
                }}
              >
                Excel
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  setExportType("pdf");
                  handleExportAll("pdf");
                }}
              >
                PDF
              </div>
            ),
          },
        ];
    return (
        <>
      <div className="filter-table-charts">
        <Row gutter={[8, 8]}>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
            <div className="heading-resigter">
              <Dropdown menu={{ items }} placement="bottom">
                <Button className="btn btn-primary" disabled={isRendering}>
                  <Typography.Text>{isRendering ? <LoadingOutlined /> : <DownloadOutlined />} Xuất dữ liệu</Typography.Text>
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    </>
    );
}

export default FilterTable;