import { Col, Form, Row, Spin} from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent, useEffect, useState } from "react";
import FormInput from "../../../../../components/form-input/FormInput";

const validations = {
	code: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã"))
			}
			return Promise.resolve()
		}
	},
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên"))
			}
			return Promise.resolve()
		}
	},
}

type FormCreateUniversityInfoProps = {
	form: FormInstance,
	onFinish?: any,
	loadingForm?: boolean,
	textRendered?: string,
}

const FormCreateUniversityInfo: FunctionComponent<FormCreateUniversityInfoProps> = ({ form, onFinish, loadingForm, textRendered }) => {
  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish} name="createUniversityInfo" initialValues={{ status : true}}>
				{ loadingForm 
					? 
						<div className="statistic-course-content statistic-course-loading">
							<Spin size="large" />
						</div> 
					: 
						<Row gutter={[16,16]}>
							<Col span={24}>
								<Form.Item name="code" rules={[validations.code]} label={`Mã ${textRendered}`}>
									<FormInput placeholder={`Nhập mã ${textRendered}`} />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item name="name" rules={[validations.name]} label={`Tên ${textRendered}`}>
									<FormInput placeholder={`Nhập tên ${textRendered}`} />
								</Form.Item>
							</Col>
						</Row>
				}
			</Form>
    </>
  )
}

export default FormCreateUniversityInfo;