import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, EyeOutlined, HistoryOutlined, MoreOutlined, SearchOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Flex, Form, Layout, Modal, Space, TableColumnsType, TableProps, Tabs, TabsProps, Typography } from "antd";
import { MenuProps } from "antd/lib";
import dayjs from "dayjs";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import FormInput from "../../components/form-input/FormInput";
import AddLessionModal from "../../components/modal/add-lession";
import PageHeader from "../../components/page-header/PageHeader";
import TableData from "../../components/table-data/TableData";
import { routesConfig } from "../../config/routes";
import { downloadCourseUnit } from "../../service/course-construct";
import { deleteLearningMaterial, getEnterpriseShare, getLearningMaterialList, getSharerList } from "../../service/learning-material";
import { LearningMaterialStore } from "../../stores/stores";
import { downloadWordDocument, getFirstLetter, RenderUnitIcon } from "../../utils/functionUltils";
import { notifyError, notifySuccess } from "../../utils/notification";
import FormFilter from "./components/FormFilter/FormFilter";
import HistoryModal from "./components/history-modal/HistoryModal";
import LearningMaterialDrawer from "./components/LearningMaterialDrawer/LearningMaterialDrawer";
import ShareLearningMaterial from "./components/share-learning-material/ShareLearningMaterial";
import "./style.scss";
type UnitListProps = {
  title?: string;
};

const LearningMaterials = ({ title }: UnitListProps) => {
  const { reload, onReload } = LearningMaterialStore()
  const [form] = Form.useForm();

  const [unitList, setUnitList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [tab, setTab] = useState<string>("1")
  const [multimediaType, setMultimediaType] = useState<string>("");
  const [isOpenAddLessonModal, setIsOpenAddLessonModal] = useState<boolean>(false)
  const [isOpenLearningMaterialDrawer, setIsOpenLearningMaterialDrawer] =
    useState<boolean>(false);
  const [isShareLearningMaterial, setIsShareLearningMaterial] = useState<boolean>(false)
  const [isViewHistory, setIsViewHistory] = useState<boolean>(false)
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataOptions, setDataOptions] = useState({
    shareUser: [],
    shareUniversity: [],
    shareType: [
      {
        value: "0",
        label: "Chưa chia sẻ",
      },
      {
        value: "1",
        label: "Cấp trường",
      },
      {
        value: "2",
        label: "Cấp bộ",
      },
    ],
    shareStatus: [
      { value: 1, label: "Chờ duyệt" },
      { value: 2, label: "Đã duyệt" },
      { value: 3, label: "Từ chối" },
    ]
  })

  const [filterData, setFilterData] = useState<any>({
    shareUniversity: [],
    shareUser: [],
    shareType: [],
    status: [],
    createDate: ["", ""],
  });
  const handleShowFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const [shareId, setShareId] = useState<number>(0);

  const fetchData = async () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    const { shareType, shareStatus, startDate, shareUser, shareUniversity } = formValues;
    const payload = {
      page: pageNumber,
      size: pageSize,
      shareType: shareType && shareType.length ? shareType : undefined,
      status: shareStatus && shareStatus.length ? shareStatus : undefined,
      shareUserIds: shareUser && shareUser.length ? shareUser : undefined,
      shareEnterpriseUuids: shareUniversity && shareUniversity.length ? shareUniversity : undefined,
      createdFrom: startDate?.[0] ? dayjs(startDate[0]).toISOString() : undefined,
      createdTo: startDate?.[1] ? dayjs(startDate[1]).toISOString() : undefined,
      keyword: searchValue || undefined,
      isFromCurrentUser: tab === "1",
      sort: ['created_date,desc'],
    };

    try {
      const response = await getLearningMaterialList(payload);
      const { data } = response.data;
      setUnitList(data?.content);
      setTotalPage(data?.totalElements);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [downloading, setDownloading] = useState<boolean>(false)
  const handleDownloadUnit = async (record: any) => {
    setDownloading(true);
    try {
      if (record?.module === "quiz") {
        await downloadCourseUnit(Number(record.id))
          .then((res: any) => {
            const link = document.createElement("a");
            link.href = res.data;
            link.download = "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error: any) => {
            notifyError((error as any)?.response?.data?.message);
          });
      } else if (record?.module === "text") {
        downloadWordDocument(
          record?.documentText
        );
      } else if (record?.module === "scorm" || record?.module === "xapi") {
        const documentUrl = record.mainKeyUrl;
        window.open(documentUrl, "_blank");
      } else {
        const response = await fetch(
          record.mainKey ? record.mainKey : record.mainKeyUrl);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${record?.fileName ? record?.fileName : record?.name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };
  const handleDelLearningMaterial = (id: number) => {
    try {
      deleteLearningMaterial(id)
      notifySuccess("Xoá học liệu thành công")
      onReload()

    } catch (error) {

    }
  }

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: "55px",
      render: (_: any, record) => <div>{record.numericalOrder}</div>,
    },
    {
      title: "Học liệu",
      dataIndex: "resource",
      key: "resource",
      width: "30%",
      render: (resource: any, record) => {
        return (
          <div className={`${"flex gap-16 align-center"}`}
            style={{ wordBreak: "break-all" }}
          >
            <div
              style={{ width: "60px" }}
            >{RenderUnitIcon(record.module)}</div>
            <div>
              <div className="font-weight-5">{record.code}</div>
              <div>{record.name}</div>

            </div>
          </div>
        )
      },
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      render: (resource: any, record: any) => {
        return (
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: `${record?.description}` || "" }}
          />
        )
      },
    },
    ...tab === "2" ?
      [
        {
          title: "Người chia sẻ",
          dataIndex: "sharer",
          key: "sharer",
          render: (resource: any, record: any) => {
            return (
              <div className="flex gap-16 align-center"
                style={{ wordBreak: "break-all" }}
              >
                <Avatar src={record?.imagePath} size={48} className="mt-2 mb-2">
                  {getFirstLetter(record?.shareUserFullName)}
                </Avatar>
                <div>
                  <div className="font-weight-5">{record.shareUserFullName}</div>
                  <div>{record.shareEnterpriseName}</div>

                </div>
              </div>
            )
          },
        },
        {
          title: "Loại chia sẻ",
          dataIndex: "shareType",
          key: "shareType",
          width: "160px",
          render: (resource: any, record: any) => {

            return (
              <div>
                {record?.shareType === 1 ? "Trường"
                  : record?.shareType === 2 ? "Bộ"
                    : "-"
                }
              </div>
            )
          },
        },
      ] : [
      ],

    ...tab === "1" ?
      [
        {
          title: "Ngày tạo",
          dataIndex: "createdDate",
          key: "createdDate",
          width: "180px",
          render: (resource: any, record: any) => {

            return (
              <div>
                {dayjs(record.createdDate).format("DD/MM/YYYY")}
              </div>
            )
          },
        }] : [{
          title: "Ngày chia sẻ",
          dataIndex: "sharedDate",
          key: "sharedDate",
          width: "180px",
          render: (resource: any, record: any) => {

            return (
              <div>
                {record.sharedDate ? dayjs(record.sharedDate).format("DD/MM/YYYY") : ""}
              </div>
            )
          },
        }],
    {
      title: "Thao tác",
      key: "actions",
      width: "160px",
      render: (_, record) => {
        const idDocument = `${record?.id}`;
        const items: MenuProps["items"] = [
          {
            label: (
              <div
                onClick={() => {
                  setIsShareLearningMaterial(true)
                  setShareId(record.id)
                }
                }
              >
                <Flex>
                  <ShareAltOutlined />
                  <Typography.Text className="pl-2"
                  >
                    Chia sẻ
                  </Typography.Text>
                </Flex>
              </div>
            ),
            key: "0",
          },
          {
            label: (

              <Flex>
                <DownloadOutlined />
                <Typography.Text className="pl-2"
                  onClick={() => {
                    handleDownloadUnit(record)
                  }
                  }

                >
                  Tải xuống
                </Typography.Text>
              </Flex>
            ),
            key: "1",
          },
          {
            label: (
              <div
                onClick={() => {
                  setIsViewHistory(true)
                  setShareId(record.id)
                }

                }
              >
                <Flex>
                  <HistoryOutlined />
                  <Typography.Text className="pl-2">
                    Lịch sử
                  </Typography.Text>
                </Flex>
              </div>
            ),
            key: "2",
          },
          // {
          //   label: (
          //     <div
          //       onClick={() => {
          //         setIsEdit(true)
          //         setIsOpenLearningMaterialDrawer(true)
          //         setShareId(record.id)
          //       }

          //       }
          //     >
          //       <Flex>
          //         <EditOutlined />
          //         <Typography.Text className="pl-2">
          //           Chỉnh sửa
          //         </Typography.Text>
          //       </Flex>
          //     </div>
          //   ),
          //   key: "3",
          // },
          {
            label: (
              <Flex className="text-[#FF4D4F]"

                onClick={() => {
                  Modal.confirm({
                    title: `Xoá học liệu ${record.name ? record.name : ""}`,
                    content: `Học liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá học liệu?`,
                    onOk: () => handleDelLearningMaterial(record.id),
                    centered: true,
                    okText: "Đồng ý",
                    cancelText: "Huỷ",
                    footer: (_, { OkBtn, CancelBtn }) => (
                      <>
                        <CancelBtn />
                        <OkBtn />
                      </>
                    ),
                  });
                }}
              >
                <DeleteOutlined />
                <Typography.Text className="pl-2 text-[#FF4D4F]">Xoá</Typography.Text>
              </Flex>
            ),
            key: "4",
          },
        ];
        return (
          <div className="flex gap-24">
            <EyeOutlined
              className="view-unit"
              onClick={() => window.open(`/course-library${routesConfig.learningMaterials}/detail/${record.id}`, '_blank')}
            />
            {tab === "1" ? <Space>
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                className="action-table"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            </Space> : <DownloadOutlined
              className="view-unit" />}
          </div>


        );
      },

    },
  ];

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        size: pageSize,
        page: pageNumber,
        keyword: searchValue,
      };
      setPageNumber(1);
      fetchData();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    fetchData();
  };

  const handleCloseFilter = () => {
    setIsShowFilter(false);
  };

  const clearFilter = () => {
    setPageNumber(1);
    form.resetFields();
    fetchData();
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onFilterSubmit = () => {
    setPageNumber(1);
    fetchData();
  };


  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";

  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Của tôi",
      children: (
        <TableData
          dataColumns={dataColumns}
          loadingTable={loading}
          dataTable={unitList}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          totalRecords={totalPage}
          scroll={{ x: 1500, y: 600 }}
          pagination={true}
          hideSelection={false}
          handleTableChangeProps={handleTableChange}
        />

      ),
    },
    {
      key: "2",
      label: "Được chia sẻ",
      children:
        <TableData
          dataColumns={dataColumns}
          loadingTable={loading}
          dataTable={unitList}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          totalRecords={totalPage}
          scroll={{ x: 1500, y: 600 }}
          pagination={true}
          hideSelection={false}
          handleTableChangeProps={handleTableChange}
        />
    },

  ];
  const handleChangeMenuType = (type: any) => {
    setIsOpenLearningMaterialDrawer(true);
    setMultimediaType(type)
  }
  const confirmAddLesson = () => {
    setIsOpenAddLessonModal(false);
  };

  // useEffect

  useEffect(() => {
    fetchData()
  }, [pageSize, pageNumber, tab, reload]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getSharerList(), getEnterpriseShare()]).then(res => {
      const listSharer = res[0]?.data?.data?.map((item: any) => {
        return ({
          value: item.id,
          label: item.fullName
        })
      });

      const listEnterpriseShare = res[1]?.data?.data?.map((item: any) => {
        return ({
          value: item.uuid,
          label: item.name
        })
      });

      setDataOptions({
        ...dataOptions,
        shareUser: listSharer,
        shareUniversity: listEnterpriseShare,
      })

      setTimeout(() => {
        setLoading(false);
      }, 300);
    })
  }, []);

  return (
    <Layout className="page-header-group unit-list-page">

      <Form.Provider>
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <div className="flex gap-8">
              <Button className="filter-button" onClick={handleShowFilter}>
                <Typography.Text>Bộ lọc</Typography.Text>
                <DownOutlined />
              </Button>
              <FormInput
                placeholder="Nhập mã, tên hoặc mô tả để tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
                style={{ width: "400px" }}
              />
              <Button type="primary"
                onClick={() => setIsOpenAddLessonModal(true)}
              >Tải học liệu</Button>
            </div>

          </div>
          {isShowFilter ? (
            <FormFilter
              handleCloseFilter={handleCloseFilter}
              clearFilter={clearFilter}
              form={form}
              showFilter={isShowFilter}
              tab={tab}
              filterData={filterData}
              setFilterData={handleChangeFilter}
              onFinish={onFilterSubmit}
              dataOptions={dataOptions}
            />
          ) : null}
        </div>

      </Form.Provider>
      <>
        <Tabs items={items}
          onChange={tab => setTab(tab)}
        />
      </>
      <AddLessionModal
        open={isOpenAddLessonModal}
        title={"Chọn học liệu"}
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={null}
        confirm={confirmAddLesson}
        multimediaType={handleChangeMenuType}
        closeModal={() => setIsOpenAddLessonModal(false)}
        isCheckDocument={false}
      />
      <LearningMaterialDrawer open={isOpenLearningMaterialDrawer}
        onCloseDraw={() => {
          setIsOpenLearningMaterialDrawer(false)
          setIsEdit(false)
        }}
        multimediaType={multimediaType}
        isEdit={isEdit}
        learningMaterialId={shareId}
      />
      <ShareLearningMaterial open={isShareLearningMaterial}
        handleCancel={() => setIsShareLearningMaterial(false)}
        handleOk={() => setIsShareLearningMaterial(false)}
        shareId={shareId}
      />
      <HistoryModal
        open={isViewHistory}
        handleCancel={() => setIsViewHistory(false)}
        handleOk={() => setIsViewHistory(false)}
        shareId={shareId}
      />

    </Layout>
  );
};

export default LearningMaterials;
