// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-content__item {
  margin-bottom: 15px; }
  .sub-content__item__title {
    font-weight: bold; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/survey-plan/survey-detail/plan-detail/survey-answers/survey-answers-info/survey-answers-info.scss"],"names":[],"mappings":"AACE;EACE,mBAAmB,EAAA;EAEnB;IACE,iBAAiB,EAAA","sourcesContent":[".sub-content {\n  &__item {\n    margin-bottom: 15px;\n\n    &__title {\n      font-weight: bold;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
