import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

interface AudioPlayerProps {
  url: string;
}

const CustomAudioPlayer: React.FC<AudioPlayerProps> = ({ url }) => {
  return (
    <AudioPlayer
      src={url}
      autoPlay={false}
      onPlay={() => console.log("Playing audio")}
      onPause={() => console.log("Paused audio")}
      style={{ width: '100%' }}
    />
  );
};

export default CustomAudioPlayer;
