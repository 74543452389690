import { Form, Spin } from "antd";
import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import "./university-info-detail.scss";
interface UniversityInfoDetailProps {
  universityInfoDetail: any;
  loadingDetail: boolean;
  textRendered?: string;
}

const UniversityInfoDetail: FC<UniversityInfoDetailProps> = ({
  universityInfoDetail,
  loadingDetail,
  textRendered,
}) => {
  return (
    <section className="wrapper-settings category-management-detail" style={{height: "auto"}}>
      <div className="setting-position wrapper-inside">
        <CustomCard className="header-card" title={`Thông tin ${textRendered}`}>
          {loadingDetail ? (
            <div className="statistic-course-content statistic-course-loading">
              <Spin size="large" />
            </div>
          ) : (
            <section>
              <Form.Item className="setting-item" name="name" label={`Tên ${textRendered}`}>
                <p className="position-content">{universityInfoDetail?.name}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="code" label={`Mã ${textRendered}`}>
                <p className="position-content">{universityInfoDetail?.code}</p>
              </Form.Item>
            </section>
          )}
        </CustomCard>
      </div>
    </section>
  );
};

export default UniversityInfoDetail;
