import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './HistoryModal.scss';
import { getLearningMaterialHistory } from '../../../../service/learning-material';
import { LearningMaterialEnum } from '../../../../constants/learing-material';
import TableData from '../../../../components/table-data/TableData';
import dayjs from 'dayjs';
import { TableProps } from 'antd/lib';

type HistoryRecord = {

  id: number,
  description: string,
  shareType: number,
  status: number,
  createdDate: string,
  createdBy: number
};

type Props = {
  open: boolean
  handleCancel: () => void
  handleOk: () => void
  shareId: number
}
const HistoryModal = (props: Props) => {
  const { open, handleCancel, handleOk, shareId } = props
  const [historyList, setHistoryList] = useState<HistoryRecord[]>([])
  const renderAction = (action: number) => {
    switch (action) {
      case LearningMaterialEnum.DRAFT:
        return 'Tạo mới';
      case LearningMaterialEnum.APPROVAL_WAIT:
        return 'Chờ duyệt';
      case LearningMaterialEnum.APPROVED:
        return 'Duyệt';
      case LearningMaterialEnum.APPROVAL_DENIED:
        return 'Từ chối';
      case LearningMaterialEnum.UPDATE:
        return 'Sửa';
      default:
        return 'Không xác đ��nh';
    }
  }
  const columns: ColumnsType<HistoryRecord> = [
    {
      title: 'STT',
      dataIndex: 'numericalOrder',
      key: 'numericalOrder',
      width: '10%',
    },
    {
      title: 'Hành động',
      dataIndex: 'type',
      key: 'type',
      width: '30%',
      render: (_, record: any) => {

        return (
          <div>
            {renderAction(record.status)}
          </div>
        )
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '40%',
    },
    {
      title: 'Ngày',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '20%',
      render: (_, record: any) => {
        return (
          <div>
            {dayjs(record.createdDate).format("DD/MM/YYYY")}
          </div>
        )
      },
    },
  ];

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const res = await getLearningMaterialHistory(shareId, {})
        setHistoryList(res?.data.data.data);
      } catch (error) {

      }
    }
    if (open) {
      fetchHistory()
    }
  }, [open, shareId])

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
  };

  return (
    <Modal
      title="Lịch sử"
      centered
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className='learning-material-history-modal'
    >
      <TableData
        dataColumns={columns}
        dataTable={historyList}
        pagination={false}
        hideSelection={false}
        handleTableChangeProps={handleTableChange}
        scroll={{ x: 0, y: 460 }}

      />
    </Modal>
  );
};

export default HistoryModal;
