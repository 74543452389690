import React, { useEffect, useState } from 'react'
import "./style.scss"
import { getDetailAdminInterface } from '../../service/admin-interface';
import { globalStore } from '../../stores/stores';
import { routesConfig } from '../../config/routes';
import { postCourseSearch } from '../../service/course-construct';
type Props = {}

const MaintenancePage = (props: Props) => {
	const [logoPersonal, setLogoPersonal] = useState<any>("");
	const [logo, setLogo] = useState<any>("");
	const { reloadAdminInterface, personalInfo } = globalStore();
	const pathname = location.pathname;

	const getColor = async () => {
		setLogoPersonal(personalInfo?.logo);
	};

	const getColorDetail = async () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const id: any = urlParams.get("interfaceId");
		if (id) {
			await getDetailAdminInterface(id).then((res) => {
				if (res.status === 200) {
					setLogo(res.data?.data?.logo);
				}
			});
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const params = {}
			const res = await postCourseSearch({ params })
		}

		fetchData
	}, [])
	useEffect(() => {
		getColorDetail();
		getColor()
	}, []);
	return (
		<div className='maintenance-page'>
			<div className='maintenance-left'>
				<div className="logo-header"  >
					<div
						className="logo-mooc2 cursor-pointer"
					>
						{logo || logoPersonal ? (
							<img
								src={
									pathname.includes(routesConfig.detailAdminInterface)
										? logo
										: logoPersonal
								}
								className="w-8 h-8 rounded-lg object-cover"
								alt="logo-mooc"
							/>
						) : (
							<div className="shape2" />
						)}
						<h2 className="mooc2">
							{process.env.REACT_APP_PROJECT_NAME || ""}
						</h2>
					</div>

				</div>
				<div className='description'>


					<div className='text-title-36'>Xin lỗi quý khách!</div>
					<div className='text-description-16'>Hệ thống đang trong giai đoạn bảo trì. Quý khách vui lòng truy cập lại sau</div>
					<div className="dot-loading">
						<div className="dot-flashing ">
						</div>
					</div>
				</div>
			</div>
			<div className="maintenance-right">
				<img
					className='description-img'
					src={`${process.env.PUBLIC_URL}/assets/img/maintenance-img.png`} alt="" />
			</div>

		</div>
	)
}

export default MaintenancePage