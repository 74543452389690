import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigToUploadBase } from "../../config/api/configApiUploadFile";
import { AuthSSOPayload } from "../../types/auth";
import forge from "node-forge";
import { axiosConfigV2 } from "../../config/api/configApiv2";

let cachedPublicKey: string | null = null;

export const verifyPassword: (param: any) => Promise<AxiosResponse<any>> = (
  param: any
) => {
  return axiosConfig.post(`/authentications/verify-password`, param);
};

export const ssoAuthorize: (
  param: AuthSSOPayload
) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfigToUploadBase.post(`/v2/oauth2/authorize`, param);
};

function convertDerToPem(der: ArrayBuffer): string {
  const binaryDer = forge.util.createBuffer(new Uint8Array(der));
  const asn1Object = forge.asn1.fromDer(binaryDer);
  const publicKey = forge.pki.publicKeyFromAsn1(asn1Object);
  return forge.pki.publicKeyToPem(publicKey);
}

// Fetch the public key from the `.rsa` file
export async function fetchPublicKey(): Promise<string> {
  if (cachedPublicKey) return cachedPublicKey;

  try {
    const response = await fetch("/moocPublicKey.rsa", { cache: "reload" });

    if (!response.ok) {
      throw new Error(`Failed to fetch public key: ${response.statusText}`);
    }

    const binaryKey = await response.arrayBuffer();

    cachedPublicKey = convertDerToPem(binaryKey);
    return cachedPublicKey;
  } catch (error) {
    console.error("Failed to fetch or validate public key:", error);
    throw error;
  }
}

export const checkToken: () => Promise<AxiosResponse<any>> = (
) => {
  return axiosConfigV2.get(`/token-validator/check`);
};
