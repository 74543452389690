import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, CollapseProps, Drawer, Form, Input, message, Modal, Row, Space, Typography } from 'antd'

import FormInput from "../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import { FormInstance } from "antd/lib";
// import "./index.scss"
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { regexEmail, regexEmailV2, regexPassword } from '../../../../utils/regex';
import { isEmpty } from 'lodash';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { rolesSlices } from '../../../../stores/list-roles/slices';
import { createMinistryAccount, getDetailMinistryAccount, getManagementAgencys, getUserMinistry, updateAccountMinistry } from '../../../../service/list-acoount-ministry/listAccountMinistry';
import _ from 'lodash';
import { getRoles } from '../../../../service/list-account-users/listAccountUsersApi';

type Props = {
  open: boolean
  handleClose: () => void
  fetchData: () => void
  detailData: any
}

const FormCreateMinistry = (props: Props) => {
  const { open, handleClose, fetchData, detailData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;
  const [status, setStatus] = useState<number>(1)
  const [listEnterPrise, setListEnterPrise] = useState<any>([])
  const [listUserMinistry, setListUserMinistry] = useState<any>([])
  const [listRole, setListRole] = useState<any>([]);
  const [dataDetail, setDataDetail] = useState<any>({})

  const validations = {
    phone: {
      required: true,
      message: "Vui lòng nhập số điện thoại"
    },
    enterprise: {
      required: true,
      message: "Vui lòng chọn giảng viên"
    },
    emailContact: [
      {
        required: true,
        message: "Vui lòng nhập địa chỉ email"
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator: (_: unknown, value: any) => {
          if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
            return Promise.reject(
              new Error("Định dạng email không đúng, vui lòng nhâp lại")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    trainingFacility: {
      required: true,
      message: "Vui lòng chọn cơ sở đào tạo"
    },
    emailLogin: [
      {
        required: true,
        message: "Vui lòng nhập địa chỉ email"
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator: (_: unknown, value: any) => {
          if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
            return Promise.reject(
              new Error("Định dạng email không đúng, vui lòng nhâp lại")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    roleLogin: {
      required: true,
      message: "Vui lòng chọn vai trò"
    },
    passwordLogin: [
      {
        required: dataDetail?.id ? false : true,
        message: "Vui lòng nhập mật khẩu",
      },
      {
        min: 8,
        max: 25,
        message: "Độ dài mật khẩu từ 8-25 ký tự.",
      },
      {
        pattern: regexPassword,
        message:
          "Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt, và có độ dài từ 8-25 ký tự.",
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator(_: RuleObject, value: StoreValue) {
          if (value && getFieldValue("oldPassword") === value) {
            return Promise.reject(
              new Error("Mật khẩu mới phải khác mật khẩu hiện tại.")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    passwordLoginConfirm: [
      {
        required: dataDetail?.id ? false : true,
        message: "Vui lòng xác nhập mật khẩu mới",
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator(_: RuleObject, value: StoreValue) {
          if (!isEmpty(value)) {
            if (getFieldValue("passwordLogin") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Mật khẩu không khớp.")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
  }

  const createAccount = async (values: any) => {
    const data = {
      userProfileId: values.enterprise,
      username: values.emailLogin,
      password: values.passwordLogin,
      confirmPassword: values.passwordLoginConfirm,
      status: status === 1 ? true : false,
      roleIds: _.isArray(values.roleLogin) ? values.roleLogin : [values.roleLogin]
    }
    if (dataDetail?.id) {
      await updateAccountMinistry(detailData?.id, data).then((res) => {
        if (res.status === 200) {
          message.success("Sửa tài khoản thành công")
        }
      })
    } else {
      await createMinistryAccount(data).then((res) => {
        if (res.status === 200) {
          message.success("Tạo tài khoản thành công")
        }
      })
    }
    handleClose()
    form.resetFields()
    fetchData()
    setDataDetail([])
  }

  const getListUserMinistry = async (value: any) => {
    await getUserMinistry(value).then((res) => {
      if (res.status === 200) {
        setListUserMinistry(res.data?.data)
      }
    })
  }

  //lay danh sach vai trò
  const getRole = async () => {
    await getRoles()
      .then((res) => {
        const { data } = res.data
        setListRole(data)
      })
  }

  const blockOne = () => (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="trainingFacility" label="Đơn vị" rules={[validations.trainingFacility]}>
            <FormItemDropdown
              options={listEnterPrise}
              allowClear={true}
              placeholder="Chọn đơn vị"
              onChange={getListUserMinistry}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="enterprise" label="Người dùng" rules={[validations.enterprise]}>
            <FormItemDropdown
              options={listUserMinistry}
              allowClear={true}
              placeholder="Chọn người dùng"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const blockTwo = () => (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="emailLogin" label="Email đăng nhập" rules={validations.emailLogin}>
            <FormInput
              placeholder='Nhập email đăng nhập'
              autoComplete="new-password"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="roleLogin" label="Vai trò" rules={[validations.roleLogin]}>
            <FormItemDropdown
              options={listRole}
              allowClear={true}
              placeholder="Chọn vai trò"
              modeDropdown="multiple"
            />
          </Form.Item>
        </Col>
      </Row>
      {
        dataDetail?.id ? null :
          <Row gutter={24} className="">
            <Col span={12}>
              <Form.Item name="passwordLogin" label="Mật khẩu" rules={validations.passwordLogin}>
                <FormInput
                  placeholder="Nhập mật khẩu cho tài khoản"
                  passwordInput={true}
                  style={{ height: '32px' }}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="passwordLoginConfirm" label="Xác nhận mật khẩu" rules={validations.passwordLoginConfirm}>
                <FormInput
                  placeholder="Xác nhận mật khẩu cho tài khoản"
                  passwordInput={true}
                  style={{ height: '32px' }}
                />
              </Form.Item>
            </Col>
          </Row>
      }
      <Row gutter={24} className="">
        <Col span={24}>
          <Form.Item name="status" label="Trạng thái">
            <Space.Compact block>
              <Button className={status === 1 ? 'active' : ''} onClick={() => setStatus(1)}>Mở khóa</Button>
              <Button className={status === 2 ? 'active' : ''} onClick={() => setStatus(2)}>Khóa</Button>
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <Typography.Title level={5}><strong>Thông tin người dùng</strong></Typography.Title>,
      children: blockOne(),
    },
    {
      key: '2',
      label: <Typography.Title level={5}><strong>Thông tin tài khoản</strong></Typography.Title>,
      children: blockTwo(),
    },
  ];

  const getListEnterprise = async () => {
    await getManagementAgencys().then((res) => {
      if (res.status === 200) {
        const arr = res.data?.data
        setListEnterPrise(arr)
      }
    })
  }

  //lay chi tiet tai khoản giảng viên
  const getListDataDetail = async () => {
    await getDetailMinistryAccount(detailData?.id).then((res) => {
      if (res.status === 200) {
        const detail = res.data?.data
        setDataDetail(res.data?.data)
        form.setFieldsValue({
          managementAgency: detail?.universityRegisterId ? detail?.universityRegisterId : undefined,
          contactPeople: detail?.userContact,
          contactRolePeople: detail?.userContactPositionId,
          phone: detail?.userContactPhone,
          emailContact: detail?.userContactEmail,
          trainingFacility: detail?.unitId,
          emailLogin: detail?.username,
          enterprise: detail?.userProfileId,
          roleLogin: detail?.roles?.map((item: any) => { return item.id }),
        })
        getListUserMinistry(detail?.unitId)
        setStatus(detail?.isActive ? 1 : 2)
      }
    })
  }

  useEffect(() => {
    if (open) {
      getListEnterprise()
      getRole()
    }
  }, [open])

  useEffect(() => {
    if (detailData?.id) {
      getListDataDetail();
    } else {
      form.resetFields()
    }
  }, [detailData])

  const clearFilter = () => {
    form.resetFields()
    setDataDetail([])
    handleClose()
  }

  return (
    <>
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === "add-chapter") {
          Modal.confirm({
            title: `Xác nhận tạo tài khoản`,
            content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra trước khi xác nhận.`,
            onOk: () => {
              createAccount(values)
            },
            okText: "Xác nhận",
            cancelText: "Huỷ",
            centered: true,
            icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            ),
          });
        }
      }}>
        <Drawer
          title="Tạo tài khoản người dùng cấp bộ"
          open={open}
          onClose={clearFilter}
          width={736}
          extra={
            <Space>
              <Button onClick={() => {
                form.resetFields()
                clearFilter()
              }} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>
                  Hủy
                </Typography.Text>
              </Button>
              <Button className="btn btn-primary"
                onClick={() => form.submit()
                }>
                <SaveOutlined />
                <Typography.Text>
                  Lưu
                </Typography.Text>
              </Button>
            </Space>
          }
        >
          <Form
            className="create-form-facility-management"
            layout={"vertical"}
            form={form}
            name="add-chapter"
            autoComplete='off'
            initialValues={{ emailLogin: '', passwordLogin: '' }}
          >
            <Collapse defaultActiveKey={['1', '2']} ghost items={items} />
          </Form>
        </Drawer>
      </Form.Provider>
    </>
  );
};

export default FormCreateMinistry;
