import React, {MouseEvent} from 'react';
import {Modal, Typography} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined} from "@ant-design/icons";
import {DraggerProps} from "antd/lib/upload";
import './index.scss'

type ModalUploadProps = {
  propsDragger?: DraggerProps,
  downloadSample?: (e: MouseEvent) => void,
  title?: string,
  okText?: string,
  cancelText?: string,
  open?: boolean,
  isHasUsers?: boolean,
  isHasStudent?: boolean,
  checkError?: boolean,
  onOk?: () => void,
  onCancel?: () => void
}

// @ts-ignore
const ModalUploadFileExcel = ({
                                propsDragger,
                                downloadSample,
                                title,
                                open,
                                isHasUsers,
                                isHasStudent,
                                onOk,
                                onCancel,
                                cancelText,
                                okText,
                                checkError
                              }: ModalUploadProps) => {
  return (
    <>
      <Modal
        centered
        title={title}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        cancelText={cancelText}
        okText={okText}
        okButtonProps={{disabled: checkError}}
      >
        <Dragger {...propsDragger} className="upload-instructors"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined/>
          </p>
          <p className="ant-upload-text">Nhấn hoặc thả tệp lên từ máy tính của bạn <span className="link">vào đây</span>
          </p>
          <p className="ant-upload-hint">
            Định dạng tệp khả dụng: CSV (Tối đa 01 tệp - 10 MB)
          </p>
        </Dragger>
        <div className="modal-import--tutorial">
          <Typography.Text className="title-tutorial">
            Hướng dẫn các bước nạp tệp danh sách {isHasUsers ? 'người dùng' : isHasStudent ? 'học viên' : 'trường'}
          </Typography.Text>
          <ol className="list-step--import">
            <li>
              <p>
                Tải mẫu điền danh sách {isHasUsers ? 'người dùng' : isHasStudent ? 'học viên' : 'trường'} <a
                className="link"
                onClick={(e) => downloadSample ? downloadSample(e) : null}>tại
                đây</a>
              </p>
            </li>
            <li>
              <p>
                Điền danh
                sách {isHasUsers ? 'người dùng' : isHasStudent ? 'học viên' : 'trường'}. {isHasUsers ? 'Người dùng phải là Giảng viên, QTCS hoặc người dùng cấp bộ' : isHasStudent ? 'học viên' : 'Trường'} đã
                đăng ký tài
                khoản trên hệ thống quản trị {process.env.REACT_APP_PROJECT_NAME || ""} và lưu file theo định dạng .CSV
              </p>
            </li>
            <li>
              <p>
                Nhấn hoặc thả tệp cần tải lên vào khu vực tải tệp
              </p>
            </li>
            <li>
              <p>
                Kiểm tra tệp trước khi chọn “Xác nhận"
              </p>
            </li>
          </ol>
        </div>
      </Modal>
    </>
  );
};

export default ModalUploadFileExcel;
