import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";
import { axiosConfigV2 } from "../../../config/api/configApiv2";
import { axiosConfigV2OpenAPI } from "../../../config/api/configOpenApiV2";
import { axiosConfigToUpload } from "../../../config/api/configApiUpload";

export const getMoocStudentById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-university-student/${id}`)
}

export const getIndustryGroupStudent: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfig.get(`/lookup/get-industry-group`)
}

export const getIndustryStudent: (codeGroup: string) => Promise<AxiosResponse<any>> = (codeGroup) => {
    return axiosConfig.get(`/lookup/get-industry?codeGroup=${codeGroup}`)
}

export const getAddressProvinces: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2OpenAPI.get(`/address/provinces?code=4`)
}

export const downloadStudentTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/mooc-university-student/user/download-template-excel`, {responseType: "arraybuffer",})
}

export const getMoocEnterpriseUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university/get-all-enterprise-customer`, data)
}
export const getStudentDetailByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-history/user-profile/get-all-by-filter`,data);
}

export const getAllStudentByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university-student/get-all-by-filter`, data)
}

export const exportExcelMoocStudentDetail: (id : any) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-university-student/export-excel/${id}`,{responseType: "arraybuffer",})
}

export const exportExcelMoocStudent: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university-student/export-excel`, data, {responseType: "arraybuffer",})
}

export const importExcelMoocStudent: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/mooc-university-student/import-excel`, data, {responseType: "arraybuffer",})
}

export const addMoocStudent: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-university-student`, data)
}

export const updateMoocStudent: (userId: number, data: any) => Promise<AxiosResponse<any>> = (userId, data) => {
    return axiosConfigV2.put(`/mooc-university-student/${userId}`, data)
}

export const changeStatusStudent: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-university-student/change-status`, data)
}

export const deleteMoocStudent: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/mooc-university-student/${id}`)
}

export const importApprovedStudentMoocSynchronizationData: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-synchronization-data/approved/student`, data)
}

export const getStudentMoocSynchronizationDataByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-synchronization-data/search/student`, data)
}