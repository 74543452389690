// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
  color: grey; }

* {
  box-sizing: border-box; }

body {
  margin: 0; }

* {
  box-sizing: border-box; }

body {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px; }

* {
  box-sizing: border-box; }

body {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px; }

.certificate-template iframe {
  margin-top: 0px; }

#certificate-id,
#certificate-id-2 {
  width: 297mm;
  height: 210mm; }
`, "",{"version":3,"sources":["webpack://./src/pages/certificate/certificate-template/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;;AAGb;EACE,sBAAsB,EAAA;;AAGxB;EACE,SAAS,EAAA;;AAGX;EACE,sBAAsB,EAAA;;AAGxB;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB,EAAA;;AAGlB;EACE,sBAAsB,EAAA;;AAGxB;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB,EAAA;;AAkClB;EAEI,eAAe,EAAA;;AAInB;;EAEE,YAAY;EACZ,aAAa,EAAA","sourcesContent":[".description {\n  color: grey;\n}\n\n* {\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n}\n\n* {\n  box-sizing: border-box;\n}\n\nbody {\n  margin-top: 0px;\n  margin-right: 0px;\n  margin-bottom: 0px;\n  margin-left: 0px;\n}\n\n* {\n  box-sizing: border-box;\n}\n\nbody {\n  margin-top: 0px;\n  margin-right: 0px;\n  margin-bottom: 0px;\n  margin-left: 0px;\n}\n\n// #i3lz{\n//   height:1367px;\n// }\n// #iilc{\n//   font-family:Georgia, serif;\n// }\n// #i2ak{\n//   font-family:Georgia, serif;\n//   color:rgb(180, 129, 74);\n// }\n// #i76x1 {\n//   font-family: Georgia, serif;\n// }\n\n// #ir0ml {\n//   font-family: Georgia, serif;\n//   color: rgb(126, 127, 121);\n// }\n\n// #i1c4b {\n//   font-family: Georgia, serif;\n// }\n\n// #i2ifr {\n//   font-family: Georgia, serif;\n// }\n\n// #i781e {\n//   font-family: Georgia, serif;\n// }\n\n.certificate-template {\n  iframe {\n    margin-top: 0px;\n  }\n}\n\n#certificate-id,\n#certificate-id-2 {\n  width: 297mm;\n  height: 210mm;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
