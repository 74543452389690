// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-learning-materials .ant-radio-group {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/learning-materials/components/LearningMaterialDrawer/LearningMaterialDrawer.scss"],"names":[],"mappings":"AAAA;EAEE,WAAW,EAAA","sourcesContent":[".add-learning-materials {\n\t.ant-radio-group {\n\t\twidth: 100%;\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
