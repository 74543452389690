import { useEffect, useRef, useState } from 'react';
import { getIdIndexDB } from '../../../../../../../pages/page-builder/grapesjs-service';
import './survey-answers-info.scss';
import { getDetailUserSurveyAnswer } from '../../../../../../../service/course-construct/survey-plan';
import { useAddLesson } from '../../../../../../../stores/stores';
import { MenuUnfoldOutlined, SettingOutlined } from '@ant-design/icons';
import { Col, Collapse, Row } from 'antd';
import { formatDateToIsoStringHHss } from '../../../../../../../utils/converDate';
const { Panel } = Collapse;

type UserDetailType = {
  createdDate?: string;
  email?: string;
  industryName?: string[];
  nameUser?: string;
  surveyName?: string;
  totalQuiz?: string;
  universityName?: string;
}

const SurveyAnswersInfo = () => {
  const isFirstRender = useRef(true);

  const [userDetail, setUserDetail] = useState<UserDetailType>({});

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getIdIndexDB('current-survey', (val: any) => {
        getUserDetail(val.record.id);
      });
    }
  }, []);

  const getUserDetail = async (id: any) => {
    await getDetailUserSurveyAnswer(id).then((res: any) => {
      setUserDetail(res.data.data);
    });
  }

  return <section className='setting-question-course'>
    <div className="setting-question-course__header flex">
      <SettingOutlined className="setting-question-course__header__second" />
      <div className="setting-question-course__header__title">Thông tin chung</div>
    </div>
    <div className="setting-question-course__content">
      <Collapse defaultActiveKey={[1, 2]}>
        <Panel key={1} header={'Thông tin học viên'}>
          <div className="sub-content">
            <Row className='sub-content__item'>
              <Col span={12} className='sub-content__item__title'>Họ và tên</Col>
              <Col span={12} className='sub-content__item__content'>{userDetail.nameUser}</Col>
            </Row>
            <Row className='sub-content__item'>
              <Col span={12} className='sub-content__item__title'>Email</Col>
              <Col span={12} className='sub-content__item__content'>{userDetail.email}</Col>
            </Row>
            <Row className='sub-content__item'>
              <Col span={12} className='sub-content__item__title'>Trường</Col>
              <Col span={12} className='sub-content__item__content'>{userDetail.universityName}</Col>
            </Row>
            <Row className='sub-content__item'>
              <Col span={12} className='sub-content__item__title'>Chuyên ngành</Col>
              <Col span={12} className='sub-content__item__content'>{userDetail.industryName?.join(', ')}</Col>
            </Row>
          </div>
        </Panel>
        <Panel key={2} header={'Thông tin Khảo sát'}>
          <div className="sub-content">
            <Row className='sub-content__item'>
              <Col span={12} className='sub-content__item__title'>Tên khảo sát</Col>
              <Col span={12} className='sub-content__item__content'>{userDetail.surveyName}</Col>
            </Row>
            <Row className='sub-content__item'>
              <Col span={12} className='sub-content__item__title'>Thời gian thực hiện</Col>
              <Col span={12} className='sub-content__item__content'>{formatDateToIsoStringHHss(userDetail.createdDate ? userDetail.createdDate : '')}</Col>
            </Row>
            <Row className='sub-content__item'>
              <Col span={12} className='sub-content__item__title'>Số câu trả lời</Col>
              <Col span={12} className='sub-content__item__content'>{userDetail.totalQuiz}</Col>
            </Row>
          </div>
        </Panel>
      </Collapse>
    </div>
  </section>
}

export default SurveyAnswersInfo;