import { Col, Form, Row, Spin} from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent, useEffect, useState } from "react";
import FormInput from "../../../../../components/form-input/FormInput";

const validations = {
	code: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã chức vụ không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã chức vụ"))
			}
			return Promise.resolve()
		}
	},
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên chức vụ không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên chức vụ"))
			}
			return Promise.resolve()
		}
	},
}

type FormCreatePositionProps = {
	form: FormInstance,
	onFinish?: any,
	dataOptions?: any,
	loadingForm: boolean,
}

const FormCreatePosition: FunctionComponent<FormCreatePositionProps> = ({ form, onFinish, dataOptions, loadingForm }) => {
    return (
        <>
            <Form layout={"vertical"} form={form} onFinish={onFinish} name="createPosition" initialValues={{ status : true}}>
				{loadingForm ? 
					<div className="statistic-course-content statistic-course-loading">
						<Spin size="large" />
					</div> 
					: 
					<Row gutter={[16,16]}>
						<Col span={24}>
							<Form.Item name="code" rules={[validations.code]} label="Mã chức vụ">
								<FormInput placeholder="Nhập mã chức vụ" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="name" rules={[validations.name]} label="Tên chức vụ">
								<FormInput placeholder="Nhập tên chức vụ" />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
        </>
    )
}

export default FormCreatePosition;