import { Button, Dropdown, Form, Layout, MenuProps, Modal, Space, TableColumnsType, TableProps, Typography, message } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, MoreOutlined, SaveOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { DataType, validateFieldCreate } from "./constants/data";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";
import DrawerCustom from "../../../components/drawer/Drawer";
import { formatDateV2 } from "../../../utils/converDate";
import { isEmpty } from "lodash";
import FormCreatePosition from "./components/form-create-position/FormCreatePosition";
import { addMoocPosition, deleteMoocPosition, getAllPositionByFilter, getMoocPositionById, updateMoocPosition } from "../../../service/category-management/position-management";
import { routesConfig } from "../../../config/routes";
import "./position-management.scss";

type PositionManagementProps = {
  title?: string;
};

const PositionManagement = ({ title }: PositionManagementProps) => {
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [editId, setEditId] = useState<number>(0);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [dataPosition, setDataPosition] = useState<any>([]);
  const navigate = useNavigate();

  const [dataOptions, setDataOptions] = useState<any>({
    dataUser: [],
  });

  const [filterData, setFilterData] = useState<any>({
    user: null,
    createdDateFrom: null,
    createdDateTo: null,
  });

  const PositionsOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa chức vụ",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa chức vụ",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên chức vụ",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      className:"title-sequence",
      render: (_: any, record) => {
        return (
          <div className="table-position cursor-pointer">
            <div>
              <a
                className="position-name"
                onClick={() =>
                  navigate(
                    `${routesConfig.positionManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                      },
                    }
                  )
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mã chức vụ",
      dataIndex: "code",
      key: "code",
      className:"title-sequence",
      width: 20,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any, index: number) => {
        return record.createdDate ? formatDateV2(record.createdDate) : null;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.positionManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        idEdit: record?.id,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: PositionsOptions,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      getPositionUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Xoá chức vụ`,
                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                        onOk: () => deletePosition(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị danh mục",
    },
  ];

  // Get data Position
  const getAllPosition = (data?: any) => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      createdDateFrom: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdDateTo: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`],
    };
    getAllPositionByFilter(dataReq)
      .then((res) => {
        if(res.status === 200) {
          setDataPosition(res.data?.data?.content);
          setTotalRecords(res.data?.data?.totalElements);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          navigate("/error/403");
        } else {
          console.error(err);
        }
        setLoading(false);
      }).finally(() => {setLoading(false);});
  };
  
  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      user: null,
      createdDateFrom: null,
      createdDateTo: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      getAllPosition();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    getAllPosition();
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      user: values?.user,
      createdDateFrom: values?.time ? (values?.time[0]).add(1, "day").toISOString() : null,
      createdDateTo: values?.time ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };

  // Get Position to edit
  const getPositionUpdate = async (id: number) => {
    setLoadingForm(true);
    await getMoocPositionById(id).then((res) => {
      const { data } = res.data;
      setDataDetail(data);
      setTimeout(() => {
        setLoadingForm(false);
      }, 333);
    })
      .catch((err) => {
        setLoadingForm(false);
        console.error(err);
      });
  };

  // Modal create | edit
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    form.resetFields();
  };

  const handleCreateAndEdit = () => {
    Modal.confirm({
      title: !isOpenedEdit ? `Thêm mới chức vụ` : `Chỉnh sửa chức vụ` ,
      content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
      onOk: () => form.submit(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  const onFinishCreate = async (values: any) => {
    if (Object.keys(values).length > 0) {
      if (isOpenedEdit) {
        const dataPayload = {
          id: editId,
          name: values.name.trim(),
          code: values.code.trim(),
        };
        setIsSaving(true);
        try {
          await updateMoocPosition(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Cập nhật chức vụ thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllPosition();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      } else {
        const dataPayload = {
          id: null,
          name: values.name.trim(),
          code: values.code.trim(),
        };
        setIsSaving(true);
        try {
          await addMoocPosition(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới chức vụ thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllPosition();
          })
        } catch (err) {
          console.error(err);
          setIsSaving(false);
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  // Delete Position
  const deletePosition = async (position: any) => {
    try {
      await deleteMoocPosition(position.id).then((res) => {
        if (res.status === 200) {
          message.success("Xóa chức vụ thành công!");
          getAllPosition();
        }
      });
    } catch (err) { }
  };

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // useEffect
  useEffect(() => {
    if (Object.keys(dataDetail)?.length > 0) {
      form.setFieldsValue({
        name: dataDetail?.name,
        code: dataDetail?.code
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    getAllPosition();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createPosition") {
            const { createPosition } = forms;
            const data = createPosition.getFieldsValue(validateFieldCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>

        <TableData
          dataTable={dataPosition}
          dataColumns={dataColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          loadingTable={loading}
          handleTableChangeProps={handleTableChange}
        />

        <DrawerCustom title={`${isOpenedEdit ? "Chỉnh sửa chức vụ" : "Thêm mới chức vụ"}`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit" onClick={handleCreateAndEdit} disabled={isSaving}>
                {isSaving ? <LoadingOutlined /> : <SaveOutlined />}
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreatePosition form={form} dataOptions={dataOptions} loadingForm={loadingForm} />
        </DrawerCustom>
      </Form.Provider>
    </Layout>
  );
};

export default PositionManagement;



