import React from "react";
import { Checkbox, Flex, Form, Radio } from "antd";
import {
  JSON_SUB_TYPE,
  QuestionBeProps,
  QuestionProps,
  QuestionPropsV2,
} from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";

function ChooseText({ data, initialData, disabled }: QuestionPropsV2) {
  const isTrueAnswer = data?.questions?.find((item: QuestionBeProps) => item.isTrue === true)
  return (
    <Form.Item
      initialValue={isTrueAnswer?.uuid}
      name={`chooseText-${data?.id}-${QuestionTypeEnum.CHOOSE_TEXT}-${JSON_SUB_TYPE.ChoosingRequest}`}
    >
      <Radio.Group className="flex flex-column gap-12" disabled={disabled}>
        {data?.questions && data?.questions?.length > 0 ? (
          data?.questions?.map((quiz: QuestionBeProps, index) => (
            <Radio checked={quiz.isTrue} className="flex" key={quiz?.uuid + index} value={quiz?.uuid}>
              <Flex className="font-size-16 line-height-24 gap-1">
                {convertNumberingTypeToText(
                  index + 1,
                  Number(JSON.parse(data?.settingGeneral)?.numberingType) || 0
                )}
                .
                <div
                  className="font-size-16 line-height-24"
                  dangerouslySetInnerHTML={{ __html: `${quiz?.content}` }}
                ></div>
              </Flex>
            </Radio>
          ))
        ) : (
          <div className="center">
            <EmptyComponent description="Không có nội dung" />
          </div>
        )}
      </Radio.Group>
    </Form.Item>
  );
}

export default ChooseText;
