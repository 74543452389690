import React, { KeyboardEvent, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Layout,
  message,
  Modal,
  PaginationProps,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import FormItemInput from "../../components/form-input/FormInput";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./style.scss";
import FilterSearch, { FilterSearchValues } from "./FilterSearch";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import {
  deleteNotifications,
  searchCreatedNotification,
} from "../../service/notifications";
import { DEFAULT_PAGE_NUM, DEFAULT_PAGE_SIZE } from "../../constants";
import CustomPopover from "../../components/popover";
import {
  NotificationProps,
  NotificationsPayload,
} from "../../types/notifications";
import moment from "moment";
import { isEmpty } from "lodash";
import AddNotification from "./AddNotification";
import { NotificationStatus } from "../../constants/notifications";
import { headerStore } from "../../stores/stores";

interface NotificationsProps {
  title?: string;
}

function Notifications({ title }: NotificationsProps) {
  const navigate = useNavigate();
  const onReloadHeader = headerStore((state) => state.onReloadHeader);
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [total, setTotal] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [hoverSortMessage, setHoverSortMessage] = useState<{
    triggerDesc: string;
    triggerAsc: string;
  }>({
    triggerDesc: "Sắp xếp theo thứ tự Z-A",
    triggerAsc: "Sắp xếp thứ tự A-Z",
  });

  //filter
  const [statusFilter, setStatusFilter] = useState<number>();
  const [creatorEmails, setCreatorEmails] = useState<string[]>();
  const [createdDate, setCreatedDate] = useState<{
    createdFrom: string;
    createdTo: string;
  }>();
  const [sort, setSort] = useState<string[]>();
  const [searchValue, setSearchValue] = useState<string>("");

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };  

  const handleSubmitSearch = () => {
    searchNotification({
      page: 1,
      size: pageSize,
      status: statusFilter,
      creatorEmails: creatorEmails,
      createdFrom: createdDate?.createdFrom,
      createdTo: createdDate?.createdTo,
      sort: sort,
      keyword: searchValue?.trim(),
    });
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };

  useEffect(() => {
    searchNotification({
      page: pageNum,
      size: pageSize,
      status: statusFilter,
      creatorEmails: creatorEmails,
      createdFrom: createdDate?.createdFrom,
      createdTo: createdDate?.createdTo,
      sort: sort,
      keyword: searchValue,
    });
  }, [
    pageNum,
    pageSize,
    statusFilter,
    creatorEmails,
    createdDate,
    sort,
    refresh,
  ]);

  const searchNotification = async (payload: NotificationsPayload) => {
    try {
      setLoading(true);
      const res = await searchCreatedNotification(payload);
      setNotifications(res.data.data?.notifications);
      setTotal(res.data.data?.total);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const sortField =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";

    setSort(sortField ? [`${sorter?.field},${sortField}`] : undefined);
  };

  const displayStatus = (status: number | string) => {
    switch (status) {
      case NotificationStatus.DRAFT:
        return (
          <Flex align="center">
            <span className="dot blue"></span>Nháp
          </Flex>
        );
      case NotificationStatus.SENT:
        return (
          <Flex align="center">
            <span className="dot green"></span> Đã gửi
          </Flex>
        );
      case NotificationStatus.RE_CALL:
        return (
          <Flex align="center">
            <span className="dot orange"></span>Thu hồi
          </Flex>
        );
      default:
        return "_";
    }
  };

  const dataColumns: TableColumnsType<NotificationProps> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: "5%",
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã",
      dataIndex: "id",
      key: "id",
      sorter: true,
      width: "7%",
      onHeaderCell: (column) => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp theo thứ từ lớn đến nhỏ",
              triggerAsc: "Sắp xếp theo thứ tự từ nhỏ đến lớn",
            });
          },
        };
      },
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      sorter: true,
      onHeaderCell: (column) => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp theo thứ tự Z-A",
              triggerAsc: "Sắp xếp theo thứ tự A-Z",
            });
          },
        };
      },
    },

    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (_: any, record) => {
        return <div>{displayStatus(record.status)}</div>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: "10%",
      render: (_: any, record) => {
        return moment(record?.createdDate).format("DD/MM/YYYY");
      },
      onHeaderCell: (column) => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp thứ tự mới đến cũ",
              triggerAsc: "Sắp xếp theo cũ đến mới",
            });
          },
        };
      },
    },
    {
      title: "Email",
      dataIndex: "creatorEmail",
      key: "creatorEmail",
    },
    {
      fixed: "right",
      key: "view",
      width: "9%",
      render: (_: any, record) => {
        return (
          <Flex gap={8} align="center">
            <Button
              className="view-btn"
              onClick={() =>
                navigate(`${routesConfig.notifications}/${record?.id}`)
              }
            >
              <EyeOutlined />
            </Button>
            <CustomPopover
              title={false}
              rootClassName="notification-action"
              placement="bottomRight"
              content={
                <div>
                  {record?.status === 2 ? (
                    <Flex gap={8} className="action-item action-item-disabled">
                      <EditOutlined /> Sửa thông báo
                    </Flex>
                  ) : (
                    <Flex
                      gap={8}
                      className="action-item"
                      onClick={() =>
                        navigate(
                          `${routesConfig.notifications}/${record?.id}/edit`
                        )
                      }
                    >
                      <EditOutlined /> Sửa thông báo
                    </Flex>
                  )}
                  <Flex
                    gap={8}
                    className="action-item"
                    onClick={() =>
                      navigate(
                        `${routesConfig.notifications}/${record?.id}/history`
                      )
                    }
                  >
                    <HistoryOutlined /> Lịch sử hoạt động
                  </Flex>
                  <Flex
                    gap={8}
                    className="action-item text-danger"
                    onClick={() => handleDeleteNotifications([record?.id])}
                  >
                    <DeleteOutlined /> Xóa thông báo
                  </Flex>
                </div>
              }
            >
              <Button className="view-btn">
                <MoreOutlined />
              </Button>
            </CustomPopover>
          </Flex>
        );
      },
    },
  ];

  const handleDeleteNotifications = async (ids?: number[]) => {
    Modal.confirm({
      type: "warning",
      icon: <InfoCircleOutlined />,
      title: (
        <span className="font-weight-5">Xác nhận xoá toàn bộ thông báo</span>
      ),
      content: (
        <span>
          {ids?.length === 1 
          ? 'Thông báo đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá thông báo?' 
          : 'Thông báo đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá toàn bộ thông báo đã chọn?'}       
        </span>
      ),
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        if (!isEmpty(ids)) {
          try {
            setLoadingDelete(true);
            await deleteNotifications({
              ids: ids as number[],
            });
            message.success("Xoá thông báo thành công!");
            navigate(routesConfig.notifications);
            setRefresh(!refresh);
            setSelectedRowKeys([]);
            onReloadHeader();
          } catch (err) {
            console.error(err);
          } finally {
            setLoadingDelete(false);
          }
        }
      },
    });
  };

  const handleChangePaginate = (page: number, size: number) => {
    setPageNum(page);
    setPageSize(size);
  };

  const handleFilter = (value: FilterSearchValues) => {
    setPageNum(1);
    setCreatedDate({
      createdFrom:
        value.date && value.date[0] ? value.date[0].toISOString() : undefined,
      createdTo:
        value.date && value.date[1] ? value.date[1].toISOString() : undefined,
    });
    setStatusFilter(value.status ? value.status : undefined);
    setCreatorEmails(
      !isEmpty(value.createdPerson) ? value.createdPerson : undefined
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };

  const locale = {
    emptyText: (
      <span>
        <p>
          <img
            className="image-empty-data"
            src="/images/empty-img-gray.png"
            alt="empty-img"
          ></img>
        </p>
        <p className="nodata-text">Không tìm thấy</p>
      </span>
    ),
    triggerDesc: hoverSortMessage.triggerDesc,
    triggerAsc: hoverSortMessage.triggerAsc,
    cancelSort: "Huỷ sắp xếp",
  };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="notifications-page w-full">
          {isEmpty(selectedRowKeys) ? (
            <div className="notifications-page-header">
              <div className="flex justify-between align-center gap-8">
                <h3 className="page-title flex-1">{title}</h3>
                <Button onClick={() => setIsShowFilter(!isShowFilter)}>
                  Bộ lọc
                  <DownOutlined
                    className={`mooc-arrow ${
                      isShowFilter ? "down-arrow" : "up-arrow"
                    }`}
                  />
                </Button>
                <div className="search-input">
                  <FormItemInput
                    style={{ width: 280 }}
                    placeholder="Nhập mã hoặc tiêu đề thông báo"
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearch}
                    afterPrefixIcon={
                      <SearchOutlined onClick={handleSubmitSearch} />
                    }
                  />
                </div>
                <AddNotification />
              </div>
              {isShowFilter && <FilterSearch onSearch={handleFilter} />}
            </div>
          ) : (
            <div className="notifications-page-header">
              <div className="flex justify-between align-center gap-8">
                <h3 className="page-title flex-1">{title}</h3>

                <Button
                  danger
                  icon={<DeleteOutlined />}
                  loading={loadingDelete}
                  onClick={() =>
                    handleDeleteNotifications(selectedRowKeys as number[])
                  }
                >
                  Xóa thông báo
                </Button>
              </div>
            </div>
          )}

          <div className="notifications-content">
            <Table
              loading={loading}
              dataSource={notifications?.map(
                (item: NotificationProps, index: number) => ({
                  ...item,
                  numericalOrder:
                    pageSize && pageNum
                      ? pageSize * (pageNum - 1) + index + 1
                      : index + 1,
                  key: item.id,
                })
              )}
              locale={locale}
              columns={dataColumns}
              rowSelection={rowSelection}
              className="pagination-table"
              scroll={{ x: 1400, y: 600 }}
              onChange={handleTableChange}
              pagination={{
                total: total,
                pageSize: pageSize,
                current: pageNum,
                itemRender: itemRender,
                showSizeChanger: true,
                onChange: handleChangePaginate,
                locale: {
                  items_per_page: "/ trang",
                  jump_to: "Đi đến trang",
                  page: "",
                },
                showQuickJumper: true,
                showTotal: (total: number) =>
                  (selectedRowKeys && selectedRowKeys.length) > 0
                    ? `Đã chọn ${selectedRowKeys.length}/${total} thông báo`
                    : `Tổng số ${total} bản ghi`,
              }}
            />
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default Notifications;
