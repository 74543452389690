import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss";
import { FormInstance } from "antd/lib";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import { getAllEnterpriseUniversityVer2 } from "../../../../service/management-participate-platform";
import { globalStore } from "../../../../stores/stores";
import { Permission } from "../../../../layout/sidebar/permission.enum";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  dataOptions?: any;
  filterData: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  filterData,
  setFilterData,
}) => {
  const { RangePicker } = DatePicker;
  const [listEnterPrise, setListEnterPrise] = useState<any>([]);
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const listStatus = [
    {
      id: 1,
      name: 'Khóa'
    },
    {
      id: 2,
      name: 'Mở khóa'
    },
  ]

  const getListEnterprise = async () => {
    await getAllEnterpriseUniversityVer2({}).then((res) => {
      if (res.status === 200) {
        const arr = res.data?.data?.map((item: any) => ({
          id: item.uuid,
          name: item.name
        }))
        setListEnterPrise(arr)
      }
    })
  }

  useEffect(() => {
    getListEnterprise();
  }, []);

  useEffect(() => {
    if(permissions?.includes(Permission.isQTCS)) {
      form.setFieldValue("universityId", personalInfo?.enterpriseUUID);
    }
  }, []);

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
            <Col
              xl={{ span: 5 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>
                Trường đại học
              </Typography.Title>
              <Form.Item name="universityId">
                <FormItemDropdown placeholder="Chọn trường" onChange={(option) => setFilterData(option, 'universityId')}
                  options={listEnterPrise} disabled={permissions?.includes(Permission.isQTCS)}/>
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 5 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>
                Trạng thái
              </Typography.Title>
              <Form.Item name="status">
                <FormItemDropdown placeholder="Chọn trạng thái" onChange={(option) => setFilterData(option, 'status')}
                  options={listStatus} />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Ngày tạo</Typography.Title>
              <Form.Item name="approvedDate">
                <RangePicker
                  placeholder={["Từ ngày", "Đến ngày"]}
                  format="YYYY-MM-DD"
                  value={filterData.approvedDate}
                  onChange={(_value, dateString: any) =>
                    setFilterData(dateString, "approvedDate")
                  }
                />
              </Form.Item>
            </Col>

            <Col
              xl={{ span: 3 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
