import React, { FunctionComponent, KeyboardEvent } from 'react';
import { Button, Col, Row, TableColumnType, Typography } from 'antd';

import './filter-table.scss'
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import FormInput from '../../form-input/FormInput';
import { globalStore } from '../../../stores/stores';


type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  tab: number,
  handleChangeInput: (e: any) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  dataColumns: TableColumnType<any>[]
  selectedRowKeys: React.Key[]
}

const FilterTableCourseConstruction: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  tab,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
}) => {

  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;
  const isPermissionsUpdatePortal = permissions?.includes("portal_management.add_new_portal.update");

  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col md={{ span: isPermissionsUpdatePortal ? 12 : 24 }} lg={{ span: isPermissionsUpdatePortal ? 12 : 24 }} xl={{ span: isPermissionsUpdatePortal ? 12 : 24 }} xs={{ span: isPermissionsUpdatePortal ? 12 : 24 }}>
            <div className="heading-search">
              <FormInput placeholder="Tìm cổng thông tin" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          {
            isPermissionsUpdatePortal ?
              <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
                <div className="heading-resigter">
                  <Button onClick={handleOpen} className="btn btn-primary" disabled={tab === 2}>
                    <PlusOutlined />
                    <Typography.Text>Thêm cổng thông tin</Typography.Text>
                  </Button>
                </div>
              </Col> : null
          }
        </Row>
      </div >
    </>
  );
};

export default FilterTableCourseConstruction;
