import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Select, Typography } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import "./FormFilter.scss";
import { get } from "lodash";
import { globalStore } from "../../../../stores/stores";

type FormFilterProps = {
  handleCloseFilter: () => void;
  tab: string;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  dataOptions?: any;
  filterData: any;
  isMoreFilters?: boolean;
  hasSharer?: boolean;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  onFinish,
  tab,
  form,
  showFilter,
  dataOptions,
  clearFilter,
  setFilterData,
  isMoreFilters = true,
  hasSharer = false
}) => {
  const { RangePicker } = DatePicker;

  const handleFinish = (values: any) => {
    const { startDate } = values;
    const dateRange = startDate ? [startDate[0], startDate[1]] : [];

    setFilterData(dateRange, "createDate");

    if (onFinish) {
      onFinish();
    }
  };
  const handleDateChange = (dates: any) => {
    const dateRange = dates ? dates.map((date: any) => date) : [];
    setFilterData(dateRange, "createDate");
  };

  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={handleFinish}>
          <Row
            gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}
            className="pt-2 learning-material-filter"
          >
            {isMoreFilters ? (
              <>
                <Col>
                  <Typography.Title level={5}>Loại chia sẻ</Typography.Title>

                  <Form.Item name="shareType">
                    <Select
                      placeholder="Loại chia sẻ"
                      onChange={(option) => setFilterData(option, "shareType")}
                      options={get(dataOptions, 'shareType', '')}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Typography.Title level={5}>
                    Trạng thái chia sẻ
                  </Typography.Title>

                  <Form.Item name="shareStatus">
                    <Select
                      placeholder="Trạng thái chia sẻ"
                      onChange={(option) => setFilterData(option, "shareStatus")}
                      options={get(dataOptions, 'shareStatus', '')}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}

            {
              tab === "2" || hasSharer ?
              <>
                <Col>
                  <Typography.Title level={5}>Người chia sẻ</Typography.Title>
                  <Form.Item name="shareUser">
                    <Select
                      placeholder="Người chia sẻ"
                      onChange={(option) => setFilterData(option, "shareUser")}
                      options={get(dataOptions, 'shareUser', '')}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Typography.Title level={5}>Trường chia sẻ</Typography.Title>
                  <Form.Item name="shareUniversity">
                    <Select
                      placeholder="Trường chia sẻ"
                      onChange={(option) => setFilterData(option, "shareUniversity")}
                      options={get(dataOptions, 'shareUniversity', '')}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
              </>
              :
              !permissions?.includes("is_qtcs") ?
              <Col>
                <Typography.Title level={5}>Trường chia sẻ</Typography.Title>
                <Form.Item name="shareUniversity">
                  <Select
                    placeholder="Trường chia sẻ"
                    onChange={(option) => setFilterData(option, "shareUniversity")}
                    options={get(dataOptions, 'shareUniversity', '')}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              :
              null
            }
            <Col>
              <Typography.Title level={5}>Ngày chia sẻ</Typography.Title>
              <Form.Item name="startDate">
                <RangePicker
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  locale={locale}
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                  onChange={handleDateChange}
                />
              </Form.Item>
            </Col>
            <Col style={{ display: "flex", alignItems: "flex-end" }}>
              <div style={{ display: "flex", gap: "12px" }}>
                <Button
                  className="btn btn-primary search-btn"
                  htmlType="submit"
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
