import React, {FunctionComponent, useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Row, Typography} from "antd";
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import {get} from "lodash";
import {useForm} from "antd/es/form/Form";
import {FormInstance} from "antd/lib";
import "./filter-group.scss";
import FormItemDropdown from '../../../../../components/form-dropdown/FormItemDropdown';
import { getAllMoocAddressDistrictByFilter, getAllMoocAddressProvinceByFilter } from '../../../../../service/category-management/administrative-unit-management';

type FormFilterProps = {
  showFilter?: boolean
  clearFilter: (form: FormInstance) => void,
  onFinish?: any,
  dataOptions: any,
  tab: string,
  filterData: any,
  setFilterData: (data: any) => any,
  setDataOptions: (data: any) => any,
  setDataDistrictFilter: (data: any) => any,
}

const FormFilter: FunctionComponent<FormFilterProps> = ({ 
  onFinish,
  showFilter,
  clearFilter,
  dataOptions,
  tab,
  filterData,
  setFilterData,
  setDataOptions,
  setDataDistrictFilter,
}) => {
  const [form] = useForm();
  const [dataDistrictByProvinceCode, setDataDistrictByProvinceCode] = useState();
  const currentProvinceCode = Form.useWatch("province", form);
  const [isTab3FirstTime, setIsTab3FirstTime] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // handle change province
  const getDataDistrictByProvinceCode = async (provinceCode: string) => {
    setIsLoading(true);
    await getAllMoocAddressDistrictByFilter({
      sort: ["name, asc"],
      provinceCode: provinceCode,
    }).then((res) => {
      if(res.status === 200) {
        const listDataDistrict = res.data?.data?.content?.map((item: any) => {
          return {
            code: item.code,
            name: item.name,
            fullName: item.fullName,
          }
        });
        form.setFieldValue('district', listDataDistrict?.[0]?.code);
        setDataDistrictByProvinceCode(listDataDistrict);
        setDataDistrictFilter(listDataDistrict);
  
        if(!isTab3FirstTime) {
          setFilterData({
            ...filterData,
            districtCode: listDataDistrict?.[0]?.code,
          })
          setIsTab3FirstTime(true);
          setDataOptions({
            ...dataOptions,
            dataDistrict: listDataDistrict,
          })
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 333);
      }
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    }).finally(() => {setIsLoading(false);})
  }

  // useEffect
  useEffect(() => {
    switch (tab) {
      case "1":
        setIsTab3FirstTime(false);
        break;
      case "2":
        setIsTab3FirstTime(false);
        form.setFieldValue('province', get(dataOptions, "dataProvince")?.[0]?.code);
        break;
      case "3":
        form.setFieldValue('province', get(dataOptions, "dataProvince")?.[0]?.code);
        getDataDistrictByProvinceCode(get(dataOptions, "dataProvince")?.[0]?.code);
        break;
      default:
        break;
    }
  }, [tab]);

  useEffect(() => {
    if(currentProvinceCode && tab === "3" && isTab3FirstTime) {
      getDataDistrictByProvinceCode(currentProvinceCode);
    }
  }, [currentProvinceCode]);

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form name="filterForm" onFinish={onFinish} form={form}>
          <Row gutter={[{xs: 8, xl: 24, md: 16}, 12]}>
            {tab !== "1" ?
              <Col xl={{span: 6}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
                <Typography.Title level={5}>Tỉnh/TP</Typography.Title>
                <Form.Item name="province">
                  <FormItemDropdown placeholder="Chọn Tỉnh/TP" options={get(dataOptions, "dataProvince")}/>
                </Form.Item>
              </Col>
              : null
            }
            {tab === "3" ?
              <Col xl={{span: 6}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
                <Typography.Title level={5}>Quận/Huyện</Typography.Title>
                <Form.Item name="district">
                  <FormItemDropdown placeholder="Chọn Quận/Huyện" disabled={isLoading} options={dataDistrictByProvinceCode}/>
                </Form.Item>
              </Col>
              : null
            }
            <Col xl={{span: 6}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Thời gian</Typography.Title>
              <Form.Item name="time">
                <DatePicker.RangePicker allowEmpty={[true, true]} placeholder={["Bắt đầu", "Kết thúc"]} format='DD/MM/YYYY' />
              </Form.Item>
            </Col>
            <Col xl={{span: 3}} md={{span: 9}} sm={{span: 9}} xs={{span: 24}} className="flex align-end">
              <div className="group-btn--filter">
                <Button className="btn btn-primary filter-btn" htmlType="submit">
                  <FilterOutlined/>
                  <Typography.Text> Lọc </Typography.Text>
                </Button>
                <Button className="btn btn-outlined clear-filter-btn" onClick={() => clearFilter(form)}>
                  <DeleteOutlined/>
                  <Typography.Text> Xóa bộ lọc </Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
