import { Button, Checkbox, CheckboxProps, Col, ColorPicker, ColorPickerProps, Divider, Drawer, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, TableColumnsType, TableProps, Typography, Upload, UploadProps, message, theme } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import { useLocation, useNavigate } from 'react-router-dom'
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons'
import FormInput from '../../form-input/FormInput'
import { getAccountUsers, getAllEnterpriseCustomer } from '../../../service/list-account-users/listAccountUsersApi'
import { postCourseSearch } from '../../../service/course-construct'
import { getTopicFilter } from '../../../service/library'
import { getQuizCourseList, postAddNewTopicQuestion } from '../../../service/library/question-list'
import TableData from '../../table-data/TableData'
var indexDB = require("idbcache").default;

type Props = {
  open: boolean
  handleClose: () => void
  dataTable: any
}

export default function AddNewTopicDrawer(props: Props) {
  const { open, handleClose, dataTable } = props
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [listSchool, setListSchool] = useState<any>([]);
  const [openCorfirmModal, setOpenCorfirmModal] = useState<boolean>(false);
  const [clearRowKey, setClearRowKey] = useState<boolean>(false)

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [color, setColor] = React.useState<string>('#1677ff');
  // const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [dataTags, setDataTags] = useState([]);
  const [courseValue, setCourseValue] = useState<any>("");
  const [dataUnit, setDataUnit] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const [page, setPage] = useState<number>(1)
  const [size, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [dataOptions, setDataOptions] = useState({
    dataUsers: [],
    dataCourse: [],
    dataTopic: [],
  });

  const validation = {
    codeTopicID: {
      required: true,
      message: "Vui lòng nhập mã đề"
    },
    topicID: {
      required: true,
      message: "Vui lòng chọn chủ đề"
    },
    titleId: {
      required: true,
      message: "Vui lòng nhập tiêu đề"
    },
    authorId: {
      required: true,
      message: "Vui lòng chọn tác giả"
    }
  }

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleChange: CheckboxProps['onChange'] = (e) => {
    setChecked(e.target.checked)
  };

  const resetData = () => {
    handleClose()
    form.resetFields()
    setImageUrl("")
  }

  const saveData = async (values: any) => {
    const listIdChoose = dataTable?.map((item: any) => item.id)
    const data = {
      code: values.codeTopicID,
      authors: values.authorId,
      title: values.titleId,
      topics: values.topicID,
      description: values.description,
      tags: values.labelId,
      courseUnitId: selectedRowKeys?.[0]?.id ?? null,
      quizIds: listIdChoose
    }
    await postAddNewTopicQuestion(data).then((res) => {
      if (res.status === 200) {
        message.success("Thêm mới đề thành công")
        form.resetFields()
        handleClose()
        setCourseValue("")
      }
    })
  }

  const getDataInfo = async () => {
    const dataCourse = {
      keyword: "",
      pageNumber: 1,
      pageSize: 10000,
    }
    const dataUser = {
      page: 1,
      size: 10000
    }
    const response = await Promise.all([getAllEnterpriseCustomer(dataUser), postCourseSearch(dataCourse), getTopicFilter({ code: "topic" })])
    const arrUser = response?.[0]?.data?.data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name
      }
    })
    const arrCourse = response?.[1]?.data?.data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name
      }
    })
    const arrTopic = response?.[2]?.data?.data?.moocPortfolioValueData?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name
      }
    })
    setDataOptions({
      ...dataOptions,
      dataUsers: arrUser,
      dataCourse: arrCourse,
      dataTopic: arrTopic,
    })
  };

  const getQuizzFromCourse = async () => {
    const data = {
      page: page,
      size: size,
      courseId: courseValue
    }
    await getQuizCourseList(data).then((res) => {
      if (res.status === 200) {
        setDataUnit(res.data.data.data)
      }
    })
  }

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Học liệu',
      dataIndex: "name",
      key: "name",
      width: 5,
    },
  ]

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
    }));
  }, [dataUnit]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {

  };


  useEffect(() => {
    if (open) {
      getDataInfo()
    }
  }, [open])

  useEffect(() => {
    if (courseValue) {
      setClearRowKey(true)
      getQuizzFromCourse()
    }
  }, [courseValue, page, size])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === 'form-add-new-topic') {
        Modal.confirm({
          title: `Xác nhận lưu bài đăng`,
          content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu bài đăng.`,
          onOk: () => {
            saveData(values)
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>

      <Drawer
        title={`Thêm mới đề`}
        placement={"right"}
        className='add-new-topic-drawer'
        width={600}
        onClose={() => {
          handleClose()
          form.resetFields()
        }}
        open={open}
        extra={
          <Space>
            <Button onClick={() => {
              handleClose()
              form.resetFields()
            }} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>
                Hủy
              </Typography.Text>
            </Button>
            <Button className="btn btn-primary"
              onClick={() => form.submit()
              }>
              <SaveOutlined />
              <Typography.Text>
                Lưu
              </Typography.Text>
            </Button>
          </Space>
        }
      >
        <>
          <Form layout={"vertical"} form={form} name="form-add-new-topic">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="codeTopicID" label="Mã đề" rules={[validation.codeTopicID]}>
                  <FormInput placeholder='Nhập đề' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="authorId" label="Tác giả" rules={[validation.authorId]}>
                  <FormItemDropdown placeholder="Chọn tác giả" modeDropdown="multiple"
                    options={dataOptions.dataUsers} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="titleId" label="Tiêu đề" rules={[validation.titleId]}>
                  <FormInput placeholder="Nhập tiêu đề" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="topicID" label="Chủ đề" rules={[validation.topicID]}>
                  <FormItemDropdown placeholder="Chọn chủ đề" modeDropdown="multiple"
                    options={dataOptions.dataTopic} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="description" label="Mô tả">
                  <FormInput placeholder='Nhập mô tả' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="labelId" label="Nhãn">
                  <FormItemDropdown
                    options={dataTags}
                    modeDropdown={'tags'}
                    allowClear={true}
                    placeholder="Thêm nhãn"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="checkbox">
                  <Checkbox onChange={handleChange}>Thêm đề từ khoá học</Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="courseId">
                  <FormItemDropdown placeholder="Chọn khoá học" disabled={!checked} onChange={setCourseValue}
                    options={dataOptions.dataCourse} />
                </Form.Item>
              </Col>
              {
                courseValue && checked ?
                  <TableData
                    dataColumns={newColumns}
                    dataTable={dataUnit}
                    selectOneRow={true}
                    setPageNumber={setPage}
                    setPageSize={setPageSize}
                    pageNumber={page}
                    pageSize={size}
                    loadingTable={loading}
                    clearRowkey={clearRowKey}
                    totalRecords={totalRecords}
                    hideSelection={true}
                    setSelectedRowKeysProps={setSelectedRowKeys}
                    pagination={true}
                    handleTableChangeProps={handleTableChange} />
                  : null
              }
            </Row>
          </Form>
        </>
      </Drawer>
    </Form.Provider >
  )
}
