import { Form, FormInstance, Layout, message, Space, TableColumnsType, TableProps, Tabs, TabsProps } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import { CheckCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import FilterTable from "../../../../components/course-library/ministry-content-moderation/list-question/filter-table/FilterTable";
import FormFilter from "../../../../components/course-library/ministry-content-moderation/list-question/filter-group/FormFilter";
import { isEmpty } from "lodash";
import { formatOriginTimev3 } from "../../../../utils/converDate";
import { getAllMinisterialApprove, quickBrowseApprove } from "../../../../service/library/ministry-content-moderation";
import { getAllProductionUnit, quizQuestionBankApprovedHistory } from "../../../../service/library";
import DetailQuestion from "../../../../components/course-library/personal-exam-repository/list-question/detail-question";
import { routesConfig } from "../../../../config/routes";
import { globalStore } from "../../../../stores/stores";
import { Permission } from "../../../../layout/sidebar/permission.enum";

type Props = {
  title?: string;
};

const MinistryQuestionModeration = (props: Props) => {
  const { title } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tab, setTab] = useState("1");
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [listDataQuestion, setListDataQuestion] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [typeQuestion, setTypeQuestion] = useState<boolean>(false);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [isOpenedDetail, setIsOpenedDetail] = useState<boolean>(false)
  const [idDetailData, setIdDetailData] = useState<number | undefined>();
  const [idUnitDetailData, setIdUnitDetailData] = useState<number | undefined>();
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const [dataOptions, setDataOptions] = useState<any>({
    dataQuestionType: [
      {
        id: 1,
        name: "Nhiều lựa chọn văn bản"
      },
      {
        id: 2,
        name: "Nhiều lựa chọn hình ảnh"
      },
      {
        id: 3,
        name: "Nhiều lựa chọn video"
      },
      {
        id: 4,
        name: "Nhiều lựa chọn đúng sai"
      },
      {
        id: 5,
        name: "Danh sách"
      },
      {
        id: 6,
        name: "Nhiều đáp án văn bản"
      },
      {
        id: 7,
        name: "Nhiều đáp án hình ảnh"
      },
      {
        id: 8,
        name: "Nhiều đáp án video"
      },
      {
        id: 9,
        name: "Câu trả lời ngắn"
      },
      {
        id: 10,
        name: "Điền vào chỗ trống"
      },
      {
        id: 11,
        name: "Kết nối"
      },
      {
        id: 12,
        name: "Sắp xếp"
      },
      {
        id: 13,
        name: "Ghi âm"
      },
      {
        id: 14,
        name: "Ghi hình"
      },
      {
        id: 15,
        name: "File đính kèm"
      },
      {
        id: 16,
        name: "Trình soạn thảo"
      },
    ],
    dataLevel: [
      {
        id: 3,
        name: "Cấp Bộ",
      },
      {
        id: 2,
        name: "Cấp Trường",
      },
      {
        id: 1,
        name: "Cấp Giảng viên",
      },
    ],
    dataType: [
      {
        id: "Duyệt mới",
        name: "Duyệt mới",
      },
      {
        id: "Duyệt chỉnh sửa",
        name: "Duyệt chỉnh sửa",
      }
    ],
    dataUnit: [],
    dataApproved: [
      {
        id: "1",
        name: "Duyệt"
      },
      {
        id: "2",
        name: "Không duyệt"
      }
    ],
  });
  const [filterData, setFilterData] = useState<any>({
    questionType: null,
    typeApproved: null,
    productionUnitId: null,
    approvedSendDateFrom: null,
    approvedSendDateTo: null,
    approvedDateFrom: null,
    approvedDateTo: null,
    typeProduction: null,
    userIds: null,
    status: false,
    historyStatus: null,
  });

  const listBreadcrumb = [
    {
      title: "Thư viện / Kiểm duyệt nội dung cấp Bộ",
    },
    {
      title: <span className="style-title">{!typeQuestion ? 'Danh sách câu hỏi' : 'Lịch sử kiểm duyệt'}</span> ,
    },
  ];

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      ...filterData,
      questionType: null,
      typeApproved: null,
      productionUnitId: null,
      approvedSendDateFrom: null,
      approvedSendDateTo: null,
      approvedDateFrom: null,
      approvedDateTo: null,
      typeProduction: null,
      userIds: null,
      historyStatus: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      fetchData();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    fetchData();
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      questionType: values.questionType,
      typeApproved: values.type,
      typeProduction: values.level,
      productionUnitId: values.unit,
      historyStatus: values.approved ? values.approved === "1" : null,
      approvedSendDateFrom: values?.time && tab != "2" ? (values?.time[0]).add(1, "day").toISOString() : null,
      approvedSendDateTo: values?.time && tab != "2" ? (values?.time[1]).add(1, "day").toISOString() : null,
      approvedDateFrom: (values?.time && tab != "1") || (values?.time && typeQuestion) ? (values?.time[0]).add(1, "day").toISOString() : null,
      approvedDateTo: (values?.time && tab != "1") || (values?.time && typeQuestion) ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "courseName,asc"
        : sorter.order === "descend"
          ? "courseName,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
    fetchData();
  };

  const onChangeTab = (key: string) => {
    setTab(key);
    setFilterData({
      ...filterData,
      status: key !== "1"
    });
    setPageNumber(1);
    form.resetFields();
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã câu hỏi",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Câu hỏi",
      dataIndex: typeQuestion ? "name" : "question",
      key: typeQuestion ? "name" : "question",
      sorter: true,
      width: 30,
      render: (_: any, record: any) => {
        const cleanQuestion = record?.question?.replace(/<\/?[^>]+(>|$)/g, "");
        return <span>{cleanQuestion}</span>;
      },
    },
    
    {
      title: "Loại câu hỏi",
      dataIndex: typeQuestion ? "type" : "typeQuestion",
      key: typeQuestion ? "type" : "typeQuestion",
      width: 30,
      render: (_: any, record: any) => {
        const questionType = typeQuestion 
          ? dataOptions.dataQuestionType?.find((type: any) => type.id === record?.type) 
          : dataOptions.dataQuestionType?.find((type: any) => type.id === record?.typeQuestion);
        
        return (
          <>
            {questionType ? questionType.name : ""}
          </>
        );
      },
    },
    {
      title: "Phân cấp",
      dataIndex: "typeProduction",
      key: "typeProduction",
      width: 20,
      render(_: any, record) {
        return (
          <>
            {record?.typeProduction === 1 ? (
              <>Cấp giảng viên</>
            ) : record?.typeProduction === 2 ? (
              <>Cấp trường</>
            ) : (
              <>Cấp bộ</>
            )}
          </>
        );
      },
    },
    {
      title: "Đơn vị xuất bản",
      dataIndex: typeQuestion ? "productUnitName" : "productionUnitName",
      key: typeQuestion ? "productUnitName" : "productionUnitName",
      width: 20,
    },
    {
      title: "Phân loại",
      dataIndex: typeQuestion ? "action" : "typeApproved",
      key: typeQuestion ? "action" : "typeApproved",
      width: 20,
    },
    ...(
      !typeQuestion ?
      [] : [
        {
          title: "Kết quả duyệt",
          dataIndex: "status",
          key: "status",
          width: 20,
          sorter:true,
          render(_: any, record: any) {
            return (
              <>
                {record?.status ? (
                  <>Duyệt</>
                ) : (
                  <>Không duyệt</>
                ) }
              </>
            );
          },
        },
      ]
    ),
    tab !== "1" || typeQuestion 
    ? 
    {
      title: "Người duyệt",
      dataIndex: typeQuestion ? "actionByName" : "approveByName",
      key: typeQuestion ? "actionByName" : "approveByName",
      width: 20,
    } : {width: 0},

    tab !== "1" || typeQuestion 
    ? 
    {
      title: "Ngày duyệt",
      dataIndex: !typeQuestion ? "approvedDate" : "createdDate",
      key: !typeQuestion ? "approvedDate" : "createdDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
        <>
        {!typeQuestion ? <>
          {!isEmpty(record?.approvedDate) ? 
        formatOriginTimev3(record?.approvedDate) : null}
        </> : <>  
          {!isEmpty(record?.createdDate) ? 
        formatOriginTimev3(record?.createdDate) : null}
        </>}
        
        </>);
      },
    } : {
      title: "Ngày gửi duyệt",
      dataIndex: "approvedSendDate",
      key: "approvedSendDate",
      width: 20,
      render: (_: any, record: any) => {
        return <>{!isEmpty(record?.approvedSendDate) ? formatOriginTimev3(record?.approvedSendDate) : null}</>;
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record?.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                        {
                          !typeQuestion ?
                          navigate(
                            `/${routesConfig.ministryContentModeration}/${routesConfig.previewExam}?id=${record.id}`,
                            {
                              state: {
                                backUrl: `/${routesConfig.courseLibrary}/${routesConfig.ministryContentModeration}/${routesConfig.listQuestionRepository}`,
                                mode: "5",
                                titleBreadCrumb: "Kiểm duyệt câu hỏi cấp Bộ",
                              },
                            }
                          ) : 
                          navigate(
                            `/${routesConfig.ministryContentModeration}/${routesConfig.previewExam}?id=${record.id}`,
                            {
                              state: {
                                backUrl: `/${routesConfig.courseLibrary}/${routesConfig.ministryContentModeration}/${routesConfig.listQuestionRepository}`,
                                mode: "7",
                                titleBreadCrumb: "Lịch sử kiểm duyệt",
                              },
                            }
                          );
                        }
                      }
                      >
                <EyeOutlined />
              </a>
              {
                tab === "1"  && !typeQuestion && permissions?.includes(Permission.QuizBankMinistryApprovedListUpdate)
                ?
                  <a
                    className="action-table"
                    onClick={() => {
                      navigate(
                        `/${routesConfig.ministryContentModeration}/${routesConfig.previewExam}?id=${record.id}`,
                        {
                          state: {
                            backUrl: `/${routesConfig.courseLibrary}/${routesConfig.ministryContentModeration}/${routesConfig.listQuestionRepository}`,
                            mode: "6",
                            titleBreadCrumb: "Kiểm duyệt câu hỏi cấp Bộ",
                          },
                        }
                      );
                    }
                  }
                  >
                    <CheckCircleOutlined />
                  </a>
                :
                  <></>
              }
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Chờ duyệt
          <span
            className={`number-record ${
              tab === "1" ? "number-record-active" : "number-record-disabled"
            }`}
          >
            {tab === "1" ? totalRecords : countAwaitingReview}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            pagination={true}
            pageSize={pageSize}
            hideSelection={true}
            dataTable={listDataQuestion}
            pageNumber={pageNumber}
            dataColumns={dataColumns}
            loadingTable={loading}
            setPageSize={setPageSize}
            scroll={{ x: 1500, y: 800 }}
            setPageNumber={setPageNumber}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Đã duyệt
          <span
            className={`number-record ${
              tab === "2" ? "number-record-active" : "number-record-disabled"
            }`}
          >
            {tab === "2" ?totalRecords : countReviewed}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listDataQuestion}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  // Quick browse
  const fetchQuickBrowse = async (values: any) => {
    const payload = selectedRowKeys.map((i: any) => {
      return {
        id: i.id,
        status: values.status,
        description: values.description,
        publishDate: null,
        scheduleType: null,
      };
    });
    
    const res = await quickBrowseApprove(payload);
    if(res.status === 200) {
      message.success("Duyệt câu hỏi thành công!");
      fetchData();
    }
  }

  const onFinish = (values : any) => {
    fetchQuickBrowse(values);
  };

  // fetch data 
  const fetchData = async () => {
    setLoading(true);
    try{
      if(typeQuestion){
        const historyPayload = {
          page: pageNumber,
          size: pageSize,
          keyword: searchValue,
          status: filterData.historyStatus,
          typeProductions: filterData.typeProduction ? filterData.typeProduction : [],
          authorIds: null,
          uuids: filterData.productionUnitId ? filterData.productionUnitId : [],
          isMinistry: true,
          action:filterData.typeApproved,
          createdDateFrom: filterData.approvedDateFrom,
          createdDateTo: filterData.approvedDateTo,
          sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder)
          ? ["createdDate, desc"]
          : [`${fieldSortOrder},${statusOrder}`],
        };
        const historyRes = await quizQuestionBankApprovedHistory(historyPayload);
        if(historyRes.status === 200) {
          setTotalRecords(historyRes.data.data.total);
          setListDataQuestion(historyRes.data.data.data);
          setTimeout(() => {
            setLoading(false);
          }, 333);
        }
      }
      else{
        const payload = {
          page: pageNumber,
          size: pageSize,
          keyword: searchValue,
          topicIds: filterData.topicIds,
          typeApproved: filterData.typeApproved,
          typeProduction: filterData.typeProduction,
          productionUnitId: filterData.productionUnitId,
          approvedSendDateFrom: filterData.approvedSendDateFrom,
          approvedSendDateTo: filterData.approvedSendDateTo,
          approvedDateFrom: tab !== "1" ? filterData.approvedDateFrom : null,
          approvedDateTo: tab !== "1" ? filterData.approvedDateTo : null,
          typeQuestion: filterData.questionType,
          type: "quiz",
          status: filterData.status,
          userIds: filterData.userIds ? filterData.userIds : [],
          sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['approvedSendDate, desc'] : [`${fieldSortOrder},${statusOrder}`],
        }
        await getAllMinisterialApprove(payload).then((res) => {
          if (res.status === 200) {
            setListDataQuestion(res.data.data.data);
            setTotalRecords(res.data.data.total);
            setCountReviewed(res.data.data.approvedCount);
            setCountAwaitingReview(res.data.data.approvedWaitCount);
  
            setTimeout(() => {
              setLoading(false);
            }, 333);
          }
        })
      }
    }catch (err) {
      setLoading(false);
      console.error(err);
    }
  }
  

  // useEffect
  useEffect(() => {
    if (!permissions) return;
    if(permissions?.includes(Permission.QuizBankMinistryApprovedListView)) {
      fetchData();
    } else {
      navigate("/error/403");
    }
  }, [filterData, pageSize, pageNumber, statusOrder, tab, typeQuestion, permissions])

  useEffect(() => {
    Promise.all([getAllProductionUnit({name: null})]).then((res) => {
      const listUnit = res[0].data.data.map((unit: any) => {
        return ({
          id: unit.uuid,
          name: unit.name,
        })
      });
      
      setDataOptions({
        ...dataOptions,
        dataUnit: listUnit,
      })
    })
  }, [])

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {}} >
        <div className={`page-header-group--layout ${ showFilter ? "open-filter" : "" }`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={!typeQuestion ? title : 'Lịch sử kiểm duyệt'} listBreadcrumb={listBreadcrumb} breadcrumb={true} />
            </div>
            <FilterTable 
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              onFinish={onFinish}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
            typeQuestion={typeQuestion}
          />
        </div>

        <div className="tabs-heading">
          {
            typeQuestion
            ?
              <TableData
                pagination={true}
                pageSize={pageSize}
                dataTable={listDataQuestion}
                pageNumber={pageNumber}
                dataColumns={dataColumns}
                loadingTable={loading}
                setPageSize={setPageSize}
                scroll={{ x: 1500, y: 800 }}
                setPageNumber={setPageNumber}
                totalRecords={totalRecords}
                handleTableChangeProps={handleTableChange}
              />
            :
            <Tabs items={itemsTab} activeKey={tab} onChange={onChangeTab} />
          }
            <DetailQuestion type={1} open={isOpenedDetail} onClose={() => setIsOpenedDetail(false)} idDetailData={idDetailData} dataOptions={dataOptions} idUnitDetailData={idUnitDetailData}/>
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default MinistryQuestionModeration;
