import { Layout } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import { getMoocPositionById } from "../../../../service/category-management/position-management";
import PositionDetail from "../../../../components/category-management/management-detail/position-info/position-detail/PositionDetail";
import "./position-management-detail.scss";

const { Content } = Layout;
const imgPath = "/images/";

const PositionManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const [positionDetail, setPositionDetail] = useState<any>();
    const listBreadcrumb = [
        {
            title: "Quản lý danh mục",
        },
        {
            title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.otherCategories}/${routesConfig.positionManagement}`}>
                <span>Chức vụ</span>
            </SafeLink>,
        },
        {
            title: <span className="style-title">{location.state.name}</span> ,
        },
    ];

    // Get detail Position
    const getPositionUpdate = async (positionId: number) => {
        setLoadingDetail(true);
        try {
            await getMoocPositionById(positionId).then((res) => {
                if (res.status === 200) {
                    const { data } = res.data;
                    setPositionDetail(data);
                    setTimeout(() => {
                        setLoadingDetail(false);
                    }, 333);
                }
            })
        } catch (err) {
            setLoadingDetail(false);
            console.error(err);
        }
    };

    useEffect(() => {
        getPositionUpdate(location.state.record.id);
    }, [location.state.record])

    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                />
                <div className="tabs-heading">
                    <PositionDetail
                        positionDetail={positionDetail}
                        loadingDetail={loadingDetail}
                    />
                </div>
            </Content>
        </>
    )
}

export default PositionManagementDetail;