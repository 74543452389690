import { Button, Dropdown, Form, Layout, MenuProps, Modal, Space, TableColumnsType, TableProps, Tabs, TabsProps, Typography, message } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, MoreOutlined, SaveOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { routesConfig } from "../../../config/routes";
import { DataType, validateFieldCreate } from "./constants/data";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import DrawerCustom from "../../../components/drawer/Drawer";
import { formatDateV2 } from "../../../utils/converDate";
import { isEmpty } from "lodash";
import FormCreateUniversityInfo from "./components/form-create-university-info/FormCreateUniversityInfo";
import { addMoocUniversityInfo, deleteMoocUniversityInfo, getAllUniversityInfoByFilter, getMoocUniversityInfoById, updateMoocUniversityInfo } from "../../../service/category-management/university-info-management";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import "./university-info-management.scss"

type UniversityInfoManagementProps = {
  title?: string;
};

const UniversityInfoManagement = ({ title }: UniversityInfoManagementProps) => {
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [tab, setTab] = useState("1");
  const navigate = useNavigate();
  const [editId, setEditId] = useState<number>(0);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [textRendered, setTextRendered] = useState("");
  const [dataUniversityInfo, setDataUniversityInfo] = useState<any>([]);

  const [filterData, setFilterData] = useState<any>({
    type: "type_school",
    createdDateFrom: null,
    createdDateTo: null,
  });

  const UniversityInfosOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      className:"title-sequence",
      render: (_: any, record) => {
        return (
          <div className="table-university-info cursor-pointer">
            <div>
              <a
                className="university-info-name"
                onClick={() =>
                  navigate(
                    `${routesConfig.universityInfoManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        tabType: tab,
                        record,
                        name: record.name,
                        textRendered: textRendered,
                      },
                    }
                  )
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
      className:"title-sequence",
      width: 20,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any, index: number) => {
        return record.createdDate ? formatDateV2(record.createdDate) : null;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.universityInfoManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        tabType: tab,
                        record,
                        name: record.name,
                        textRendered: textRendered,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: UniversityInfosOptions,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      getUniversityInfoUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Xoá ${textRendered}`,
                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                        onOk: () => deleteUniversityInfo(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị danh mục",
    },
  ];

  // Get data UniversityInfo
  const getAllUniversityInfo = (data?: any) => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      createdDateFrom: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdDateTo: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`],
      type: filterData.type,
    };
    getAllUniversityInfoByFilter(dataReq)
      .then((res) => {
        setDataUniversityInfo(res.data?.data?.content);
        setTotalRecords(res.data?.data?.totalElements);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          navigate("/error/403");
        } else {
          console.error(err);
        }
        setLoading(false);
      }).finally(() => {setLoading(false);});
  };
  
  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      ...filterData,
      createdDateFrom: null,
      createdDateTo: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      getAllUniversityInfo();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    getAllUniversityInfo();
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      userType: values?.userType,
      createdDateFrom: values?.time ? (values?.time[0]).add(1, "day").toISOString() : null,
      createdDateTo: values?.time ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };


  // Get UniversityInfo to edit
  const getUniversityInfoUpdate = async (id: number) => {
    setLoadingForm(true);
    await getMoocUniversityInfoById(id, filterData.type).then((res) => {
      const { data } = res.data;
      setDataDetail(data);
      setTimeout(() => {
        setLoadingForm(false);
      }, 333);
    })
      .catch((err) => {
        setLoadingForm(false);
        console.error(err);
      });
  };

  // Modal create | edit
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    form.resetFields();
  };

  const handleCreateAndEdit = () => {
    Modal.confirm({
      title: !isOpenedEdit ? `Thêm mới ${textRendered}` : `Chỉnh sửa ${textRendered}` ,
      content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
      onOk: () => form.submit(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  const onFinishCreate = async (values: any) => {
    if (Object.keys(values).length > 0) {
      if (isOpenedEdit) {
        const dataPayload = {
          id: editId,
          name: values.name.trim(),
          code: values.code.trim(),
          type: filterData.type,
        };
        setIsSaving(true);
        try {
          await updateMoocUniversityInfo(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Cập nhật thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllUniversityInfo();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      } else {
        const dataPayload = {
          id: null,
          name: values.name.trim(),
          code: values.code.trim(),
          type: filterData.type,
        };
        setIsSaving(true);
        try {
          await addMoocUniversityInfo(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllUniversityInfo();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  // Delete UniversityInfo
  const deleteUniversityInfo = async (universityInfo: any) => {
    try {
      await deleteMoocUniversityInfo(universityInfo.id, filterData.type).then((res) => {
        if (res.status === 200) {
          message.success("Xóa thành công!");
          getAllUniversityInfo();
        }
      });
    } catch (err) { }
  };

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // tab items
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: <div>Loại hình trường</div>,
      children: (
        <div className="type-school-tab">
          <TableData
            pagination={true}
            pageSize={pageSize}
            hideSelection={false}
            dataTable={dataUniversityInfo}
            pageNumber={pageNumber}
            dataColumns={dataColumns}
            loadingTable={loading}
            setPageSize={setPageSize}
            scroll={{ x: 1500, y: 800 }}
            setPageNumber={setPageNumber}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <div>Loại trường</div>,
      children: (
        <div className="type-tab">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={dataUniversityInfo}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
        key: "3",
        label: <div>Cấp độ đào tạo</div>,
        children: (
          <div className="training-level-tab">
            <TableData
              dataColumns={dataColumns}
              loadingTable={loading}
              dataTable={dataUniversityInfo}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              totalRecords={totalRecords}
              scroll={{ x: 1500, y: 800 }}
              pagination={true}
              hideSelection={false}
              handleTableChangeProps={handleTableChange}
            />
          </div>
        ),
      },
  ];

  // Handle change tab
  const onChangeTab = (key: string) => {
    setTab(key);
    setFilterData({
      ...filterData,
      type: key === "1" ? "type_school" : key === "2" ? "type" : "training_level",
    });
    setPageNumber(1);
    form.resetFields();
  };

  // useEffect
  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        name: dataDetail.name,
        code: dataDetail.code,
        userType: dataDetail.type
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    getAllUniversityInfo();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  useEffect(() => {
    // setLoading(true);
    Promise.all([])
      .then((res) => {
        // setDataOptions({
        //   ...dataOptions,
        //   dataUniversityInfo: dataUniversityInfo,
        //   dataDepartment: dataIndustryGroup,
        //   dataSpecialized: dataIndustry,
        //   dataRegency: dataPositions,
        //   dataUniversity: dataEnterpriseUniversity,
        //   dataProvinces: dataProvinces,
        // })

        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
  }, []);

  useEffect(() => {
    switch (tab) {
      case "1":
        setTextRendered("loại hình trường");
        break;
      case "2":
        setTextRendered("loại trường");
        break;
      case "3":
        setTextRendered("cấp độ đào tạo");
      break;
      default:
        break;
    }
  }, [tab]);
  
  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createUniversityInfo") {
            const { createUniversityInfo } = forms;
            const data = createUniversityInfo.getFieldsValue(validateFieldCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
                <PageHeader 
                  title={
                    `Danh sách ${tab === "1" 
                    ? "loại hình trường" : tab === "2" 
                    ? "loại trường" : "cấp độ đào tạo"}`
                  } 
                  listBreadcrumb={listBreadcrumb} 
                />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              isRendering={isRendering}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>

        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChangeTab} />
        </div>

        <DrawerCustom 
          title={`${isOpenedEdit ? `Chỉnh sửa ${textRendered}` : `Thêm mới ${textRendered}`}`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit" onClick={handleCreateAndEdit} disabled={isSaving}>
                {isSaving ? <LoadingOutlined /> : <SaveOutlined />}
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateUniversityInfo form={form} loadingForm={loadingForm} textRendered={textRendered}/>
        </DrawerCustom>
      </Form.Provider>
    </Layout>
    
  );
};

export default UniversityInfoManagement;



