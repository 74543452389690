import React from 'react';
import ReactPlayer from 'react-player';
import "./View.scss"
type Props = {
  url: string;
};

const VideoPlayer = (props: Props) => {
  const { url } = props;

  return (
    <div className="view-wrapper">
      <ReactPlayer
        className="view-content"
        url={url}
        controls
        width="100%"
        height="100%"
        playing={false}
      />
    </div>
  );
};

export default VideoPlayer;
