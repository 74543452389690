import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import "./index.scss";
import grapesjs, { Block, Editor } from "grapesjs";
import GjsEditor, { useEditor, WithEditor } from "@grapesjs/react";
import { isEmpty } from "lodash";
import { uiDefault } from "../../page-builder/grapesjs-ui";
import { imagesAssests, styleManagerConfig } from "./grapesjs-config";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsTailWind from "grapesjs-tailwind";
import gjsNavbar from "grapesjs-navbar";
import gjsPresetWeb from "grapesjs-preset-webpage";
import gjsStyleBg from "grapesjs-style-bg";
import gjsStyleFilter from "grapesjs-style-filter";
import gjsStyleGradient from "grapesjs-style-gradient";
import gjsTabs from "grapesjs-tabs";
import gjsComponentCountdown from "grapesjs-component-countdown";
import grapesjsTooltip from "grapesjs-tooltip";
import grapesjsCustomCode from "grapesjs-custom-code";
import grapesjsTyped from "grapesjs-typed";
import { useAuthStore } from "../../../stores/stores";
import { HelpService } from "../../../service/helper.service";
import { LoadingOutlined, RetweetOutlined } from "@ant-design/icons";
import {
  generalContentIntroduction,
  generalCoursePopular,
  generalCoursesToStart,
  generalFooterIntroduction,
  generalHeaderIntroduction,
  nameAffiliatedBlocks,
  nameBasicBlock,
  nameCategoryBlocks,
  nameCategoryTemplates,
  nameConditionBlocks,
  nameContactBlock,
  nameCountBlocks,
  nameHeaderBlocks,
  nameLecturersBlock,
  nameLinkedCourseBlock,
  nameRegisterBlocks,
  nameRoadMapBlocks,
} from "./grapesjs-ui";
import { confirmMessage, isValidJSON, stringCssObj } from "./grapesjs-service";
import { routesConfig } from "../../../config/routes";
import {
  getTemplateUniversity,
  postSaveComponentUniversity,
  postTemplateByUserUniversity,
  putUpdateTemplateUniversity,
} from "../../../service/portal-infomation/portalInformationApi";
import { BASE_URL_V2 } from "../../../config/api/configApiv2";
import { pushBlockItem } from "../../page-builder/grapesjs-service";
import CommonAdvanceSettingsPlugin from "../../page-builder/plugins/common-advance-settings";
import {
  getCoursesFeatured,
  getCoursesToStart,
} from "../../../service/courses";
import { CourseInfo } from "../../../types/course";
import { formatCurrency } from "../../../utils/format";
import { formatCurrencyToVND } from "../../../utils/functionUltils";

var indexDB = require("idbcache").default;

type Props = {
  setTab: (value: string) => void;
  tab: string;
  universityName?: string;
  logo?: string;
};

const GeneralIntroduction = (props: Props) => {
  // const { setTab, tab } = props;
  const universityName = props?.universityName;
  const logo = props?.logo;
  const MOOCs_SV_SITE = process.env.REACT_APP_MOOCs_SV_URL;
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const accessToken = useAuthStore((state) => state.accessToken);
  const [isOpenSync, setIsOpenSync] = useState<boolean>(false);
  const [infoSelect, setInfoSelect] = useState({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalSave, setOpenModalSave] = useState<boolean>(false);
  const [isSaveAsBlock, setIsSaveAsBlock] = useState<boolean>(false);
  const [nameBlock, setNameBlock] = useState("");
  const [iconVisible, setIconVisible] = useState(false);
  const [editorCustom, setEditorCustom] = useState<Editor>();
  const [blockUISave, setBlockUISave] = useState<string>("");
  const componentClassRef = useRef<any>(null);
  const [editorCus, setEditorCus] = useState<Editor>();

  const [coursesFeatured, setCoursesFeatured] = useState<CourseInfo[]>([]);
  const [coursesToStart, setCourseToStart] = useState<CourseInfo[]>([]);

  let countApiCall = 0;
  let allCourseBlocksUI: any[] = [];
  const totalApiCall = 1;

  const helpService = new HelpService();

  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);
  const pathname = url.pathname;
  const segments = pathname.split("/");
  const universityID = segments[segments.length - 1];

  useEffect(() => {
    const fetchCoursesFeatured = async () => {
      const res = await getCoursesFeatured(params?.id);
      setCoursesFeatured(res.data.data);
    };
    const fetchCoursesStart = async () => {
      setLoading(true);
      const res = await getCoursesToStart(params?.id);
      setLoading(false);
      setCourseToStart(res.data.data);
    };
    fetchCoursesStart();
    fetchCoursesFeatured();
  }, []);

  useEffect(() => {
    // getTemplateCourseBrowse()
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const syncData = () => {
    setIsOpenSync(true);
  };

  const saveDetailTemplatesByCoueseId = (type: string, ed?: Editor) => {
    indexDB.get("courseSavedTempUniversity-" + params?.id).then((val: any) => {
      if (val) {
        const detailDataTemplates: any = {
          status: type === "draft" ? 0 : 1,
          template: val,
          label: nameBlock,
          isTemplateSample: isSaveAsBlock,
        };
        if (isSaveAsBlock) {
          if (nameBlock !== "") {
            putUpdateTemplateUniversity(
              detailDataTemplates,
              universityID,
              props.tab
            ).then((res: { data: { data: string } }) => {
              helpService.successMessage(res.data.data);
              setNameBlock("");
              setIsSaveAsBlock(false);
              setOpenModalSave(false);
              if (isSaveAsBlock) {
                addMyBlocksToCategories(ed);
              }
            });
          } else {
            helpService.errorMessage("Chưa nhập tên mẫu");
          }
        } else {
          putUpdateTemplateUniversity(
            detailDataTemplates,
            universityID,
            props.tab
          ).then((res: { data: { data: string } }) => {
            helpService.successMessage("Lưu thành công");
            setNameBlock("");
            setIsSaveAsBlock(false);
            setOpenModalSave(false);
          });
        }
      }
    });
  };

  const renderTeacher = (teachers: string[]) => {
    return teachers?.length > 0
      ? teachers
          .map(
            (item: string, index) =>
              `<span className="ml-1" key={index}>
            ${index !== teachers.length - 1 ? `<span>${item}, </span>` : item}
          </span>`
          )
          .join("")
      : " Không có thông tin";
  };

  const onEditor = (editor: Editor) => {
    const panelManager = editor.Panels;
    const panelDevicesResponsiveId = "devices-c";
    const panelOpntionsId = "options";
    const panelViewsId = "views";
    // panelManager.removeButton(panelOpntionsId, 'export-template');
    // panelManager.removeButton(panelOpntionsId, 'gjs-open-import-webpage');
    // panelManager.removeButton(panelViewsId, 'open-layers');
    let titles = document.querySelectorAll("*[title]");
    for (let i = 0; i < titles.length; i++) {
      let el = titles[i];
      let title = el.getAttribute("title");
      title = title ? title.trim() : "";
      if (!title) break;
      el.setAttribute("data-tooltip", title);
      el.setAttribute("title", "");
      el.setAttribute("data-tooltip", "");
    }
    panelManager.getButton(panelOpntionsId, "sw-visibility")?.set("active", 1);
    const optionBtns = [
      ["sw-visibility", "Xem thành phần"],
      ["preview", "Xem trước"],
      ["fullscreen", "Toàn màn hình"],
      ["export-template", "Xem mã nguồn"],
      ["undo", "Hoàn tác"],
      ["redo", "Làm lại"],
      ["gjs-open-import-webpage", "Nhập mã nguồn"],
      ["canvas-clear", "Xóa"],
    ];
    const viewBtns = [
      ["open-sm", "Quản lý định dạng"],
      ["open-tm", "Cài đặt"],
      ["open-layers", "Quản lý lớp"],
      ["open-blocks", "Chọn mẫu"],
    ];
    optionBtns.forEach((item, i) => {
      panelManager.getButton(panelOpntionsId, item[0])?.set("attributes", {
        title: item[1],
        "data-tooltip": item[1],
        "data-tooltip-pos": "top",
      });
    });
    viewBtns.forEach((item, i) => {
      if (i === viewBtns.length - 1) {
        panelManager.getButton(panelViewsId, item[0])?.set("attributes", {
          title: item[1],
          "data-tooltip": item[1],
          "data-tooltip-pos": "left",
        });
      } else {
        panelManager.getButton(panelViewsId, item[0])?.set("attributes", {
          title: item[1],
          "data-tooltip": item[1],
          "data-tooltip-pos": "top",
        });
      }
    });

    panelManager.addButton(panelOpntionsId, {
      id: "save-all-template",
      className: "gjs-btn-custom gjs-pn-btn-save",
      command() {
        setOpenModalSave(true);
      },
      attributes: {
        title: "",
        "data-tooltip": "Lưu",
        "data-tooltip-pos": "top",
      },
      active: false,
    });

    editor.on("update", (e) => {
      setTimeout(() => {
        const courseSavedTempalte = {
          id: params?.id,
          html: editor.getHtml(),
          css: editor.getCss(),
        };
        indexDB.set(
          "courseSavedTempUniversity-" + params?.id,
          JSON.stringify(courseSavedTempalte),
          1440
        );
      }, 333);
    });

    editor.on("component:selected", (res) => {
      const toolTipTextAction = [
        "In đậm",
        "In nghiêng",
        "Gạch chân",
        "Gạch ngang",
        "Link",
        "Chỉnh sửa",
      ];
      const getAllActionIcon = document.querySelectorAll(".gjs-rte-action");
      getAllActionIcon.forEach((item, i) => {
        const itemHTML = item as HTMLInputElement;
        itemHTML.setAttribute("data-tooltip", toolTipTextAction[i]);
      });
      const commandToAdd = "tlb-save-block";
      const selectedComponent = editor.getSelected();
      if (selectedComponent) {
        let displayIdElm = document.getElementById("display-for-id");
        if (displayIdElm) {
          (displayIdElm as HTMLInputElement).value = res?.ccid;
        }
        let displayIdNameElm = document.getElementById("display-for-name");
        if (displayIdNameElm && res.attributes.name) {
          (displayIdElm as HTMLInputElement).value = res?.attributes?.name;
        }
      }
      const defaultToolbar = selectedComponent?.get("toolbar") || [];
      const commandExists = defaultToolbar?.some(
        (item) => item.command === commandToAdd
      );
      if (res.attributes.tagName !== "body") {
        if (!commandExists) {
          res?.set({
            toolbar: [
              ...defaultToolbar,
              {
                label:
                  '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"></path></svg>',
                attributes: { title: "Lưu khối giao diện" },
                command: commandToAdd,
              },
            ],
          });
        }
        let defaultDisplayFor: any = {
          "display-for": "all",
          "display-on-nav": "no",
        };
        styleManagerConfig.sectors
          .filter((s) => s.name == "Display For")
          .map((s) => s.properties)
          .flat()
          .forEach((sector) => {
            let key = (sector as any)?.key;
            let value =
              res?.getAttributes()[key?.toLowerCase()] ||
              defaultDisplayFor[key];
            let elm = document.querySelector(
              '[name="' + key + '"][value="' + value + '"]'
            );
            if (elm) {
              (elm as HTMLInputElement).checked = true;
            }
          });
        componentClassRef.current?.updateComponentClass(
          res?.getAttributes()["component-class"]
        );
      }
      let displaySettingEl = document.querySelector<HTMLDivElement>(
        ".gjs-sm-sector__settings_operations"
      );
      if (
        res.attributes.tagName === "section" &&
        res.attributes.attributes.name === "roadmap"
      ) {
        if (!commandExists) {
          res?.set({
            toolbar: [
              ...defaultToolbar,
              {
                label: `<?xml version="1.0" ?><svg id="Layer_1" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M96.1,103.6c-10.4,8.4-23.5,12.4-36.8,11.1c-10.5-1-20.3-5.1-28.2-11.8H44v-8H18v26h8v-11.9c9.1,7.7,20.4,12.5,32.6,13.6   c1.9,0.2,3.7,0.3,5.5,0.3c13.5,0,26.5-4.6,37-13.2c19.1-15.4,26.6-40.5,19.1-63.9l-7.6,2.4C119,68.6,112.6,90.3,96.1,103.6z"/><path d="M103,19.7c-21.2-18.7-53.5-20-76.1-1.6C7.9,33.5,0.4,58.4,7.7,81.7l7.6-2.4C9,59.2,15.5,37.6,31.9,24.4   C51.6,8.4,79.7,9.6,98,26H85v8h26V8h-8V19.7z"/></g></svg>`,
                attributes: { title: "Đồng bộ" },
                command: syncData,
              },
              {
                label:
                  '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"></path></svg>',
                attributes: { title: "Lưu" },
                command: commandToAdd,
              },
            ],
          });
        }
        if (displaySettingEl && res.getChildAt(1)) {
          displaySettingEl.style.display = "block";
        }
      }
      if (
        res.attributes.tagName === "section" &&
        res.attributes.attributes.name !== "roadmap"
      ) {
        if (displaySettingEl) {
          displaySettingEl.style.display = "none";
        }
      }
    });

    editor.on("block:drag:start", (block) => {
      setTimeout(() => {
        const blockSelected = allCourseBlocksUI.find((x) => {
          return x.id === block.id;
        });
        if (blockSelected) {
          editor.addStyle(blockSelected.css);
        }
      }, 333);
    });

    editor.on("block:drag:stop", (component, block) => {
      if (component !== null) {
        editor.select(component);
        const btnStyleManger = panelManager.getButton(panelViewsId, "open-sm");
        btnStyleManger && btnStyleManger.set("active", 1);
      }
    });

    editor.Commands.add("canvas-clear", (ed: Editor) => {
      confirmMessage(
        "Xóa toàn bộ",
        0,
        "Bạn có chắc muốn xóa toàn bộ thành phần không?",
        () => {
          ed.Css.clear();
          ed.Components.clear();
        }
      );
    });

    editor.Commands.add("tlb-delete", (ed: Editor) => {
      confirmMessage(
        "Xóa thành phần",
        0,
        "Bạn có chắc muốn xóa thành phần này không?",
        () => {
          helpService.successMessage("Thao tác thành công");
          ed.getSelected()?.destroy();
        }
      );
    });

    editor.Commands.add("tlb-save-block", (ed: Editor) => {
      const elementSelected = ed.getSelected();
      const blockHTML = elementSelected?.toHTML();
      const itSelfStyle = elementSelected?.getStyle();
      const itSelfId = elementSelected?.getId();
      let blockCss = "";
      if (!isEmpty(itSelfStyle)) {
        blockCss += `#${itSelfId}{${stringCssObj(itSelfStyle)}}`;
      }
      elementSelected?.forEachChild((x) => {
        const idChildEle = x.ccid;
        const styleChildEle = x.getStyle();
        if (!isEmpty(styleChildEle)) {
          blockCss += `#${idChildEle}{${stringCssObj(styleChildEle)}}`;
        }
      });
      const blockUI = JSON.stringify({ html: blockHTML, css: blockCss });
      setEditorCustom(ed);
      setBlockUISave(blockUI);
      setOpenModal(true);
    });

    editor.Commands.add("preview", (ed: Editor) => {
      window.open(
        routesConfig.universityPageBuilderView + "/" + params?.id,
        "_blank",
        "noreferrer"
      );
    });

    editor.TraitManager.addType("radio-group", {
      createInput({ trait, component }) {
        const optionValue =
          component.getAttributes()[trait.getName().toLowerCase()];
        const traitOpts = trait.get("options") || [];
        const options = traitOpts.length ? traitOpts : [];

        const el = document.createElement("div");
        el.innerHTML = `
          <form class=''>
          ${options
            .map(
              (opt) => `
              <label class='display-for-wrapper'>
                <input type='radio' class='radio-button-input' value='${
                  opt.id
                }' name='${trait.getName()}' ${
                opt.id == optionValue ? "checked" : ""
              } />
                <span>${opt.name}</span>
              </label>`
            )
            .join("")}
          </form>
        `;
        return el;
      },
      onEvent({ elInput, component, trait, event }) {
        let attribute: any = {};
        attribute[trait.getName()] = (event.target as HTMLInputElement)?.value;
        component.addAttributes(attribute);
      },
    });

    editor.on("component:mount", (component) => {
      const componentClass = component.getAttributes()["component-class"];
      function setNonEditableRecursive(comp: any) {
        comp.set({
          editable: false,
          draggable: false,
          removable: false,
          copyable: false,
          resizable: false,
          selectable: false,
          hoverable: false,
          layerable: false,
          badgable: false,
        });
        comp.components().forEach((child: any) => {
          setNonEditableRecursive(child);
        });
      }
      if (componentClass) {
        switch (componentClass) {
          case "CoursesPopular":
            const coursePopularItems = coursesFeatured
              ?.map(
                (item: CourseInfo) =>
                  `<div class="bg-white rounded-lg shadow-md overflow-hidden">
                  <img src=${
                    item?.thumbnailUrl || "https://via.placeholder.com/300x200"
                  } alt="Khóa học" class="w-full h-48 object-cover" />
                  <div class="p-4">
                    <h3 class="text-sm text-gray-500 uppercase font-semibold">${
                      item?.universityName
                    } (${item?.slug})</h3>
                    <h2 class="text-xl font-bold text-gray-800 mt-2">${
                      item?.name
                    }</h2>
                    <p class="text-red-500 font-semibold mt-2">${formatCurrencyToVND(
                      item?.cost
                    )}</p>
                    <p class="text-gray-600 text-sm mt-1">Giảng viên: ${renderTeacher(
                      item?.teachers as string[]
                    )}</p>

                    <!-- Thông tin bổ sung -->
                    <div class="flex items-center justify-between mt-4">
                      <span class="bg-green-100 text-green-800 text-xs font-medium px-3 py-1 rounded-full">Thịnh hành</span>
                      <span class="flex text-yellow-500 text-sm gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M12 .587l3.668 7.431 8.209 1.209-5.938 5.788 1.408 8.197-7.347-3.86-7.347 3.86 1.408-8.197-5.938-5.788 8.209-1.209z" />
                        </svg>
                        ${item?.avgStar}
                      </span>
                      <span class="text-blue-500 text-sm">${
                        item?.totalStudents
                      } học viên</span>
                    </div>
                  </div>
                </div>`
              )
              .join("");

            component.components(coursePopularItems);
            setNonEditableRecursive(component);
            break;

          case "CoursesToStart":
            const courseItems = coursesToStart
              ?.map(
                (item: CourseInfo) =>
                  `<div class="bg-white rounded-lg shadow-md overflow-hidden">
                  <img src=${
                    item?.thumbnailUrl || "https://via.placeholder.com/300x200"
                  } alt="Khóa học" class="w-full h-48 object-cover" />
                  <div class="p-4">
                    <h3 class="text-sm text-gray-500 uppercase font-semibold">${
                      item?.universityName
                    } (${item?.slug})</h3>
                    <h2 class="text-xl font-bold text-gray-800 mt-2">${
                      item?.name
                    }</h2>
                    <p class="text-red-500 font-semibold mt-2">${formatCurrencyToVND(
                      item?.cost
                    )}</p>
                    <p class="text-gray-600 text-sm mt-1">Giảng viên: ${renderTeacher(
                      item?.teachers as string[]
                    )}</p>

                    <!-- Thông tin bổ sung -->
                    <div class="flex items-center justify-between mt-4">
                      <span class="bg-green-100 text-green-800 text-xs font-medium px-3 py-1 rounded-full">Thịnh hành</span>
                      <span class="flex text-yellow-500 text-sm gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M12 .587l3.668 7.431 8.209 1.209-5.938 5.788 1.408 8.197-7.347-3.86-7.347 3.86 1.408-8.197-5.938-5.788 8.209-1.209z" />
                        </svg>
                        ${item?.avgStar}
                      </span>
                      <span class="text-blue-500 text-sm">${
                        item?.totalStudents
                      } học viên</span>
                    </div>
                  </div>
                </div>`
              )
              .join("");

            component.components(courseItems);
            setNonEditableRecursive(component);
            break;

          case "DiscoverLinks":
            component.components(`
               <li>
                  <a href="${MOOCs_SV_SITE}/course-info?university=${universityID}" class="block px-4 py-2 hover:bg-gray-100 text-gray-700 font-semibold">Danh sách khoá học</a>
                </li>
                <li>
                  <a href="${MOOCs_SV_SITE}/curriculum-programs?university=${universityID}" class="block px-4 py-2 hover:bg-gray-100 text-gray-700 font-semibold">Danh sách chương trình học</a>
                </li>`);
            break;
          case "universityName":
            component.components(`${universityName}`);
            break;
          case "universityLogo":
            component.components(`<img id="i2fag" src="${logo}"class="w-8" />`);
            break;
          case "searchCourseInput":
            component.components(` <div class="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
                <label for="searchField" id="ivwxdf" class="leading-7 text-sm">Nhập từ khoá bạn muốn tìm kiếm</label>             
                <input type="text" id="searchField" name="searchField" required class="w-full bg-gray-100 rounded border bg-opacity-50 border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
              </div>
              <button id="iyhrge" class="inline-flex text-white border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg bg-red-700">Tìm kiếm</button>`);
            break;
        }
      }
    });
  };

  const onChangeName = (e: any) => {
    setNameBlock(e.target.value);
  };

  const saveDetailTemplateBlock = (
    ed: Editor | undefined,
    template: string | undefined,
    cb?: any
  ) => {
    const detailDatablock = {
      label: nameBlock,
      template: template,
    };
    postSaveComponentUniversity(detailDatablock, universityID, props.tab).then(
      (res) => {
        setOpenModal(false);
        setNameBlock("");
        helpService.successMessage(res.data.data);
        addMyBlocksToCategories(ed);
        cb && cb(res);
      }
    );
  };

  const changeName = () => {
    if (nameBlock !== "") {
      setIconVisible(true);
      saveDetailTemplateBlock(editorCustom, blockUISave, () => {
        setIconVisible(false);
      });
    } else {
      helpService.errorMessage("Chưa nhập tên mẫu");
    }
  };

  const reloadAllTemplate = (editor: Editor, objTemplate: string) => {
    const parseCourseSavedTempalte = JSON.parse(objTemplate);
    editor.setComponents(parseCourseSavedTempalte.html);
    editor.setStyle(parseCourseSavedTempalte.css);
  };

  const getDetailTemplatesByCoueseId = (cb?: any) => {
    //change later
    getTemplateUniversity(universityID, props.tab).then((res) => {
      const { data } = res.data;
      cb && cb(data);
    });
  };

  const loadDefaultUI = (editor: Editor) => {
    editor.setComponents(uiDefault.html);
    editor.setStyle(uiDefault.css);
  };

  const checkTemplateIsNull = (editor: Editor) => {
    const components = editor.getComponents();
    if (components.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCancel = () => {
    setIsOpenSync(false);
  };

  const closeModal = () => {
    setNameBlock("");
    setOpenModal(false);
  };

  const handleClickSuffix = () => {
    setIconVisible(true);
    setTimeout(() => {
      let random;
      let nameRandom = "";
      while (nameRandom.length < 6) {
        random = Math.floor(Math.random() * 10).toString();
        if (!nameRandom.includes(random)) {
          nameRandom += random;
        }
      }
      setNameBlock("temp_" + nameRandom);
    }, 1499);
    setTimeout(() => {
      setIconVisible(false);
    }, 1500);
  };

  const addDetailTemplatesByCoueseId = (editor: Editor) => {
    indexDB.get("courseSavedTempUniversity-" + params?.id).then((val: any) => {
      if (val) {
        reloadAllTemplate(editor, val);
      }
      getDetailTemplatesByCoueseId((res: any) => {
        if (!isEmpty(res)) {
          const checkUIIsNull = checkTemplateIsNull(editor);
          if (
            checkUIIsNull &&
            res.template === null &&
            res.template_draft === null &&
            res.template_status_code === null
          ) {
            loadDefaultUI(editor);
          } else {
            reloadAllTemplate(editor, res.template);
          }
        }
      });
    });
  };

  function MyComponentWithUseEditor({ data }: any) {
    const editor = useEditor();
    const handleSync = () => {
      const component = editor.Components.getComponent();
      const titleEl = component
        ?.getEl()
        ?.querySelectorAll(".bg-white > .text-xl");
      const sectionContent = component?.getEl()?.querySelectorAll(".ml-4 h3");
      const sectionDescription = component
        ?.getEl()
        ?.querySelectorAll(".ml-4 p");
      const sectionTime = component?.getEl()?.querySelectorAll(".ml-4 span");
      if (data) {
        const { node } = data;
        // @ts-ignore
        titleEl.forEach((el, i) => {
          if (i + 1 === node.orderNumber) {
            el.textContent = node.title;
          }
        });
        sectionContent?.forEach((el1, i) => {
          el1.textContent = node.title;
        });
        sectionDescription?.forEach((el2, i) => {
          el2.textContent = node.title;
        });
        sectionTime?.forEach((el3, i) => {
          el3.textContent = node.title;
        });

        message.success("Đồng bộ thành công");
        setIsOpenSync(false);
      }
    };

    return (
      <>
        <Modal
          open={isOpenSync}
          okText="Đồng bộ"
          cancelText="Huỷ"
          onCancel={handleCancel}
          onOk={handleSync}
        >
          <>
            {/* <div className="select-data">
              {isArray(selectValue) && selectValue?.length > 0 ? (
                <a onClick={() => setSelectValue([])}>Bỏ chọn tất cả</a>
              ) : (
                <a onClick={() => setSelectValue(allValues)}>Chọn tất cả</a>
              )}
            </div>
            <div className="sync-data--tree">
              <Tree
                checkable
                treeData={treeData}
                onSelect={onSelect}
                onCheck={handleCheck}
                height={233}
                checkedKeys={selectValue}
              />
            </div> */}
          </>
        </Modal>
      </>
    );
  }

  const getHeaderCourse = (cb?: any) => {
    cb && cb();
  };

  const addBlockToEditor = (blockManager: any, blocks: any, cb?: any) => {
    blocks.forEach((b: any, i: number) => {
      blockManager.add(b.id, {
        label: b.label ? b.label : "Mẫu " + (i + 1),
        content: b.html ? b.html : b.content,
        category: b.category,
        media: b.media,
      });
      if (i === blocks.length - 1) {
        cb && cb();
      }
    });
  };

  const afterCallApiDone = (editor: Editor | undefined, data: any[]) => {
    countApiCall += 1;
    allCourseBlocksUI = allCourseBlocksUI.concat(data);
    // if (countApiCall === totalApiCall) {
    const blockManager = editor?.BlockManager;
    const blockManagerAll = blockManager?.getAll();
    let getAllblocks: any[] = [];
    addBlockToEditor(blockManager, allCourseBlocksUI, () => {
      blockManagerAll?.forEach((x: Block, indexBlock: Number) => {
        ``;
        getAllblocks.push({
          id: x.getId(),
          label: x.getLabel(),
          content: x.getContent(),
          category: x.getCategoryLabel(),
          media: x.getMedia(),
        });

        if (indexBlock === blockManagerAll.length - 1) {
          const isMyBlocks = getAllblocks.filter((x) => {
            return x.category === nameCategoryBlocks;
          });
          const isMyTemplates = getAllblocks.filter((x) => {
            return x.category === nameCategoryTemplates;
          });
          const isMyLecturerss = getAllblocks.filter((x) => {
            return x.category === nameLecturersBlock;
          });
          const isContactBlock = getAllblocks.filter((x) => {
            return x.category === nameContactBlock;
          });
          const isLinkedCourseBlock = getAllblocks.filter((x) => {
            return x.category === nameLinkedCourseBlock;
          });
          const isBasicBlock = getAllblocks.filter((x) => {
            return x.category === nameBasicBlock;
          });
          const isCountBlock = getAllblocks.filter((x) => {
            return x.category === nameCountBlocks;
          });
          const isRoadMapBlock = getAllblocks.filter((x) => {
            return x.category === nameRoadMapBlocks;
          });
          const isHeaderBlock = getAllblocks.filter((x) => {
            return x.category === nameHeaderBlocks;
          });
          const isRegisterBlock = getAllblocks.filter((x) => {
            return x.category === nameRegisterBlocks;
          });
          const isConditionBlock = getAllblocks.filter((x) => {
            return x.category === nameConditionBlocks;
          });
          const isAffiliatedBlock = getAllblocks.filter((x) => {
            return x.category === nameAffiliatedBlocks;
          });
          const isOtherBlocks = getAllblocks.filter((x) => {
            return (
              x.category !== nameCategoryBlocks &&
              x.category !== nameCategoryTemplates &&
              x.category !== nameContactBlock &&
              x.category !== nameLinkedCourseBlock &&
              x.category !== nameLecturersBlock &&
              x.category !== "Basic" &&
              x.category !== nameBasicBlock &&
              x.category !== nameCountBlocks &&
              x.category !== nameRoadMapBlocks &&
              x.category !== nameHeaderBlocks &&
              x.category !== nameRegisterBlocks &&
              x.category !== nameConditionBlocks &&
              x.category !== nameAffiliatedBlocks
            );
          });
          const isMyUI = [
            ...isAffiliatedBlock,
            ...isConditionBlock,
            ...isRegisterBlock,
            ...isHeaderBlock,
            ...isMyBlocks,
            ...isMyTemplates,
            ...isMyLecturerss,
            ...isContactBlock,
            ...isLinkedCourseBlock,
            ...isCountBlock,
            ...isRoadMapBlock,
            ...isBasicBlock,
          ];
          const finalAllBlocks = [...isMyUI, ...isOtherBlocks];
          blockManager?.clear();
          addBlockToEditor(blockManager, finalAllBlocks);
          setEditorCus(editor);
        }
      });
    });
    // }
  };

  const addMyBlocksToCategories = (editor: Editor | undefined) => {
    getHeaderCourse(() => {
      let customCourseBlocksUI: any[] = [];
      customCourseBlocksUI.push(generalHeaderIntroduction(params?.id));
      customCourseBlocksUI.push(generalContentIntroduction());
      customCourseBlocksUI.push(generalFooterIntroduction());
      customCourseBlocksUI.push(generalCoursePopular());
      customCourseBlocksUI.push(generalCoursesToStart());

      afterCallApiDone(editor, customCourseBlocksUI);
    });

    getDetailTemplatesCategory(
      (res: { content: any; forEach: (arg0: (x: any) => void) => void }) => {
        let blockData = res.content;
        let customCourseBlocksUI: any[] = [];
        blockData.forEach(
          (x: {
            isComponent: boolean;
            category: string;
            content: any;
            html: any;
            css: any;
          }) => {
            if (x.isComponent) {
              x.category = nameCategoryBlocks;
            }
            if (!x.isComponent) {
              x.category = nameCategoryTemplates;
            }
            const templateContent = x.content;
            if (templateContent !== null && isValidJSON(templateContent)) {
              const blockUI = JSON.parse(templateContent);
              x.html = blockUI.html;
              x.css = blockUI.css;
            }
            pushBlockItem(customCourseBlocksUI, x);
          }
        );
        // pushBlockItem(customCourseBlocksUI, contactBlockCustom);
        // pushBlockItem(customCourseBlocksUI, customLinkedCourse);
        afterCallApiDone(editor, customCourseBlocksUI);
      }
    );

    getDetailBlockCategory(
      (res: { content: any; forEach: (arg0: (x: any) => void) => void }) => {
        let blockData = res.content;
        let customCourseBlocksUI: any[] = [];
        blockData.forEach(
          (x: {
            isComponent: boolean;
            category: string;
            content: any;
            html: any;
            css: any;
          }) => {
            if (x.isComponent) {
              x.category = nameCategoryBlocks;
            }
            if (!x.isComponent) {
              x.category = nameCategoryTemplates;
            }
            const templateContent = x.content;
            if (templateContent !== null && isValidJSON(templateContent)) {
              const blockUI = JSON.parse(templateContent);
              x.html = blockUI.html;
              x.css = blockUI.css;
            }
            pushBlockItem(customCourseBlocksUI, x);
          }
        );
        // pushBlockItem(customCourseBlocksUI, contactBlockCustom);
        // pushBlockItem(customCourseBlocksUI, customLinkedCourse);
        afterCallApiDone(editor, customCourseBlocksUI);
      }
    );
  };

  const handleLinkTitle = (editor: Editor) => {
    editor.Components.addType("link", {
      model: {
        defaults: {
          script: function () {
            // Get the current element
            const el = this;

            // Function to update the title attribute
            const updateTitle = () => {
              el.setAttribute("title", el.textContent);
            };

            // Listen for changes to the element's text content
            el.addEventListener("input", updateTitle);

            // Update the title initially
            updateTitle();
          },
        },
      },
      isComponent: (el) => {
        if (el.tagName === "A") {
          return { type: "link" };
        }
      },
    });
  };

  const getDetailTemplatesCategory = (cb?: any) => {
    let params = {
      page: 1,
      size: 100,
      keyword: "",
      enterpriseId: universityID,
      type: props.tab,
      isComponent: true,
    };
    postTemplateByUserUniversity(params).then((res) => {
      const { data } = res.data;
      cb && cb(data);
    });
  };

  const getDetailBlockCategory = (cb?: any) => {
    let dataParams = {
      page: 1,
      size: 100,
      keyword: "",
      enterpriseId: universityID,
      type: props.tab,
      isComponent: false,
    };
    postTemplateByUserUniversity(dataParams).then((res) => {
      const { data } = res.data;
      cb && cb(data);
    });
  };

  return (
    <>
      {loading ? (
        <div className="loading-editor">
          <Spin />
        </div>
      ) : (
        <GjsEditor
          className="main-page-builder"
          grapesjs={grapesjs}
          grapesjsCss="../../../src/pages/page-builder/grapesjs.min.css"
          waitReady={true}
          options={{
            height: "calc(100vh - 186px)",
            storageManager: false,
            fromElement: true,
            showOffsets: true,
            selectorManager: { componentFirst: true },
            assetManager: {
              embedAsBase64: false,
              assets: imagesAssests,
              upload: BASE_URL_V2 + "/file-manager/page-builder-upload",
              uploadName: "files",
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            },
            canvas: {
              styles: [],
              scripts: [],
            },
            plugins: [
              gjsBlocksBasic,
              gjsTailWind,
              gjsNavbar,
              gjsPresetWeb,
              gjsStyleBg,
              gjsStyleFilter,
              gjsStyleGradient,
              gjsComponentCountdown,
              gjsTabs,
              grapesjsCustomCode,
              grapesjsTooltip,
              grapesjsTyped,
              addMyBlocksToCategories,
              CommonAdvanceSettingsPlugin,
              handleLinkTitle,
              addDetailTemplatesByCoueseId,
            ],
            pluginsOpts: {
              [gjsBlocksBasic]: {
                flexGrid: true,
                category: "Giao diện cơ bản",
                labelColumn1: "1 cột",
                labelColumn2: "2 cột",
                labelColumn3: "3 cột",
                labelColumn37: "3 phần 7 cột",
                labelText: "Chữ",
                labelLink: "Link",
                labelImage: "Ảnh",
                labelVideo: "Video",
                labelMap: "Bàn đồ",
              },
              [gjsPresetWeb]: {
                modalImportTitle: "Thêm giao diện",
                modalImportLabel:
                  '<div style="margin-bottom: 10px; font-size: 13px;">Hãy dán HTML/CSS của bạn vào và bấm Import</div>',

                modalImportContent: (editor: Editor) => {
                  return (
                    editor.getHtml() + "<style>" + editor.getCss() + "</style>"
                  );
                },
              },
              [gjsTailWind]: {
                cover: "",
              },
              [gjsTabs]: {
                tabsBlock: { category: "Extra" },
              },
              [grapesjsTyped]: {
                block: {
                  category: "Extra",
                  content: {
                    type: "typed",
                    "type-speed": 40,
                    strings: ["Text row one", "Text row two", "Text row three"],
                  },
                },
              },
            },
            styleManager: styleManagerConfig,
          }}
          onEditor={onEditor}
        >
          <WithEditor>
            <MyComponentWithUseEditor data={infoSelect} />
          </WithEditor>
        </GjsEditor>
      )}

      <Modal
        title="Lưu vào mẫu"
        // icon = {<SaveOutlined />}
        open={openModal}
        okText="Xác nhận"
        cancelText="Hủy"
        onOk={changeName}
        onCancel={closeModal}
        okButtonProps={iconVisible ? { disabled: true } : { disabled: false }}
        cancelButtonProps={
          iconVisible ? { disabled: true } : { disabled: false }
        }
      >
        <Form.Item required={true} label="Tên mẫu">
          <Input
            disabled={iconVisible}
            value={nameBlock}
            onChange={onChangeName}
            suffix={
              <>
                <Tooltip title="Sinh mã tự động">
                  {iconVisible ? (
                    <LoadingOutlined />
                  ) : (
                    <RetweetOutlined onClick={handleClickSuffix} />
                  )}
                </Tooltip>
              </>
            }
          />
        </Form.Item>
      </Modal>
      <Modal
        open={openModalSave}
        title="Xác nhận lưu giao diện trình bày?"
        onOk={() => {}}
        onCancel={() => {
          setNameBlock("");
          setIsSaveAsBlock(false);
          setOpenModalSave(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setNameBlock("");
              setIsSaveAsBlock(false);
              setOpenModalSave(false);
            }}
          >
            Hủy
          </Button>,
          <Button
            key="submit"
            onClick={() => {
              saveDetailTemplatesByCoueseId("draft", editorCus);
            }}
          >
            Lưu nháp
          </Button>,
          <Button
            key="link"
            type="primary"
            // loading={loading}
            onClick={() => {
              saveDetailTemplatesByCoueseId("published", editorCus);
            }}
          >
            Lưu
          </Button>,
        ]}
        // okButtonProps={typePopup === 0 ? "btn btn-filled--danger" : "btn"}
      >
        <Checkbox
          checked={isSaveAsBlock}
          onChange={(e) => {
            setIsSaveAsBlock(e.target.checked);
          }}
        >
          Lưu làm mẫu
        </Checkbox>
        <br />
        <span className="description">
          Khối giao diện sẽ được lưu vào mục "Mẫu hoàn chỉnh"
        </span>
        {isSaveAsBlock ? (
          <Form.Item required={true} label="Tên mẫu">
            <Input
              disabled={iconVisible}
              value={nameBlock}
              onChange={onChangeName}
              suffix={
                <>
                  <Tooltip title="Sinh mã tự động">
                    {iconVisible ? (
                      <LoadingOutlined />
                    ) : (
                      <RetweetOutlined onClick={handleClickSuffix} />
                    )}
                  </Tooltip>
                </>
              }
            />
          </Form.Item>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default GeneralIntroduction;
