import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  SortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./SortableItem";
import "./style.scss";

const containerStyle: React.CSSProperties = {
  background: "#dadada",
  padding: 10,
  margin: 10,
  flex: 1,
  minHeight: 100,
  display: "flex",
  gap: 16,
};

interface ContainerProps {
  id: string;
  items: any;
  className?: string;
  description?: string;
  strategy?: SortingStrategy;
}

export default function Container(props: ContainerProps) {
  const { id, items, className, description, strategy } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext id={id} items={items} strategy={strategy}>
      <div
        className={`sort-container ${className}`}
        ref={setNodeRef}
        style={containerStyle}
      >
        {items.length === 0 && description ? (
          <div
            style={{
              padding: 10,
              textAlign: "center",
              color: "#aaa",
              border: "2px dashed #ccc",
              minHeight: "50px",
            }}
          >
            {description}
          </div>
        ) : (
          items.map((id: string) => <SortableItem key={id} id={id} />)
        )}
      </div>
    </SortableContext>
  );
}
