import { InfoCircleOutlined } from "@ant-design/icons";
import { Flex, Progress, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import { routesConfig } from "../../../config/routes";

const { Title, Text } = Typography;

interface IProps {
  minStudentData: any;
  handleChangeTab: () => any;
}
const MinStudentJoin = ({ minStudentData, handleChangeTab }: IProps) => {
  return (
    <div>
      <Flex className="mt-6 mb-[1rem]">
        <Text className="text-[1rem] pr-3">
          Tiêu chí đánh giá số sinh viên tối thiểu tham gia buổi học
        </Text>
        <Tooltip
          title={
            <div>
              Tiêu chí được thiết lập tại{" "}
              <span onClick={handleChangeTab} className="text-[#1890FF] cursor-pointer">
                Cài đặt kiểm soát chất lượng tiết học video
              </span>{" "}
            </div>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Flex justify="space-between" className="flex-wrap lg:!flex-nowrap">
        <div className="w-full lg:w-1/3 lg:mr-2 mb-6 lg:mb-0 bg-white">
          <div className="p-[24px] text-[1rem] font-medium">
            Tiêu chí đánh giá
          </div>
          <Flex className="border-y">
            <div className="w-1/2 bg-[#FAFAFA] p-4 font-medium">
              Số lượng tiêu chuẩn
            </div>
            <Flex className="w-1/2 p-4" justify="space-between">
              <Text>{minStudentData?.setting} sinh viên</Text>
              <Tooltip title="Số lượng sinh viên tối thiểu">
                <InfoCircleOutlined />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex className="pt-6" justify="center">
            <Progress
              strokeLinecap="butt"
              type="dashboard"
              percent={(minStudentData?.total/minStudentData?.setting) * 100}
              className=""
              gapDegree={180}
              status="active"
              format={(percent) => (
                <div>
                  <div className="leading-0">
                    <Text className="leading-0 text-[2rem] font-medium">
                      {minStudentData?.total}
                    </Text>
                    <Text className="leading-0 text-[1rem] font-medium">
                      /{minStudentData?.setting}
                    </Text>
                  </div>
                  <div className="leading-0">
                    <Text className="leading-0 text-[1rem] font-normal">
                      Thực tế
                    </Text>
                    <Text className="leading-0 text-[1rem] font-normal">
                      /Tiêu chí
                    </Text>
                  </div>
                </div>
              )}
              size={[200, 10]}
            />
          </Flex>
          <Flex className="border-y">
            <div className="w-1/2 bg-[#FAFAFA] p-4 font-medium">
              Kết quả đánh giá
            </div>
            <Flex className="w-1/2 p-4" justify="space-between">
              {minStudentData?.total < minStudentData?.setting ? (
                <Text className="border border-[#FFA39E] text-[#F5222D] bg-[#FFF1F0] px-2 rounded-md">
                  Không đạt
                </Text>
              ) : (
                <Text className="border border-[#91D5FF] text-[#1677FF] bg-[#E6F7FF] px-2 rounded-md">
                  Đạt
                </Text>
              )}
            </Flex>
          </Flex>
        </div>
        <div className="w-full lg:w-2/3 lg:ml-2 bg-white">
          <div className="p-[24px] text-[1rem] font-medium">
            Thống kê chi tiết ( Tổng: {minStudentData?.total} sinh viên )
          </div>
          <div>
            <Flex className="bg-[#FAFAFA] py-4 border-t">
              <div className="px-4 w-1/2 font-medium">Họ và tên</div>
              <div className="px-4 w-1/2 font-medium border-x">Email</div>
            </Flex>
            <div className="overflow-auto max-h-[265px]">
              {minStudentData?.details?.map(
                (value: { name: string; email: string }) => (
                  <Flex className="py-4 border-t">
                    <div className="px-4 w-1/2">{value?.name}</div>
                    <div className="px-4 w-1/2">{value?.email}</div>
                  </Flex>
                )
              )}
            </div>
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default MinStudentJoin;
