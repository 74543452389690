import { Col, Row, Typography } from "antd";
import { useState } from "react";
import "./style.scss";

type Props = {
  isCloseModal: (value: boolean) => void;
  multimediaType: React.Dispatch<React.SetStateAction<string>>;
};

const Multimedia = (props: Props) => {
  const { isCloseModal, multimediaType } = props;

  const handleChooseMultimediaType = (type: string) => {
    isCloseModal(false);
    multimediaType(type)
  };
  const multimediaList = [
    {
      title: "Video",
      imgUrl: "/images/Cinema.png",
      describe: "Tải video của bạn lên làm tài liệu học tập",
      type: "video",
    },
    {
      title: "Scorm",
      imgUrl: "/images/Frames.png",
      describe: "Tải tệp Scorm của bạn lên làm tài liệu học tập",
      type: "scorm",
    },
    {
      title: "xAPI",
      imgUrl: "/images/Frames.png",
      describe: "Tải tệp xAPI của bạn lên làm tài liệu học tập",
      type: "xAPI",
    },
    {
      title: "PDF",
      imgUrl: "/images/PDF.png",
      describe: "Tải lên và trình bày các tệp PDF trong khóa học ",
      type: "PDF",
    },
    {
      title: "Âm thanh",
      imgUrl: "/images/Audio.png",
      describe: "Tải tệp âm thanh lên làm tài liệu học tập",
      type: "audio",
    },
    {
      title: "Văn bản",
      imgUrl: "/images/Text.png",
      describe: "Soạn thảo văn bản",
      type: "text",
    },
  ];
  return (
    <div className="multimedia">
      {multimediaList.map((el, index) => (
        <Col
          key={index}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xs={{ span: 24 }}
          onClick={() => handleChooseMultimediaType(el?.type)}
          className="multimedia-item"
        >
          <Row>
            <Col
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 3 }}
              xs={{ span: 3 }}
            >
              <img src={el.imgUrl} className="multimedia-img" />
            </Col>

            <Col
              md={{ span: 20 }}
              lg={{ span: 20 }}
              xl={{ span: 240 }}
              xs={{ span: 20 }}
            >
              <Typography.Title level={5}>{el.title}</Typography.Title>
              <Typography.Text className="sub-title">
                {el.describe}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      ))}
    </div>
  );
};

export default Multimedia;
