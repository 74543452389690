import { Flex, Form, Radio } from "antd";
import {
  JSON_SUB_TYPE,
  QuestionBeProps,
  QuestionPropsV2,
} from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";

function ChooseImage({ data, initialData, disabled }: QuestionPropsV2) {
  const placeholders = data
    ? 2 - (data.questions.length % 2 === 0 ? 2 : data.questions.length % 2)
    : null;
  return (
  
      <Radio.Group disabled={disabled}>
        <div className="flex layout-two-col">
          {data?.questions && data?.questions?.length > 0 ? (
            data?.questions?.map((quiz: QuestionBeProps, index: number) => (
              <div className={`image-quiz-wrap`} key={quiz.uuid + index}>
                <Radio checked={quiz?.isTrue} id={quiz.uuid + index} value={quiz?.uuid || index + 1}>
                  <Flex className="font-size-16 line-height-24 gap-1">
                    {convertNumberingTypeToText(
                      index + 1,
                      Number(JSON.parse(data?.settingGeneral)?.numberingType) || 0
                    )}
                    .
                    <div
                      className="font-size-16 line-height-24"
                      dangerouslySetInnerHTML={{ __html: `${quiz?.content}` }}
                    ></div>
                  </Flex>
                </Radio>
                <label htmlFor={quiz.uuid + index}>
                  <img
                    className="mt-1"
                    src={quiz?.filePath || ""}
                    alt="quiz-card"
                  />
                </label>
              </div>
            ))
          ) : (
            <div className="center">
              <EmptyComponent description="Không có nội dung" />
            </div>
          )}
          {Array.from({ length: placeholders || 0 }).map((_, index) => (
            <div
              key={`placeholder-${index}`}
              className={`image-quiz-wrap choose-correct placeholder`}
            />
          ))}

          {/* <div className={`image-quiz-wrap active-quiz-wrong`}>
            <Radio id="1" value={1}>
              Mô tả câu hỏi{" "}
            </Radio>
            <label htmlFor="1">
              <img
                className="mt-1"
                src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
                alt="quiz-card"
              />
            </label>
          </div> */}
        </div>
      </Radio.Group>
  );
}

export default ChooseImage;
