import axios from "axios";
import { useAuthStore } from "../../stores/stores";
export const BASE_URL = process.env.REACT_APP_BASE_API_URL?.replaceAll('"', "");
export const configApiWithoutNotify = axios.create({
  baseURL: `${BASE_URL}v2`,
  timeout: 15000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

configApiWithoutNotify.interceptors.request.use(
  (config) => {
    const accessToken = useAuthStore.getState().accessToken;
    if (!config.headers["Authorization"] || accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

configApiWithoutNotify.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error);
  }
);
