import { Col, Empty, Row, Space, Spin, Typography } from "antd";
import StatisticalReportCard from "../component/StatisticalReportCard";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import useChartExport from "../../../../../statistical-report/for-instructors/components/chart/useChartExport";
import { staticReportStore } from "../../../../../../stores/stores";
import { useEffect, useState } from "react";
import { getChartSurveyAnswers } from "../../../../../../service/course-construct/survey-plan";

type Props = {
    isShowListAnswers: boolean;
    quizName?: string,
    quizType?: number,
    selectedQuiz?: number,
}

const QuizReportChart = (props: Props) => {
    const { isShowListAnswers, quizType, quizName, selectedQuiz } = props;
    const [loading, setLoading] = useState(false);
    const [quizData, setQuizData] = useState<any>([]);

    // Hook for managing chart exports
    const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

    const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

    // get type
    const getQuizType = () => {
        switch (quizType) {
            case 1:
                return "Câu hỏi nhiều lựa chọn văn bản";
            case 2:
                return "Câu hỏi nhiều lựa chọn hình ảnh";
            case 3:
                return "Câu hỏi nhiều lựa chọn video";
            case 4:
                return "Câu hỏi dạng đúng sai";
            case 5:
                return "Câu hỏi dạng danh sách";
            case 6:
                return "Câu hỏi dạng nhiều câu trả lời văn bản";
            case 7:
                return "Câu hỏi dạng nhiều câu trả lời ảnh";
            case 8:
                return "Câu hỏi dạng nhiều câu trả lời video";
            default:
                return "";
        }
    }

    const listColors = ["#FF7875", "#FFC069", "#5CDBD3", "#69C0FF", "#B37FEB", "#5e21b5", "#e7ed24", "#877676", "#f0c7c7"];

    // fetch Data
    const fetchData = async () => {
        setLoading(true);
        const dataReq = {
            quizId: selectedQuiz, 
        }

        await getChartSurveyAnswers(dataReq).then((res) => {
            if(res.status === 200) {
                setQuizData(res.data?.data?.statistic);
                setTimeout(() => {
                    setLoading(false);
                }, 333);
            }
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        }); 
    }

    // useEffect
    useEffect(() => {
        if(quizType === 1 || quizType === 2 || quizType === 3 || quizType === 4 ||
            quizType === 5 || quizType === 6 || quizType === 7 || quizType === 8
        ) {
            fetchData();
        }
    }, [selectedQuiz]);

    useEffect(() => {
        setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
    }, [tab, exportType]);

    return (
        <>
            <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
                <Col span={24} className="col-donut-chart">
                    <StatisticalReportCard
                        chartName={getQuizType()}
                        content={
                        <Spin spinning={loading}>
                            {quizData.length > 0 ? (
                                <Row>
                                    <Col
                                        xl={{ span: isShowListAnswers ? 14 : 24 }}
                                        md={{ span: isShowListAnswers ? 14 : 24 }}
                                        sm={{ span: 24 }}
                                        xs={{ span: 24}}
                                    >
                                        <DonutChart
                                            data={quizData}
                                            title=""
                                            height={300}
                                            innerSize="70%"
                                            size="80%"
                                            colors={listColors}
                                            totalName="Tổng số"
                                            ref={createChartRef(tab, `${quizName}`)}
                                        />
                                    </Col>
                                    {
                                        isShowListAnswers
                                        ?
                                            <Col xl={{ span: 10 }} md={{ span: 10 }} sm={{ span: 24 }} xs={{ span: 24 }} className="col-chart">
                                                {quizData?.map((quiz: any, index: number) => {
                                                    return(
                                                        <Space className="answer-des" style={{width: "100%"}}>
                                                            <div className="color-icon" style={{ backgroundColor: listColors[index]}}></div>
                                                            <Typography.Text>{quiz.criteria?.replace(/<\/?[^>]+(>|$)/g, "")}</Typography.Text>
                                                        </Space>
                                                    )
                                                })}
                                            </Col>
                                        :
                                            <></>
                                    }
                                    
                                </Row>
                                
                            ) : (
                            <Empty description="Không có dữ liệu" />
                            )}
                        </Spin>
                        }
                        isChart={true}
                        exportOptions={[
                        {
                            label: "Hình ảnh (PNG)",
                            action: () =>
                            handleExportImage(tab, `${quizName}`, "image/png"),
                        },
                        {
                            label: "PDF",
                            action: () =>
                            handleExportImage(
                                tab,
                                `${quizName}`,
                                "application/pdf"
                            ),
                        },
                        ]}
                    />
                </Col>
            </Row>
        </>
    )
}

export default QuizReportChart;