import { Avatar, Button, Empty, Form, Input, Modal, Select, Table, Tabs, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getEnterpriseShare, getLearningMaterialFromLibrary, getSharerList } from '../../../service/learning-material';
import { LearningMaterialType } from '../../../types/learning-material';
import { filterOption, getFirstLetter, RenderUnitIcon } from '../../../utils/functionUltils';
import "./ChooseLearningMaterialModal.scss";
import { useAuthStore } from '../../../stores/stores';

interface Props {
	open: boolean;
	onCancel: () => void;
	onSelectLearningMaterial: (course: LearningMaterialType) => void;
	type: string;
}

const ChooseLearningMaterialModal: React.FC<Props> = ({ open, onCancel, type, onSelectLearningMaterial }) => {
	const [form] = Form.useForm();
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [listLearningMaterial, setListLearningMaterial] = useState<LearningMaterialType[]>([]);
	const [activeTab, setActiveTab] = useState<string>("1");
	const [sharerList, setSharerList] = useState<any[]>([]);
	const [enterpriseList, setEnterpriseList] = useState<any[]>([]);
	const userInfo = useAuthStore((state) => state.user);

	const dataColumns: ColumnsType<LearningMaterialType> = [
		{
			title: "STT",
			dataIndex: "numericalOrder",
			key: "numericalOrder",
			width: "60px",
			render: (_: any, __: any, index: number) => (pageNumber - 1) * pageSize + index + 1,
		},
		{
			title: "Học liệu",
			dataIndex: "resource",
			key: "resource",
			render: (resource: any, record) => {
				return (
					<div className={`${"flex gap-16 align-center"}`}
						style={{ wordBreak: "break-all" }}
					>
						<div
							style={{ width: "60px" }}
						>{RenderUnitIcon(record.module)}</div>
						<div>
							<div className="font-weight-5">{record.code}</div>
							<div>{record.name}</div>

						</div>
					</div>
				)
			},
		},
		{
			title: "Mô tả",
			dataIndex: "description",
			key: "description",
			render: (resource: any, record: any) => {
				return (
					<div
						className="mt-3"
						dangerouslySetInnerHTML={{ __html: `${record?.description}` || "" }}
					/>
				)
			},
		},
		...activeTab === "2" ?
			[
				{
					title: "Người chia sẻ",
					dataIndex: "sharer",
					key: "sharer",
					render: (resource: any, record: any) => {
						return (
							<div className="flex gap-16 align-center"
								style={{ wordBreak: "break-all" }}
							>
								<Avatar src={record?.imagePath} size={48} className="mt-2 mb-2">
									{getFirstLetter(record?.shareUserFullName)}
								</Avatar>
								<div>
									<div className="font-weight-5">{record.shareUserFullName}</div>
									<div>{record.shareEnterpriseName}</div>

								</div>
							</div>
						)
					},
				},
				{
					title: "Loại chia sẻ",
					dataIndex: "shareType",
					key: "shareType",
					width: "160px",
					render: (resource: any, record: any) => {

						return (
							<div>
								{record?.shareType === 1 ? "Trường"
									: record?.shareType === 2 ? "Bộ"
										: "-"
								}
							</div>
						)
					},
				},
			] : [
			],

		...activeTab === "1" ?
			[
				{
					title: "Ngày tạo",
					dataIndex: "createdDate",
					key: "createdDate",
					width: "180px",
					render: (resource: any, record: any) => {

						return (
							<div>
								{dayjs(record.createdDate).format("DD/MM/YYYY")}
							</div>
						)
					},
				}] : [{
					title: "Ngày chia sẻ",
					dataIndex: "sharedDate",
					key: "sharedDate",
					width: "180px",
					render: (resource: any, record: any) => {

						return (
							<div>
								{record.sharedDate ? dayjs(record.sharedDate).format("DD/MM/YYYY") : ""}
							</div>
						)
					},
				}],
		{
			title: 'Thao tác',
			key: 'action',
			fixed: 'right',
			width: '100px',
			render: (_, record) => (
				<Button type="link" onClick={() => onSelectLearningMaterial(record)}>
					Chọn
				</Button>
			),
		},
	];
	const tabItems = [
		{
			key: "1",
			label: "Của tôi",
			children: (
				<Table
					columns={dataColumns}
					dataSource={listLearningMaterial}
					loading={loading}
					pagination={{
						current: pageNumber,
						pageSize,
						total: totalRecords,
						onChange: (page, size) => {
							setPageNumber(page);
							setPageSize(size);
						},
					}}
					scroll={{ x: 1000, y: 'calc(90vh - 340px)' }}
					rowKey="id"
					locale={{ emptyText: <Empty description="Không tìm thấy" /> }}
				/>
			),
		},
		{
			key: "2",
			label: "Được chia sẻ",
			children: (
				<Table
					columns={dataColumns}
					dataSource={listLearningMaterial}
					loading={loading}
					pagination={{
						current: pageNumber,
						pageSize,
						total: totalRecords,
						onChange: (page, size) => {
							setPageNumber(page);
							setPageSize(size);
						},
					}}
					scroll={{ x: 1000, y: 'calc(90vh - 340px)' }}
					rowKey="id"
					locale={{ emptyText: <Empty description="Không tìm thấy" /> }}
				/>
			),
		},
	];

	const handleFilterSubmit = () => {
		form.validateFields()
			.then((values) => {
				getListData();
			})
			.catch((errorInfo) => {
				console.log('Validation failed:', errorInfo);
			});
	};

	const getListData = () => {
		form.validateFields().then((values) => {
			setLoading(true);
			const { enterprise, sharer, keyword } = values;
			const payload = {
				page: pageNumber,
				size: pageSize,
				sort: ['created_date, desc'],
				isFromCurrentUser: activeTab === "1" ? true : false,
				module: type === "audio" ? "mp3" : type,
				shareEnterpriseUuids: enterprise || undefined,
				shareUserIds: sharer || undefined,
				keyword
			};

			getLearningMaterialFromLibrary(payload)
				.then((res) => {
					const { data } = res;
					setListLearningMaterial(data?.data.content);
					setTotalRecords(data.data.totalElements);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}).catch((errorInfo) => {
			console.log("Validation failed:", errorInfo);
		});
	};


	const fetchSharerList = async () => {
		try {
			const res = await getSharerList();
			setSharerList(res.data.data.map((it: any) => ({
				label: it.fullName,
				value: it.id
			})));
		} catch (error) {
			console.error(error);
		}
	};

	const fetchEnterpriseList = async () => {
		try {
			const res = await getEnterpriseShare()
			setEnterpriseList(res.data.data.map((it: any) => ({
				label: it.name,
				value: it.uuid
			})))
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (open) {

			fetchSharerList();
			fetchEnterpriseList();
		}
	}, [open]);

	useEffect(() => {
		if (open) {
			form.resetFields();
			getListData();
		}
	}, [open, pageNumber, pageSize, activeTab]);

	useEffect(() => {
		if (open) {
			setActiveTab("1")
		}
	}, [open]);

	useEffect(() => {
		if (activeTab === "2") {
			form.setFieldsValue({ enterprise: [], Sharer: [] });
		} else if (activeTab === "1") {
			form.setFieldsValue({
				enterprise: [userInfo?.enterpriseUuid],
				sharer: [userInfo?.id]
			});
		}
	}, [activeTab]);

	return (
		<Modal
			title="Chọn học liệu từ thư viện"
			open={open}
			onCancel={() => {
				onCancel();
				form.resetFields();
			}}
			footer={null}
			centered
			className="choose-learning-material-modal"
			width="80vw"
			style={{ height: '90vh', maxHeight: '90vh' }}
		>
			<Form
				form={form}
				initialValues={{
					enterprise: activeTab === "1" ? [userInfo?.enterpriseUuid] : undefined,
					sharer: activeTab === "1" ? [userInfo?.id] : undefined,
				}}
			>
				<div className="flex gap-24">
					<div className='flex-1'>
						<Typography.Title level={5}>Thông tin</Typography.Title>
						<Form.Item name="keyword" style={{ flex: 1 }}>
							<Input placeholder="Tìm kiếm khoá học"
								onPressEnter={handleFilterSubmit}
							/>
						</Form.Item>
					</div>
					{activeTab === "2" ? <div className='flex-1'>
						<Typography.Title level={5}>Trường</Typography.Title>
						<Form.Item name="enterprise" style={{ flex: 1 }}>
							<Select
								mode="multiple"
								placeholder="Chọn trường"
								style={{ width: '100%' }}
								options={enterpriseList}
								filterOption={filterOption}
								notFoundContent={
									<span style={{ textAlign: "center" }}>
										<p>
											<img
												className="image-empty-data"
												src="/images/empty-img-gray.png"
												alt="empty-img"
											></img>
										</p>
										<p className="nodata-text">Không tìm thấy</p>
									</span>
								}
							// disabled={activeTab === "1"}
							/>
						</Form.Item>
					</div> : null}
					<div className='flex-1'>
						<Typography.Title level={5}>Người chia sẻ</Typography.Title>
						<Form.Item name="sharer" >
							<Select
								mode="multiple"
								placeholder="Chọn người chia sẻ"
								style={{ width: '100%' }}
								options={sharerList}
								filterOption={filterOption}
								disabled={activeTab === "1"}
								notFoundContent={
									<span style={{ textAlign: "center" }}>
										<p>
											<img
												className="image-empty-data"
												src="/images/empty-img-gray.png"
												alt="empty-img"
											></img>
										</p>
										<p className="nodata-text">Không tìm thấy</p>
									</span>
								}
							/>
						</Form.Item>
					</div>
					<div style={{ display: 'flex', alignItems: "flex-end" }}>
						<label></label>
						<Button onClick={handleFilterSubmit}>
							Lọc
						</Button>
					</div>
				</div>
			</Form>
			<Tabs
				items={tabItems}
				activeKey={activeTab}
				onChange={(tab) => setActiveTab(tab)}
			/>

		</Modal>
	);
};

export default ChooseLearningMaterialModal;
