import { Radio, Typography } from "antd";
import { AnswersPros } from "./survey-answers-content";
import { convertQuillToString } from "../../../../../../../utils/convertQuillToString";

const ShortText = (props: AnswersPros) => {
  const { question } = props;
  return <div className='survey-answers-content__wrapper'>
    <div className='survey-answers-content__title'>
      <Typography.Title level={3}>{convertQuillToString(question.question)}</Typography.Title>
    </div>
    <div className='survey-answers-content__body'>
      {question.resultJson.map((x: any, i: number) => {
        return <div key={i + 1} className='survey-answers-content__body__item'>
          <div className='item__action'>
            <span>{i + 1}.</span>
          </div>
          <div className='item__action'>
            <span>{x.contents.join(',')}</span>
          </div>
        </div>
      })}
    </div>
  </div>
}

export default ShortText;