import {
  Form,
  TableColumnsType,
  TableProps,
  Typography,
  FormInstance,
  Layout,
  Button,
  Dropdown,
  Modal,
  Space,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import React, {
  ChangeEvent,
  useState,
  useTransition,
  KeyboardEvent,
  useEffect,
} from "react";
import TableData from "../../components/table-data/TableData";
import { formatDateV2, formatOriginTimev3 } from "../../utils/converDate";
import FilterTable from "../../components/account-registration-report/filter-table/index";
import FormTable from "../../components/account-registration-report/form-table/index";
import PageHeader from "../../components/page-header/PageHeader";
import "./index.scss";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import {
  getAmountRegister,
  getPostUniversityStudentAccount,
  getStatisticalRegisterFilter,
} from "../../service/account-register";
import { error } from "console";
import { useAuthStore } from "../../stores/stores";
import { exportExcelFile, getAllStudentAccount } from "../../service/list-account-student/listAccountStudent";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string
}

const AccountRegistrationReport = (props: Props) => {
  const { title } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [isPending, startTransition] = useTransition()
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [typeSelect, setTypeSelect] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statAmount, setStatAmount] = useState<any>();
  const [dataStatAccount, setDataStatAccount] = useState<any[]>([])
  const [statusOrder, setStatusOrder] = useState<string>("desc");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("created_date")
  const [accountUsers, setAccountUsers] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [exportMode, setExportMode] = useState<boolean>(false)
  const [customSort, setCustomSort] = useState<string>("desc");
  const navigate = useNavigate();

  const menu = [
    {
      key: "1",
      label: "Thêm chương từ mẫu",
      // icon: <RedoOutlined />,
    },
    {
      key: "2",
      label: "Tạo bản sao chương",
      // icon: <RedoOutlined />,
    },
  ]

  const [columns, setColumns] = useState<any>([])

  const [filterData, setFilterData] = useState<any>({
    universityId: null,
    sourceRegister: null,
    approvedDate: null,
  });

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
    setCustomSort(data)
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleOpen = () => {
    // setIsOpenDrawer(true)
    // setIsEdit(false)
    // setIsTitleEdit(false)
  }

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    // if (e.key === "Enter") {
    //   setLoadingContent(true)
    //   getListStudentCourse({
    //     keyword: searchValue,
    //     size: pageSize,
    //     page: totalPage,
    //     courseId: id
    //   }).then(res => {
    //     const { data } = res
    //     setDataStudents(data.content)
    //     setLoadingContent(false)
    //   }).catch(err => console.error(err))
    // }
  };

  const handleOpenModal = () => {
    // setIsOpenedImport(true)
  }

  const handleClose = () => {
    // setIsOpenDrawer(false)
    // form.resetFields()
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("")
    }
  };

  useEffect(() => {
    handleGetAmountAccountRegister();
  }, []);

  const handleGetAmountAccountRegister = async () => {
    await getAmountRegister()
      .then((res) => {
        setStatAmount(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/error/403");
        } else {
          console.error(err);
        }
      }
    );
  };

  const exportExcel = (fileData: any, msg: string) => {
    const blob = new Blob([fileData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);

    downloadLink.download = msg;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    message.success("Tải xuống thành công!")
  }

  const handleSubmitSearch = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: !statusOrder || [4, 5, 8].includes(typeSelect) ? [] : [`${fieldSortOrder},${statusOrder}`],
      keyword: searchValue.trim() || undefined,
      universityId: filterData.universityId ? Number(filterData.universityId) : null,
      sourceRegister: filterData.sourceRegister,
      typeAccountStudent: typeSelect,
      createdDateFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      createdDateTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      ekycStatus: filterData.status ? Number(filterData.status) : null,
      customSort: customSort,
    };

    if (exportMode) {
      await exportExcelFile(data).then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          const fileData = res.data;
          exportExcel(fileData, "Kết cáo tài khoản sinh viên.xlsx")
        }
      })
    } else {
      await getStatisticalRegisterFilter(data)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data
            setAccountUsers(data.data)
            setTotalRecords(data.total);

            setTimeout(() => {
              setIsLoading(false);
            }, 500);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
    setExportMode(false)

  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      universityId: null,
      sourceRegister: null,
      approvedDate: null,
    });
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const renderStatus = (value: number) => {
    switch (value) {
      case 0:
        return <p>Chưa xác thực</p>
        break;
      case 1:
        return <p>Chờ duyệt</p>
        break;
      case 2:
        return <p>Hợp lệ</p>
        break;
      case 3:
        return <p>Không hợp lệ</p>
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (typeSelect === 4 || typeSelect === 5 || typeSelect === 8) {
      const newColums = [
        {
          title: "STT",
          dataIndex: "numericalOrder",
          key: "numericalOrder",
          width: 10,
          render: (_: any, record: any, index: number) => {
            return <div>{record?.numericalOrder}</div>;
          },
        },
        {
          title:
            typeSelect === 4
              ? "Số thành công"
              : typeSelect === 5
                ? "Định danh giấy tờ cá nhân"
                : "Tự đăng ký",
          dataIndex: "name",
          key: "name",
          width: 10,
          sorter: true,
          children: [
            {
              title: 'Số tài khoản',
              dataIndex: typeSelect === 4 ? 'successCount' : (typeSelect === 5 ? "successIdentificationCount" : "selfRegistrationCount"),
              key: typeSelect === 4 ? 'successCount' : (typeSelect === 5 ? "successIdentificationCount" : "selfRegistrationCount"),
              width: 20,
            },
            {
              title: 'Tỉ lệ',
              dataIndex: typeSelect === 4 ? 'successPercentage' : (typeSelect === 5 ? "successIdentificationPercentage" : "selfRegistrationPercentage"),
              key: typeSelect === 4 ? 'successPercentage' : (typeSelect === 5 ? "successIdentificationPercentage" : "selfRegistrationPercentage"),
              width: 20,
            },
          ],
        },
        {
          title:
            typeSelect === 4
              ? "Số thất bại"
              : typeSelect === 5
                ? "Định danh khuôn mặt"
                : "Trường đăng ký",
          dataIndex: "name",
          key: "name",
          width: 10,
          children: [
            {
              title: 'Số tài khoản',
              dataIndex: typeSelect === 4 ? 'failCount' : (typeSelect === 5 ? "successFaceCount" : "universityRegistrationCount"),
              key: typeSelect === 4 ? 'failCount' : (typeSelect === 5 ? "successFaceCount" : "universityRegistrationCount"),
              width: 20,
            },
            {
              title: 'Tỉ lệ',
              dataIndex: typeSelect === 4 ? 'failPercentage' : (typeSelect === 5 ? "successFaceCPercentage" : "universityRegistrationPercentage"),
              key: typeSelect === 4 ? 'failPercentage' : (typeSelect === 5 ? "successFaceCPercentage" : "universityRegistrationPercentage"),
              width: 20,
            },
          ],
        },
        ...(typeSelect === 4
          ? [
            {
              title: "Tổng số",
              dataIndex: "total",
              key: "total",
              width: 14,
            },
          ]
          : []),
        ...(typeSelect === 8
          ? [
            {
              title: "Tổng số tài khoản đã đinh danh",
              dataIndex: "total",
              key: "total",
              width: 20,
            },
          ]
          : []),
        {
          title: "Ngày tạo",
          dataIndex: "created_date",
          key: "created_date",
          width: 20,
          render: (_: any, record: any) => {
            return (
              <>{record?.created_date ? formatOriginTimev3(record?.created_date) : null}</>
            );
          },
        },
      ]
      setColumns(newColums)
    }
    else {
      const newColums = [
        {
          title: "STT",
          dataIndex: "numericalOrder",
          key: "numericalOrder",
          width: 10,
          render: (_: any, record: any, index: number) => {
            return <div>{record?.numericalOrder}</div>;
          },
        },
        {
          title: "Tên đăng nhập",
          dataIndex: "username",
          key: "username",
          sorter: true,
          width: 14,
        },
        {
          title: "Họ và tên người dùng",
          dataIndex: "name",
          key: "name",
          width: 14,
        },
        {
          title: "Trường đại học",
          dataIndex: "universityName",
          key: "universityName",
          width: 14,
        },
        ...(typeSelect === 6 || typeSelect === 7
          ? [
            {
              title: "Mô tả",
              dataIndex: "approvedDescription",
              key: "approvedDescription",
              width: 14,
            },
            {
              title: "Ngày duyệt",
              dataIndex: "approvedDate",
              key: "approvedDate",
              width: 14,
              render: (_: any, record: any) => {
                return (
                  <>
                    {record?.approvedDate ? formatOriginTimev3(record?.approvedDate) : null}
                  </>
                );
              },
            },
          ]
          : typeSelect === 9
            ? [
              {
                title: "Số giấy tờ cá nhân",
                dataIndex: "cardId",
                key: "cardId",
                width: 10,
              },
              {
                title: "Trạng thái eKYC",
                dataIndex: "",
                key: "name",
                width: 10,
                render: (_: any, record: any) => {
                  return (
                    <>
                      <div className="flex items-center gap-2">
                        <div className="table-status">
                          <div
                            className="color-icon"
                            style={{
                              borderColor:
                                record.ekycStatus === 0
                                  ? "gray"
                                  : record.ekycStatus === 1
                                    ? "blue" : record.ekycStatus === 2
                                      ? "green" : "red",
                              color: record.ekycStatus === 0
                                ? "gray"
                                : record.ekycStatus === 1
                                  ? "blue" : record.ekycStatus === 2
                                    ? "green" : "red",
                            }}
                          ></div></div>
                        {renderStatus(record?.ekycStatus)}
                      </div>
                    </>
                  );
                },
              },
            ]
            : [
              {
                title: "Nguồn đăng ký",
                dataIndex: "sourceRegister",
                key: "sourceRegister",
                width: 14,
                render: (_: any, record: any) => {
                  return (
                    <div>
                      {
                        !record?.sourceRegister ? null :
                          <div className="flex items-center gap-2">
                            <div className="table-status">
                              <div
                                className="color-icon"
                                style={{
                                  backgroundColor:
                                    record.sourceRegister === 1
                                      ? "lightgray"
                                      : "blue",
                                }}
                              >
                              </div>
                            </div>
                            {Number(record?.sourceRegister) === 1 ? "Tự đăng ký" : "Trường đăng ký"}
                          </div>
                      }
                    </div>
                  );
                },
              },
              {
                title: "Trạng thái",
                dataIndex: "status",
                key: "status",
                width: 14,
                render: (_: any, record: any) => {
                  return (
                    <div className="flex items-center gap-2">
                      <div className="table-status">
                        <div
                          className="color-icon"
                          style={{
                            backgroundColor:
                              record.status
                                ? "#24b524"
                                : "red",
                          }}
                        ></div>
                      </div>
                      {record?.status ? "Thành công" : "Thất bại"}
                    </div>
                  );
                },
              },
            ]),
        {
          title: "Ngày tạo",
          dataIndex: "createdDate",
          key: "createdDate",
          width: 20,
          render: (_: any, record: any) => {
            return (
              <>{record?.createdDate ? formatOriginTimev3(record?.createdDate) : formatOriginTimev3(record?.dateJoined)}</>
            );
          },
        },
        {
          title: "",
          dataIndex: "action",
          fixed: "right",
          key: "operation",
          render: (_: any, record: any) => {
            return (
              <>
                <Space>
                  <Dropdown
                    menu={{
                      items: menu,
                      onClick: ({ key }) => {
                        if (key === '1') { }
                      },
                    }}
                    placement="bottomRight"
                  >
                    <Button>
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Space >
              </>
            );
          },
          width: 10,
        }
      ]
      setColumns(newColums)

    }
    handleSubmitSearch()

  }, [typeSelect, statusOrder, exportMode, pageNumber, pageSize]);

  const handleSetTypeSelect = (typeSelect: any) => {
    if ([6, 7, 9].includes(typeSelect)) {
      setFieldSortOrder("dateJoined")
    } else {
      setFieldSortOrder("created_date")
    }
    setTypeSelect(typeSelect)
  }

  return (
    <Layout className="page-header-group">
      <div className="wrapper-settings-history account-registration-report">
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "createStudent") {
              // onFinish(values)
            }
            if (name === "filterForm") {
              handleSubmitSearch()
            }
          }}
        >
          <div className={`page-header-group--layout`}>
            <div className="table-header--wrapper">
              <div className="table-header--left">
                <PageHeader title={title} />
              </div>
            </div>
          </div>

          <section className="flex gap-5 m-[15px]">
            <div className='rounded-md bg-white basis-1/3 p-4 '>
              <p>Tổng số</p>
              <p className='text-4xl mt-1'>{statAmount?.total}</p>
              <p className='mt-1 font-semibold text-1xl'>Đơn vị: Tài khoản</p>
            </div>
            <div className='rounded-md bg-white basis-1/3 p-4'>
              <p>Thành công</p>
              <p className='text-green-400 text-4xl mt-1'>{statAmount?.success}</p>
              <p className='mt-1 font-semibold text-1xl'>Đơn vị: Tài khoản</p>
            </div>
            <div className='rounded-md bg-white basis-1/3 p-4'>
              <p>Thất bại</p>
              <p className='text-red-400 text-4xl mt-1'>{statAmount?.fail}</p>
              <p className='mt-1 font-semibold text-1xl'>Đơn vị: Tài khoản</p>
            </div>
          </section>

          <section className="history-page">
            <div className="history-header">
              <Typography.Title className="history-title" style={{ margin: 0 }}>
                Danh sách tài khoản
              </Typography.Title>
              <FilterTable
                handleOpenFilter={handleOpenFilter}
                showFilter={showFilter}
                searchValue={searchValue}
                handleSearch={handleSearch}
                handleOpen={handleOpen}
                handleOpenModal={handleOpenModal}
                handleChangeInput={handleChangeInput}
                handleSubmitSearch={handleSubmitSearch}
                setTypeSelect={handleSetTypeSelect}
                setExportMode={setExportMode}
              />
            </div>
            <div className="filter-class--register">
              <FormTable
                handleCloseFilter={handleCloseFilter}
                clearFilter={clearFilter}
                showFilter={showFilter}
                filterData={filterData}
                setFilterData={handleChangeFilter}
              />
            </div>

            <div className="tabs-heading">
              <Spin spinning={isLoading}>
                <TableData
                  setPageNumber={setPageNumber}
                  setPageSize={setPageSize}
                  dataTable={accountUsers}
                  dataColumns={columns}
                  pageNumber={pageNumber}
                  totalRecords={totalRecords}
                  pageSize={pageSize}
                  pagination={true}
                  loadingTable={isPending}
                  scroll={{ x: 1500, y: 600 }}
                  handleTableChangeProps={handleTableChange}
                />
              </Spin>
            </div>
          </section>

        </Form.Provider>
      </div>
    </Layout>
  )
}

export default AccountRegistrationReport;
