import { AxiosResponse } from "axios"
import { axiosConfigV2 } from "../../../config/api/configApiv2"

export const getMoocOrganizationById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/auth-organization-management/organization/${id}`)
}
export const getAllOrganizationByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/auth-organization-management/organization/get-all-by-filter`, data)
}

export const addMoocOrganization: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/auth-organization-management/organization`, data)
}

export const updateMoocOrganization: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`auth-organization-management/organization`, data)
}

export const deleteMoocOrganization: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/auth-organization-management/organization/${id}`)
}
