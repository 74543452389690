import { Button, Col, Collapse, Drawer, Form, message, Radio, RadioChangeEvent, Row, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { isEmpty, size } from 'lodash'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import "./index.scss"
import { postEditPermissionQuestion } from '../../../../../service/library/question-list'

type Props = {
  open: boolean
  onClose: () => void
  detailData: any
  getListData: () => void
}

export default function EditPermissionQuestion(props: Props) {
  const { open, onClose, detailData, getListData } = props
  const [form] = Form.useForm()

  const [availableStatus, setAvailableStatus] = useState<any>(0);

  useEffect(() => {
    if (open) {
      // getListDataDetail();
    }
  }, [open]);

  const radioItemList = [
    {
      value: 0,
      title: "Công khai",
      describe: "Nội dung này cần Cấp bộ kiểm duyệt trước khi công khai",
    },
    {
      value: 1,
      title: "Nội bộ",
      describe: "Nội dung này cần QTCS kiểm duyệt trước khi công khai nội bộ",
    },
    {
      value: 2,
      title: "Giới hạn",
      describe: "Chỉ hiển thị nội dung với đối tượng được chia sẻ",
    },
  ];

  const handleRadioChange = (e: RadioChangeEvent) => {
    setAvailableStatus(e.target.value);
  };

  const clearData = () => {
    setAvailableStatus(0);
    onClose()
  };

  useEffect(() => {
    console.log('12313', detailData)
    if(detailData?.access) {
      setAvailableStatus(detailData?.access === 'Công khai' ? 0 : (detailData?.access === 'Nội bộ' ? 1 : (detailData?.access === 'Giới hạn' ? 2 : null)))
    }
  }, [detailData])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === "form-detail-edit-permission") {
        const data = {
          access: availableStatus === 0 ? 'Công khai' : (availableStatus === 1 ? 'Nội bộ' : 'Giới hạn'),
          bankId: detailData?.id
        }
        await postEditPermissionQuestion(data).then((res) => {
          if (res.status === 200) {
            message.success("Lưu thông tin thành công")
            clearData()
            getListData()
          }
        })
      }
    }}>
      <Drawer
        title={"Sửa quyền truy cập"}
        open={open}
        onClose={clearData}
        className='detail-edit-permission-drawer'
        width={736}
        bodyStyle={{ padding: "0px" }}
        extra={
          <Space>
            <Button onClick={clearData}>
              <CloseOutlined />
              Hủy
            </Button>
            <Button onClick={() => form.submit()} className='btn btn-primary'>
              <SaveOutlined />
              Gửi yêu cầu
            </Button>
          </Space>
        }
      >
        <Form
          className="detail-edit-permission"
          layout={"vertical"}
          form={form}
          name="form-detail-edit-permission"
        >
          <div className='mt-5 ml-7 mr-7'>
            <Typography.Title level={5}>Quyền truy cập</Typography.Title>
            <Radio.Group value={availableStatus}>
              {radioItemList.map((item, index) => (
                <div key={index} className="radio-item wrapper-radio">
                  <Radio value={item.value} onChange={handleRadioChange}>
                    <Typography.Title
                      className="setting-chapter-title"
                      level={5}
                    >
                      {item.title}
                    </Typography.Title>
                  </Radio>
                  <div className="setting-chapter-description">
                    {item.describe}
                  </div>
                </div>
              ))}
            </Radio.Group>
          </div>
        </Form>
      </Drawer>
    </Form.Provider >
  )
}
