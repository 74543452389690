import { Radio, Typography, Upload } from "antd";
import { AnswersPros } from "./survey-answers-content";
import { convertQuillToString } from "../../../../../../../utils/convertQuillToString";
import { isEmpty } from "lodash";
import ReactPlayer from "react-player";

const ChooseVideo = (props: AnswersPros) => {
  const { question } = props;
  return <div className='survey-answers-content__wrapper'>
    <div className='survey-answers-content__title'>
      <Typography.Title level={3}>{convertQuillToString(question.question)}</Typography.Title>
    </div>
    <div className='survey-answers-content__body'>
      {question.answers.map((x: any, i: number) => {
        return <div key={i + 1} className='survey-answers-content__body__item'>
          <div className='item__action'>
            <Radio checked={x.isTrue} disabled></Radio>
          </div>
          <div className='item__action'>
            <Upload
              listType='picture-card'
              className='item__action__uploader'
              showUploadList={false}
              disabled
            >
              {
                !isEmpty(x.filePath) ? (
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={x.filePath}
                    controls={true}
                  />
                ) : (
                  <span className='empty'>Không có video</span>
                )
              }
            </Upload>
          </div>
          <div className='item__action'>
            {convertQuillToString(x.content)}
          </div>
        </div>
      })}
    </div>
  </div>
}

export default ChooseVideo;