import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";
import { CoursePayload } from "../../types/course";
import { axiosConfigV2OpenAPI } from "../../config/api/configOpenApiV2";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigAI } from "../../config/api/axiosConfigAI";

export const getResultsQuiz: (
  sessionId: string
) => Promise<AxiosResponse<any>> = (sessionId) => {
  return axiosConfigV2.get(`/course/structure/exam/result/${sessionId}`);
};

export const getCoursesBlockQuiz: (
  payload: CoursePayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2OpenAPI.post(`/course/get-course-by-filter`, payload);
};

export const getIndustryFilter: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2OpenAPI.post(`/course/get-industry-filter`, payload);
};

export const getUniversityFilter: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2OpenAPI.post(`/course/get-university-filter`, payload);
};

export const getGroupIndustry: (
  keyword?: string
) => Promise<AxiosResponse<any>> = (keyword) => {
  return axiosConfigV2OpenAPI.get(`/course/get-all-group-industry?keyword=${keyword}`);
};

export const exportExcelCourse: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2OpenAPI.post(`/course/export-excel`, payload, {
    responseType: "arraybuffer",
  });
};

export const getCourseDetail: (id?: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2OpenAPI.get(`/course/${id}`);
};

export const getCourseName: (id?: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2OpenAPI.get(`/course/get-name/${id}`);
};

export const getCourseRating: (id?: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2OpenAPI.get(`/comment/course/count?courseId=${id}`);
};

export const exportCourseReview: (
  id: number,
  params: any
) => Promise<AxiosResponse<any>> = (id: number, payload: any) => {
  return axiosConfigV2OpenAPI.post(`/comment/course/${id}/export-excel`, payload, {
    responseType: "arraybuffer",
  }
  );
};

export const getCourseReviewsList: (
  id: number,
  params: any
) => Promise<AxiosResponse<any>> = (id: number, payload: any) => {
  return axiosConfigV2OpenAPI.get(`/comment/course/${id}?${payload}`);
};

export const addCourseComment: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2.post(`/course-review/comment`, payload);
};

export const editCourseComment: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2.put(`/course-review/edit-comment`, payload);
};

export const deleteCourseComment: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.delete(`/course-review/delete`, { data });
};

export const getCourseRecommend: (id: number) => Promise<AxiosResponse<any>> = (
  id: number
) => {
  return axiosConfigV2OpenAPI.get(`/course/get-similar-course/${id}`);
};

export const getPopularTopics: (payload?: CoursePayload) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2OpenAPI.post(`/course/get-all-industry`, payload);
};

export const postCourseEnrollment: (payload?: any) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2.post(`/my-registration/register-a-course`, payload);
};

export const getCoursesFeatured: (featuredUuid?: string | number) => Promise<AxiosResponse<any>> = (featuredUuid) => {
  return axiosConfigV2OpenAPI.post(`/course/get-featured-course`, {
    featuredUuid
  });
};

export const getCoursesToStart: (university?: string | number) => Promise<AxiosResponse<any>> = (university) => {
  return axiosConfigAI.get(`/student-favorite/get-suggest-course?pageIndex=1&pageSize=6&university=${university}`);
};

export const getCoefficientByCourse: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-course-coefficient/get-coefficient-by-course/${id}`);
};

export const createOrUpdate: (payload?: any) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2.post(`/mooc-course-coefficient/create-or-update`, payload);
};