import { useEffect } from "react";
import "./add-survy-core.scss";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useAddLesson } from "../../../../stores/stores";
import QuestionItem from "../../../../components/course-construction/question-item";
import CustomCard from "../../../../components/custom-card/CustomCard";
import SurvyList from "./survey-list/survey-list";
import SurvySetting from "./setting-question-course/setting-question-course";

type AddSurvyCourseProps = {
  activeHandleSave: () => void;
};

const AddSurvyCourse = (props: AddSurvyCourseProps) => {
  const navigate = useNavigate();
  const setQuestion = useAddLesson((state) => state.setQuestion);
  const question = useAddLesson((state) => state.question);
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);
  const setTypeQuestionChoose = useAddLesson((state) => state.setTypeQuestionChoose);

  useEffect(() => {
    setQuestion({});
    setModeQuestion("");
    setTypeQuestionChoose("");
  }, [navigate]);

  return (
    <div className="editor-course flex">
      <SurvyList activeHandleSave={props.activeHandleSave} />
      <CustomCard className="card-info--wrapper flex-1">
        <div className="list-wrapper">
          {question ? (<QuestionItem />) : <>Xin vui lòng chọn câu hỏi</>}
        </div>
      </CustomCard>
      <SurvySetting />
    </div>
  );
};

export default AddSurvyCourse;
