import create from "zustand";
import { StateCreator } from "zustand";
import { PersonalInfoProps } from "../../types/person";

export interface globalState {
  isFooterDefault: boolean;
  schoolId: string;
  reload: boolean;
  reloadAdminInterface: boolean;
  isFullscreenQuiz: boolean;
  openLoading: any;
  percentageProcessApi: number;
  personalInfo: PersonalInfoProps | {};
  setFooterDefault: () => void;
  setSchoolId: (id: string) => void;
  setReload: (data: boolean) => void;
  setIsFullscreenQuiz: (data: boolean) => void;
  setOpenLoading: (data: any) => void;
  setPercentageProcessApi: (data: any) => void;
}

export const globalSlice: StateCreator<globalState> = (set) => ({
  isFooterDefault: true,
  schoolId: "",
  reload: true,
  reloadAdminInterface: false,
  isFullscreenQuiz: false,
  openLoading: {},
  percentageProcessApi: 0,
  personalInfo: {},

  setPersonalInfo: (info: PersonalInfoProps) => {
    set(() => ({ personalInfo: info }));
  },
  setFooterDefault: () => set((state) => ({ isFooterDefault: false })),
  setSchoolId: (state: string) => {
    set(() => ({ schoolId: state }));
  },
  setReload: (value: boolean) => {
    set({ reload: value });
  },
  setIsFullscreenQuiz: (value: boolean) => {
    set({ isFullscreenQuiz: value });
  },
  setOpenLoading: (value: any) => {
    set({ openLoading: value });
  },
  setPercentageProcessApi: (value: any) => {
    set({ percentageProcessApi: value });
  },
  setReloadAdminInterface: (value: boolean) => {
    set({ reloadAdminInterface: value });
  },
});
