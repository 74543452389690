import {Button, Col, DatePicker, Form, FormInstance, Row, Typography} from 'antd';
import React from 'react';
import FormItemDropdown from '../../../../form-dropdown/FormItemDropdown';
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import {FilterData} from "./filter";
import {get} from 'lodash';

type FilterGroupTypes = {
  form: FormInstance,
  showFilter: boolean,
  onFinish?: any;
  filterData: FilterData
  clearFilter: () => void;
  dataOptions?: any;
  typeQuestion?: boolean
}


const publishStatus = [
  {
    label: 'Bản nháp',
    value: '0'
  },
  {
    label: 'Chờ QTCS duyệt',
    value: '1'
  },
  {
    label: 'Chờ cấp bộ duyệt',
    value: '2'
  },
  {
    label: 'QTCS không duyệt',
    value: '3'
  },
  {
    label: 'Cấp Bộ không duyệt',
    value: '4'
  },
  {
    label: 'Đã lên lịch',
    value: '5'
  },
  {
    label: 'Đã xuất bản',
    value: '6'
  },
  {
    label: 'Chưa xuất bản',
    value: '7'
  },
  {
    label: 'Đề xuất chỉnh sửa',
    value: '8'
  }
]


const FilterGroup = ({
  showFilter,
  form,
  filterData,
  dataOptions,
  onFinish,
  clearFilter,
  typeQuestion
}: FilterGroupTypes) => {
  const { RangePicker } = DatePicker;
  return (
    <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
      <Form form={form} name="filterForm" onFinish={onFinish}>
        <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
          <Col
            xl={{span: 4}}
            md={{span: 5}}
            sm={{span: 5}}
            xs={{span: 24}}
          >
            <Typography.Title level={5}>Chủ đề</Typography.Title>
            <Form.Item name="topic">
              <FormItemDropdown
                modeDropdown="multiple"
                placeholder="Chọn chủ đề"
                options={dataOptions.dataTopic}
              />
            </Form.Item>
          </Col>
          {
            !typeQuestion ?
              <>
                <Col
                  xl={{span: 4}}
                  md={{span: 5}}
                  sm={{span: 5}}
                  xs={{span: 24}}
                >
                  <Typography.Title level={5}>Quyền truy cập</Typography.Title>
                  <Form.Item name="permission">
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Chọn quyền truy cập"
                      options={dataOptions.dataPermission}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={{span: 4}}
                  md={{span: 5}}
                  sm={{span: 5}}
                  xs={{span: 24}}
                >
                  <Typography.Title level={5}>Ngày cập nhật</Typography.Title>
                  <Form.Item name="updatedDate">
                    <RangePicker
                      placeholder={["Bắt đầu", "Kết thúc"]}
                      format="DD/MM/YYYY"
                      value={filterData.updatedDate}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={{span: 4}}
                  md={{span: 5}}
                  sm={{span: 5}}
                  xs={{span: 24}}
                >
                  <Typography.Title level={5}>Tình trạng</Typography.Title>
                  <Form.Item name="status">
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Chọn tình trạng"
                      options={publishStatus}
                    />
                  </Form.Item>
                </Col>
              </>
              :
              <>
                <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
                  <Typography.Title level={5}>Phân cấp</Typography.Title>
                  <Form.Item name="level">
                    <FormItemDropdown
                      modeDropdown='multiple'
                      placeholder="Chọn phân cấp"
                      options={get(dataOptions, 'dataLevel')}
                    />
                  </Form.Item>
                </Col>
                <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
                  <Typography.Title level={5}>Đơn vị xuất bản</Typography.Title>
                  <Form.Item name="unit">
                    <FormItemDropdown modeDropdown="multiple" placeholder="Chọn đơn vị xuất bản" options={get(dataOptions, 'dataUnit')}/>
                  </Form.Item>
                </Col>
                <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
                  <Typography.Title level={5}>Thời gian</Typography.Title>
                  <Form.Item name="time">
                    <DatePicker.RangePicker allowEmpty={[true, true]} placeholder={["Từ ngày", "Đến ngày"]}
                                            format='DD/MM/YYYY'/>
                  </Form.Item>
                </Col>
              </>
          }
          <Col
            xl={{span: 3}}
            md={{span: 9}}
            sm={{span: 9}}
            xs={{span: 24}}
            className="flex align-end"
          >
            <div className="group-btn--filter">
              <Button
                className="btn btn-primary filter-btn"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <FilterOutlined/>
                <Typography.Text>Lọc</Typography.Text>
              </Button>
              <Button
                className="btn btn-outlined clear-filter-btn"
                onClick={clearFilter}
              >
                <DeleteOutlined/>
                <Typography.Text>Xóa bộ lọc</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterGroup;