import React, {FunctionComponent} from "react";
import {Button, Col, DatePicker, Form, Row, Typography} from "antd";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import "./filter-group.scss";
import {dataStatus} from "../../constants/data";
import {FormInstance} from "antd/lib";

type FormFilterProps = {
  onFinish?: any;
  showFilter?: boolean;
  clearFilter: () => void;
  setDataFilter: (trangThai: any, ngayTao: string) => void;
  filterData: any
  form: FormInstance
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
                                                          onFinish,
                                                          showFilter,
                                                          clearFilter,
                                                          filterData,
                                                          setDataFilter,
                                                          form,
                                                        }) => {
  const {RangePicker} = DatePicker;
  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form name="formFilterRole" form={form} onFinish={onFinish}>
          <Row gutter={24}>
            {/* <Col span={6}>
              <Form.Item name="quyens">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn quyền hạn"
                                  options={get(dataOptions, 'dataPermissions')}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="nguoiDungs">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn người dùng"
                                  options={get(dataOptions, 'dataUsers')}/>
              </Form.Item>
            </Col> */}
            <Col span={6}>
              <Form.Item name="trangThai">
                <Typography.Title level={5}>Trạng thái</Typography.Title>
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Chọn trạng thái"
                  options={dataStatus}
                  onChange={(option) => {
                    setDataFilter(option, "trangThai");
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Typography.Title level={5}>Thời gian</Typography.Title>
              <Form.Item name="rangeDate">
                <RangePicker
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format="DD/MM/YYYY"
                  value={filterData.rangeDate}
                  onChange={(option) => {
                    setDataFilter(option, "rangeDate");
                  }}
                  // onChange={(_value, dateString: any) => {
                  //   const convertedDate = [
                  //     dateString[0].toISOString(),
                  //     dateString[1].toISOString(),
                  //   ];
                  //   console.log("dateString", convertedDate);
                  //   setDataFilter(convertedDate, "ngayTao");
                  // }}
                />
              </Form.Item>
            </Col>
            <Col span={6} className="flex items-end">
              <Row gutter={8}>
                <Col>
                  <Button className="btn btn-primary" htmlType="submit">
                    <FilterOutlined/>
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="btn btn-outlined"
                    onClick={clearFilter}
                  >
                    <DeleteOutlined/>
                    <Typography.Text>Xóa bộ lọc</Typography.Text>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
