import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, Space, TableColumnType, Typography } from 'antd';

import FormInput from '../../../form-input/FormInput';
import './filter-table.scss'
import { DownOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { DataType } from '../../../../types/assign-course-construction';
import { CheckboxOptionType } from "antd/lib";
import AddTemplateCourse from '../../../modal/add-template-course';
import CopyTemplateCourse from '../../../modal/copy-template-course';
import AddTemplateSectionCourse from '../../../modal/add-template-sequence-course';
import CopyTemplateSequenceCourse from '../../../modal/copy-template-sequence-course';
import { useParams } from 'react-router-dom';
import CopyTemplateUnitCourse from '../../../modal/copy-template-unit-course';

type FilterTableProps = {
  handleOpen: () => void,
  getListData: () => void,
  searchValue: string,
  tab: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  checkedList: Array<string>,
  setCheckedList: (value: string[]) => void,
  dataColumns: TableColumnType<DataType>[],
  selectedRowKeys: React.Key[],
  filterData: any
}


const FilterTableCourseConstruction: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  tab,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  selectedRowKeys,
  filterData,
  getListData
}) => {
  //thêm chương từ chương mẫu

  const [isOpenAddTemplateSequenceModal, setIsOpenAddTemplateSequenceModal] = useState(false)
  const [isOpenCopySequenceTemplateModal, setIsOpenCopySequenceTemplateModal] = useState(false)

  //thêm bài giảng từ bài giảng mẫu mẫu
  const [isOpenAddTemplateModal, setIsOpenAddTemplateModal] = useState(false)
  const [isOpenCopyTemplateModal, setIsOpenCopyTemplateModal] = useState(false)
  const [isOpenCopyUnitTemplateModal, setIsOpenCopyUnitTemplateModal] = useState(false)
  const [option, setOption] = useState<any>([])

  const params = useParams()

  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key
  }))


  const items: MenuProps['items'] = [
    {
      label: (
        <Checkbox.Group options={options as CheckboxOptionType[]} value={checkedList} onChange={(value) => {
          setCheckedList(value as string[])
        }} />

      ),
      key: 'radio',
    }
  ]

  const menuProps = {
    items
  }

  const options2: MenuProps["items"] = [
    {
      key: "1",
      label: "Thêm bài giảng từ mẫu",
      // icon: <RedoOutlined />,
    },
    {
      key: "2",
      label: "Tạo bản sao",
      // icon: <RedoOutlined />,
    },
  ];

  useEffect(() => {
    if (tab === '1') {
      setOption([
        {
          key: "1",
          label: "Thêm chương từ mẫu",
          // icon: <RedoOutlined />,
        },
        {
          key: "2",
          label: "Tạo bản sao chương",
          // icon: <RedoOutlined />,
        },
      ])
    }
    if (tab === '2') {
      setOption([
        {
          key: "1",
          label: "Thêm bài giảng từ mẫu",
          // icon: <RedoOutlined />,
        },
        {
          key: "2",
          label: "Tạo bản sao bài giảng",
          // icon: <RedoOutlined />,
        },
      ])
    }
    if (tab === '3') {
      setOption([
        {
          key: "1",
          label: "Tạo bản sao học liệu",
          // icon: <RedoOutlined />,
        },
      ])
    }
    if (tab === '4') {
      setOption([])
    }
    if (tab === '5') {
      setOption([
        {
          key: "1",
          label: "Tạo bản sao học liệu",
          // icon: <RedoOutlined />,
        },
      ])
    }
  }, [tab])

  return (
    <>
      <div className="filter-block">
        <Row gutter={8} className='w-full'>
          <Col
            md={{ span: `${['1', '4', '5', '3', '6'].includes(tab) ? 9 : (tab === '3' ? 11 : 6)}` }}
            lg={{ span: `${['1', '4', '5', '3', '6'].includes(tab) ? 9 : (tab === '3' ? 11 : 6)}` }}
            xl={{ span: `${['1', '4', '5', '3', '6'].includes(tab) ? 9 : (tab === '3' ? 11 : 6)}` }}
            xs={{ span: `${['1', '4', '5', '3', '6'].includes(tab) ? 9 : (tab === '3' ? 11 : 6)}` }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>
                  Tìm kiếm nâng cao
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col
            md={{ span: 10 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
            xs={{ span: 10 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khoá cần tìm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          {
            tab === '2' ?
              <Col
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xs={{ span: 8 }}
              >
                <div className="heading-resigter">
                  <Button onClick={handleOpen} className="btn btn-primary pl-3">
                    <Typography.Text> <SyncOutlined /> Đồng bộ nội dung bài giảng</Typography.Text>
                  </Button>
                </div>
              </Col> : null
          }

          <Col
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            xs={{ span: 3 }}
          >
            <div className="heading-resigter">
              <Space>
                <Dropdown
                  menu={{
                    items: option,
                    onClick: ({ key }) => {
                      if (tab === '1' && key === '1') {
                        setIsOpenAddTemplateSequenceModal(true)
                      }
                      if (tab === '1' && key === '2') {
                        setIsOpenCopySequenceTemplateModal(true)
                      }
                      if (tab === '2' && key === '1') {
                        setIsOpenAddTemplateModal(true)
                      }
                      if (tab === '2' && key === '2') {
                        setIsOpenCopyTemplateModal(true)
                      }
                      if (tab === '3' && key === '1') {
                        setIsOpenCopyUnitTemplateModal(true)
                      }
                      if (tab === '5' && key === '1') {
                        setIsOpenCopyUnitTemplateModal(true)
                      }
                    },
                  }}
                  placement="bottomRight"
                >
                  <Button>
                    Thao tác <DownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </div>
          </Col>
        </Row>
        {/* thêm chương từ chương mẫu modal */}
        <AddTemplateSectionCourse
          open={isOpenAddTemplateSequenceModal}
          handleClose={() => setIsOpenAddTemplateSequenceModal(false)}
          tab={tab}
          filterData={filterData}
          getListDataProps={getListData}
        />
        <CopyTemplateSequenceCourse
          open={isOpenCopySequenceTemplateModal}
          handleClose={() => setIsOpenCopySequenceTemplateModal(false)}
          tab={tab}
          searchValue={searchValue}
          filterData={filterData}
          getListDataProps={getListData}
        />

        {/* thêm bài giảng từ bài giảng mẫu modal */}
        <AddTemplateCourse
          open={isOpenAddTemplateModal}
          handleClose={() => setIsOpenAddTemplateModal(false)}
          tab={tab}
          filterData={filterData}
          getListDataProps={getListData}
        />
        <CopyTemplateCourse
          open={isOpenCopyTemplateModal}
          handleClose={() => setIsOpenCopyTemplateModal(false)}
          tab={tab}
          searchValue={searchValue}
          filterData={filterData}
          getListDataProps={getListData}
        />

        {/* bài giảng */}
        <CopyTemplateUnitCourse
          open={isOpenCopyUnitTemplateModal}
          handleClose={() => setIsOpenCopyUnitTemplateModal(false)}
          tab={tab}
          searchValue={searchValue}
          filterData={filterData}
          getListDataProps={getListData}
        />
      </div>
    </>
  );
};

export default FilterTableCourseConstruction;
