import React, {FunctionComponent, useEffect, useState} from "react";
import {Select} from "antd";
import "./form-item-dropdown.scss";
import {IDataOptions, IDataSelect, IDataSelectStatus,} from "../../types/listUsers";

type FormItemDropdownProps = {
  modeDropdown?: "multiple" | "tags";
  disabled?: boolean;
  allowClear?: boolean;
  loading?: boolean;
  placeholder?: string;
  style?: any;
  disabledOptions?: boolean
  options?: IDataSelect[] | IDataOptions[] | IDataSelectStatus[] | unknown[];
  defaultValue?: string | string[] | number | number[];
  onChange?: (values: any) => void;
  onPopupScroll?: (e: any) => void;
  value?: any;
  optionValue?: any;
  className?: string;
  priority?: string;
  onBlur?: (values: any) => void;
};

const locale = () => (
  <span>
    <p>
      <img
        className="image-empty-data"
        src="/images/empty-img-gray.png"
        alt="empty-img"
      ></img>
    </p>
    <p className="nodata-text">Không tìm thấy</p>
  </span>
);

const FormItemDropdown: FunctionComponent<FormItemDropdownProps> = ({
                                                                      modeDropdown,
                                                                      options,
                                                                      placeholder,
                                                                      disabled,
                                                                      allowClear,
                                                                      value,
                                                                      className,
                                                                      defaultValue,
                                                                      priority,
                                                                      onChange,
                                                                      onPopupScroll,
                                                                      onBlur,
                                                                      style,
                                                                      loading
                                                                    }) => {
  const [fetching, setFetching] = useState(false);
  const [optionsState, setOptionsState] = useState(options);
  const [optionsStateFilter, setOptionsStateFilter] = useState<any>([]);
  const debounceFetcher = (value: string) => {

  };

  const onSearch = (e: any) => {
    if (e.target.value.length === 0 && e.keyCode === 32) {
      e.preventDefault()
      return false
    }
  };

  useEffect(() => {
    if (options) {
      setOptionsState(options);
      setOptionsStateFilter(options);
    }
  }, [options]);
  
  return (
    <Select
      onChange={onChange}
      onBlur={onBlur}
      showSearch
      style={style}
      disabled={disabled}
      optionFilterProp="children"
      maxTagCount="responsive"
      allowClear={allowClear}
      value={value}
      placeholder={placeholder}
      mode={modeDropdown}
      onPopupScroll={onPopupScroll}
      virtual={false}
      loading={loading}
      defaultValue={defaultValue}
      notFoundContent={
        <span style={{textAlign: "center"}}>
          <p>
            <img
              className="image-empty-data"
              src="/images/empty-img-gray.png"
              alt="empty-img"
            ></img>
          </p>
          <p className="nodata-text">Không tìm thấy</p>
        </span>
      }
      className={className}
      // onSearch={(value: any) => onSearch(value)}
      onInputKeyDown={(value: any) => onSearch(value)}
    >
      {optionsStateFilter?.map((option: any, index: number) => (
        <Select.Option
          key={index || option.value || option.code || option.id}
          disabled={option.disabled ? option.disabled : false}
          value={
            typeof option.value === "boolean"
              ? option.value
              : priority
                ? option.code
                : option.id ||
                option.assignUserId ||
                option.enterpriseId ||
                option.code
          }
        >
          {option.nameEn ||
            option.name ||
            option.label ||
            option.ten ||
            option.email ||
            option.assignUserName ||
            option.enterpriseName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FormItemDropdown;
