import React, {useEffect, useMemo, useState, useTransition} from "react";
import {BarStackedChartProps, PieChart} from "../../../../../types/static-report";
import {staticReportStore} from "../../../../../stores/stores";
import dayjs from "dayjs";
import {
  getCourseByFormatUniversity,
  getCourseByIndustryGroup,
  getSynthesizeCourseUniversity,
  getUnitByIndustryGroup,
  getUniversityMultiMediaUnit,
  getUniversityReferenceSourceByType,
  getUniversityScormXapiUnit,
  getUniversityTestByType
} from "../../../../../service/statistical-report";
import ResourceChartTab from "../chart/resource-tab/ResourceChartTab";
import ResourceTable from "../table/resource-table";
import {debounce} from "lodash";
import {renderColBarStackedChart} from "../../../../../utils/staticReport";

type Props = {
  dataType: string
};

const ResourceTab = ({dataType}: Props) => {

  const {staticReportType, filterValues, tab, isSelectedUniversity} = staticReportStore();
  const [isPending, startTransition] = useTransition();
  const [chartData, setChartData] = useState({
    courseFormatUniversityList: [] as BarStackedChartProps[],
    courseFormatUniversityTotal: 0,
    courseByIndustry: [] as PieChart[],
    courseByIndustryTotal: 0,
    unitByIndustry: [] as PieChart[],
    unitByIndustryTotal: 0,
    universityByTestType: [] as PieChart[],
    universityByTestTypeTotal: 0,
    universityReferenceSource: [] as PieChart[],
    universityReferenceSourceTotal: 0,
    universityScormXapiUnit: [] as PieChart[],
    universityScormXapiUnitTotal: 0,
    universityMultiMediaUnit: [] as PieChart[],
    universityMultiMediaUnitTotal: 0,
    synthesizeCourseUniversity: [] as Array<any>,
    synthesizeCourseUniversityTotal: 0,
  });

  const [loading, setLoading] = useState({
    courseFormatUniversityList: false,
    courseByIndustry: false,
    unitByIndustry: false,
    universityByTestType: false,
    universityReferenceSource: false,
    universityScormXapiUnit: false,
    universityMultiMediaUnit: false,
  });

  const fetchDataChart = async () => {
    const payload = {
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: isSelectedUniversity ? filterValues?.universityIds : [],
      timeUnit: filterValues.timeUnit,
    };

    const listApi = [
      {
        key: 'courseFormatUniversityList',
        total: 'courseFormatUniversityTotal',
        api: getCourseByFormatUniversity,
        params: payload,
      },
      {
        key: 'courseByIndustry',
        total: 'courseByIndustryTotal',
        api: getCourseByIndustryGroup,
        params: payload,
      },
      {
        key: 'unitByIndustry',
        total: 'unitByIndustryTotal',
        api: getUnitByIndustryGroup,
        params: payload,
      },
      {
        key: 'universityByTestType',
        total: 'universityByTestTypeTotal',
        api: getUniversityTestByType,
        params: payload,
      },
      {
        key: 'universityReferenceSource',
        total: 'universityReferenceSourceTotal',
        api: getUniversityReferenceSourceByType,
        params: payload,
      },
      {
        key: 'universityScormXapiUnit',
        total: 'universityScormXapiUnitTotal',
        api: getUniversityScormXapiUnit,
        params: payload,
      },
      {
        key: 'universityMultiMediaUnit',
        total: 'universityMultiMediaUnitTotal',
        api: getUniversityMultiMediaUnit,
        params: payload,
      },
      {
        key: 'synthesizeCourseUniversity',
        total: 'synthesizeCourseUniversityTotal',
        api: getSynthesizeCourseUniversity,
        params: payload,
      },
    ]

    await Promise.allSettled(
      listApi.map(async ({key, total, api, params}) => {
        setLoading((prev) => ({...prev, [key]: true, [total]: true}));
        try {
          const response = await api(params);
          startTransition(() => {
            setChartData((prevState) => ({
              ...prevState,
              [key]: response?.data?.data?.statistic || response?.data?.data,
              [total] : response?.data?.data?.total ? response?.data?.data?.total : null,
            }));
          })
        } catch (error) {
          console.error(`Error fetching data for ${key}:`, error);
          startTransition(() => {
            setChartData(prevState => ({
              ...prevState,
              [key]: [], // Provide fallback empty data
              [total] : []
            }));
          });
        } finally {
          setLoading((prev) => ({...prev, [key]: false, [total]: false}));
        }
      })
    );
  }

  const debouncedFetchData = useMemo(
    () => debounce(fetchDataChart, 500),
    [filterValues]
  );

  useEffect(() => {
    if (tab === "2") {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  const memoizedChartData = useMemo(() => {
    const series = ["Thẻ ghi danh", "Tự do", "Riêng tư"];
    renderColBarStackedChart(series, chartData.courseFormatUniversityList);

    return {
      courseFormatUniversityList: chartData.courseFormatUniversityList,
      courseByIndustry: chartData.courseByIndustry,
      unitByIndustry: chartData.unitByIndustry,
      universityByTestType: chartData.universityByTestType,
      universityReferenceSource: chartData.universityReferenceSource,
      universityScormXapiUnit: chartData.universityScormXapiUnit,
      universityMultiMediaUnit: chartData.universityMultiMediaUnit,
      synthesizeCourseUniversity: chartData.synthesizeCourseUniversity,
      courseFormatUniversityTotal: chartData.courseFormatUniversityTotal,
      courseByIndustryTotal: chartData.courseByIndustryTotal,
      unitByIndustryTotal: chartData.unitByIndustryTotal,
      universityByTestTypeTotal: chartData.universityByTestTypeTotal,
      universityReferenceSourceTotal: chartData.universityReferenceSourceTotal,
      universityScormXapiUnitTotal: chartData.universityScormXapiUnitTotal,
      universityMultiMediaUnitTotal: chartData.universityMultiMediaUnitTotal,
      synthesizeCourseUniversityTotal: chartData.synthesizeCourseUniversityTotal,
    };
  }, [chartData]);


  return (
    (dataType === "1") ?
      <ResourceChartTab  {...memoizedChartData} loading={loading}/> :
      <ResourceTable {...memoizedChartData} loading={isPending}/>
  )
};

export default ResourceTab;

