import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  Tooltip,
  Typography,
  message,
} from "antd";
import { CheckboxProps } from "antd/lib";
import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomCard from "../../../components/custom-card/CustomCard";
import PageHeader from "../../../components/page-header/PageHeader";
import { routesConfig } from "../../../config/routes";
import { postLogout } from "../../../layout/header/services";
import { HelpService } from "../../../service/helper.service";
import { registerDeleteAccount } from "../../../service/list-account-users/listAccountUsersApi";
import { useAuthStore } from "../../../stores/stores";
import { DataType } from "../../../types/listUsers";
import ChangePasswordModal from "../../detail/components/change-password/ChangePassword";
import DetailSettings from "../../detail/components/detail-info/DetailSettings";
import { postChangePassword } from "../../login/components/login-page/services";
import "./account.scss";
import {
  deleteAccountByOtp,
  deleteAccountByPassword,
  editAccountInfo,
  sendOtpDeleteAccount,
} from "./services";

const { Content } = Layout;
type AccountInfoProps = {
  dataUser?: DataType;
  refreshData?: () => void;
};

const Account: FunctionComponent<AccountInfoProps> = (
  props: AccountInfoProps
) => {
  const helpService = new HelpService();
  const [form] = Form.useForm();

  const saveUserInfo = useAuthStore((state) => state.saveUserInfo);
  const accessToken = useAuthStore((state) => state.accessToken);
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);

  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [useOtpDelAcc, setUseOtpDelAcc] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number | null>(60);
  const [isCheckPolicy, setIsCheckPolicy] = useState(false);
  const [isOpenedModal, setIsOpenedModal] = useState<boolean | undefined>(
    false
  );
  const [otp, setOtp] = useState<any>();
  const [password, setPassword] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsCheckPolicy(e.target.checked);
  };

  // Modal policy
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Modal delete
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const handleRegisterDelete = async () => {
    try {
      await registerDeleteAccount();
      setIsModalDeleteOpen(true);
    } catch (error) { }
  };

  const handleCloseModalDelete = () => {
    setIsModalDeleteOpen(false);
    setUseOtpDelAcc(false);
  };

  // Modal warning delete
  const [isModalWarningOpen, setIsModalWarningOpen] = useState(false);

  // const showModalWarning = () => {
  //   setIsModalDeleteOpen(false);
  //   setIsModalWarningOpen(true);
  // };

  const handleDeleteAccount = async () => {
    try {
      if (useOtpDelAcc) {
        await deleteAccountByOtp({
          otp: otp,
        });
      } else {
        await deleteAccountByPassword({
          password: password,
        });
      }
      if (accessToken) {
        try {
          await postLogout().then((res) => {
            if (res.status === 200) {
              logout();
              setTimeout(() => {
                navigate(routesConfig.login);
              }, 1000);
            }
          });
        } catch (error) {
          logout();
        }
      }
    } catch (error: any) { }
  };

  const handleCloseModalWarning = () => {
    setIsModalWarningOpen(false);
  };

  const handleOkModalWarning = () => {
    setIsModalWarningOpen(false);
    setIsModalDeleteOpen(false);
    message.success("Xóa tài khoản thành công");
  };

  const openModal = () => {
    setIsOpenedModal(true);
  };

  const closeModal = () => {
    setIsOpenedModal(false);
    form.resetFields();
  };

  const logoutFNC = async () => {
    await postLogout().then((res) => {
      if (res.status === 200) {
        logout();
        setTimeout(() => {
          navigate(routesConfig.login);
        }, 1000);
      }
    });
  };
  const handleLogout = async () => {
    if (accessToken) {
      try {
        logoutFNC();
      } catch (error) { }
    }
  };

  const handleSubmit = async (values: any) => {
    const body = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    try {
      await postChangePassword(body).then((res) => {
        form.resetFields();
        setIsOpenedModal(false);
        helpService.successMessage(
          "Cập nhật mật khẩu thành công! Bạn sẽ chuyển đến trang đăng nhập trong 5 giây."
        );

        setTimeout(() => {
          handleLogout();
        }, 5000);
      });
    } catch (error) { }
  };

  useEffect(() => {
    if (getUserInfo) {
      form.setFieldsValue({
        tieuSu: getUserInfo?.tieuSu,
        email: getUserInfo?.email,
        vaiTros: getUserInfo?.vaiTros?.map((data: any) => ({
          ...data,
          value: data.code,
          label: data.name,
        })),
        trangThai: getUserInfo?.trangThai,
        ngonNgu: getUserInfo?.ngonNgu,
        muiGio: getUserInfo?.muiGio,
        // timeZone,
      });
    }
  }, [getUserInfo]);

  const handleSave = async () => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    const formData = new FormData();

    if (values.tieuSu) {
      formData.append("profileStory", values.tieuSu);
    }

    try {
      const response = await editAccountInfo(formData);
      saveUserInfo({
        ...getUserInfo,
        tieuSu: values?.tieuSu,
      });

      message.success(`Cập nhật thông tin tài khoản thành công`);
      setIsEdit(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSendOtp = () => {
    setCountdown(60);
    sendOtpDeleteAccount();
    setUseOtpDelAcc(true);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    if (getUserInfo) {
      form.setFieldsValue({
        tieuSu: getUserInfo?.tieuSu,
        email: getUserInfo?.email,
        vaiTros: getUserInfo?.vaiTros?.map((data: any) => ({
          ...data,
          value: data.code,
          label: data.name,
        })),
        trangThai: getUserInfo?.trangThai,
        ngonNgu: getUserInfo?.ngonNgu,
        muiGio: getUserInfo?.muiGio,
        // timeZone,
      });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev && prev > 0) {
            return prev - 1;
          } else {
            clearInterval(timer);
            return null;
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          handleSubmit(values);
        }}
      >
        <Content>
          <PageHeader
            title="Thông tin tài khoản"
            positionItem="column"
            breadcrumb={false}
          />
          <div className="wrapper-personal-information wrapper">
            <section className="detail-account">
              <div className="form-detail">
                <CustomCard
                  title={
                    <div className="card-heading--info">
                      <div>
                        <Typography.Title level={5}>
                          Thông tin cơ bản
                        </Typography.Title>
                        <Typography.Text className="sub-title">
                          Thay đổi thông tin cơ bản của bạn
                        </Typography.Text>
                      </div>
                      <div className="account-profile-icon">
                        {isEdit ? (
                          <div className="flex gap-8">
                            <Button
                              type="primary"
                              onClick={handleSave}
                              loading={isLoading}
                            >
                              Lưu
                            </Button>
                            <Button onClick={cancelEdit}>Huỷ</Button>
                          </div>
                        ) : (
                          <div
                            className="edit-outline"
                            onClick={() => setIsEdit(true)}
                          >
                            <EditOutlined />
                          </div>
                        )}
                      </div>
                    </div>
                  }
                  className="card-info--wrapper"
                >
                  <div className="hr-tag"></div>
                  <Form form={form}>
                    <Form.Item name="tieuSu" label="Tiểu sử">
                      {isEdit ? <Input /> : <span>{getUserInfo?.tieuSu}</span>}
                    </Form.Item>
                    <div className="hr-tag"></div>
                    <Tooltip
                      placement="right"
                      title="Cài đặt không thể thay đổi"
                    >
                      <Form.Item name="email" label="Email">
                        {isEdit ? (
                          <Input
                            readOnly
                            suffix={
                              <InfoCircleOutlined
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            }
                          />
                        ) : (
                          <span>{getUserInfo?.email}</span>
                        )}
                      </Form.Item>
                    </Tooltip>

                    <div className="hr-tag"></div>

                    <Form.Item name="vaiTros" label="Vai trò">
                      {isEdit ? (
                        <Select
                          mode="multiple"
                          options={form.getFieldValue("vaiTros")}
                          disabled
                          suffixIcon={
                            <InfoCircleOutlined
                              style={{ color: "rgba(0,0,0,.45)" }}
                            />
                          }
                        ></Select>
                      ) : (
                        getUserInfo?.vaiTros?.map((it: any) => (
                          <div>{it.name}</div>
                        ))
                      )}
                    </Form.Item>
                    <div className="hr-tag"></div>

                    <Form.Item label="Ngôn ngữ">
                      {isEdit ? (
                        <Tooltip
                          placement="right"
                          title="Cài đặt không thể thay đổi"
                        >
                          <span className="tooltip-container">
                            <Input
                              value="Tiếng Việt"
                              readOnly
                              suffix={
                                <InfoCircleOutlined
                                  style={{ color: "rgba(0,0,0,.45)" }}
                                />
                              }
                            />
                          </span>
                        </Tooltip>
                      ) : <span>Tiếng việt</span>}
                    </Form.Item>
                    <div className="hr-tag"></div>
                    <Tooltip
                      placement="right"
                      title="Cài đặt không thể thay đổi"
                    >
                      <Form.Item name="muiGio" label="Múi giờ">
                        {isEdit ? (
                          <Input
                            readOnly
                            suffix={
                              <InfoCircleOutlined
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            }
                          />
                        ) : (
                          getUserInfo?.muiGio || "(UTC+07:00) Bangkok, Hanoi, Jakarta"
                        )}
                        {/* <span className="tooltip-container">
                          <Input
                            value={timeZone}
                            readOnly
                            suffix={
                              <InfoCircleOutlined
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            }
                          />
                        </span> */}
                      </Form.Item>
                    </Tooltip>
                  </Form>
                </CustomCard>
                <DetailSettings openModal={openModal} />

                <ChangePasswordModal
                  open={isOpenedModal}
                  title="Đổi mật khẩu"
                  hideModal={closeModal}
                />
                <CustomCard
                  title={
                    <>
                      <div className="card-heading--info pt-4">
                        <Typography.Title level={5}>
                          Xóa tài khoản
                        </Typography.Title>
                      </div>
                      <Typography.Text className="sub-title">
                        Xóa toàn bộ tài khoản và thông tin của bạn khỏi hệ thống
                        {process.env.REACT_APP_PROJECT_NAME || ""}
                      </Typography.Text>
                    </>
                  }
                  className="card-info--wrapper"
                >
                  <div className="list-wrapper mt-2">
                    <Card className="card-wrapper">
                      <div className="icon-warnning">
                        <svg
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1143_21436)">
                            <path
                              d="M19.0001 15.667V19.0003M19.0001 22.3337H19.0084M27.3334 19.0003C27.3334 23.6027 23.6025 27.3337 19.0001 27.3337C14.3977 27.3337 10.6667 23.6027 10.6667 19.0003C10.6667 14.398 14.3977 10.667 19.0001 10.667C23.6025 10.667 27.3334 14.398 27.3334 19.0003Z"
                              stroke="#FAAD14"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1143_21436">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(9 9)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="wrapper-policy">
                        <Typography.Title className="title-policy">
                          Điều khoản dịch vụ về Xoá tài khoản
                        </Typography.Title>
                        <Typography.Text className="content-policy">
                          Bằng việc bạn bấm vào “Xoá tài khoản” bạn đã đồng ý
                          với chúng tôi về{" "}
                          <Link
                            to="#"
                            className="link-note"
                            onClick={showModal}
                          >
                            {" "}
                            Điều khoản dịch vụ về xoá tài khoản{" "}
                          </Link>
                          và bạn chịu hoàn toàn trách nhiệm về quyết định của
                          mình.
                        </Typography.Text>
                      </div>
                    </Card>

                    <Modal
                      open={isModalOpen}
                      onCancel={handleClose}
                      title="Điều khoản dịch vụ về xoá tài khoản"
                      className="modal-policy"
                      footer={
                        <Button
                          key="close"
                          onClick={handleClose}
                          className="btn-primary"
                        >
                          Đóng
                        </Button>
                      }
                    >
                      <div className="policy-content">
                        <p>
                          Hãy đảm bảo rằng bạn đã đọc và hiểu rõ các điều khoản
                          và cảnh báo trên trước khi tiến hành xóa tài khoản của
                          mình. Việc xóa tài khoản là một hành động không thể
                          hoàn tác và có thể gây mất mát toàn bộ dữ liệu và
                          quyền truy cập vào dịch vụ.
                        </p>
                        <p>
                          1. Quyền truy cập và dịch vụ: Xóa tài khoản sẽ dẫn đến
                          mất quyền truy cập vào hệ thống và tất cả các tính
                          năng, chức năng, và lợi ích liên quan đến tài khoản.
                          Bạn sẽ không thể đăng nhập, sử dụng hay tận dụng bất
                          kỳ dịch vụ nào liên quan đến tài khoản sau khi xóa.
                        </p>
                        <p>
                          2. Mất dữ liệu: Xóa tài khoản sẽ gây mất mát toàn bộ
                          dữ liệu liên quan, bao gồm thông tin cá nhân, cài đặt,
                          lịch sử giao dịch và bất kỳ dữ liệu liên quan nào
                          khác. Một khi tài khoản đã bị xóa, bạn sẽ không thể
                          khôi phục lại dữ liệu.
                        </p>
                        <p>
                          3. Không thể hoàn tác: Việc xóa tài khoản là hành động
                          không thể hoàn tác. Một khi tài khoản đã bị xóa, bạn
                          sẽ không thể khôi phục hoặc lấy lại tài khoản. Hãy đảm
                          bảo rằng bạn đã xem xét kỹ lưỡng và quyết định xóa tài
                          khoản một cách chín chắn.
                        </p>
                        <p>
                          4. Không thể sử dụng lại thông tin: Các thông tin và
                          dữ liệu liên quan đến tài khoản sau khi xóa không thể
                          được sử dụng lại. Điều này có nghĩa là tên người dùng,
                          địa chỉ email và bất kỳ thông tin cá nhân nào khác
                          không thể được tái sử dụng hoặc khôi phục.
                        </p>
                        <p>
                          5. Không thể xóa một phần tài khoản: Việc xóa tài
                          khoản sẽ áp dụng cho toàn bộ tài khoản và toàn bộ dữ
                          liệu liên quan. Không có tùy chọn xóa một phần hoặc
                          chỉ một phần dữ liệu của tài khoản.
                        </p>
                        <p>
                          6. Chú ý đến hậu quả: Hãy cân nhắc kỹ lưỡng hậu quả
                          của việc xóa tài khoản. Nếu có thắc mắc hay vấn đề,
                          hãy liên hệ với bộ phận hỗ trợ khách hàng hoặc tìm
                          hiểu các tùy chọn và cài đặt bảo mật khác có sẵn để
                          xem xét các giải pháp thay thế.
                        </p>
                      </div>
                    </Modal>

                    <Modal
                      open={isModalDeleteOpen}
                      className="modal-delete-account"
                      okText="Xác nhận"
                      okButtonProps={{ className: "ant-btn-dangerous" }}
                      onOk={handleDeleteAccount}
                      onCancel={handleCloseModalDelete}
                      cancelText="Hủy"
                      centered
                      closable={false}
                    >
                      {!useOtpDelAcc ? (
                        <div className="flex gap-16">
                          <img
                            src="/assets/icons/Vector.png"
                            style={{ width: "20px", height: "20px" }}
                          />
                          <div>
                            <div className="confirm-delete-title">
                              Xác nhận xoá tài khoản
                            </div>
                            <div className="confirm-delete-txt">
                              Bạn có chắc chắn muốn xoá tài khoản
                            </div>
                            <div className="modal-delete-body">
                              <Input
                                placeholder="Nhập mật khẩu"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            <div
                              className="use-otp-code"
                              onClick={handleSendOtp}
                            >
                              Sử dụng mã bảo mật
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex gap-16">
                          <img
                            src="/assets/icons/Vector.png"
                            style={{ width: "20px", height: "20px" }}
                          />
                          <div>
                            <div className="confirm-delete-title">
                              Xác nhận xoá tài khoản
                            </div>
                            <div className="confirm-delete-txt">
                              Mã bảo mật đã được gửi đến email
                              <span className="use-otp-code">
                                &nbsp;{getUserInfo?.email}
                              </span>
                            </div>
                            {/* <div className="modal-delete-body">
                              <Input placeholder="Nhập mã bảo vệ" />
                            </div> */}
                            <div className="modal-delete-body otp-body">
                              <label className="label-body">
                                <span className="compulsory">*</span> Mã bảo mật
                              </label>
                              <div className="mt-1">
                                <Input
                                  placeholder="Nhập mã bảo mật"
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="use-otp-code">
                              Không nhận được mã?
                              {countdown !== null ? (
                                <span className="text-primary">
                                  &nbsp;Gửi lại&nbsp; ({countdown})s
                                </span>
                              ) : (
                                <span
                                  className="text-primary"
                                  onClick={handleSendOtp}
                                >
                                  {" "}
                                  Gửi lại
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Modal>

                    <Modal
                      open={isModalWarningOpen}
                      className="modal-warning-delete"
                      okText="Xác nhận"
                      okButtonProps={{ className: "ant-btn-dangerous" }}
                      onOk={handleOkModalWarning}
                      onCancel={handleCloseModalWarning}
                      cancelText="Hủy"
                      centered
                    >
                      <div className="modal-warning-body">
                        <span
                          className="icon-warning"
                          style={{
                            fontSize: "18px",
                            marginRight: "15px",
                            color: "#FF4D4F",
                          }}
                        >
                          {" "}
                          <WarningOutlined />{" "}
                        </span>
                        <div className="warning-text">
                          <Typography.Text className="notification">
                            Tài khoản sẽ xoá sau 30 ngày
                          </Typography.Text>
                          <Typography.Text className="description">
                            Sau 30 ngày, mọi dữ liệu tài khoản sẽ không thể khôi
                            phục. Bạn chắc chắn muốn xoá tài khoản?
                          </Typography.Text>
                        </div>
                      </div>
                    </Modal>

                    <div className="check-policy">
                      <Checkbox onChange={onChange}>
                        Xác nhận đã đọc và hiểu rõ các điều khoản
                      </Checkbox>
                    </div>
                    <Flex justify="flex-end" className="button-group">
                      <Button
                        className="ant-btn-dangerous"
                        disabled={!isCheckPolicy}
                        onClick={handleRegisterDelete}
                      >
                        <DeleteOutlined />
                        <Typography.Text> Xóa tài khoản</Typography.Text>
                      </Button>
                    </Flex>
                  </div>
                </CustomCard>
              </div>
            </section>
          </div>
        </Content>
      </Form.Provider>
    </>
  );
};

export default Account;
