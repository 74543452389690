// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-bar-theme {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  z-index: 9; }
  .slider-bar-theme .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: unset; }
  .slider-bar-theme .menu-wrapper {
    border-inline-end: 0 !important; }
  .slider-bar-theme .sidebar-screen {
    max-height: 850px; }
    @media (max-width: 1366px) {
      .slider-bar-theme .sidebar-screen {
        max-height: 670px; } }

.ant-menu-submenu-popup .ant-menu-title-content,
.ant-menu-submenu-popup .ant-menu-submenu-title {
  color: #000; }
  .ant-menu-submenu-popup .ant-menu-title-content i,
  .ant-menu-submenu-popup .ant-menu-submenu-title i {
    color: #000 !important; }
`, "",{"version":3,"sources":["webpack://./src/layout/sidebar/sidebar.scss"],"names":[],"mappings":"AAEA;EAKE,yCAAyC;EACzC,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,UAAU,EAAA;EATZ;IAEI,YAAY,EAAA;EAFhB;IAYI,+BAA+B,EAAA;EAZnC;IAgBI,iBAAiB,EAAA;IAEjB;MAlBJ;QAmBM,iBAAiB,EAAA,EAEpB;;AAIH;;EAII,WAAW,EAAA;EAJf;;IAOM,sBAAsB,EAAA","sourcesContent":["@import \"../../styles/mixin\";\n\n.slider-bar-theme {\n  .ant-menu-submenu-selected>.ant-menu-submenu-title {\n    color: unset;\n  }\n\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n  text-align: left;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.85);\n  z-index: 9;\n\n  .menu-wrapper {\n    border-inline-end: 0 !important;\n  }\n\n  .sidebar-screen {\n    max-height: 850px;\n\n    @media (max-width: 1366px) {\n      max-height: 670px;\n    }\n  }\n\n}\n\n.ant-menu-submenu-popup {\n\n  .ant-menu-title-content,\n  .ant-menu-submenu-title {\n    color: #000;\n\n    i {\n      color: #000 !important;\n    }\n  }\n}\n\n@include respond(tab-port) {\n  // .sider {\n  //   display: none;\n  // }\n\n  // .menu-mobile {\n  //   padding: 0 10px;\n  // }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
