import {
  ArrowLeftOutlined,
  DownOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps,
} from "antd";
import { KeyboardEvent, useEffect, useState } from "react";
import FormItemInput from "../../../components/form-input/FormInput";
import TableData from "../../../components/table-data/TableData";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FilterSearch, { FilterSearchValues } from "./FilterSearch";
import { routesConfig } from "../../../config/routes";
import { getExamSessions } from "../../../service/scoring";
import useKeyword from "../../../hooks/useKeyword";
import { toNumber } from "lodash";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import { SessionInfo } from "../../../types/scoring-course";
import moment from "moment";
import { ScoringStatusExam } from "../../../constants/scoring";
import { Permission } from "../../../layout/sidebar/permission.enum";
import { CheckRole, getPermissions } from "../../../utils/checkRole";

interface CourseScoringListProps {
  title?: string;
}

function ScoringList({ title }: CourseScoringListProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const courseId = toNumber(params?.id);
  const courseName = location.state?.courseName;
  const unitName = location.state?.unitName;
  const blockId = toNumber(useKeyword("blockId"));

  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [sessions, setSessions] = useState<SessionInfo[]>([]);
  const [hoverSortMessage, setHoverSortMessage] = useState<{
    triggerDesc: string;
    triggerAsc: string;
  }>({
    triggerDesc: "Sắp xếp theo thứ tự Z-A",
    triggerAsc: "Sắp xếp thứ tự A-Z",
  });

  //filter
  const [sort, setSort] = useState<string[]>();
  const [searchValue, setSearchValue] = useState("");
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<number>();
  const [teacherFilter, setTeacherFilter] = useState<number>();
  const [date, setDate] = useState<{
    start: string;
    end: string;
  }>();

  const [displayRoleAction, setDisplayRoleAction] = useState(false);

  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleAction = [Permission.AutomatedExamGradingUpdate];

    const checkRoleView = CheckRole(roleAction, listPermission);
    if (checkRoleView.includes(true)) {
      setDisplayRoleAction(true);
    }
  }, [listPermission]);

  useEffect(() => {
    getExamSession();
  }, [statusFilter, teacherFilter, date, pageSize, currentPage, sort]);

  const getExamSession = async () => {
    try {
      setLoading(true);
      const res = await getExamSessions({
        blockId: blockId,
        page: currentPage,
        size: pageSize,
        status: statusFilter,
        markUserId: teacherFilter,
        studentUsername: searchValue?.trim(),
        start: date?.start,
        end: date?.end,
        sort: sort,
      });
      setTotalElements(res.data.data.total);
      setSessions(res.data.data.data);
    } catch (err) {
      console.error;
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSubmitSearch = () => {
    getExamSession();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };

  const handleFilter = (value: FilterSearchValues) => {
    setDate({
      start:
        value.date && value.date[0] ? value.date[0].toISOString() : undefined,
      end:
        value.date && value.date[1] ? value.date[1].toISOString() : undefined,
    });
    setStatusFilter(value.status ? value.status : undefined);
    setTeacherFilter(value.teacher ? value.teacher : undefined);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const sortField =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";

    setSort(sortField ? [`${sorter?.field},${sortField}`] : undefined);
  };

  const locale = {
    emptyText: (
      <span>
        <p>
          <img
            className="image-empty-data"
            src="/images/empty-img-gray.png"
            alt="empty-img"
          ></img>
        </p>
        <p className="nodata-text">Không tìm thấy</p>
      </span>
    ),
    triggerDesc: hoverSortMessage.triggerDesc,
    triggerAsc: hoverSortMessage.triggerAsc,
    cancelSort: "Huỷ sắp xếp",
  };

  const dataColumns: TableColumnsType<SessionInfo> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      align: "center",
      width: "5%",
      render: (_: any, record, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Tên học viên",
      dataIndex: "studentFullname",
      key: "studentFullname",
      sorter: true,
      onHeaderCell: () => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp theo thứ tự Z-A",
              triggerAsc: "Sắp xếp theo thứ tự A-Z",
            });
          },
        };
      },
    },
    {
      title: "Email",
      dataIndex: "studentUserEmail",
      key: "studentUserEmail",
      sorter: true,
      onHeaderCell: () => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp theo thứ tự Z-A",
              triggerAsc: "Sắp xếp theo thứ tự A-Z",
            });
          },
        };
      },
    },
    {
      title: "Ngày nộp",
      dataIndex: "submitTime",
      key: "submitTime",
      sorter: true,
      onHeaderCell: () => {
        return {
          onMouseEnter: () => {
            setHoverSortMessage({
              triggerDesc: "Sắp xếp thứ tự mới đến cũ",
              triggerAsc: "Sắp xếp theo cũ đến mới",
            });
          },
        };
      },
      render: (_: any, record) =>
        moment(record.submitTime).format("DD/MM/YYYY"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record) => {
        return (
          <div className="flex align-center">
            {displayStatus(record.status)}
          </div>
        );
      },
    },
    {
      title: "Người chấm",
      dataIndex: "markedUserFullname",
      key: "markedUserFullname",
      render: (_: any, record) => record.markedUserFullname || "_",
    },
    {
      title: "",
      key: "scoring",
      render: (_: any, record) => {
        return (
          <Link
            className="text-primary"
            to={`/${routesConfig.scoring}/${record?.sessionId}`}
            state={{
              courseName: courseName,
              courseId: courseId,
              unitName: unitName,
              blockId: blockId,
              studentName: record.studentFullname,
              edit: true,
            }}
          >
            Chấm điểm
          </Link>
        );
      },
    },
    {
      fixed: "right",
      key: "view",
      width: "5%",
      render: (_: any, record) => {
        return (
          <Button
            className="view-btn"
            onClick={() =>
              navigate(`/${routesConfig.scoring}/${record?.sessionId}`, {
                state: {
                  courseName: courseName,
                  courseId: courseId,
                  unitName: unitName,
                  blockId: blockId,
                  studentName: record.studentFullname,
                },
              })
            }
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
  ];

  const displayStatus = (status: number | string) => {
    switch (status) {
      case ScoringStatusExam.SCORING:
        return (
          <Flex align="center">
            {" "}
            <div className="dot blue"></div>Đang chấm
          </Flex>
        );
      case ScoringStatusExam.COMPLETED:
        return (
          <Flex align="center">
            <div className="dot green"></div>Hoàn thành
          </Flex>
        );
      case ScoringStatusExam.WAITING:
        return (
          <Flex align="center">
            <div className="dot gray"></div>Đợi chấm
          </Flex>
        );
      default:
        break;
    }
  };

  const breadcrumb = [
    {
      title: (
        <Link to={`${routesConfig.scoringListCourse}`}>Danh sách khoá học</Link>
      ),
    },
    {
      title: (
        <Link
          to={`${routesConfig.scoringListCourse}/${courseId}`}
          state={{
            name: courseName,
          }}
        >
          {courseName}
        </Link>
      ),
    },
    {
      title: unitName,
    },
  ];

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="scoring-course w-full">
          <div className="scoring-page-header">
            <Breadcrumb items={breadcrumb} className="mb-1" />
            <div className="flex justify-between align-center gap-8">
              <div className="flex gap-8 align-center flex-1">
                <ArrowLeftOutlined
                  className="arrowleft-icon"
                  onClick={() => navigate(-1)}
                />
                <h3 className="page-title">{title}</h3>
              </div>
              <Button onClick={() => setIsShowFilter(!isShowFilter)}>
                Bộ lọc{" "}
                <DownOutlined
                  className={`mooc-arrow ${
                    isShowFilter ? "down-arrow" : "up-arrow"
                  }`}
                />
              </Button>
              <div className="search-input">
                <FormItemInput
                  style={{ width: 280 }}
                  placeholder="Nhập tên học viên"
                  value={searchValue}
                  onChange={handleChangeInput}
                  onKeyPress={handleSearch}
                  afterPrefixIcon={
                    <SearchOutlined onClick={handleSubmitSearch} />
                  }
                />
              </div>
              {displayRoleAction && (
                <Button
                  onClick={() =>
                    navigate(
                      `${routesConfig.scoringListCourse}/${routesConfig.managementScoreAuto}/${blockId}`
                    )
                  }
                >
                  Thao tác
                </Button>
              )}
            </div>
            {isShowFilter && <FilterSearch onSearch={handleFilter} />}
          </div>
          <div className="scoring-content">
            <TableData
              dataTable={sessions?.map((item: SessionInfo, index) => ({
                ...item,
                id: item.sessionId,
              }))}
              dataColumns={dataColumns}
              scroll={{ x: 1000, y: 600 }}
              pagination
              localeProp={locale}
              pageNumber={currentPage}
              pageSize={pageSize}
              loadingTable={loading}
              totalRecords={totalElements}
              handleTableChangeProps={handleTableChange}
              setPageNumber={setCurrentPage}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default ScoringList;
