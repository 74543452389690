import React, { useEffect, useState } from "react";
import TextEditor from "../../../text-editor/TextEditor";
import { Button, Col, Row, Typography, Upload, UploadFile } from "antd";
import { InboxOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/lib";
import "./style.scss";
import { useAddLesson, useAuthStore } from "../../../../stores/stores";
import { initialChooseFileUploadQuestionValue, } from "../../../../constants";
import { SortableList } from "../../../SortableList";
import {
  AnswerFileUploadInfo,
  BeQuizAnswer,
  QuestionData,
  QuestionItemDetail,
} from "../../../../types/course-construction";
import { findMaxIndexOfArray, mapAnsBEtoFE } from "../../../../utils/arrays";
import FileUploadCriteria from "./FileUploadCriteria";
import { HelpService } from "../../../../service/helper.service";
import { get } from "lodash";
import { FileType } from "../../../../utils/uploadImage";
import { DraggableUploadListItem } from "../video";
import { BASE_URL_V2 } from "../../../../config/api/configApiv2";
import { renderQuestionNameByType, renderQuestionDescriptionByType } from "../../../../utils/functionUltils";

const { Dragger } = Upload;

let locale = {
  emptyText: "Không có dữ liệu",
};

const FileUpload = ({ dataFromApi = {} as QuestionData }: QuestionItemDetail) => {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const accessToken = useAuthStore((state) => state.accessToken);
  const [questionName, setQuestionName] = useState<string>("");
  const question = useAddLesson((state: any) => state.question);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  let [answers, setAnswers] = useState(initialChooseFileUploadQuestionValue);
  const [dataUpload, setDataUpload] = useState<UploadFile[]>([
    {
      uid: "",
      name: "",
      url: "",
    },
  ]);
  // const [criteria, setCriteria] = useState(
  //   initialChooseFileUploadCriteriaValue
  // );
  const helpService = new HelpService();

  const setCurrentQuestionInfo = useAddLesson(
    (state) => state.setCurrentQuestionInfo
  );

  const handleChangeText = (current: { id: number; value: string }) => {
    const distAnswer = [...answers];
    // distCriteria[current.id] = current.value;
    const indexOfChangeItem = distAnswer.findIndex(
      (item) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      distAnswer[indexOfChangeItem] = {
        ...distAnswer[indexOfChangeItem],
        value: current.value,
      };
      setAnswers(distAnswer);
    }
  };

  const handleRemoveOneChoose = (id: number) => {
    if (id) {
      const newList = [...answers];
      setAnswers(newList.filter((item) => item.id !== id));
    } else console.error("Cannot find id");
  };

  const handleAddMoreCriteria = () => {
    if (answers.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(answers) + 1,
        value: "",
        key: findMaxIndexOfArray(answers) + 1,
        isTrue: true,
        isDisplayContent: false,
        point: 0,
        filePath: '',
        fileName: '',
      };
      const newList = [...answers];
      setAnswers(newList.concat([newItem]));
    } else setAnswers(initialChooseFileUploadQuestionValue);
  };

  useEffect(() => {
    setQuestionName(question.question);
    if (dataFromApi.answers && dataFromApi.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(dataFromApi.answers);
      // const dataUploadAnswer = data.answers.map((i: BeQuizAnswer) => ({
      //   uid: i.orderNumber.toString(),
      //   url: i.filePath,
      //   name: i.fileName,
      // }));
      // setDataUpload(dataUploadAnswer);
      setAnswers(feAnswer);
    } else {
      setAnswers([]);
      setDataUpload([]);
    }
    // const feCriteria = get(
    //   data.settingGeneral?.scoreCriteria,
    //   "scoreCriteria",
    //   []
    // );
    // if (feCriteria && feCriteria.length > 0) {
    //   setCriteria(feCriteria);
    // }
  }, [question, dataFromApi.answers]);

  useEffect(() => {
    if (settingQuestion.displayScoreCriteria === undefined) {
      settingQuestion.displayScoreCriteria = true;
    }
    setCurrentQuestionInfo({ ...answers, questionName: questionName, });
  }, [answers, questionName]);

  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const handelUpdateAnswer = (data: AnswerFileUploadInfo) => {
    let newList = [...answers];

    const indexOfItem = newList.findIndex((item: AnswerFileUploadInfo) => {
      return item.id === data.id;
    });

    if (indexOfItem !== -1) {
      newList[indexOfItem] = {
        ...newList[indexOfItem],
        filePath: data.filePath as string,
        fileName: data.fileName as string,
      };
    }
    setAnswers(newList);
  };

  const handleChangeFile: UploadProps["onChange"] = (info) => {
    setTimeout(() => {
      setLoadingUpload(false);
    }, 333);
    if (info.file.status === "error") {
      helpService.errorMessage("Xảy ra lỗi khi tải lên !");
    }
    if (info.file.status === "done") {
      const newItem = {
        id: findMaxIndexOfArray(answers) + 1,
        key: findMaxIndexOfArray(answers) + 1,
        point: 0,
        value: "",
        isTrue: true,
        fileName: info.file.response?.fileName,
        filePath: info.file.response?.filePath,
        isDisplayContent: false,
      };
      if (answers.length > 0) {
        answers = [...answers].concat([newItem]);
      } else {
        ([initialChooseFileUploadQuestionValue[0].fileName] =
          info.file.response?.fileName),
          ([initialChooseFileUploadQuestionValue[0].filePath] =
            info.file.response?.filePath),
          (answers = [initialChooseFileUploadQuestionValue[0]]);
      }
      handelUpdateAnswer(newItem);
      helpService.successMessage("Tải lên thành công !");
    }

    setDataUpload(info.fileList);

    if (info.file.status === "removed") {
      const newListAnswers = [...answers].filter((item, index) =>
        [...dataUpload]
          .filter((item) => item.status !== "removed")
          .some((ans) => item.id === parseInt(ans.uid))
      );
      setAnswers(newListAnswers);
      helpService.successMessage("Xóa thành công !");
    }
  };

  const handleBeforeUpload = (file: FileType) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      helpService.errorMessage("Bạn phải tải lên file JPG hoặc PNG !");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 333);
    }
    return isJpgOrPng;
  };

  const handleDragChange = (value: any) => {
    // setCriteria(value);
  };

  return (
    <section className="question-wrapper">
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionName} onChange={handleChangeQuestionName} />
      <Dragger
        className="dragger-record"
        name="file"
        multiple={true}
        action={BASE_URL_V2 + "/mooc-course-block-quiz/upload-file"}
        headers={{ Authorization: "Bearer " + accessToken }}
        onChange={handleChangeFile}
        beforeUpload={handleBeforeUpload}
        fileList={dataUpload}
        itemRender={(originNode, item) => {
          return (
            <DraggableUploadListItem originNode={originNode} file={item} />
          );
        }}
      // onRemove={handleRemoveFile}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click hoặc kéo thả file đính kèm vào đây để tải lên
        </p>
        <div className="val-file-upload">
          <p>Các định dạng file đính kèm hợp lệ:...</p>
          <p>Kích thước file đính kèm tối đa:...</p>
        </div>
      </Dragger>
      <div className="upload-record">
        <Button
          icon={<UploadOutlined />}
          disabled
        // onClick={handleUpload}
        >
          Tải lên
        </Button>
      </div>
      {settingQuestion.displayScoreCriteria && (
        <div className="tree-course-construct list-answers">
          <Typography.Text>Tiêu chí tính điểm</Typography.Text>
          <SortableList
            items={answers}
            onChange={handleDragChange}
            renderItem={(record: any, index: number) => (
              <SortableList.Item id={record.id}>
                {/* <SortableList.DragHandle /> */}
                <FileUploadCriteria
                  index={record.id}
                  value={record.value}
                  onChange={handleChangeText}
                  onDelete={handleRemoveOneChoose}
                />
              </SortableList.Item>
            )}
          />
          <Row>
            <Col span={24}>
              <Button
                className="btn-add-more mt-1"
                type="default"
                icon={<PlusOutlined />}
                onClick={handleAddMoreCriteria}
              >
                Thêm tiêu chí
              </Button>
            </Col>
          </Row>
          {/* <div style={{ maxWidth: 400, margin: "30px auto" }}></div> */}
        </div>
      )}
    </section>
  );
};

export default FileUpload;
