// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-card-department {
  margin-top: 15px;
  margin-left: 1.5vw; }

.col-card-major {
  margin-top: 15px;
  margin-left: 0.5vw; }

.action-modal .ant-modal-content .ant-modal-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0; }

.action-modal .ant-modal-content .ant-modal-body {
  border-bottom: 1px solid #f0f0f0; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/department-major-management/department-major-management.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB,EAAA;;AAGpB;EACE,gBAAgB;EAChB,kBAAkB,EAAA;;AAGpB;EAGM,oBAAoB;EACpB,mBAAmB;EACnB,gCAAgC,EAAA;;AALtC;EASM,gCAAgC,EAAA","sourcesContent":[".col-card-department {\n  margin-top: 15px;\n  margin-left: 1.5vw;\n}\n\n.col-card-major {\n  margin-top: 15px;\n  margin-left: 0.5vw;\n}\n\n.action-modal {\n  .ant-modal-content {\n    .ant-modal-header {\n      padding-bottom: 10px;\n      margin-bottom: 10px;\n      border-bottom: 1px solid #f0f0f0;\n    }\n\n    .ant-modal-body {\n      border-bottom: 1px solid #f0f0f0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
