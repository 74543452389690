import { Button, Dropdown, Form, Layout, MenuProps, Modal, Popconfirm, Space, Switch, TableColumnsType, TableProps, Typography, message } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { HelpService } from "../../../service/helper.service";
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, MoreOutlined, SaveOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { routesConfig } from "../../../config/routes";
import { DataType, validateFieldCreate } from "./constants/data";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";
import DrawerCustom from "../../../components/drawer/Drawer";
import FormCreateUniversity from "./components/form-create-university/FormCreateUniversity";
import { formatDateV2 } from "../../../utils/converDate";
import { get, isEmpty } from "lodash";
import { addMoocUniversity, changeStatusUniversity, deleteMoocUniversity, downloadUniversityTemplateFile, exportExcelMoocUniversity, getAddressProvinces, getMoocOrganization, getMoocUniversityByFilter, getMoocUniversityById, getMoocUniversityTrainingType, getMoocUniversityType, importExcelMoocUniversity, updateMoocUniversity } from "../../../service/category-management/university-management";
import "./university-management.scss";
import ImportIndustryDBModal from "./components/import-industry-db-modal/ImportIndustryDBModal";
import { globalStore } from "../../../stores/stores";
import { Permission } from "../../../layout/sidebar/permission.enum";

type UniversityManagementProps = {
  title?: string;
};

const UniversityManagement = ({ title }: UniversityManagementProps) => {
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const navigate = useNavigate();
  const helpService = new HelpService();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [editId, setEditId] = useState<number>(0);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [dataUniversity, setDataUniversity] = useState<any>([]);
  const [openImportUniversityModal, setOpenImportUniversityModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const { personalInfo, reloadAdminInterface, setReloadAdminInterface } = globalStore();
  const permissions = personalInfo?.permissions;
  const [dataOptions, setDataOptions] = useState<any>({
    dataModalType: [],
    dataTrainingType: [],
    dataProvince: [],
    dataDistrict: [],
    dataWard: [],
    dataManagementAgency: [],
  });

  const [filterData, setFilterData] = useState<any>({
    keyword: null,
    status: null,
    typeSchool: null,
    createdDateFrom: null,
    createdDateTo: null,
  });
  const [memberUniversity, setMemberUniversity] = useState<any[]>([]);

  const universityOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa cơ sở đào tạo",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa cơ sở đào tạo",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên trường",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 60,
      render: (_: any, record) => {
        return (
          <div className="table-university cursor-pointer">
            <div>
              <a
                className="university-name"
                onClick={() =>
                  navigate(
                    `${routesConfig.universityManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        universityId: record.id,
                      },
                    }
                  )
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mã trường",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Loại hình",
      dataIndex: "typeSchool",
      key: "typeSchool",
      width: 30,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 40,
      render: (_: any, record, index: number) => {
        return record.createdDate ? formatDateV2(record.createdDate) : null;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: { status: boolean; id: number }) => {
        const handleChange = (checked: boolean) => {
          record.status = checked;
        };
        const confirm = (id: number) => {
          setLoading(true);
          changeStatusUniversity({
            id: id,
            status: record?.status,
          }).then((res) => {
            if (res.status === 200) {
              setTimeout(() => {
                setLoading(false);
              }, 333);
              helpService.successMessage("Thay đổi trạng thái thành công!");
              getAllUniversity();
            }
          }).catch(err => {
            setLoading(false);
            console.error(err);
          })
        };

        const cancel = () => {
          helpService.errorMessage("Hủy thao tác thành công!");
        };

        return (
          <Popconfirm
            rootClassName="popconfirm-change-status"
            description={`Xác nhận đổi trạng thái?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.status}
              checked={record.status}
              onChange={handleChange} />
          </Popconfirm>
        );
      },
      width: 20,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.universityManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        universityId: record.id,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: universityOptions,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      getUniversityUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Xoá cơ sở đào tạo`,
                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                        onOk: () => deleteUniversity(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị danh mục",
    },
  ];

  // Get data university
  const getAllUniversity = (data?: any) => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      status: filterData.status,
      typeSchool: filterData.typeSchool,
      isEnterprise: true,
      createdFromDate: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdToDate: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`]
    };
    getMoocUniversityByFilter(dataReq)
      .then((res) => {
        setDataUniversity(res.data.data.list);
        setTotalRecords(res.data.data.total);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/error/403");
        } else {
          console.error(err);
        }
        setLoading(false);
      });
  };

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      typeSchool: null,
      createdDateFrom: null,
      createdDateTo: null,
      status: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      getAllUniversity();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    getAllUniversity();
  };

  const handleFinish = (values: any) => {
    const typeSchool = Object(dataOptions.dataTrainingType.find((item: any) => item.id === values.type));
    setPageNumber(1);
    setFilterData({
      ...filterData,
      status: values?.status,
      typeSchool: typeSchool?.name,
      createdDateFrom: values?.time ? (values?.time[0]).add(1, "day").toISOString() : null,
      createdDateTo: values?.time ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };

  // Get university to edit
  const getUniversityUpdate = async (id: number) => {
    setLoadingForm(true);
    try {
      await getMoocUniversityById(id).then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          setDataDetail(data);
          setTimeout(() => {
            setLoadingForm(false);
          }, 333);
        }
      })
    } catch (err) {
      setLoadingForm(false);
      console.error(err);
    }
  };

  // Modal create | edit
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    form.resetFields();
  };

  const handleCreateAndEdit = () => {
    Modal.confirm({
      title: !isOpenedEdit ? `Thêm mới trường đại học` : `Chỉnh sửa trường đại học`,
      content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
      onOk: () => form.submit(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  // type: loại hình
  // typeSchool: loại trường
  const onFinishCreate = async (values: any) => {
    if (Object.keys(values).length > 0) {
      if (isOpenedEdit) {
        const dataPayload = {
          id: editId,
          name: values.name?.trim(),
          nameEn: values.englishName?.trim(),
          trainingLevel: values.trainingLevel,
          code: values.code?.trim(),
          type: values.trainingType,
          typeSchool: values.modalType,
          organizationId: values.managementAgency,
          provinceCode: values.province,
          districtCode: values.district,
          wardCode: values.ward,
          principal: values.principal?.trim(),
          address: values.address?.trim(),
          addressWebsite: values.website?.trim(),
          status: values.status,
          children: memberUniversity?.map(it => it.uuid || it.enterpriseId)
        };
        setIsSaving(true);
        try {
          await updateMoocUniversity(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Cập nhật cơ sở đào tạo thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllUniversity();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
          setTimeout(() => {
            setReloadAdminInterface(!reloadAdminInterface)
          }, 0);
        }
      } else {
        const dataPayload = {
          name: values.name?.trim(),
          nameEn: values.englishName?.trim(),
          trainingLevel: values.trainingLevel,
          code: values.code?.trim(),
          type: values.trainingType,
          typeSchool: values.modalType,
          organizationId: values.managementAgency,
          provinceCode: values.province,
          districtCode: values.district,
          wardCode: values.ward,
          principal: values.principal?.trim(),
          address: values.address?.trim(),
          addressWebsite: values.website?.trim(),
          status: values.status,
          children: memberUniversity?.map(it => it.uuid)
        };
        setIsSaving(true);
        try {
          await addMoocUniversity(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới cơ sở đào tạo thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }

            handleClose();
            getAllUniversity();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
          setTimeout(() => {
            setReloadAdminInterface(!reloadAdminInterface)
          }, 0);
        }
      }
    }
  };

  // Delete university
  const deleteUniversity = async (cate: any) => {
    await deleteMoocUniversity(cate.id).then((res) => {
      if (res.status === 200) {
        message.success("Xóa cơ sở đào tạo thành công!");
        getAllUniversity();
      }
    });
  };

  // Import file Excel to add new
  const importExcel = async (file: any) => {
    setLoading(true);
    await importExcelMoocUniversity(file).then((res) => {
      if (res.status === 200) {
        message.success("Thêm mới cơ sở đào tạo thành công!");
        setLoading(false);
        setTimeout(() => {
          setLoading(false);
        }, 300);
        getAllUniversity();
      }
    }).catch((err) => {
      {
        setLoading(false);
        console.error(err);
      }
    })
  }

  // Import IndustryDB
  const handleCloseImportUniversityModal = () => {
    setOpenImportUniversityModal(false);
  }

  // Export file Excel
  const exportExcel = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      status: filterData.status,
      createdFromDate: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdToDate: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`],
      typeSchool: filterData.typeSchool,
    };
    setIsRendering(true);
    try {
      await exportExcelMoocUniversity(dataReq).then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Danh sách cơ sở đào tạo.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!");
        setIsRendering(false);
      })
    } catch (err) {
      setIsRendering(false);
      console.error("Error downloading Excel file:", err);
    }
  }

  // Download file template excel
  const handleDownloadFile = async () => {
    try {
      await downloadUniversityTemplateFile().then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Mẫu thêm mới cơ sở đào tạo.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!")
      })
    } catch (err) {
      console.error("Error downloading Excel file:", err);
    }
  }

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // useEffect
  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        name: dataDetail.name,
        englishName: dataDetail.nameEn ? dataDetail.nameEn : null,
        trainingLevel: dataDetail.trainingLevel,
        modalType: dataDetail.typeSchool,
        code: dataDetail.code,
        trainingType: dataDetail.type,
        province: dataDetail.provinceCode,
        managementAgency: dataDetail.organizationId ? dataDetail.organizationId : null,
        district: dataDetail.districtCode,
        principal: dataDetail.principal ? dataDetail.principal : null,
        ward: dataDetail.wardCode,
        address: dataDetail.address ? dataDetail.address : null,
        website: dataDetail.addressWebsite ? dataDetail.addressWebsite : null,
        status: dataDetail.status,
        type: dataDetail.type,
        children: dataDetail.children,
      });

    }
  }, [dataDetail]);

  useEffect(() => {
    getAllUniversity();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getMoocUniversityType(), getMoocUniversityTrainingType(), getMoocOrganization(), getAddressProvinces()])
      .then((res) => {
        const dataTrainingType = res[0].data?.data;
        const dataModalType = res[1].data?.data;
        const dataManagementAgency = res[2].data?.data;
        const dataProvince = res[3].data.data.map((item: any) => {
          return ({
            id: item.code,
            name: item.name,
          })
        })

        const dataTypeSchool = dataTrainingType.map((item: any) => ({
          id: item?.name,
          name: item?.name
        }))
        const dataType = dataModalType.map((item: any) => ({
          id: item?.name,
          name: item?.name
        }))
        setDataOptions({
          ...dataOptions,
          dataTrainingType: dataTypeSchool,
          dataModalType: dataType,
          dataManagementAgency: dataManagementAgency,
          dataProvince: dataProvince,
        });

        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
  }, [])
  const handleMemberUniversityChange = (data: any[]) => {
    setMemberUniversity(data);
  };
  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === "createUniversity") {
          const { createUniversity } = forms;
          const data = createUniversity.getFieldsValue(validateFieldCreate);
          onFinishCreate(data);
        }
      }}
      >
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              exportExcel={exportExcel}
              handleDownloadFile={handleDownloadFile}
              importExcel={importExcel}
              isRendering={isRendering}
              setOpenImportUniversityModal={setOpenImportUniversityModal}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>

        <TableData
          dataTable={dataUniversity}
          dataColumns={dataColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          loadingTable={loading}
          handleTableChangeProps={handleTableChange}
        />

        <DrawerCustom title={`${isOpenedEdit ? "Chỉnh sửa cơ sở đào tạo" : "Thêm mới cơ sở đào tạo"}`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit" onClick={handleCreateAndEdit} disabled={isSaving}>
                {isSaving ? <LoadingOutlined /> : <SaveOutlined />}
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateUniversity form={form} dataOptions={dataOptions} loadingForm={loadingForm}
            onMemberUniversityChange={handleMemberUniversityChange}

          />
        </DrawerCustom>
      </Form.Provider>
      <ImportIndustryDBModal openImportUniversityModal={openImportUniversityModal} handleCloseImportUniversityModal={handleCloseImportUniversityModal} />
    </Layout>
  );
};

export default UniversityManagement;
