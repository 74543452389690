import { DownOutlined, EyeOutlined, FileDoneOutlined, RetweetOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Form, Layout, Modal, TableColumnsType, TableProps, Tabs, TabsProps, Typography } from "antd";
import dayjs from "dayjs";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import FormInput from "../../../../components/form-input/FormInput";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import { routesConfig } from "../../../../config/routes";
import { getLearningMaterialList, revokeApproval } from "../../../../service/learning-material";
import { LearningMaterialStore } from "../../../../stores/stores";
import { getFirstLetter, RenderUnitIcon } from "../../../../utils/functionUltils";
import FormFilter from "../../components/FormFilter/FormFilter";
import "./style.scss";
import ApproveMulti from "../approve-multi/ApproveMulti";
import { notifySuccess } from "../../../../utils/notification";
type UnitListProps = {
  title?: string;
};

const DepartmentLevel = ({ title }: UnitListProps) => {
  const navigate = useNavigate()
  const [form] = Form.useForm();

  const { reload, onReload, tabLink } = LearningMaterialStore()
  const [unitList, setUnitList] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [isApproveMultiple, setIsApproveMultiple] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(tabLink)
  const [filterData, setFilterData] = useState<any>({
    shareType: [],
    status: [],
    createDate: ["", ""],
  });


  const handleShowFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const handleRevokeApproval = async (id: number) => {
    const payload = {
      id: id
    }

    try {
      await revokeApproval(payload)
      onReload()
      notifySuccess("Đã thu hồi duyện học liệu")
    } catch (error) {

    }
  }
  const fetchData = async () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    const { startDate, shareUniversity } = formValues;

    const payload = {
      page: pageNumber,
      size: pageSize,
      createdFrom: startDate?.[0] ? dayjs(startDate[0]).toISOString() : undefined,
      createdTo: startDate?.[1] ? dayjs(startDate[1]).toISOString() : undefined,
      shareEnterpriseUuids: shareUniversity && shareUniversity.length ? shareUniversity : undefined,
      keyword: searchValue || undefined,
      shareType: [1],
      status: [tab === "1" ? 1 : 2],
      sort: ['created_date,desc'],
    };

    try {
      const response = await getLearningMaterialList(payload);
      const { data } = response.data;
      setUnitList(data?.content);
      setTotalPage(data?.totalElements);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData()
  }, [pageSize, pageNumber, tab, reload]);

  useEffect(() => {
    setTab("1")
  }, [])

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: "55px",
      render: (_: any, record) => <div>{record.numericalOrder}</div>,
    },
    {
      title: "Học liệu",
      dataIndex: "resource",
      key: "resource",
      width: "30%",
      render: (resource: any, record) => {
        return (
          <div className={`${"flex gap-16 align-center"}`}
            style={{ wordBreak: "break-all" }}
          >
            <div
              style={{ width: "60px" }}
            >{RenderUnitIcon(record.module)}</div>
            <div>
              <div className="font-weight-5">{record.code}</div>
              <div>{record.name}</div>

            </div>
          </div>
        )
      },
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      render: (resource: any, record: any) => {
        return (
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: `${record?.description}` || "" }}
          />
        )
      },
    },
    {
      title: "Người chia sẻ",
      dataIndex: "sharer",
      key: "sharer",
      render: (resource: any, record: any) => {
        return (
          <div className="flex gap-16 align-center"
            style={{ wordBreak: "break-all" }}
          >
            <Avatar src={record?.imagePath} size={48} className="mt-2 mb-2">
              {getFirstLetter(record?.shareUserFullName)}
            </Avatar>
            <div>
              <div className="font-weight-5">{record.shareUserFullName}</div>
              <div>{record.shareEnterpriseName}</div>

            </div>
          </div>
        )
      },
    },
    {
      title: "Ngày chia sẻ",
      dataIndex: "createdDate",
      key: "createdDate",
      width: "180px",
      render: (resource: any, record: any) => {
        return (
          <div>
            {dayjs(record.createdDate).format("DD/MM/YYYY")}
          </div>
        )
      },
    },
    // {
    //   title: "Trạng thái chia sẻ",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "160px",
    //   render: (resource: any, record: any) => {

    //     return (
    //       <div>
    //         {record?.status === 1 ? "Chờ duyệt"
    //           : record?.status === 2 ? "Đã duyệt"
    //             : record?.status === 3 ? "Từ chối"
    //               : "Bản nháp"
    //         }
    //       </div>
    //     )
    //   },
    // },
    ...tab === "2" ?
      [
        {
          title: "Ngày duyệt",
          dataIndex: "approvedDate",
          key: "approvedDate",
          width: "180px",
          render: (resource: any, record: any) => {

            return (
              <div>
                {record.approvedDate ? dayjs(record.approvedDate).format("DD/MM/YYYY") : ""}
              </div>
            )
          },
        },
      ] : [],
    {
      title: "Thao tác",
      key: "actions",
      width: "120px",
      render: (_, record) => {
        return (
          <div className="flex gap-24">
            {tab === "1" ? <div>
              <FileDoneOutlined style={{ fontSize: "24px" }}
                className="view-unit"
                onClick={() => navigate(`/course-library${routesConfig.learningMaterials}/department-level-content-moderation/approve/${record?.id}`)}
              />
            </div> :
              <RetweetOutlined
                className="view-unit"
                onClick={() => {
                  Modal.confirm({
                    title: `Thu hồi duyệt học liệu ${record.name ? record.name : ""}`,
                    content: `bạn có chắc chắn thu hồi duyệt học liệu này?`,
                    onOk: () => handleRevokeApproval(record.id),
                    centered: true,
                    okText: "Đồng ý",
                    cancelText: "Huỷ",
                    footer: (_, { OkBtn, CancelBtn }) => (
                      <>
                        <CancelBtn />
                        <OkBtn />
                      </>
                    ),
                  });
                }} />
            }
            <div className="flex gap-24">
              <EyeOutlined
                className="view-unit"
                onClick={() => navigate(`/course-library${routesConfig.learningMaterials}/detail/${record.id}`)}
              />

            </div>
          </div>
        );
      },

    },
  ];

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      fetchData();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    fetchData();
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Chờ duyệt",
      children: (
        <TableData
          dataColumns={dataColumns}
          loadingTable={loading}
          dataTable={unitList}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          totalRecords={totalPage}
          scroll={{ x: 1500, y: 600 }}
          pagination={true}
          hideSelection={false}
          handleTableChangeProps={handleTableChange}
        />
      ),
    },
    {
      key: "2",
      label: "Đã duyệt",
      children:
        <TableData
          dataColumns={dataColumns}
          loadingTable={loading}
          dataTable={unitList}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          totalRecords={totalPage}
          scroll={{ x: 1500, y: 600 }}
          pagination={true}
          hideSelection={false}
          handleTableChangeProps={handleTableChange}
        />
    },
  ];

  const handleCloseFilter = () => {
    setIsShowFilter(false);
  };

  const clearFilter = () => {
    setPageNumber(1);
    form.resetFields();
    fetchData();
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const onFilterSubmit = () => {
    setPageNumber(1);
    fetchData();
  };

  return (
    <Layout className="page-header-group school-level">
      <Form.Provider>
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <div className="flex gap-8">
              <Button className="filter-button" onClick={handleShowFilter}>
                <Typography.Text>Bộ lọc</Typography.Text>
                <DownOutlined />
              </Button>
              <FormInput
                placeholder="Nhập mã, tên hoặc mô tả để tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
                style={{ width: "400px" }}
              />
              <Button type="primary"
                onClick={() => setIsApproveMultiple(true)}
              >Duyệt nhanh</Button>
            </div>
            {isShowFilter ? (
              <FormFilter
                handleCloseFilter={handleCloseFilter}
                clearFilter={clearFilter}
                form={form}
                showFilter={isShowFilter}
                tab={tab}
                filterData={filterData}
                setFilterData={handleChangeFilter}
                onFinish={onFilterSubmit}
                isMoreFilters={false}
                hasSharer={true}
              />
            ) : null}
          </div>
        </div>
      </Form.Provider>
      <>
        <Tabs items={items}
          onChange={tab => setTab(tab)}
          defaultActiveKey={tabLink}
        />
        <ApproveMulti open={isApproveMultiple}
          handleClose={() => setIsApproveMultiple(false)}
          shareType={1}
        />
      </>
    </Layout>
  );
};

export default DepartmentLevel;
