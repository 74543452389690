import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import PageHeader from "../../../../../components/page-header/PageHeader";
import { Button, Layout, Spin } from "antd";
import QuizReportChart from "./quiz-report-chart/QuizReportChart";
import QuizReportTable from "./quiz-report-table/QuizReportTable";
import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAllQuizBySurvey } from "../../../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz";
import { get, size } from "lodash";
import { getChartSurveyAnswers } from "../../../../../service/course-construct/survey-plan";

const {Content} = Layout;
const imgPath = "/images/";

const SurveyReportQuiz = () => {
    const [selectedQuiz, setSelectedQuiz] = useState(0);
    const [isChart, setIsChart] = useState(true);
    const [listQuiz, setListQuiz] = useState<any>([]);
    const [fullListDataQuiz, setFullListDataQuiz] = useState<any>([]);
    const [isShowListAnswers, setIsShowListAnswers] = useState(true);
    const [quizType, setQuizType] = useState(0);
    const [quizName, setQuizName] = useState("");
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const isFirstRender = useRef(true);

    const handleChangeQuiz = (e: any) => {
        setSelectedQuiz(e);
        const quiz = fullListDataQuiz.find((item: any) => item.id === e);
        setQuizName(quiz.question?.replace(/<\/?[^>]+(>|$)/g, ""));
        setQuizType(quiz.type);
        
        if(quiz.type === 1 || quiz.type === 2 || quiz.type === 3 || quiz.type === 4 || quiz.type === 5 || quiz.type === 6 || quiz.type === 7 ||quiz.type === 8) {
            setIsChart(true);
            if(e === 4) {
                setIsShowListAnswers(false);
            } else {
                setIsShowListAnswers(true);
            }
        } else {
            setIsChart(false);
            setIsShowListAnswers(false);
        }
    }

    // use Effect
    useEffect(() => {
        if(location.state?.quizId) {
            setLoading(true);
            try {
                getAllQuizBySurvey({id: location.state?.quizId}).then((res) => {
                    if(res.status === 200) {
                        const dataQuiz = res.data?.flatMap((item: any) => item.quizzes || []);
                        const listQuiz = dataQuiz.map((quiz: any) => {
                            return {
                                code: quiz.id,
                                name: quiz.question?.replace(/<\/?[^>]+(>|$)/g, ""),
                            }
                        })
                        setFullListDataQuiz(dataQuiz);
                        setListQuiz(listQuiz);
                        setTimeout(() => {
                            setLoading(false);
                        }, 333);
                    }
                })               
            } catch (err) {
                setLoading(false);
                console.error(err);
            }
        }
    }, [location.state?.quizId]);

    useEffect(() => {
        if (listQuiz.length > 0 && isFirstRender.current) {
            handleChangeQuiz(listQuiz[0]?.code);
            isFirstRender.current = false;
        }
    }, [listQuiz]);
    
    return (
        <>
            <Content>
                <PageHeader 
                    title={`Báo cáo thống kê câu trả lời`}
                    desTitle={`${location.state?.quizDetail?.name}`}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    headerGroupButton={true}
                    childrenGroupButton={
                        loading  
                            ?
                                <div className="report-quiz-loading-header">
                                    <Spin />
                                </div>
                            :
                                <div className="button-container">
                                    <FormItemDropdown 
                                        options={listQuiz}
                                        defaultValue={listQuiz[0]?.code}
                                        className="dropdown-list-quiz"
                                        placeholder="Chọn câu hỏi"
                                        onChange={handleChangeQuiz}
                                    />
                                </div>
                    }
                />
                {
                    loading 
                        ?
                            <div className="report-quiz-loading flex">
                                <Spin />
                            </div>
                        :
                            selectedQuiz ? 
                                (isChart ? 
                                    <QuizReportChart 
                                        quizName={quizName}
                                        quizType={quizType} 
                                        selectedQuiz={selectedQuiz}
                                        isShowListAnswers={isShowListAnswers}
                                    /> 
                                    : 
                                    <QuizReportTable 
                                        quizName={quizName}
                                        quizType={quizType} 
                                        selectedQuiz={selectedQuiz}
                                    />
                                ) 
                                    : 
                                <></>
                }
                
            </Content>
        </>
    )
}

export default SurveyReportQuiz;