import { CourseDetail } from "./../../types/course";
import { StateCreator } from "zustand";
import {
  GroupIndustry,
  IndustryInfo,
  TrainingLevel,
  UniversityInfo,
} from "../../types/course";

export interface courseState {
  industries: IndustryInfo[];
  universities: UniversityInfo[];
  groupIndustry: GroupIndustry[];
  trainingLevelsFilter: TrainingLevel[];
  industriesFilterUniversity: IndustryInfo[];
  courseDetail: CourseDetail;

  setIndustries: (data: IndustryInfo[]) => void;
  setUniversities: (data: UniversityInfo[]) => void;
  setGroupIndustry: (data: GroupIndustry[]) => void;
  setTrainingLevelFilter: (data: TrainingLevel[]) => void;
  setIndustriesFilterUniversity: (data: IndustryInfo[]) => void;
  setCourseDetail: (data: CourseDetail) => void;
}

export const coursesSlice: StateCreator<courseState> = (set) => ({
  industries: [],
  universities: [],
  groupIndustry: [],
  trainingLevelsFilter: [],
  industriesFilterUniversity: [],
  courseDetail: {},

  setIndustries: (data: IndustryInfo[]) => {
    set(() => ({ industries: data }));
  },
  setUniversities: (data: UniversityInfo[]) => {
    set(() => ({ universities: data }));
  },
  setGroupIndustry: (data: GroupIndustry[]) => {
    set(() => ({ groupIndustry: data }));
  },
  setTrainingLevelFilter: (data: TrainingLevel[]) => {
    set(() => ({ trainingLevelsFilter: data }));
  },
  setIndustriesFilterUniversity: (data: IndustryInfo[]) => {
    set(() => ({ industriesFilterUniversity: data }));
  },
  setCourseDetail: (data: CourseDetail) => {
    set(() => ({ courseDetail: data }));
  },
});
