import { Flex, Input, Modal, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { getSettingCourseList } from "../../service/setting-course-structure";
import { SearchProps } from "antd/es/input";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routesConfig } from "../../config/routes";
const { Title, Text } = Typography;
const { Search } = Input;

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleNotice: (type: any, message: string) => void;
  numberTab: string;
  handleRefresh: () => void;
}

const ModalSettingCourse = ({
  isOpen,
  handleClose,
  handleNotice,
  numberTab,
  handleRefresh,
}: IProps) => {
  const [dataSetting, setDataSetting] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sort, setSort] = useState<string>("desc");
  const ulRef = useRef(null);
  const [isStopScroll, setIsStopScroll] = useState(false);

  const [pageSize, setPageSize] = useState<number>(20);

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };

  const handleScroll = () => {
    const ulElement: any = ulRef.current;
    if (
      ulElement?.scrollTop + ulElement?.clientHeight ===
      ulElement?.scrollHeight &&
      !isStopScroll
    ) {
      setPageSize((prevItems) => prevItems + 10);
    }
  };

  // Gắn sự kiện scroll vào window
  useEffect(() => {
    if (isOpen) {
      const ulElement: any = ulRef.current;
      ulElement.addEventListener("scroll", handleScroll);
      return () => ulElement.removeEventListener("scroll", handleScroll);
    }
  }, [pageSize,isOpen]);

  const fetchData = async () => {
    const param = {
      name: searchValue,
      skip: 0,
      take: pageSize,
    };
    try {
      const response = await getSettingCourseList(param);
      const { data } = response.data;
      setDataSetting(data);
      if (pageSize >= data?.total?._count?.id) {
        setIsStopScroll(true);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, [searchValue, pageSize]);
  const handleCancelModal = () => {
    handleClose();
    setPageSize(20)
  }
  return (
    <Modal
      className=""
      title="Danh sách khoá học"
      closeIcon={true}
      centered
      open={isOpen}
      onCancel={handleCancelModal}
      footer={""}
    >
      <div>
        <p className="pb-4">Chọn khoá học để cài đặt cấu trúc</p>
        <Search
          placeholder="Nhập từ khoá cần tìm "
          onSearch={onSearch}
        //   value={searchText}
        //   onChange={handleChangeSearch}
        />
        <Flex className="bg-[#FAFAFA] border-b py-4 mt-4">
          <div className="px-4 font-medium w-1/5 border-r">STT</div>
          <Flex className="px-4 font-medium w-4/5" justify="space-between">
            <p>Tên khoá học</p>
            {sort === "desc" ? (
              <div className="cursor-pointer" onClick={() => setSort("asc")}>
                <CaretUpOutlined />
              </div>
            ) : (
              <div className="cursor-pointer" onClick={() => setSort("desc")}>
                <CaretDownOutlined />
              </div>
            )}
          </Flex>
        </Flex>
        <div
          id="scrollList"
          ref={ulRef}
          className="max-h-[450px] overflow-auto"
        >
          {dataSetting?.map((data: any, index: number) => (
            <Link to={`/${routesConfig.settingStructureCourse}/${data?.id}`}>
              <Flex className="py-4 border-b hover:bg-[#E6F7FF]">
                <div className="px-4 w-1/5">
                  {index + 1 < 10 ? "0" + `${index + 1}` : index + 1}
                </div>
                <Flex className="px-4 w-4/5 text-[#FF4D4F]">
                  <img
                    className=""
                    loading="eager"
                    alt=""
                    src={"/images/ic_school_placeholder.svg"}
                  />
                  <div>
                    <span className="pl-2 text-black font-medium">
                      {data?.name}
                    </span>
                  </div>
                </Flex>
              </Flex>
            </Link>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ModalSettingCourse;
