import { Button, Col, DatePicker, message, Modal, Radio, RadioChangeEvent, Row, Space, TableColumnsType, TableProps, Tabs, TabsProps, TimePicker, Typography } from "antd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Dayjs } from "dayjs";
import TableData from "../../table-data/TableData";
import { DeleteOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import AddTopicToCourseModal from "./add-topic-to-course";
import AddNewTopicDrawer from "../../drawer/add-new-topic";

type Props = {
  open: boolean;
  closeModal: () => void;
  getListData: () => void;
  countChoose: any
};

export default function BankQuestionStorageModal(props: Props) {
  const [dataTable, setDataTable] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const [isOpenAddTopicToCourseModal, setIsOpenAddTopicToCourseModal] = useState(false)
  const [isOpenAddNewTopic, setIsOpenAddNewTopic] = useState(false)

  const {
    open,
    closeModal,
    countChoose,
    getListData
  } = props;

  const onOk = async () => {

  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Câu hỏi',
      dataIndex: "question",
      key: "question",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div dangerouslySetInnerHTML={{ __html: record?.question }}></div>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 5,
      render: (_, record, index) => {
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  if (!isEmpty(dataTable)) {
                    const arr = [...dataTable]
                    const newArr = arr.filter((item) => item.id !== record.id)
                    setDataTable(newArr)
                  }
                }}
              > 
                <DeleteOutlined className="text-red-500" />
              </a>
            </Space>
          </>
        );
      },
    }
  ]

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
    }));
  }, [dataTable]);

  useEffect(() => {
    if (countChoose) {
      setDataTable(countChoose)
    }
  }, [countChoose, open])

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {

  };

  const openModalAddTopic = () => {
    setIsOpenAddTopicToCourseModal(true)
  }

  const openModalAddNewTopic = () => {
    setIsOpenAddNewTopic(true)
  }

  return (
    <Modal
      className="bank-question-storage-modal"
      title={`Câu hỏi đã chọn ${dataTable.length}`}
      closeIcon={true}
      centered
      open={open}
      okText={"Xuất bản"}
      cancelText={"Huỷ"}
      onOk={onOk}
      onCancel={closeModal}
      footer={[
        <Button type="primary" onClick={openModalAddTopic}>
          Thêm vào đề có sẵn
        </Button>,
        <Button onClick={openModalAddNewTopic}>
          Tạo đề mới
        </Button>,
      ]}
    >
      <Row gutter={16}>
        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <TableData
            dataColumns={newColumns}
            dataTable={dataTable}
            loadingTable={loading}
            scroll={{ x: 400, y: 600 }}
            hideSelection={false}
            pagination={false}
            handleTableChangeProps={handleTableChange}
          />
        </Col>
      </Row>
      <AddTopicToCourseModal
        open={isOpenAddTopicToCourseModal}
        closeModal={() => {
          setIsOpenAddTopicToCourseModal(false)
          getListData()
        }}
        dataTable={dataTable}
      />

      <AddNewTopicDrawer
        open={isOpenAddNewTopic}
        handleClose={() => {
          setIsOpenAddNewTopic(false)
          getListData()
        }}
        dataTable={dataTable}
      />
    </Modal >

  );
}
