import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, message, Modal, Row, Typography } from "antd";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { jwtDecode } from 'jwt-decode';
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../../../components/form-input/FormInput";
import { routesConfig } from "../../../../config/routes";
import useModalConfirm from "../../../../hooks/useModalConfirm";
import {
  getRevokeLogOut,
  postLogout,
} from "../../../../layout/header/services";
import { useAuthStore } from "../../../../stores/stores";
import { regexPassword } from "../../../../utils/regex";
import { postChangePassword, postChangePasswordV2 } from "../../../login/components/login-page/services";
import "./ChangePassword.scss";

type ChangePasswordProps = {
  title?: string | ReactNode;
  open?: boolean;
  hideModal?: () => void;
  footer?: ReactNode;
  openModal?: () => void;
  initalValue?: any;
  config?: any;
  accessTokenNotLogin?: string;
};


const ChangePasswordModal: FunctionComponent<ChangePasswordProps> = ({
  title,
  open,
  hideModal,
  openModal,
  initalValue,
  config,
  accessTokenNotLogin
}) => {
  const showConfirm = useModalConfirm();
  const [form] = Form.useForm();
  const password = Form.useWatch("newPassword", form);
  const refreshToken = useAuthStore((state) => state.refreshToken);
  const logout = useAuthStore((state) => state.logout);
  const navigate = useNavigate();
  const [messageError, setMessageError] = useState<string>("");
  const accessToken = useAuthStore((state) => state.accessToken);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mustChangePass, setMustChangePass } = useAuthStore()

  //check xem đổi mật khẩu có cần quy tắc hay không
  const [rulePass, setRulePass] = useState<boolean>(true);

  const validate = {
    oldPassword: {
      required: true,
      message: "Vui lòng nhập mật khẩu hiện tại.",
    },
    newPassword: [
      {
        required: true,
        message: "Vui lòng nhập mật khẩu mới.",
      },
      ...(rulePass ?
        [
          {
            min: 8,
            max: 25,
            message: "Độ dài mật khẩu từ 8-25 ký tự.",
          },
          {
            pattern: regexPassword,
            message:
              "Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt, và có độ dài từ 8-25 ký tự.",
          },
        ]
        : []
      ),
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator(_: RuleObject, value: StoreValue) {
          if (value && getFieldValue("oldPassword") === value) {
            return Promise.reject(
              new Error("Mật khẩu mới phải khác mật khẩu hiện tại.")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    confirmPassword: {
      required: true,
      message: "Vui lòng nhập lại mật khẩu mới.",
    },
  };

  useEffect(() => {
    if (messageError) {
      form?.setFields([
        {
          name: "oldPassword",
          errors: [messageError],
        },
      ]);
    }
  }, [messageError, form]);

  const handleRevokeLogOut = async () => {
    if (refreshToken) {
      try {
        await getRevokeLogOut(refreshToken).then((res) => {
          if (res?.data?.statusCode == "200") {
            logout();
            setTimeout(() => {
              navigate(routesConfig.login);
            }, 1000);
          }
        });
      } catch (error) { }
    }
  };

  const logoutFNC = async () => {
    await postLogout().then((res) => {
      if (res.status === 200) {
        logout();
        setTimeout(() => {
          navigate(routesConfig.login);
        }, 1000);
      }
    });
  };

  const handleLogout = async () => {
    if (accessToken) {
      try {
        // console.log('accessToken', accessToken)
        await handleRevokeLogOut();
        // await logoutFNC();
      } catch (error) { }
    }
  };

  const handleSubmit = async (values: any) => {
    setMessageError("");
    setIsLoading(true);
    try {
      if (accessToken) {
        const body = {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        };
        await postChangePassword(body);
        form.resetFields();
        message.success(
          "Cập nhật mật khẩu thành công! Bạn sẽ chuyển đến trang đăng nhập trong 5 giây."
        );
        hideModal && hideModal();
        setTimeout(() => {
          handleLogout();
        }, 5000);
      } else {
        if (accessTokenNotLogin) {
          const user: any = jwtDecode(accessTokenNotLogin);
          const body = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            userId: user?.id
          };
          await postChangePasswordV2(body);
          form.resetFields();
          message.success("Cập nhật mật khẩu thành công!")
          hideModal && hideModal();
        }
      }
    } catch (error: any) {
      setMessageError(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open && config) {
      const obj = config.filter((item: any) => item.code === 'password_rule')
      setRulePass(obj?.[0]?.status)
    }
  }, [open, config])

  useEffect(() => {
    if (open && config) {
      const obj = config.filter((item: any) => item.code === 'password_rule')
      setRulePass(obj?.[0]?.status)
    }
  }, [open, config])

  return (
    <>
      <Modal
        title={
          <>
            <div className="header-title">{title}</div>
            <div className="hr-tag"></div>
          </>
        }
        open={open}
        onCancel={() => {
          hideModal && hideModal();
          form.resetFields();
          setMustChangePass(false)
        }}
        footer={
          <>
            <div className="btn-modal--footer">
              <Button
                className="btn btn-outlined"
                onClick={() => {
                  hideModal && hideModal();
                  form.resetFields();
                  setMustChangePass(false)
                }}
              >
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                htmlType="submit"
                onClick={() =>
                  showConfirm({
                    title: "Thay đổi mật khẩu",
                    description: <span>Bạn có xác nhận thay đổi mật khẩu</span>,
                    okText: "Đổi mật khẩu",
                    cancelText: "Huỷ",
                    type: "danger",
                    canDestroy: false,
                    icon: (
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/warning-orange-icon.svg`}
                      />
                    ),
                    onOk: () => {
                      form.submit();
                    },
                    onCancel() { },
                  })
                }
                loading={isLoading}
                style={{ maxWidth: "240px" }}
              >
                Lưu thay đổi
              </Button>
            </div>
          </>
        }
        centered
        className="change-password-modal"
      >
        <div className="form-wrapper">
          <Form
            form={form}
            layout={"vertical"}
            name="formChangePassword"
            initialValues={initalValue}
            onFinish={handleSubmit}
          >
            <Row gutter={16} justify={"start"} wrap={true}>
              <Col span={24}>
                <Form.Item
                  label="Mật khẩu hiện tại"
                  name="oldPassword"
                  rules={[validate.oldPassword]}
                  hasFeedback
                  style={{ marginTop: "0px" }}
                >
                  <FormInput
                    passwordInput={true}
                    placeholder="Nhập mật khẩu hiện tại"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Mật khẩu mới"
                  name="newPassword"
                  rules={validate.newPassword}
                  hasFeedback
                >
                  <FormInput
                    passwordInput={true}
                    placeholder="Nhập mật khẩu mới"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Xác nhận mật khẩu mới"
                  name="confirmPassword"
                  dependencies={["newPassword"]}
                  hasFeedback
                  rules={[
                    validate.confirmPassword,
                    ({ getFieldValue }) => ({
                      validator(_: RuleObject, value: StoreValue) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Mật khẩu không khớp.")
                        );
                      },
                    }),
                  ]}
                >
                  <FormInput
                    passwordInput={true}
                    placeholder="Nhập mật khẩu mới"
                  />
                </Form.Item>
              </Col>
            </Row>

            {
              rulePass ?
                <div className="condition-container">
                  <div
                    className={`password-check-message flex gap-8 ${password && password?.length >= 8 && password?.length < 26
                      ? "valid"
                      : "invalid"
                      }`}
                  >
                    {password && password?.length >= 8 && password?.length < 26 ? (
                      <CheckCircleFilled />
                    ) : (
                      <CloseCircleFilled />
                    )}
                    Độ dài mật khẩu từ 8-25 ký tự
                  </div>
                  <div
                    className={`password-check-message flex gap-8 ${regexPassword.test(password) ? "valid" : "invalid"
                      }`}
                  >
                    {regexPassword.test(password) ? (
                      <CheckCircleFilled />
                    ) : (
                      <CloseCircleFilled />
                    )}
                    Bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
                  </div>
                </div> : null
            }
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
