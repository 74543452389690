import React, { useEffect, useState } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import {
  Button,
  Flex,
  Form,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
  Spin,
} from "antd";
import { MINIMUM_COMPLETION_RATE } from "../../../../../constants/course";
import "./style.scss";
import RateScoreTable, { RateScoreProps } from "./components/RateScoreTable";
import { SaveOutlined } from "@ant-design/icons";
import ScoreFormula from "./components/ScoreFormula";
import {
  createOrUpdate,
  getCoefficientByCourse,
} from "../../../../../service/courses";
import { useParams } from "react-router-dom";
import { isNumber, toNumber } from "lodash";

interface RegularMaterialsProps {
  id: number;
  name: string;
  requireToComplete: number;
}

interface AchievementMaterialsProps {
  blockId: number;
  coefficient: number;
  courseScore: number;
  recordResults: number;
  unitName: string;
}

type FormulaInfo = {
  blockInfo: {
    blockId: number;
    coefficient: number;
  }[];
  formula: string;
};
function EvaluateSetting() {
  const [form] = Form.useForm();
  const params = useParams();
  const courseId = toNumber(params?.id);

  const minimumPerCompleteWatch = Form.useWatch("minimumPerComplete", form);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setIsRefetch] = useState<boolean>(false);

  /* value render first */
  const [evaluateSetting, setEvaluateSetting] = useState<any>();
  const [regularMaterials, setRegularMaterials] =
    useState<RegularMaterialsProps[]>();
  const [achievementMaterials, setAchievementMaterials] = useState<
    AchievementMaterialsProps[]
  >([]);
  /* ----------------- */

  const [gradingRules, setGradingRules] = useState<RateScoreProps[]>([]);
  const [formulaInfo, setFormulaInfo] = useState<FormulaInfo>();

  useEffect(() => {
    const getSettingEvaluateValue = async () => {
      if (courseId) {
        try {
          setLoading(true);
          const res = await getCoefficientByCourse(courseId);
          const resData = res.data.data;
          setEvaluateSetting(resData);
          setRegularMaterials(resData?.regularMaterials);
          setAchievementMaterials(resData?.achievementMaterials);
          form.setFieldsValue({
            minimumPerComplete: resData?.minimumPercentageComplete,
            requiredCourses:
              resData?.regularMaterials?.length > 0
                ? resData?.regularMaterials
                    ?.filter((item: any) => item?.requireToComplete === 1)
                    ?.map((item: any) => item?.id)
                : [],
            isHasCertification: resData?.isHasCertification,
            courseCredit: resData?.courseCredit,
            minimumGPA: resData?.minimumGPA,
          });
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }
    };
    getSettingEvaluateValue();
  }, [courseId, refetch]);

  useEffect(() => {
    if (achievementMaterials?.length) {
      for (let i = 0; i < achievementMaterials?.length; i++) {
        form.setFieldValue(
          `block-${achievementMaterials[i].blockId}`,
          achievementMaterials[i]?.courseScore
        );
      }
    }
  }, [achievementMaterials]);

  function convertBlockItems(
    obj: Record<string, any>
  ): Array<Record<string, any>> {
    return Object.entries(obj)
      .filter(([key]) => key.startsWith("block-")) // Lọc các key bắt đầu bằng "block-"
      .map(([key, value]) => {
        const newKey = key.replace("block-", ""); // Loại bỏ "block-"
        return { [newKey]: value };
      });
  }

  const handleSaveEvaluate = async (values: any) => {
    const achievementMaterialsArray = convertBlockItems(values);
    const payload = {
      courseId: courseId,
      formula: formulaInfo?.formula,
      minimumPerComplete: values?.minimumPerComplete,
      minimumGPA: values?.minimumGPA,
      courseCredit: values?.courseCredit,
      isHasCertification: values?.isHasCertification,
      regularMaterials: values?.requiredCourses?.map((item: string) => ({
        id: item,
        requireToComplete: 1,
      })),
      achievementMaterials: achievementMaterialsArray?.map((item: any) => ({
        blockId: toNumber(Object.keys(item)[0]),
        courseScore: Object.values(item)[0],
        coefficient:
          formulaInfo?.blockInfo?.find(
            (it) => it.blockId === toNumber(Object.keys(item)[0])
          )?.coefficient || 0,
      })),
      gradingRules: gradingRules.map((item: RateScoreProps) => ({
        id: isNumber(item?.id) ? item.id : null,
        letterScale: item.letterScale,
        numberingScaleFrom: item.numberingScaleFrom,
        numberingScaleTo: item.numberingScaleTo,
      })),
    };

    try {
      setIsSubmitting(true);
      await createOrUpdate(payload);
      message.success("Thực hiện thành công");
      setIsRefetch(!refetch);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="evaluate-page-setting">
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleSaveEvaluate}>
          <CustomCard title="Điều kiện đánh giá">
            <p className="mt-2 text-14 font-weight-5">
              Điểu kiện hoàn thành môn học
            </p>

            <div className="evaluate-container mt-1 mb-3">
              <Flex align="center" gap={16}>
                <span className="text-14">Tỷ lệ hoàn thành tối thiểu</span>{" "}
                <Form.Item name={"minimumPerComplete"} initialValue={100}>
                  <Select
                    placeholder="Chọn tỷ lệ"
                    style={{ width: 140 }}
                    options={MINIMUM_COMPLETION_RATE}
                  />
                </Form.Item>
              </Flex>
              <p className="text-12 text-italic mt-1">
                Tỉ lệ phần trăm học liệu tối thiểu yêu cầu học viên phải xem
              </p>

              <Flex align="center" className="mt-3" gap={16}>
                <span className="text-14">Học liệu bắt buộc hoàn thành </span>{" "}
                <Form.Item name={"requiredCourses"}>
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    placeholder="Chọc học liệu"
                    disabled={minimumPerCompleteWatch === 100}
                    style={{ width: 300 }}
                    options={regularMaterials?.map(
                      (item: RegularMaterialsProps) => ({
                        label: item.name,
                        value: item.id,
                      })
                    )}
                  />
                </Form.Item>
              </Flex>
              <p className="text-12 text-italic mt-1">
                Các học liệu bắt buộc yêu cầu học viên phải xem mới được coi là
                hoàn thành môn học
              </p>
            </div>

            <p className="mt-5 text-14 font-weight-5">Điểu kiện qua môn</p>

            <div className="evaluate-container mt-1">
              <p className="text-14 mt-1">Điểm học phần</p>
              <p className="text-12 text-italic">
                Học viên cần đạt điểm tối thiểu {`(>=)`} được cài đặt tại đây để
                coi là đạt
              </p>

              {achievementMaterials?.map((item: AchievementMaterialsProps) => (
                <Flex
                  align="center"
                  className="mt-1"
                  gap={16}
                  key={item?.blockId}
                >
                  <span className="text-14 text-italic">{item?.unitName} </span>{" "}
                  <Form.Item name={`block-${item.blockId}`}>
                    <InputNumber style={{ width: 140 }} max={10} min={0} />
                  </Form.Item>
                </Flex>
              ))}

              <div className="mt-3">
                <p className="text-14">
                  <span className="text-danger">*</span> Điểm GPA
                </p>
                <div className="text-14 mt-1">
                  Công thức tính GPA
                  <span className="ml-3 text-12 text-italic">
                    Điểm GPA cuối môn sẽ được tính theo công thức dưới đây
                  </span>
                  <ScoreFormula
                    formulaQuiz={evaluateSetting?.formulaQuiz}
                    courses={achievementMaterials}
                    onChange={setFormulaInfo}
                  />
                </div>

                <Flex align="center" className="mt-3" gap={16}>
                  <span className="text-14">Điểm GPA tối thiểu cần đạt </span>{" "}
                  <Form.Item
                    name={"minimumGPA"}
                    initialValue={0}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập điểm GPA",
                      },
                    ]}
                  >
                    <InputNumber
                      max={10}
                      min={0}
                      step={0.1}
                      style={{ width: 140 }}
                    />
                  </Form.Item>
                </Flex>
              </div>
            </div>
          </CustomCard>

          <CustomCard
            className="mt-2"
            title={
              <span>
                <span className="text-danger">*</span>Khoá học cấp chứng nhận
                khi học viên hoàn thành ?
              </span>
            }
          >
            <Form.Item
              name={"isHasCertification"}
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn cấp chứng nhận",
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical" className="mt-2">
                  <Radio value={0}>Không</Radio>
                  <Radio value={1}>Có</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </CustomCard>

          <CustomCard className="mt-2" title="Quy tắc quy đổi điểm">
            <div>
              <RateScoreTable
                data={evaluateSetting?.gradingRules}
                onChange={setGradingRules}
              />
            </div>
          </CustomCard>

          <CustomCard className="mt-2">
            <Flex gap={6} align="center" className="text-16 font-bold">
              <span>Số tín chỉ học viên tích luỹ được:</span>{" "}
              <Form.Item
                name={"courseCredit"}
                initialValue={0}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tín chỉ",
                  },
                ]}
              >
                <InputNumber min={0} step={1} />
              </Form.Item>
              <span>tín chỉ</span>
            </Flex>
          </CustomCard>

          <Flex className="mt-4 mb-4" align="center" gap={16}>
            <Button
              htmlType="submit"
              icon={<SaveOutlined />}
              className="btn-primary"
              loading={isSubmitting}
            >
              Lưu
            </Button>
            <Button className="btn-secondary">Huỷ</Button>
          </Flex>
        </Form>
      </Spin>
    </div>
  );
}

export default EvaluateSetting;
