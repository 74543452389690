import { Button, Modal, Spin } from "antd";
import "./CertificateModal.scss";
import { useEffect, useState } from "react";
import { getStudentCertificate } from "../../../../../service/course-management";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

type Props = {
  open?: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  title?: string;
  content?: string;
  confirmIcon?: string;
  btnCancel?: boolean;
  confirmBtntitle?: string;
  specialClass?: string;
  loading?: boolean;
  studentId: number;
};

type CertificateDetail = {
  id: number;
  certificateTemplate: string;
  studentId: number;
  fullName: string;
  courseId: number;
  courseName: string;
  issueDate: string;
  dayFinished: string;
};

const CertificateModal = (props: Props) => {
  const { open, handleOk, handleCancel, title, content, studentId } = props;
  const [certificateDetail, setCertificateDetail] = useState<CertificateDetail>();
  const [certificateContent, setCertificateContent] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const replacements = [
    { componentClass: "student-name-certificate", newText: certificateDetail?.fullName },
    { componentClass: "date-certificate", newText: dayjs(certificateDetail?.issueDate).format("DD/MM/YYYY") },

  ];

  const replaceContent = (content: string) => {
    let updatedContent = content;

    replacements.forEach((replacement) => {
      const regex = new RegExp(
        `(<[^>]*component-class="${replacement.componentClass}"[^>]*>)(.*?)(</[^>]+>)`,
        "g"
      );
      updatedContent = updatedContent.replace(regex, (match, startTag, existingText, endTag) => {
        return `${startTag}${replacement.newText}${endTag}`;
      });
    });

    return updatedContent;
  };
  useEffect(() => {
    const fetchCertificate = async () => {
      setLoading(true);
      try {
        const payload = {
          courseId: id,
          studentId: studentId,
        };
        const res = await getStudentCertificate(payload);
        setCertificateDetail(res?.data.data);
      } catch (error) {
        console.error("Error fetching certificate:", error);
      } finally {
        setLoading(false);
      }
    };
    if (open) {
      fetchCertificate();
    }
  }, [open, studentId, id]);


  useEffect(() => {
    if (certificateDetail?.certificateTemplate) {
      setTimeout(() => {
        setCertificateContent(replaceContent(certificateDetail?.certificateTemplate));
      }, 0);
    }
    else setCertificateContent("")
  }, [certificateDetail?.certificateTemplate, id, studentId, certificateDetail]);
  return (
    <Modal
      open={open}
      className={`certificate-modal`}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={null}
    >
      {loading ? <div className="certificate-loading"><Spin /></div> : <>
        <div className="confirm-modal-title">{title}</div>
        <div className="confirm-modal-content mb-3">{content}</div>
        <div
          className="certificate"
          dangerouslySetInnerHTML={{ __html: certificateContent }}
        /></>}

    </Modal>
  );
};

export default CertificateModal;
