export const dataStatus = [
  {
    value: true,
    label: "Hoạt động",
  },
  {
    value: false,
    label: "Không hoạt động",
  },
];

export const validateFieldCreate = [
  "ten",
  "moTa",
  "quyens",
  "trangThai",
  "nguoiDungs",
  "defaultGv",
  "defaultQtcs",
  "defaultSv",
  "macDinh",
  "defaultRoleMain",
];

export const validateFieldFilter = ["quyens", "nguoiDungs", "trangThai"];
