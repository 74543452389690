import { message } from "antd";

export const exportFileExcel = async (API: any, dataReq: any, fileName: string, setIsRendering: (value: boolean) => void) => {
    setIsRendering(true);
    try {
      await API(dataReq).then((res: any) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
  
        downloadLink.download = `${fileName}.xlsx`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        // message.success("Tải xuống thành công!");
        setIsRendering(false);
      })
    } catch (err) {
      setIsRendering(false);
      console.error("Error downloading Excel file:", err);
    }
}