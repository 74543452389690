// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-academic-level .academic-level-name {
  font-size: 16px;
  font-weight: 500;
  color: #1677ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%; }

.title-sequence {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/academic-level-management/academic-level-management.scss"],"names":[],"mappings":"AAAA;EAEQ,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,cAAc;EACd,eAAe,EAAA;;AAGvB;EACI,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe,EAAA","sourcesContent":[".table-academic-level {\n    .academic-level-name {\n        font-size: 16px;\n        font-weight: 500;\n        color: #1677ff;\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        display: block;\n        max-width: 100%;\n    }\n}\n.title-sequence{\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
