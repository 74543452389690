import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ConfigProvider } from "antd";
import { DirtyProvider } from "./contexts/DirtyProvider";
import katex from "katex";
import "katex/dist/katex.min.css";
import WebSocketComponent from "./components/websocket/WebSocketComponent";
import { useEffect } from "react";
import { getPermission } from "./service/common";
import { globalStore, useAuthStore } from "./stores/stores";
// @ts-ignore */
window.katex = katex;

function App() {
  const { setPersonalInfo, reloadAdminInterface } = globalStore();
  const accessToken = useAuthStore((state) => state.accessToken);
  useEffect(() => {
    window.document.title = `${process.env.REACT_APP_PROJECT_NAME || ""}`;
  }, []);

  useEffect(() => {
    if (accessToken) {
      const getPersonalInfo = async () => {
        await getPermission().then((res) => {
          if (res.status === 200) {
            setPersonalInfo(res.data?.data);
          }
        });
      };
      getPersonalInfo();
    }
  }, [reloadAdminInterface, accessToken]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Roboto",
          },
        }}
      >
        <DirtyProvider>
          <WebSocketComponent>
            <RouterProvider router={router} />
          </WebSocketComponent>
        </DirtyProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
