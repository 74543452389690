import {
  Button,
  Col,
  ColorPicker,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
// import "./index.scss"

import { FileType } from "../../../utils/uploadImage";
import {
  createPortalAdministration,
  getAllUniversity,
  getDetailPortalAdministration,
  updatePortalAdministration,
} from "../../../service/portal-administration";
import { isEmpty } from "lodash";
import { Tooltip } from "antd/lib";

type Props = {
  open: boolean;
  handleClose: () => void;
  getListData: () => void;
  id: string | undefined;
  idDetail: number;
};

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Bạn chỉ có thể upload file ảnh");
    return isJpgOrPng;
  }
  return false;
};

export default function AddPortalAdminstrationDrawer(props: Props) {
  const { open, handleClose, getListData, idDetail } = props;
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");

  const [listSchool, setListSchool] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [color, setColor] = React.useState<string>("#1677ff");

  const validation = {
    universityName: {
      required: true,
      message: "Vui lòng nhập trường đại học",
    },
    logoUniversity: {
      required: idDetail && idDetail !== 0 ? false : true,
      message: "Vui lòng tải logo trường đại học",
    },
    url: {
      required: true,
      message: "Vui lòng nhập url",
    },
  };

  const getUniversity = async () => {
    await getAllUniversity(searchValue).then((res) => {
      setListSchool(res.data.data);
    });
  };

  const getDetailPortalAdmin = async () => {
    await getDetailPortalAdministration(idDetail).then((res) => {
      const { data } = res.data;
      if (res.status === 200) {
        form.setFieldsValue({
          universityName: data?.enterpriseId,
          url: data?.slug,
          mainColor: data?.color,
        });
        setImageUrl(data?.logo);
      }
    });
  };

  useEffect(() => {
    form.setFieldValue("typeStatus", 1);
  }, []);

  useEffect(() => {
    if(!idDetail){
      form.resetFields()
      setImageUrl("")
      setSearchValue("")
      setColor("1677ff")
    }
  }, [idDetail])

  useEffect(() => {
    getUniversity();
    if (idDetail && idDetail !== 0) {
      getDetailPortalAdmin();
    }
  }, [open]);

  const handleChange: UploadProps["onChange"] = (info) => {
    setLoading(true);
    if (
      info.fileList?.[0]?.type === "image/jpeg" ||
      info.fileList?.[0]?.type === "image/png"
    ) {
      getBase64(info.fileList?.[0]?.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    } else {
      form.setFieldValue("universityLogo", null);
      setImageUrl("");
      setLoading(false);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === "form-add-portal-adminstration") {
          const arr = listSchool.filter(
            (item: any) => item.id === values.universityName
          );
          const formData = new FormData();

          const data = values.url.trim().split(" ");
          const newData = data?.filter((item: any) => item).join("-");

          if (!isEmpty(values.universityLogo?.fileList)) {
            formData.append(
              "logo",
              values.universityLogo?.fileList[0].originFileObj ?? null
            );
          }
          formData.append("uuid ", values.universityName ?? null);
          formData.append("slug ", newData ?? null);
          formData.append("color ", color ?? null);

          if (idDetail && idDetail !== 0) {
            await updatePortalAdministration(formData).then((res) => {
              if (res.status === 200) {
                message.success("Cập nhật cổng thông tin thành công");
                form.resetFields();
                handleClose();
                getListData();
                setSearchValue("");
                setImageUrl("");
              }
            });
          } else {
            await createPortalAdministration(formData).then((res) => {
              if (res.status === 200) {
                message.success("Tạo cổng thông tin thành công");
                form.resetFields();
                handleClose();
                getListData();
                setSearchValue("");
                setImageUrl("");
              }
            });
          }
        }
      }}
    >
      <Drawer
        title={
          idDetail && idDetail !== 0
            ? "Sửa cổng thông tin"
            : "Thêm cổng thông tin"
        }
        placement={"right"}
        className="add-portal-adminstration-drawer"
        width={600}
        onClose={handleClose}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button className="btn btn-primary" onClick={() => form.submit()}>
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
      >
        <>
          <Form
            layout={"vertical"}
            form={form}
            name="form-add-portal-adminstration"
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="universityName"
                  rules={[validation.universityName]}
                  label="Trường đại học"
                >
                  <FormItemDropdown
                    placeholder="Trường đại học"
                    onChange={setSearchValue}
                    options={listSchool}
                  />
                </Form.Item>
              </Col>
              <Col span={15} className="mt-2 flex">
                <Form.Item
                  name="universityLogo"
                  rules={[validation.logoUniversity]}
                  label="Logo trường"
                  hasFeedback
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept=".png,.svg,.jpg,.jpeg"
                    onChange={handleChange}
                    maxCount={1}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
                <Form.Item
                  label=""
                  className="flex items-center mt-3 ml-3 text-gray-500"
                >
                  <p>
                    Recommanded resolution <br /> is 640*640 with file size{" "}
                    <br /> less than 2MB, keep visual <br /> elements centered
                  </p>
                </Form.Item>
              </Col>
              <Col span={9} className="mt-2 flex">
                <Form.Item name="mainColor" label="Màu sắc chủ đạo" hasFeedback>
                  <ColorPicker
                    onChange={(c) => {
                      setColor(c.toHexString());
                    }}
                    value={color}
                    size="large"
                    showText
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="mt-2 flex">
                <Form.Item
                  name="url"
                  label="Url"
                  hasFeedback
                  rules={[validation.url]}
                >
                  <Input
                    maxLength={30}
                    addonBefore={
                      <Tooltip
                        title={`${process.env.REACT_APP_MOOCs_SV_URL}/school/search/university-template-detail?uuid=${idDetail}&slug=`}
                      >
                        <div className="max-w-[300px] break-words truncate">
                          {process.env.REACT_APP_MOOCs_SV_URL}
                          /school/search/university-template-detail?uuid=
                          {idDetail}&slug=
                        </div>
                      </Tooltip>
                    }
                    defaultValue=""
                    placeholder="daihoc1"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
    </Form.Provider>
  );
}
