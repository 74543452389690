import { commonMedia } from "./grapesjs-config";
export const nameCategoryBlocks = "Khối giao diện";
export const nameCategoryBlocks2 = "Giao diện mẫu";
export const nameCategoryTemplates = "Mẫu hoàn chỉnh";
export const nameContactBlock = "Khối liên hệ";
export const nameLinkedCourseBlock = "Khối khóa học mẫu";
export const nameLecturersBlock = "Khối giảng viên";
export const nameBasicBlock = "Giao diện cơ bản";
export const nameCountBlocks = "Khối thống kê mẫu";
export const nameRoadMapBlocks = "Khối lộ trình khóa học";
export const nameHeaderBlocks = "Giới thiệu chung";
export const nameRegisterBlocks = "Khối ghi danh";
export const nameAffiliatedBlocks = "Khối liên kết";
export const nameRegisterPolicyBlocks = 'Chính sách ghi danh';
export const nameConditionBlocks = 'Điều kiện về kỹ năng và kiến thức của khóa học';
export const nameOpinionBlocks = 'Ý kiến nhận xét';
export const nameQuestionBlocks = 'Câu hỏi';

export const generalHeaderIntroduction = (data?: any) => {
  const blockUI = {
    id: "header-block-id",
    label: "Phần đầu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body id="i6ll">
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font bg-red-700">
    <div id="ibtwy-2" class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <img id="i2fag-2" src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" class="w-8"/>
      <a id="ix94a-2" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"><span id="itksl-2" class="ml-3 text-xl text-white">Đại học Bách khoa Hà Nội</span></a>
      <nav id="ie4ll-2" class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400-flex flex-wrap items-center text-base justify-center">
        <a id="i9zse-2" class="text-white hover:bg-red-100 hover:text-red-700 p-4 rounded">Giới thiệu</a>
        <a id="i067i-2" class="text-white hover:bg-red-100 hover:text-red-700 p-4 bg-red-600 rounded">Khám phá</a>
        <a id="i722x-2" class="text-white hover:bg-red-100 hover:text-red-700 p-4 rounded" component-class="BlogHeaderLink" Uuid="${data}">Blog</a>
      </nav>
      <div component-class="HeaderLoginDiv">
        <button id="i37d7-2" class="px-4 py-2 rounded mr-2 bg-white text-red-700 hover:bg-red-100">Đăng nhập</button>
        <button id="ipphw-2" class="border px-4 py-2 rounded hover:bg-red-100 bg-red-700 border-white text-white hover:text-red-700">Đăng ký</button>
      </div>
    </div>
  </header>
  <section id="iayb-2" class="text-gray-600 body-font">
  </section>
  <section id="i4xe-2" class="text-gray-600 body-font">
  </section>
  <header id="ikzvv" class="text-gray-600 body-font shadow-md">
    <div id="i63k" class="container mx-auto">
      <div id="iqlb" class="flex">
        <!-- Filter Column -->
        <!-- Courses Column -->
      </div>
    </div>
    <footer id="i5d9ox" class="text-gray-600 body-font bg-gray-100">
    </footer>
  </header>
</body>
  `;

  return blockUI;
};

export const generalSearchCourseList = (data?: any) => {
  const blockUI = {
    id: "search-block-id",
    label: "Phần thanh tìm kiếm",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body id="i23n">
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font bg-red-700">
  </header>
  <div id="iuph" class="bg-red-600 flex flex-col items-center justify-center text-white py-24">
    <div id="i9fg" class="text-center max-w-2xl">
      <span id="ioszd" class="inline-block py-1 px-2 rounded text-xs font-medium tracking-widest mb-4">Danh sách khoá học</span>
      <h1 id="ir54" class="text-4xl font-bold mb-8">Tất cả khoá học
      </h1>
      <p id="i1l4g" class="text-lg mb-6">Với 1000+ các khoá học với nhiều chủ đề khác nhau
      </p>
      <div class="flex justify-center w-full">
        <input type="text" placeholder="Nhập từ khoá cần tìm" id="i8ujj" class="w-full md:w-1/2 px-4 py-2 text-gray-700 focus:outline-none mr-2 rounded"/>
        <button id="iytk3" class="text-white px-4 py-2 hover:bg-gray-700 rounded bg-red-400">Tìm kiếm</button>
      </div>
    </div>
  </div>
</body>
  `;

  return blockUI;
};

export const generalContentIntroduction = (data?: any) => {
  const blockUI = {
    id: "content-block-id",
    label: "Phần nội dung",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="ikzvv" class="text-gray-600 body-font shadow-md">
    <div id="i63k" class="container mx-auto">
      <div id="iqlb" class="flex">
        <!-- Filter Column -->
        <div id="i0ic" class="w-1/4 p-5 rounded-lg">
          <h2 id="i5yz3" class="text-xl font-bold mb-4 text-red-700">Bộ lọc
          </h2>
          <!-- Category Filter -->
          <div class="mb-6">
            <h3 id="ic9wb" class="font-semibold mb-2 text-red-700">Chuyên ngành
            </h3>
            <div id="ih3z9">
              <label id="innbn" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i7wqu" class="ml-2 text-gray-700">Photoshop (90)</span></label>
              <label id="ic24p" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ilhz8" class="ml-2 text-gray-700">Blender (80)</span></label>
              <label id="ii37x" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ita4e" class="ml-2 text-gray-700">Canva (80)</span></label>
              <label id="i7g9c4" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i1vajn" class="ml-2 text-gray-700">Dựng mô hình 3D (80)</span></label>
              <label id="ib7zf3" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ialpty" class="ml-2 text-gray-700">Thiết kế Website (80)</span></label>
            </div>
          </div>
          <!-- Rating Filter -->
          <div class="mb-6">
            <h3 id="iugib" class="font-semibold mb-2 text-red-700">Xếp hạng
            </h3>
            <div id="ieja8">
              <label id="iet7j" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">1 Sao</span></label>
              <label id="iavik" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">2 Sao</span></label>
              <label id="ifj6t" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">3 Sao</span></label>
              <label id="iwzbv" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">4 Sao</span></label>
              <label id="i5pqg" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">5 Sao</span></label>
            </div>
          </div>
          <!-- Price Filter -->
          <div class="mb-6">
            <h3 id="iyla3" class="font-semibold mb-2 text-red-700">Giá
            </h3>
            <div id="is3hk">
              <label id="iwujh" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">Miễn phí</span></label>
              <label class="inline-flex items-center mb-2"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">Trả phí</span></label>
            </div>
          </div>
          <!-- Course Type Filter -->
          <div class="mb-6">
            <h3 id="ivchk" class="font-semibold mb-2 text-red-700">Loại khoá học
            </h3>
            <div>
              <label id="io0y9" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i13ng" class="ml-2 text-gray-700">Tự do ghi danh</span></label>
              <label id="in3cj" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i8x4r" class="ml-2 text-gray-700">Thẻ ghi danh</span></label>
            </div>
          </div>
        </div>
        <!-- Courses Column -->
        <div id="iek3h" class="w-3/4 p-5">
          <div id="ilngk" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div id="iuf75s-2" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 id="ibrl-2-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ics33-2-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div id="iahwd-2" class="border-gray-200 border-opacity-60 flex">
                  <img id="ihxeuh-2" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p id="iut2a5-2" class="leading-relaxed text-base">
                    <span id="ibkvye-2" class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p id="id6wj-2-2" class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i5qqpy" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="iby9bo" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ig1e91" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ik1oql" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ieis6r" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i98as3" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i1ztmg" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i74fvg" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="im8mf9" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ifmq58" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ixm299" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i15hyl" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="iitoqb" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i4exk9" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i9nose" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i9sw46" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <!-- Course Card 1 -->
            <!-- Course Card 2 -->
            <!-- Course Card 3 -->
            <!-- Add more course cards as needed -->
          </div>
          <div id="icl2y" class="container mx-auto p-5">
            <!-- Pagination Section -->
            <div class="flex justify-between items-center mb-6">
              <!-- Items per page selection -->
              <div>
                <label for="itemsPerPage" id="iy572" class="mr-2 text-gray-700">Khoá học / trang</label>
                <select id="itemsPerPage" class="border-gray-300 rounded-md shadow-sm p-2 border"><option value="10">10</option><option value="20">20</option><option value="30">30</option><option value="40">40</option><option value="50">50</option></select>
              </div>
              <!-- Pagination controls -->
              <div class="flex items-center">
                <button id="i4pfe" class="px-4 py-2 text-gray-700 hover:bg-gray-300 rounded mx-4">Trang trước</button>
                <ul id="iq1qr" class="flex list-none border-gray-300 overflow-hidden rounded">
                  <li>
                    <button id="i5f7a" class="px-4 py-2 hover:bg-gray-300 rounded bg-red-100 text-red-700">1</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">2</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">3</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">4</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">5</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">6</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">7</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">8</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">9</button>
                  </li>
                  <li>
                    <button id="i3a0o" class="px-4 py-2 text-gray-700 hover:bg-gray-300">10</button>
                  </li>
                </ul>
                <button id="i2h0g" class="px-4 py-2 text-gray-700 hover:bg-gray-300 rounded mx-4">Trang sau</button>
              </div>
              <!-- Go to page selection -->
              <div>
                <label for="goToPage" id="ixggg" class="mr-2 text-gray-700">Đi đến trang</label>
                <input type="number" id="goToPage" min="1" max="10" class="w-16 border-gray-300 rounded-md shadow-sm p-2 border"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</body>
  `;

  return blockUI;
};

export const fieldCourse = (data?: any) => {
  const blockUI = {
    id: "categories-block-id",
    label: "Phần Nội dung",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
   <body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font bg-red-700">
  </header>
  <div data-gjs-editable="false" data-gjs-removable="false">
  
  <section id="iayb-2" class="text-gray-600 body-font" data-gjs-editable="false" data-gjs-removable="false">
    <div id="iz66-2" class="container px-5 mx-auto flex flex-wrap pt-8">
      <div id="ihji-2">
        <div id="icxj-2" class="flex flex-col mb-10 lg:items-start items-center">
        </div>
      </div>
      <div id="igyb-2" class="flex-grow w-full">
        <div id="i23y6h" class="border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2 text-red-700 px-4 bg-red-100">
          <span id="iynbp8">Tất cả</span>
        </div>
        <div id="ihicf" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="iyj56">Thiết kế đồ hoạ</span>
        </div>
        <div id="i3bfa" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ikidw">Thiết kế Website</span>
        </div>
        <div id="ixwck" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ia816">Công cụ thiết kế</span>
        </div>
        <div id="ismzm" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="i1bdi">Thiết kế Game</span>
        </div>
        <div id="inou9" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ivhf1">Thiết kế nội thất</span>
        </div>
        <div id="i86wm" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="iu0a9">Thiết kế thời trang</span>
        </div>
        <div id="istv9" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ih2jr">Công nghệ thông tin</span>
        </div>
        <div id="idbkx" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ip8db">Quản trị kinh doanh</span>
        </div>
        <div id="imfde" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="i2oxq">Khác (12)</span>
        </div>
        <div id="isjvj" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ih3n6">Xem thêm</span>
        </div>
      </div>
    </div>
  </section>
  <section id="iayb-2" class="text-gray-600 body-font" data-gjs-editable="false" data-gjs-removable="false">
    <div id="iz66-2" class="container px-5 mx-auto flex flex-wrap pt-8" >
      <div component-class="DetailProgramCourse"></div>
    </div>
  </section>
</body>
  `;

  return blockUI;
};

export const courseRecommendIntroduction = (data?: any) => {
  const blockUI = {
    id: "recommend-course-block-id",
    label: "Phần khóa học tiêu biểu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <section id="i4xe-2" class="text-gray-600 body-font">
    <div id="ixht-2" class="container px-5 mx-auto py-8">
      <div id="ieau9-2" class="flex flex-col w-full mb-8">
        <h1 id="i11l-2" class="text-2xl font-medium title-font mb-4 sm:text-3xl">Khoá học để bạn bắt đầu
        </h1>
      </div>
      <div id="iccjpk" class="flex flex-wrap -m-4">
        <div id="iuf75s" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ibrl-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ics33-2" class="text-gray-900 title-font text-lg font-medium mb-2" editable="false">Nhập môn vẽ kỹ thuật số - Digital Painting 123

            <br/>
              <br/>
            </h2>
            <div id="iahwd" class="border-gray-200 border-opacity-60 flex">
              <img id="ihxeuh" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
              <p id="iut2a5" class="leading-relaxed text-base">
                <span id="ibkvye" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="id6wj-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iq9zvy" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ih28xq" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i1e1uh" class="text-gray-900 title-font text-lg font-medium mb-2">Làm quen với phần mềm chỉnh sửa ảnh Lightroom
            </h2>
            <div id="inr971" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="iokrd1" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ipqjm2" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="itkivg" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i15vh" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="i098d-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-6-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ij199-2" class="text-gray-900 title-font text-lg font-medium mb-2">Thiết kế Website bán hàng chuyên nghiệp
              <br/>
              <br/>
            </h2>
            <div id="if7y35" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i4txwx" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iqhw6l" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i3a6y-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i5qu3n" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="i9va6q" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i6lj8v" class="text-gray-900 title-font text-lg font-medium mb-2">Chỉnh sửa, cắt ghép ảnh với Adobe Photoshop
            </h2>
            <div id="ivfmrv" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ilvuxk" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iulh7j" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i68u45" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iheeib" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ic7gs-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-4-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ib1uf-2" class="text-gray-900 title-font text-lg font-medium mb-2">Làm quen với phần mềm chỉnh sửa ảnh Lightroom
            </h2>
            <div id="iidcz7" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ixu3b8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="i1ew2t" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iv1io-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ix582s" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ihl1k2" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ievwtw" class="text-gray-900 title-font text-lg font-medium mb-2">Kỹ năng Canva cơ bản
              <br/>
              <br/>
            </h2>
            <div id="i8gzy9" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i61mtl" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iher7n" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iwwxlh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ipw3c3" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="iy6wp-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-5-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i7ebm-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
              <br/>
              <br/>
            </h2>
            <div id="iplgk8" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="igf0sj" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ikt5i5" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="ivug2-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ixb3fi" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ifdssg" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="io7pp9" class="text-gray-900 title-font text-lg font-medium mb-2">Cấu trúc dữ liệu và giải thuật 
              <br/>
              <br/>
            </h2>
            <div id="inoi9m" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="it7pp8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="il1fmf" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i52syh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <section id="iayb" class="text-gray-600 body-font">
        </section>
      </div>
    </div>
  </section>
</body>
  `;
  return blockUI;
};

export const generalFooterIntroduction = (data?: any) => {
  const blockUI = {
    id: "footer-block-id",
    label: "Phần cuối",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Hero Section with Background
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <footer id="i5d9ox" class="text-gray-600 body-font bg-gray-100">
    <div id="iy8na6" class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" id="i4mk8z" class="w-8"/>
      <div id="ijhjhp" class="w-90 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <span id="inz6nz" class="text-xl ml-8">Đại học Bách Khoa Hà Nội</span>
        <p id="ihqko5" class="text-sm text-gray-500 ml-8 mt-8">Bản quyền thuộc về Đại học Bách khoa Hà Nội
          <br/>Địa chỉ: Số 1 Đại Cồ Việt, Hai Bà Trưng, Hà Nội
          <br/>Điện thoại: 024 3869 4242
        </p>
      </div>
      <div id="iozwhq" class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
        <div id="im9se" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
          </nav>
        </div>
        <div id="iw0yh" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="i18zz" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Giới thiệu</a>
            </li>
            <li id="iu5b6" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Khám phá</a>
            </li>
            <li id="ivzynq" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Blog</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="ik4k7l" class="mb-4">
              <a id="iuw93t" class="text-gray-600 hover:text-gray-800">Chính sách</a>
            </li>
            <li id="iw2yl9" class="mb-4">
              <a id="ihhfkr" class="text-gray-600 hover:text-gray-800">Quyền riêng tư</a>
            </li>
            <li id="ipzazs" class="mb-4">
              <a id="i2jcjh" class="text-gray-600 hover:text-gray-800">Liên hệ</a>
            </li>
            <li id="iuvvzm">
            </li>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</body>`;

  return blockUI;
};

export const countBlockGenerete = (dataReview?: any, dataStudent?: any) => {
  const blockUI = {
    id: 'statistical-block-id',
    options: {
      category: nameCountBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'CountWrapperComponent',
        },
        components: `
        <div component-class='CountComponent' class="text-gray-600 body-font bg-purple-700">
          <div class="container px-5 mx-auto">
            <div class="flex flex-wrap">
              <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/cc48746b-1a10-403f-8884-40e7c212590cplay-circle.svg" class="mr-2 gjs-selected">
              <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="leading-relaxed text-base text-white">${dataReview?.count} giờ video theo yêu cầu</p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/1844bf06-85c1-4587-b330-4919ae963cacdownload-01.svg" class="mr-2">
              <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="leading-relaxed text-base text-white">107 nguồn tài nguyên có thể tải xuống</p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/f25aac1c-e691-4157-9355-7f5045c6755fuser-circle.svg" class="mr-2">
              <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="leading-relaxed text-base text-white">${dataStudent?.count} học viên đã đăng ký</p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/83bfc8b9-48f7-412b-a2c7-6885992c152eStar%20icon.svg" class="mr-2">
              <span data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-white">${dataReview?.rate === null ? 0 : dataReview?.rate} (58 bình luận)</span>
            </div>
          </div>
        </section>
        `,
      }
    },
  }
  return blockUI;
}

export const roadMapBlockGenerete = (data?: any) => {
  const blockUI = {
    id: 'roadmap-course-block-id',
    options: {
      category: nameRoadMapBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RoadMapWrapperComponent',
        },
        components: '',
      }
    }
  }
  blockUI.options.content.components = `
    <div component-class='RoadMapComponent' data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="text-gray-600 body-font gjs-selected">
    <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="flex justify-center my-8">
    <div data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-3xl font-medium text-purple-700">Lộ trình khoá học</div>
    </div>
    <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="container mx-auto p-4">
    <div data-gjs-highlightable="true" title="roadmap" data-gjs-title="roadmap" data-gjs-type="default" draggable="true" class="grid grid-cols-1 md:grid-cols-2 gap-8">
  `
  data.forEach((chapter: any) => {
    blockUI.options.content.components += `
      <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" name="section-unit" class="bg-white rounded-lg p-4 border-2">
      <h2 data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-xl font-bold text-purple-700 mb-4">${chapter.title}</h2>
      <ul data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="list-none p-0">
    `
    chapter.children.forEach((lecture: any) => {
      blockUI.options.content.components += `
        <li data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="mb-4 flex items-center">
          <a data-gjs-highlightable="true" data-gjs-type="link" draggable="true" href="#">
            <img data-gjs-type="image" draggable="true" src="https://via.placeholder.com/200x120" alt="Thumbnail bài giảng" class="w-40 rounded-md">
          </a>
          <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="ml-4">
            <h3 data-gjs-highlightable="true" title="roadmap-lesson" data-gjs-type="text" draggable="true" name='title-lesson' class="text-lg font-medium">${lecture.title}</h3>
            <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-gray-700">${lecture.description ? lecture.description : "Chưa có mô tả"}</p>
            <span data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-sm text-gray-300">10 phút</span>
          </div>
        </li>
      `
    })
    blockUI.options.content.components += `</ul></div>`
  });
  blockUI.options.content.components += `
    </div>
    </div>
    <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="flex justify-center my-8">
    <button data-gjs-highlightable="true" data-gjs-type="text" draggable="true" type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl">Xem thêm</button>
    </div>
    </div>
  `
  return blockUI;
}

export const contactBlockCustom = (data?: any) => {
  const blockUI = {
    id: 'contact-block-id',
    options: {
      category: nameContactBlock,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'ContactWrapperComponent',
        },
        components: `
          <div component-class="ContactComponent" class="text-gray-600 body-font relative">
            <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                    <iframe
                        frameborder="0"
                        width="100%"
                        height="100%"
                        title="map"
                        marginheight="0"
                        marginwidth="0"
                        scrolling="no"
                        src="https://maps.google.com/maps?&q=6 P. Lê Văn Thiêm, Thanh Xuân Trung, Thanh Xuân, Hà Nội&z=14&t=q&output=embed"
                        class="absolute inset-0"
                    ></iframe>
                    <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                        <div class="lg:w-1/2 px-6">
                            <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ĐỊA CHỈ</h2>
                            <p class="mt-1">6 P. Lê Văn Thiêm, Thanh Xuân Trung, Thanh Xuân, Hà Nội</p>
                        </div>
                        <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                            <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                            <a class="text-indigo-500 leading-relaxed">example@email.com</a>
                            <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">SỐ ĐIỆN THOẠI</h2>
                            <p class="leading-relaxed">123-456-7890</p>
                        </div>
                    </div>
                </div>
                <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                    <form>
                        <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Góp ý</h2>
                        <p class="leading-relaxed mb-5 text-gray-600">Mọi góp ý của bạn giúp chúng tôi hoàn thiện khoá học hơn</p>
                        <div class="relative mb-4">
                            <label for="Name" title="Tên" class="leading-7 text-sm text-gray-600">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />
                        </div>
                        <div class="relative mb-4">
                            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />
                        </div>
                        <div class="relative mb-4">
                            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                required
                                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                            ></textarea>
                        </div>
                        <button type="submit" class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Gửi</button>
                    </form>
                </div>
            </div>
          </div>
        `,
      },
    },
  }
  return blockUI;
};

export const headerBlockLogo = (data?: any) => {
  const blockUI = {
    id: 'header-block-logo',
    options: {
      category: nameHeaderBlocks,
      label: 'Logo',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'HeaderWrapperComponent',
        },
        components: `
          <header component-class="HeaderComponent" class="text-gray-600 body-font shadow-md">
            <div class="container mx-auto flex flex-wrap flex-col md:flex-row items-center p-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/5771d778-a594-4c68-b654-c2022f77db47FTU_logo_2020.png" class="w-10 h-10 mx-auto ml-0"/>
              <div class="ml-0 text-xl">Get new skills risk-free.
              </div>
            </div>
          </header>
        `,
      },
    },
  }
  return blockUI;
}

export const headerBlockName = (data?: any) => {
  const blockUI = {
    id: 'header-block-name',
    options: {
      category: nameHeaderBlocks,
      label: 'Tên',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'HeaderWrapperComponent',
        },
        components: `
          <header component-class="HeaderComponent" class="text-gray-600 body-font shadow-md">
            <div class="container mx-auto flex flex-wrap flex-col md:flex-row items-center p-4">
              <div class="ml-0 text-xl mx-auto text-bold font-bold text-red-800">Logo Trường Đại học Ngoại Thương</div>
              <div class="ml-0 text-xl">Get new skills risk-free.
              </div>
            </div>
          </header>
        `,
      },
    },
  }
  return blockUI;
}

export const registerBlock1 = (data?: any) => {
  const blockUI = {
    id: 'register-block-1-id',
    options: {
      category: nameRegisterBlocks,
      label: 'Giao diện 1',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterWrapperComponent',
        },
        components: `
          <div component-class="RegisterComponent" class="text-gray-600 body-font">
            <div class="container px-5 mx-auto flex flex-wrap py-8">
              <div class="flex flex-col flex-wrap lg:w-1/2 lg:text-left  border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
                <div class="flex flex-col mb-10 lg:items-start items-center">
                  <div class="flex-grow w-full">
                    <h2 class="text-black title-font font-medium text-2xl mb-4">What you learn
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will learn how to leverage the power of Python to solve tasks.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >You will build games and programs that use Python libraries.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will be able to use Python for your own work problems or personal projects.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will create a portfolio of Python based projects you can share.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Learn to use Python professionally, learning both Python 2 and Python 3!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Create games with Python, like Tic Tac Toe and Blackjack!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn advanced Python features, like the collections module and how to work with timestamps!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn to use Object Oriented Programming with classes!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand complex topics, like decorators.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand how to use both the Jupyter Notebook and create .py files</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg"class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Get an understanding of how to create GUIs in the Jupyter Notebook system!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Build a complete understanding of Python from the ground up!</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:w-1/3 shadow-lg">
                <div class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full">
                  <form>
                    <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
                    </iframe>
                    <p class="leading-relaxed text-gray-600 mb-4">Xem trước khoá học này
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span class="text-3xl">1.700.000</span>
                      </p>
                    </div>
                    <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8">Ghi danh ngay</button>
                    <p class="leading-relaxed text-gray-600 mb-4">Khoá học này bao gồm:
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/ae1cd3cd-020b-4b2a-b411-a69d0ea733f3play-circle.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>24 giờ video theo yêu cầu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img  src="https://s3.moooc.xyz/dev-stable/page-builder/c9768dd1-6262-4763-954f-651113dd82efdownload-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>07 nguồn tài nguyên có thể tải xuống</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/a4204056-4ac0-4e3e-bda6-9694d2b6dd66hourglass-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Quyền truy cập đầy đủ vĩnh viễn</span>
                      </p>
                    </div>
                    <divclass="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/1daf4632-0a4b-44d2-b932-8d2baf12fd54certificate-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Chứng chỉ khi hoàn thành</span>
                      </p>
                    </divclass=>
                  </form>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }

  return blockUI;
}

export const registerBlock2 = (data?: any) => {
  const blockUI = {
    id: 'register-block-2-id',
    options: {
      category: nameRegisterBlocks,
      label: 'Giao diện 2',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterWrapperComponent',
        },
        components: `
          <div component-class="RegisterComponent" class="text-gray-600 body-font">
            <div class="container px-5 mx-auto flex flex-wrap py-8">
              <div class="flex flex-col flex-wrap lg:w-1/2 lg:text-left  border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
                <div class="flex flex-col mb-10 lg:items-start items-center">
                  <div class="flex-grow w-full">
                    <h2 class="text-black title-font font-medium text-2xl mb-4">What you learn
                    </h2>
                    <div  class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >You will learn how to leverage the power of Python to solve tasks.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will build games and programs that use Python libraries.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will be able to use Python for your own work problems or personal projects.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will create a portfolio of Python based projects you can share.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn to use Python professionally, learning both Python 2 and Python 3!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Create games with Python, like Tic Tac Toe and Blackjack!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn advanced Python features, like the collections module and how to work with timestamps!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn to use Object Oriented Programming with classes!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand complex topics, like decorators.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg"class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand how to use both the Jupyter Notebook and create .py files</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Get an understanding of how to create GUIs in the Jupyter Notebook system!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Build a complete understanding of Python from the ground up!</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:w-1/3 shadow-lg">
                <div  class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full">
                  <form>
                    <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
                    </iframe>
                    <p class="leading-relaxed text-gray-600 mb-4">Xem trước khoá học này
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span class="text-3xl">1.700.000</span>
                      </p>
                    </div>
                    <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8">Ghi danh ngay</button>
                    <p class="leading-relaxed text-gray-600 mb-4">Khoá học này bao gồm:
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/ae1cd3cd-020b-4b2a-b411-a69d0ea733f3play-circle.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>24 giờ video theo yêu cầu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/c9768dd1-6262-4763-954f-651113dd82efdownload-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>07 nguồn tài nguyên có thể tải xuống</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/a4204056-4ac0-4e3e-bda6-9694d2b6dd66hourglass-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Quyền truy cập đầy đủ vĩnh viễn</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/1daf4632-0a4b-44d2-b932-8d2baf12fd54certificate-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Chứng chỉ khi hoàn thành</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }

  return blockUI;
}

export const registerBlock3 = (data?: any) => {
  const blockUI = {
    id: 'register-block-3-id',
    options: {
      category: nameRegisterBlocks,
      label: 'Giao diện 3',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterWrapperComponent',
        },
        components: `
         <div component-class="RegisterComponent" class="text-gray-600 body-font">
            <div  class="container px-5 mx-auto flex flex-wrap py-8">
              <div class="flex flex-col flex-wrap lg:w-1/2 lg:text-left  border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
                <div class="flex flex-col mb-10 lg:items-start items-center">
                  <div class="flex-grow w-full">
                    <h2 class="text-black title-font font-medium text-2xl mb-2">Mục tiêu chung
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Những kiến thức, kỹ năng để học tập hiệu quả</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Sẵn sàng tiếp nhận và thích nghi với công nghệ mới</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Biết phản biện với những tương tác trên mạng xã hội để sử dụng trong cuộc sống, trong công việc tương lai </span>
                      </p>
                    </div>
                    <h2 class="text-black title-font font-medium text-2xl mb-2">Mục tiêu về kiến thức
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Kiến thức cơ bản về thông tin và truyền thông</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Kiến thức cơ bản về công cụ xử lý thông tin (máy tính, thiết bị, các loại phần mềm…)</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Hiểu biết về các công nghệ hiện đại có vai trò quan trọng</span>
                      </p>
                    </div>
                    <h2 class="text-black title-font font-medium text-2xl mb-2">Mục tiêu về kỹ năng
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Tổ chức lưu trữ thông tin trên máy tính một cách an toàn và hiệu quả; sử dụng máy tính để giải quyết vấn đề thông dụng </span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Soạn thảo văn bản, tài liệu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Làm việc, quản lý dữ liệu hiệu quả qua bảng tính</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Chuẩn bị bài thuyết trình và trình chiếu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Thiết lập và sử dụng các công cụ cộng tác trực tuyến để nâng cao hiệu quả công việc</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="i8aa4m-2" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Build a complete understanding of Python from the ground up!</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mb-10 lg:mb-0 rounded-lg lg:w-1/3">
                <div class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full shadow-lg">
                  <form>
                    <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
                    </iframe>
                    <p class="leading-relaxed text-gray-600 mb-4">Video xem trước học phần này
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span class="text-3xl">Miễn phí</span>
                      </p>
                    </div>
                    <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8 w-full">Ghi danh ngay</button>
                    <p class="leading-relaxed text-gray-600 mb-4">Học phần này yêu cầu:
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-04-16T07%3A25%3A30.871170892Z/1b99052c-b320-4fc3-a6c5-0891c1d21a73book-open-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Phòng học lý thuyết</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-04-16T07%3A25%3A30.871170892Z/8d8b84eb-abaf-47d4-94b7-b84d165c29balaptop-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Phòng máy tính</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-04-16T07%3A26%3A44.451816467Z/535e8641-ceb6-4d0b-b1b7-6048fca6983fwifi.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Đầy đủ phần mềm</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }

  return blockUI;
}

export const registerPolicy = (data?: any) => {
  const blockUI = {
    id: 'register-policy-block-id',
    options: {
      category: nameRegisterPolicyBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterPolicyWrapperComponent',
        },
        components: `
         <section id="iayb-2" component-class="RegisterPolicyComponent" class="text-gray-600 body-font">
            <div id="idhf" class="container mx-auto px-5 py-12">
              <!-- Title -->
              <div id="iqzng" class="text-center mb-12">
                <h1 id="ijp3j" class="text-4xl font-bold text-purple-600">Chính sách ghi danh
                </h1>
                <p id="i8swl" class="text-gray-600 mt-4">Lựa chọn ghi danh khoá học phù hợp với bạn
                </p>
              </div>
              <!-- Pricing Comparison Table -->
              <div class="overflow-x-auto">
                <div class="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
                  <div class="p-4">
                    <div id="i61yv" class="flex justify-between items-center border-b border-gray-200">
                      <div id="iaw35" class="w-1/3 text-gray-600 font-bold">
                      </div>
                      <div id="i3oz6" class="w-1/3 text-center text-gray-600 font-bold py-4 bg-gray-100">Miễn phí
                      </div>
                      <div id="i3oiv" class="w-1/3 text-center font-bold py-4 bg-purple-600 text-white">Trả phí
                      </div>
                    </div>
                    <div class="divide-y divide-gray-200">
                      <!-- Feature 1 -->
                      <div id="ivns2" class="flex">
                        <div id="i4huh" class="w-1/3 text-gray-800 py-4">Truy cập vào nội dung học liệu
                        </div>
                        <div id="i7gth" class="w-1/3 text-center bg-gray-50 py-4">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                        <div id="itneu" class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <!-- Feature 2 -->
                      <div id="inazw" class="flex">
                        <div id="iet4k" class="w-1/3 text-gray-800 py-4">Được hỗ trợ từ hệ thống MOOCs
                        </div>
                        <div id="iumz9" class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" id="i9jrd" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                        <div id="ifjyr" class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <div id="i3t79" class="flex">
                        <div id="ivaz1" class="w-1/3 text-gray-800 py-4">Được hỗ trợ từ người hướng dẫn
                        </div>
                        <div id="i5we2" class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-red-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                            </path>
                          </svg>
                        </div>
                        <div id="i1o25" class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <!-- Feature 3 -->
                      <!-- Feature 4 -->
                      <div id="i1bvl" class="flex">
                        <div id="irv94" class="w-1/3 text-gray-800 py-4">Được chấm điểm bài kiểm tra, bài thi
                        </div>
                        <div id="i99vj" class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-red-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                            </path>
                          </svg>
                        </div>
                        <div id="ie8om" class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <!-- Feature 5 -->
                      <div id="iwzst" class="flex">
                        <div id="i6v46" class="w-1/3 text-gray-800 py-4">Được cấp chứng chỉ, chứng nhận sau khi hoàn thành khoá học
                        </div>
                        <div id="ioliv" class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-red-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                            </path>
                          </svg>
                        </div>
                        <div id="iibkp" class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <div id="ihrxj" class="flex">
                        <div id="irjm7" class="w-1/3 text-gray-800 py-4">
                        </div>
                        <div id="i1lnw" class="w-1/3 text-center py-4 bg-gray-50">
                          <button type="submit" id="ib5zmq" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl">Ghi danh</button>
                        </div>
                        <div id="iqcon" class="w-1/3 text-center py-4 bg-purple-50">
                          <button type="submit" id="imii9" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl">Ghi danh</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="icw9x" class="flex justify-center my-8">
            </div>
          </section>
        `,
      },
    },
  }

  return blockUI;
}

export const conditionBlockGenerete = (dataCourses: any[] = [], dataSkills: any[] = []) => {
  const blockUI = {
    id: 'condition-block-id',
    options: {
      category: nameConditionBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'ConditionWrapperComponent',
        },
        components: `
          <div component-class="ConditionComponent" id="iayb" class="text-gray-600 body-font">
            <div id="iz66" class="container px-5 mx-auto flex flex-wrap py-8">
              <div id="ihji" class="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:pt-6 lg:pl-6 lg:pr-6 lg:w-1/3">
                <div id="icxj" class="flex flex-col mb-10 lg:items-start items-center">
                  <div id="igyb" class="flex-grow w-full">
                    <h2 id="ieb3" class="text-black title-font font-medium text-2xl mb-2">Khóa học tiên quyết</h2>    
        `,
      },
    },
  }
  if (dataCourses.length > 0) {
    dataCourses.forEach((item: any) => {
      blockUI.options.content.components += `<div id="i23y6h" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span id="iynbp8">${item}</span></div>`;
    });
  } else {
    blockUI.options.content.components += `<div id="i23y6h" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span id="iynbp8">Mẫu khóa học</span></div>`;
  }


  blockUI.options.content.components += `</div></div></div>
    <div id="iirg5o" class="flex flex-col flex-wrap lg:text-left  border-2 rounded-lg lg:pt-6 lg:pl-6 lg:pr-6 lg:w-1/3">
      <div class="flex flex-col mb-10 lg:items-start items-center">
        <div id="idclsc" class="flex-grow w-full">
          <h2 id="ii6p4u" class="text-black title-font font-medium text-2xl mb-4">
            <span id="ik5dkm">Điều kiện kỹ năng</span>
          </h2>`;

  if (dataSkills.length > 0) {
    dataSkills.forEach((item: any) => {
      blockUI.options.content.components += `<div id="i23y6h" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span id="iynbp8">${item}</span></div>`;
    });
  } else {
    blockUI.options.content.components += `<div id="i23y6h" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span id="iynbp8">Mẫu kĩ năng</span></div>`;
  }



  blockUI.options.content.components += `</div></div></div>
    <div id="ig9dj-2" class="flex justify-center items-center lg:pt-6 lg:pl-6 lg:pr-6 lg:w-1/3 lg:pb-6">
      <img id="irac9-2" src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-08-13T02%3A56%3A33.939990152Z/873aa93c-a5eb-4b50-ae33-20d5f4f3140e449817739_2151883921849332_3253220698885439734_n.jpg"/>
    </div>
  </div></div>`;
  return blockUI;
}

export const affiliatedBlock = (data?: any) => {
  const blockUI = {
    id: 'affiliated-block-id',
    options: {
      category: nameAffiliatedBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'LinkedCourseComponent',
        },
        components: `
         <div component-class="AffiliatedComponent" id="i4xe" class="text-gray-600 body-font">
            <div id="ixht" class="container px-5 mx-auto py-8">
              <div id="ieau9" class="flex flex-col  w-full mb-20">
                <h1 id="i11l" class="text-2xl font-medium title-font mb-4 text-purple-700 sm:text-3xl">Liên kết
                </h1>
                <p id="i0m5" class="lg:w-2/3 mx-auto leading-relaxed text-base">Danh sách những khoá học chúng tôi khuyến nghị người học đạt được.
                </p>
              </div>
              <div class="flex flex-wrap -m-4">
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 id="ibrl" class="text-gray-500 text-xs tracking-widest title-font mb-1">DANH MỤC
                    </h3>
                    <h2 id="ics33" class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 1
                    </h2>
                    <p id="id6wj" class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 id="i098d" class="text-gray-500 text-xs tracking-widest title-font mb-1">
                    </h3>
                    <h3 id="ibrl-6" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">DANH MỤC
                    </h3>
                    <h2 id="ij199" class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 2
                    </h2>
                    <p id="i3a6y" class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 id="ic7gs" class="text-gray-500 text-xs tracking-widest title-font mb-1">
                    </h3>
                    <h3 id="ibrl-4" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">DANH MỤC
                    </h3>
                    <h2 id="ib1uf" class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 3
                    </h2>
                    <p id="iv1io" class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 id="iy6wp" class="text-gray-500 text-xs tracking-widest title-font mb-1">
                    </h3>
                    <h3 id="ibrl-5" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">DANH MỤC
                    </h3>
                    <h2 id="i7ebm" class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 4
                    </h2>
                    <p id="ivug2" class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }
  return blockUI;
}

export const opinionBlockCustom = (data?: any) => {
  const blockUI = {
    id: 'opinion-block-id',
    options: {
      category: nameOpinionBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'OpinionWrapperComponent',
        },
        components: `
          <div component-class="OpinionComponent" id="ikcpb" class="text-gray-600 body-font">
            <div id="idmrk" class="container px-5 mx-auto py-8">
              <h1 id="ij9mp" class="text-3xl font-medium title-font mb-12  text-purple-700">Ý kiến nhận xét
              </h1>
              <div class="flex flex-wrap -m-4">
                <div class="p-4 md:w-1/2 w-full">
                  <div class="h-full bg-gray-100 p-8 rounded">
                    <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                      </path>
                    </svg>
                    <p class="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
                    </p>
                    <a class="inline-flex items-center"><img alt="testimonial" src="https://dummyimage.com/106x106" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/><span class="flex-grow flex flex-col pl-4"><span class="title-font font-medium text-gray-900">Nguyễn Ngọc Tú</span><span id="idhba" class="text-gray-500 text-sm">Học viên</span></span></a>
                  </div>
                </div>
                <div id="izgjd" class="p-4 md:w-1/2 w-full">
                  <div id="irq1kl" class="h-full bg-gray-100 p-8 rounded">
                    <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                      </path>
                    </svg>
                    <p id="ifs6mz" class="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
                    </p>
                    <a class="inline-flex items-center"><img alt="testimonial" src="https://dummyimage.com/107x107" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/><span class="flex-grow flex flex-col pl-4"><span id="i6mw7e" class="title-font font-medium text-gray-900">Trần Thị Lan Anh</span><span id="icm1aw" class="text-gray-500 text-sm">Học viên</span></span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      `,
      },
    },
  }
  return blockUI;
};

export const questionBlockCustom = (data?: any) => {
  const blockUI = {
    id: 'question-block-id',
    options: {
      category: nameQuestionBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'QuestionWrapperComponent',
        },
        components: `
          <div component-class="QuestionComponent" id="i8zn" class="dark:text-gray-800">
            <div class="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 id="i6au" class="text-2xl font-semibold sm:text-4xl text-purple-700">Những câu hỏi thường gặp
              </h2>
              <p class="mt-4 mb-6 dark:text-gray-600">
                Mô tả
              </p>
              <div class="space-y-4">
                <details class="w-full border rounded-lg" open>
                  <summary id="iuz1" class="px-2 py-2 focus:outline-none">
                    Khóa học này dành cho ai?
                  </summary>
                  <p class="px-2 py-2 ml-4">
                    Nội dung
                  </p>
                </details>
                <details class="w-full border rounded-lg" open>
                  <summary id="iidfw" class="px-2 py-2 focus:outline-none">
                    Khóa học này bao gồm những gì?
                  </summary>
                  <p class="px-2 py-2 ml-4">
                    Nội dung
                  </p>
                </details>
                <details class="w-full border rounded-lg" open>
                  <summary id="iytcd" class="px-2 py-2 focus:outline-none">
                    Tôi có thể học khóa học này ở đâu?
                  </summary>
                  <p class="px-2 py-2 ml-4">
                     Nội dung
                  </p>
                </details>
              </div>
            </div>
          </div>
      `,
      },
    },
  }
  return blockUI;
};

export const uiDefault = {
  html: `
  <body>
  <section id="ih3k" class="text-gray-600 body-font">
    <div id="it98" class="container px-5 mx-auto flex flex-wrap py-12">
      <div id="inbi" class="flex flex-col flex-wrap lg:w-1/2 lg:pl-12 lg:text-left lg:pt-24 lg:pr-12">
        <div class="flex flex-col mb-10 lg:items-start items-center">
          <div id="i4qci" class="flex-grow">
            <h2 id="i4jv" class="text-black text-5xl title-font font-medium mb-8">English Vocabulary Launch: Upgrade your speaking
            </h2>
            <p id="if63x" class="leading-relaxed text-base mb-8">Learn English vocabulary, speaking and listening skills and become more fluent learning from a native English teacher
            </p>
          </div>
          <div id="iakxk" class="container mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <div id="ivl4r" class="ml-0 mr-2">Khoá học tạo bởi
            </div>
            <img src="https://s3.moooc.xyz/dev-stable/page-builder/c2565ae6-0556-44da-b77d-a2df6134eec4FTU_logo_2020.png" id="igxog" class="w-10 mr-auto"/>
            <div id="i053c" class="ml-0 mr-2">Tác giả:
            </div>
            <img src="https://s3.moooc.xyz/dev-stable/page-builder/39da7095-85ec-473e-b80b-33be4622ee4cavatar.jpg" id="ij8ed" class="w-10 mr-2 rounded-full"/>
            <div id="ilria" class="ml-0 mr-2 text-purple-500">Đặng Bảo Chung
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
        <img alt="feature" src="https://s3.moooc.xyz/dev-stable/page-builder/23921849-e7c2-4991-ae55-c2fe7338fffclewis-keegan-oB2aEeE8s4A-unsplash%201.jpg" id="isccx" class="object-center h-full w-full ml-auto mr-auto rounded-2xl"/>
      </div>
    </div>
  </section>
  <section id="iayb" class="text-gray-600 body-font">
    <div id="iz66" class="container px-5 mx-auto flex flex-wrap py-8">
      <div id="ihji" class="flex flex-col flex-wrap lg:w-1/2 lg:text-left border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
        <div id="icxj" class="flex flex-col mb-10 lg:items-start items-center">
          <div id="igyb" class="flex-grow w-full">
            <h2 id="ieb3" class="text-black title-font font-medium text-2xl mb-4">What you learn
            </h2>
            <div id="ieq5uh" class="border-gray-200 border-opacity-60 flex">
              <div id="i80k3j" class="border-gray-200 border-opacity-60 flex mb-4">
                <div id="ibso4u" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="inpjmf" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span id="ixcht9">You will learn how to leverage the power of Python to solve tasks.</span>
                  </p>
                </div>
                <div id="ip4ys2" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="izdarj" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span id="iyyy1o">You will build games and programs that use Python libraries.</span>
                  </p>
                </div>
              </div>
            </div>
            <div id="ix44hp" class="border-gray-200 border-opacity-60 flex">
              <div id="i7t0v8" class="border-gray-200 border-opacity-60 flex mb-4">
                <div id="iccc9l" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="inijoa" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span id="ixacbz">You will be able to use Python for your own work problems or personal projects.</span>
                  </p>
                </div>
                <div id="ird0n9" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="i5kx01" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span id="imo9lt">You will create a portfolio of Python based projects you can share.</span>
                  </p>
                </div>
              </div>
            </div>
            <div id="il3796" class="border-gray-200 border-opacity-60 flex">
              <div id="ihbb97" class="border-gray-200 border-opacity-60 flex mb-4">
                <div id="ifssbn" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="i4rkni" class="mr-4 w-6 h-6"/>
                  <p id="iy075" class="leading-relaxed text-base">
                    <span id="ibaq25">Learn to use Python professionally, learning both Python 2 and Python 3!</span>
                  </p>
                </div>
                <div id="iavncg" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="i90dpn" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span id="i27d45">Create games with Python, like Tic Tac Toe and Blackjack!</span>
                  </p>
                </div>
              </div>
            </div>
            <div id="iht359" class="border-gray-200 border-opacity-60 flex">
              <div id="i2kpmh" class="border-gray-200 border-opacity-60 flex mb-4">
                <div id="isxvwi" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="ihipdg" class="mr-4 w-6 h-6"/>
                  <p id="i387dp" class="leading-relaxed text-base">
                    <span id="i5rs54">Learn advanced Python features, like the collections module and how to work with timestamps!</span>
                  </p>
                </div>
                <div id="i9r72q" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="idrqcb" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span id="i4wui7">Learn to use Object Oriented Programming with classes!</span>
                  </p>
                </div>
              </div>
            </div>
            <div id="ivik6a" class="border-gray-200 border-opacity-60 flex">
              <div id="i9yqeu" class="border-gray-200 border-opacity-60 flex mb-4">
                <div id="ia4nup" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="iq26f4" class="mr-4 w-6 h-6"/>
                  <p id="i9jqvg" class="leading-relaxed text-base">
                    <span id="ihqsfk">Understand complex topics, like decorators.</span>
                  </p>
                </div>
                <div id="i3r82y" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="i1aayt" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span id="icsith">Understand how to use both the Jupyter Notebook and create .py files</span>
                  </p>
                </div>
              </div>
            </div>
            <div id="ibrwav" class="border-gray-200 border-opacity-60 flex">
              <div id="iidt0z" class="border-gray-200 border-opacity-60 flex mb-4">
                <div id="i8ax8g" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="i32rxw" class="mr-4 w-6 h-6"/>
                  <p id="i61get" class="leading-relaxed text-base">
                    <span id="iyx3kk">Get an understanding of how to create GUIs in the Jupyter Notebook system!</span>
                  </p>
                </div>
                <div id="ivdpxb" class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" id="imrvzk" class="mr-4 w-6 h-6"/>
                  <p id="icuyjl" class="leading-relaxed text-base">
                    <span id="i2377q">Build a complete understanding of Python from the ground up!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="icqra" class="w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:w-1/3 shadow-lg">
        <div id="iwbrd" class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full">
          <form id="i1plh">
            <iframe allowfullscreen="allowfullscreen" id="ie61m" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
            </iframe>
            <p class="leading-relaxed text-gray-600 mb-4">Xem trước khoá học này
            </p>
            <div id="iahwd" class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i4h1v" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ibkvye" class="text-3xl">1.700.000</span>
              </p>
            </div>
            <button type="submit" id="ib5zmq" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8">Ghi danh ngay</button>
            <p id="iie687" class="leading-relaxed text-gray-600 mb-4">Khoá học này bao gồm:
            </p>
            <div id="i7h9yt" class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/ae1cd3cd-020b-4b2a-b411-a69d0ea733f3play-circle.svg" id="ikiuvu" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="i03cqg">24 giờ video theo yêu cầu</span>
              </p>
            </div>
            <div id="irhax" class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/c9768dd1-6262-4763-954f-651113dd82efdownload-01.svg" id="iddnbu" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iokd2j">07 nguồn tài nguyên có thể tải xuống</span>
              </p>
            </div>
            <div id="ii33fa" class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/a4204056-4ac0-4e3e-bda6-9694d2b6dd66hourglass-01.svg" id="iswwhe" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ii47x5">Quyền truy cập đầy đủ vĩnh viễn</span>
              </p>
            </div>
            <div class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/1daf4632-0a4b-44d2-b932-8d2baf12fd54certificate-01.svg" id="i6l3n4" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="idytb6">Chứng chỉ khi hoàn thành</span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section id="iayb-2" class="text-gray-600 body-font">
    <div id="iz66-2" class="container px-5 mx-auto flex flex-wrap py-8">
      <div id="ihji-2" class="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:pt-12 lg:pl-12 lg:pr-12 mr-4 lg:w-1/3">
        <div id="icxj-2" class="flex flex-col mb-10 lg:items-start items-center">
          <div id="igyb-2" class="flex-grow w-full">
            <h2 id="ieb3-2" class="text-black title-font font-medium text-2xl mb-4">Khóa học tiên quyết
            </h2>
            <div id="i23y6h" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="iynbp8">English Basic</span>
            </div>
            <div id="ikiefi" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="ipdehc">Grammar</span>
            </div>
            <div id="i1rj0t" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="i11rxg">Vocab General</span>
            </div>
            <div id="if0lkg" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="i6f8aj">English Pronunciation</span>
            </div>
            <div id="innok2" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="i0jvck">English for Beginner</span>
            </div>
          </div>
        </div>
      </div>
      <div id="iirg5o" class="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:pt-12 lg:pl-12 lg:pr-12 mr-4 lg:w-1/3">
        <div class="flex flex-col mb-10 lg:items-start items-center">
          <div id="idclsc" class="flex-grow w-full">
            <h2 id="ii6p4u" class="text-black title-font font-medium text-2xl mb-4">
              <span id="ik5dkm">Điều kiện kỹ năng</span>
            </h2>
            <div id="i6bdjl" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="ivwehk">UI/UX</span>
            </div>
            <div id="i7kust" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="ifspop">Website</span>
            </div>
            <div id="i4w8wb" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="ievknh">Mobile Application</span>
            </div>
            <div id="izw8o4" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="icxxtg">Java</span>
            </div>
            <div id="iayaxu" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="iltocq">English</span>
            </div>
            <div id="iorczg" class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span id="i8gwki">C#</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="i541" class="text-gray-600 body-font">
    <div id="ibol" class="container px-5 mx-auto py-8">
      <div id="ibkt" class="flex flex-col w-full mb-20">
        <h1 id="itbd" class="sm:text-3xl text-2xl font-medium title-font text-purple-700">Người hướng dẫn
        </h1>
        <p id="iqji" class="lg:w-2/3 mx-auto leading-relaxed text-base">Danh sách giảng viên hàng đầu tham gia hướng dẫn tại tại khoá học
        </p>
      </div>
      <div class="flex flex-wrap -m-2">
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/80x80" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="igbds" class="text-gray-900 title-font font-medium">Bùi Ngọc Châu
              </h2>
              <p id="izvh1" class="text-gray-500">Hiệu phó
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/84x84" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="im8bu" class="text-gray-900 title-font font-medium">Huân Trần
              </h2>
              <p id="ie9hh" class="text-gray-500">Hiệu phó
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/88x88" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="iakyf" class="text-gray-900 title-font font-medium">Quý Kiên
              </h2>
              <p id="i64p3" class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/90x90" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="itzfz" class="text-gray-900 title-font font-medium">Quân Trần
              </h2>
              <p id="i8o0d" class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/94x94" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="ihu6m" class="text-gray-900 title-font font-medium">Đăng Định
              </h2>
              <p id="iqwbn" class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/98x98" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="idwew" class="text-gray-900 title-font font-medium">Nguyễn Trần Chỉnh
              </h2>
              <p id="iv22d" class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/100x90" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="ilrub" class="text-gray-900 title-font font-medium">Minh Nghĩa
              </h2>
              <p id="inbsx" class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/104x94" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="it0jv" class="text-gray-900 title-font font-medium">Phạm Thị Duyên
              </h2>
              <p id="inh6l" class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/108x98" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 id="i5igd" class="text-gray-900 title-font font-medium">Thái Phạm
              </h2>
              <p id="iljcv" class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="ikcpb" class="text-gray-600 body-font">
    <div id="idmrk" class="container px-5 mx-auto py-8">
      <h1 id="ij9mp" class="text-3xl font-medium title-font mb-12 text-purple-700">Ý kiến nhận xét
      </h1>
      <div class="flex flex-wrap -m-4">
        <div class="p-4 md:w-1/2 w-full">
          <div class="h-full bg-gray-100 p-8 rounded">
            <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
              </path>
            </svg>
            <p class="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
            </p>
            <a class="inline-flex items-center"><img alt="testimonial" src="https://dummyimage.com/106x106" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/><span class="flex-grow flex flex-col pl-4"><span class="title-font font-medium text-gray-900">Nguyễn Ngọc Tú</span><span id="idhba" class="text-gray-500 text-sm">Học viên</span></span></a>
          </div>
        </div>
        <div id="izgjd" class="p-4 md:w-1/2 w-full">
          <div id="irq1kl" class="h-full bg-gray-100 p-8 rounded">
            <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
              </path>
            </svg>
            <p id="ifs6mz" class="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
            </p>
            <a class="inline-flex items-center"><img alt="testimonial" src="https://dummyimage.com/107x107" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/><span class="flex-grow flex flex-col pl-4"><span id="i6mw7e" class="title-font font-medium text-gray-900">Trần Thị Lan Anh</span><span id="icm1aw" class="text-gray-500 text-sm">Học viên</span></span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="iytfu" class="text-gray-600 body-font relative">
    <div id="ilvri" class="container px-5 mx-auto flex sm:flex-nowrap flex-wrap py-8">
      <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
        <iframe frameborder="0" width="100%" height="100%" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?&q=Lê văn thiêm hà nội&z=15&t=q&output=embed" id="i6sat" class="absolute inset-0"></iframe>
        <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
          <div class="lg:w-1/2 px-6">
            <h2 id="it1gc" class="title-font font-semibold text-gray-900 tracking-widest text-xs">ĐỊA CHỈ
            </h2>
            <p id="i3vt2" class="mt-1">6 P. Lê Văn Thiêm, Thanh Xuân Trung, Thanh Xuân, Hà Nội
            </p>
          </div>
          <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
            <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL
            </h2>
            <a class="text-indigo-500 leading-relaxed">example@email.com</a>
            <h2 id="i81dy" class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">SỐ ĐIỆN THOẠI
            </h2>
            <p id="i8gj4" class="leading-relaxed">123-456-7890
            </p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
        <form id="iol3fl">
          <h2 id="iyr5fi" class="text-gray-900 text-lg mb-1 font-medium title-font">Liên hệ với chúng tôi
          </h2>
          <p id="id5szv" class="leading-relaxed mb-5 text-gray-600">Mọi góp ý của bạn giúp chúng tôi hoàn thiện khoá học hơn
          </p>
          <div id="ipuizh" class="relative mb-4">
            <label for="Name" id="iohrm3" title="Tên" class="leading-7 text-sm text-gray-600">Name</label>
            <input type="text" id="name-2" name="name" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
          <div class="relative mb-4">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email-2" name="email" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
          <div class="relative mb-4">
            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message-2" name="message" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
          <button type="submit" id="ioca6c" class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Gửi</button>
        </form>
      </div>
    </div>
  </section>
  <section id="i8zn" class="dark:text-gray-800">
    <div class="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
      <h2 id="i6au" class="text-2xl font-semibold sm:text-4xl text-purple-700">Những câu hỏi thường gặp
      </h2>
      <p class="mt-4 mb-6 dark:text-gray-600">
        Mô tả
      </p>
      <div class="space-y-4">
        <details class="w-full border rounded-lg" open>
          <summary id="iuz1" class="px-2 py-2 focus:outline-none">
            Khóa học này dành cho ai?
          </summary>
          <p class="px-2 py-2 ml-4">
            Nội dung
          </p>
        </details>
        <details class="w-full border rounded-lg" open>
          <summary id="iidfw" class="px-2 py-2 focus:outline-none">
            Khóa học này bao gồm những gì?
          </summary>
          <p class="px-2 py-2 ml-4">
            Nội dung
          </p>
        </details>
        <details class="w-full border rounded-lg" open>
          <summary id="iytcd" class="px-2 py-2 focus:outline-none">
            Tôi có thể học khóa học này ở đâu?
          </summary>
          <p class="px-2 py-2 ml-4">
            Nội dung
          </p>
        </details>
      </div>
    </div>
  </section>
</body>
  `,
  css: `
  * { box-sizing: border-box; } 
  body {margin: 0;}
  `,
}
