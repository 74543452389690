import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../../../config/api/configApiv2";

export const getMoocIndustryById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-industry/${id}`);
}

export const getAllMoocIndustryByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-industry/get-all-by-filter`, data);
}

export const createMoocIndustry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-industry/create`, data);
}

export const updateMoocIndustry: (data: any, id: number) => Promise<AxiosResponse<any>> = (data, id) => {
    return axiosConfigV2.put(`/mooc-industry/update/${id}`, data);
}

export const deleteMoocIndustry: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/mooc-industry/${id}`)
}