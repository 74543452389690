import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Radio, Button, Input, Row, Col, message } from 'antd';
import './ApproveLearningMaterial.scss';
import { getLearningMaterialDetail, approveLearningMaterial } from '../../../../service/learning-material';
import LearningMaterialDetail from '../LearningMaterialDetail/LearningMaterialDetail';
import { notifySuccess } from '../../../../utils/notification';
import { routesConfig } from '../../../../config/routes';
import { LearningMaterialStore } from '../../../../stores/stores';

const ApproveLearningMaterial: React.FC = () => {
  const { levelContentModeration, id } = useParams();
  const navigate = useNavigate();
  const { setTabLink } = LearningMaterialStore()

  const [detail, setDetail] = useState<any>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    const fetchLearningMaterialDetail = async () => {
      try {
        const res = await getLearningMaterialDetail(Number(id));
        setDetail(res?.data.data);
      } catch (error) {
        console.error("Error fetching learning material detail:", error);
      }
    };
    fetchLearningMaterialDetail();
  }, [id]);

  const handleApprove = async () => {

    try {
      const payload = {
        id: Number(id),
        status: status === 'approve' ? 2 : 3,
        description,
      };
      await approveLearningMaterial(payload);
      notifySuccess("Duyệt học liệu thành công")
      setTabLink("2")
      navigate(`/course-library${routesConfig.learningMaterials}/${levelContentModeration === "department-level-content-moderation" ? "department-level-content-moderation" : "school-level-content-moderation"}?tab=2`)
    } catch (error) {
    }
  };

  return (
    <div className="approve-learning-material">
      <div className="approve-learning-material__header">Duyệt học liệu</div>
      <div className="approve-learning-material__file-info">
        {detail?.code ? detail.code : ""}
        {detail?.fileName ? " / " + detail.fileName : ""}
      </div>
      <div className="approve-container">
        <div className="approve-learning-material__left">
          <LearningMaterialDetail viewDetail={false} />
        </div>
        <div className="approve-learning-material__right">
          <h3>Duyệt học liệu</h3>
          <Radio.Group
            className="approve-learning-material__options"
            onChange={(e) => setStatus(e.target.value)}
          >
            <Radio value="approve">
              <span className="approve-type">Đồng ý</span>
              <p className="radio-description">
                Các Giảng viên có thể tìm kiếm và tải xuống học liệu được chia sẻ này
              </p>
            </Radio>
            <Radio value="disapprove">
              <span className="approve-type">Không đồng ý</span>
              <p className="radio-description">
                Học liệu sẽ không được chia sẻ dùng chung trên hệ thống
              </p>
            </Radio>
          </Radio.Group>

          <div className="approve-learning-material__description">
            <label>Mô tả</label>
            <Input.TextArea
              placeholder="Nhập mô tả"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="approve-learning-material__actions">
            <Button type="primary" className="save-btn" onClick={handleApprove}
              disabled={status === "" || description == ""}
            >
              Lưu
            </Button>
            <Button className="close-btn">Đóng</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveLearningMaterial;
