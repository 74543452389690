import { Col, Form, Layout, message, Modal, Row } from "antd";
import {ReactNode, useEffect, useState} from "react";
import FormInput from "../../../components/form-input/FormInput";
import CardDepartment from "../../../components/category-management/department-major-management/card-department/CardDepartment";
import CardMajor from "../../../components/category-management/department-major-management/card-major/CardMajor";
import { useForm } from "antd/es/form/Form";
import { createMoocIndustryGroup, getMoocIndustryGroupById, updateMoocIndustryGroup } from "../../../service/category-management/department-major-management/department";
import { createMoocIndustry, getMoocIndustryById, updateMoocIndustry } from "../../../service/category-management/department-major-management/major";
import "./department-major-management.scss";
import { globalStore } from "../../../stores/stores";

export interface DepartmentDataType {
  name: string;
  code: string;
  numericalOrder: ReactNode;
}
type DepartmentManagementProps = {
  title?: string;
};

const validations = {
	departmentName: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên khoa không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên khoa"))
			}
			return Promise.resolve()
		}
	},
	departmentCode: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã khoa không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã khoa"))
			}
			return Promise.resolve()
		}
	},
  majorName: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên chuyên ngành không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên chuyên ngành"))
			}
			return Promise.resolve()
		}
	},
	majorCode: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã chuyên ngành không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã chuyên ngành"))
			}
			return Promise.resolve()
		}
	},
}

const DepartmentMajorManagement = ({ title }: DepartmentManagementProps) => {
  const [selectedUniversity, setSelectedUniversity] = useState<string | null>(null);
  const [currentUniversity, setCurrentUniversity] = useState<string | null>(null);
  const [hasCurrentUniversity, setHasCurrentUniversity] = useState(false);
  const [departmentId, setDepartmentId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [departmentEditId, setDepartmentEditId] = useState(0);
  const [majorEditId, setMajorEditId] = useState(0);
  const [isFetchDataDepartment, setIsFetchDataDepartment] = useState(false);
  const [isFetchDataMajor, setIsFetchDataMajor] = useState(false);
  const [listDataDepartment, setListDataDepartment] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState(1);
  const [modalValues, setModalValues] = useState<any>({
    title: "",
    nameForm: "",
    nameRule: null,
    codeRule: null,
    text: "",
  })
  const { personalInfo } = globalStore();
  const [form] = useForm();

  // get selected university
  const handleDepartmentFinish = (values: any) => {
    setSelectedUniversity(values.university);
  }
  
  // Modal
  const handleOk = () => {
    form.submit();
  }

  const handleCancel = () => {
    setOpenModal(false);
    form.resetFields();
  }

  // add department
  const handleAddDepartment = async (values: any) => {
    form.resetFields();
    setOpenModal(false);
    const reqData = { 
      name: values.name.trim(),
      code: values.code.trim() 
    }
    await createMoocIndustryGroup(reqData).then((res) => {
      if(res.status === 200) {
        setIsFetchDataDepartment(!isFetchDataDepartment);
        message.success("Thêm mới khoa thành công!");
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  // edit department
  const handleEditDepartment = async (values: any) => {
    form.resetFields();
    setOpenModal(false);
    const reqData = { 
      name: values.name.trim(),
      code: values.code.trim() 
    }
    await updateMoocIndustryGroup(reqData, departmentEditId).then((res) => {
      if(res.status === 200) {
        setIsFetchDataDepartment(!isFetchDataDepartment);
        message.success("Chỉnh sửa khoa thành công!");
        setTypeOfModal(1);
      }
    })
  }

  // get department
  const getDepartmentById = async () => {
    await getMoocIndustryGroupById(departmentEditId).then((res) => {
      if(res.status === 200) {
        if(typeOfModal === 2) {
          form.setFieldsValue({
            name: res.data?.data?.name,
            code: res.data?.data?.code,
          })
        }
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  // add major
  const handleAddMajor = async (values: any) => {
    form.resetFields();
    setOpenModal(false);
    const reqData = { 
      name: values.name.trim(),
      code: values.code.trim(),
      industryGroupId: departmentId,
    }
    await createMoocIndustry(reqData).then((res) => {
      if(res.status === 200) {
        setIsFetchDataMajor(!isFetchDataMajor);
        message.success("Thêm mới chuyên ngành thành công!");
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  // edit major
  const handleEditMajor = async (values: any) => {
    form.resetFields();
    setOpenModal(false);
    const reqData = { 
      name: values.name.trim(),
      code: values.code.trim(),
      industryGroupId: departmentId,
    }
    await updateMoocIndustry(reqData, majorEditId).then((res) => {
      if(res.status === 200) {
        setIsFetchDataMajor(!isFetchDataMajor);
        message.success("Chỉnh sửa chuyên ngành thành công!");
        setTypeOfModal(1);
      }
    })
  }

  // get major
  const getMajorById = async () => {
    await getMoocIndustryById(majorEditId).then((res) => {
      if(res.status === 200) {
        if(typeOfModal === 4) {
          form.setFieldsValue({
            name: res.data?.data?.name,
            code: res.data?.data?.code,
          })
        }
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  // get current university
  const getCurrentUniversity = async () => {
    if(personalInfo) {
      if(personalInfo?.enterpriseUUID) {
        setCurrentUniversity(personalInfo?.enterpriseUUID);
      } else {
        setCurrentUniversity(null);
      }
      setHasCurrentUniversity(true);
    }
  }

  // useEffect
  useEffect(() => {
    getCurrentUniversity();
  }, [personalInfo])

  useEffect(() => {
    if (!openModal) {
      setTypeOfModal(1);
    } else if(openModal && typeOfModal === 2) {
      getDepartmentById();
    } else if(openModal && typeOfModal === 4) {
      getMajorById();
    } else {
      form.resetFields();
    }
  }, [openModal]);

  // Types of Modal
  // 1: Add department
  // 2: Edit department
  // 3: Add major
  // 4: Edit major

  useEffect(() => {
    switch (typeOfModal) {
      case 1:
        setModalValues({
          title: "Thêm mới khoa",
          nameForm: "create-department-modal",
          nameRule: validations.departmentName,
          codeRule: validations.departmentCode,
          text: "khoa",
        })
        break;
      case 2:
        setModalValues({
          title: "Chỉnh sửa khoa",
          nameForm: "edit-department-modal",
          nameRule: validations.departmentName,
          codeRule: validations.departmentCode,
          text: "khoa",
        })
        break;
      case 3:
        setModalValues({
          title: "Thêm mới chuyên ngành",
          nameForm: "create-major-modal",
          nameRule: validations.majorName,
          codeRule: validations.majorCode,
          text: "chuyên ngành",
        })
        break;
      case 4:
        setModalValues({
          title: "Chỉnh sửa chuyên ngành",
          nameForm: "edit-major-modal",
          nameRule: validations.majorName,
          codeRule: validations.majorCode,
          text: "chuyên ngành",
        })
        break;
      default:
        break;
    }
  }, [typeOfModal]);

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if(name === "department-filter-form") {
          handleDepartmentFinish(values);
        }
        if(name === "create-department-modal") {
          handleAddDepartment(values);
        }
        if(name === "edit-department-modal") {
          handleEditDepartment(values);
        }
        if(name === "create-major-modal") {
          handleAddMajor(values);
        }
        if(name === "edit-major-modal") {
          handleEditMajor(values);
        }
      }}>
        <Row>
          <Col xl={{span: 7}} md={{span: 7}} sm={{span: 24}} xs={{span: 24}} className="col-card-department">
            <CardDepartment
              currentUniversity={currentUniversity}
              hasCurrentUniversity={hasCurrentUniversity}
              selectedUniversity={selectedUniversity} 
              setDepartmentId={setDepartmentId}
              setOpenModal={setOpenModal}
              setDepartmentEditId={setDepartmentEditId}
              isFetchDataDepartment={isFetchDataDepartment}
              setListDataDepartment={setListDataDepartment}
              setTypeOfModal={setTypeOfModal}
            />
          </Col>
          <Col xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}} className="col-card-major">
            <CardMajor 
              departmentId={departmentId}
              currentUniversity={currentUniversity}
              isFetchDataMajor={isFetchDataMajor}
              setOpenModal={setOpenModal}
              setTypeOfModal={setTypeOfModal}
              setMajorEditId={setMajorEditId}
            />
          </Col>
        </Row>
        <Modal className="action-modal" title={modalValues.title} open={openModal} onOk={handleOk} onCancel={handleCancel} centered okText="Lưu" cancelText="Huỷ">
          <Form layout={"vertical"} form={form} name={modalValues.nameForm}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="code" rules={[modalValues.codeRule]} label={`Mã ${modalValues.text}`}>
                  <FormInput placeholder={`Nhập mã ${modalValues.text}`} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="name" rules={[modalValues.nameRule]} label={`Tên ${modalValues.text}`}>
                  <FormInput placeholder={`Nhập tên ${modalValues.text}`} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Form.Provider>
    </Layout>
  );
};

export default DepartmentMajorManagement;
