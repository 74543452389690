import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Flex, Form, Row, Select } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { getCreatorsEmail } from "../../service/notifications";
import useDebounce from "../../hooks/useDebounce";
import { KeyValue } from "../../constants";
import { viLocale } from "../../utils/pickerType";

const { RangePicker } = DatePicker;

export interface FilterSearchValues {
  status?: number;
  createdPerson?: string[];
  date?: any;
}

interface FilterNotifications {
  onChange?: (value: FilterSearchValues) => void;
  onSearch?: (value: FilterSearchValues) => void;
  onClear?: (value: null) => void;
}

function FilterSearch({
  onSearch = () => {},
  onClear = () => {},
}: FilterNotifications) {
  const [form] = Form.useForm();

  const [keyword, setKeyword] = useState<string>("");
  const keyDebounce = useDebounce(keyword.trim(), 200);
  
  const [emailsOptions, setEmailOptions] = useState<KeyValue[]>([]);

  const handleFinish = (values: FilterSearchValues) => {
    onSearch(values);
  };

  useEffect(() => {
    const getEmails = async () => {
      const res = await getCreatorsEmail(keyDebounce);
      setEmailOptions(
        res.data.data.map((item: string) => ({
          label: item,
          value: item,
        }))
      );
    };
    getEmails();
  }, [keyDebounce]);

  const handleClear = () => {
    form.resetFields();
    onClear(null);
  };

  return (
    <div className="mt-2">
      <Form form={form} onFinish={handleFinish}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item name="status">
              <Select
                className="w-full"
                placeholder="Trạng thái"
                options={[
                  { label: "Tất cả", value: 0 },
                  { label: "Nháp", value: 1 },
                  { label: "Đã gửi", value: 2 },
                  { label: "Thu hồi ", value: 3 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="createdPerson">
              <Select
                mode="multiple"
                maxTagCount="responsive"
                showSearch
                onSearch={(value: string) => setKeyword(value)}
                className="w-full"
                placeholder="Email"
                options={emailsOptions}
                filterOption={false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="date">
              <RangePicker
                className="w-full"
                placeholder={["Bắt đầu", "Kết thúc"]}
                format="YYYY-MM-DD"
                locale={viLocale} 
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Flex gap={8}>
              <Button
                htmlType="submit"
                icon={<FilterOutlined />}
                className="btn-primary"
              >
                Lọc
              </Button>
              <Button
                onClick={handleClear}
                icon={<DeleteOutlined />}
                className="btn-outline"
              >
                Xoá tất cả
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FilterSearch;
