import { Form, Spin } from "antd";
import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import "./positionDetail.scss";
interface PositionDetailProps {
  positionDetail: any;
  loadingDetail: boolean;
}

const PositionDetail: FC<PositionDetailProps> = ({
  positionDetail,
  loadingDetail,
}) => {
  return (
    <section className="wrapper-settings category-management-detail" style={{height: "auto"}}>
      <div className="setting-position wrapper-inside">
        <CustomCard className="header-card" title="Thông tin chức vụ">
          {loadingDetail ? (
            <div className="statistic-course-content statistic-course-loading">
              <Spin size="large" />
            </div>
          ) : (
            <section>
              <Form.Item className="setting-item" name="name" label="Tên chức vụ">
                <p className="position-content">{positionDetail?.name}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="code" label="Mã chức vụ">
                <p className="position-content">{positionDetail?.code}</p>
              </Form.Item>
            </section>
          )}
        </CustomCard>
      </div>
    </section>
  );
};

export default PositionDetail;
