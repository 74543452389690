import React from "react";
import { Flex, Form, Select } from "antd";
import {
  JSON_SUB_TYPE,
  QuestionBeProps,
  QuestionProps,
  QuestionPropsV2,
} from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";

function Dropdown({ data, initialData, disabled }: QuestionPropsV2) {
  const isTrueAnswer = data?.questions?.find((item: QuestionBeProps) => item.isTrue === true)
  return (
    <Form.Item
      initialValue={isTrueAnswer?.uuid}
      name={`dropdown-${data?.id}-${QuestionTypeEnum.DROP_DOWN}-${JSON_SUB_TYPE.ChoosingRequest}`}
    >
      {data?.questions && data?.questions?.length > 0 ? (
        <Select
          disabled={disabled}
          placeholder="Chọn đáp án của bạn"
          options={data?.questions?.map((quiz: QuestionBeProps, index) => ({
            label: (
              <Flex className="font-size-16 line-height-24 gap-1">
                {convertNumberingTypeToText(
                  index + 1,
                  Number(JSON.parse(data?.settingGeneral)?.numberingType) || 0
                )}
                .
                <div
                  className="font-size-16 line-height-24"
                  dangerouslySetInnerHTML={{ __html: `${quiz?.content}` }}
                ></div>
              </Flex>
            ),
            value: quiz?.uuid || index,
          }))}
        />
      ) : (
        <div className="center">
          <EmptyComponent description="Không có nội dung" />
        </div>
      )}
    </Form.Item>
  );
}

export default Dropdown;
