import React, { useEffect, useRef, useState } from "react";
import { Flex, FormInstance } from "antd";
import { JSON_SUB_TYPE, QuestionBeProps, QuestionProps, QuestionPropsV2, QuizProps, QuizPropsV2 } from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";
import { isEmpty, toNumber } from "lodash";
import { SortableList } from "../../../../../components/SortableList";

interface ConnectProps extends QuestionPropsV2 {
  data?: QuizPropsV2;
  form?: FormInstance<any>;
  onChange?: (value: any) => void;
}
function Connect({
  data,
  disabled,
  initialData,
  onChange = () => {},
}: ConnectProps) {
  const [answers, setAnswers] = useState<any>([]);
  const connectRef = useRef<HTMLDivElement | null>(null);
  const [itemHeight, setItemHeight] = useState<number | undefined>(0);

  const [constAnswer, setConstAnswer] = useState<any[]>([]);
  const [dirtyAns, setDirtyAns] = useState<string[]>([]);

  useEffect(() => {
    setItemHeight(connectRef.current?.clientHeight);
  }, [connectRef.current, window.innerWidth]);

  useEffect(() => {
    if (data?.questions && data?.questions?.length > 0) {
      setAnswers(
        data?.questions?.map((ans: QuestionBeProps) => ({
          id: ans?.content?.right.key,
          value: ans?.content?.right.content,
        }))
      );
      setConstAnswer(
        data?.questions?.map((ans: QuestionBeProps) => ({
          id: ans?.content?.right.key,
          value: ans?.content?.right.content,
        }))
      );
    }
  }, [data]);


  useEffect(() => {
    if (answers?.length) {
      const dirtyArr: string[] = [];
      for (let i = 0; i < answers.length; i++) {
        if (JSON.stringify(constAnswer[i]) !== JSON.stringify(answers[i])) {
          dirtyArr.push(constAnswer[i]?.id, answers[i]?.id);
        }
      }
      setDirtyAns((prevState: string[]) => [...prevState, ...dirtyArr]);
    }
  }, [answers]);

  useEffect(() => {
    if (!isEmpty(initialData)) {
      setAnswers(
        initialData?.answer?.map((item: any) => ({
          id: item?.right?.key,
          value: item?.right?.content,
        }))
      );
    }
  }, [data?.id]);

  const handleDragChange = (fieldValues: any) => {
    setAnswers(fieldValues);
    if (data) {
      const answer = data?.questions?.map(
        (item: QuestionBeProps, index: number) => ({
          left: { ...item.content?.left },
          right: {
            key: fieldValues[index]?.id,
            content: fieldValues[index]?.value,
          },
        })
      );
      onChange({
        [`connect-${data?.id}-${QuestionTypeEnum.CONNECT}-${JSON_SUB_TYPE.ConnectingRequest}`]:
          answer,
      });
    }
  };

  return (
    <div className="flex">
      <div className="connect-quest justify-space-between w-50 mr-3">
        {data?.questions && data?.questions?.length > 0 ? (
          data?.questions?.map((quiz: QuestionBeProps, index) => (
            <div
              className={`connect-quest-item ${dirtyAns?.includes(quiz?.content?.right?.key) && "connect-quest-item-connecting"}`}
              key={`${quiz.uuid}-${index}-left`}
              ref={connectRef}
            >
               <Flex className="font-size-16 line-height-24 gap-1">
                {convertNumberingTypeToText(
                  index + 1,
                  Number(JSON.parse(data?.settingGeneral)?.numberingType) || 0
                )}
                .
                <div
                  className="font-size-16 line-height-24"
                  dangerouslySetInnerHTML={{ __html: `${quiz?.content?.left?.content}` }}
                ></div>
              </Flex>
            </div>
          ))
        ) : (
          <div className="center">
            <EmptyComponent description="Không có nội dung" />
          </div>
        )}
      </div>
      <div className="w-50 ml-3">
        <SortableList
          items={answers}
          className="gap-16 justify-space-between"
          disabled={disabled}
          onChange={handleDragChange}
          renderItem={(record: any) => (
            <SortableList.Item
              className="connect-item"
              id={record.id}
              styles={{ height: toNumber(itemHeight) + 2 }}
            >
              <SortableList.DragHandle />
              <div className="font-size-14">{record.value}</div>
            </SortableList.Item>
          )}
        />
      </div>
    </div>
  );
}

export default Connect;
