import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Layout, message } from "antd";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonModal from "../../../../../../components/modal/common";
import PageHeaderCommon from "../../../../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../../../../config/routes";
import {
  deleteReportAttendance,
  updateReportSettingAttendance,
} from "../../../../../../service/setting-attendance";
import { useStudyManagementStore } from "../../../../../../stores/stores";
import TableReportCheckInDetail from "./TableReportCheckInDetail";
import '../../../setting-remind-checkin.scss'
const { Content } = Layout;
const imgPath = "/images/";

const ReportCheckInDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [report, setReport] = useState({
    title: "",
    idReport: "",
  });
  const [paramStatus, setParamStatus] = useState<any>([]);
  const [idCourse, setIdCourse] = useState("");
  const [isViewHistory, setIsViewHistory] = useState(false);
  const [isRemote, setIsRemote] = useState(false);

  const [isAction, setIsAction] = useState(false);
  const [idEditReport, setIdEditReport] = useState(NaN);
  const [role, setRole] = useState<any>({
    is_edit: false,
    is_remove: false,
    is_view: false,
    is_assign: false,
  });
  const pathName = location.pathname;
  const isView = pathName.includes(routesConfig.reportCheckInDetail);
  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const { setTabAttendance } = useStudyManagementStore((state) => state);

  const handleCancelHistory = () => {
    setIsViewHistory(false);
    setIdEditReport(NaN);
  };
  const listBreadcrumb = [
    {
      title: "Quản lý khoá học",
    },
    {
      title: "Danh sách báo cáo",
    },
    {
      title: report?.title,
    },
  ];

  const GroupButton: any = () => {
    const handleSaveReportCheckIn = async () => {
      try {
        await updateReportSettingAttendance(report?.idReport, {
          attendance: paramStatus,
        });
        messageApi.open({
          type: "success",
          content: `Lưu thành công ${report?.title}`,
        });
      } catch (error) {
        messageApi.open({
          type: "error",
          content: `Lưu thất bại ${report?.title}`,
        });
      }
    };
    return (
      <Flex
        gap="small"
        justify="flex-end"
        className="w-full lg:w-2/3 xl:w-1/2 m-auto my-4"
      >
        {role.is_remove && (
          <Button danger onClick={() => setIsRemote(true)}>
            Xóa báo cáo
          </Button>
        )}
        {isViewHistory ? (
          <Button onClick={handleCancelHistory}>Thoát chế độ</Button>
        ) : (
          role.is_assign && (
            <Button onClick={() => setIsViewHistory(true)}>
              Xem lại lịch sử
            </Button>
          )
        )}
        {!isViewHistory && isAction && (
          <Button type="primary" onClick={handleSaveReportCheckIn}>
            Lưu
          </Button>
        )}
      </Flex>
    );
  };

  const handleBack = () => {
    if (idCourse) {
      setTabAttendance("3");
      navigate(
        `/management-study/${routesConfig.settingRemindCheckInDetail}/${idCourse}`
      );
    } else {
      navigate(-1);
    }
  };
  const handleRemote = async () => {
    try {
      await deleteReportAttendance(report?.idReport);
      messageApi.open({
        type: "success",
        content: `Xóa báo cáo điểm danh ${report?.title} thành công`,
      });
      setIsRemote(false);
      navigate(-1);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: `Xóa báo cáo điểm danh ${report?.title} thất bại`,
      });
    }
  };
  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={report?.title}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        headerGroupButton={true}
        childrenGroupButton={
          isView ? (
            role.is_remove && (
              <Button danger onClick={() => setIsRemote(true)}>
                Xóa báo cáo
              </Button>
            )
          ) : (
            <GroupButton />
          )
        }
        breadcrumb={true}
        handleBack={handleBack}
      />
      <TableReportCheckInDetail
        idDocument={params?.id}
        setReport={setReport}
        setParamStatus={setParamStatus}
        setIdCourse={setIdCourse}
        isViewHistory={isViewHistory}
        setIsAction={setIsAction}
        idEditReport={idEditReport}
        setIdEditReport={setIdEditReport}
        role={role}
        setRole={setRole}
      />
      <CommonModal
        open={isRemote}
        title={report?.title}
        desc={
          <div>
            Khoá học đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá
            báo cáo này?
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleRemote}
        closeModal={() => setIsRemote(false)}
      />
    </Content>
  );
};

export default ReportCheckInDetail;
