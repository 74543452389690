import { Col, Form, Layout, Row, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomCard from "../../../../components/custom-card/CustomCard";
import { getDetailEnrolledClass, getEnrolledClassForm, getEnrolledInstructorTeacherLookup } from "../../../../service/course-construct";
import { DetailRegistrationClassProps, RegistrationClassTabProps } from "../registration-class";
import "./info.scss";
const { Content } = Layout;
const dateFormat = 'DD/MM/YYYY';

function InfoTab(props: RegistrationClassTabProps) {
  const location = useLocation();
  const [dataDetail, setDataDetail] = useState<DetailRegistrationClassProps>({});
  const [classForm, setClassForm] = useState<string>('');
  const [teachers, setTeachers] = useState<any[]>([]);
  const [expectedTimeTypeAPI, setExpectedTimeTypeAPI] = useState<string>('');
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const { id, classId } = useParams()

  useEffect(() => {
    setLoadingContent(true);
    if (props.currentTab === '1') {
      getAllDataDetailRegisterClass();
    }
  }, [props.currentTab]);

  const getAllDataDetailRegisterClass = async () => {
    try {
      let [getDataDetail, getDataForms, getDataTechers] = await Promise.all([
        getDetailEnrolledClass(Number(classId)),
        getEnrolledClassForm(),
        getEnrolledInstructorTeacherLookup(Number(id)),
      ]);
      if (getDataDetail.status === 200) {
        setDataDetail(getDataDetail.data);
        if (getDataDetail.data.expectedTimeType === 10) {
          setExpectedTimeTypeAPI('ngày');
        }

        if (getDataDetail.data.expectedTimeType === 20) {
          setExpectedTimeTypeAPI('tuần');
        }

        if (getDataDetail.data.expectedTimeType === 30) {
          setExpectedTimeTypeAPI('tháng');
        }

        if (getDataDetail.data.expectedTimeType === null || getDataDetail.data.expectedTimeType === 0) {
          setExpectedTimeTypeAPI('');
        }
      }
      if (getDataForms.status === 200) {
        let allFormClass = getDataForms.data;
        allFormClass.forEach((x: any) => {
          if (Number(x.id) === getDataDetail.data.form) {
            setClassForm(x.name);
          }
        });
      }
      if (getDataTechers.status === 200) {
        let allTeachers = getDataTechers.data;
        let classTeachers = getDataDetail.data.instructorTeachers;
        let apiClassTeachers: any[] = [];
        if (classTeachers.length > 0) {
          let convertClassTeachers = classTeachers.split(';');
          convertClassTeachers.forEach((x: any, i: number) => {
            const teacher = allTeachers.find((y: any) => { return x === y.id });
            if (teacher) {
              apiClassTeachers.push(teacher.name);
            }
            if (i === (convertClassTeachers.length - 1)) {
              setTeachers(apiClassTeachers);
            }
          });
        }
      }
      setLoadingContent(false);
    } catch (error) { console.error(error); }
  }

  return (
    <section className="wrapper-settings">
      <div className="setting-lecturer registration-class-info">
        <CustomCard title="Thông tin lớp ghi danh" className="wrapper-settings--card">
          <Spin spinning={loadingContent} style={{ backgroundColor: '#fff' }}>
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <Form.Item className="setting-item" name="name" label="Tên lớp">
                  <p className="lecturer-content">{dataDetail.name ? dataDetail.name : 'Không có'}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="universityName" label="Hình thức">
                  <p className="lecturer-content">{classForm ? classForm : 'Không có'}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item item-label" name="regency" label="Số lượng học viên tối đa">
                  <p className="lecturer-content">{dataDetail.numberStudent ? dataDetail.numberOfSession : "Không giới hạn"}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item item-label" name="code" label="Số buổi">
                  <p className="lecturer-content">{dataDetail.numberOfSession ? dataDetail.numberOfSession : "Không giới hạn"}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item item-label" name="academicRank" label="Giảng viên">
                  <p className="lecturer-content">{teachers.length > 0 ? teachers.map((x: any) => { return (<>{x}</>); }) : 'Không có'}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item item-label" name="degree" label="Nỗ lực mong chờ">
                  <p className="lecturer-content">{dataDetail.expectedTime ? dataDetail.expectedTime : "Không có"} {expectedTimeTypeAPI}</p>
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </CustomCard>

        <CustomCard title="Thời gian ghi danh" className="wrapper-settings--card">
          <Spin spinning={loadingContent} style={{ backgroundColor: '#fff' }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item className="setting-item" name="name" label="Ngày mở ghi danh">
                  <p className="lecturer-content">{dataDetail.enrolledTime ? dataDetail.enrolledTime : 0} - {dataDetail.enrolledDate ? moment(dataDetail.enrolledDate).format(dateFormat) : 0}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="universityName" label="Ngày đóng ghi danh">
                  <p className="lecturer-content">{dataDetail.closedTime && dataDetail.closedDate
                    ? `${dataDetail.closedTime || 0} - ${dataDetail.closedDate ? moment(dataDetail.closedDate).format(dateFormat) : 0}`
                    : "Không có"}</p>
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </CustomCard>

        <CustomCard title="Lịch khai giảng" className="wrapper-settings--card">
          <Spin spinning={loadingContent} style={{ backgroundColor: '#fff' }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item className="setting-item" name="name" label="Ngày bắt đầu">
                  <p className="lecturer-content">{dataDetail.openingTime ? dataDetail.openingTime : 0} - {dataDetail.openingDate ? moment(dataDetail.openingDate).format(dateFormat) : 0}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="universityName" label="Ngày dự kiện kết thúc">
                  <p className="lecturer-content"> {dataDetail.closingTime || dataDetail.closingDate
                    ? `${dataDetail.closingTime || 0} - ${dataDetail.closingDate ? moment(dataDetail.closingDate).format(dateFormat) : 0}`
                    : "Không có"}</p>
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </CustomCard>
      </div>
    </section>
  );
};

export default InfoTab;
