import { Button, TableColumnsType, Typography } from 'antd';
import './table-survey-answers.scss';
import { ArrowLeftOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import FormItemInput from '../../../../../form-input/FormInput';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import TableData from '../../../../../table-data/TableData';
import { TableProps } from 'antd/lib';
import { useNavigate, useParams } from 'react-router-dom';
import { routesConfig } from '../../../../../../config/routes';
import { listSurveyAnswers } from '../../../../../../service/course-construct/survey-plan';
import { formatDateV2 } from '../../../../../../utils/converDate';
import { saveToIndexDB } from '../../../../../../pages/page-builder/grapesjs-service';

type TableSurveyAnswersProps = {
  setIsSurveyAnswersTable: (val: boolean) => void;
  isSurveyAnswersTable: boolean;
  idSurvey: number | null;
  surveyDetail?: any;
};

interface DataType {
  numericalOrder: number;
  userId: number;
  nameUser: string;
  completeLevel: string;
  createdDate: string;
}

const TableSurveyAnswers = (props: TableSurveyAnswersProps) => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get('unitId');
  const params = useParams();

  const [listPlan, setListPlan] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [keyWord, setKeyWord] = useState<string>('');

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: 'Số thứ tự',
      dataIndex: 'numericalOrder',
      key: 'numericalOrder',
      render: (_: any, record, index: number) => {
        return (
          <span>{record?.numericalOrder}</span>
        );
      },
    },
    {
      title: 'Người thực hiện',
      dataIndex: 'nameUser',
      key: 'nameUser',
      render: (_: any, record) => {
        return (
          <span>{record?.nameUser}</span>
        );
      },
    },
    {
      title: 'Mức độ hoàn thành',
      dataIndex: 'completeLevel',
      key: 'completeLevel',
      render: (_: any, record) => {
        return (
          <span>{record?.completeLevel}</span>
        );
      },
    },
    {
      title: 'Ngày thực hiện',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (_: any, record) => {
        return (
          <span>{formatDateV2(record?.createdDate)}</span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'operation',
      render: (_, record) => {
        return (
          <>
            <Button onClick={() => {
              saveToIndexDB('current-survey', {
                record: record,
                quizDetail: props.surveyDetail
              });
              navigate(`${routesConfig.surveyAnswerDetail}`, { state: { mode: 'backState' } })
            }}>
              <EyeOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.idSurvey) {
      setLoading(false);
      getListSurveyAnswers();
    }
  }, [props.idSurvey]);

  const getListSurveyAnswers = async () => {
    const request = await listSurveyAnswers({ surveyFormId: props.idSurvey });
    const data = request.data.data;
    setListPlan(data.content);
    setPageSize(data.pageable.pageSize);
    setPageNumber(data.pageable.pageNumber);
    setTotalRecords(data.totalElements);
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
  };

  const handleEnterSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {

    }
  };

  const handleTableChange: TableProps["onChange"] = (pagination, filters, sorter: any) => {
    const data = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : "";
  };

  return (
    <section className='survey-answers-table__wrapper'>
      <div className="survey-answers-table__header">
        <div className="survey-answers-table__header__left">
          <Button onClick={() => {
            props.setIsSurveyAnswersTable(false);
          }}>
            <ArrowLeftOutlined />
          </Button>
          <Typography.Title level={4}>
            Câu trả lời của
          </Typography.Title>
        </div>
        <div className="survey-answers-table__header__right">
          <FormItemInput
            placeholder="Nhập từ khóa"
            onChange={handleChangeSearch}
            onKeyPress={handleEnterSearch}
            afterPrefixIcon={
              <SearchOutlined
                onClick={() => { }}
              />
            }
          />
          <Button
            type='primary'
            onClick={() => {
              navigate(`${routesConfig.questionStatisticalReport}`, { state: { mode: 'backState', quizId: props.idSurvey, quizDetail: props.surveyDetail } })
            }}
          >
            <span>Thống kê</span>
          </Button>
        </div>
      </div>
      <div className="survey-answers-table__content">
        <TableData
          dataTable={listPlan}
          dataColumns={dataColumns}
          pageNumber={pageNumber}
          pageSize={pageSize}
          loadingTable={loading}
          scroll={{ x: 1500, y: 450 }}
          totalRecords={totalRecords}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          setSelectedRowKeysProps={setSelectedRowKeys}
          handleTableChangeProps={handleTableChange}
          pagination={true}
        />
      </div>
    </section>
  );
}

export default TableSurveyAnswers;