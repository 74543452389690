// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-remind:hover {
  border-color: #4096ff; }
  .action-remind:hover svg {
    color: #69b1ff; }

.icon-status-remind {
  width: 80px;
  height: 80px; }
  .icon-status-remind svg {
    width: 100%;
    height: 100%; }
    .icon-status-remind svg path {
      fill: #1677FF; }

.action-remind:hover {
  border-color: #4096ff; }
  .action-remind:hover svg {
    color: #69b1ff; }

.icon-status-remind {
  width: 80px;
  height: 80px; }
  .icon-status-remind svg {
    width: 100%;
    height: 100%; }
    .icon-status-remind svg path {
      fill: #1677FF; }

.modal-rule {
  display: none; }
  .modal-rule .ant-modal-body {
    margin: 0 !important; }

.ant-carousel .slick-slider .slick-dots {
  bottom: 20px; }
`, "",{"version":3,"sources":["webpack://./src/pages/study-management/setting-remind-checkin/setting-remind-checkin.scss"],"names":[],"mappings":"AAAA;EACI,qBAAqB,EAAA;EADzB;IAGQ,cAAc,EAAA;;AAGtB;EACI,WAAW;EACX,YAAY,EAAA;EAFhB;IAIQ,WAAW;IACX,YAAY,EAAA;IALpB;MAOY,aAAa,EAAA;;AAKzB;EACI,qBAAqB,EAAA;EADzB;IAGQ,cAAc,EAAA;;AAGtB;EACI,WAAW;EACX,YAAY,EAAA;EAFhB;IAIQ,WAAW;IACX,YAAY,EAAA;IALpB;MAOY,aAAa,EAAA;;AAKzB;EACI,aAAa,EAAA;EADjB;IAGQ,oBAAoB,EAAA;;AAI5B;EAGQ,YAAY,EAAA","sourcesContent":[".action-remind:hover {\n    border-color: #4096ff;\n    svg {\n        color: #69b1ff;\n    }\n}\n.icon-status-remind{\n    width: 80px;\n    height: 80px;\n    svg {\n        width: 100%;\n        height: 100%;\n        path{\n            fill: #1677FF;\n        }\n    }\n}\n\n.action-remind:hover {\n    border-color: #4096ff;\n    svg {\n        color: #69b1ff;\n    }\n}\n.icon-status-remind{\n    width: 80px;\n    height: 80px;\n    svg {\n        width: 100%;\n        height: 100%;\n        path{\n            fill: #1677FF;\n        }\n    }\n}\n\n.modal-rule {\n    display: none;\n    .ant-modal-body {\n        margin: 0 !important;\n    }\n}\n\n.ant-carousel {\n    .slick-slider {\n        .slick-dots {\n        bottom: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
