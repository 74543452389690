import { Button, Dropdown, Form, Layout, MenuProps, Modal, Space, TableColumnsType, TableProps, Tabs, TabsProps, Typography, message } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, MoreOutlined, SaveOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { routesConfig } from "../../../config/routes";
import { DataType, validateFieldCreate } from "./constants/data";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";
import DrawerCustom from "../../../components/drawer/Drawer";
import { formatDateV2 } from "../../../utils/converDate";
import { isEmpty } from "lodash";
import ImportIndustryDBModal from "./components/import-industry-db-modal/ImportIndustryDBModal";
import FormCreateAcademicLevel from "./components/form-create-academic-level/FormCreateAcademicLevel";
import { addMoocAcademicLevel, deleteMoocAcademicLevel, getAllAcademicLevelByFilter, getMoocAcademicLevelById, updateMoocAcademicLevel } from "../../../service/category-management/academic-level-management";
import "./academic-level-management.scss"

type AcademicLevelManagementProps = {
  title?: string;
};

const AcademicLevelManagement = ({ title }: AcademicLevelManagementProps) => {
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [tab, setTab] = useState("1");
  const navigate = useNavigate();
  const [editId, setEditId] = useState<number>(0);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [dataAcademicLevel, setDataAcademicLevel] = useState<any>([]);

  const [dataOptions, setDataOptions] = useState<any>({
    dataUser: [],
  });

  const [filterData, setFilterData] = useState<any>({
    type: "is_giang_vien",
    userType: null,
    createdDateFrom: null,
    createdDateTo: null,
  });

  const AcademicLevelsOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa học vấn",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa học vấn",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên học vấn",
      dataIndex: "level_name",
      key: "level_name",
      sorter: true,
      width: 30,
      className:"title-sequence",
      render: (_: any, record) => {
        return (
          <div className="table-academic-level cursor-pointer">
            <div>
              <a
                className="academic-level-name"
                onClick={() =>
                  navigate(
                    `${routesConfig.academicLevelManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        tabType: tab,
                        record,
                        name: record.name,
                      },
                    }
                  )
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mã học vấn",
      dataIndex: "code",
      key: "code",
      className:"title-sequence",
      width: 20,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any, index: number) => {
        return record.createdDate ? formatDateV2(record.createdDate) : null;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.academicLevelManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        tabType: tab,
                        record,
                        name: record.name,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: AcademicLevelsOptions,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      getAcademicLevelUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Xoá học vấn`,
                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                        onOk: () => deleteAcademicLevel(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị danh mục",
    },
  ];

  // Get data AcademicLevel
  const getAllAcademicLevel = (data?: any) => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      createdDateFrom: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdDateTo: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`],
      type: filterData.type,
    };
    getAllAcademicLevelByFilter(dataReq)
      .then((res) => {
        setDataAcademicLevel(res.data?.data?.content);
        setTotalRecords(res.data?.data?.totalElements);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          navigate("/error/403");
        } else {
          console.error(err);
        }
        setLoading(false);
      }).finally(() => {setLoading(false);});
  };
  
  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      ...filterData,
      createdDateFrom: null,
      createdDateTo: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      getAllAcademicLevel();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    getAllAcademicLevel();
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      userType: values?.userType,
      createdDateFrom: values?.time ? (values?.time[0]).add(1, "day").toISOString() : null,
      createdDateTo: values?.time ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };


  // Get AcademicLevel to edit
  const getAcademicLevelUpdate = async (id: number) => {
    setLoadingForm(true);
    await getMoocAcademicLevelById(id, filterData.type).then((res) => {
      const { data } = res.data;
      setDataDetail(data);
      setTimeout(() => {
        setLoadingForm(false);
      }, 333);
    })
      .catch((err) => {
        setLoadingForm(false);
        console.error(err);
      });
  };

  // Modal create | edit
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    form.resetFields();
  };

  const handleCreateAndEdit = () => {
    Modal.confirm({
      title: !isOpenedEdit ? `Thêm mới học vấn` : `Chỉnh sửa học vấn` ,
      content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
      onOk: () => form.submit(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  const onFinishCreate = async (values: any) => {
    if (Object.keys(values).length > 0) {
      if (isOpenedEdit) {
        const dataPayload = {
          id: editId,
          name: values.name.trim(),
          code: values.code.trim(),
          type: filterData.type,
        };
        setIsSaving(true);
        try {
          await updateMoocAcademicLevel(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Cập nhật học vấn thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllAcademicLevel();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      } else {
        const dataPayload = {
          id: null,
          name: values.name.trim(),
          code: values.code.trim(),
          type: filterData.type,
        };
        setIsSaving(true);
        try {
          await addMoocAcademicLevel(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới học vấn thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllAcademicLevel();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  // Delete AcademicLevel
  const deleteAcademicLevel = async (academicLevel: any) => {
    try {
      await deleteMoocAcademicLevel(academicLevel.id, filterData.type).then((res) => {
        if (res.status === 200) {
          message.success("Xóa học vấn thành công!");
          getAllAcademicLevel();
        }
      });
    } catch (err) { }
  };

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // tab items
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: <div>Giảng viên</div>,
      children: (
        <div className="lecturer-tab">
          <TableData
            pagination={true}
            pageSize={pageSize}
            hideSelection={false}
            dataTable={dataAcademicLevel}
            pageNumber={pageNumber}
            dataColumns={dataColumns}
            loadingTable={loading}
            setPageSize={setPageSize}
            scroll={{ x: 1500, y: 800 }}
            setPageNumber={setPageNumber}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <div>Sinh viên</div>,
      children: (
        <div className="student-tab">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={dataAcademicLevel}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  // Handle change tab
  const onChangeTab = (key: string) => {
    setTab(key);
    setFilterData({
      ...filterData,
      type: key === "1" ? "is_giang_vien" : "is_sv",
    });
    setPageNumber(1);
    form.resetFields();
  };

  // useEffect
  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        name: dataDetail.name,
        code: dataDetail.code,
        userType: dataDetail.type
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    getAllAcademicLevel();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createAcademicLevel") {
            const { createAcademicLevel } = forms;
            const data = createAcademicLevel.getFieldsValue(validateFieldCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>

        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChangeTab} />
        </div>

        <DrawerCustom 
          title={`${isOpenedEdit ? 
            `Chỉnh sửa học vấn ${tab === "1" ? "giảng viên" : "sinh viên"}` : 
            `Thêm mới học vấn ${tab === "1" ? "giảng viên" : "sinh viên"}`}`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit" onClick={handleCreateAndEdit} disabled={isSaving}>
                {isSaving ? <LoadingOutlined /> : <SaveOutlined />}
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateAcademicLevel form={form} dataOptions={dataOptions} loadingForm={loadingForm} />
        </DrawerCustom>
      </Form.Provider>
    </Layout>
  );
};

export default AcademicLevelManagement;



