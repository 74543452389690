import './survy-list-question.scss';
import { Button, Col, Form, Input, message, Modal, Row, Spin, TableColumnsType, TableProps, } from 'antd';
import { ChangeEvent, KeyboardEvent, useEffect, useState, useTransition } from 'react';
import { CopyOutlined, PlusOutlined, SearchOutlined, WarningOutlined, } from '@ant-design/icons';
import FormInput from '../../../../../../components/form-input/FormInput';
import AddQuestionCourseModal from '../../../../../../components/modal/add-question-course';
import {
  DEFAULT_MAX_LENGTH_QUESTION_BANK,
  DEFAULT_TEXAREA_ROW,
  ModeQuestion,
  QuestionProgressModeClick,
  QuestionTypeEnum,
  TypeCreateQuestion,
} from '../../../../../../constants';
import {
  deleteGroupQuiz,
  getQuizBank,
  getQuizById,
  postCreateGroup,
  postCreateSurveyGroup,
  updateGroupQuiz,
  uploadFileMoodleQuiz,
} from '../../../../../../service/course-construct';
import {
  addQuizToQuestionBank,
  addSurveyToQuestionBank,
  addUnitQuizFormBank,
  addUnitQuizSurveyFormBank,
  changeOrderGroupNumberQuiz,
  changeOrderNumberQuiz,
  createMoocCourseBlockQuiz,
  createMoocCourseBlockQuizConnect,
  deleteMoocCourseBlockQuiz,
  getAllQuizBySurvey,
  getAllQuizByUnit,
} from '../../../../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz';
import { HelpService } from '../../../../../../service/helper.service';
import { useAddLesson, useAuthStore } from '../../../../../../stores/stores';
import { Quiz, UnitQuiz } from '../../../../../../types/course-construction';
import { convertQuillToString } from '../../../../../../utils/convertQuillToString';
import { routesConfig } from '../../../../../../config/routes';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import {
  ItemsDraggerMulti,
  MultipleContainers
} from '../../../../../../components/multiple-containers/examples/MultipleContainers';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useDirty } from '../../../../../../contexts/DirtyProvider';
import TableData from "../../../../../../components/table-data/TableData";
import HtmlToText from "../../../../../../components/html-to-text";
import TextArea from 'antd/es/input/TextArea';
import { saveToIndexDB } from '../../../../../page-builder/grapesjs-service';
import { FileType } from '../../../../../../utils/uploadImage';

var indexDB = require("idbcache").default;
type Props = {
  tab: string;
  dataLessonId: number;
};


export default function SurvyListQuestion(props: Props) {
  const onRefreshQuizBlock = useAddLesson((state) => state.onRefreshQuizBlock);
  const setModeClick = useAddLesson((state) => state.setModeClick);
  const setQuestion = useAddLesson((state) => state.setQuestion);
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const refreshQuizBlock = useAddLesson((state) => state.refreshQuizBlock);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);
  const setTypeCreateQuestion = useAddLesson((state) => state.setTypeCreateQuestion);
  const currentIdQuestionAdd = useAddLesson((state) => state.currentIdQuestionAdd);

  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get('unitId');
  const helpService = new HelpService();
  const [isPending, startTransition] = useTransition();
  const { setDirty } = useDirty();
  const accessToken = useAuthStore((state) => state.accessToken);

  const [dataFiltered, setDataFiltered] = useState<any>([]);
  const [dataIdOpenCollapse, setDataIdOpenCollapse] = useState<any[]>([]);
  const [totalIdOpenCollapse, setTotalIdOpenCollapse] = useState<any[]>([]);
  const [dataClone, setDataClone] = useState<any>({});
  const [namePart, setNamePart] = useState<string>('');
  const [bankCodeValue, setBankCodeValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueQuestion, setSearchValueQuestion] = useState<string>('');
  const [courseBlockGroupIdClick, setCourseBlockGroupId] = useState<number>(0);
  const [courseBlockQuestionBank, setCourseBlockQuestionBank] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(-1);
  const [idQuizToAdd, setIdQuizToAdd] = useState<number>(0);
  const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);
  const [addQuestionBankModal, setAddQuestionBankModal] = useState<boolean>(false);
  const [loadingQuizBlock, setLoadingQuizBlock] = useState<boolean>(false);
  const [openAddPartModal, setAddPartModal] = useState<boolean>(false);
  const [openEditPartModal, setEditPartModal] = useState<boolean>(false);
  const [editGroupId, setEditGroupId] = useState<number>(1);
  const [editGroupName, setEditGroupName] = useState<string>("");
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [isDuplicateModalOpen, setDuplicateIsModalOpen] = useState<boolean>(false);
  const [itemsRender, setItemsRender] = useState<ItemsDraggerMulti>({});
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [statusOrder, setStatusOrder] = useState<string>('');
  const [fieldSortOrder, setFieldSortOrder] = useState<string>('');
  const [dataTableQuestion, setDataTableQuestion] = useState([])

  useEffect(() => {
    getAllQuizByUnitId();
  }, [refreshQuizBlock]);

  useEffect(() => {
    if (addQuestionBankModal) {
      getQuestionsBank();
    }
  }, [addQuestionBankModal, pageSize, pageNumber, statusOrder]);

  useEffect(() => {
    if (modeQuestion === ModeQuestion.CREATE && dataFiltered.length > 0) {
      let currentQuestionAdd: any;
      dataFiltered.some((ele: any) => {
        currentQuestionAdd = ele.quizzes.find((x: any) => {
          return x.id === currentIdQuestionAdd;
        });
        if (currentQuestionAdd) {
          return true;
        }
        return false;
      });
      getQuizLesson({
        ...currentQuestionAdd,
        type: currentQuestionAdd.type,
        courseBlockGroupId: currentQuestionAdd.groupId,
      });
    }
  }, [dataFiltered]);


  const getAllQuizByUnitId = async (searchKeyWord?: string) => {
    let customUnitId = unitId;
    if (!unitId) {
      return;
    }
    if (props.dataLessonId !== 0) {
      customUnitId = JSON.stringify(props.dataLessonId);
    }
    try {
      setLoadingQuizBlock(true);

      let quizes: any;

      let bodyData = {
        id: Number(customUnitId),
        keyword: '',
      };

      if (searchKeyWord === '') {
        bodyData.keyword = searchKeyWord.trim();
      } else {
        bodyData.keyword = searchValue.trim();
      }

      setModeClick(QuestionProgressModeClick.START);

      if (location.pathname.includes(routesConfig.addLesson)) {
        setTypeCreateQuestion(TypeCreateQuestion.COURSEWARE);
        quizes = await getAllQuizByUnit(bodyData);
        indexDB.set("courseBlockId", JSON.stringify(quizes.data[0].blockId), 1440);
      }

      if (location.pathname.includes(routesConfig.addSurvey)) {
        setTypeCreateQuestion(TypeCreateQuestion.SURVEY);
        quizes = await getAllQuizBySurvey(bodyData);
      }


      const quizesDataApi = quizes.data;

      const customQuizeData = quizesDataApi.map((x: UnitQuiz) => {
        x.quizzes.forEach((y: any) => {
          y.groupId = x.groupId;
        });
        return { ...x };
      });

      const customDataFiltered = quizesDataApi.filter((quizUnit: UnitQuiz) => {
        if (!quizUnit.isBegin && !quizUnit.isEnd) {
          quizUnit.id = quizUnit.groupId;
          return quizUnit;
        }
      });

      // const containers = quizesDataApi.map((quizUnit: UnitQuiz) => {
      //   return quizUnit.groupId;
      // });

      let customItemsRender: any = {};
      let customAllIdGroup: any = [];

      quizesDataApi.forEach((quizUnit: UnitQuiz, index: number) => {
        if (!quizUnit.isBegin && !quizUnit.isEnd) {
          customAllIdGroup.push('quiz-' + quizUnit.groupId);
          let arrayQuestion: UniqueIdentifier[] = [];
          quizUnit.quizzes.forEach((item: Quiz) => {

            arrayQuestion.push(convertQuillToString(item.id));
          });
          customItemsRender = { ...customItemsRender, ['quiz-' + quizUnit.groupId]: arrayQuestion };
        }
      });

      let createDataIdOpenCollapse = quizesDataApi.map((quizUnit: UnitQuiz) => {
        return JSON.stringify(quizUnit.groupId);
      });

      let orderCustomItemsRender = orderObjectKeys(customItemsRender, customAllIdGroup);

      setDataFiltered(customDataFiltered);
      setItemsRender(orderCustomItemsRender);
      setDataIdOpenCollapse(createDataIdOpenCollapse);
      setTotalIdOpenCollapse(createDataIdOpenCollapse);
      saveToIndexDB('course-block-quiz', customDataFiltered);
    } catch (error: any) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoadingQuizBlock(false);
      }, 1000);
    }
  };


  const getQuestionsBank = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValueQuestion.trim(),
      typeBank: typeCreateQuestion === TypeCreateQuestion.SURVEY ? 'survey-form-quiz' : 'quiz',
      sortBy: statusOrder
    }
    startTransition(() => {
      getQuizBank(dataReq).then(res => {
        const { data } = res.data
        setDataTableQuestion(data.content.map((content: any) => ({
          id: content.quizId,
          quizId: content.quizId,
          codeQuiz: content.codeQuiz,
          question: content.question,
          quizType: content.quizType
        })))
        setPageSize(data.size)
        setTotalRecords(data.totalElements)
        // setTotalRecords(data.totalElements)
      }).catch(err => console.error(err))
    })

  }

  const saveQuizFromBank = async () => {
    const dataReq = selectedRowKeys?.map((it: any) => {
      return {
        codeQuizBank: it.codeQuiz,
        blockQuizGroupId: courseBlockQuestionBank
      }
    })
    try {
      const response = await addUnitQuizFormBank(dataReq)
      const { status, data } = response
      if (status === 200) {
        message.success("Thêm câu hỏi thành công")
        setAddQuestionBankModal(false)
        setSelectedRowKeys([])
        getAllQuizByUnitId();
      }
    } catch (err) {
      console.error(err)
    }
  }

  const saveQuizSurveyFromBank = async () => {
    const dataReq = selectedRowKeys?.map((it: any) => {
      return {
        codeQuizBank: it.codeQuiz,
        blockQuizGroupId: courseBlockQuestionBank
      }
    })
    try {
      const response = await addUnitQuizSurveyFormBank(dataReq)
      const { status, data } = response
      if (status === 200) {
        message.success("Thêm câu hỏi thành công")
        setAddQuestionBankModal(false)
        setSelectedRowKeys([])
        getAllQuizByUnitId();
      }
    } catch (err) {
      console.error(err)
    }
  }

  const orderObjectKeys = (obj: any, keyOrder: any) => {
    let orderedObject: any = {};
    for (let key of keyOrder) {
      if (obj.hasOwnProperty(key)) {
        orderedObject[key] = obj[key];
      }
    }
    return orderedObject;
  }

  const getQuizLesson = async (quiz: Quiz) => {
    setModeQuestion(ModeQuestion.UPDATE);
    setModeClick(QuestionProgressModeClick.INPROGRESS);
    setQuestion(quiz);
    setAddQuestionModal(false);
    setActiveTab(quiz?.id as number);
    setCourseBlockGroupId(quiz.courseBlockGroupId);
    setDirty(true);
  };

  const handleAddQuestion = (courseBlockGroupId: number) => {
    setAddQuestionModal(true);
    setCourseBlockGroupId(courseBlockGroupId);
  };

  const handleAddQuestionFormBank = (courseBlockGroupId: number) => {
    setAddQuestionBankModal(true)
    setCourseBlockQuestionBank(courseBlockGroupId)
  }

  // Modal save question
  const showSaveModal = (id: number) => {
    setIsSaveModalOpen(true);
    setIdQuizToAdd(id);
    setBankCodeValue('');
  };

  const handleChangeText = (e: any) => {
    setBankCodeValue(e.target.value);
  };

  const handleSaveQuizToBankOk = async () => {
    if (bankCodeValue) {
      try {
        let request: any;
        if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
          request = await addQuizToQuestionBank(idQuizToAdd, bankCodeValue);
        }
        if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
          request = await addSurveyToQuestionBank(idQuizToAdd, bankCodeValue);
        }
        helpService.successMessage(request.data.message);
        setIsSaveModalOpen(false);
      } catch (err) {
        console.error(err);
      }
    } else {
      message.error("Vui lòng nhập mã câu hỏi!");
      return Promise.reject(); // Trả về Promise.reject để ngăn không cho hộp thoại đóng
    }
  };

  // Modal duplicate question
  const handleDuplicateOk = () => {
    setDuplicateIsModalOpen(false);
    getDetailQuestion(dataClone.id);
  };

  const getDetailQuestion = async (cloneFromId: any) => {
    const quizDetail = await getQuizById(cloneFromId);
    const quizClone = quizDetail.data.body;
    quizClone.question = "Bản sao " + quizClone.question;
    quizClone.id = undefined;
    createQuiz(quizClone);
  };

  const createQuiz = async (dataReq: any) => {
    try {
      if (dataReq.type === QuestionTypeEnum.CONNECT) {
        await createMoocCourseBlockQuizConnect(dataReq);
      } else {
        await createMoocCourseBlockQuiz(dataReq);
      }
      helpService.successMessage(`Tạo bản sao câu hỏi thành công!`);
      onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Tạo mới thất bại");
      console.error(err);
    }
  };

  // Delete
  const deleteQuiz = async (id: number) => {
    try {
      await deleteMoocCourseBlockQuiz(id);
      helpService.successMessage("Xoá câu hỏi thành công!");
      getAllQuizByUnitId();
    } catch (err) {
      console.error(err);
    }
  };

  const renderType = (value: string) => {
    switch (Number(value)) {
      case 1:
        return "Nhiều lựa chọn văn bản"
      case 2:
        return "Nhiều lựa chọn ảnh"
      case 3:
        return "Nhiều lựa chọn video"
      case 4:
        return "Nhiều lựa chọn đúng sai"
      case 5:
        return "Danh sách"
      case 6:
        return "Nhiều đáp án văn bản"
      case 7:
        return "Nhiều đáp án hình ảnh"
      case 9:
        return "Câu trả lời ngắn"
      case 10:
        return "Điền vào chỗ trống"
      case 11:
        return "Trình soạn thảo"
      case 12:
        return "Kết nối"
      case 13:
        return "Sắp xếp"
      case 14:
        return "Ghi âm"
      case 15:
        return "Ghi hình"
      case 16:
        return "Đính kèm"
      default:
        return "Bản nháp"
    }
  }

  // const itemsOptionQuestion: MenuProps["items"] = [
  //   {
  //     label: "Tạo bản sao",
  //     key: "1",
  //     icon: <CopyOutlined />,
  //   },
  //   {
  //     label: "Lưu vào ngân hàng câu hỏi",
  //     key: "2",
  //     icon: <SaveOutlined />,
  //   },
  //   {
  //     label: "Xóa",
  //     key: "3",
  //     icon: <DeleteOutlined />,
  //     danger: true,
  //   },
  // ];

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      sorter: true,
      width: 5,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder}</span>
        );
      },
    },
    {
      title: "Câu hỏi",
      dataIndex: "question",
      key: "question",
      width: 20,
      sorter: true,
      ellipsis: true,
      render: (_: any, record, index: number) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: record?.question
            }}
          />
        );
      },
    },
    {
      title: "Loại câu hỏi",
      dataIndex: "quizType",
      key: "quizType",
      sorter: true,
      ellipsis: true,
      width: 15,
      render: (_: any, record, index: number) => {
        return (
          <div>{renderType(record?.quizType)}</div>
        );
      },
    }
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "question,asc"
        : sorter.order === "descend"
          ? "question,desc"
          : '';
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValueQuestion(value);
  };

  const handleSubmitSearch = () => {
    startTransition(() => {
      getQuestionsBank()
      setPageNumber(1)
    })
  }

  const handleSearchQuestionBank = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      startTransition(() => {
        getQuestionsBank()
        setPageNumber(1)
      })
    }
  };

  // const handleDragChange = async (value: any) => {
  //   const groupId = value[0].groupId;
  //   const newListData = dataFiltered.map((groupData: any) => {
  //     if (groupData.groupId === groupId) {
  //       groupData.quizzes = value;
  //     }
  //     return { ...groupData };
  //   });
  //   setDataFiltered(newListData);                                                                                                                                                                                                        
  //   const newOrderQuiz = value.map((x: any, i: number) => {
  //     return { id: x.id, orderNumber: i + 1 };
  //   });
  //   const requestOrderQuiz = await changeOrderNumberQuiz(newOrderQuiz);
  //   helpService.successMessage(requestOrderQuiz.data.message);
  // };

  const handleChildDropDown = (key: string, record: any) => {
    if (key === "1") {
      setDuplicateIsModalOpen(true);
      setDataClone(record);
    }
    if (key === "2") {
      showSaveModal(record.id);
    }
    if (key === "3") {
      Modal.confirm({
        title: `Xóa câu hỏi`,
        content: `Dữ liệu đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa câu hỏi ?`,
        onOk: () => deleteQuiz(record.id),
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        okButtonProps: {
          className: "ant-btn-dangerous",
        },
        icon: (
          <WarningOutlined style={{ color: "#FF4D4F" }} />
        ),
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    }
  }

  // const listItems: CollapseProps["items"] = dataFiltered.map(
  //   (quizUnit: UnitQuiz) => ({
  //     key: JSON.stringify(quizUnit.groupId),
  //     label: <span>{quizUnit.title}</span>,
  //     children: (
  //       <>
  //         <SortableList
  //           items={quizUnit?.quizzes}
  //           onChange={handleDragChange}
  //           renderItem={(record: any, index: number) => (
  //             <SortableList.Item id={record.id}>
  //               <div className={`section-record ${record.id === activeTab ? "section-record-active" : ''}`}>
  //                 <SortableList.DragHandle />
  //                 <div className="section-record__question-name"
  //                   key={record.id}
  //                   onClick={() => {
  //                     getQuizLesson({
  //                       ...record,
  //                       type: record.type,
  //                       courseBlockGroupId: quizUnit.groupId,
  //                     });
  //                   }}
  //                 >
  //                   <div className="text-inline w-inline">
  //                     {/* <div className="quiz-index">{index + 1}</div> */}
  //                     <div className="quiz-question">
  //                       <div className="question-name">{convertQuillToString(record.question)}</div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <Dropdown
  //                   trigger={["click"]}
  //                   className="quiz-action"
  //                   menu={{
  //                     items: itemsOptionQuestion,
  //                     onClick: ({ key }) => {
  //                       handleChildDropDown(key, record);
  //                     },
  //                   }}>
  //                   <a onClick={(e: any) => e.preventDefault()}>
  //                     <Space>
  //                       <MoreOutlined />
  //                     </Space>
  //                   </a>
  //                 </Dropdown>
  //               </div>
  //             </SortableList.Item>
  //           )}
  //         />
  //         {quizUnit?.quizzes.length === 0 ? <span className="no-question">Không có câu hỏi</span> : ''}
  //         <p className="cursor-pointer add-question p-1" onClick={() => handleAddQuestion(quizUnit.groupId)}>
  //           <PlusOutlined className="mr-2" /> Thêm câu hỏi
  //         </p>
  //       </>
  //     ),
  //   })
  // );

  const handleEnterSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getAllQuizByUnitId();
    }
  };

  const closeAddPartModal = () => {
    setNamePart('');
    setAddPartModal(false);
    setEditPartModal(false);
  };

  const onChangeName = (e: any) => {
    if (openEditPartModal) {
      setEditGroupName(e.target.value);
    } else {
      setNamePart(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (openEditPartModal) {
      if (editGroupName !== '') {
        let reqData = {
          title: editGroupName,
        }
        updateGroupQuiz(editGroupId, reqData).then((res) => {
          closeAddPartModal();
          getAllQuizByUnitId();
          message.success("Sửa tên phần thành công!");
        })
      } else {
        helpService.errorMessage("Chưa nhập tên phần");
      }
    } else {
      if (namePart !== '') {
        let data: any;
        if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
          data = {
            unitId: Number(unitId),
            title: namePart,
            orderNumber: dataFiltered.length + 1 || 1,
          };
          const response = postCreateGroup(data).then((result) => {
            getAllQuizByUnitId();
            closeAddPartModal();
          }).catch((error) => {
          });
        }
        if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
          data = {
            surveyFormId: Number(unitId),
            title: namePart,
            orderNumber: dataFiltered.length + 1 || 1,
          };
          const response = postCreateSurveyGroup(data).then((result) => {
            getAllQuizByUnitId();
            closeAddPartModal();
          }).catch((error) => {
          });
        }
      } else {
        helpService.errorMessage("Chưa nhập tên phần");
      }
    }
  };

  const handelChangePostionParent = async (value: any, cb?: any) => {
    const newOrderParent = value.map((x: any, i: number) => {
      return { id: Number(x.replace('quiz-', '')), orderNumber: i + 1 };
    });
    const requestOrderParent = await changeOrderGroupNumberQuiz(newOrderParent);
    if (requestOrderParent.status === 200) {
      helpService.successMessage(requestOrderParent.data.message);
    } else {
      helpService.errorMessage(requestOrderParent.data.message);
    }
  }

  const handelChangePostionChild = async (value: any, cb?: any) => {
    let newOrderChild: any = [];
    Object.entries(value).forEach((key: any, i: number) => {
      newOrderChild = key[1].map((x: any, i: number) => {
        return { id: Number(x), orderNumber: i + 1, groupId: Number(key[0].replace('quiz-', '').replaceAll('"', '')) };
      });
    });
    const requestOrderChild = await changeOrderNumberQuiz(newOrderChild);
    if (requestOrderChild.status === 200) {
      helpService.successMessage(requestOrderChild.data.message);
    } else {
      helpService.errorMessage(requestOrderChild.data.message);
    }
  }

  const handleBeforeUpload = (file: FileType) => {
    const isXMLFile = file.type === 'text/xml';
    if (!isXMLFile) {
      helpService.errorMessage('Bạn phải tải lên file XML !');
    }
    return isXMLFile;
  };

  const handleCustomRequestUpload = async (options: any) => {
    const file = options.file;
    if (file.type === 'text/xml') {
      const formData = new FormData();
      formData.append('unitId', JSON.stringify(Number(unitId)));
      formData.append('groupId', JSON.stringify(editGroupId));
      formData.append('xmlFile', file);
      await uploadFileMoodleQuiz(formData).then((res) => {
        if (res.status === 200) {
          helpService.successMessage(res.data.message);
          onRefreshQuizBlock();
        }
      });
    }
  }

  return (
    <>
      <div className="end-question-lesson">
        <div className="end-question-lesson__header">
          <Row gutter={[{ xs: 8, xl: 8, md: 8 }, 8]} className="mt-2">
            <Col xl={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <FormInput
                placeholder="Tìm câu hỏi"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  if (e.target.value === '') {
                    getAllQuizByUnitId(e.target.value);
                  }
                }}
                onKeyPress={handleEnterSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={() => {
                    getAllQuizByUnitId();
                  }}
                  />
                }
              />
            </Col>
          </Row>
        </div>
        <section className="end-question-lesson__body">
          <Spin spinning={loadingQuizBlock}>
            <div className="dragger-multiple-containers multiple-quizes">
              <MultipleContainers
                collapsible={true}
                activeQuestion={activeTab}
                strategy={rectSortingStrategy}
                vertical
                titleButton={'Chọn ngân hàng câu hỏi'}
                dataItemsRender={itemsRender}
                dataBase={dataFiltered}
                handelChangePostionParent={handelChangePostionParent}
                handelChangePostionChild={handelChangePostionChild}
                handle={true}
                trashable={true}
                handleAddQuestionFromBank={handleAddQuestionFormBank}
                handleAddGroupQuestion={handleAddQuestion}
                handleClickChild={(id) => {
                  let record: any = {};
                  dataFiltered.map((x: UnitQuiz) => {
                    x.quizzes.map((y: Quiz) => {
                      if (y.id === id) {
                        record = y;
                      }
                    });
                  });
                  getQuizLesson({
                    ...record,
                    type: record.type,
                    courseBlockGroupId: record.groupId,
                  });
                }}
                handleClickDropDownChild={(key, id) => {
                  let record: any = {};
                  dataFiltered.map((x: UnitQuiz) => {
                    x.quizzes.map((y: Quiz) => {
                      if (y.id === id) {
                        record = y;
                      }
                    });
                  });
                  handleChildDropDown(key, record);
                }}
                handleEditNameGroup={(id) => {
                  setEditGroupId(Number(id.split('-')[1]));
                  setEditPartModal(true);
                  const groupName = dataFiltered.find((item: any) => item.groupId === Number(id.split('-')[1])).title;
                  setEditGroupName(groupName);
                }}
                handleDeleteGroup={(id) => {
                  Modal.confirm({
                    title: `Xóa phần`,
                    content: `Dữ liệu đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa phần này ?`,
                    onOk: () => {
                      const respone = deleteGroupQuiz(Number(id.split('-')[1]));
                      respone.then((res) => {
                        getAllQuizByUnitId();
                      }).catch((error) => {
                      })
                    },
                    okText: "Xác nhận",
                    cancelText: "Huỷ",
                    centered: true,
                    okButtonProps: {
                      className: "ant-btn-dangerous",
                    },
                    icon: (
                      <WarningOutlined style={{ color: "#FF4D4F" }} />
                    ),
                    footer: (_, { OkBtn, CancelBtn }) => (
                      <>
                        <CancelBtn />
                        <OkBtn />
                      </>
                    ),
                  });
                }}
                handleAddGroupId={(id) => {
                  setEditGroupId(id);
                }}
                uploadMoodle={{
                  showUploadList: false,
                  accept: '.xml',
                  beforeUpload(file) {
                    handleBeforeUpload(file)
                  },
                  customRequest(options) {
                    handleCustomRequestUpload(options)
                  },
                }}
              />
            </div>
          </Spin>
        </section>
        <Button className="cursor-pointer add-question p-1 add-part"
          onClick={() => {
            setAddPartModal(true);
          }}>
          <PlusOutlined className="mr-2" />
          Thêm phần
        </Button>
      </div>
      <Modal
        title={openEditPartModal ? `Sửa phần` : `Thêm phần`}
        open={openEditPartModal ? openEditPartModal : openAddPartModal}
        okText={openEditPartModal ? `Sửa` : `Thêm`}
        cancelText="Hủy"
        onOk={handleSubmit}
        onCancel={closeAddPartModal}
      >
        <Form.Item required={true} label="Tên phần:">
          <Input value={openEditPartModal ? editGroupName : namePart} onChange={onChangeName} />
        </Form.Item>
      </Modal>

      <AddQuestionCourseModal
        courseBlockGroupIdClick={courseBlockGroupIdClick}
        open={addQuestionModal}
        title={"Thêm câu hỏi"}
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={null}
        closeModal={() => setAddQuestionModal(false)}
      />
      <Modal
        className="add-question-modal"
        title="Thêm từ ngân hàng câu hỏi"
        open={addQuestionBankModal}
        onOk={(typeCreateQuestion === TypeCreateQuestion.SURVEY) ? saveQuizSurveyFromBank : saveQuizFromBank}
        onCancel={() => {
          setAddQuestionBankModal(false);
          setSelectedRowKeys([])

        }}
        centered
        okText="Lưu lại"
        cancelText="Hủy bỏ"
        width={800}
      >
        <div>
          <div className="modal-filter-table">
            <div className="filter-input">
              <FormInput placeholder={"Nhập từ khóa cần tìm"} onChange={handleChangeInput}
                value={searchValueQuestion}
                onKeyPress={handleSearchQuestionBank}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </div>
          <TableData
            hideSelection={true}
            dataColumns={dataColumns}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            loadingTable={isPending}
            totalRecords={totalRecords}
            rowSelected={selectedRowKeys}
            setSelectedRowKeysProps={setSelectedRowKeys}
            pagination={true}
            dataTable={dataTableQuestion}
            handleTableChangeProps={handleTableChange}
            scroll={{ x: 500, y: 560 }}
            unit={"câu hỏi"}
          />
        </div>
      </Modal>
      {/* Modal save question */}
      <Modal
        className="save-modal"
        title="Lưu vào ngân hàng câu hỏi"
        open={isSaveModalOpen}
        onOk={handleSaveQuizToBankOk}
        onCancel={() => {
          setIsSaveModalOpen(false);
        }}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <div className="save-modal-input">
          <label>Mã câu hỏi: </label>
          <TextArea
            placeholder="Nhập mã vào đây"
            maxLength={DEFAULT_MAX_LENGTH_QUESTION_BANK}
            rows={DEFAULT_TEXAREA_ROW}
            showCount
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChangeText}
            value={bankCodeValue}
          />
        </div>
      </Modal>
      <Modal
        confirmLoading={loadingQuizBlock}
        className="duplicate-modal"
        title={
          <span>
            <CopyOutlined
              style={{ marginRight: 15, fontSize: 24, color: "#1677FF" }}
            />
            Tạo bản sao câu hỏi
          </span>
        }
        open={isDuplicateModalOpen}
        onOk={handleDuplicateOk}
        onCancel={() => {
          setDuplicateIsModalOpen(false);
        }}
        okText="Tạo bản sao"
        cancelText="Hủy"
        closable={false}
      >
        <p>Bạn chắc chắn muốn tạo bản sao cho câu hỏi ?</p>
      </Modal>
    </>
  );
}
