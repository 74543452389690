import { Col, Form, Row, Spin } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent, useEffect, useState } from "react";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { get } from "lodash";
import { getAllMoocAddressDistrictByFilter } from "../../../../../service/category-management/administrative-unit-management";

const validations = {
  code: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length > 10) {
        return Promise.reject(new Error("Mã không vượt quá 10 ký tự"));
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập mã"));
      }
      return Promise.resolve();
    },
  },
  codeName: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length > 512) {
        return Promise.reject(new Error("Tên mã không vượt quá 512 ký tự"));
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên mã"));
      }
      return Promise.resolve();
    },
  },
  name: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length > 512) {
        return Promise.reject(new Error("Tên không vượt quá 512 ký tự"));
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên"));
      }
      return Promise.resolve();
    },
  },
  fullName: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length > 512) {
        return Promise.reject(new Error("Tên đầy đủ không vượt quá 512 ký tự"));
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên đầy đủ"));
      }
      return Promise.resolve();
    },
  },
  nameEn: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length > 512) {
        return Promise.reject(new Error("Tên tiếng Anh không vượt quá 512 ký tự"));
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên tiếng Anh"));
      }
      return Promise.resolve();
    },
  },
  fullNameEn: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length > 512) {
        return Promise.reject(new Error("Tên tiếng Anh đầy đủ không vượt quá 512 ký tự"));
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên tiếng Anh đầy đủ"));
      }
      return Promise.resolve();
    },
  },
  province: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(new Error("Vui lòng chọn đơn vị cấp Tỉnh"));
      }
      return Promise.resolve();
    },
  },
  district: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(new Error("Vui lòng chọn đơn vị cấp Huyện"));
      }
      return Promise.resolve();
    },
  },
};

type FormCreateAdministrativeUnitProps = {
  form: FormInstance;
  onFinish?: any;
  loadingForm?: boolean;
  textRendered?: string;
  dataOptions: any;
  tab: string,
};

const FormCreateAdministrativeUnit: FunctionComponent<FormCreateAdministrativeUnitProps> = ({ 
	form, onFinish, loadingForm, textRendered, dataOptions, tab 
}) => {
  
const currentProvinceCode = Form.useWatch("province", form);
const [dataDistrictByProvinceCode, setDataDistrictByProvinceCode] = useState([]);

// handle change province
  const getDataDistrictByProvinceCode = async (provinceCode: string) => {
    await getAllMoocAddressDistrictByFilter({
      sort: ["name, asc"],
      provinceCode: provinceCode,
    }).then((res) => {
      const listDataDistrict = res.data?.data?.content?.map((item: any) => {
        return {
          code: item.code,
          name: item.name
        }
      });
      setDataDistrictByProvinceCode(listDataDistrict);
      form.setFieldValue("district", null);
    })
  }

useEffect(() => {
  if(currentProvinceCode) {
    getDataDistrictByProvinceCode(currentProvinceCode);
  }
}, [currentProvinceCode]);

  return (
    <>
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        name="createAdministrativeUnit"
        initialValues={{ status: true }}
      >
        {loadingForm ? (
          <div className="statistic-course-content statistic-course-loading">
            <Spin size="large" />
          </div>
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item name="code" rules={[validations.code]} label={`Mã`} >
                <FormInput placeholder={`Nhập mã ${textRendered}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="codeName" rules={[validations.codeName]} label={`Tên mã`} >
                <FormInput placeholder={`Nhập tên mã ${textRendered}`} />
              </Form.Item>
            </Col>
			      <Col span={12}>
              <Form.Item name="name" rules={[validations.name]} label={`Tên`} >
                <FormInput placeholder={`Nhập tên ${textRendered}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="fullName" rules={[validations.fullName]} label={`Tên đầy đủ`} >
                <FormInput placeholder={`Nhập tên đầy đủ ${textRendered}`} />
              </Form.Item>
            </Col>
			      <Col span={24}>
              <Form.Item name="nameEn" rules={[validations.nameEn]} label={`Tên tiếng Anh`} >
                <FormInput placeholder={`Nhập tên tiếng Anh ${textRendered}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="fullNameEn" rules={[validations.fullNameEn]} label={`Tên tiếng Anh đầy đủ`} >
                <FormInput placeholder={`Nhập tên tiếng Anh đầy đủ ${textRendered}`} />
              </Form.Item>
            </Col>
            {
              tab !== "1" ?
              <Col span={24}>
                <Form.Item name="province" rules={[validations.province]} label={`Đơn vị cấp Tỉnh`} >
                  <FormItemDropdown placeholder="Chọn đơn vị cấp Tỉnh" options={get(dataOptions, "dataProvince")}/>
                </Form.Item>
              </Col> 
              : null
            }
            {
              tab === "3" ?
              <Col span={24}>
                <Form.Item name="district" rules={[validations.district]} label={`Đơn vị cấp Huyện`} >
                  <FormItemDropdown placeholder="Chọn đơn vị cấp Huyện" options={dataDistrictByProvinceCode}/>
                </Form.Item>
              </Col> 
              : null
            }
          </Row>
        )}
      </Form>
    </>
  );
};

export default FormCreateAdministrativeUnit;
