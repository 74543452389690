import { Button, Card, Dropdown, MenuProps, message, Modal, Space, TableColumnsType, TableProps, Typography } from "antd";
import { ChangeEvent, FunctionComponent, KeyboardEvent, ReactNode, useEffect, useState } from "react";
import FormItemInput from "../../../form-input/FormInput";
import { DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import TableData from "../../../table-data/TableData";
import "./card-major.scss";
import { isEmpty, size } from "lodash";
import { deleteMoocIndustry, getAllMoocIndustryByFilter } from "../../../../service/category-management/department-major-management/major";

interface MajorDataType {
  name: string;
  code: string;
  numericalOrder: ReactNode;
}
type CardMajorProps = {
    departmentId: number | null;
    currentUniversity?: string | null,
    isFetchDataMajor?: boolean,
    setOpenModal: (value: boolean) => void,
    setTypeOfModal: (value: number) => void,
    setMajorEditId: (value: number) => void,
};


const CardMajor: FunctionComponent<CardMajorProps> = ({
    departmentId,
    currentUniversity,
    isFetchDataMajor,
    setOpenModal,
    setTypeOfModal,
    setMajorEditId,
}) => {
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listDataMajor, setListDataMajor] = useState([]);
  
  const dataColumns: TableColumnsType<MajorDataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 1,
      render: (_: any, record: any, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên chuyên ngành",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 5,
    },
    {
      title: "Mã chuyên ngành",
      dataIndex: "code",
      key: "code",
      width: 2,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record: any) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              {/* <a
                className="action-table"
                onClick={
                  () => {}
                  //   navigate(
                  //     `${routesConfig.universityManagementDetail}/${record?.id}`,
                  //     {
                  //       state: {
                  //         mode: "view",
                  //         record,
                  //         name: record.name,
                  //         universityId: record.id,
                  //       },
                  //     }
                  //   )
                }
              >
                <EyeOutlined />
              </a> */}
              {!currentUniversity ?
                  <></>
                :
                  <Dropdown
                    menu={{
                      items: majorOptions,
                      onClick: ({ key }) => {
                        if (key === "1") {
                          setMajorEditId(record.id);
                          setOpenModal(true);
                          setTypeOfModal(4);
                        }
                        if (key === "2") {
                          Modal.confirm({
                            title: `Xoá chuyên ngành`,
                            content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                            onOk: () => deleteMajor(record.id),
                            centered: true,
                            okText: "Xác nhận",
                            cancelText: "Huỷ bỏ",
                            footer: (_, { OkBtn, CancelBtn }) => (
                              <>
                                <CancelBtn />
                                <OkBtn />
                              </>
                            ),
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                  >
                    <Button>
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
              }
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 1,
    },
  ];

  const majorOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa chuyên ngành",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa chuyên ngành",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // Filter Search
  const handleChangeInput= (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  const handleSubmitSearch = () => {
    fetchData();
  };

  // fetch data
  const fetchData = async () => {
    setLoading(true);
    const reqData = {
        page: pageNumber,
        size: pageSize,
        keyword: searchValue,
        industryGroupId: departmentId,
        enterpriseUuid: null,
        sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`]
    }
    await getAllMoocIndustryByFilter(reqData).then((res) => {
        if(res.status === 200) {
            setListDataMajor(res.data?.data?.data);
            setTotalRecords(res.data?.data?.total);
            setTimeout(() => {
                setLoading(false);
            }, 333);
        }
    }).catch((err) => {
        console.error(err);
    })
  }

  // Delete department
  const deleteMajor = async (id: number) => {
    await deleteMoocIndustry(id).then((res) => {
      if(res.status === 200) {
        message.success("Xóa chuyên ngành thành công!");
        fetchData();
      }
    })
  }

  // useEffect
  useEffect(() => {
    if(departmentId) {
      fetchData();
    }
  }, [pageNumber, pageSize, statusOrder, isFetchDataMajor])

  useEffect(() => {
    if(pageNumber === 1 && departmentId) {
      fetchData();
    } else if(!departmentId) {
      setListDataMajor([]);
      setTotalRecords(0);
    } else {
      setPageNumber(1);
    }
  }, [departmentId])

  return (
    <>
      <Card className="card-major" bordered={false}>
        <div className="header-major-card">
          <div className="card-major-title flex mb-1">
            <Typography.Title level={4} >{`Danh sách chuyên ngành`}</Typography.Title>
            <div className="card-major-title-right flex">
              <FormItemInput
                placeholder="Nhập để tìm kiếm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={ <SearchOutlined onClick={handleSubmitSearch} /> }
              />
              {
                !currentUniversity ?
                  <></>
                :
                  <Button 
                    className="add-new-btn btn-primary ml-3" 
                    disabled={!currentUniversity || !departmentId}
                    onClick={() => {
                      setOpenModal(true);
                      setTypeOfModal(3);
                    }}
                  >
                    Thêm mới
                  </Button>
              }
            </div>
          </div>
        </div>
        <TableData
          dataTable={listDataMajor}
          dataColumns={dataColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          // scroll={{ x: 500, y: 900 }}
          totalRecords={totalRecords}
          loadingTable={loading}
          handleTableChangeProps={handleTableChange}
        />
      </Card>
    </>
  );
};

export default CardMajor;
