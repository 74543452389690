import { DeleteOutlined, EditOutlined, EyeOutlined, FileDoneOutlined, UploadOutlined, } from "@ant-design/icons";
import { Form, Layout, MenuProps, Space, TableColumnsType, TableProps, Tabs, TabsProps, message, } from "antd";
import { isEmpty } from "lodash";
import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TableData from "../../components/table-data/TableData";
import PageHeader from "../../components/page-header/PageHeader";
import FilterManagementParticipate from "../../components/management-participate-platform/filter-table/FilterTable";
import FormFilter from "../../components/management-participate-platform/filter-group/FormFilter";
import { formatOriginTime } from "../../utils/converDate";
import { getListManagementParticipatePlatform } from "../../service/management-participate-platform";
import DetailParticipateDrawer from "../../components/drawer/management-participate-platform/detail-participant";
import ConfirmRequestParticipateDrawer from "../../components/drawer/management-participate-platform/confirm-request-participant";
import { globalStore } from "../../stores/stores";
import { Permission } from "../../layout/sidebar/permission.enum";

type Props = {
  title?: string;
};

const ManagementParticipatePlatform = (props: Props) => {
  const { title } = props;
  const [tab, setTab] = useState("1");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [listParticipant, setListParticipant] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenDetailParticipate, setIsOpenDetailParticipate] = useState<boolean>(false);
  const [isOpenConfirmParticipate, setIsOpenConfirmParticipate] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [detailData, setDetailData] = useState<any>();
  const location = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const [filterData, setFilterData] = useState<any>({
    status: null,
    approvedDate: null,
  });

  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      createdDateFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) && tab === "1" ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      createdDateTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) && tab === "1" ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      approvedDateFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) && tab === "2" ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      approvedDateTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) && tab === "2" ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      provinceCode: filterData.status ?? null,
      status: tab === "1" ? 0 : 1,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
    };

    await getListManagementParticipatePlatform(data)
      .then((res) => {
        const { data } = res.data
        setListParticipant(data.data)
        setTotalRecords(data.total);
        setCountAwaitingReview(data?.allRecordApprovedWait)
        setCountReviewed(data?.allRecordApproved)

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/error/403");
        } else {
          console.error(err);
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, statusOrder, pageSize]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    setPageNumber(1)
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1)
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
    });
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Trường",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 20,
    },
    ...(tab === "1" ? [
      {
        title: "Ngày gửi",
        dataIndex: "courseName",
        key: "courseName",
        width: 20,
        render: (_: any, record: any) => {
          return (
            <>{record?.createdDate ? formatOriginTime(record?.createdDate) : null}</>
          );
        },
      }
    ] : [
      {
        title: "Ngày xử lý",
        dataIndex: "courseName",
        key: "courseName",
        width: 20,
        render: (_: any, record: any) => {
          return (
            <>{record?.approvedDate ? formatOriginTime(record?.approvedDate) : null}</>
          );
        },
      }
    ]
    ),
    {
      title: "Tỉnh",
      dataIndex: "provinceName",
      key: "provinceName",
      width: 20,
    },
    {
      title: "Người liên hệ",
      dataIndex: "userContact",
      key: "userContact",
      width: 20,
    },
    ...(tab === "1" && permissions?.includes(Permission.ProcessRequestUpdate)? [{
      title: "",
      dataIndex: "name",
      key: "name",
      width: 20,
      render: (_: any, record: any, index: number) => {
        return <div className="cursor-pointer text-blue-500" onClick={() => {
          setIsOpenConfirmParticipate(true)
          setDetailData(record)
        }}>Xử lý yêu cầu</div>;
      },
    }] : []),
    ...(permissions?.includes(Permission.ViewRequestDetails) ? [{
        title: "",
        dataIndex: "action",
        key: "operation",
        render: (_: any, record: any) => {
          return (
            <>
              <Space>
                <a
                  className="action-table"
                  onClick={() => {
                    setIsOpenDetailParticipate(true)
                    setDetailData(record)
                  }}
                >
                  <EyeOutlined />
                </a>
              </Space>
            </>
          );
        },
        width: 10,
    }]: [])
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Chờ xử lý
          <span
            className={`number-record ${tab === "1" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countAwaitingReview}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listParticipant}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
            clearRowkey={clearRowkey}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Đã xử lý
          <span
            className={`number-record ${tab === "2" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countReviewed}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listParticipant}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const deletePortalAdmin = async () => {
    // await deleteTopic(deleteId).then((res) => {
    //   if (res.status === 200) {
    //     message.success("Xoá chủ đề thành công")
    //     setIsOpenedConfirm(false)
    //     getListData()
    //   }
    // })
  };

  const statusList = [
    {
      value: "1",
      label: "Đã xuất bản",
    },
  ];

  return (
    <Layout className="page-header-group course-evaluation">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "filterForm") {
            getListData()
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterManagementParticipate
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
        </div>
      </Form.Provider>

      <DetailParticipateDrawer
        open={isOpenDetailParticipate}
        onClose={() => setIsOpenDetailParticipate(false)}
        detailData={detailData}
      />
      <ConfirmRequestParticipateDrawer
        open={isOpenConfirmParticipate}
        onClose={() => setIsOpenConfirmParticipate(false)}
        detailData={detailData}
        getListData={getListData}
      />
    </Layout>
  );
};

export default ManagementParticipatePlatform;
