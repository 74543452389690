import { Button, Form, Layout, Modal, Typography, message } from "antd";
import { FunctionComponent, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import CustomCard from "../../../../components/custom-card/CustomCard";
import "./portfolio-value-management-detail.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../../../config/api/configApiv2";
import { exportExcelMoocPortfolioValueDetail } from "../../../../service/category-management/portfolio-value-management";
import { formatDateV2 } from "../../../../utils/converDate";

const { Content } = Layout;
const imgPath = "/images/";

const PortfolioValueManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);

    const listBreadcrumb = [
        {
            title: "Quản lý danh mục",
        },
        {
            title: (
                <SafeLink
                    to={`/${routesConfig.categoryManagement}/${routesConfig.portfolioValueManagement}`}
                >
                    <span>Quản lý giá trị danh mục</span>
                </SafeLink>
            ),
        },
        {
            title: location.state.name,
        },
    ];
    const [isRendering, setIsRendering] = useState(false);

    const handleExportExcel = () => {
        Modal.confirm({
            title: `Xác nhận kết xuất danh sách giá trị danh mục`,
            content: ``,
            onOk: () => exportExcel(),
            centered: true,
            okText: "Xác nhận",
            cancelText: "Huỷ bỏ",
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
        });
    }

    const exportExcel = async () => {
        setIsRendering(true); // Bắt đầu quá trình xử lý

        try {
            // Gọi API với ID của danh mục hiện tại
            const res = await exportExcelMoocPortfolioValueDetail(location.state?.record.id);

            // Tạo Blob từ dữ liệu nhận được
            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            // Tạo một liên kết tạm thời để tải xuống tệp
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `${location.state?.name}_Thong_tin_chi_tiet.xlsx`; // Đặt tên file tải xuống
            downloadLink.click(); // Kích hoạt việc tải file

            message.success("Tải xuống thành công!"); // Thông báo thành công
        } catch (error) {
            console.error("Lỗi khi tải xuống:", error); // Xử lý lỗi nếu có
            message.error("Có lỗi xảy ra, vui lòng thử lại!"); // Thông báo lỗi
        } finally {
            setIsRendering(false); // Kết thúc quá trình xử lý
        }
    };

    return (
        <Content>
            <PageHeader
                title={location.state?.name ? location.state?.name : ""}
                listBreadcrumb={listBreadcrumb}
                arrowLeft={imgPath + "arrowLeft.svg"}
                positionItem="column"
                breadcrumb={true}
                headerGroupButton={isShowSubmitButton}
                childrenGroupButton={
                    <Button type="primary" onClick={handleExportExcel} disabled={isRendering}>
                        <Typography.Text>
                            {isRendering ? <LoadingOutlined /> : ''} Kết xuất thông tin chi tiết
                        </Typography.Text>
                    </Button>
                }
            />
            <section className="wrapper-settings category-management-detail">
                <div className="setting-portfolio wrapper-inside">
                    <CustomCard className="header-card" title="Thông tin giá trị danh mục" >
                        <Form.Item className="setting-item" name="cateName" label="Tên thuộc tính">
                            <p className="portfolio-content">{location.state?.name}</p>
                        </Form.Item>
                        <Form.Item className="setting-item" name="cateCode" label="Mã thuộc tính">
                            <p className="portfolio-content">{location.state?.record?.code}</p>
                        </Form.Item>
                        <Form.Item className="setting-item" name="cateDad" label="Thuộc tính cha">
                            <p className="portfolio-content">{location.state?.record?.parentName}</p>
                        </Form.Item>
                        <Form.Item className="setting-item" name="status" label="Trạng thái">
                            <p>
                                {location.state?.record?.status ? <>Hoạt động</> : <>Không hoạt động</>}
                            </p>
                        </Form.Item>
                        <Form.Item className="setting-item" name="createdDate" label="Ngày tạo">
                            <p className="created-date">{formatDateV2(location.state?.record?.createdDate)}</p>
                        </Form.Item>
                    </CustomCard>
                </div>
            </section>
        </Content>
    );
};

export default PortfolioValueManagementDetail;
