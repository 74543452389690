import React, {useEffect, useMemo, useState, useTransition} from "react";
import {debounce} from "lodash";
import {BarStackedChartProps, DualChart} from "../../../../../types/static-report";
import dayjs from "dayjs";
import {staticReportStore} from "../../../../../stores/stores";
import {
  getDiscussCourseActionForMinistry,
  getRateUnitByCourseForMinistry,
  getRateUnitByModuleForMinistry,
  getRateUnitBySequenceForMinistry,
  getUnitDiscussForMinistry,
  getUnitReviewAndAccessForMinistry
} from "../../../../../service/statistical-report";
import FeedbackAndEvaluationChartTab from "../chart/feedback-and-evaluation-tab/FeedbackAndEvaluationChartTab";
import FeedbackEvaluationTable from "../table/feedback-evaluation";

type Props = {
  dataType: string
};

const FeedbackAndEvaluationTab = ({dataType}: Props) => {
  const [isPending, startTransition] = useTransition();
  const {staticReportType, filterValues, tab, isSelectedUniversity} = staticReportStore();
  const [moduleGroup, setModuleGroup] = useState(3);

  const [chartData, setChartData] = useState({
    unitDiscussForMinistry: [] as BarStackedChartProps[],
    discussCourseActionForMinistry: [] as BarStackedChartProps[],
    unitReviewAndAccessForMinistry: [] as DualChart[],
    rateUnitByCourseForMinistry: [] as BarStackedChartProps[],
    rateUnitBySequenceForMinistry: [] as BarStackedChartProps[],
    rateUnitByModuleForMinistry: [] as BarStackedChartProps[],
  });

  const [loading, setLoading] = useState({
    unitDiscussForMinistry: false,
    discussCourseActionForMinistry: false,
    unitReviewAndAccessForMinistry: false,
    rateUnitByCourseForMinistry: false,
    rateUnitBySequenceForMinistry: false,
    rateUnitByModuleForMinistry: false,
  });

  const payload = useMemo(
    () => ({
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: isSelectedUniversity ? filterValues?.universityIds : [],
      timeUnit: filterValues.timeUnit
    }),
    [
      filterValues.dateFrom,
      filterValues.dateTo,
      filterValues?.courseLevelIds,
      filterValues?.industryGroupIds,
      filterValues?.courseIds,
      filterValues?.universityIds,
      filterValues.timeUnit,
    ]
  );

  const fetchDataChart = async () => {
    try {
      const listApi = [
        {
          key: 'unitDiscussForMinistry',
          api: getUnitDiscussForMinistry,
          params: payload,
        },
        {
          key: 'discussCourseActionForMinistry',
          api: getDiscussCourseActionForMinistry,
          params: payload,
        },
        {
          key: 'rateUnitByCourseForMinistry',
          api: getRateUnitByCourseForMinistry,
          params: payload,
        },
        {
          key: 'rateUnitBySequenceForMinistry',
          api: getRateUnitBySequenceForMinistry,
          params: payload,
        },
        {
          key: 'rateUnitByModuleForMinistry',
          api: getRateUnitByModuleForMinistry,
          params: payload,
        }
      ];

      await Promise.allSettled(
        listApi.map(async ({key, api, params}) => {
          setLoading((prev) => ({...prev, [key]: true}));
            const response = await api(params);
            const data = response?.data?.data?.statistic || response?.data?.data;
            setChartData((prevState) => ({
              ...prevState,
              [key]: data,
            }));
            setLoading((prev) => ({...prev, [key]: false}));
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchUnitReviewAndAccessForMinistry = async (moduleGroupNumber: number) => {
    setLoading((prev) => ({...prev, unitReviewAndAccessForMinistry: true}));
    try {
      const data = await getUnitReviewAndAccessForMinistry({
        ...payload,
        courseStructureType: "unit",
        moduleGroup: moduleGroupNumber,
      });
      setChartData((prevState) => ({
        ...prevState,
        unitReviewAndAccessForMinistry: data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching unitReviewAndAccessForMinistry data:", error);
    } finally {
      setLoading((prev) => ({...prev, unitReviewAndAccessForMinistry: false}));
    }
  };

  const debouncedFetchData = useMemo(
    () => debounce(fetchDataChart, 300),
    [filterValues]
  );

  const debouncedFetchUnitReviewAndAccess = useMemo(
    () => debounce(fetchUnitReviewAndAccessForMinistry, 300),
    [filterValues]
  );

  useEffect(() => {
    if (tab === "3") {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  useEffect(() => {
    if (tab === "3") {
      debouncedFetchUnitReviewAndAccess(moduleGroup);
    }
    return () => {
      debouncedFetchUnitReviewAndAccess.cancel();
    };
  }, [filterValues, tab, moduleGroup]);

  const memoizedUnitDiscussForMinistry = useMemo(
    () => chartData.unitDiscussForMinistry,
    [chartData.unitDiscussForMinistry]
  );

  const memoizedDiscussCourseActionForMinistry = useMemo(
    () => chartData.discussCourseActionForMinistry,
    [chartData.discussCourseActionForMinistry]
  );

  const memoizedUnitReviewAndAccessForMinistry = useMemo(
    () => chartData.unitReviewAndAccessForMinistry,
    [chartData.unitReviewAndAccessForMinistry]
  );

  const memoizedRateUnitBySequenceForMinistry = useMemo(
    () => chartData.rateUnitBySequenceForMinistry,
    [chartData.rateUnitBySequenceForMinistry]
  );

  const memoizedRateUnitByCourseForMinistry = useMemo(
    () => chartData.rateUnitByCourseForMinistry,
    [chartData.rateUnitByCourseForMinistry]
  );

  const memoizedRateUnitByModuleForMinistry = useMemo(
    () => chartData.rateUnitByModuleForMinistry,
    [chartData.rateUnitByModuleForMinistry]
  );

  // const memoizedChartData = useMemo(() => {
  //   return {
  //     unitDiscussForMinistry: chartData.unitDiscussForMinistry,
  //     discussCourseActionForMinistry: chartData.discussCourseActionForMinistry,
  //     unitReviewAndAccessForMinistry: chartData.unitReviewAndAccessForMinistry,
  //     rateUnitBySequenceForMinistry: chartData.rateUnitBySequenceForMinistry,
  //     rateUnitByCourseForMinistry: chartData.rateUnitByCourseForMinistry,
  //     rateUnitByModuleForMinistry: chartData.rateUnitByModuleForMinistry,
  //   };
  // }, [chartData]);

  return (dataType === "1") ?
    <FeedbackAndEvaluationChartTab 
      // {...memoizedChartData} 
      loading={loading}
      unitDiscussForMinistry={memoizedUnitDiscussForMinistry}
      discussCourseActionForMinistry={memoizedDiscussCourseActionForMinistry}
      unitReviewAndAccessForMinistry={memoizedUnitReviewAndAccessForMinistry}
      rateUnitBySequenceForMinistry={memoizedRateUnitBySequenceForMinistry}
      rateUnitByCourseForMinistry={memoizedRateUnitByCourseForMinistry}
      rateUnitByModuleForMinistry={memoizedRateUnitByModuleForMinistry}
      setModuleGroup={setModuleGroup}
      
    /> :
    <FeedbackEvaluationTable 
      // {...memoizedChartData} 
      loading={isPending} 
      unitDiscussForMinistry={memoizedUnitDiscussForMinistry}
      discussCourseActionForMinistry={memoizedDiscussCourseActionForMinistry}
      unitReviewAndAccessForMinistry={memoizedUnitReviewAndAccessForMinistry}
      rateUnitBySequenceForMinistry={memoizedRateUnitBySequenceForMinistry}
      rateUnitByCourseForMinistry={memoizedRateUnitByCourseForMinistry}
      rateUnitByModuleForMinistry={memoizedRateUnitByModuleForMinistry}
      setModuleGroup={setModuleGroup}
    />;
  };

export default FeedbackAndEvaluationTab;
