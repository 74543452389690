import { Radio, Typography } from "antd";
import { AnswersPros } from "./survey-answers-content";
import { convertQuillToString } from "../../../../../../../utils/convertQuillToString";
import ReactPlayer from "react-player";
import { useEffect } from "react";

const Video = (props: AnswersPros) => {
  const { question } = props;
  return <div className='survey-answers-content__wrapper'>
    <div className='survey-answers-content__title'>
      <Typography.Title level={3}>{convertQuillToString(question.question)}</Typography.Title>
    </div>
    <div className='survey-answers-content__body'>
      {question.resultFile.map((x: any, i: number) => {
        return <div key={i + 1} className='survey-answers-content__body__item'>
          <div className='item__action item__action__media'>
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              url={x.signedUrl}
              controls={true}
            />
          </div>
        </div>
      })}
    </div>
  </div>
}

export default Video;