import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";

export const getListPortalAdministration: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university/portal/get-by-condition`, data);
};

export const createPortalAdministration: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.post(`/mooc-university/portal`, data);
};

export const updatePortalAdministration: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.put(`/mooc-university/portal`, data);
};

export const getAllUniversity: (name: string) => Promise<AxiosResponse<any>> = (
  name
) => {
  return axiosConfigV2.get(`/mooc-university/portal/get-by-name?name=${name}`);
};

export const getDetailPortalAdministration: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-university/portal/${id}`);
};

export const deletePortalAdministration: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-university/portal/${id}`);
};

export const getAllCurriculum: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-curriculum/get-by-condition`, data);
};

export const getAllCourse: (uuid: string | undefined, 
  name: string | undefined
) => Promise<AxiosResponse<any>> = (uuid,name) => {
  return axiosConfigV2.get(`/mooc-courses/get-by-name/${uuid}?name=${name}`);
};

export const createListProgram: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigToUpload.post(`/mooc-curriculum/create`, data);
};

export const updateListProgram: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigToUpload.put(`/mooc-curriculum/update`, data);
};

export const deleteListProgram: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.delete(`/mooc-curriculum/delete`, { data });
};

export const getDetailCurriculum: (id: string | undefined) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-curriculum/${id}`);
};

export const publishListProgram: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.put(`/mooc-curriculum/publish`, data);
};

export const saveComponentListProgram: (id: string, data: any) => Promise<AxiosResponse<any>> = (
  id, data
) => {
  return axiosConfigV2.post(`/mooc-curriculum/save-component/${id}`, data);
};

export const getComponentListProgram: (id: string, data: any) => Promise<AxiosResponse<any>> = (
  id, data
) => {
  return axiosConfigV2.post(`/mooc-curriculum/get-component-by-user/${id}`, data);
};

export const getDetailPublicListProgram: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-curriculum/get-information-publish/${id}`);
};

