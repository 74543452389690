import React, { useEffect, useState } from 'react';
import DrawerCustom from '../../../../drawer/Drawer';
import { Button, Checkbox, Col, Form, Radio, Row, Space, Typography } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import FormInput from "../../../../form-input/FormInput";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import { get, isNull } from "lodash";
import { getCourseDetail, postCourseSearch } from "../../../../../service/course-construct";
import './index.scss'
import { globalStore } from '../../../../../stores/stores';
import { Permission } from '../../../../../layout/sidebar/permission.enum';

type CreateExamProps = {
  id?: number | null,
  isOpened: boolean,
  closeForm: () => void,
  dataOptions: any,
  dataDetail: any,
  user: any,
}

const validations = {
  code: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 6) {
        return Promise.reject(
          new Error("Mã đề phải từ 6 ký tự trở lên")
        );
      }
      if (value && value.length > 255) {
        return Promise.reject(
          new Error("Mã đề không vượt quá 255 ký tự")
        );
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập mã đề"));
      }
      return Promise.resolve();
    },
  },
  author: {
    required: true,
    message: "Vui lòng chọn tác gỉả",
  },
  name: {
    required: true,
    message: "Vui lòng chọn tiêu đề",
  },
  topic: {
    required: true,
    message: "Vui lòng chọn chủ đề",
  }
};

const mockExam = [
  {
    id: 1,
    name: "Đề 1"
  },
  {
    id: 2,
    name: "Đề 2"
  },
  {
    id: 3,
    name: "Đề 3"
  },
  {
    id: 4,
    name: "Đề 4"
  }
]

const dataTags = [
  { code: 'tag 1', name: "Tag 1" },
  { code: 'tag 2', name: "Tag 2" },

]


const getAllChildren = (sections: any[], result = []) => {
  sections.forEach((section) => {
    if (section.children && section.children.length > 0) {
      getAllChildren(section.children, result); // Recursively add children to the result array
    } else {
      // @ts-ignore
      result.push(section); // Add leaf children (no more nested children) to the array
    }
  });
  return result;
};


const CreateExam = ({ isOpened, id, closeForm, dataOptions, user, dataDetail }: CreateExamProps) => {
  const [hadPlan, setHadPlan] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [dataCourse, setDataCourse] = useState([]);
  const [dataSection, setDataSection] = useState([]);
  const [valueCourse, setValueCourse] = useState(0);
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const fetchCourseData = async () => {
    const dataReq = {
      pageNumber: 1,
      pageSize: 1000,
    }
    setLoading(true)
    try {
      const response = await postCourseSearch(dataReq)
      const { data } = response.data;
      const newData = data.data.map((data: any) => ({ name: data.name, code: data.id }))
      setDataCourse(newData)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }

  }

  const fetchDetailCourse = async (id: number) => {
    try {
      const response = await getCourseDetail(id.toString())
      const { data } = response.data;
      setDataSection(data.sections)
    } catch (e) {
      console.error(e)
    }
  }


  const getValue = (value: any) => {
    setValueCourse(value)
  }


  useEffect(() => {
    if (id && id !== 0 && !isNull(dataDetail) && Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        code: dataDetail.code,
        authors: dataDetail.authors,
        title: dataDetail.title,
        topics: dataDetail.topics,
        description: dataDetail.description,
        tags: dataDetail.tags,
      });
    }
  }, [dataDetail, id]);


  useEffect(() => {
    if (!isOpened) {

      form.resetFields();
    }
  }, [isOpened]);

  useEffect(() => {
    if (!isSync) {
      form.resetFields(['course'])
      setDataSection([])
    }
  }, [isSync]);


  useEffect(() => {
    fetchCourseData()
  }, []);


  useEffect(() => {
    if (valueCourse > 0) {
      fetchDetailCourse(valueCourse)
    }
  }, [valueCourse]);

  const child = getAllChildren(dataSection)
  return (
    <>
      <DrawerCustom
        title={id && id !== 0 ? "Chỉnh sửa đề" : "Thêm mới đề"}
        open={isOpened}
        onClose={closeForm}
        extraNode={
          <Space>
            <Button
              onClick={closeForm}
              className="btn btn-outlined"
            >
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        <Form
          layout={"vertical"}
          form={form}
          name="createUnitBank"
          initialValues={{ tags: [], authors: [get(user, 'id', '')] }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="code"
                rules={[validations.code]}
                label="Mã đề"
              >
                <FormInput disabled={!!id} placeholder="Nhập tên khóa học" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="authors"
                rules={[validations.author]}
                label="Tác giả"
              >
                <FormItemDropdown
                  modeDropdown="multiple"
                  options={permissions?.includes(Permission.isMinistry) ? dataOptions.dataUsersMinistry : dataOptions.dataUsers}
                  allowClear={true}
                  placeholder="Chọn tác giả"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Tiêu đề"
                rules={[validations.name]}
              >
                <FormInput placeholder="Nhập tiêu đề" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="topics"
                label="Chủ đề"
                rules={[validations.topic]}
              >
                <FormItemDropdown
                  // priority="code"
                  modeDropdown="multiple"
                  options={dataOptions.dataTopic}
                  allowClear={true}
                  placeholder="Chọn chủ đề"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Mô tả"
              >
                <FormInput placeholder="Nhập mô tả" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="tags" label="Nhãn">
                <FormItemDropdown
                  options={dataTags}
                  modeDropdown={'tags'}
                  allowClear={true}
                  placeholder="Thêm nhãn"
                />
              </Form.Item>
              {isNull(id) && <label className="label-down-text">
                Kế hoạch xây dựng đã được phê duyệt
              </label>}
            </Col>
            {isNull(id) && <Col span={12} className="check-box-sync">
              <Form.Item name="isSyncPlan">
                <div className={`text-sync ${hadPlan ? "" : "text-sync-grey"}`}>
                  <Checkbox
                    onChange={() => setIsSync(!isSync)}
                    checked={isSync}
                  />{" "}
                  Thêm đề từ khóa học
                </div>
              </Form.Item>
            </Col>}
          </Row>
          {isNull(id) && <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="course"
              >
                <FormItemDropdown
                  disabled={!isSync}
                  loading={loading}
                  options={dataCourse}
                  allowClear={false}
                  onChange={getValue}
                  placeholder="Chọn khóa học"
                />
              </Form.Item>
            </Col>
            {dataSection && dataSection.length > 0 && <Col span={24}>
              <Form.Item
                name="examId"
                className="list-exam"
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {
                      child.map((child: any) => (
                        <div key={child.id} className="wrapper-radio">
                          <Radio value={child.id}>
                            {child.name}
                          </Radio>
                        </div>
                      ))
                    }
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>}
          </Row>}
        </Form>
      </DrawerCustom>
    </>
  );
};

export default CreateExam;