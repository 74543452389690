import { Button, Collapse, Space, Typography } from 'antd';
import React from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import DrawerCustom from "../../../../drawer/Drawer";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { IDataDetail } from '../../../../../pages/library/bank-exam/components/personal-exam-repository/list-exam';
import './index.scss'
import { isNull } from "lodash";

type DetailExamProps = {
  isOpened: boolean
  closeForm: () => void
  dataDetail?: IDataDetail | null
}

const DetailExam = ({ isOpened, closeForm, dataDetail }: DetailExamProps) => {
  const { Panel } = Collapse;
  const { Title, Text } = Typography;
  const {
    title,
    description,
    code,
    numberQuestion,
    topics,
    numberOfUse,
    authors,
    access,
    productionUnit,
    tags,
    typeProduction,
    createdBy
  } = dataDetail || {};
  return (
    <DrawerCustom title={'Xem chi tiết đề'}
      open={isOpened}
      onClose={closeForm}
      bodyStyle={{
        padding: 0,
      }}
      extraNode={
        <Space>
          <Button
            onClick={closeForm}
            className="btn btn-outlined"
          >
            <CloseOutlined />
            <Typography.Text>Hủy</Typography.Text>
          </Button>
          <Button
            className="btn btn-primary"
            htmlType="submit"
          // onClick={saveData}
          >
            <SaveOutlined />
            <Typography.Text>Lưu</Typography.Text>
          </Button>
        </Space>
      }
      widthDrawer={736}
    >
      <div>
        <CustomCard
          className="card-detail-exam"
        >
          <div>
            <Collapse defaultActiveKey={['1', '2']} ghost className="collapse-exam-detail">
              <Panel header="Thông tin đề" key="1" className="collapse-exam-detail--header">
                <div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Mã đề:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {code}
                      </Text>
                    </div>
                  </div>

                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Tiêu đề:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {title}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Chủ đề:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {topics}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Số câu hỏi:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {numberQuestion}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Mô tả:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {description}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Nhãn:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {tags}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Lượt dùng:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {!isNull(numberOfUse) ? numberOfUse : 0}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Phân cấp:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {!isNull(typeProduction) ? typeProduction : 'Không có'}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Tác giả:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {authors}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Đơn vị xuất bản:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {!isNull(productionUnit) ? productionUnit : 'Không có'}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Quyền truy cập:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {access}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Người tạo
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {createdBy}
                      </Text>
                    </div>
                  </div>
                </div>
              </Panel>

            </Collapse>
          </div>
        </CustomCard>
      </div>
    </DrawerCustom>
  );
};

export default DetailExam;