import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Flex, Input, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { generateRandomId } from "../../../../../../utils/functionUltils";
import { toNumber, toString } from "lodash";

export interface RateScoreProps {
  id: string | number;
  numberingScaleFrom: number;
  numberingScaleTo: number;
  letterScale: string;
}

const MIN_SCORE = 0;
const MAX_SCORE = 10;
const initialAddValue = {
  id: "",
  numberingScaleFrom: 0,
  numberingScaleTo: 0,
  letterScale: "",
};

function formatNumber(num: number): string {
  return Number.isInteger(num) ? `${num}.0` : `${num}`;
}

interface RateScoreTableProps {
  data?: RateScoreProps[];
  onChange?: (value: RateScoreProps[]) => void;
}

function RateScoreTable({ data, onChange = () => {} }: RateScoreTableProps) {
  const [scoresRate, setScoresRate] = useState<RateScoreProps[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const [rateAddingValue, setAddingValue] =
    useState<RateScoreProps>(initialAddValue);
  const [rateEditingValue, setEditingValue] =
    useState<RateScoreProps>(initialAddValue);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    if (data?.length) {
      setScoresRate(data);
    }
  }, [data]);

  useEffect(() => {
    if (isDirty) onChange(scoresRate);
  }, [scoresRate, isDirty]);

  const handleSave = () => {
    setIsDirty(true);
    const newValue = {
      id: generateRandomId("addingScore"),
      numberingScaleFrom: rateAddingValue?.numberingScaleFrom,
      numberingScaleTo: rateAddingValue?.numberingScaleTo,
      letterScale: rateAddingValue?.letterScale,
    };

    setScoresRate((prevState: RateScoreProps[]) => [...prevState, newValue]);
    setIsAdding(false);
    setAddingValue(initialAddValue);
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
    setAddingValue(initialAddValue);
  };

  const handleCancelEdit = () => {
    setEditingValue(initialAddValue);
  };

  const handleSaveEdit = () => {
    setIsDirty(true);
    const newItem = {
      id: generateRandomId("addingScore"),
      numberingScaleFrom: rateEditingValue?.numberingScaleFrom,
      numberingScaleTo: rateEditingValue?.numberingScaleTo,
      letterScale: rateEditingValue?.letterScale,
    };
    const newArray = scoresRate.map((item: RateScoreProps) =>
      item.id === rateEditingValue.id ? { ...item, ...newItem } : item
    );
    setScoresRate(newArray);
    setEditingValue(initialAddValue);
  };

  const handleDelete = (id: string | number) => {
    setIsDirty(true);
    Modal.confirm({
      title: "Xoá quy tắc",
      content: "Bạn có muốn xoá quy tắc này?",
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: () => {
        setScoresRate((prevState: RateScoreProps[]) =>
          prevState.filter((item: RateScoreProps) => item.id !== id)
        );
      },
      onCancel() {},
    });
  };

  return (
    <div className="rate-score-table mt-5">
      <Flex className="mb-1" justify="flex-start" gap={8}>
        <Button
          disabled={isAdding}
          className="btn-primary"
          onClick={() => setIsAdding(true)}
          icon={<PlusOutlined />}
        >
          Thêm mới
        </Button>
      </Flex>
      <Flex className="rate-row-header">
        <div className="tb-score-number">Thang điểm 10</div>
        <div className="tb-score-char">Thang điểm chữ</div>
      </Flex>
      <Flex className="rate-td-row">
        <div className="tb-item">Từ</div>
        <div className="tb-item">Đến</div>
        <div className="tb-item"></div>
      </Flex>
      <Flex className="rate-td-row">
        <div className="tb-item text-des">8.5</div>
        <div className="tb-item text-des">10</div>
        <div className="tb-item text-des">A+</div>
      </Flex>
      {scoresRate?.length > 0 &&
        scoresRate.map((item: RateScoreProps) => {
          // edit item
          if (item.id === rateEditingValue.id) {
            return (
              <Flex
                className="rate-td-row adding-row"
                key={rateEditingValue.id}
              >
                <div className="tb-item-adding">
                  <InputNumber
                    value={rateEditingValue.numberingScaleFrom}
                    onChange={(value) =>
                      setEditingValue((prev: RateScoreProps) => ({
                        ...prev,
                        numberingScaleFrom: toNumber(value),
                      }))
                    }
                    className="input-adding-score"
                    min={MIN_SCORE}
                    max={MAX_SCORE}
                    step={0.1}
                  />
                </div>
                <div className="tb-item-adding">
                  <InputNumber
                    value={rateEditingValue.numberingScaleTo}
                    className="input-adding-score"
                    min={MIN_SCORE}
                    max={MAX_SCORE}
                    step={0.1}
                    onChange={(value) =>
                      setEditingValue((prev: RateScoreProps) => ({
                        ...prev,
                        numberingScaleTo: toNumber(value),
                      }))
                    }
                  />
                </div>
                <div className="tb-item-adding">
                  <Input
                    value={rateEditingValue.letterScale}
                    onChange={(e) =>
                      setEditingValue((prev: RateScoreProps) => ({
                        ...prev,
                        letterScale: toString(e.target.value),
                      }))
                    }
                    className="input-adding-score"
                    maxLength={2}
                  />
                </div>
                <Flex className="save-score-btn" gap={8}>
                  <Button
                    onClick={handleSaveEdit}
                    className="btn-primary ml-2"
                    icon={<SaveOutlined />}
                  >
                    Lưu
                  </Button>
                  <Button onClick={handleCancelEdit}>Huỷ</Button>
                </Flex>
              </Flex>
            );
          }
          // render item in list
          return (
            <Flex className="rate-td-row" key={item.id}>
              <div className="tb-item">
                {formatNumber(item.numberingScaleFrom)}
              </div>
              <div className="tb-item">
                {formatNumber(item.numberingScaleTo)}
              </div>
              <div className="tb-item one-line">{item.letterScale}</div>
              <Flex className="btn-row-action" gap={6}>
                <Button
                  className="btn-none-border"
                  onClick={() => {
                    setEditingValue({
                      id: item.id,
                      letterScale: item.letterScale,
                      numberingScaleFrom: item.numberingScaleFrom,
                      numberingScaleTo: item.numberingScaleTo,
                    });
                  }}
                >
                  <EditOutlined />
                </Button>
                <Button
                  className="btn-none-border"
                  onClick={() => handleDelete(item.id)}
                >
                  <DeleteOutlined />
                </Button>
              </Flex>
            </Flex>
          );
        })}

      {isAdding && (
        <Flex className="rate-td-row adding-row">
          <div className="tb-item-adding">
            <InputNumber
              onChange={(value) =>
                setAddingValue((prev: RateScoreProps) => ({
                  ...prev,
                  numberingScaleFrom: toNumber(value),
                }))
              }
              className="input-adding-score"
              min={MIN_SCORE}
              max={MAX_SCORE}
              step={0.1}
            />
          </div>
          <div className="tb-item-adding">
            <InputNumber
              className="input-adding-score"
              min={MIN_SCORE}
              max={MAX_SCORE}
              step={0.1}
              onChange={(value) =>
                setAddingValue((prev: RateScoreProps) => ({
                  ...prev,
                  numberingScaleTo: toNumber(value),
                }))
              }
            />
          </div>
          <div className="tb-item-adding">
            <Input
              onChange={(e) =>
                setAddingValue((prev: RateScoreProps) => ({
                  ...prev,
                  letterScale: toString(e.target.value),
                }))
              }
              className="input-adding-score"
            />
          </div>
          <Flex className="save-score-btn" gap={8}>
            <Button
              onClick={handleSave}
              className="btn-primary ml-2"
              icon={<SaveOutlined />}
            >
              Lưu
            </Button>
            <Button onClick={handleCancelAdd}>Huỷ</Button>
          </Flex>
        </Flex>
      )}
    </div>
  );
}

export default RateScoreTable;
