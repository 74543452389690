// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.math-recipe .recipe-container {
  min-height: 100px;
  justify-content: center; }
  .math-recipe .recipe-container .dist-formula {
    height: 100%;
    padding: 6px 8px !important;
    font-size: 24px;
    background: #f0f0f0 !important; }

.math-recipe .fixed-container {
  gap: 16px; }

.math-recipe .const-formula {
  border-radius: 6px;
  border: 1px solid #9F224E;
  cursor: pointer; }

.sort-container {
  width: 100%;
  min-height: 100px; }

.dnd-container {
  padding: 20px;
  min-height: 100px;
  background: #f0f0f0;
  margin-top: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/tabs-setting-common/tab-evalute/components/ScoreFormula/style.scss"],"names":[],"mappings":"AACA;EAEI,iBAAiB;EACjB,uBAAuB,EAAA;EAH3B;IAKM,YAAY;IACZ,2BAA2B;IAC3B,eAAe;IACf,8BAA8B,EAAA;;AARpC;EAaI,SAAS,EAAA;;AAbb;EAiBI,kBAAkB;EAClB,yBAAyB;EACzB,eAAe,EAAA;;AAInB;EACE,WAAW;EACX,iBAAiB,EAAA;;AAGnB;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB,EAAA","sourcesContent":["@import '../../../../../../../styles/variables';\n.math-recipe {\n  .recipe-container {\n    min-height: 100px;\n    justify-content: center;\n    .dist-formula{\n      height: 100%;\n      padding: 6px 8px !important;\n      font-size: 24px;\n      background: #f0f0f0 !important;\n    }\n  }\n\n  .fixed-container {\n    gap: 16px;\n  }\n\n  .const-formula {\n    border-radius: 6px;\n    border: 1px solid #9F224E;\n    cursor: pointer;\n  }\n}\n\n.sort-container {\n  width: 100%;\n  min-height: 100px;\n}\n\n.dnd-container {\n  padding: 20px;\n  min-height: 100px;\n  background: #f0f0f0;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
