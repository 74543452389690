import React, { ReactElement, useEffect, useState } from "react";
import TextEditor from "../../../text-editor/TextEditor";
import {
  Button,
  Col,
  message,
  Row,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import {
  CameraOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./style.scss";
import { useAddLesson, useAuthStore } from "../../../../stores/stores";
import {
  initialChooseFileUploadQuestionValue,
  initialChooseVideoCriteriaValue,
} from "../../../../constants";
import { SortableList } from "../../../SortableList";
import {
  CriteriaVideoInfo,
  QuestionData,
  QuestionItemDetail,
} from "../../../../types/course-construction";
import { findMaxIndexOfArray, mapAnsBEtoFE } from "../../../../utils/arrays";
import QuestionCriteria from "./QuestionCriteria";
import { FileType } from "../../../../utils/uploadImage";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { BASE_URL_V2 } from "../../../../config/api/configApiv2";
import { renderQuestionNameByType, renderQuestionDescriptionByType } from "../../../../utils/functionUltils";

const { Dragger } = Upload;

let locale = {
  emptyText: "Không có dữ liệu",
};

type DraggableUploadListItemProps = {
  originNode: ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: any;
};

export const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

function RecordVideo({ data = {} as QuestionData }: QuestionItemDetail) {
  const questionList = useAddLesson((state: any) => state.questionList);
  const question = useAddLesson((state: any) => state.question);
  const [questionName, setQuestionName] = useState<string>("");
  let [dataAnswer, setDataAnswer] = useState(initialChooseVideoCriteriaValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [criteria, setCriteria] = useState<string[]>([])
  const [dataUpload, setDataUpload] = useState<UploadFile[]>([
    {
      uid: "",
      name: "",
      url: "",
    },
  ]);

  const accessToken = useAuthStore((state) => state.accessToken);
  const setCurrentQuestionInfo = useAddLesson(
    (state) => state.setCurrentQuestionInfo
  );
  const settingQuestion = useAddLesson((state) => state.settingQuestion);

  const handelUpdateAnswer = (data: CriteriaVideoInfo) => {
    let newList = [...dataAnswer];
    const indexOfItem = newList.findIndex((item: CriteriaVideoInfo) => {
      return item.id === data.id;
    });

    if (indexOfItem !== -1) {
      newList[indexOfItem] = {
        ...newList[indexOfItem],
        filePath: data.filePath as string,
        fileName: data.fileName as string,
      };
    }
    setDataAnswer(newList);
  };

  const handleChangeText = (current: { id: number; value: string }) => {
    const distAnswer = [...dataAnswer];
    // distCriteria[current.id] = current.value;
    const indexOfChangeItem = distAnswer.findIndex(
      (item) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      distAnswer[indexOfChangeItem] = {
        ...distAnswer[indexOfChangeItem],
        value: current.value,
      };
      setDataAnswer(distAnswer);
    }
  };

  const handleRemoveOneChoose = (id: number) => {
    if (id) {
      const newList = [...dataAnswer];
      setDataAnswer(newList.filter((item) => item.id !== id));
    } else console.error("Cannot find id");
  };

  const handleAddMoreChoose = () => {
    if (dataAnswer.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(dataAnswer) + 1,
        value: "",
        key: findMaxIndexOfArray(dataAnswer) + 1,
        isTrue: true,
        isDisplayContent: false,
        point: 0,
        filePath: "",
        fileName: "",
      };
      const newList = [...dataAnswer];
      setDataAnswer(newList.concat([newItem]));
    } else setDataAnswer(initialChooseFileUploadQuestionValue);
  };

  useEffect(() => {
    setQuestionName(question.question);
    if (data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      // const dataUploadAnswer = data.answers.map((i: BeQuizAnswer) => ({
      //   uid: get(i, "orderNumber", 0).toString(),
      //   url: i.filePath,
      //   name: i.fileName,
      // }));
      // setDataUpload(dataUploadAnswer);
      setDataAnswer(feAnswer);
    } else {
      setDataAnswer([]);
      setDataUpload([]);
      // setCriteria([]);
    }
    // const feCriteria = get(
    //   data.settingGeneral?.scoreCriteria,
    //   "scoreCriteria",
    //   []
    // );
    // if (feCriteria && feCriteria.length > 0) {
    //   setCriteria(feCriteria);
    // }
  }, [question, data.answers]);

  useEffect(() => {
    if (settingQuestion.displayScoreCriteria === undefined) {
      settingQuestion.displayScoreCriteria = true;
    }
    setCurrentQuestionInfo({ ...dataAnswer, questionName: questionName, });
  }, [dataAnswer, questionName]);

  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const handleDragChange = (value: any) => {
    // setCriteria(value);
  };

  const beforeUpload = (fileUpload: FileType) => {
    setIsLoading(true);
    const isVideo = fileUpload.type === "video/mp4";
    if (!isVideo) {
      message.error("Bạn phải tải lên file video/mp4");
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
    return isVideo;
  };

  const handleUpload: UploadProps["onChange"] = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} tải lên thành công.`);
      const saveAnswer = {
        id: findMaxIndexOfArray(dataAnswer) + 1,
        key: findMaxIndexOfArray(dataAnswer) + 1,
        value: "",
        isTrue: false,
        fileName: info.file.response?.fileName,
        filePath: info.file.response?.filePath,
        isDisplayContent: false,
        point: 0,
      };

      if (dataAnswer.length > 0) {
        dataAnswer = [...dataAnswer].concat(saveAnswer);
      } else {
        ([initialChooseVideoCriteriaValue[0].fileName] =
          info.file.response?.fileName),
          ([initialChooseVideoCriteriaValue[0].filePath] =
            info.file.response?.filePath),
          (dataAnswer = [initialChooseVideoCriteriaValue[0]]);
      }

      handelUpdateAnswer(saveAnswer);
    } else if (status === "error") {
      message.error(`${info.file.name} tải lên thất bại.`);
    }
    setDataUpload(info.fileList);
    if (status === "removed") {
      const newListAnswers = [...dataAnswer].filter((item, index) =>
        [...dataUpload]
          .filter((item) => item.status !== "removed")
          .some((ans) => item.id === parseInt(ans.uid))
      );
      setDataAnswer(newListAnswers);
    }
  };

  return (
    <section className="question-wrapper">
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionName} onChange={handleChangeQuestionName} />
      <Dragger
        className="dragger-record"
        action={BASE_URL_V2 + "/mooc-course-block-quiz/upload-file"}
        headers={{ Authorization: "Bearer " + accessToken }}
        fileList={dataUpload}
        name="file"
        accept=".mp4"
        beforeUpload={beforeUpload}
        itemRender={(originNode, item) => {
          return (
            <DraggableUploadListItem originNode={originNode} file={item} />
          );
        }}
        onChange={handleUpload}
      >
        <p className="ant-upload-drag-icon">
          <CameraOutlined />
        </p>
        <p className="ant-upload-text">Ghi hình</p>
      </Dragger>
      <div className="upload-record">
        <Button icon={<UploadOutlined />} disabled>
          Tải lên
        </Button>
      </div>

      {settingQuestion.displayScoreCriteria && (
        <div className="tree-course-construct list-answers">
          <Typography.Text className="mb-2">Tiêu chí tính điểm</Typography.Text>
          <SortableList
            items={dataAnswer}
            onChange={handleDragChange}
            renderItem={(record: any, index: number) => (
              <SortableList.Item id={record.id}>
                {/* <SortableList.DragHandle /> */}
                <QuestionCriteria
                  index={record.id}
                  value={record.value}
                  onChange={handleChangeText}
                  onDelete={handleRemoveOneChoose}
                />
              </SortableList.Item>
            )}
          />

          <Row>
            <Col span={24}>
              <Button
                className="btn-add-more mt-1"
                type="default"
                icon={<PlusOutlined />}
                onClick={handleAddMoreChoose}
              >
                Thêm tiêu chí
              </Button>
            </Col>
          </Row>
          {/* <div style={{ maxWidth: 400, margin: "30px auto" }}></div> */}
        </div>
      )}
    </section>
  );
}

export default RecordVideo;
