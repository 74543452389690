// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learning-material-filter .ant-col {
  flex: 1 1; }
`, "",{"version":3,"sources":["webpack://./src/pages/library/bank-exam/components/FormFilter/FormFilter.scss"],"names":[],"mappings":"AAAA;EAEI,SAAO,EAAA","sourcesContent":[".learning-material-filter {\n  .ant-col {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
