import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Form, Layout, Spin, Tabs, TabsProps } from "antd";
import React, { KeyboardEvent, useEffect, useState } from "react";
import FormItemInput from "../../components/form-input/FormInput";
import FilterSearch from "./components/FilterSearch";
import WaitingForConfirmation from "./WaitingForConfirmation";
import Confirmed from "./Confirmed";
import "./style.scss";
import { Permission } from "../../layout/sidebar/permission.enum";
import useKeyword from "../../hooks/useKeyword";
import { isArray, isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import Page403 from "../403";
import { LoadingComponent } from "../../components/loading/loading";
import { FilterSearchTransactionValues } from "../../types/transaction";
import dayjs from "dayjs";
import { globalStore } from "../../stores/stores";

function EnrollmentConfirmation() {
  const navigate = useNavigate();
  const university = useKeyword("university");
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [permissions, setPermission] = useState<string[]>([]);
  const [filterValues, setFilterValues] =
    useState<FilterSearchTransactionValues>();
  const [isSubmitSearchKeyWord, setIsSubmitSearchKeyWord] =
    useState<boolean>(false);
  const { personalInfo } = globalStore();
  const [isSystemManagement, setIsSystemManagement] = useState<boolean>(false);
  const [isFacilityManagementPayment, setIsFacilityManagementPayment] =
    useState<boolean>(false);

  useEffect(() => {
    if (permissions?.length) {
      if (permissions.includes(Permission.isQTCS)) {
        setIsFacilityManagementPayment(true);
      }
      if (permissions?.includes(Permission.isQTHT)) {
        setIsSystemManagement(true);
        if (isEmpty(university)) {
          navigate(routesConfig.trainingLocations);
        }
      }
    }
  }, [permissions]);

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      const listPermissionConvert = personalInfo?.permissions;
      setPermission(listPermissionConvert);
    };
    getRoles();
  }, [personalInfo]);

  const handleSubmitSearch = () => {
    setIsSubmitSearchKeyWord(!isSubmitSearchKeyWord);
  };

  const handleFilter = (value: any) => {
    setFilterValues({
      courseIds: value?.courseId ? [value?.courseId] : undefined,
      startTime: isArray(value?.time)
        ? dayjs(value?.time[0]).format("HH:mm:ss")
        : undefined,
      endTime: isArray(value?.time)
        ? dayjs(value?.time[1]).format("HH:mm:ss")
        : undefined,
      startDate: isArray(value?.date)
        ? dayjs(value?.date[0]).toISOString()
        : undefined,
      endDate: isArray(value?.date)
        ? dayjs(value?.date[1]).toISOString()
        : undefined,
    });
  };

  const handleChangeTab = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Chờ xác nhận",
      children: (
        <WaitingForConfirmation
          filter={filterValues}
          searchValue={searchValue}
          isSubmitSearch={isSubmitSearchKeyWord}
        />
      ),
    },
    {
      key: "2",
      label: "Đã xác nhận",
      children: (
        <Confirmed
          searchValue={searchValue}
          filter={filterValues}
          isSubmitSearch={isSubmitSearchKeyWord}
        />
      ),
    },
  ];

  const breadcrumb = [
    {
      title: (
        <Link to={`${routesConfig.trainingLocations}`}>
          Danh sách cơ sở đào tạo
        </Link>
      ),
    },
    {
      title: `Danh sách giao dịch`,
    },
  ];

  if (isFacilityManagementPayment || (isSystemManagement && university))
    return (
      <Layout className="page-header-group">
        <Form.Provider>
          <div className="enrollment-page w-full">
            <Spin spinning={loading}>
              <div className="enrollment-page-header">
                {!isEmpty(university) ? (
                  <Breadcrumb items={breadcrumb} className="mb-1" />
                ) : (
                  ""
                )}
                <div className="flex justify-between align-center gap-8">
                  <h3 className="page-title flex-1">Danh sách giao dịch</h3>
                  <Button onClick={() => setIsShowFilter(!isShowFilter)}>
                    Bộ lọc
                    <DownOutlined
                      className={`mooc-arrow ${
                        isShowFilter ? "down-arrow" : "up-arrow"
                      }`}
                    />
                  </Button>
                  <div className="search-input">
                    <FormItemInput
                      style={{ width: 280 }}
                      placeholder="Mã giao dịch hoặc mail học viên"
                      value={searchValue}
                      onChange={handleChangeInput}
                      onKeyPress={handleSearch}
                      afterPrefixIcon={
                        <SearchOutlined onClick={handleSubmitSearch} />
                      }
                    />
                  </div>
                </div>
                {isShowFilter && <FilterSearch onSearch={handleFilter} />}
              </div>
              <div className="enrollment-content">
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  onChange={handleChangeTab}
                />
              </div>
            </Spin>
          </div>
        </Form.Provider>
      </Layout>
    );

  return loading ? <LoadingComponent /> : <Page403 />;
}

export default EnrollmentConfirmation;
