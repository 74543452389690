import { AxiosResponse } from "axios"
import { axiosConfigV2 } from "../../../config/api/configApiv2"

export const getMoocPositionById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/auth-organization-management/position/${id}`)
}
export const getAllPositionByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/auth-organization-management/position/get-all-by-filter`, data)
}

export const addMoocPosition: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/auth-organization-management/position`, data)
}

export const updateMoocPosition: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`auth-organization-management/position`, data)
}

export const deleteMoocPosition: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/auth-organization-management/position/${id}`)
}
