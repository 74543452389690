import CustomCard from "../../../../custom-card/CustomCard";
import { Button, Dropdown, Flex, Form, Input, message, Modal, Space, Tooltip, Typography, } from "antd";
import { SortableList } from "../../../../SortableList";
import "./plan-detail.scss";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import React, { KeyboardEvent, useEffect, useState } from "react";
import DrawerCustom from "../../../../drawer/Drawer";
import FormCreateSurveyForm from "./form-create-survey-form/FormCreateSurveyForm";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addSurveyFormToQuestionBank,
  createSurveyForm,
  deleteSurveyFormById,
  getAllSurveyForm,
  getListBankCode,
  getSurveyFormById,
  updateOrderNumberSurveyForm,
  updateSurveyForm,
} from "../../../../../service/course-construct/survey-plan";
import { routesConfig } from "../../../../../config/routes";
import dayjs from "dayjs";
import { HelpService } from "../../../../../service/helper.service";
import CommonModal from "../../../../modal/common";
import FormItemInput from "../../../../form-input/FormInput";
import TableData from "../../../../table-data/TableData";
import TableSurveyAnswers from "./table-survey-answers/table-survey-answers";

var indexDB = require("idbcache").default;

type SurveyPlanDetailProps = {
  surveyPlan?: any;
};

const itemsMenuDropDown: MenuProps["items"] = [
  {
    label: "Chỉnh sửa",
    key: "1",
    icon: <EditOutlined />,
  },
  {
    label: "Thiết lập câu hỏi",
    key: "2",
    icon: <SettingOutlined />,
  },
  {
    label: "Xem câu trả lời",
    key: "3",
    icon: <UnorderedListOutlined />,
  },
  {
    label: "Lưu vào ngân hàng đề",
    key: "4",
    icon: <SaveOutlined />,
  },
  {
    label: "Xóa phiếu khảo sát",
    key: "5",
    icon: <DeleteOutlined />,
    danger: true,
  },
];

const PlanDetail = ({ surveyPlan }: SurveyPlanDetailProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const helpService = new HelpService();

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [listDataSurveyForm, setListDataSurveyForm] = useState<any>([]);
  const [editId, setEditId] = useState<null | number>(null);
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const [idSurveyForm, setIdSurveyForm] = useState<number>(0);
  const [bankCodeValue, setBankCodeValue] = useState<string>("");
  const [recordDetail, setRecordDetail] = useState<any>();
  const [dataOptions, setDataOptions] = useState({ dataSurveyForm: [], });
  const [dataDetail, setDataDetail] = useState({
    name: undefined,
    code: undefined,
    templateBank: undefined,
    timeEnd: undefined,
    timeStart: undefined,
  });
  const [isSurveyAnswersTable, setIsSurveyAnswersTable] = useState<boolean>(false);

  const getSurveyForm = async (id: number) => {
    try {
      const res = await getAllSurveyForm(id);
      setListDataSurveyForm(res.data.data);
    } catch (error) {
    }
  };

  const getBankCode = async () => {
    try {
      const { data } = await getListBankCode();
      setDataOptions({
        ...dataOptions,
        dataSurveyForm: data.data,
      });
    } catch (err) {
    }
  };

  // Copy link
  const handleCopyLink = async (url: string) => {
    const urlToCopy = url;
    // Sao chép vào clipboard
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        // Hiển thị thông báo thành công
        message.success("Sao chép đường dẫn thành công!");
      })
      .catch((err) => {
        // Hiển thị thông báo lỗi nếu có lỗi xảy ra
        message.error("Có lỗi xảy ra khi sao chép đường dẫn!");
        console.error("Could not copy text: ", err);
      });
  };

  // Drag
  const handleDragApi = async (values: any) => {
    try {
      const requestOrderSurveyForm = await updateOrderNumberSurveyForm(values);
      helpService.successMessage(requestOrderSurveyForm.data.message);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDragChange = async (values: any) => {
    setListDataSurveyForm(values);

    const newOrderNumber = {
      orderNumberSurveyForm: values.map((item: any, index: number) => {
        return {
          surveyFormID: item.id,
          orderNumber: index + 1,
        };
      }),
    };
    handleDragApi(newOrderNumber);
  };


  // Handle submit modal save/edit survey form
  const handleSaveForm = async (values: any) => {
    try {
      if (isOpened && !editId) {
        await createSurveyForm(values);
        message.success("Thêm phiếu khảo sát thành công!");
        setReloadData(!reloadData);
      } else if (isOpenedEdit && editId) {
        await updateSurveyForm(values);
        message.success("Sửa phiếu khảo sát thành công!");
        setReloadData(!reloadData);
      }
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  const onFinish = (values: any) => {
    const payloadData = {
      surveyPlanId: surveyPlan.id,
      idSurveyForm: editId ? editId : null,
      name: values.surveyTitle,
      code: values.surveyCode,
      templateBank: values.surveyForm ? values.surveyForm : null,
      startDate: values?.executionTime
        ? (values?.executionTime[0]).toISOString()
        : "",
      endDate: values?.executionTime
        ? (values?.executionTime[1]).toISOString()
        : "",
    };
    handleSaveForm(payloadData);
  };

  const handleSaveCreate = () => {
    form.submit();
    setIsOpenConfirm(false)
  };

  // Modal add/edit survey form
  const handleAddSurveyForm = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    setEditId(null);
    form.resetFields();
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const getSurveyFormEdit = (id: number) => {
    getSurveyFormById(id)
      .then((res) => {
        const { data } = res.data;
        setDataDetail(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Delete survey form
  const deleteSurvey = async (id: number) => {
    try {
      await deleteSurveyFormById(id);
      message.success("Xoá phiếu khảo sát thành công!");
      setReloadData(!reloadData);
    } catch (err) {
      console.error(err);
    }
  };

  // Add bank code
  const handleAddToQuestionBank = async (id: number, code: string) => {
    if (code) {
      try {
        await addSurveyFormToQuestionBank({
          idSurveyForm: id,
          codeBank: code,
        });
        setIsOpen(false);
        setReloadData(!reloadData);
        message.success("Lưu khảo sát vào ngân hàng đề thành công!");
      } catch (err) {
        console.error(err);
      }
    } else {
      message.error("Vui lòng nhập mã phiếu khảo sát!");
      return Promise.reject(); // Trả về Promise.reject để ngăn không cho hộp thoại đóng
    }
  };

  const isModalOpen = (id: number) => {
    setIdSurveyForm(id);
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOk = () => {
    handleAddToQuestionBank(idSurveyForm, bankCodeValue);
  };

  const resetValueAfterClose = () => {
    setBankCodeValue("");
  };

  const handleAddCodeBank = (e: any) => {
    setBankCodeValue(e.target.value);
  };

  // Preview
  var currentURL = window.location.href;

  const handleNewWindows = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  // useEffect
  useEffect(() => {
    Promise.all([getBankCode(), getSurveyForm(surveyPlan.id)]);
  }, [reloadData]);

  useEffect(() => {
    if (editId && isOpenedEdit) {
      form.setFieldsValue({
        surveyTitle: dataDetail.name,
        surveyCode: dataDetail.code,
        surveyForm: dataDetail.templateBank,
        executionTime: [dayjs(dataDetail.timeStart), dayjs(dataDetail.timeEnd)],
      });
    } else {
      form.setFieldsValue({
        surveyTitle: null,
        surveyCode: null,
        surveyForm: null,
        executionTime: null,
      });
    }
  }, [dataDetail]);

  return (
    <section className="wrapper-settings plan-detail">
      <div className={isSurveyAnswersTable ? 'setting-plan disappear' : 'setting-plan'}>
        <CustomCard className="plan-card" title="Kế hoạch chi tiết">
          <SortableList
            items={listDataSurveyForm}
            onChange={handleDragChange}
            renderItem={(record: any) => (
              <SortableList.Item id={record.id}>
                <SortableList.DragHandle />
                <Form.Item className="plan-item" name="name">
                  <p className="survey-name">{record.name}</p>
                </Form.Item>
                <Flex gap="small" vertical className="survey-form-actions">
                  <Flex gap="small">
                    <Tooltip title="Sao chép đường dẫn">
                      <Button
                        type="default"
                        icon={<LinkOutlined />}
                        onClick={() => handleCopyLink(record.url)}
                      />
                    </Tooltip>
                    <Tooltip title="Trình xem trước">
                      <Button
                        type="default"
                        icon={<PlayCircleOutlined />}
                        onClick={() => {
                          indexDB.set(
                            "current-course",
                            JSON.stringify({
                              name: record.name,
                              isEdit: 1,
                              backUrl: `${location.pathname}`,
                              backBreadCrumb: surveyPlan.name,
                            }),
                            1440
                          );
                          handleNewWindows(
                            `/${routesConfig.courseConstruction}/preview-plan?unitId=${record.id}`
                          );
                        }}
                      />
                    </Tooltip>
                    <Tooltip>
                      <Dropdown
                        menu={{
                          items: itemsMenuDropDown,
                          onClick: ({ key }) => {
                            if (key === "1") {
                              getSurveyFormEdit(record.id);
                              handleOpenEdit(record.id);
                            }
                            if (key === "2") {
                              // setting
                              indexDB.set("current-course", JSON.stringify({
                                name: record.name,
                                parent: surveyPlan.name,
                              }), 1440);
                              navigate(`${routesConfig.addSurvey}?unitId=${record.id}`, { state: { id: record.id } });
                            }
                            if (key === "3") {
                              setIsSurveyAnswersTable(true);
                              setEditId(record.id);
                              setRecordDetail(record);
                            }
                            if (key === "4") {
                              isModalOpen(record.id);
                            }
                            if (key === "5") {
                              if (record.status !== 4) {
                                Modal.confirm({
                                  title: `Xoá phiếu khảo sát`,
                                  content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn chắc chắn muốn xoá phiếu khảo sát ?`,
                                  centered: true,
                                  okText: "Xác nhận",
                                  cancelText: "Huỷ bỏ",
                                  okButtonProps: {
                                    className: "ant-btn-dangerous",
                                  },
                                  icon: (
                                    <WarningOutlined
                                      style={{ color: "#FF4D4F" }}
                                    />
                                  ),
                                  onOk: () => deleteSurvey(record.id),
                                  footer: (_, { OkBtn, CancelBtn }) => (
                                    <>
                                      <CancelBtn />
                                      <OkBtn />
                                    </>
                                  ),
                                });
                              } else {
                                message.warning(
                                  "Không thể xóa khảo sát đã xuất bản."
                                );
                              }
                            }
                          },
                        }}
                        placement="bottomRight"
                      >
                        <Button
                          className="ant-btn-icon-only"
                          type="default"
                        >
                          <MoreOutlined />
                        </Button>
                      </Dropdown>
                    </Tooltip>
                  </Flex>
                </Flex>
              </SortableList.Item>
            )}
          />
        </CustomCard>
        <Button
          className="btn btn-primary btn-add-survey-form"
          onClick={handleAddSurveyForm}
        >
          <PlusOutlined /> Thêm phiếu khảo sát
        </Button>

        {/* Lưu vào ngân hàng đề */}
        <Modal
          className="modal-save-to-bank"
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <SaveOutlined
                style={{ fontSize: 22, marginRight: 15, color: "#1677FF" }}
              />
              Lưu vào ngân hàng đề
            </div>
          }
          open={isOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          afterClose={resetValueAfterClose}
          okText="Xác nhận"
          cancelText="Hủy"
        >
          <p className="code-label">Mã phiếu: </p>
          <Input
            onChange={handleAddCodeBank}
            value={bankCodeValue}
            placeholder="Nhập mã phiếu khảo sát"
          />
        </Modal>

        {/* Thêm, sửa phiếu khảo sát */}
        <DrawerCustom
          title={
            isOpenedEdit ? "Chỉnh sửa phiếu khảo sát" : "Thêm phiếu khảo sát"
          }
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" onClick={() => setIsOpenConfirm(true)}>
                <SaveOutlined />
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateSurveyForm
            onFinish={onFinish}
            isOpened={isOpened}
            form={form}
            dataOptions={dataOptions}
            surveyPlan={surveyPlan}
          />
        </DrawerCustom>
        <CommonModal
          open={isOpenConfirm}
          title={`Thông báo xác nhận lưu phiếu khảo sát`}
          desc={
            <><p>Xin vui lòng xác nhận việc lưu phiếu khảo sát</p>
              <div>
                Đảm bảo rằng tất cả các{" "}
                <span className="text-browse">
                  thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
                </span>
                trước khi lưu phiếu khảo sát
              </div>
            </>
          }
          closeIcon={true}
          okText={"Xác nhận"}
          cancelText={"Huỷ"}
          icon={
            <InfoCircleOutlined
              style={{ color: "#FFC53D", marginRight: "10px" }}
            />
          }
          confirm={handleSaveCreate}
          closeModal={() => setIsOpenConfirm(false)}
          className={""}
        />
      </div>
      <div className={isSurveyAnswersTable ? 'survey-answers-table appear' : 'survey-answers-table'}>
        <TableSurveyAnswers
          isSurveyAnswersTable={isSurveyAnswersTable}
          setIsSurveyAnswersTable={(val) => {
            setEditId(null);
            setIsSurveyAnswersTable(val);
          }}
          idSurvey={editId}
          surveyDetail={recordDetail}
        />
      </div>
    </section>
  );
};

export default PlanDetail;
