import { Button, Col, DatePicker, Form, message, Modal, Radio, RadioChangeEvent, Row, Space, TableColumnsType, TableProps, Tabs, TabsProps, TimePicker, Typography } from "antd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Dayjs } from "dayjs";
import TableData from "../../table-data/TableData";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
import { getPageUnitBank } from "../../../service/library";
import { postCourseSearch } from "../../../service/course-construct";
import { postAddTopicPersonalStorageQuestion, postAddTopicToCourseQuestion } from "../../../service/library/question-list";
import { getAllSectionByCourseId, getSequenceBySection, getUnitBySequence } from "../../../service/content-list-course/contentListCourseApi";

type Props = {
  open: boolean;
  closeModal: () => void;
  dataTable: any
};

export default function AddTopicToCourseModal(props: Props) {
  const [dataSave, setDataSave] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(1)
  const [form] = Form.useForm()

  const [dataOptions, setDataOptions] = useState({
    dataTopic: [],
    dataCourse: [],
    dataSection: [],
    dataSequence: [],
    dataUnit: [],
  });

  const {
    open,
    closeModal,
    dataTable,
  } = props;

  const onOk = async () => {

  };

  const validations = {
    object: {
      required: true,
      message: 'Vui lòng chọn đối tượng'
    },
    topicID: {
      required: value === 1 ? true : false,
      message: "Vui lòng chọn đề"
    },
    courseId: {
      required: value === 2 ? true : false,
      message: "Vui lòng khoá học"
    },
    sectionId: {
      required: value === 2 ? true : false,
      message: "Vui lòng chọn chương"
    },
    sequenceId: {
      required: value === 2 ? true : false,
      message: "Vui lòng chọn bài giảng"
    },
    unitId: {
      required: value === 2 ? true : false,
      message: "Vui lòng chọn học liệu"
    },

  }

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const getDataInfo = async () => {
    const dataUnit = {
      keyword: "",
      page: 1,
      size: 10000,
      access: [],
      topics: [],
      dateFrom: "",
      publicStatus: [],
      type: "unit_quiz",
    }
    const dataCourse = {
      keyword: "",
      pageNumber: 1,
      pageSize: 10000,
    }
    const response = await Promise.all([getPageUnitBank(dataUnit), postCourseSearch(dataCourse)])
    const arrUnit = response?.[0]?.data?.data?.content?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.question
      }
    })
    const arrCourse = response?.[1]?.data?.data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name
      }
    })
    setDataOptions({
      ...dataOptions,
      dataTopic: arrUnit,
      dataCourse: arrCourse,
    })
  };

  const resetForm = () => {
    setValue(1)
    form.resetFields()
    closeModal()
  }

  const getSection = async (id: string) => {
    await getAllSectionByCourseId(id).then((res) => {
      if (res.status === 200) {
        setDataOptions({
          ...dataOptions,
          dataSection: res?.data,
        })
      }
    })
  }

  const getSequence = async (id: string) => {
    await getSequenceBySection(id).then((res) => {
      if (res.status === 200) {
        setDataOptions({
          ...dataOptions,
          dataSequence: res?.data,
        })
      }
    })
  }

  const getUnit = async (id: string) => {
    await getUnitBySequence(id).then((res) => {
      if (res.status === 200) {
        setDataOptions({
          ...dataOptions,
          dataUnit: res?.data.data.map((item: any) => {
            return {
              id: item?.id,
              name: item?.name
            }
          }),
        })
      }
    })
  }

  const savePersonalTopic = async (values: any) => {
    const listIdChoose = dataSave.map((item: any) => item.id)
    const data = {
      id: value === 1 ? values.topicID : values.unitId,
      quizIds: listIdChoose
    }
    if(value === 1) {

      await postAddTopicPersonalStorageQuestion(data).then((res) => {
        if (res.status === 200) {
          message.success("Thêm đề thành công vào khoá học thành công")
        }
      })
    } else {
      await postAddTopicToCourseQuestion(data).then((res) => {
        if (res.status === 200) {
          message.success("Thêm đề thành công vào khoá học thành công")
        }
      })
      resetForm()
    }
  }

  useEffect(() => {
    if (open) {
      getDataInfo()
      form.setFieldValue("object", 1)
    }
  }, [open])

  useEffect(() => {
    setDataSave(dataTable)
  }, [dataTable])

  return (
    <Form.Provider onFormFinish={(name, { values, forms }) => {
      if (name === "form-add-topic-to-course") {
        if (value) {
          savePersonalTopic(values)
        }
      }
    }}>
      <Modal
        className="add-topic-to-course-modal"
        title={`Thêm đề vào khoá học`}
        closeIcon={true}
        centered
        open={open}
        onOk={onOk}
        onCancel={() => {
          closeModal()
          form.resetFields()
        }}
        footer={[
          <Button onClick={() => {
            closeModal()
            form.resetFields()
          }}>
            <CloseOutlined />
            Huỷ
          </Button>,
          <Button type="primary" onClick={() => form.submit()}>
            Thêm
          </Button>,
        ]}
      >
        <Form
          className="add-topic-to-course"
          layout={"vertical"}
          form={form}
          name="form-add-topic-to-course"
        >
          <Row gutter={16} className="mt-2">
            <Col
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="object" rules={[validations.object]} label="Đối tượng">
                <Radio.Group name="radiogroup" value={value} defaultValue={1} onChange={onChange}>
                  <Radio value={1}>Đề cá nhân</Radio>
                  <Radio value={2}>Khoá học</Radio>
                </Radio.Group>
              </Form.Item>
              {
                value === 1 ?
                  <>
                    <Form.Item name="topicID" label="Chọn đề" rules={[validations.topicID]}>
                      <FormItemDropdown placeholder="Chọn đề"
                        options={dataOptions.dataTopic} />
                    </Form.Item>
                  </>
                  :
                  <>
                    <Form.Item name="courseId" label="Chọn khoá học" rules={[validations.courseId]}>
                      <FormItemDropdown placeholder="Chọn khoá học" onChange={() => getSection(form.getFieldValue("courseId"))}
                        options={dataOptions.dataCourse} />
                    </Form.Item>
                    <Form.Item name="sectionId" label="Chọn Chương" rules={[validations.sectionId]}>
                      <FormItemDropdown placeholder="Chọn Chương" onChange={() => getSequence(form.getFieldValue("sectionId"))}
                        options={dataOptions.dataSection} />
                    </Form.Item>
                    <Form.Item name="sequenceId" label="Chọn Bài giảng" rules={[validations.sequenceId]}>
                      <FormItemDropdown placeholder="Chọn Bài giảng" onChange={() => getUnit(form.getFieldValue("sequenceId"))}
                        options={dataOptions.dataSequence} />
                    </Form.Item>
                    <Form.Item name="unitId" label="Chọn Học liệu" rules={[validations.unitId]}>
                      <FormItemDropdown placeholder="Chọn học liệu"
                        options={dataOptions.dataUnit} />
                    </Form.Item>
                  </>
              }
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider >
  );
}
