import { Layout, Row } from 'antd';
import './survey-answers.scss';
import CustomCard from '../../../../../custom-card/CustomCard';
import SurveyAnswersInfo from './survey-answers-info/survey-answers-info';
import SurveyAnswersList from './survey-answers-list/survey-answers-list';
import SurveyAnswersContent from './survey-answers-content/survey-answers-content';
import { useEffect, useState } from 'react';
const { Content } = Layout;

const SurveyAnswers = () => {
  const [question, setQuestion] = useState<any>();

  useEffect(() => {

  }, []);
  return <Content className='add-lesson'>
    <div className="editor-course editor-course-survey">
      <SurveyAnswersList handelTranferIdQuestion={(question) => { setQuestion(question); }} />
      <CustomCard className="card-info--wrapper flex-1">
        <div className="list-wrapper">
          <SurveyAnswersContent question={question} />
        </div>
      </CustomCard>
      <SurveyAnswersInfo />
    </div>
  </Content>
}

export default SurveyAnswers;