import React from 'react'

type Props = {
  content: string
}

const TextViewer = (props: Props) => {
  const { content } = props
  return (
    <div className="mt-5" dangerouslySetInnerHTML={{ __html: content }}></div>
  )
}

export default TextViewer