import { Col, Form, MenuProps, Row, Spin, Switch, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { get, isEmpty } from "lodash";
import { regexEmailV2, phoneNumberRegex, regexEmail } from "../../../../../utils/regex";

const validations = {
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên người dùng không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên người dùng"))
			}
			return Promise.resolve()
		}
	},
	organization: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn đơn vị"))
			}
			return Promise.resolve()
		}
	},
	gender: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn giới tính"))
			}
			return Promise.resolve()
		}
	},
	phone: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!isEmpty(value) && !value.match(new RegExp(phoneNumberRegex))) {
				return Promise.reject(new Error("Sai định dạng, vui lòng nhập lại"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập số điện thoại"))
			}
			return Promise.resolve()
		}
	},
	email: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
				return Promise.reject(new Error("Sai định dạng, vui lòng nhập lại"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập địa chỉ email"))
			}
			return Promise.resolve()
		}
	},
}

type FormCreateLecturerProps = {
	form: FormInstance,
	onFinish?: any,
	dataOptions?: any,
	loadingForm: boolean,
}

const gendersOptions = [
	{
		id: "Nam",
		name: "Nam",
	},
	{
		id: "Nữ",
		name: "Nữ",
	},
	{
		id: "Khác",
		name: "Khác",
	},
];

const FormCreateLecturer: FunctionComponent<FormCreateLecturerProps> = ({ form, onFinish, dataOptions, loadingForm }) => {
	return (
		<>
			<Form layout={"vertical"} form={form} onFinish={onFinish} name="createUser" initialValues={{ status: true }}>
				{loadingForm ?
					<div className="statistic-course-content statistic-course-loading">
						<Spin size="large" />
					</div>
					:
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item name="name" rules={[validations.name]} label="Tên người dùng">
								<FormInput placeholder="Nhập tên người dùng" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="organization" rules={[validations.organization]} label="Đơn vị">
								<FormItemDropdown options={get(dataOptions, 'dataOrganization', '')} allowClear={true} placeholder="Chọn đơn vị" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="regency" label="Chức vụ">
								<FormItemDropdown options={get(dataOptions, 'dataRegency', '')} allowClear={true} placeholder="Chọn chức vụ" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="academicLevel" label="Trình độ học vấn">
								<FormItemDropdown options={get(dataOptions, 'dataAcademicLevel', '')} allowClear={true} placeholder="Chọn trình độ học vấn" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="gender" rules={[validations.gender]} label="Giới tính">
								<FormItemDropdown options={gendersOptions} placeholder="Chọn giới tính" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="domicile" label="Nguyên quán">
								<FormItemDropdown options={get(dataOptions, 'dataProvince', '')} allowClear={true} placeholder="Chọn nguyên quán" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="phone" rules={[validations.phone]} label="Điện thoại liên hệ">
								<FormInput placeholder="Nhập số điện thoại" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="email" rules={[validations.email]} label="Địa chỉ Email">
								<FormInput placeholder="Nhập địa chỉ email" />
							</Form.Item>
						</Col>
						<Col span={24} style={{ display: "flex", justifyContent: "space-between" }}>
							<span>Trạng thái</span>
							<Form.Item name="status">
								<Switch defaultChecked />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
		</>
	)
}

export default FormCreateLecturer;