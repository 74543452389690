// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-submit-review .container-form {
  margin-bottom: 14px; }
  .form-submit-review .container-form .container-form-title {
    margin-bottom: 14px; }
  .form-submit-review .container-form .ant-picker {
    margin-right: 8px; }
  .form-submit-review .container-form .time-input {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 10px; }
  .form-submit-review .container-form .ant-radio {
    align-self: start;
    margin-top: 3px; }

.submit-review-loading {
  justify-content: center; }
  .submit-review-loading .ant-spin {
    margin-top: 30vh; }
`, "",{"version":3,"sources":["webpack://./src/components/course-library/personal-exam-repository/list-exam/submit-review/submitReview.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAmB,EAAA;EAFvB;IAKM,mBAAmB,EAAA;EALzB;IASM,iBAAiB,EAAA;EATvB;IAaM,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,gBAAgB,EAAA;EAhBtB;IAmBM,iBAAiB;IACjB,eAAe,EAAA;;AAKrB;EACE,uBAAuB,EAAA;EADzB;IAII,gBAAgB,EAAA","sourcesContent":[".form-submit-review {\n  .container-form {\n    margin-bottom: 14px;\n\n    .container-form-title {\n      margin-bottom: 14px;\n    }\n\n    .ant-picker {\n      margin-right: 8px;\n    }\n\n    .time-input {\n      display: flex;\n      gap: 16px;\n      align-items: center;\n      margin-top: 10px;\n    }\n    .ant-radio {\n      align-self: start;\n      margin-top: 3px;\n    }\n  }\n}\n\n.submit-review-loading {\n  justify-content: center;\n\n  .ant-spin {\n    margin-top: 30vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
