import { useEffect } from 'react';
import './survey-answers-content.scss';
import { getDetailSurveyAnswer } from '../../../../../../../service/course-construct/survey-plan';
import { Radio, Typography } from 'antd';
import { convertQuillToString } from '../../../../../../../utils/convertQuillToString';
import { QuestionTypeEnum } from '../../../../../../../constants';
import ChooseText from './ChooseText';
import ChooseImage from './ChooseImage';
import ChooseVideo from './ChooseVideo';
import TrueOrFalse from './TrueOrFalse';
import DropDown from './DropDown';
import ChooseMultiText from './ChooseMultiText';
import ChooseMultiImage from './ChooseMultiImage';
import ChooseMultiVideo from './ChooseMultiVideo';
import Connect from './Connect';
import ShortText from './ShortText';
import FileUpload from './FileUpload';
import Video from './Video';
import Mp3 from './Mp3';
type SurveyAnswersContentProps = {
  question: any;
};

export type AnswersPros = {
  question: any;
}

const SurveyAnswersContent = (props: SurveyAnswersContentProps) => {
  const { question } = props;

  const renderAnswersByType = () => {
    if (question) {
      switch (question.type) {
        case QuestionTypeEnum.CHOOSE_TEXT:
          return <ChooseText question={question} />;
        case QuestionTypeEnum.CHOOSE_IMAGE:
          return <ChooseImage question={question} />;
        case QuestionTypeEnum.CHOOSE_VIDEO:
          return <ChooseVideo question={question} />;
        case QuestionTypeEnum.TRUE_OR_FALSE:
          return <TrueOrFalse question={question} />;
        case QuestionTypeEnum.DROP_DOWN:
          return <DropDown question={question} />;
        case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
          return <ChooseMultiText question={question} />;
        case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
          return <ChooseMultiImage question={question} />;
        case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
          return <ChooseMultiVideo question={question} />;
        case QuestionTypeEnum.CONNECT:
          return <Connect question={question} />;
        case QuestionTypeEnum.SHORT_TEXT:
          return <ShortText question={question} />;
        case QuestionTypeEnum.FILE_UPLOAD:
          return <FileUpload question={question} />;
        case QuestionTypeEnum.VIDEO:
          return <Video question={question} />;
        case QuestionTypeEnum.MP3:
          return <Mp3 question={question} />;
        default:
          return <section></section>;
      }
    }
  };

  return <section className='survey-answers-content'>{renderAnswersByType()}</section>
}

export default SurveyAnswersContent;