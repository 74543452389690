import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import SortableItem from "./SortableItem";
import "./style.scss";
import { Formula } from ".";

interface ContainerProps {
  id: string;
  items: Formula[];
  className?: string;
  description?: string;
  itemClassname?: string;
}

export default function Container(props: ContainerProps) {
  const { id, items, className, description, itemClassname } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  const containerStyle: React.CSSProperties = {
    background: "#f0f0f0",
    padding: 10,
    minHeight: 100,
    display: items.length > 0 ? "flex" : "block",
    flexWrap: "wrap",
    alignItems: "center",
    borderRadius: 8,
  };

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <div
        ref={setNodeRef}
        className={`sort-container ${className}`}
        style={containerStyle}
      >
        {items.length === 0 && description ? (
          <div
            style={{
              padding: 10,
              textAlign: "center",
              color: "#aaa",
              border: "2px dashed #ccc",
              minHeight: "200px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {description}
          </div>
        ) : (
          items.map((item: Formula) => (
            <SortableItem
              className={itemClassname}
              key={item.id}
              id={item.id}
              label={item.label}
            />
          ))
        )}
      </div>
    </SortableContext>
  );
}
