import { FunctionComponent, useEffect, useState } from "react";
import "./index.scss";
import DetailInfo from "../../detail/components/detail-info/DetailInfo";
import { Button, Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import { useParams } from "react-router-dom";
import {
  HistoryOutlined,
  LockOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
// import { DetailContent } from "../components/detail-content/DetailContent";
import UserDocument from "../../detail/components/user-document/UserDocument";
import { useAppStore } from "../../../stores/stores";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import GeneralIntroduction from "../general-introduction";
import ListProgramPage from "../list-program";
import AddListProgramDrawer from "../../../components/drawer/add-list-program";
import CourseList from "../course-list";
import CourseListPage from "../page-course-list";
import { CheckRole, getPermissions } from "../../../utils/checkRole";
import { Permission } from "../../../layout/sidebar/permission.enum";

const { Content } = Layout;
const imgPath = "/images/";

const DetailPortalAdministration: FunctionComponent = () => {
  const location = useLocation();
  const [tab, setTab] = useState("1");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [listPermission, setListPermission] = useState([]);
  const [displayRole, setDisplayRole] = useState(false);
  const [displayRoleListCoursePage, setDisplayRoleListCoursePage] = useState(false);
  const [displayRoleListCourseProgramPage, setDisplayRoleListCourseProgramPage] = useState(false);
  const params = useParams();

  const getDetailCourseData = async () => { };

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params))
    setListPermission(listPermissionConvert)
  }

  useEffect(() => {
    getDetailCourseData();
    getRoles()
  }, []);

  useEffect(() => {
    const roleIntroductionPage = [
      Permission.PortalManagementIntroductionPageView,
      Permission.PortalManagementIntroductionPageUpdate,
    ]
    const roleListCoursePage = [
      Permission.PortalManagementCourseListPageView,
      Permission.PortalManagementCourseListPageUpdate,
    ]

    const roleListCourseProgramPage = [
      Permission.PortalManagementProgramListPageView,
      Permission.PortalManagementProgramListPageUpdate,
    ]

    const checkRole = CheckRole(roleIntroductionPage, listPermission)
    const checkRoleIntroductionPage = CheckRole(roleListCoursePage, listPermission)
    const checkRoleListCourseProgramPage = CheckRole(roleListCourseProgramPage, listPermission)

    if (checkRole.includes(true)) {
      setDisplayRole(true)
      setTab("1")
    } else {
      setTab("4")
    }
    
    if (checkRoleIntroductionPage.includes(true)) {
      setDisplayRoleListCoursePage(true)
    }
    if (checkRoleListCourseProgramPage.includes(true)) {
      setDisplayRoleListCourseProgramPage(true)
    }

  }, [listPermission]);

  const items: TabsProps["items"] = [
    ...(displayRole ? [
      {
        key: "1",
        // icon: <UserOutlined />,
        label: "Giới thiệu chung",
        children: (
          <div className="">
            <section className="detail-account detail-course">
              <div className="form-detail">
                <GeneralIntroduction setTab={setTab} tab={tab} universityName={location.state.name} logo={location.state.logo} />
              </div>
            </section>
          </div>
        ),
      },
    ] : []),
    ...(displayRoleListCoursePage ? [
      {
        key: "2",
        // icon: <HistoryOutlined />,
        label: "Trang danh sách khoá học",
        children: (
          <div className="">
            <section className="detail-account detail-course">
              <div className="form-detail">
                <CourseList setTab={setTab} tab={tab} />
              </div>
            </section>
          </div>
        ),
      },
    ] : []),
    ...(displayRoleListCourseProgramPage ? [
      {
        key: "3",
        // icon: <LockOutlined />,
        label: "Trang danh sách chương trình học",
        children: (
          <div className="">
            <section className="detail-account detail-course">
              <div className="form-detail">
                <CourseListPage setTab={setTab} tab={tab} />
              </div>
            </section>
          </div>
        ),
      },
    ] : []),
    {
      key: "4",
      // icon: <LockOutlined />,
      label: "Danh sách chương trình học",
      children: (
        <div className="list-program">
          <section className="detail-account">
            <div className="form-detail">
              <ListProgramPage refreshData={refreshData} />
            </div>
          </section>
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: (
        <a href={`/${routesConfig.portalAdministration}`}>
          Quản trị cổng thông tin
        </a>
      ),
    },
    {
      title: location.state.name,
    },
  ];

  return (
    <>
      <Content className="detail-portal-administration">
        <PageHeader
          title={location.state.name}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={tab === "4"}
          childrenGroupButton={
            <div className="button-container">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsOpen(true)}
              >
                Thêm chương trình học
              </Button>
            </div>
          }
        />
        <div className="tabs-heading">
          <Tabs items={items} activeKey={tab} onChange={setTab} />
        </div>
      </Content>
      <AddListProgramDrawer
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        refreshData={setRefreshData}
      />
    </>
  );
};

export default DetailPortalAdministration;
