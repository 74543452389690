import {AxiosResponse} from "axios";
import {axiosConfigV2} from "../../../config/api/configApiv2";

export const getDataSurveyPlan: (dataReq: Partial<object>) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post('/survey-plan/search-survey-plan', dataReq)
}

export const getTargetSurveyLookup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get('/survey-plan/target-survey-lookup')
}

export const getObjectSurveyLookup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get('/survey-plan/object-survey-lookup')
}

export const createSurveyPlan: (dataReq: Partial<object>) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post('/survey-plan', dataReq)
}

export const updateSurveyPlan: (id: number, dataReq: Partial<object>) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfigV2.put(`/survey-plan/${id}`, dataReq)
}

export const deleteSurveyPlan: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/survey-plan/${id}`)
}

export const getSurveyPlan: (id: number) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfigV2.get(`/survey-plan/get-survey-plan/${id}`)
}

export const configSurveyActive: (dataReq: Partial<object>) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/survey-plan/survey-plan-config`, dataReq)
}

export const createDuplicateSurveyPlan: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.post(`/survey-plan/duplicate-survey-plan/${id}`)
}

export const getSurveyPlanById: (id?: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/survey-plan/get-survey-plan/${id}`)
}


export const getQuizSurvey: (dataReq: object) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/survey-plan/search-quiz-by-survey-plan`, dataReq)
}

export const getAllObjectSurveyLookup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/survey-plan/object-survey-lookup`)
}

export const getAllSurveyForm: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/survey-plan/get-list-survey-form/${id}`)
}

export const getSurveyPlanHistoryById: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.post(`/survey-plan/survey-plan-history/${id}`, data)
}

export const getDataSurveyActionHistory: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get('/survey-plan/action-history-lookup')
}

export const getDataDetailConfig: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/survey-plan/get-survey-plan-config/${id}`)
}
export const getDataSurveyUserHistory: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/survey-plan/user-history-lookup/${id}`)
}

export const addSurveyFormToQuestionBank: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-plan/add-survey-form-to-question-bank`, data)
}

export const createSurveyForm: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-plan/create-survey-form`, data)
}

export const updateOrderNumberSurveyForm: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-plan/update-order-number-survey-form`, data)
}

export const getDataLayoutSurvey: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/survey-plan/layout-survey-plan-lookup`)
}

export const updateSurveyForm: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/survey-plan/update-survey-form`, data)
}

export const getSurveyFormById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/survey-plan/get-survey-form/${id}`)
}

export const getListBankCode: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/survey-plan/list-bank-code-lookup`)
}

export const deleteSurveyFormById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/survey-plan/delete-survey-form/${id}`)
}

export const duplicateSurveyFormById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.post(`/survey-plan/delete-survey-form/${id}`)
}

export const listSurveyAnswers: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-plan/search-survey-plan-exam-user`, data)
}

export const getDetailSurveyAnswer: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/survey-plan/get-survey-plan-exam-user-detail/${id}`)
}

export const getChartSurveyAnswers: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-plan/chart-answer-by-quiz-survey`, data)
}

export const getDetailUserSurveyAnswer: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/survey-plan/info-survey-plan-by-user/${id}`)
}