import {Col, Image, Modal, Row, Typography} from "antd";
import {ReactNode, useEffect, useState} from "react";
import "./index.scss";
import {useAddLesson} from "../../../stores/stores";
import {
  ListTypeQuestion,
  ModeQuestion,
  QuestionProgressModeClick,
  QuestionTypeEnum,
  TypeCreateQuestion
} from "../../../constants";
import {renderQuestionNameByType} from "../../../utils/functionUltils";
import {useDirty} from "../../../contexts/DirtyProvider";
import {getIdIndexDB, saveToIndexDB} from "../../../pages/page-builder/grapesjs-service";
import {
  createMoocCourseBlockQuiz,
  createMoocCourseBlockQuizConnect
} from "../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz";
import {HelpService} from "../../../service/helper.service";

type Props = {
  open: boolean;
  closeIcon: boolean;
  title: string;
  okText: string;
  cancelText: string;
  courseBlockGroupIdClick: number;
  icon: ReactNode;
  closeModal: (value: boolean) => void;
  onSelectQuestionType?: (type: number) => void;
};

export default function AddQuestionCourseModal(props: Props) {
  const {
    open,
    title,
    closeIcon,
    okText,
    cancelText,
    courseBlockGroupIdClick,
    closeModal,
    icon,
    onSelectQuestionType
  } = props;
  const {Text, Link} = Typography;
  const setTypeQuestionChoose = useAddLesson((state) => state.setTypeQuestionChoose);
  const setQuestion = useAddLesson((state) => state.setQuestion);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const setModeClick = useAddLesson((state) => state.setModeClick);
  const setQuestionSettingsBe = useAddLesson((state) => state.setQuestionSettingsBe);
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);
  const setCurrentIdQuestionAdd = useAddLesson((state) => state.setCurrentIdQuestionAdd);
  const onRefreshQuizBlock = useAddLesson((state) => state.onRefreshQuizBlock);
  const reviewList = useAddLesson((state) => state.reviewList);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const question = useAddLesson((state) => state.question);

  const [activeType, setActiveType] = useState(0);
  const [currentParentCourse, setCurrentParentCourse] = useState<string>();
  const [currentCourse, setCurrentCourse] = useState<any>();
  const [dataListTypeQuestion, setDataListTypeQuestion] = useState<any>(ListTypeQuestion);

  const helpService = new HelpService();
  const {setDirty} = useDirty();

  useEffect(() => {
    getIdIndexDB('current-course', (val: any) => {
      setCurrentParentCourse(val.parent);
      setCurrentCourse({id: val.id, name: val.name});
    });
  }, []);

  useEffect(() => {
    setCurrentCourse({id: reviewList.id, name: reviewList.name});
  }, [reviewList]);

  useEffect(() => {
    setActiveType(0);
  }, [open]);

  useEffect(() => {
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      const newDataChooseList = dataListTypeQuestion.filter((x: any) => {
        return x.type !== 4
      });
      setDataListTypeQuestion(newDataChooseList);
    } else {
      setDataListTypeQuestion(ListTypeQuestion);
    }
  }, [typeCreateQuestion]);

  const confirmCreateQuestion = (type: number) => {
    setActiveType(type);
    onSelectQuestionType?.(type);
    setQuestion(
      new Object({
        type: type,
        question: renderQuestionNameByType(type),
        courseBlockGroupId: courseBlockGroupIdClick,
      })
    );
    setTypeQuestionChoose(type);
    setModeQuestion(ModeQuestion.CREATE);
    setQuestionSettingsBe({});
    setCurrentQuestionInfo({});
    setModeClick(QuestionProgressModeClick.INPROGRESS);
    closeModal(true);
    setDirty(true);
    let createAnswers: any[] = [];
    if (type === QuestionTypeEnum.TRUE_OR_FALSE) {
      createAnswers = [
        {content: "Đúng", orderNumber: 1, isTrue: false},
        {content: "Sai", orderNumber: 2, isTrue: false}
      ]
    }
    let payload;
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      payload = {
        answers: createAnswers,
        courseBlockGroupId: courseBlockGroupIdClick,
        orderNumber: 1,
        question: renderQuestionNameByType(type),
        questionValue: renderQuestionNameByType(type),
        type: type,
        settingGeneral: {
          displayScoreCriteria: true,
          fileCapacity: 0,
          fileQuantity: 0,
          haveCorrectAnswer: true,
          isAutoMark: true,
          numberingType: 1,
          requiredAnswer: true,
          showForViewer: true,
          shuffleQuestion: true,
          timeToPlay: 0,
        },
        settingHint: {
          content: '',
          isDisplayInstruction: false,
          schema: '',
          uri: 'https://',
        },
        settingPoint: {
          correct: 0,
          incorrect: 0,
          isPerQuiz: false,
        },
        settingResponse: {
          correct: '',
          incorrect: '',
          isDisplayPerResponse: false,
          notYet: '',
        },
      };
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      payload = {
        orderNumber: 1,
        courseBlockGroupId: courseBlockGroupIdClick,
        type: type,
        question: renderQuestionNameByType(type),
        answers: createAnswers,
        settingGeneral: {
          showForViewer: true,
          requiredAnswer: true,
          numberingType: 1,
          fileCapacity: 0,
          fileQuantity: 0,
        },
      };
    }
    if (payload) {
      createQuiz(payload);
    }
  };

  const createQuiz = async (dataReq: any) => {
    try {
      let apiRespone: any;
      if (dataReq && dataReq.type && dataReq.type === QuestionTypeEnum.CONNECT) {
        apiRespone = await createMoocCourseBlockQuizConnect(dataReq);
      } else {
        apiRespone = await createMoocCourseBlockQuiz(dataReq);
      }
      const dataRespone = apiRespone.data;
      setModeClick(QuestionProgressModeClick.END);
      setCurrentIdQuestionAdd(dataRespone.body.id);
      saveToIndexDB('current-course', {
        currentId: dataReq.id,
        id: dataReq.id,
        name: currentCourse.name,
        parent: currentParentCourse,
      });
      onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Tạo mới thất bại");
      console.error(err);
    } finally {
    }
  };
  return (
    <Modal
      className="add-type-question-modal"
      title={
        <>
          {icon} <Typography.Title level={5}>{title}</Typography.Title>
        </>
      }
      closeIcon={closeIcon}
      centered
      open={open}
      okText={okText}
      cancelText={cancelText}
      onCancel={() => closeModal(false)}
      footer={null}
      width={"50vw"}
    >
      <section className="list-type-question">
        {dataListTypeQuestion.map((item: any, i: number) => (
          <div className="item-type" key={i + 1}>
            <div className="item-type__title">
              <Typography.Title level={5}>{item.title}</Typography.Title>
            </div>
            <div className="item-type__list">
              <Row gutter={16}>
                {item.childs.map((itemChild: any, iChild: number) => (
                  <Col span={6} key={iChild + 1}>
                    <div
                      className={`item-type__list__item ${activeType === itemChild.type ? "type-active" : ""} ${itemChild?.disable ? "item-disable" : ""}`}
                      onClick={() => confirmCreateQuestion(itemChild.type)}
                    >
                      <Typography.Paragraph className="title">
                        {itemChild.title}
                      </Typography.Paragraph>
                      <Image
                        preview={false}
                        src={itemChild.image}
                        className="icon"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      </section>
    </Modal>
  );
}
