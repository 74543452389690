import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getLearningMaterialList: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/unit-library/search`, payload);
};

export const getLearningMaterialDetail: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/unit-library/detail/${id}`);
};

export const createLearningMaterial: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/unit-library/create`, payload);
};

export const updateLearningMaterial: (
  id: number,
  payload: any
) => Promise<AxiosResponse<any>> = (id, payload) => {
  return axiosConfigV2.put(`/unit-library/update/${id}`, payload);
};

export const deleteLearningMaterial: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/unit-library/delete/${id}`);
};

export const shareLearningMaterial: (
  id: number,
  type: boolean
) => Promise<AxiosResponse<any>> = (id, type) => {
  return axiosConfigV2.post(
    `/unit-library/send-approve-unit-library/${id}/${type}`
  );
};

export const getLearningMaterialHistory: (
  id: number,
  payload: any
) => Promise<AxiosResponse<any>> = (id, payload) => {
  return axiosConfigV2.post(
    `/unit-library/unit-library-history/${id}`,
    payload
  );
};
export const approveLearningMaterial: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/unit-library/approve-unit-library`, payload);
};

export const revokeApproval: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/unit-library/revoke`, payload);
};

export const approveAllLearningMaterial: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/unit-library/approved-all`, payload);
};

export const saveLearningTemplate: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/unit-library/save-unit-library-from-block`, data);
};

export const getLearningMaterialFromLibrary: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/unit-library/get-library-to-add-unit`, payload);
};

export const getEnterpriseShare: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/unit-library/get-approver-enterprise`);
};

export const getSharerList: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/unit-library/get-approvers`);
};
