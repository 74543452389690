// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learning-material-history-modal {
  max-height: 68vh;
  min-height: 68vh;
  height: 68vh;
  min-width: 848px; }
  .learning-material-history-modal .ant-modal-title {
    padding: 24px; }
  .learning-material-history-modal .ant-modal-content {
    padding: 0px; }
  .learning-material-history-modal .table-wrapper {
    padding: 0px 0px 24px 24px; }
  .learning-material-history-modal .ant-modal-content {
    max-height: 68vh;
    min-height: 68vh;
    height: 68vh; }
`, "",{"version":3,"sources":["webpack://./src/pages/learning-materials/components/history-modal/HistoryModal.scss"],"names":[],"mappings":"AAAA;EACC,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB,EAAA;EAJjB;IAOE,aAAa,EAAA;EAPf;IAWE,YAAY,EAAA;EAXd;IAeE,0BAA0B,EAAA;EAf5B;IAmBE,gBAAgB;IAChB,gBAAgB;IAChB,YAAY,EAAA","sourcesContent":[".learning-material-history-modal {\n\tmax-height: 68vh;\n\tmin-height: 68vh;\n\theight: 68vh;\n\tmin-width: 848px;\n\n\t.ant-modal-title {\n\t\tpadding: 24px;\n\t}\n\n\t.ant-modal-content {\n\t\tpadding: 0px;\n\t}\n\n\t.table-wrapper {\n\t\tpadding: 0px 0px 24px 24px;\n\t}\n\n\t.ant-modal-content {\n\t\tmax-height: 68vh;\n\t\tmin-height: 68vh;\n\t\theight: 68vh;\n\t}\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
