import React, { useEffect, useRef, useState } from "react";
import { Layout } from "antd";
import "./styles.scss";
import PageHeader from "../page-header/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import QuizExamScreen from "./components";
import { getDetailQuestionBank, getDetailQuestionBankMinistry, getQuestionBankDetailHistory, getQuestionDetailHistory } from "../../service/question-bank";
export interface LocationState {
  message: string;
  backUrl: string;
  mode: string;
  titleBreadCrumb: string;
}

function DetailExam() {
  const backAnchorRef = useRef<HTMLAnchorElement>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get("id");
  const [questionId, setQuestionId] = useState("")
  const [title, setTitle] = useState("");
  const [backBreadCrumb, setBackBreadCrumb] = useState(
    "Quay lại"
  );
  const [idCourse, setIdCourse] = useState("");
  const { state } = location as { state: LocationState };

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(state?.backUrl, {
      state: {
        id: unitId,
      },
    });
  };

  const { Content } = Layout;
  const imgPath = "/images/";

  const listBreadcrumb = [
    {
      title: (
        <div className="cursor-pointer" onClick={navigateBack}>
          {state?.titleBreadCrumb ? state?.titleBreadCrumb  : backBreadCrumb}
        </div>
      ),
    },
    {
      title: title || "Không có dữ liệu",
    },
  ];

  const handleGetDetail = async () => {
    try {
      await getDetailQuestionBank(Number(unitId)).then((result) => {
        if (result.data) {
          setTitle(result?.data.data.title);
          setIdCourse(result?.data.data.unitId);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailMinistryContent = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setTitle(result?.data.data.title);
          setIdCourse(result?.data.data.moocCourseUnitId);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailUniversityContent = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setTitle(result?.data.data.title);
          setIdCourse(result?.data.data.moocCourseUnitId);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailQuestion = async () => {
    try {
      await getDetailQuestionBankMinistry(Number(unitId)).then((result) => {
        if (result.data) {
          setTitle(result?.data.data.code);
          // setIdCourse(result?.data.data.unitId);
          // setQuestionId(result?.data.data.id)
          setQuestionId(result?.data?.data?.moocCourseBlockQuizId)
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleGetDetailHistoryQuestion = async () => {
    try {
      await getQuestionDetailHistory(Number(unitId)).then((result) => {
        if (result.data) {
          setTitle(result?.data.data.code);
          setQuestionId(result?.data?.data?.quizId)
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDetailHistoryQuestionBank = async () => {
    try {
      await getQuestionBankDetailHistory(Number(unitId)).then((result) => {
        if (result.data) {
          setTitle(result?.data.data.title);
          setIdCourse(result?.data?.data?.unitId)
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(state?.mode === "0" ||state?.mode === "4" ) handleGetDetail();
    else if(state?.mode === "1") handleGetDetailMinistryContent();
    else if(state?.mode === "2") handleGetDetailUniversityContent();
    else if(state?.mode === "5" || state?.mode ==="6") handleGetDetailQuestion();
    else if (state?.mode === "7") handleGetDetailHistoryQuestion()
    else if (state?.mode === "8") handleGetDetailHistoryQuestionBank()
  }, []);

  return (
    <Content>
      <PageHeader
        title={title || "Không có dữ liệu"}
        listBreadcrumb={listBreadcrumb}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        headerGroupButton={true}
      />

      <div className="exam-container">
        <div className="exam-layout">
          <QuizExamScreen unitId={idCourse} questionId={questionId}/>
        </div>
      </div>
    </Content>
  );
}

export default DetailExam;
