import {isEmpty} from "lodash";
import {QuestionTypeEnum} from "../constants";
import {BeQuizAnswer, ConnectAnswer, QuestionAnswerInfo,} from "../types/course-construction";
import {QuizRequest} from "../types/course";
import {Draft} from "../types/scoring-course";

export const convertStringToArrayNum = (str?: string | any) => {
  if (str && str.length > 0) {
    return str.split(", ").map((str: any) => Number(str.trim()));
  }
  return str;
};

export const convertArrayToString = (arr?: Array<string> | Array<any>) => {
  if (arr) {
    return arr
      .map((item) => {
        return item.name;
      })
      .join(", ");
  }
  return arr;
};

export const convertArrNumToString = (arr?: Array<number> | Array<any>) => {
  if (arr && isArray(arr)) {
    return arr
      .map((item) => {
        return item.toString();
      })
      .join(", ");
  }
  return "";
};

export const convertArrayStringName = (
  arr: Array<{ id: number; code: string; name: string } | undefined | any>
) => {
  if (arr) {
    return arr.map((item) => item.name);
  }
  return arr;
};

export const convertArrayToNumber = (
  arr: Array<{ name: string; id: number } | undefined | any>
) => {
  if (arr) {
    return arr.map((item) => item?.id);
  }
  return [];
};

export const isArray = (input: any) => {
  return input instanceof Array;
};

export const findObjNameById = (
  arrStr: [{ id: number; name: string }],
  id?: string | number | undefined
) => {
  if (arrStr.length > 0) {
    return arrStr.find((item: any) => item.id === id)?.name;
  }
  return [];
};

export const findObjName = (arrStr?: any, id?: string | number | undefined) => {
  if (arrStr.length > 0) {
    return arrStr.find((item: any) => item.id === id);
  }
  return [];
};

export const convertCodeToName = (dataArray: any, dataCode: any) => {
  if (dataArray?.length > 0 && dataCode?.length > 0) {
    return dataArray.reduce((acc: any[], item: { code: any; name: any }) => {
      if (dataCode.includes(item.code)) {
        acc.push(item.name);
      }
      return acc;
    }, []);
  }
  return [];
};

export const convertNameToCode = (dataArray: any, dataCode: any) => {
  if (dataArray?.length > 0 && dataCode?.length > 0) {
    return dataArray.reduce((acc: any[], item: { code: any; name: any }) => {
      if (dataCode.includes(item.name)) {
        acc.push(item.code);
      }
      return acc;
    }, []);
  }
  return [];
};

export const combineArray = (dataArray: any, dataCode: any) => {
  if (dataArray?.length > 0 && dataCode?.length > 0) {
    return dataArray.reduce(
      (acc: any[], item: { code: any; id: any; name: any }) => {
        if (dataCode.includes(item.code || item.name)) {
          acc.push(item.id);
        }
        return acc;
      },
      []
    );
  }
  return [];
};

export const extractKeys = (data: any[], keys = []) => {
  data.forEach((item) => {
    // @ts-ignore
    keys.push(item.key);
    if (item.children && item.children.length > 0) {
      extractKeys(item.children, keys);
    }
  });
  return keys;
};

export const findMaxIndexOfArray = (array: any[]) => {
  if (array.length === 0) {
    return 0;
  }
  let maxId = array[0].id;

  for (let i = 1; i < array.length; i++) {
    if (array[i].id > maxId) {
      maxId = array[i].id;
    }
  }

  return maxId;
};

export const convertObjectToArray = (
  obj: any,
  ignoreKey: string,
  ignoreKey2?: string,
  ignoreKey3?: string,
) => {
  const result = [];
  for (const key in obj) {
    if (key !== ignoreKey && key !== ignoreKey2 && key !== ignoreKey3) {
      result.push(obj[key]);
    }
  }
  return result;
};

// Convert error string into an array of objects
export const convertErrors = (str: string) => {
  return str
    .split(',')
    .filter(error => error.includes(':'))  // Remove any empty items from split
    .map((error) => {
      const parts = error.split(':'); // Split by ':'
      const line = parts[0] ? parts[0].trim().replace('Dòng', 'Line') : ''; // Replace 'Dòng' with 'Line'
      const message = parts[1] ? parts[1].trim() : '';

      return `${line}: ${message}`;
    });
};

export const mapAnsFEtoBE = (obj: any, questionType: number) => {
  const feAnsArr = convertObjectToArray(obj, 'questionName', 'criteria', 'reminderText');

  if (questionType === QuestionTypeEnum.CONNECT) {
    return feAnsArr.map((ans: ConnectAnswer, index: number) => ({
      content: ans.content,
      orderNumber: index + 1,
      point: ans.point,
      response: ans.response,
      isDisplayContent: ans.isDisplayContent,
      filePath: ans.fileName,
      isTrue: ans.isTrue,
      key: ans.key,
    }));
  } else {
    return feAnsArr.map((ans: QuestionAnswerInfo, index: number) => ({
      content: ans.value,
      orderNumber: index + 1,
      point: ans.point,
      response: ans.response,
      isDisplayContent: ans.isDisplayContent,
      filePath: ans.fileName,
      isTrue: ans.isTrue,
      uuid: ans.uuid,
    }));
  }
};

export const mapAnsBEtoFE = (beAnsArr: BeQuizAnswer[]) => {
  if (!isArray(beAnsArr) && !beAnsArr?.length) return [];


  return beAnsArr.map((ans: BeQuizAnswer, index: number) => ({
    id: ans.orderNumber,
    value: ans.content,
    key: ans.orderNumber,
    isTrue: ans.isTrue,
    filePath: ans.filePath,
    fileName: ans.fileName,
    name: ans.fileName,
    isDisplayContent: ans.isDisplayContent,
    criteria: ans.criteria,
    point: ans.point,
    response: ans.response,
    uuid: ans.uuid,
  }));


};

export const mapConnectAnsBEtoFE = (beAnsArr: ConnectAnswer[]) => {
  if (!beAnsArr?.length) return [];

  return beAnsArr.map((ans: ConnectAnswer, index: number) => ({
    id: ans.orderNumber,
    content: ans.content,
    orderNumber: ans.orderNumber,
    point: ans.point,
    response: ans.response,
    isDisplayContent: ans.isDisplayContent,
    filePath: ans.filePath,
    fileName: ans.fileName,
    isTrue: ans.isTrue,
    key: ans.key,
  }));
};

export const getArrayFilterChecked = (
  arr: any[], // Mảng dữ liệu ban đầu
  arrFilter: any[], // Mảng sau khi đã lọc thường sẽ là 1 mảng boolean và undefind
  key: string // dữ liệu của key muốn lấy từ mảng ban đầu
) => {
  if (isEmpty(arr) || !arr?.length) return [];

  const distArr: any = [];
  arrFilter.forEach((item: boolean | undefined, index) => {
    if (item) distArr.push(arr[index][key]);
  });

  return distArr;
};

export const getArrOrUndefined = (arr: any[]) => {
  if (arr && arr.length > 0) return arr;

  return undefined;
};

export function addOrUpdateQuizRequest(
  array: QuizRequest[],
  newItem: QuizRequest
) {
  if (!array?.length) return [newItem];

  const newArr = [...array];
  const index = newArr.findIndex((item) => item.quizId === newItem.quizId);

  if (index !== -1) {
    // Nếu tìm thấy quizId trong mảng, cập nhật phần tử tại vị trí tìm được
    if (isEmpty(newItem.answer) || newItem.answer[0] === null)
      return newArr.filter(
        (item: QuizRequest) => item.quizId !== newItem.quizId
      );
    else newArr[index] = newItem;
  } else {
    // Nếu không tìm thấy, thêm mới vào mảng
    newArr.push(newItem);
  }
  return newArr;
}

export function addOrUpdateQuizMarkRequest(
  array: Draft[],
  newItem: Draft
) {
  if (!array?.length) return [newItem];

  const newArr = [...array];
  const index = newArr.findIndex((item) => item.quizId === newItem.quizId);

  if (index !== -1) {
    // Nếu tìm thấy quizId trong mảng, cập nhật phần tử tại vị trí tìm được
    if (!newItem.isConfigPointForTeacher)
      return newArr.filter(
        (item: Draft) => item.quizId !== newItem.quizId
      );
    else newArr[index] = newItem;
  } else {
    // Nếu không tìm thấy, thêm mới vào mảng
    newArr.push(newItem);
  }
  return newArr;
}

export function normalizeString(str: string) {
  return str
    .toLowerCase() // Convert to lowercase
    .normalize("NFD") // Decompose accents
    .replace(/[\u0300-\u036f]/g, ""); // Remove accents
}
