import { Col, Form, Row, Spin, Tag } from "antd";
import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import "./organization-detail.scss";
interface OrganizationDetailProps {
  organizationDetail: any;
  loadingDetail: boolean;
}

const OrganizationDetail: FC<OrganizationDetailProps> = ({
  organizationDetail,
  loadingDetail,
}) => {
  return (
    <section className="wrapper-settings category-management-detail" style={{height: "auto"}}>
      <div className="setting-organization wrapper-inside">
        <CustomCard className="header-card" title="Thông tin tổ chức">
          {loadingDetail ? (
            <div className="statistic-course-content statistic-course-loading">
              <Spin size="large" />
            </div>
          ) : (
            <section>
              <Form.Item className="setting-item" name="name" label="Tên tổ chức">
                <p className="organization-content">{organizationDetail?.name}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="code" label="Mã tổ chức">
                <p className="organization-content">{organizationDetail?.code}</p>
              </Form.Item>
            </section>
          )}
        </CustomCard>
      </div>
    </section>
  );
};

export default OrganizationDetail;
