import { DeleteOutlined, EyeOutlined, MinusCircleOutlined, MoreOutlined, PlusCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Layout, MenuProps, message, Space, TableColumnsType, TableProps } from 'antd';
import React, { ChangeEvent, useEffect, useMemo, useState, KeyboardEvent } from 'react';
import PageHeader from '../../../../../../components/page-header/PageHeader';
import { formatOriginTimev3 } from "../../../../../../utils/converDate";
import TableData from "../../../../../../components/table-data/TableData";
import FilterExamTable
  from '../../../../../../components/course-library/personal-exam-repository/list-question/filter-table-exam';
import FilterGroup from "../../../../../../components/course-library/personal-exam-repository/list-question/filter-group";
import { useForm } from "antd/es/form/Form";
import { FilterData } from "../../../../../../components/course-library/personal-exam-repository/list-question/filter-group/filter";
import DetailQuestion from '../../../../../../components/course-library/personal-exam-repository/list-question/detail-question';
import HistoryQuestion from '../../../../../../components/course-library/personal-exam-repository/list-question/history-question';
import EditPermissionQuestion from '../../../../../../components/course-library/personal-exam-repository/list-question/editPermisson';
import SentPermissionQuestion from '../../../../../../components/course-library/personal-exam-repository/list-question/sentPermission';
import PublishQuestionModal from '../../../../../../components/modal/publish-question';
import CreateQuestion from '../../../../../../components/course-library/personal-exam-repository/list-question/create-question';
import { title } from 'process';
import { isEmpty } from 'lodash';
import { deleteQuestionBank, deleteQuestionStorageBank, getListQuestion, getListQuestionStorage } from '../../../../../../service/library/question-list';
import CommonModal from '../../../../../../components/modal/common';
import { getAllProductionUnit } from '../../../../../../service/library';
import BankQuestionStorageModal from '../../../../../../components/modal/bank-question-storage';
import AddTopicToCourseModal from '../../../../../../components/modal/bank-question-storage';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '../../../../../../config/routes';

const ListQuestionPersonal = () => {
  const navigate = useNavigate()
  const [form] = useForm()

  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedDetail, setIsOpenedDetail] = useState<boolean>(false)
  const [isOpenedHistory, setIsOpenedHistory] = useState<boolean>(false)
  const [isOpenedEditPermission, setIsOpenedEditPermission] = useState<boolean>(false)
  const [isOpenedSentPermission, setIsOpenedSentPermission] = useState<boolean>(false)
  const [isOpenedPublishModal, setIsOpenedPublishModal] = useState<boolean>(false)
  const [isOpenBankStorageModal, setIsOpenBankStorageModal] = useState<boolean>(false)
  const [idDetailData, setIdDetailData] = useState<number | undefined>();
  const [idUnitDetailData, setIdUnitDetailData] = useState<number | undefined>();
  const [detailData, setDetailData] = useState<any>({});

  const [page, setPage] = useState<number>(1)
  const [size, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string>("")
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [typeQuestion, setTypeQuestion] = useState<boolean>(false) // false: danh sách câu hỏi, //true: câu hỏi lưu trũ
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [isOpenedConfirmStorage, setIsOpenedConfirmStorage] = useState<boolean>(false);

  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState<any>([]);

  const [countChoose, setCountChoose] = useState<any>([])
  const [dataOptions, setDataOptions] = useState({
    dataTopic: [
      { id: 1, name: "Nhiều lựa chọn văn bản" },
      { id: 2, name: "Nhiều lựa chọn ảnh" },
      { id: 3, name: "Nhiều lựa chọn video" },
      { id: 4, name: "Đúng/Sai" },
      { id: 5, name: "Danh sách" },
      { id: 6, name: "Nhiều đáp án văn bản" },
      { id: 7, name: "Nhiều đáp án hình ảnh" },
      { id: 8, name: "Nhiều đáp án video" },
      { id: 9, name: "Câu trả lời ngắn" },
      { id: 10, name: "Điển vào chỗ trống" },
      { id: 11, name: "Kết nối" },
      { id: 12, name: "Sắp xếp" },
      { id: 13, name: "Ghi âm" },
      { id: 14, name: "Ghi hình" },
      { id: 15, name: "File đính kèm" },
      { id: 16, name: "Trình soạn thảo" },
    ],

    dataPermission: [
      {
        id: "Công khai",
        name: "Công khai"
      },
      {
        id: "Nội bộ",
        name: "Nội bộ"
      },
      {
        id: "Giới hạn",
        name: "Giới hạn"
      }
    ],
    dataDifficulty: [
      {
        id: 0,
        name: "Cơ bản"
      },
      {
        id: 1,
        name: "Trung bình"
      },
      {
        id: 2,
        name: "Nâng cao"
      }
    ],
    dataStatus: [
      {
        id: 0,
        name: "Bản nháp"
      },
      {
        id: 1,
        name: "Chờ QTCS duyệt"
      },
      {
        id: 2,
        name: "Chờ cấp bộ duyệt"
      },
      {
        id: 3,
        name: "QTCS Không duyệt"
      },
      {
        id: 4,
        name: "Cấp bộ không duyệt"
      },
      {
        id: 5,
        name: "Đã lên lịch"
      },
      {
        id: 6,
        name: "Đã xuất bản"
      },
      {
        id: 7,
        name: "Chưa xuất bản"
      },
      {
        id: 8,
        name: "Đề xuất chỉnh sửa"
      },
    ],
    dataTypeProduction: [
      {
        id: 1,
        name: "Giảng viên"
      },
      {
        id: 2,
        name: "Trường"
      },
      {
        id: 3,
        name: "Cấp bộ"
      },
    ],
    dataUnit: []
  })

  const [filterData, setFilterData] = useState<FilterData>({
    questionId: null,
    typeProduction: null,
    unit: null,
    difficulty: null,
    permission: null,
    updatedDate: null,
    status: null,
  });

  useEffect(() => {
    Promise.all([getAllProductionUnit({ name: null })]).then((res) => {
      const listUnit = res[0].data.data.map((item: any) => {
        return {
          id: item?.uuid,
          name: item?.name,
        }
      });
      setDataOptions({
        ...dataOptions,
        dataUnit: listUnit,
      })
    })
  }, [])

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      ...([0, 3, 4].includes(record?.publishStatus) ?
        [{
          key: "0",
          label: "Gửi kiểm duyệt",
        },]
        : []),
      ...(record?.publishStatus === 8 ?
        [{
          key: "4",
          label: "Gửi kiểm duyệt lại",
        },]
        : []),
      ...(record?.publishStatus === 5 || record?.publishStatus === 7 ?
        [{
          key: "1",
          label: "Xuất bản",
        },]
        : []),
      ...(record.publishStatus === 1 || record.publishStatus === 2
        ? []
        : [{
          key: "2",
          label: "Chỉnh sửa",
        },]),
      {
        key: "3",
        label: "Sửa quyền truy cập",
      },
      // ...(record?.publishStatus === 0 ?
      //   [{
      //     key: "4",
      //     label: "Thiết lập câu hỏi",
      //   },]
      //   : []),
      {
        key: "5",
        label: "Lịch sử kiểm duyệt",
      },
      {
        key: "6",
        label: "Xóa",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
    ];
    return options1;
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Mã câu hỏi',
      dataIndex: "code",
      key: "code",
      width: 5,
    },
    {
      title: "Câu hỏi",
      dataIndex: "question",
      key: "question",
      width: 15,
      sorter: true,
      render: (_: any, record: any) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: record?.question }}></div>
        );
      },
    },
    ...(
      !typeQuestion ? [{
        title: "Chủ đề",
        dataIndex: "topic",
        key: "topic",
        width: 5,
      }] : []
    ),
    {
      title: "Loại câu hỏi",
      dataIndex: "type",
      key: "type",
      width: 7,
      // sorter: true,
      render: (_: any, record: any) => {
        const arr: any = dataOptions?.dataTopic?.filter((item) => item.id === record?.type)
        return (
          <p>{arr?.[0]?.name}</p>
        );
      },
    },
    {
      title: "Độ khó",
      dataIndex: "level",
      key: "level",
      width: 7,
      render: (_: any, record: any) => {
        const arr: any = dataOptions?.dataDifficulty?.filter((item) => item.id === record?.level)
        return (
          <p>{arr?.[0]?.name}</p>
        );
      },
    },
    ...(
      !typeQuestion ? [{
        title: "Trạng thái",
        dataIndex: "publishStatus",
        key: "publishStatus",
        width: 5,
        render: (_: any, record: any) => {
          const arr: any = dataOptions?.dataStatus?.filter((item) => item.id === record?.publishStatus)
          return (
            <p>{arr?.[0]?.name}</p>
          );
        },
      }] : [
        {
          title: "Phân cấp",
          dataIndex: "usage",
          key: "usage",
          width: 5,
          render: (_: any, record: any) => {
            const arr: any = dataOptions?.dataTypeProduction?.filter((item) => item.id === record?.typeProduction)
            return (
              <p>{arr?.[0]?.name}</p>
            );
          },
        },
        {
          title: "Đơn vị xuất bản",
          dataIndex: "productUnitName",
          key: "productUnitName",
          width: 5,
        },
      ]
    ),
    {
      title: "Ngày cập nhật",
      dataIndex: "updated_date",
      key: "updatedDate",
      sorter: true,
      width: 8,
      render: (_: any, record) => {
        return (
          <>{record.updateDate ? formatOriginTimev3(record.updateDate) : null}</>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 5,
      render: (_, record, index) => {
        const handleChangeCount = (type: string) => {
          const arr = [...dataTable]
          arr[index].checked = !arr[index].checked
          setDataTable(arr)

          if (type === "add") {
            setCountChoose([...countChoose, ...[record]])
          } else {
            const findObj = countChoose.filter((item: any) => item.id !== record.id)
            setCountChoose(findObj)
          }
        }

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenedDetail(true)
                  setIdDetailData(record.id)
                  setIdUnitDetailData(record?.unitId)
                }}
              >
                <EyeOutlined />
              </a>

              {
                !typeQuestion ?
                  <Dropdown
                    menu={{
                      items: createOptions(record),
                      onClick: ({ key }) => {
                        if (key === "0") {
                          if (record?.publishStatus === 8) {
                            setIsOpenedSentPermission(true)
                            setIdDetailData(record.id)
                            setMode("edit")
                          } else {
                            setIsOpenedSentPermission(true)
                            setIdDetailData(record.id)
                          }
                        }
                        if (key === "1") {
                          setIsOpenedPublishModal(true)
                          setIdDetailData(record.id)
                        }
                        if (key === "2") {
                          handleEditQuestion(record.id)
                        }
                        if (key === "3") {
                          setIsOpenedEditPermission(true)
                          setIdDetailData(record.id)
                          setDetailData(record)
                          setMode("")
                        }
                        if (key === "4") {
                          setIsOpenedSentPermission(true)
                          setIdDetailData(record.id)
                          setMode("edit")
                        }
                        if (key === "5") {
                          setIsOpenedHistory(true)
                          setIdDetailData(record.id)
                        }
                        if (key === "6") {
                          setIsOpenedConfirm(true)
                          setIdDetailData(record.id)
                        }

                      },
                      // else if (key === '2')handleCloseTask
                    }}
                    placement="bottomRight"
                  >
                    <Button>
                      <MoreOutlined />
                    </Button>
                  </Dropdown> :
                  <>
                    {
                      !record?.checked ?
                        <Button onClick={() => handleChangeCount("add")}><PlusCircleOutlined /></Button> :
                        <Button onClick={() => handleChangeCount("")}><MinusCircleOutlined /></Button>
                    }
                    <Button onClick={() => {
                      setIsOpenedConfirmStorage(true)
                      setIdDetailData(record.id)
                    }}><DeleteOutlined className='text-red-500' /></Button>
                  </>
              }
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ];

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
    }));
  }, [dataTable]);

  const getListData = async () => {
    setLoading(true);
    if (!typeQuestion) {
      const data = {
        page: page,
        size: size,
        keyword: searchValue.trim() || undefined,
        topics: [],
        access: filterData.permission ? filterData.permission : [],
        level: filterData.difficulty,
        type: filterData.questionId,
        createdDateFrom: !isEmpty(filterData.updatedDate) && !isEmpty(filterData.updatedDate?.[0]) ? `${filterData.updatedDate?.[0]}T00:00:00.000Z` : null,
        createdDateTo: !isEmpty(filterData.updatedDate) && !isEmpty(filterData.updatedDate?.[1]) ? `${filterData.updatedDate?.[1]}T00:00:00.000Z` : null,
        publicStatus: filterData.status ? filterData.status : [],
        isPublish: false,
        sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder)
          ? ["updated_date, desc"]
          : [`${fieldSortOrder},${statusOrder}`],
      };

      await getListQuestion(data).then((res) => {
        if (res.status === 200) {
          const { data, total } = res.data.data
          const arr = data?.map((item: any) => {
            return {
              ...item,
              checked: false
            }
          })
          setDataTable(arr)
          setTotalRecords(total)

          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
    } else {
      const data = {
        page: page,
        size: size,
        keyword: searchValue.trim() || undefined,
        topics: [],
        access: [],
        level: filterData.difficulty,
        type: filterData.questionId,
        createdDateFrom: !isEmpty(filterData.updatedDate) && !isEmpty(filterData.updatedDate?.[0]) ? `${filterData.updatedDate?.[0]}T00:00:00.000Z` : null,
        createdDateTo: !isEmpty(filterData.updatedDate) && !isEmpty(filterData.updatedDate?.[1]) ? `${filterData.updatedDate?.[1]}T00:00:00.000Z` : null,
        publicStatus: [],
        isPublish: false,
        typeProduction: filterData.typeProduction ?? [],
        uuid: filterData.unit ?? [],
        sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder)
          ? ["updated_date, desc"]
          : [`${fieldSortOrder},${statusOrder}`],
      }

      await getListQuestionStorage(data).then((res) => {
        if (res.status === 200) {
          const { data, total } = res.data.data
          const arr = data?.map((item: any) => {
            return {
              ...item,
              checked: false
            }
          })
          setDataTable(arr)
          setTotalRecords(total)

          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
    }
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  }

  const handleOpen = () => {
    // setIsOpened(true)
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  }

  const handleEditQuestion = (id: string) => {
    navigate(`${routesConfig.createQuestionRepository}?id=${id}`, { state: { mode: 'backState' }, });
  }

  const handleSubmitSearch = () => {
    getListData()
  }

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      questionId: null,
      typeProduction: null,
      unit: null,
      difficulty: null,
      permission: null,
      updatedDate: null,
      status: null,
    })
  }

  const onFinish = () => {

  }

  const closeDetail = () => setIsOpenedDetail(false)
  const saveData = () => {
    form.submit();
  };

  const deleteQuestionBankAdmin = async () => {
    await deleteQuestionBank(idDetailData).then((res) => {
      if (res.status === 200) {
        message.success("Xoá câu hỏi thành công")
        getListData()
        setIsOpenedConfirm(false)
      }
    })
  }

  const deleteQuestionStorageBankAdmin = async () => {
    await deleteQuestionStorageBank(idDetailData, true).then((res) => {
      if (res.status === 200) {
        message.success("Xoá câu hỏi lưu trữ thành công")
        getListData()
        setIsOpenedConfirmStorage(false)
      }
    })
  }

  useEffect(() => {
    getListData()
  }, [page, size, typeQuestion, fieldSortOrder, statusOrder])

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === "filterForm") {
          getListData()
        }
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={!typeQuestion ? "Danh sách câu hỏi của tôi" : "Danh sách câu hỏi lưu trữ"} />
            </div>
            <FilterExamTable
              handleSearch={handleSearch}
              handleOpen={handleOpen}
              handleSubmitSearch={handleSubmitSearch}
              handleChangeInput={handleChange}
              handleOpenFilter={handleOpenFilter}
              searchValue={searchValue}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
              countChoose={countChoose}
              setIsOpenBankStorageModal={setIsOpenBankStorageModal}
            />
          </div>
          <FilterGroup
            form={form}
            showFilter={showFilter}
            clearFilter={clearFilter}
            setFilterData={handleChangeFilter}
            filterData={filterData}
            dataOptions={dataOptions}
            onFinish={onFinish}
            typeQuestion={typeQuestion}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataColumns={newColumns}
            dataTable={dataTable}
            setPageNumber={setPage}
            setPageSize={setPageSize}
            pageNumber={page}
            pageSize={size}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            hideSelection={false}
            setSelectedRowKeysProps={setSelectedRowKeys}
            pagination={true}
            handleTableChangeProps={handleTableChange} />
          <DetailQuestion open={isOpenedDetail} onClose={() => setIsOpenedDetail(false)} idDetailData={idDetailData} dataOptions={dataOptions} idUnitDetailData={idUnitDetailData} />
          <HistoryQuestion open={isOpenedHistory} onClose={() => setIsOpenedHistory(false)} idDetailData={idDetailData} dataOptions={dataOptions} />
          <EditPermissionQuestion open={isOpenedEditPermission} onClose={() => setIsOpenedEditPermission(false)} detailData={detailData}
            getListData={getListData} />
          <SentPermissionQuestion
            open={isOpenedSentPermission}
            onClose={() => setIsOpenedSentPermission(false)}
            mode={mode}
            idDetailData={idDetailData}
            getListData={getListData}
          />
          <PublishQuestionModal open={isOpenedPublishModal} closeModal={() => setIsOpenedPublishModal(false)} idDetailData={idDetailData} getListData={getListData} />
        </div>
      </Form.Provider>
      <CommonModal
        open={isOpenedConfirm}
        title={'Xoá câu hỏi'}
        desc={`Câu hỏi đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá câu hỏi này không?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deleteQuestionBankAdmin}
        closeModal={() => setIsOpenedConfirm(false)}
        className="modal-delete-common"
      />
      <CommonModal
        open={isOpenedConfirmStorage}
        title={'Xoá câu hỏi lưu trữ'}
        desc={`Câu hỏi lưu trữ đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá câu hỏi này không?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deleteQuestionStorageBankAdmin}
        closeModal={() => setIsOpenedConfirmStorage(false)}
        className="modal-delete-common"
      />
      <BankQuestionStorageModal
        open={isOpenBankStorageModal}
        closeModal={() => setIsOpenBankStorageModal(false)}
        countChoose={countChoose}
        getListData={getListData}
      />
    </Layout>
  );
};

export default ListQuestionPersonal;