import {
  Button,
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
} from "antd";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import { getCourseStudentList } from "../../../service/course-management";
import { Circle } from "./components/Circle";
import StudentDataAnalysis from "./components/student-data-analysis/StudentDataAnalysis";
import "./CourseStudentList.scss";
import FilterStudentList from "./filter-table/FilterTable";
import FormFilter from "./filter-table/FormFilter";
import { EyeOutlined } from "@ant-design/icons";
import CertificateModal from "./components/certificate-modal/CertificateModal";
import { checkAndCountdown } from "../../../utils/functionUltils";

type Props = {
  title?: string;
};

const CourseStudentList = (props: Props) => {
  const { id } = useParams();
  const [tab, setTab] = useState("1");
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [courseStudentList, setCourseStudentList] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [lastPreview, setlastPreview] = useState<any>(null);
  const [certificateChoose, setCertificateChoose] = useState<any>();
  const [isViewCertificate, setIsViewCertificate] = useState<boolean>(false);

  const [filterData, setFilterData] = useState<any>({
    status: null,
    approvedDate: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      courseId: Number(id),
      learningStatus: form.getFieldValue("learningStatus") || undefined,
      registrationForm: form.getFieldValue("registrationForm") || undefined,
      createdDateFrom: !isEmpty(form.getFieldValue("enrollmentDate"))
        ? dayjs(form.getFieldValue("enrollmentDate")?.[0])
          .endOf("day")
          .toISOString()
        : undefined,
      createdDateTo: !isEmpty(form.getFieldValue("enrollmentDate"))
        ? dayjs(form.getFieldValue("enrollmentDate")?.[1])
          .endOf("day")
          .toISOString()
        : undefined,
      startDateFrom: !isEmpty(form.getFieldValue("startDate"))
        ? dayjs(form.getFieldValue("startDate")?.[0]).endOf("day").toISOString()
        : undefined,
      startDateTo: !isEmpty(form.getFieldValue("startDate"))
        ? dayjs(form.getFieldValue("startDate")?.[1]).endOf("day").toISOString()
        : undefined,
      endDateFrom: !isEmpty(form.getFieldValue("endDate"))
        ? dayjs(form.getFieldValue("endDate")?.[0]).endOf("day").toISOString()
        : undefined,
      endDateTo: !isEmpty(form.getFieldValue("endDate"))
        ? dayjs(form.getFieldValue("endDate")?.[1]).endOf("day").toISOString()
        : undefined,
    };

    await getCourseStudentList(data)
      .then((res) => {
        const { data } = res.data;
        setCourseStudentList(data.content);
        setTotalRecords(data.totalElements);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder, lastPreview]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    setPageNumber(1);
    form.resetFields();
    getListData();
  };

  const RenderRegistrationForm = (type: number) => {
    switch (type) {
      case 1:
        return (
          <div>
            <Circle color="#D9D9D9" />
            Học trước
          </div>
        );
      case 2:
        return (
          <div>
            <Circle color="#1677FF" /> Mua khoá học
          </div>
        );
      case 3:
        return (
          <div>
            <Circle color="#52C41A" /> Học thử
          </div>
        );

      default:
        return null;
    }
  };

  const RenderLearningStatus = (type: number) => {
    switch (type) {
      case 1:
        return <div className="learning-Enroll">Ghi danh</div>;
      case 2:
        return <div className="learning">Đang học</div>;
      case 3:
        return <div className="learning-success">Hoàn thành</div>;
      case 4:
        return <div className="learning-expired">Hết hạn</div>;

      default:
        return null;
    }
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: "6px",
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên học viên",
      dataIndex: "studentName",
      key: "studentName",
      sorter: true,
      width: 30,
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      width: 20,
    },
    {
      title: "Hình thức",
      dataIndex: "registrationForm",
      key: "registrationForm",
      width: "14px",
      render: (_: any, record) => {
        return RenderRegistrationForm(record.registrationForm);
      },
    },
    {
      title: "Trạng thái học tập",
      dataIndex: "learningStatus",
      key: "learningStatus",
      width: 20,
      render: (_: any, record) => {
        return RenderLearningStatus(record.learningStatus);
      },
    },
    {
      title: "Ngày ghi danh",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: 20,
      render: (_: any, record) => {
        return (
          <span>
            {record?.createdDate
              ? dayjs(record?.createdDate).format("DD/MM/YYYY")
              : null}
          </span>
        );
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      sorter: true,
      width: 20,
      render: (_: any, record) => {
        return (
          <span>
            {record?.startDate
              ? dayjs(record?.startDate).format("DD/MM/YYYY")
              : null}
          </span>
        );
      },
    },
    {
      title: "Thời gian học tập",
      dataIndex: "endDate",
      key: "endDate",
      sorter: true,
      width: 28,
      render: (_: any, record) => {

        return (
          <span>
            <div>{Math.abs(checkAndCountdown(new Date(record.endDate))) >= 0 ? `Còn ${Math.abs(checkAndCountdown(new Date(record.endDate)))} ngày nữa hết thời gian` : `Đã hết thời gian ${Math.abs(checkAndCountdown(new Date(record.endDate)))} ngày`}</div>
          </span>
        );
      },
    },
    {
      title: "Chứng chỉ",
      dataIndex: "isGetCertificate",
      key: "isGetCertificate",
      sorter: true,
      width: 16,
      render: (_: any, record) => {
        return (
          <span>
            {record?.isGetCertificate
              ? <Button
                className="action-table"
                onClick={() => {
                  setCertificateChoose(record)
                  setIsViewCertificate(true)

                }}
              ><EyeOutlined

                /></Button>
              : null}
          </span>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: <div>Danh sách</div>,
      children: (
        <div className="waiting-assignment">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={courseStudentList}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <div>Thống kê</div>,
      children: (
        <div className="assigned">
          <StudentDataAnalysis />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };
  console.log("certificateChoose", certificateChoose);

  return (
    <Layout className="page-header-group course-student-list">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "filterForm") {
            getListData();
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper justify-between pb-6">

            <div className="table-header--left">
              <PageHeader
                title={"Danh sách học viên"}
                listBreadcrumb={listBreadcrumb}
              />
            </div>
            <FilterStudentList
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              setlastPreview={setlastPreview}
              tab={tab}
            />
          </div>

          {showFilter ? (
            <FormFilter
              handleCloseFilter={handleCloseFilter}
              clearFilter={clearFilter}
              form={form}
              showFilter={showFilter}
              tab={tab}
              filterData={filterData}
              setFilterData={handleChangeFilter}
            />
          ) : null}
        </div>
        <div className="tabs-heading">
          <div className="course-student-list">
            <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
          </div>
        </div>
      </Form.Provider>
      <CertificateModal
        studentId={certificateChoose?.id}
        open={isViewCertificate}
        handleCancel={() => setIsViewCertificate(false)}
      />

    </Layout>
  );
};

export default CourseStudentList;
