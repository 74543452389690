import React, { useEffect, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { BlockMath } from "react-katex";
import "katex/dist/katex.min.css";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Container from "./container";
import { Button, Empty, Flex, Input } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import {
  convertToLatex,
  generateRandomId,
  parseBlockArray,
  parseExpression,
} from "../../../../../../../utils/functionUltils";
import { Item } from "./SortableItem";

export type Formula = {
  id: string;
  label: string;
  value: any;
};

type ItemState = {
  courses: Formula[];
  operations: Formula[];
  formula: Formula[];
};

type Course = {
  blockId: number;
  coefficient: number;
  courseScore: number;
  recordResults: number;
  unitName: string;
};
interface ScoreFormulaProps {
  courses?: Course[];
  formula?: string;
  formulaQuiz?: string;
  onChange?: (value: any) => void;
}

const ScoreFormula = ({
  formulaQuiz = "",
  courses = [],
  onChange = () => {},
}: ScoreFormulaProps) => {
  const [showForm, setShowForm] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [active, setActive] = useState<Formula>();
  const [items, setItems] = useState<ItemState>({
    courses: [],
    operations: [
      { id: generateRandomId(), label: "+", value: "+" },
      { id: generateRandomId(), label: "-", value: "-" },
      { id: generateRandomId(), label: "*", value: "*" },
      { id: generateRandomId(), label: "/", value: "/" },
      { id: generateRandomId(), label: "(", value: "(" },
      { id: generateRandomId(), label: ")", value: ")" },
    ],
    formula: [],
  });
  const [isAddingValue, setIsAddingValue] = useState<boolean>(false);
  const [operationAdding, setOperationAdding] = useState<string>("");
  const currentLatexFormula = convertToLatex(
    items.formula.map((item: Formula) => item.label).join(" ") || ""
  );

  useEffect(() => {
    if (items?.formula?.length) {
      onChange({
        blockInfo: parseBlockArray(
          parseExpression(items.formula.map((item: Formula) => item.value))
        ),
        formula: items.formula.map((item: Formula) => item.value).join(" "),
      });
    }
  }, [items.formula]);

  useEffect(() => {
    if (courses?.length) {
      setItems({
        operations: [
          { id: generateRandomId(), label: "+", value: "+" },
          { id: generateRandomId(), label: "-", value: "-" },
          { id: generateRandomId(), label: "*", value: "*" },
          { id: generateRandomId(), label: "/", value: "/" },
          { id: generateRandomId(), label: "(", value: "(" },
          { id: generateRandomId(), label: ")", value: ")" },
        ],
        formula: [],
        courses: courses.map((item: Course) => ({
          id: item.blockId,
          label: item.unitName,
          value: `id{${item.blockId}}`,
        })) as any,
      });
    }
  }, [courses, showForm, refresh]);

  useEffect(() => {
    if (formulaQuiz) {
      setShowForm(false);
    }
  }, [formulaQuiz]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function findContainer(id: string): any | undefined {
    return (Object.keys(items) as Array<keyof ItemState>).find(
      (key) => items[key].some((item) => item.id === id) || key === id
    );
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;
  
    if (!over) return;
  
    const activeId = active.id as string;
    const overId = over.id as string;
  
    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);
  
    // Nếu không xác định được container, hoặc cùng container => bỏ qua
    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }
  
    // Nếu thả lên 'operations' => bỏ qua
    if (overContainer === "operations") return;
  
    // Nếu kéo từ 'operations' sang 'courses' => bỏ qua
    if (activeContainer === "operations" && overContainer === "courses") return;
  
    // ******************
    // Nếu kéo từ 'operations' sang 'formula' => ko xử lý ở dragOver
    // ******************
    if (activeContainer === "operations" && overContainer === "formula") {
      return;
    }
  
    // Các trường hợp còn lại, ví dụ kéo từ courses sang formula, 
    // hoặc formula sang formula (move trong cùng container):
    setItems((prev) => {
      const activeItems = prev[activeContainer as keyof ItemState];
      const overItems = prev[overContainer as keyof ItemState];
  
      const movedItem = activeItems?.find((item: Formula) => item.id === activeId);
      if (!movedItem) return prev;
  
      return {
        ...prev,
        [activeContainer]: activeItems.filter((item) => item.id !== activeId),
        [overContainer]: [...overItems, movedItem],
      };
    });
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!over) {
      // TH1: Người dùng thả chuột ra ngoài hẳn (không nằm lên container nào).
      // Nếu item đang ở "formula", xóa nó luôn.
      const activeId = active.id as string;
      const activeContainer = findContainer(activeId);
  
      if (activeContainer === "formula") {
        // Lấy item trong formula
        setItems((prev) => {
          const itemInFormula = prev.formula.find((f) => f.id === activeId);
          if (!itemInFormula) return prev;
  
          // Kiểm tra xem item này có cùng label với 1 item trong "operations" không?
          const isSimilarToOperation = prev.operations.some(
            (op) => op.label === itemInFormula.label
          );
  
          if (isSimilarToOperation) {
            // Xóa khỏi formula
            return {
              ...prev,
              formula: prev.formula.filter((f) => f.id !== activeId),
            };
          }
  
          return prev;
        });
      }
  
      return; // Dừng luôn vì không "over" container nào
    }
  
    // Xác định container
    const activeId = active.id as string;
    const overId = over.id as string;
    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);
  
    if (!activeContainer || !overContainer) return;
  
    // -----------------------------
    // copy item nếu kéo từ operations -> formula
    // -----------------------------
    if (activeContainer === "operations" && overContainer === "formula") {
      setItems((prev) => {
        const itemToCopy = prev.operations.find((i) => i.id === activeId);
        if (!itemToCopy) return prev;
  
        const newItem = {
          ...itemToCopy,
          id: generateRandomId(), // Tạo ID mới
        };
  
        return {
          ...prev,
          formula: [...prev.formula, newItem],
        };
      });
      return;
    }
  
    // -----------------------------
    // 2) Xoá item nếu kéo từ formula -> (không phải formula) VÀ item này "giống" operations
    // -----------------------------
    if (activeContainer === "formula" && overContainer !== "formula") {
      setItems((prev) => {
        const itemInFormula = prev.formula.find((f) => f.id === activeId);
        if (!itemInFormula) return prev;
  
        const isSimilarToOperation = prev.operations.some(
          (op) => op.label === itemInFormula.label
        );
  
        if (isSimilarToOperation) {
          // Xóa khỏi formula
          return {
            ...prev,
            formula: prev.formula.filter((f) => f.id !== activeId),
          };
        }
  
        return prev; // Nếu không "giống" operations, giữ nguyên
      });
      return;
    }
  
    // -----------------------------
    // 3) Move item trong cùng container hoặc từ courses -> formula
    // -----------------------------
    const activeIndex = items[activeContainer as keyof ItemState].findIndex(
      (item) => item.id === activeId
    );
    const overIndex = items[overContainer as keyof ItemState].findIndex(
      (item) => item.id === overId
    );
  
    if (activeIndex !== overIndex && activeContainer === overContainer) {
      setItems((prev) => ({
        ...prev,
        [overContainer]: arrayMove(
          prev[overContainer as keyof ItemState],
          activeIndex,
          overIndex
        ),
      }));
    }
  }

  const handleAddOperation = () => {
    setItems((prev: ItemState) => ({
      ...prev,
      operations: [
        ...prev.operations,
        {
          id: generateRandomId(),
          label: operationAdding,
          value: operationAdding,
        },
      ],
    }));
    setIsAddingValue(false);
    setOperationAdding("");
  };

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const { id } = active;
    const containerId = active?.data?.current?.sortable?.containerId;

    if (!containerId) return;

    const currentActiveItem = items[containerId as keyof ItemState].find(
      (item: Formula) => item.id === id
    );

    if (!currentActiveItem) return;

    setActive(currentActiveItem);
  }

  return (
    <div className="math-recipe">
      {showForm ? (
        <div>
          <div>
            <DndContext
              sensors={sensors}
              collisionDetection={pointerWithin}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
            >
              <div className="text-14 font-weight-5 mt-2">
                Danh sách học phần
              </div>
              {items.courses?.length > 0 ? (
                <Container
                  className="fixed-container"
                  itemClassname="const-formula"
                  id="courses"
                  items={items.courses}
                />
              ) : (
                <div className="mt-1">
                  {" "}
                  <Empty description="Không có học phần tính điểm" />
                </div>
              )}

              <Flex align="center" className="mt-2 mb-1" gap={6}>
                <p className="text-14 font-weight-5">Toán tử</p>{" "}
                {isAddingValue ? (
                  <Flex gap={8} align="center">
                    <Input
                      onPressEnter={handleAddOperation}
                      style={{ width: 150 }}
                      value={operationAdding}
                      onChange={(e) => setOperationAdding(e.target.value)}
                    />
                    <Button
                      onClick={handleAddOperation}
                      style={{ width: 24, height: 24 }}
                      className="btn-primary"
                      icon={<CheckOutlined style={{ fontSize: 12 }} />}
                    ></Button>
                    <Button
                      onClick={() => {
                        setIsAddingValue(false);
                        setOperationAdding("");
                      }}
                      style={{ width: 24, height: 24 }}
                      icon={<CloseOutlined style={{ fontSize: 12 }} />}
                    ></Button>
                  </Flex>
                ) : (
                  <Button
                    onClick={() => setIsAddingValue(true)}
                    style={{ width: 24, height: 24 }}
                    className="btn-primary"
                    icon={<PlusOutlined style={{ fontSize: 12 }} />}
                  ></Button>
                )}
              </Flex>
              <Container
                className="fixed-container"
                itemClassname="const-formula"
                id="operations"
                items={items.operations}
              />
              <Flex align="center" className="mt-2" gap={16}>
                <p className="text-14 font-weight-5">Xây dựng công thức</p>{" "}
                <Button
                  icon={<RedoOutlined />}
                  className="btn-primary"
                  onClick={() => setRefresh(!refresh)}
                >
                  Đặt lại
                </Button>
              </Flex>
              <Container
                id="formula"
                itemClassname="dist-formula"
                className="recipe-container mt-5"
                description="Kéo vào đây"
                items={items.formula}
              />
              <DragOverlay>
                {active ? (
                  <Item
                    className="const-formula"
                    id={active.id as string}
                    label={active.label}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
          </div>

          <div className="mt-2">Kết quả:</div>
          {/* Hiển thị công thức */}
          {currentLatexFormula ? (
            <BlockMath math={currentLatexFormula} />
          ) : (
            <div className="text-16 text-danger text-center font-italic">
              Công thức không hợp lệ
            </div>
          )}

          <Button
            className="btn-primary mt-1"
            onClick={() => setShowForm(false)}
          >
            Huỷ
          </Button>
        </div>
      ) : (
        <div className="mt-2">
          <div className="text-28 font-bold text-center">
            <BlockMath math={convertToLatex(formulaQuiz)} />
          </div>

          <Button
            className="btn-primary mt-2"
            icon={<PlusOutlined />}
            onClick={() => setShowForm(true)}
          >
            Tạo công thức mới
          </Button>
        </div>
      )}
    </div>
  );
};

export default ScoreFormula;
