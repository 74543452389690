import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Empty, Form, Input, message, Modal, Radio, Table, Typography } from 'antd';
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import FormInput from '../../../../components/form-input/FormInput';
import { approveAllLearningMaterial, getLearningMaterialList } from '../../../../service/learning-material';
import dayjs from 'dayjs';
import './ApproveMulti.scss';
import { getFirstLetter, RenderUnitIcon } from '../../../../utils/functionUltils';
import { notifySuccess } from '../../../../utils/notification';
import { LearningMaterialStore } from '../../../../stores/stores';

type Props = {
	handleClose?: () => void;
	getListDataProps?: () => void;
	open: boolean;
	shareType: number
};

const ApproveMulti = (props: Props) => {
	const { handleClose, getListDataProps, open, shareType } = props;
	const { onReload } = LearningMaterialStore();

	const [form] = Form.useForm();
	const [searchValue, setSearchValue] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [listLearningMaterial, setListLearningMaterial] = useState([]);
	const [approvalStatus, setApprovalStatus] = useState<string>("");
	const [description, setDescription] = useState<string>('');
	const [isModalVisible, setIsModalVisible] = useState(false); // State kiểm soát Modal hiển thị

	const getListData = () => {
		setLoading(true);
		const payload = {
			page: pageNumber,
			size: pageSize,
			code: searchValue || undefined,
			shareType: [shareType],
			status: [1],
			sort: ['created_date, desc']
		};

		getLearningMaterialList(payload)
			.then((res) => {
				const { data } = res;
				setListLearningMaterial(data?.data.content);
				setTotalRecords(data.data.totalElements);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getListData();
	}, [open, pageNumber, pageSize]);

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
		if (e.key === 'Enter') {
			getListData();
		}
	};

	const handleSubmitSearch = () => {
		getListData();
	};

	const handleBulkCheckCourse = async (description: string, status: string) => {
		const data = {
			ids: selectedRowKeys,
			status: status,
			description: description,
		};

		try {
			await approveAllLearningMaterial(data);
			notifySuccess(`${approvalStatus === "2" ? "Duyệt" : "Từ chối duyệt"} học liệu thành công`);
			onReload()
			getListDataProps && getListDataProps();
			handleClose && handleClose();
			setSelectedRowKeys([]);
			setDescription('');
			setApprovalStatus('');
		} catch (error) {
		}
	};

	const options = [
		{ label: 'Đồng ý', value: '2' },
		{ label: 'Không đồng ý', value: '3' },
	];

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleConfirm = () => {
		if (!approvalStatus) {
			message.warning("Vui lòng chọn trạng thái duyệt!");
			return;
		}

		handleBulkCheckCourse(description, approvalStatus);
		setIsModalVisible(false);
	};

	const dataColumns = [
		{
			title: 'STT',
			dataIndex: 'numericalOrder',
			key: 'numericalOrder',
			width: '60px',
			render: (_: any, __: any, index: number) =>
				(pageNumber - 1) * pageSize + index + 1
		},
		{
			title: "Học liệu",
			dataIndex: "resource",
			key: "resource",
			width: "30%",
			render: (resource: any, record: any) => {
				return (
					<div className={`${"flex gap-16 align-center"}`}
						style={{ wordBreak: "break-all" }}
					>
						<div
							style={{ width: "60px" }}
						>{RenderUnitIcon(record.module)}</div>
						<div>
							<div className="font-weight-5">{record.code}</div>
							<div>{record.name}</div>

						</div>
					</div>
				)
			},
		},
		{
			title: "Mô tả",
			dataIndex: "description",
			key: "description",
			render: (resource: any, record: any) => {
				return (
					<div
						className="mt-3"
						dangerouslySetInnerHTML={{ __html: `${record?.description}` || "" }}
					/>
				)
			},
		},
		{
			title: "Người chia sẻ",
			dataIndex: "sharer",
			key: "sharer",
			render: (resource: any, record: any) => {
				return (
					<div className="flex gap-16 align-center"
						style={{ wordBreak: "break-all" }}
					>
						<Avatar src={record?.imagePath} size={48} className="mt-2 mb-2">
							{getFirstLetter(record?.shareUserFullName)}
						</Avatar>
						<div>
							<div className="font-weight-5">{record.shareUserFullName}</div>
							<div>{record.shareEnterpriseName}</div>

						</div>
					</div>
				)
			},
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdDate',
			key: 'createdDate',
			width: '120px',
			render: (createdDate: any) => dayjs(createdDate).format('DD/MM/YYYY'),
		},
		{
			title: "Ngày chia sẻ",
			dataIndex: "sharedDate",
			key: "sharedDate",
			width: "120px",
			render: (resource: any, record: any) => {

				return (
					<div>
						{record.sharedDate ? dayjs(record.sharedDate).format("DD/MM/YYYY") : ""}
					</div>
				)
			},
		}
	];

	const resetData = () => {
		setSearchValue('');
		setSelectedRowKeys([]);
		setPageNumber(1);
		setPageSize(10);
		setListLearningMaterial([]);
		setTotalRecords(0);
		setApprovalStatus('');
		setDescription('');
		form.resetFields();
	};

	useEffect(() => {
		if (open) {
			resetData();
			getListData();
		} else {
			resetData();
		}
	}, [open]);

	return (
		<Modal
			className="approve-multi-learning-material"
			title="Duyệt nhiều nội dung học liệu"
			centered
			open={open}
			onCancel={handleClose}
			width="80vw"
			style={{ height: '90vh', maxHeight: '90vh' }}
			footer={[
				<Button onClick={handleClose} key="cancel">Hủy bỏ</Button>,
				<Button
					type="primary"
					onClick={showModal}
					disabled={selectedRowKeys.length === 0}
					key="submit"
				>
					Xác nhận
				</Button>
			]}
		>
			<Form form={form} name="approve-multi-learning-material-modal">
				<div className="approve-multi-header">
					<Typography.Title level={5}>Tìm kiếm khóa học</Typography.Title>
					<FormInput
						placeholder="Nhập từ khóa cần tìm"
						value={searchValue}
						onChange={handleChangeInput}
						onKeyPress={handleSearch}
						afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
						style={{ maxWidth: "400px" }}
					/>
				</div>

				<div className="approve-multi-status">
					<Typography.Text>Danh sách khóa học</Typography.Text>
					{selectedRowKeys.length > 0 && (
						<Typography.Text className="selected-count">
							Đã chọn {selectedRowKeys.length}/{totalRecords}
						</Typography.Text>
					)}
				</div>

				<div className="table-container">
					<Table
						columns={dataColumns}
						dataSource={listLearningMaterial}
						loading={loading}
						pagination={{
							current: pageNumber,
							pageSize,
							total: totalRecords,
							onChange: (page, size) => {
								setPageNumber(page);
								setPageSize(size);
							}
						}}
						rowSelection={{
							selectedRowKeys,
							onChange: setSelectedRowKeys
						}}
						scroll={{ x: 1000, y: 'calc(90vh - 340px)' }}
						rowKey="id"
						locale={{ emptyText: <Empty description="Không tìm thấy"></Empty> }}
					/>
				</div>
			</Form>

			<Modal
				title={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<CheckCircleOutlined style={{ color: "#1677FF", fontSize: '24px', marginRight: '10px' }} />
						Xác nhận duyệt học liệu
					</div>
				}
				open={isModalVisible}
				onCancel={handleCancel}
				onOk={handleConfirm}
				okText="Xác nhận"
				cancelText="Đóng"
				centered
				okButtonProps={{
					disabled: !approvalStatus
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
					<Typography.Text>Xin vui lòng xác nhận việc duyệt học liệu.</Typography.Text>
				</div>

				<Radio.Group
					onChange={(e) => setApprovalStatus(e.target.value)}
					options={options}
					value={approvalStatus}
				/>
				<Input.TextArea
					rows={4}
					placeholder="Nhập mô tả"
					onChange={(e) => setDescription(e.target.value)}
					style={{ marginTop: '8px' }}
				/>
			</Modal>
		</Modal>
	);
};

export default ApproveMulti;
