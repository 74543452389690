import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../../config/api/configApiUpload";

// Province
export const getMoocAddressProvinceByCode: (code: string) => Promise<AxiosResponse<any>> = (code) => {
    return axiosConfigV2.get(`/address/province/${code}`)
}
export const getAllMoocAddressProvinceByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/province/get-all-by-filter`, data);
}
export const addMoocAddressProvince: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/province`, data);
}
export const updateMoocAddressProvince: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/address/province`, data);
}
export const deleteMoocAddressProvince: (code: string) => Promise<AxiosResponse<any>> = (code) => {
    return axiosConfigV2.delete(`/address/province/${code}`)
}
export const exportExcelMoocProvince: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/province/export-excel`, data, {responseType:"arraybuffer"})
}
export const importExcelMoocProvince: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/address/province/import-excel`, data, {responseType:"arraybuffer"})
}
export const downloadMoocProvinceTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/address/province/download-template-excel`, {responseType: "arraybuffer",})
}

// District
export const getMoocAddressDistrictByCode: (code: string) => Promise<AxiosResponse<any>> = (code) => {
    return axiosConfigV2.get(`/address/district/${code}`)
}
export const getAllMoocAddressDistrictByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/district/get-all-by-filter`, data);
}
export const addMoocAddressDistrict: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/district`, data);
}
export const updateMoocAddressDistrict: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/address/district`, data);
}
export const deleteMoocAddressDistrict: (code: string) => Promise<AxiosResponse<any>> = (code) => {
    return axiosConfigV2.delete(`/address/district/${code}`)
}
export const exportExcelMoocDistrict: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/district/export-excel`, data, {responseType:"arraybuffer"})
}
export const importExcelMoocDistrict: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/address/district/import-excel`, data, {responseType:"arraybuffer"})
}
export const downloadMoocDistrictTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/address/district/download-template-excel`, {responseType: "arraybuffer",})
}

// Ward
export const getMoocAddressWardByCode: (code: string) => Promise<AxiosResponse<any>> = (code) => {
    return axiosConfigV2.get(`/address/ward/${code}`)
}
export const getAllMoocAddressWardByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/ward/get-all-by-filter`, data)
}
export const addMoocAddressWard: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/ward`, data)
}
export const updateMoocAddressWard: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/address/ward`, data)
}
export const deleteMoocAddressWard: (code: string) => Promise<AxiosResponse<any>> = (code) => {
    return axiosConfigV2.delete(`/address/ward/${code}`)
}
export const exportExcelMoocWard: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/address/ward/export-excel`, data, {responseType:"arraybuffer"})
}
export const importExcelMoocWard: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/address/ward/import-excel`, data, {responseType:"arraybuffer"})
}
export const downloadMoocWardTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/address/ward/download-template-excel`, {responseType: "arraybuffer",})
}




