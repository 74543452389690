import React, { useEffect } from 'react';
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";

type AddExamCourseModalModalProps = {
  onCancel: () => void;
  open: boolean;
  onFinish: (values: any) => void;
  courseOptions: any[];
  sectionOptions: any[];
  sequenceOptions: any[];
  form: any;
};

const AddExamCourseModal: React.FC<AddExamCourseModalModalProps> = ({
  onCancel,
  open,
  onFinish,
  courseOptions,
  sectionOptions,
  sequenceOptions,
  form,
}) => {
  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open, form]);
  return (
    <Modal
      className="add-exam-course-modal"
      title="Thêm đề vào khóa học"
      open={open}
      onOk={() => form.submit()}
      onCancel={onCancel}
      okButtonProps={{
        icon: <PlusOutlined />,
      }}
      okText="Thêm"
      cancelButtonProps={{
        icon: <CloseOutlined />,
      }}
      cancelText="Hủy"
    >
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item
          name="course"
          label="Chọn khóa học"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn khóa học",
            },
          ]}
        >
          <FormItemDropdown
            options={courseOptions}
            allowClear={true}
            placeholder="Chọn khóa học"
          />
        </Form.Item>
        <Form.Item
          name="section"
          label="Chọn Chương"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn Chương",
            },
          ]}
        >
          <FormItemDropdown
            options={sectionOptions}
            allowClear={true}
            placeholder="Chọn Chương"
          />
        </Form.Item>
        <Form.Item
          name="sequence"
          label="Chọn Bài giảng"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn Bài giảng",
            },
          ]}
        >
          <FormItemDropdown
            options={sequenceOptions}
            allowClear={true}
            placeholder="Chọn Bài giảng"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddExamCourseModal;