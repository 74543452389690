import { Modal, Button, Checkbox } from 'antd';
import React, { useState } from 'react';
import './ShareLearningMaterial.scss';
import { shareLearningMaterial } from '../../../../service/learning-material';
import { notifySuccess } from '../../../../utils/notification';
import { LearningMaterialStore } from '../../../../stores/stores';
type Props = {
	open: boolean
	handleCancel: () => void
	handleOk: () => void
	shareId: number
}
const ShareLearningMaterial = (props: Props) => {
	const { open, handleCancel, handleOk, shareId } = props
	const { onReload } = LearningMaterialStore()
	const [selectedLevel, setSelectedLevel] = useState<'school' | 'department'>('school');
	const handleLevelChange = (level: 'school' | 'department') => {
		setSelectedLevel(level);
	};
	const handleShare = async () => {
		try {
			const res = await shareLearningMaterial(shareId, selectedLevel === "school" ? false : true)
			notifySuccess(`Chia sẻ học liệu đến ${selectedLevel === "school" ? "Cấp trường" : "Cấp bộ"}`);
			onReload()
		} catch (error) {

		}
		handleOk();
	}

	return (
		<Modal
			title="Chia sẻ học liệu"
			centered
			open={open}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key="cancel" onClick={handleCancel}>
					Đóng
				</Button>,
				<Button key="submit" type="primary" onClick={handleShare}>
					Chia sẻ
				</Button>
			]}
		>
			<div className="share-options">
				<div className="option">
					<Checkbox
						checked={selectedLevel === 'school'}
						onChange={() => handleLevelChange('school')}
					/>
					<div className="option-text">
						<div className="option-title">Cấp Trường</div>
						<div className="option-description">
							Chỉ các Giảng viên trong Trường đại học nơi bạn công tác mới có thể tìm kiếm và tải
							xuống học liệu bạn đã chia sẻ
						</div>
					</div>
				</div>
				<div className="option">
					<Checkbox
						checked={selectedLevel === 'department'}
						onChange={() => handleLevelChange('department')}
					/>
					<div className="option-text">
						<div className="option-title">Cấp Bộ</div>
						<div className="option-description">
							Tất cả Giảng viên tham gia nền tảng đều có thể tìm kiếm và tải xuống học liệu bạn đã chia sẻ
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ShareLearningMaterial;
