// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-answers-content {
  height: 100%; }
  .survey-answers-content__wrapper {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #D9D9D9; }
  .survey-answers-content__title {
    padding-bottom: 15x;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 15px; }
  .survey-answers-content__body__item {
    display: flex;
    margin-bottom: 15px;
    align-items: center; }
    .survey-answers-content__body__item:last-child {
      margin-bottom: 0; }
    .survey-answers-content__body__item .item__action {
      font-size: 16px;
      font-weight: 500;
      margin-right: 10px; }
      .survey-answers-content__body__item .item__action:last-child {
        margin-right: 0; }
      .survey-answers-content__body__item .item__action__media {
        width: 100%;
        height: calc(100vh - 230px); }
      .survey-answers-content__body__item .item__action__file {
        border: 1px solid #D9D9D9;
        padding: 8px;
        border-radius: 8px;
        display: block;
        width: 100%;
        color: #1677FF;
        cursor: pointer; }
      .survey-answers-content__body__item .item__action__uploader .ant-upload {
        overflow: hidden; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/survey-plan/survey-detail/plan-detail/survey-answers/survey-answers-content/survey-answers-content.scss"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAA;EAEZ;IACE,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yBAAyB,EAAA;EAG3B;IACE,mBAAmB;IACnB,gCAAgC;IAChC,mBAAmB,EAAA;EAInB;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB,EAAA;IAHpB;MAMG,gBAAgB,EAAA;IANnB;MAUG,eAAe;MACf,gBAAgB;MAChB,kBAAkB,EAAA;MAZrB;QAeK,eAAe,EAAA;MAfpB;QAmBK,WAAW;QACX,2BAA2B,EAAA;MApBhC;QAwBK,yBAAyB;QACzB,YAAY;QACZ,kBAAkB;QAClB,cAAc;QACd,WAAW;QACX,cAAc;QACd,eAAe,EAAA;MA9BpB;QAoCO,gBAAgB,EAAA","sourcesContent":[".survey-answers-content {\n  height: 100%;\n\n  &__wrapper {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 20px;\n    border: 1px solid #D9D9D9;\n  }\n\n  &__title {\n    padding-bottom: 15x;\n    border-bottom: 1px solid #D9D9D9;\n    margin-bottom: 15px;\n  }\n\n  &__body {\n    &__item {\n      display: flex;\n      margin-bottom: 15px;\n      align-items: center;\n\n      &:last-child {\n        margin-bottom: 0;\n      }\n\n      .item__action {\n        font-size: 16px;\n        font-weight: 500;\n        margin-right: 10px;\n\n        &:last-child {\n          margin-right: 0;\n        }\n\n        &__media {\n          width: 100%;\n          height: calc(100vh - 230px);\n        }\n\n        &__file {\n          border: 1px solid #D9D9D9;\n          padding: 8px;\n          border-radius: 8px;\n          display: block;\n          width: 100%;\n          color: #1677FF;\n          cursor: pointer;\n        }\n\n        &__uploader {\n\n          .ant-upload {\n            overflow: hidden;\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
