import { Radio, Typography } from "antd";
import { AnswersPros } from "./survey-answers-content";
import { convertQuillToString } from "../../../../../../../utils/convertQuillToString";

const TrueOrFalse = (props: AnswersPros) => {
  const { question } = props;
  return <div className='survey-answers-content__wrapper'>
    <div className='survey-answers-content__title'>
      <Typography.Title level={3}>{convertQuillToString(question.question)}</Typography.Title>
    </div>
    <div className='survey-answers-content__body'>
      <div className='survey-answers-content__body__item'>
        <div className='item__action'>
          <Radio checked={false} disabled></Radio>
        </div>
        <div className='item__action'>Đúng</div>
      </div>
      <div className='survey-answers-content__body__item'>
        <div className='item__action'>
          <Radio checked={false} disabled></Radio>
        </div>
        <div className='item__action'>Sai</div>
      </div>
    </div>
  </div>
}

export default TrueOrFalse;