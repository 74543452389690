import { AxiosResponse } from "axios"
import { axiosConfigV2 } from "../../../config/api/configApiv2"

export const getMoocAcademicLevelById: (id: number, type: string) => Promise<AxiosResponse<any>> = (id, type) => {
    return axiosConfigV2.get(`/mooc-education-management/${id}?type=${type}`)
}
export const getAllAcademicLevelByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-education-management/get-all-by-filter`, data)
}

export const addMoocAcademicLevel: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-education-management`, data)
}

export const updateMoocAcademicLevel: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-education-management`, data)
}

export const deleteMoocAcademicLevel: (id: number, type: string) => Promise<AxiosResponse<any>> = (id, type) => {
    return axiosConfigV2.delete(`/mooc-education-management/${id}?type=${type}`)
}