// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-course-survey {
  height: calc(100vh - 48px);
  display: flex; }
  .editor-course-survey .card-info--wrapper {
    padding-bottom: 40px; }
  .editor-course-survey .list-wrapper {
    height: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/survey-plan/survey-detail/plan-detail/survey-answers/survey-answers.scss"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa,EAAA;EAFf;IAKI,oBAAoB,EAAA;EALxB;IASI,YAAY,EAAA","sourcesContent":[".editor-course-survey {\n  height: calc(100vh - 48px);\n  display: flex;\n\n  .card-info--wrapper {\n    padding-bottom: 40px;\n  }\n\n  .list-wrapper {\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
