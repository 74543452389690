import { Form, FormInstance, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import SettingContentCourse from "../setting-content-course";
import "./index.scss";
import TabInstructor from "./tab-instructor";
import TabSettingPublish from "./tab-setting-publish";
import {
  getCertificationCourse,
  getCostCourse,
  getInstructTeacher,
} from "../../../../service/content-course/contenCourseApi";
import { useParams } from "react-router-dom";
import TabCertificate from "./tab-certification";
import { usePreventRouteChange } from "../../../../hooks/usePreventRouteChange";
import { isEmpty } from "lodash";
import EvaluateSetting from "./tab-evalute";

type TabsSettingCommonProps = {
  form: FormInstance;
  onFinish?: (name: string, { forms, values }: any) => void;
  isCertificate: boolean;
  certificateId: number;
  setCertificateId: (value: number) => void;
  setIsCertificate: (value: boolean) => void;
  detailData: any;
  initialValues: any;
  isDirty: boolean;
  tabForm: string;
  courseStatus: number;
  courseDetail: any;
  setTabForm: (value: string) => void;
  setDirty: (value: boolean) => void;
  resetForm: () => void;
  certificateDetails: any;
};

const TabsSettingCommon = ({
  form,
  onFinish,
  isCertificate,
  certificateId,
  setCertificateId,
  setIsCertificate,
  detailData,
  initialValues,
  isDirty,
  setDirty,
  tabForm,
  setTabForm,
  resetForm,
  courseDetail,
  courseStatus,
  certificateDetails,
}: TabsSettingCommonProps) => {
  const { id } = useParams();
  const { confirmNavigation } = usePreventRouteChange();
  const [isRevoke, setIsRevoke] = useState<boolean>(false);
  const [dataConfigDetail, setDataConfigDetail] = useState({
    dataInstructorTeacher: [],
    dataCertificateCourse: [],
    dataCostCourse: [],
  });

  const methodFeeValue = Form.useWatch("isCostFree", form);

  const handleChange = (value: string) => {
    confirmNavigation({ link: "" }, false, () => {
      setTabForm(value);
    });
  };

  const getDataConfig = async (id: number) => {
    try {
      const response = await Promise.allSettled([
        getInstructTeacher(id),
        getCertificationCourse(id),
        getCostCourse(id),
      ]);
      setDataConfigDetail({
        ...dataConfigDetail,
        dataInstructorTeacher:
          response[0].status === "fulfilled" ? response[0].value.data.data : [],
        dataCertificateCourse:
          response[1].status === "fulfilled" ? response[1].value.data.data : [],
        dataCostCourse:
          response[2].status === "fulfilled" ? response[2].value.data.data : [],
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      getDataConfig(Number(id));
    }
  }, [id, isRevoke]);
  const items = [
    {
      key: "1",
      label: "Cài đặt thẻ tên",
      children: (
        <Form.Provider
          onFormFinish={onFinish}
          onFormChange={(
            formName,
            info: { changedFields: any; forms: any }
          ) => {
            if (!isEmpty(info.changedFields[0].value)) {
              setDirty(true);
            }
          }}
        >
          <Form form={form} initialValues={initialValues}>
            <SettingContentCourse
              resetForm={resetForm}
              isCertificate={isCertificate}
              certificateId={certificateId}
              dataSetting={detailData}
              form={form}
              setCertificateId={setCertificateId}
              setIsCertificate={setIsCertificate}
            />
          </Form>
        </Form.Provider>
      ),
    },
    {
      key: "2",
      label: "Cài đặt người hướng dẫn",
      children: (
        <TabInstructor
          dataInstructorTeacher={dataConfigDetail.dataInstructorTeacher}
          isDirty={isDirty}
          setDirty={setDirty}
        />
      ),
    },
    {
      key: "3",
      label: "Cài đặt xuất bản",
      children: (
        <TabSettingPublish
          formWrapper={form}
          dataCostCourse={dataConfigDetail.dataCostCourse}
          dataConfig={detailData}
          methodFeeValue={methodFeeValue}
          isDirty={isDirty}
          setDirty={setDirty}
          courseStatus={courseStatus}
          courseDetail={courseDetail}
          isRevoke={isRevoke}
          setIsRevoke={setIsRevoke}
        />
      ),
    },
    {
      key: "4",
      label: "Cài đặt chứng chỉ",
      children: (
        <TabCertificate
          dataCertificateCourse={dataConfigDetail.dataCertificateCourse}
          isDirty={isDirty}
          setDirty={setDirty}
          courseId={Number(id)}
          courseDetail={certificateDetails}
        />
      ),
    },
    {
      key: "5",
      label: "Đánh giá",
      children: <EvaluateSetting />,
    },
  ];

  return (
    <>
      <Tabs
        items={items}
        activeKey={tabForm}
        className="tabs-active-setting"
        tabPosition="left"
        onChange={handleChange}
      />
    </>
  );
};

export default TabsSettingCommon;
