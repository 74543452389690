import PageHeader from "../../../../../components/page-header/PageHeader";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Space,
  TableColumnsType,
  TableProps,
} from "antd";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import FilterBankExam from "../../../../../components/course-library/bank-exam/filterBankExam/FilterBankExam";
import "./bank-exam-list.scss";
import {
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import FilterForm from "../../../../../components/course-library/bank-exam/filterForm/FilterForm";
import "./bank-exam-list.scss";
import { routesConfig } from "../../../../../config/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { addUnitBankToStorage, getAllAuthor, getAllProductionUnit, getPageUnitBankPublic, getTopicFilter, removeStorage, useUnitBankToSequence } from "../../../../../service/library";
import { postCourseSearch } from "../../../../../service/course-construct";
import { getSectionByCourse, getSequenceBySection } from "../../../../../service/learning-course";
import { useForm, useWatch } from "antd/es/form/Form";
import AddExamCourseModal from "./AddExamModal";
import TableData from "../../../../../components/table-data/TableData";
import { formatOriginTime } from "../../../../../utils/converDate";

type BankExamListProps = {
  title: string;
};

const BankExamList = ({ title }: BankExamListProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [listDataExam, setListDataExam] = useState<any>();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [sequenceOptions, setSequenceOptions] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState<number | null>(null);
  const [isFirstTimes, setIsFirstTimes] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = useForm();
  const [dataOptions, setDataOptions] = useState<any>({
    dataTopic: [],
    dataLevel: [
      {
        id: 3,
        name: "Cấp Bộ",
      },
      {
        id: 2,
        name: "Cấp Trường",
      },
      {
        id: 1,
        name: "Cấp Giảng viên",
      },
    ],
    dataUnit: [],
    dataAuthor: [],
  });

  const [filterData, setFilterData] = useState<any>({
    topics: null,
    typeProduction: null,
    productionUnitId: null,
    authors: null,
    dateFrom: null,
    dateTo: null,
  });

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      {
        key: "0",
        label: "Thêm vào khóa học",
      },
      {
        key: "1",
        label: !record.storageStatus ? "Lưu trữ" : "Bỏ lưu trữ",
      },
    ];
    return options1;
  };


  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      ...filterData,
      topics: [],
      typeProduction: null,
      productionUnitId: null,
      authors: null,
      dateFrom: null,
      dateTo: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      fetchData();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    fetchData();
  };

  const onFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      topics: values.topic,
      typeApproved: values.type,
      typeProduction: values.level,
      productionUnitId: values.unit,
      userIds: values.author,
      dateFrom: values?.time
        ? (values?.time[0]).add(1, "day").toISOString()
        : null,
      dateTo: values?.time
        ? (values?.time[1]).add(1, "day").toISOString()
        : null,
    });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "courseName,asc"
        : sorter.order === "descend"
          ? "courseName,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleStoreBank = async (record: any) => {
    if (record.storageStatus === false) {
      try {
        await addUnitBankToStorage(record.id).then((res) => {
          if (res.status === 200) {
            message.success('Lưu trữ thành công!');
            fetchData();
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
    else {
      try {
        await removeStorage(record.id, false).then((res) => {
          if (res.status === 200) {
            message.success('Bỏ lưu trữ thành công!');
            fetchData();
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
  };


  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã đề",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Tiêu đề",
      dataIndex: "question",
      key: "question",
      width: 30,
    },
    {
      title: "Số câu hỏi",
      dataIndex: "totalQuiz",
      key: "totalQuiz",
      width: 20,
    },
    {
      title: "Chủ đề",
      dataIndex: "topic",
      key: "topic",
      width: 20,
    },
    {
      title: "Phân cấp",
      dataIndex: "typeProduction",
      key: "typeProduction",
      width: 20,
      render: (_: any, record) => {
        return (
          <>
            {record?.typeProduction === 1 ? (
              <>Cấp giảng viên</>
            ) : record?.typeProduction === 2 ? (
              <>Cấp trường</>
            ) : (
              <>Cấp bộ</>
            )}
          </>
        );
      },
    },
    {
      title: "Lượt dùng",
      dataIndex: "numberOfUse",
      key: "numberOfUse",
      width: 20,
      render: (_: any, record) => {
        return (
          <>{record?.numberOfUse ? record?.numberOfUse : 0}</>
        );
      },
    },
    {
      title: "Đơn vị xuất bản",
      dataIndex: "productionUnitName",
      key: "productionUnitName",
      width: 30,
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updateDate",
      key: "updateDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            {!isEmpty(record?.updateDate)
              ? formatOriginTime(record?.updateDate)
              : null}
          </>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record?.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigate(
                    `/${routesConfig.ministryContentModeration}/${routesConfig.previewExam}?id=${record?.id}`,
                    {
                      state: {
                        backUrl: `/${routesConfig.courseLibrary}/${routesConfig.bankExam}/${routesConfig.bankExamList}`,
                        mode: "0",
                        titleBreadCrumb: "Quản lý ngân hàng đề",
                      },
                    }
                  );
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: createOptions(record),
                  onClick: ({ key }) => {
                    if (key === "0") {
                      showModal(record?.id);

                    }
                    if (key === "1") {
                      handleStoreBank(record);
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const showModal = (bankId: number) => {
    setSelectedBankId(bankId); // Lưu bankId vào state
    fetchCourses();
    setIsModalOpen(true);
  };


  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await callUseUnitBankToSequence(values);
      setIsModalOpen(false);
    } catch (error) {
      message.error("Vui lòng kiểm tra lại các trường bắt buộc.");
    }
  };


  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // fetch data
  const fetchData = async () => {
    setLoading(true);
    const payload = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      topics: filterData.topics || [],
      // typeProduction: filterData.typeProduction,
      // productionUnitId: filterData.productionUnitId,
      dateFrom: filterData.dateFrom,
      dateTo: filterData.dateTo,
      type: "unit_quiz",
      // sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ["approvedSendDate, desc"] : [`${fieldSortOrder},${statusOrder}`],
    };

    try {
      await getPageUnitBankPublic(payload).then((res) => {
        if (res.status === 200) {
          setListDataExam(res.data?.data?.content);
          setTotalRecords(res.data?.data?.totalElements);

          setTimeout(() => {
            setLoading(false);
          }, 333);
        }
      })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // useEffect
  useEffect(() => {
    fetchData();
  }, [filterData, pageSize, pageNumber, statusOrder]);

  useEffect(() => {
    const topicId = location.state?.topicId;
    if (topicId) {
      setShowFilter(true);
      setIsFirstTimes(false);
      form.setFieldsValue({
        topic: topicId,
      });

      setFilterData({
        ...filterData,
        topics: [topicId],
      });
    }
  }, [location.state?.topicId]);

  useEffect(() => {
    Promise.all([
      getTopicFilter({ code: "topic" }),
      getAllProductionUnit({ name: null }),
      getAllAuthor({ name: null }),
    ]).then((res) => {
      const listTopic = res[0].data.data.moocPortfolioValueData;
      const listUnit = res[1].data.data.map((unit: any) => {
        return ({
          id: unit.uuid,
          name: unit.name,
        })
      });
      const listAuthor = res[2].data.data;

      setDataOptions({
        ...dataOptions,
        dataTopic: listTopic,
        dataUnit: listUnit,
        dataAuthor: listAuthor,
      });
    });
  }, []);

  const fetchCourses = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 100000,
    }
    try {
      await postCourseSearch(payload).then((res) => {
        if (res.status === 201) {
          setCourseOptions(res.data.data.data);
        }
      })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  const courseId = useWatch("course", form);
  useEffect(() => {
    form.setFieldsValue({
      section: null,
      sequence: null,
    })
    if (courseId) {
      fetchSection(courseId);
    }
  }, [courseId])

  const fetchSection = async (id: number) => {
    try {
      await getSectionByCourse(id).then((res) => {
        if (res.status === 200) {
          setSectionOptions(res.data);
        }
      })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const sectionId = useWatch("section", form);
  useEffect(() => {
    form.setFieldsValue({
      sequence: null,
    })
    if (sectionId) {
      fetchSequence(sectionId);
    }
  }, [sectionId])

  const fetchSequence = async (id: number) => {
    try {
      await getSequenceBySection(id).then((res) => {
        if (res.status === 200) {
          setSequenceOptions(res.data);
        }
      })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const callUseUnitBankToSequence = async (values: any) => {
    const payload = {
      sequenceId: values.sequence,
      bankId: selectedBankId,
    };
    try {
      await useUnitBankToSequence(payload);
      message.success("Đề đã được thêm thành công!");
    } catch (err) {
      message.error("Có lỗi xảy ra khi thêm đề.");
      console.error(err);
    }
  };


  return (
    <Layout className="page-header-group bank-exam-list">
      <Form.Provider onFormFinish={(name, { values, forms }) => { }}>
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterBankExam
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FilterForm
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={onFinish}
            showFilter={showFilter}
            form={form}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataTable={listDataExam}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 800 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <AddExamCourseModal onCancel={handleCancel} onFinish={handleOk} open={isModalOpen} courseOptions={courseOptions} sectionOptions={sectionOptions} sequenceOptions={sequenceOptions} form={form} />
      </Form.Provider>
    </Layout>
  );
};

export default BankExamList;
