import React, { useEffect, useState } from 'react'
import TextEditor from '../../../text-editor/TextEditor'
import { SortableList } from '../../../SortableList'
import { Button, Row, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useAddLesson } from '../../../../stores/stores'
import { initialChooseConnectTextQuestionValue } from '../../../../constants'
import { ConnectQuestionData, QuestionConnectItemDetail } from '../../../../types/course-construction';
import { findMaxIndexOfArray, mapConnectAnsBEtoFE } from '../../../../utils/arrays';
import './style.scss';
import QuestionConnect from './QuestionConnect';
import FormInputText from '../../../form-input-text/FormInputText'
import { renderQuestionNameByType, renderQuestionDescriptionByType } from '../../../../utils/functionUltils'

const Connect = ({ dataFromApi = {} as ConnectQuestionData }: QuestionConnectItemDetail) => {
  const question = useAddLesson((state: any) => state.question);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const [answers, setAnswers] = useState(initialChooseConnectTextQuestionValue);
  const handleChangeText = (current: {
    id: number;
    content: {
      left: { content: string },
      right: { content: string },
    }
  }) => {
    const distAnswer = [...answers];
    const indexOfChangeItem = distAnswer.findIndex(
      (item) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      distAnswer[indexOfChangeItem] = {
        ...distAnswer[indexOfChangeItem],
        content: {
          left: { content: current.content.left.content },
          right: { content: current.content.right.content },
        },
      };
      setAnswers(distAnswer);
    }
  }

  const handleRemoveOneChoose = (id: number) => {
    if (id) {
      const newList = [...answers];
      setAnswers(newList.filter((item) => item.id !== id));
    } else console.error("Cannot find id");
  }

  const handleAddMoreChoose = () => {
    if (answers.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(answers) + 1,
        content: {
          left: {
            content: "",
          },
          right: {
            content: "",
          },
        },
        orderNumber: findMaxIndexOfArray(answers) + 1,
        point: 0,
        response: "",
        isDisplayContent: false,
        filePath: "",
        fileName: "",
        isTrue: true,
      };
      const newList = [...answers];
      setAnswers(newList.concat([newItem]));
    } else setAnswers(initialChooseConnectTextQuestionValue);
  }

  useEffect(() => {
    setCurrentQuestionInfo({ ...currentQuestionInfo, ...answers, });
  }, [answers]);

  useEffect(() => {
    if (dataFromApi.answers && dataFromApi.answers?.length > 0) {
      const feAnswer = mapConnectAnsBEtoFE(dataFromApi.answers);
      setAnswers(feAnswer);
    } else {
      setAnswers([])
    }
  }, [dataFromApi.answers]);

  const handleChangeQuestionName = (qName: string) => {
    setCurrentQuestionInfo({ ...currentQuestionInfo, questionName: qName, });
  };

  const handleDragChange = (value: any) => {
    setAnswers(value);
  };

  const handleSaveReminderText = (value: any) => {
    setCurrentQuestionInfo({ ...currentQuestionInfo, reminderText: value, });
  };

  return (
    <section className='question-wrapper'>
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={question.question} onChange={handleChangeQuestionName} />
      <FormInputText
        value={dataFromApi.reminderText}
        onChange={handleSaveReminderText}
        placeholder="Nhập nội dung lời nhắc"
        autoSize={{ minRows: 1, maxRows: 6 }}
        containerClassName="w-full"
        label=""
        type="textarea"
      />
      <SortableList
        items={answers}
        onChange={handleDragChange}
        renderItem={(record: any) => (
          <SortableList.Item id={record.id}>
            <SortableList.DragHandle />
            <QuestionConnect
              index={record.id}
              content={record.content}
              onChange={handleChangeText}
              onDelete={handleRemoveOneChoose}
            />
          </SortableList.Item>
        )}
      />
      <Row className="add-answer-row mt-1">
        <Button
          icon={<PlusOutlined className="size-22" />}
          className="add-answer-btn"
          onClick={handleAddMoreChoose}
        >
          Thêm cặp lựa chọn kết nối
        </Button>
      </Row>
      <div style={{ maxWidth: 400, margin: "30px auto" }}></div>
    </section>
  )
}

export default Connect;
