import { ReactNode } from "react";

export interface DataType {
    id: number,
    name: string,
    code: string,
    nameEn: string,
    fullName: string,
    previousCode: string,
    fullNameEn: string,
    codeName: string,
    createdDate: string,
    numericalOrder: ReactNode,
}

export const dataStatus = [
  {
    value: true,
    label: "Hoạt động"
  },
  {
    value: false,
    label: "Không hoạt động"
  }
]

export const validateFieldCreate = ["name", "fullName", "code", "codeName", "nameEn", "fullNameEn", "province", "district"]

export const validateFieldFilter = []
