import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Card, Col, Empty, Row, Spin, Statistic } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import "./overview-chart-tab.scss";
import StatisticalReportCard from "../../StatisticalReportCard";
import ComboDualAxesChart from "../../../../../../components/types-of-charts/ComboDualAxesChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import { BarStackedChartProps, DualChart, PieChart } from "../../../../../../types/static-report";
import useChartExport from "../../../../for-instructors/components/chart/useChartExport";
import { staticReportStore } from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>;
  visitsAndVisitors: DualChart[];
  visitsAndVisitorsByRegister: PieChart[];
  universityRelationList: Array<any>;
  teacherByUniversity: BarStackedChartProps[];
  studentByUniversity: BarStackedChartProps[];
  courseByUniversitySponsor: BarStackedChartProps[];
  courseByUniversitySponsorTotal: number;
  unitResources: Array<any>;
  synthesizeDataUniversity: any;
};

const OverviewChartTab = (props: Props) => {
  const {
    loading,
    visitsAndVisitors,
    visitsAndVisitorsByRegister,
    universityRelationList,
    teacherByUniversity,
    studentByUniversity,
    courseByUniversitySponsor,
    courseByUniversitySponsorTotal,
    unitResources,
    synthesizeDataUniversity
  } = props;

  const [dataStatistical, setDataStatistical] = useState({
    totalVisit: 0,
    rateVisit: 0,
    totalAffiliatedUniversity: 0,
    rateAffiliatedUniversity: 0,
    totalLecturer: 0,
    rateLecturer: 0,
    totalStudent: 0,
    rateStudent: 0,
    totalCourse: 0,
    rateCourse: 0,
    totalResource: 0,
    rateResource: 0,
  });

  const [totalColumnValue, setTotalColumnValue] = useState(0);
  const [totalLineValue, setTotalLineValue] = useState(0);
  const [totalSeries, setTotalSeries] = useState<any>([]);

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  useEffect(() => {
    const visit: any = synthesizeDataUniversity.find((item: any) => item?.criteria === "Lượt truy cập");
    const affiliatedUniversity: any = synthesizeDataUniversity.find((item: any) => item?.criteria === "Trường liên kết");
    const lecturer: any = synthesizeDataUniversity.find((item: any) => item?.criteria === "Giảng viên");
    const student: any = synthesizeDataUniversity.find((item: any) => item?.criteria === "Học viên");
    const course: any = synthesizeDataUniversity.find((item: any) => item?.criteria === "Khóa học");
    const resource: any = synthesizeDataUniversity.find((item: any) => item?.criteria === "Tài nguyên");
    
    setDataStatistical({
      totalVisit: visit?.count,
      rateVisit: typeof visit?.percentage === 'number' ? visit.percentage.toFixed(2) : '0',
      totalAffiliatedUniversity: affiliatedUniversity?.count,
      rateAffiliatedUniversity: typeof affiliatedUniversity?.percentage === 'number' ? affiliatedUniversity.percentage.toFixed(2) : '0',
      totalLecturer: lecturer?.count,
      rateLecturer: typeof lecturer?.percentage === 'number' ? lecturer.percentage.toFixed(2) : '0',
      totalStudent: student?.count,
      rateStudent: typeof student?.percentage === 'number' ? student.percentage.toFixed(2) : '0',
      totalCourse: course?.count,
      rateCourse: typeof course?.percentage === 'number' ? course.percentage.toFixed(2) : '0',
      totalResource: resource?.count,
      rateResource: typeof resource?.percentage === 'number' ? resource.percentage.toFixed(2) : '0',
    });
  }, [synthesizeDataUniversity])

  useEffect(() => {
    let colTotal = 0;
    let lineTotal = 0;
    visitsAndVisitors.map((item) => {
      colTotal += item.columnValue;
      lineTotal += item.lineValue;
    });
    setTotalColumnValue(colTotal);
    setTotalLineValue(lineTotal);
  },[visitsAndVisitors]);

  useEffect(() => {
    let timesTotal = 0;
    let usersTotal = 0;
    visitsAndVisitorsByRegister.map((item: any) => {
      timesTotal += item?.values[0].count;
      usersTotal += item?.values[1].count;
    });
    setTotalSeries([timesTotal, usersTotal]);
  },[visitsAndVisitors]);

  const renderStatistical = () => {
    return (
      <>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Lượt truy cập"
              value={get(dataStatistical, 'totalVisit', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateVisit = get(dataStatistical, 'rateVisit', 0);
                const isNegative = rateVisit < 0;
                const absRateVisit = Math.abs(rateVisit);
                return (
                  <>
                    {value}
                    <span className="percentage-difference">
                      {absRateVisit}% 
                      {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                      ) : (
                        <CaretUpOutlined style={{ color: "#52C41A" }} />
                      )}
                    </span>
                  </>
                );
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Trường liên kết"
              value={get(dataStatistical, 'totalAffiliatedUniversity', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateAffiliatedUniversity = get(dataStatistical, 'rateAffiliatedUniversity', 0);
                const isNegative = rateAffiliatedUniversity < 0;
                const absRateAffiliatedUniversity = Math.abs(rateAffiliatedUniversity);
                return (
                  <>
                    {value}
                    <span className="percentage-difference">
                      {absRateAffiliatedUniversity}% 
                      {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                      ) : (
                        <CaretUpOutlined style={{ color: "#52C41A" }} />
                      )}
                    </span>
                  </>
                );
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Giảng viên"
              value={get(dataStatistical, 'totalLecturer', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateLecturer = get(dataStatistical, 'rateLecturer', 0);
                const isNegative = rateLecturer < 0;
                const absRateLecturer = Math.abs(rateLecturer);
                return (
                  <>
                    {value}
                    <span className="percentage-difference">
                      {absRateLecturer}% 
                      {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                      ) : (
                        <CaretUpOutlined style={{ color: "#52C41A" }} />
                      )}
                    </span>
                  </>
                );
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Học viên"
              value={get(dataStatistical, 'totalStudent', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateStudent = get(dataStatistical, 'rateStudent', 0);
                const isNegative = rateStudent < 0;
                const absRateStudent = Math.abs(rateStudent);
                return (
                  <>
                    {value}
                    <span className="percentage-difference">
                      {absRateStudent}% 
                      {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                      ) : (
                        <CaretUpOutlined style={{ color: "#52C41A" }} />
                      )}
                    </span>
                  </>
                );
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Khóa học"
              value={get(dataStatistical, 'totalCourse', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateCourse = get(dataStatistical, 'rateCourse', 0);
                const isNegative = rateCourse < 0;
                const absRateCourse = Math.abs(rateCourse);
                return (
                  <>
                    {value}
                    <span className="percentage-difference">
                      {absRateCourse}% 
                      {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                      ) : (
                        <CaretUpOutlined style={{ color: "#52C41A" }} />
                      )}
                    </span>
                  </>
                );
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Tài nguyên"
              value={get(dataStatistical, 'totalResource', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateResource = get(dataStatistical, 'rateResource', 0);
                const isNegative = rateResource < 0;
                const absRateResource = Math.abs(rateResource);
                return (
                  <>
                    {value}
                    <span className="percentage-difference">
                      {absRateResource}% 
                      {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                      ) : (
                        <CaretUpOutlined style={{ color: "#52C41A" }} />
                      )}
                    </span>
                  </>
                );
              }}
            />
          </Card>
        </Col>
      </>
    )
  }

  const listColors = ["#877676", "#f0c7c7", "#FF7875", "#FFC069", "#5CDBD3", "#69C0FF", "#B37FEB", "#5e21b5", "#e7ed24"];

  return (
    <>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        {renderStatistical()}
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượt truy cập và số người truy cập"
            content={
              <Spin spinning={loading.visitsAndVisitors}>
                {visitsAndVisitors.length > 0 ? (
                  <ComboDualAxesChart
                    data={visitsAndVisitors}
                    columnName={`Số người truy cập (${totalColumnValue})`}
                    columnColor="#69C0FF"
                    lineName={`Số lượt truy cập (${totalLineValue})`}
                    lineColor="#FF7875"
                    hasTotal={false}
                    yAxisOpposite={true}
                    ref={createChartRef(tab, "Số lượt truy cập và số người truy cập")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượt truy cập và số người truy cập", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượt truy cập và số người truy cập",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Đối tượng truy cập"
            content={
              <Spin spinning={loading.visitsAndVisitorsByRegister}>
                {visitsAndVisitorsByRegister.length > 0 ? (
                  <BarChart
                    data={visitsAndVisitorsByRegister}
                    colors={["#69C0FF", "#FFC069"]}
                    seriesTotal={totalSeries.length > 0 ? totalSeries : null}
                    showLegend={true}
                    hasTotal={false}
                    ref={createChartRef(tab, "Đối tượng truy cập")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Đối tượng truy cập", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Đối tượng truy cập",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng trường liên kết"
            content={
              <Spin spinning={loading.universityRelationList}>
                {universityRelationList.length > 0 ? (
                  <BarChart
                    data={universityRelationList}
                    colors={["#69C0FF"]}
                    showLegend={false}
                    hasTotal={true}
                    ref={createChartRef(tab, "Số lượng trường liên kết")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng trường liên kết", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng trường liên kết",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng giảng viên"
            content={
              <Spin spinning={loading.teacherByUniversity}>
                {teacherByUniversity.length > 0 ? (
                  <BarStackedChart
                    data={teacherByUniversity}
                    colors={listColors}
                    hasTotal={true}
                    ref={createChartRef(tab, "Số lượng giảng viên")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng giảng viên", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng giảng viên",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng học viên"
            content={
              <Spin spinning={loading.studentByUniversity}>
                {studentByUniversity.length > 0 ? (
                  <BarStackedChart
                    data={studentByUniversity}
                    colors={["#5CDBD3", "#69C0FF"]}
                    hasTotal={true}
                    ref={createChartRef(tab, "Số lượng học viên")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng học viên", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng học viên",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo trường"
            content={
              <Spin spinning={loading.courseByUniversitySponsor}>
                {courseByUniversitySponsor.length > 0 ? (
                  <BarStackedChart
                    data={courseByUniversitySponsor}
                    colors={["#5CDBD3", "#69C0FF"]}
                    hasTotal={true}
                    totalData={courseByUniversitySponsorTotal}
                    ref={createChartRef(tab, "Số lượng khóa học theo trường")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng khóa học theo trường", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng khóa học theo trường",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng tài nguyên"
            content={
              <Spin spinning={loading.unitResources}>
                {unitResources.length > 0 ? (
                  <DonutChart
                    data={unitResources}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#69C0FF", "#5CDBD3", "#FFC069", "#FF7875"]}
                    ref={createChartRef(tab, "Số lượng tài nguyên")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng tài nguyên", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng tài nguyên",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default OverviewChartTab;
