import { Tooltip } from "antd";
import parse from "html-react-parser";
import * as htmlToImage from 'html-to-image';
import React, { useEffect, useRef, useState } from "react";
import { getDetailCertificate } from "../../../service/certificate";
import "./index.scss";

const CertificatePageViewer: React.FC = () => {
  const [html, setHTML] = useState<string>("");
  const [css, setCSS] = useState<string>("");
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isImageReady, setIsImageReady] = useState(false);
  const certificateRef = useRef<HTMLDivElement>(null);
  const [deviceType, setDeviceType] = useState<"pc" | "tablet" | "mobile">("pc");

  useEffect(() => {
    const fetchTemplate = async () => {
      const currentUrl = window.location.href;
      const id = currentUrl.split("/").pop();

      try {
        const res = await getDetailCertificate(id);
        const template = JSON.parse(res.data.data.template);
        setHTML(template.html);
        setCSS(template.css);

        const style = document.createElement("style");
        style.innerHTML = template.css;
        document.head.appendChild(style);
      } catch (error) {
        console.error("Error fetching template:", error);
      }
    };

    fetchTemplate();
  }, []);

  const convertToImage = () => {
    const node = document.getElementById("certificate");
    if (node) {
      htmlToImage
        .toPng(node, {
          filter: (node) => node.tagName !== "SCRIPT",
          width: node.offsetWidth * 3,
          height: node.offsetHeight * 3,
          style: {
            transform: "scale(3)",
            transformOrigin: "top left",
          },
        })
        .then((dataUrl) => {
          setImageSrc(dataUrl);
          setIsImageReady(true);
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        });
    } else {
      console.error("Element with id 'certificate' not found");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      convertToImage();
    }, 200);
  }, []);

  const replaceComponents = (domNode: any) => {
    if (domNode.attribs && domNode.attribs["component-class"]) {
      const props = domNode.attribs;
      switch (domNode.attribs["component-class"]) {
        case "student-name-certificate":
          return <p {...props}>Tên học viên</p>;
        case "course-name-certificate":
          return <p {...props}>TÊN KHOÁ HỌC</p>;
        case "date-certificate":
          return <p {...props}>Ngày cấp</p>;
        case "signature-teacher-certificate":
          return <p {...props}>Chữ kí</p>;
        default:
          return null;
      }
    }
  };
  const resizePage = (type: "pc" | "tablet" | "mobile") => {
    setDeviceType(type);
  };
  const getDeviceStyles = () => {
    switch (deviceType) {
      case "pc":
        return null
      case "tablet":
        return { width: "768px !important", height: "auto" };
      case "mobile":
        return { width: "375px !important", height: "auto" };
      default:
        return {};
    }
  };

  return (

    <>
      <div className="flex justify-center gap-3">
        <Tooltip title="PC">
          <svg
            style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
            viewBox="0 0 24 24"
            onClick={() => resizePage("pc")}
          >
            <path
              fill="#8b8b8b"
              d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"
            ></path>
          </svg>
        </Tooltip>
        <Tooltip title="Tablet">
          <svg
            style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
            viewBox="0 0 24 24"
            onClick={() => resizePage("tablet")}
          >
            <path
              fill="#8b8b8b"
              d="M19,18H5V6H19M21,4H3C1.89,4 1,4.89 1,6V18A2,2 0 0,0 3,20H21A2,2 0 0,0 23,18V6C23,4.89 22.1,4 21,4Z"
            ></path>
          </svg>
        </Tooltip>
        <Tooltip title="Mobile">
          <svg
            style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
            viewBox="0 0 24 24"
            onClick={() => resizePage("mobile")}
          >
            <path
              fill="#8b8b8b"
              d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z"
            ></path>
          </svg>
        </Tooltip>
      </div>
      <div className="flex align-center justify-center container mt-2 mx-auto">
        {!isImageReady && (
          <div
            ref={certificateRef}
            id="certificate"
          // style={{
          //   position: "relative",
          //   // ...getDeviceStyles(),
          //   display: isImageReady ? "none" : "inline-block",
          // }}
          >
            {parse(html, { replace: replaceComponents })}
          </div>
        )}

        {imageSrc && isImageReady && (
          <img
            src={imageSrc}
            alt="Generated Certificate"
            style={{
              ...getDeviceStyles(),
              maxHeight: "calc(100vh - 62px)",
              width: "297mm",
              height: "210mm"
            }}
          />
        )}
      </div></>
  );
};

const buttonStyle = {
  padding: "10px 20px",
  backgroundColor: "#4CAF50",
  color: "#fff",
  border: "none",
  cursor: "pointer",
};

export default CertificatePageViewer;
