import React, { useEffect, useState } from "react";
import { Button, Form, Popover, Spin } from "antd";

import {
  HoursGlassIcon,
  NextIcon,
  PreviousIcon,
  QuestionMarkIcon,
} from "../../../../components/icons/svg";
import { toNumber } from "lodash";
import { QuestionTypeEnum } from "../../../../constants";
import {
  QuestionBeProps,
  QuizProps,
  QuizRequest,
} from "../../../../types/course";
import { addOrUpdateQuizRequest } from "../../../../utils/arrays";
import EmptyComponent from "../../../../components/empty";
import FillText from "../../../../pages/preview-quiz/structure/quiz/fill-text/FillText";
import Mp3 from "../../../../pages/preview-quiz/structure/quiz/mp3";
import VideoRecorder from "../../../../pages/preview-quiz/structure/quiz/video";
import FileUpload from "../../../../pages/preview-quiz/structure/quiz/file-upload";
import LongText from "../../../../pages/preview-quiz/structure/quiz/long-text";
import ShortText from "../../../../pages/preview-quiz/structure/view-results/short-text";
import Connect from "../../../../pages/preview-quiz/structure/quiz/connect";
import ChooseMultiVideo from "../../../../pages/preview-quiz/structure/quiz/choose-multi-video";
import ChooseMultiImage from "../../../../pages/preview-quiz/structure/quiz/choose-multi-image";
import ChooseMultiText from "../../../../pages/preview-quiz/structure/quiz/choose-multi-text";
import Dropdown from "../../../../pages/preview-quiz/structure/quiz/drop-down";
import TrueOrFalse from "../../../../pages/preview-quiz/structure/quiz/true-or-false";
import ChooseVideo from "../../../../pages/preview-quiz/structure/quiz/choose-video";
import ChooseImage from "../../../../pages/preview-quiz/structure/quiz/choose-image";
import ChooseText from "../../../../pages/preview-quiz/structure/quiz/choose-text/ChooseText";

interface ExamLayoutProps {
  quiz?: any;
  loading?: boolean;
  isPausing?: boolean;
  disableNextBtn?: boolean;
  disablePrevBtn?: boolean;
  quizzes: QuizProps[];
  quizzesCompleted?: QuizRequest[];
  isTrainingQuizHasTime?: boolean;
  timeOfCurrentQuiz?: number;
  quizzesHasTimeCompleted?: number[];
  onPause?: () => void;
  onFinish?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onChange?: (data: QuizRequest[]) => void;
  onFinishTrainingQuizHasTime?: () => void;
  disableOption?: boolean
}

function ExamLayout({
  quiz,
  loading = false,
  quizzes = [],
  disableNextBtn = false,
  disablePrevBtn = false,
  quizzesCompleted,
  isTrainingQuizHasTime = false,
  isPausing = false,
  timeOfCurrentQuiz = 0,
  quizzesHasTimeCompleted = [],
  onPause = () => {},
  onFinish = () => {},
  onNext = () => {},
  onPrevious = () => {},
  onChange = () => {},
  onFinishTrainingQuizHasTime = () => {},
  disableOption= false
}: ExamLayoutProps) {
  const [form] = Form.useForm();
  const data = {
    ...quiz,
    questions:
      quiz?.answers !== undefined
        ? typeof quiz?.answers !== 'string'
          ? quiz?.answers
          : JSON.parse(quiz?.answers)
        : "",
  };
  const type = data?.type;
  const [fieldValues, setFieldValues] = useState<QuizRequest[]>([]);
  const disabledQuiz =
    quizzesHasTimeCompleted?.includes(data?.id) && isTrainingQuizHasTime;

  //   useEffect(() => {
  //     setFieldValues(quizzesCompleted);
  //   }, []);

  const initialData = () => {
    const isTrueAnswer = data?.questions?.find(
      (item: QuestionBeProps) => item.isTrue === true
    );
    return isTrueAnswer.uuid;
  };

  const handleInitData = () => {
    const isTrueAnswer = data?.questions?.find(
      (item: QuestionBeProps) => item.isTrue === true
    );
    return isTrueAnswer.uuid;
  };

  const renderQuestionByType = () => {
    switch (type) {
      case QuestionTypeEnum.CHOOSE_TEXT:
        return (
          <ChooseText
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_IMAGE:
        return (
          <ChooseImage
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_VIDEO:
        return (
          <ChooseVideo
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.TRUE_OR_FALSE:
        return (
          <TrueOrFalse
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.DROP_DOWN:
        return (
          <Dropdown
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
        return (
          <ChooseMultiText
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
        return (
          <ChooseMultiImage
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
        return (
          <ChooseMultiVideo
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.CONNECT: //done push value
        return (
          <Connect
            disabled={disabledQuiz|| disableOption}
            data={data}
            onChange={handleChangeValues}
            // initialData={initialData()}
          />
        );

      case QuestionTypeEnum.SHORT_TEXT:
        return (
          <ShortText
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.LONG_TEXT:
        return (
          <LongText
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        ); //done push value

      case QuestionTypeEnum.FILE_UPLOAD: //done push value
        return (
          <FileUpload
            disabled={disabledQuiz|| disableOption}
            form={form}
            data={data}
            onChange={handleChangeValues}
            // initialData={initialData()}
          />
        );

      case QuestionTypeEnum.VIDEO: //done push value
        return (
          <VideoRecorder
            disabled={disabledQuiz|| disableOption}
            data={data}
            onChange={handleChangeValues}
            // initialData={initialData()}
          />
        );

      case QuestionTypeEnum.MP3: //done push value
        return (
          <Mp3
            disabled={disabledQuiz|| disableOption}
            data={data}
            onChange={handleChangeValues}
            // initialData={initialData()}
          />
        );

      case QuestionTypeEnum.FillText: //done push value
        return (
          <FillText
            disabled={disabledQuiz|| disableOption}
            data={data}
            // initialData={initialData()}
          />
        );

      default:
        <p>Xin vui lòng chọn câu hỏi</p>;
        break;
    }
  };

  useEffect(() => {
    onChange(fieldValues);
  }, [fieldValues]);

  const handleChangeValues = (fields: any) => {
    const fieldKey = Object.keys(fields)[0];
    const fieldValue = Object.values(fields);
    if (fieldKey) {
      const fieldInfo = fieldKey?.split("-");
      // default quiz payload
      let quizReq: QuizRequest = {
        type: toNumber(fieldInfo[2]),
        quizId: toNumber(fieldInfo[1]),
        questionType: fieldInfo[3],
        answer: fieldValue,
      };
      const type = toNumber(fieldInfo[2]);
      const quizId = toNumber(fieldInfo[1]);
      if (
        type === QuestionTypeEnum.CHOOSE_MULTI_TEXT ||
        type === QuestionTypeEnum.CHOOSE_MULTI_IMAGE ||
        type === QuestionTypeEnum.CHOOSE_MULTI_VIDEO
      ) {
        const current = quizzes.find((quiz: QuizProps) => quiz.id === quizId);

        if (current) {
          quizReq = {
            type: type,
            quizId: quizId,
            questionType: fieldInfo[3],
            answer: JSON.parse(current?.answers as string)
              .map((quest: QuestionBeProps) => quest.uuid)
              .filter(
                (_: QuestionBeProps, index: number) => !!fieldValue[index]
              ),
          };
        }
      }
      if (type === QuestionTypeEnum.CONNECT) {
        quizReq = {
          type: toNumber(fieldInfo[2]),
          quizId: toNumber(fieldInfo[1]),
          questionType: fieldInfo[3],
          answer: fieldValue[0],
        };
      }
      setFieldValues((prevState: QuizRequest[]) =>
        addOrUpdateQuizRequest(prevState, quizReq)
      );
    }
  };

  return data ? (
    <div className="quiz-layout flex flex-column">
      <div className="question-content flex-1">
        <Spin spinning={loading}>
          <div className="question-header">
            <div className="question-title font-size-16 line-height-24 font-weight-6 flex gap-8 mt-1">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${
                    data?.question
                      ? data?.question
                      : "Xin vui lòng chọn câu hỏi"
                  }`,
                }}
              ></div>
            </div>
          </div>
          <div className="question-display-content pt-2 flex-1">
            <Form
              form={form}
              onValuesChange={(v, value) => handleChangeValues(value)}
            >
              {renderQuestionByType()}{" "}
            </Form>
          </div>
        </Spin>
      </div>

      {quizzes?.length > 0 ? (
        <div className="quiz-layout-footer">
          <div className="btn-group-right flex gap-12">
            <Button
              className="btn-paginate center h-36"
              onClick={onPrevious}
              disabled={disablePrevBtn}
            >
              <PreviousIcon />
            </Button>
            <Button
              className="btn-paginate center h-36"
              onClick={onNext}
              disabled={disableNextBtn}
            >
              <NextIcon />
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <div className="center">
      <EmptyComponent description="Không có nội dungggg" />
    </div>
  );
}

export default ExamLayout;
