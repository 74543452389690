// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; }
  .view-wrapper .view-content,
  .view-wrapper iframe {
    max-height: calc(100vh - 132px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/learning-materials/components/view/View.scss"],"names":[],"mappings":"AAAA;EACC,kBAAkB;EAClB,WAAW;EACX,sBAAsB,EAAA;EAHvB;;IAOE,+BAA+B;IAC/B,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY,EAAA","sourcesContent":[".view-wrapper {\n\tposition: relative;\n\twidth: 100%;\n\tpadding-bottom: 56.25%;\n\n\t.view-content,\n\tiframe {\n\t\tmax-height: calc(100vh - 132px);\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
