import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PageHeader from "../../../../page-header/PageHeader";
import "./create-question.scss";
import SafeLink from "../../../../link/SafeLink";
import { routesConfig } from "../../../../../config/routes";
import AddQuestionCourseModal from "../../../../modal/add-question-course";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import {
  getAllEnterpriseCustomer,
  getAllmoocFacilityAccount,
} from "../../../../../service/list-account-users/listAccountUsersApi";
import { get, isEmpty } from "lodash";
import { useAddLesson, useAuthStore } from "../../../../../stores/stores";
import { mapAnsFEtoBE } from "../../../../../utils/arrays";
import { ModeQuestion, QuestionTypeEnum, TypeCreateQuestion } from "../../../../../constants";
import { HelpService } from "../../../../../service/helper.service";
import { convertQuillToString } from "../../../../../utils/convertQuillToString";
import {
  getQuestionDetail,
  postCreateQuestionBank,
  postCreateQuestionBankConnect,
  putUpdateQuestionBank,
  putUpdateQuestionBankConnect,
} from "../../../../../service/question-bank";
import { useLocation, useNavigate } from "react-router-dom";
import ChooseText from "../../../../course-construction/question-item/choose-text";
import ChooseImage from "../../../../course-construction/question-item/choose-image";
import ChooseVideo from "../../../../course-construction/question-item/choose-video";
import TrueOrFalse from "../../../../course-construction/question-item/true-or-false";
import DropDown from "../../../../course-construction/question-item/drop-down";
import ChooseMultiText from "../../../../course-construction/question-item/choose-multi-text";
import ChooseMultiImage from "../../../../course-construction/question-item/choose-multi-image";
import ShortText from "../../../../course-construction/question-item/short-text";
import FileUpload from "../../../../course-construction/question-item/file-upload";
import Video from "../../../../course-construction/question-item/choose-video";
import Mp3 from "../../../../course-construction/question-item/mp3";
import {
  ConnectQuestionData,
  QuestionData,
} from "../../../../../types/course-construction";
import Connect from "../../../../course-construction/question-item/connect";
import { getTopicFilter } from "../../../../../service/library";
import ChooseMultiVideos from "../../../../course-construction/question-item/choose-multi-video";
import { getAllAccountTeacher, getAllmoocTeacherAccount } from "../../../../../service/list-account-teachers/listAccountTeachersApi";

const imgPath = "/images/";
const { Content } = Layout;
const { Option } = Select;
type CreateQuestionProps = {
  title: string;
};
const CreateQuestion = ({ title }: CreateQuestionProps) => {
  const navigate = useNavigate();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const question = useAddLesson((state: any) => state.question);
  const setQuestion = useAddLesson((state: any) => state.setQuestion);
  const [codeQuestion, setCodeQuestion] = useState("");
  const [form] = Form.useForm();
  const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);
  const [selectedQuestionType, setSelectedQuestionType] = useState<
    string | null | Number
  >(null);
  const setCurrentQuestionInfo = useAddLesson(
    (state) => state.setCurrentQuestionInfo
  );
  const [questionData, setQuestionData] = useState<QuestionData>(
    {} as QuestionData
  );
  const [questionFormat, setQuestionFormat] = useState<QuestionData>(
    {} as QuestionData
  );
  const [connectQuestionData, setConnectQuestionData] =
    useState<ConnectQuestionData>({} as ConnectQuestionData);
  const setTypeCreateQuestion = useAddLesson(
    (state) => state.setTypeCreateQuestion
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [loadingQuestion, setLoadingQuestion] = useState<boolean>(false);
  const userInfo = useAuthStore((state) => state.user);
  const universityID = userInfo?.enterpriseUUID || null;
  useEffect(() => {
    if (!id) {
      setQuestion({});
    }
  }, [id]);

  const setModeQuestionStore = useAddLesson((state) => state.setModeQuestion);
  const [modeQuestion, setModeQuestion] = useState("");

  const handleChangeFormatData = (data: any) => {
    const { answer, ...rest } = data;
    const updatedObj = { ...rest, answers: answer };
    setQuestionFormat(updatedObj);
  };

  const handleGetDetailQuestion = async () => {
    setLoadingQuestion(true);
    try {
      const res = await getQuestionDetail(id).then((result) => {
        setQuestionData(result.data.data);
        setQuestion(
          new Object({
            type: result.data.data.type,
            question: result.data.data.question,
            courseBlockGroupId: id,
          })
        );
        setCodeQuestion(result.data.data.code);
        form.setFieldValue("codeQuestion", result.data.data.code);
        form.setFieldValue("level", result.data.data.level);
        form.setFieldValue("authors", result.data.data.authors);
        form.setFieldValue("tags", result.data.data.tags);
        form.setFieldValue("topic", result.data.data.topics);

        setLoadingQuestion(false);
        handleChangeFormatData(result.data.data);
        // setCurrentQuestionInfo({
        //   ...result.data.data.answers,
        //   questionName: result.data.data.questionName,
        // });
        setSelectedQuestionType(result.data.data.type);

        // code: value.codeQuestion,
        // level: value.level,
        // authors: value.authors,
        // tags: value.tags,
        // topic: value.topic,
      });
    } catch (error) {
      console.error(error);
      setLoadingQuestion(false);
    }
  };

  useEffect(() => {
    if (id) handleGetDetailQuestion();
    if (location.pathname.includes(routesConfig.createQuestionRepository)) {
      setTypeCreateQuestion(TypeCreateQuestion.QUESTIONBANK);
    }
  }, []);

  useEffect(() => {
    setModeQuestion(id ? "update" : "create");
    setModeQuestionStore(id ? "update" : "create");
  }, [id]);

  const currentQuestionInfo = useAddLesson(
    (state) => state.currentQuestionInfo
  );
  const handleAddQuestion = () => {
    setAddQuestionModal(true);
  };
  const handleSelectQuestion = () => {
    setQuestion({});
    const updatedObj = { ...questionFormat, answers: [] };
    setQuestionFormat(updatedObj);
  };
  const handleSave = () => {
    form.submit();
  };

  const listBreadcrumb = [
    {
      title: "Thư viện",
    },
    {
      title: "Kho đề cá nhân",
    },
    {
      title: (
        <SafeLink
          to={`/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listQuestionRepository}`}
        >
          <span>Danh sách câu hỏi</span>
        </SafeLink>
      ),
    },
    {
      title: modeQuestion === "create" ? "Thêm câu hỏi" : `${codeQuestion}`,
    },
  ];
  const helpService = new HelpService();
  const [dataOptions, setDataOptions] = useState({
    dataTopic: [],
    dataPermission: [],
    dataStatus: [],
    dataUsers: [],
  });

  const dataTags = [
    { code: "tag 1", name: "Tag 1" },
    { code: "tag 2", name: "Tag 2" },
  ];

  const handleGetActiveTopic = (topics: any) => {
    const activeTopics = topics.filter((item: { status: any }) => item.status);
    return activeTopics.map((value: { name: string; id: number }) => ({
      label: value.name,
      code: value.id,
    }));
  };

  const fetchDataOptions = async () => {
    try {
      const response = await Promise.allSettled([
        // getAllValueByFilter(dataReq),
        getTopicFilter({ code: "topic" }),
        // getAccountUsers(dataReq),
        getAllmoocTeacherAccount(),
        // getAllAuthor({ name: null })
        getAllmoocFacilityAccount(),
      ]);

      const mapResponseData = (responseData: any) =>
        responseData.map((value: { name: any; id: any, username: string }) => ({
          label: value.name,
          code: value.id,
          email: value.username,
      }));

      const safeGetData = (response: any) =>
        response?.value?.data?.data || response?.value?.data?.data?.data || [];

      // @ts-ignore
      setDataOptions({
        ...dataOptions,
        dataTopic:
          response[0].status === "fulfilled"
            ? handleGetActiveTopic(
                response[0].value.data.data.moocPortfolioValueData
              )
            : [],
        dataUsers:
          response[1].status === "fulfilled" &&
          response[2].status === "fulfilled"
            ? mapResponseData(safeGetData(response[1]))
              .concat(mapResponseData(safeGetData(response[2])))
              .reduce((unique: any, item: any) => {
                if(!unique.some((u: any) => u.code === item.code)) {
                  unique.push(item);
                }
                return unique;
              }, [])
            :
              [],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const stripHtml = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const formattedArray = (str: string) => {
    const formatString: string[] = str.split(" - ").map((item) => item.trim());
    return formatString;
  };

  useEffect(() => {
    fetchDataOptions();
  }, []);

  const validateFormCreate = ["code", "authors", "title", "topics"];

  const createQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      let apiRespone: any;
      if (question.type === QuestionTypeEnum.CONNECT) {
        apiRespone = await postCreateQuestionBankConnect(dataReq);
      } else {
        apiRespone = await postCreateQuestionBank(dataReq);
      }
      const dataResponse = apiRespone.data;
      helpService.successMessage(dataResponse.message);
      navigate(
        `/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listQuestionRepository}`
      );
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Tạo mới thất bại");
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  };

  const updateQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      if (question.type === QuestionTypeEnum.CONNECT) {
        await putUpdateQuestionBankConnect(dataReq);
      } else {
        await putUpdateQuestionBank(dataReq);
      }
      helpService.successMessage("Cập nhật câu hỏi thành công");
      // saveToIndexDB('current-course', { currentId: dataReq.id, id: dataReq.id, name: currentCourse.name, parent: currentParentCourse, });
      // onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Cập nhật thất bại");
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  };

  const renderQuestionByType = () => {
    if (Number(selectedQuestionType))
      switch (Number(selectedQuestionType)) {
        case QuestionTypeEnum.CHOOSE_TEXT:
          return <ChooseText dataFromApi={questionFormat} />;
        case QuestionTypeEnum.CHOOSE_IMAGE:
          return <ChooseImage data={questionFormat} />;
        case QuestionTypeEnum.CHOOSE_VIDEO:
          return <ChooseVideo data={questionFormat} />;
        case QuestionTypeEnum.TRUE_OR_FALSE:
          return <TrueOrFalse data={questionFormat} />;
        case QuestionTypeEnum.DROP_DOWN:
          return <DropDown data={questionFormat} />;
        case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
          return <ChooseMultiText data={questionFormat} />;
        case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
          return <ChooseMultiImage data={questionFormat} />;
        case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
          return <ChooseMultiVideos data={questionFormat} />;
        case QuestionTypeEnum.CONNECT:
          return <Connect dataFromApi={connectQuestionData} />;
        case QuestionTypeEnum.SHORT_TEXT:
          return <ShortText data={questionFormat} />;
        case QuestionTypeEnum.FILE_UPLOAD:
          return <FileUpload dataFromApi={questionFormat} />;
        case QuestionTypeEnum.VIDEO:
          return <Video data={questionFormat} />;
        case QuestionTypeEnum.MP3:
          return <Mp3 data={questionFormat} />;
        default:
          return <></>;
      }
  };

  const handleSaveQuestion = () => {
    const value = form.getFieldsValue();
    let payload = {
      code: value.codeQuestion,
      level: value.level,
      authors: value.authors,
      tags: value.tags,
      topics: value.topic,
      type: selectedQuestionType,
      question: currentQuestionInfo.questionName,
      questionValue: convertQuillToString(currentQuestionInfo.questionName),
      reminderText: currentQuestionInfo.reminderText || "",
      answers: mapAnsFEtoBE(currentQuestionInfo, question.type as number),
    };
    let payload2 = {
      id: Number(id),
      description: "",
      code: value.codeQuestion,
      level: value.level,
      authors: value.authors,
      tags: value.tags,
      topics: value.topic,
      type: selectedQuestionType,
      question: currentQuestionInfo.questionName,
      questionValue: convertQuillToString(currentQuestionInfo.questionName),
      reminderText: currentQuestionInfo.reminderText || "",
      answers: mapAnsFEtoBE(currentQuestionInfo, question.type as number),
    };
    const param = {
      code: "string",
      level: 0,
      authors: [0],
      topics: [0],
      tags: ["string"],
      type: 0,
      question: "string",
      questionValue: "string",
      reminderText: "string",
      answers: [
        {
          uuid: "string",
          content: "string",
          orderNumber: 0,
          point: 0,
          response: "string",
          isDisplayContent: true,
          filePath: "string",
          fileName: "string",
          isTrue: true,
        },
      ],
      id: 0,
      description: "string",
    };
    const strippedQuestionName = stripHtml(
      currentQuestionInfo.questionName
    ).trim();
    const checkArr = payload.answers.some((i: any) => isEmpty(i.filePath));
    if (
      (QuestionTypeEnum.CHOOSE_VIDEO === payload.type ||
        QuestionTypeEnum.CHOOSE_MULTI_VIDEO === payload.type ||
        QuestionTypeEnum.CHOOSE_IMAGE === payload.type ||
        QuestionTypeEnum.CHOOSE_MULTI_IMAGE === payload.type) &&
      checkArr &&
      payload.answers.length !== 0
    ) {
      message.error("Không được bỏ trống các giá trị file và nội dung");
    } else if (strippedQuestionName.length === 0) {
      message.error("Không được bỏ trống nội dung câu hỏi");
    } else if (selectedQuestionType) {
      if (modeQuestion === ModeQuestion.CREATE) {
        createQuiz(payload);
      }
      if (modeQuestion === ModeQuestion.UPDATE) {
        updateQuiz(payload2);
      }
    } else {
      message.error("Không được bỏ trống nội dung câu hỏi");
    }
  };

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "body-create-left") {
            handleSaveQuestion();
          }
        }}
      >
        <Content className="add-question">
          <PageHeader
            title={
              modeQuestion === "create"
                ? "Thêm câu hỏi"
                : `Sửa câu hỏi ${codeQuestion}`
            }
            headerGroupButton={true}
            positionItem="column"
            listBreadcrumb={listBreadcrumb}
            breadcrumb={true}
            arrowLeft={imgPath + "arrowLeft.svg"}
            childrenGroupButton={
              <div className="button-container">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  loading={loadingSubmit}
                  onClick={handleSave}
                >
                  Lưu
                </Button>
              </div>
            }
          />
          <Row className="body-create-question">
            <AddQuestionCourseModal
              courseBlockGroupIdClick={Number(id)}
              open={addQuestionModal}
              title={id ? `Sửa câu hỏi ${codeQuestion}` : "Thêm câu hỏi"}
              closeIcon={true}
              okText={"Xác nhận"}
              cancelText={"Huỷ"}
              icon={null}
              closeModal={() => setAddQuestionModal(false)}
              onSelectQuestionType={(type: any) => {
                handleSelectQuestion();
                setSelectedQuestionType(type);
              }}
            />
            <Col span={4} style={{ backgroundColor: "#FFF" }}>
              <Form
                form={form}
                name="body-create-left"
                className="body-create-left"
                layout="vertical"
                initialValues={{authors: [get(userInfo, 'id', '')]}}
              >
                <Form.Item
                  label="Mã câu hỏi"
                  name={"codeQuestion"}
                  rules={[
                    { required: true, message: "Vui lòng nhập mã câu hỏi!" },
                  ]}
                >
                  <Input placeholder="Nhập mã câu hỏi" />
                </Form.Item>

                <Form.Item
                  label="Độ khó"
                  name={"level"}
                  rules={[{ required: true, message: "Vui lòng chọn độ khó!" }]}
                >
                  <Select placeholder="Chọn độ khó">
                    <Option value={0}>Dễ</Option>
                    <Option value={1}>Trung bình</Option>
                    <Option value={2}>Nâng cao</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="authors"
                  rules={[
                    { required: true, message: "Vui lòng chọn tác giả!" },
                  ]}
                  label="Tác giả"
                >
                  <FormItemDropdown
                    modeDropdown="multiple"
                    options={dataOptions.dataUsers}
                    allowClear={true}
                    placeholder="Chọn tác giả"
                  />
                </Form.Item>

                <Form.Item
                  name="topic"
                  label="Chủ đề"
                  rules={[{ required: true, message: "Vui lòng chọn chủ đề!" }]}
                >
                  <FormItemDropdown
                    modeDropdown="multiple"
                    options={dataOptions.dataTopic}
                    allowClear={true}
                    placeholder="Chọn chủ đề"
                  />
                </Form.Item>

                <Form.Item name="tags" label="Nhãn">
                  <FormItemDropdown
                    options={dataTags}
                    modeDropdown={"tags"}
                    allowClear={true}
                    placeholder="Thêm nhãn"
                  />
                </Form.Item>
                <Form.Item>
                  <Button onClick={handleAddQuestion}>
                    <PlusOutlined /> Chọn loại câu hỏi
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              span={16}
              style={{ backgroundColor: "#grey", marginLeft: "100px" }}
            >
              <div className="question-content-box">
                <Spin spinning={loadingQuestion}>{renderQuestionByType()}</Spin>
              </div>
            </Col>
          </Row>
        </Content>
      </Form.Provider>
    </>
  );
};
export default CreateQuestion;
