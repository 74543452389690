import { Button, Col, Row } from "antd";
import QuizLayout from "../../../pages/preview-quiz/structure/quiz";
import FormInput from "../../../components/form-input/FormInput";
import ExamLayout from "./exam-quiz-preview";
import { useEffect, useState } from "react";
import { GroupsExam, QuizProps, QuizPropsV2 } from "../../../types/course";
import "./styles.scss";

import MoocMenu from "../../menu/MoocMenu";
import { SearchOutlined } from "@ant-design/icons";
import SettingExam from "./right-menu";
import { getQuizById, getUnitDetail } from "../../../service/course-construct";
import { getAllQuizByUnit } from "../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz";
import { useLocation } from "react-router-dom";

import EmptyComponent from "../../../components/empty";
import FillText from "../../../pages/preview-quiz/structure/quiz/fill-text/FillText";
import Mp3 from "../../../pages/preview-quiz/structure/quiz/mp3";
import VideoRecorder from "../../../pages/preview-quiz/structure/quiz/video";
import FileUpload from "../../../pages/preview-quiz/structure/quiz/file-upload";
import LongText from "../../../pages/preview-quiz/structure/quiz/long-text";
import ShortText from "../../../pages/preview-quiz/structure/view-results/short-text";
import Connect from "../../../pages/preview-quiz/structure/quiz/connect";
import ChooseMultiVideo from "../../../pages/preview-quiz/structure/quiz/choose-multi-video";
import ChooseMultiImage from "../../../pages/preview-quiz/structure/quiz/choose-multi-image";
import ChooseMultiText from "../../../pages/preview-quiz/structure/quiz/choose-multi-text";
import Dropdown from "../../../pages/preview-quiz/structure/quiz/drop-down";
import TrueOrFalse from "../../../pages/preview-quiz/structure/quiz/true-or-false";
import ChooseVideo from "../../../pages/preview-quiz/structure/quiz/choose-video";
import ChooseImage from "../../../pages/preview-quiz/structure/quiz/choose-image";
import ChooseText from "../../../pages/preview-quiz/structure/quiz/choose-text/ChooseText";
import { QuestionTypeEnum } from "../../../constants";

export interface LocationState {
  message: string;
  backUrl: string;
  mode: string;
  titleBreadCrumb: string;
}

type QuizExamScreenProps = {
  unitId?: string;
  mode?: string;
  questionId?: string;
};

interface Answer {
  point: number;
  isTrue: boolean;
  content: string;
  fileName: string | null;
  filePath: string | null;
  response: string;
  orderNumber: number;
  isDisplayContent: string | null;
}

interface Quiz {
  id: number;
  question: string;
  reminderText: string | null;
  type: number;
  answers: Answer[];
  orderNumber: number;
  settingGeneral: string;
  settingPoint: string;
  settingHint: string;
  settingResponse: string;
}

interface QuizGroup {
  groupId: number;
  blockId: number;
  title: string;
  description: string | null;
  isBegin: boolean;
  isEnd: boolean;
  orderNumber: number;
  quizzes: Quiz[];
  quizDtos: any; // You can refine this type based on your data structure
}

function QuizExamScreen(props: QuizExamScreenProps) {
  const { unitId, questionId } = props;
  const [currentQuestion, setCurrentQuestion] = useState<QuizProps>();
  const [groups, setGroups] = useState<GroupsExam[]>([]);
  const [quizzesFlat, setQuizzesFlat] = useState<QuizProps[]>([]);
  const [active, setActive] = useState<number>(0);
  const [activeParent, setActiveParent] = useState<string[]>([]);
  const disabledQuiz = true;
  const location = useLocation();
  const { state } = location as { state: LocationState };

  const getQuestionInfo = async () => {
    try {
      const resAPI = await getQuizById(questionId).then((res) => {
        setCurrentQuestion(res?.data.body);
      });
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    if (
      (questionId && state.mode === "5") ||
      (questionId && state.mode === "6") ||
      (questionId && state.mode === "7")
    ) {
      getQuestionInfo();
    }
  }, [questionId]);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredGroups, setFilteredGroups] = useState<GroupsExam[]>();

  const initialQuiz = (groups: GroupsExam[]) => {
    if (groups && groups?.length > 0) {
      for (let i = 0; i < groups?.length; i++) {
        if (groups[i]?.quizzes && groups[i]?.quizzes?.length > 0) {
          setCurrentQuestion(groups[i]?.quizzes[0]);
        }
      }
    }
  };

  const handleSetActiveParent = (parentKey: string) => {
    setActiveParent([`parent-${parentKey}`]);
  };

  const handleChangeQuizPanel = (quiz: QuizProps) => {
    setCurrentQuestion(quiz);
  };

  const handleNextQuiz = () => {
    if (quizzesFlat && currentQuestion) {
      // next quiz
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );
      if (currentIndexQuiz !== -1) {
        const nextQuiz = quizzesFlat[currentIndexQuiz + 1];
        if (nextQuiz) setCurrentQuestion(nextQuiz);
      }
    }
  };

  const handlePreviousQuiz = () => {
    if (quizzesFlat && currentQuestion) {
      // prev quiz
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );
      if (currentIndexQuiz !== -1) {
        const prevQuiz = quizzesFlat[currentIndexQuiz - 1];
        if (prevQuiz) setCurrentQuestion(prevQuiz);
      }
    }
  };

  const disableNextBtn = () => {
    return currentQuestion?.id === quizzesFlat[quizzesFlat?.length - 1]?.id;
  };

  const disablePrevBtn = () => {
    return currentQuestion?.id === quizzesFlat[0]?.id;
  };

  const handleFlatQuestion = (quizzes: any) => {
    for (let i = 0; i < quizzes?.length; i++) {
      if (quizzes[i]?.quizzes) {
        setQuizzesFlat((prevState: QuizProps[]) => [
          ...prevState,
          ...quizzes[i]?.quizzes,
        ]);
      }
    }
  };

  const handleSearchChange = (text: string) => {
    if (text) {
      const filtered = groups
        .map((group) => {
          const filteredQuizzes = group.quizzes.filter((quiz) =>
            quiz.question.toLowerCase().includes(text)
          );

          return { ...group, quizzes: filteredQuizzes };
        })
        .filter(
          (group: { quizzes: string | any[] }) => group.quizzes.length > 0
        );

      setFilteredGroups(filtered);
    } else {
      setFilteredGroups(groups);
    }
  };

  useEffect(() => {
    handleFlatQuestion(filteredGroups);
  }, [filteredGroups]);

  useEffect(() => {
    const getUnitInfo = async () => {
      const data = {
        id: unitId,
        keyword: "",
      };
      try {
        const resAPI = await getAllQuizByUnit(data).then((res) => {
          setGroups(res.data);
          initialQuiz(res.data);
          setFilteredGroups(res.data);
          setCurrentQuestion(res.data[0].quizzes[0]);
        });
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    if (state.mode !== "5" && state.mode !== "6") {
      if (unitId) getUnitInfo();
    } 
  }, [unitId]);

  const renderContainer = () => {
    return (
      <div className="structure-content flex">
        {state.mode === "5" || state.mode === "6" || state.mode === "7"? (
          <></>
        ) : (
          <div>
            <div className="pv-16 search-container">
              <Row gutter={[{ xs: 8, xl: 8, md: 8 }, 8]} className="mt-2">
                <Col
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <FormInput
                    placeholder="Tìm câu hỏi"
                    onChange={(e: any) => {
                      const query = e.target.value.toLowerCase();
                      setSearchQuery(query);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSearchChange(searchQuery);
                      }
                    }}
                    afterPrefixIcon={
                      <SearchOutlined
                        onClick={() => {
                          handleSearchChange(searchQuery);
                        }}
                      />
                    }
                  />
                </Col>
              </Row>
            </div>
            <MoocMenu
              data={filteredGroups as any}
              mode="small"
              active={active}
              activeParent={activeParent}
              setActive={setActive}
              setActiveParent={handleSetActiveParent}
              onClick={handleChangeQuizPanel}
            />
          </div>
        )}

        <div className="train-layout flex-1">
          <ExamLayout
            disableOption={true}
            quiz={currentQuestion}
            quizzes={quizzesFlat}
            disableNextBtn={disableNextBtn()}
            disablePrevBtn={disablePrevBtn()}
            onNext={handleNextQuiz}
            onPrevious={handlePreviousQuiz}
          />
        </div>
        <SettingExam />
      </div>
    );
  };
  return (
    <div className="course-structure-exam">
      <div className="structure-header w-full flex gap-24">
        <h3 className="font-size-24 line-height-32 mb-2"></h3>
      </div>

      {renderContainer()}
    </div>
  );
}

export default QuizExamScreen;
