// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approve-multi-learning-material .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 90vh; }

.approve-multi-learning-material .ant-modal-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 1;
  overflow: hidden; }

.approve-multi-learning-material .approve-multi-header {
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0; }

.approve-multi-learning-material .approve-multi-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px; }

.approve-multi-learning-material .selected-count {
  font-weight: bold; }

.approve-multi-learning-material .table-container {
  flex-grow: 1; }
  .approve-multi-learning-material .table-container .ant-table-wrapper {
    height: calc( 100% - 80px); }
    .approve-multi-learning-material .table-container .ant-table-wrapper .ant-table-body {
      max-height: calc( 100% - 50px);
      overflow-y: auto; }
`, "",{"version":3,"sources":["webpack://./src/pages/learning-materials/content-moderation/approve-multi/ApproveMulti.scss"],"names":[],"mappings":"AAAA;EAEI,aAAa;EACb,sBAAsB;EACtB,YAAY,EAAA;;AAJhB;EAQI,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,SAAO;EACP,gBAAgB,EAAA;;AAZpB;EAgBI,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,gCAAgC,EAAA;;AArBpC;EAyBI,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB,EAAA;;AA7BxB;EAiCI,iBAAiB,EAAA;;AAjCrB;EAqCI,YAAY,EAAA;EArChB;IAuCM,0BAEC,EAAA;IAzCP;MA2CQ,8BAEC;MACD,gBAAgB,EAAA","sourcesContent":[".approve-multi-learning-material {\n  .ant-modal-content {\n    display: flex;\n    flex-direction: column;\n    height: 90vh;\n  }\n\n  .ant-modal-body {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    flex: 1;\n    overflow: hidden;\n  }\n\n  .approve-multi-header {\n    height: 52px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-bottom: 8px;\n    border-bottom: 1px solid #f0f0f0;\n  }\n\n  .approve-multi-status {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-top: 8px;\n    padding-bottom: 16px;\n  }\n\n  .selected-count {\n    font-weight: bold;\n  }\n\n  .table-container {\n    flex-grow: 1;\n    .ant-table-wrapper {\n      height: calc(\n        100% - 80px\n      ); // Table should fill the available space in the modal body\n      .ant-table-body {\n        max-height: calc(\n          100% - 50px\n        ); // Allow table body to scroll within the container\n        overflow-y: auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
