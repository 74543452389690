import { Button, Card, Col, Dropdown, Form, MenuProps, message, Modal, Row, Spin, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import FormItemInput from "../../../form-input/FormInput";
import { DeleteOutlined, EditOutlined, FilterOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useState } from "react";
import "./card-department.scss";
import { FormInstance } from "antd/lib";
import { getAllEnterpriseUniversity } from "../../../../service/management-participate-platform";
import { deleteMoocIndustryGroup, getAllMoocIndustryGroupByFilter } from "../../../../service/category-management/department-major-management/department";
import { isEmpty } from "lodash";
type CardDepartmentProps = {
    selectedUniversity?: string | null,
    currentUniversity?: string | null,
    hasCurrentUniversity?: boolean,
    isFetchDataDepartment?: boolean,
    setDepartmentId: (id: number | null) => void,
    setOpenModal: (value: boolean) => void,
    setDepartmentEditId: (value: number) => void,
    setListDataDepartment: (value: any) => void,
    setTypeOfModal: (value: number) => void,
}

const CardDepartment: FunctionComponent<CardDepartmentProps> = ({
  selectedUniversity,
  currentUniversity,
  hasCurrentUniversity,
  isFetchDataDepartment,
  setDepartmentId,
  setOpenModal,
  setDepartmentEditId,
  setListDataDepartment,
  setTypeOfModal,
}) => {
  const [form] = useForm();
  const [searchValue, setSearchValue] = useState<string>("");
  const [rowActive, setRowActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [listEnterpriseUniversity, setListEnterpriseUniversity] = useState([]);
  const [listDepartment, setListDepartment] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({
    enterpriseUuid: null,
  })

  const handleActiveRow = (item: any, index: number) => {
    setDepartmentId(item.id);
    setRowActive(index);
  };
  
  const departmentOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa khoa",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa khoa",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  // Filter Search Department
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setRowActive(0);
      fetchData();
    }
  };

  const handleSubmitSearch= () => {
    setRowActive(0);
    fetchData();
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setRowActive(0);
    setFilterData({
        enterpriseUuid: null,
    });
  };

  // fetch data
  const fetchData = async () => {
    setLoading(true);
    const dataReq = {
        page: 1,
        size: 100000,
        keyword: searchValue,
        industryGroupId: null,
        enterpriseUuid: currentUniversity ? currentUniversity : filterData.enterpriseUuid,
        sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`]
      }

    await getAllMoocIndustryGroupByFilter(dataReq).then((res) => {
        if(res.status === 200) {
            setListDepartment(res.data?.data?.data);
            setListDataDepartment(res.data?.data?.data);
            setTimeout(() => {
                setLoading(false);
            }, 333);
        }
    }).catch((err) => {
        setLoading(false);
        console.error(err);
    })
  }

  // get list enterprise university
  const getListEnterprise = async () => {
    const data = {
      page: 1,
      size: 10000,
      sort: [],
      keyword: ""
    }
    await getAllEnterpriseUniversity(data).then((res) => {
      if (res.status === 200) {
        const listUniversity = res.data?.data?.content?.map((item: any) => ({
          id: item.uuid,
          name: item.name
        }))
        setListEnterpriseUniversity(listUniversity)
      }
    })
  }

  // Delete department
  const deleteDepartment = async (id: number) => {
    await deleteMoocIndustryGroup(id).then((res) => {
      if(res.status === 200) {
        message.success("Xóa khoa thành công!");
        fetchData();
      }
    })
  }

  // useEffect
  useEffect(() => {
    if(selectedUniversity) {
        setFilterData({
            ...filterData,
            enterpriseUuid: selectedUniversity,
        });
    }
  }, [selectedUniversity]);

  useEffect(() => {
    if(hasCurrentUniversity) {
      fetchData();
    }
  }, [filterData, hasCurrentUniversity, isFetchDataDepartment]);

  useEffect(() => {
    getListEnterprise();
  }, []);

  useEffect(() => {
    if(listDepartment.length > 0) {
      setDepartmentId(listDepartment[0]?.id);
    } else if (listDepartment.length === 0) {
      setDepartmentId(null);
    }
  }, [listDepartment]);

  useEffect(() => {
    if(currentUniversity) {
      form.setFieldsValue({
        university: currentUniversity,
      })
    }
  }, [hasCurrentUniversity]);

  return (
    <>
      <Card className="card-department" bordered={false} >
        <div className="header-department-card">
          <div className="card-department-title flex mb-1">
            <Typography.Title level={4}>Danh sách khoa</Typography.Title>
            {!currentUniversity ?
                <></>
              :
                <Button 
                  className="add-new-btn btn-primary" 
                  disabled={!currentUniversity} 
                  onClick={() => {
                    setOpenModal(true);
                    setTypeOfModal(1);
                  }}
                > 
                  Thêm mới 
                </Button>
            }
          </div>
          <div className="card-filter">
            <Form name="department-filter-form" form={form}>
              <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
                <Col span={24} >
                  <FormItemInput
                    placeholder="Nhập để tìm kiếm"
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearch}
                    afterPrefixIcon={
                      <SearchOutlined onClick={handleSubmitSearch} />
                    }
                  />
                </Col>
                <Col xl={{span: !currentUniversity ? 14 : 24}} md={{span: !currentUniversity ? 14 : 24}} sm={{span: !currentUniversity ? 14 : 24}} xs={{span: 24}} >
                  <Form.Item name="university">
                    <FormItemDropdown placeholder="Chọn trường" options={listEnterpriseUniversity} disabled={!!currentUniversity}/>
                  </Form.Item>
                </Col>
                {currentUniversity ? 
                  <></>
                  :
                <Col xl={{span: 9}} md={{span: 9}} sm={{span: 9}} xs={{span: 24}} className="flex">
                    <div className="group-btn--filter">
                        <Button className="btn btn-primary filter-btn" disabled={!!currentUniversity} htmlType="submit">
                            <FilterOutlined/>
                            <Typography.Text> Lọc </Typography.Text>
                        </Button>
                        <Button className="btn btn-outlined clear-filter-btn" disabled={!!currentUniversity} onClick={() => clearFilter(form)}>
                            <DeleteOutlined/>
                            <Typography.Text> Xóa </Typography.Text>
                        </Button>
                    </div>
                </Col>

                }
              </Row>
            </Form>
          </div>
        </div>
        {
            loading ?
                <div className="card-department-loading flex">
                    <Spin size="large" />
                </div>
            :
            listDepartment?.length > 0 ? 
                <div className="body-card">
                    {listDepartment.map((item: any, index: number) => {
                        return (
                        <div className={`department-item ${ rowActive === index ? `active` : "" }`}
                            onClick={() => handleActiveRow(item, index)}
                        >
                            <Typography.Text className="row-index">
                            {index + 1}.
                            </Typography.Text>
                            <Typography.Text className="department-name">
                            {item.name} ({item.code})
                            </Typography.Text>
                            {!currentUniversity ? 
                              <></>
                                :
                              <Dropdown
                                menu={{
                                  items: departmentOptions,
                                  onClick: ({ key }) => {
                                    if (key === "1") {
                                      setDepartmentEditId(item.id);
                                      setOpenModal(true);
                                      setTypeOfModal(2);
                                    }
                                    if (key === "2") {
                                        Modal.confirm({
                                        title: `Xoá khoa`,
                                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                                        onOk: () => deleteDepartment(item.id),
                                        centered: true,
                                        okText: "Xác nhận",
                                        cancelText: "Huỷ bỏ",
                                        footer: (_, { OkBtn, CancelBtn }) => (
                                            <>
                                            <CancelBtn />
                                            <OkBtn />
                                            </>
                                        ),
                                        });
                                    }
                                  },
                                }}
                                placement="bottomRight"
                              >
                                <MoreOutlined style={{ cursor: "pointer" }} />
                              </Dropdown>
                            }
                        </div>
                        );
                    })}
                </div>
            :
                <div className="body-card-empty flex">
                    <img
                    className="image-empty-data"
                    src="/images/empty-img-gray.png"
                    alt="empty-img"
                    ></img>
                </div>
        }
      </Card>
    </>
  );
};
export default CardDepartment;
