import { Form, Tabs } from "antd";
import { FormInstance } from "antd/lib";
import React, { FunctionComponent } from "react";
import { DataType } from "../../../../types/listUsers";
import "./create-role.scss";
import InfoRoleForm from "./InfoRoleForm";
import InfoRoleGeneralForm from "./InfoRoleGeneral";
import RoleAccount from "./RoleAccount";

type CreateRoleProps = {
  form: FormInstance;
  checkEdit?: boolean;
  checkIsFormEdit?: boolean;
  resetPermissions?: any;
  dataEdit?: DataType | any;
  count?: number;
  isOpened?: boolean;
  setCount?: (value: number) => void;
  activeKey?: string;
  setActiveKey?: React.Dispatch<React.SetStateAction<string>>;
  checkedList: Array<string>;
  setCheckedList: React.Dispatch<React.SetStateAction<string[]>>;
  isCreate?: boolean;
  defaultRole?: any
};

const CreateRole: FunctionComponent<CreateRoleProps> = ({
  form,
  checkEdit,
  checkIsFormEdit,
  dataEdit,
  count,
  setCount,
  isOpened,
  resetPermissions,
  activeKey,
  setActiveKey,
  checkedList,
  setCheckedList,
  isCreate,
  defaultRole
}) => {
  const items = [
    {
      key: "1",
      label: "Thông tin vai trò",
      children: (
        <InfoRoleGeneralForm
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          form={form}
          checkEdit={checkEdit}
          checkIsFormEdit={checkIsFormEdit}
          resetPermissions={resetPermissions}
          dataEdit={dataEdit}
          isOpened={isOpened}
          defaultRole={defaultRole}
        />
      ),

    },
    {
      key: "2",
      label: "Gán quyền",
      children: (
        <InfoRoleForm
          form={form}
          checkEdit={checkEdit}
          checkIsFormEdit={checkIsFormEdit}
          resetPermissions={resetPermissions}
          dataEdit={dataEdit}
          isOpened={isOpened}
        />
      ),
      disabled: isCreate
    },
    {
      key: "3",
      label: "Gán tài khoản",
      children: (
        <RoleAccount
          form={form}
          checkEdit={checkEdit}
          checkIsFormEdit={checkIsFormEdit}
          resetPermissions={resetPermissions}
          dataEdit={dataEdit}
          isOpened={isOpened}
        />
      ),
      disabled: isCreate
    },
  ];
  return (
    <div>
      <Form form={form} name="formRole" initialValues={{ trangThai: true }}>
        <Tabs activeKey={activeKey} onChange={setActiveKey} items={items} />
      </Form>
    </div>
  );
};

export default CreateRole;