import { Form, Spin } from "antd";
import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import "./administrative-unit-detail.scss";
interface AdministrativeUnitDetailProps {
  administrativeUnitDetail: any;
  loadingDetail: boolean;
  textRendered?: string;
  tab?: string,
}

const AdministrativeUnitDetail: FC<AdministrativeUnitDetailProps> = ({
  administrativeUnitDetail,
  loadingDetail,
  textRendered,
  tab,
}) => {
  return (
    <section className="wrapper-settings category-management-detail" style={{height: "auto"}}>
      <div className="setting-position wrapper-inside">
        <CustomCard className="header-card" title={`Thông tin ${textRendered}`}>
          {loadingDetail ? (
            <div className="statistic-course-content statistic-course-loading">
              <Spin size="large" />
            </div>
          ) : (
            <section>
              <Form.Item className="setting-item" name="code" label={`Mã`}>
                <p className="position-content">{administrativeUnitDetail?.code}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="codeName" label={`Tên mã`}>
                <p className="position-content">{administrativeUnitDetail?.codeName}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="name" label={`Tên`}>
                <p className="position-content">{administrativeUnitDetail?.name}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="fullName" label={`Tên đầy đủ`}>
                <p className="position-content">{administrativeUnitDetail?.fullName}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="nameEn" label={`Tên tiếng Anh`}>
                <p className="position-content">{administrativeUnitDetail?.nameEn}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="fullNameEn" label={`Tên tiếng Anh đầy đủ`}>
                <p className="position-content">{administrativeUnitDetail?.fullNameEn}</p>
              </Form.Item>
              <Form.Item className="setting-item" name="createdDate" label={`Ngày tạo`}>
                <p className="position-content">{administrativeUnitDetail?.createdDate}</p>
              </Form.Item>
              {
                tab !== "1" ? 
                <Form.Item className="setting-item" name="createdDate" label={`Đơn vị cấp Tỉnh`}>
                  <p className="position-content">{administrativeUnitDetail?.provinceName}</p>
                </Form.Item>
                : null
              }
              {
                tab === "3" ? 
                <Form.Item className="setting-item" name="createdDate" label={`Đơn vị cấp Huyện`}>
                  <p className="position-content">{administrativeUnitDetail?.districtName}</p>
                </Form.Item>
                : null
              }
            </section>
          )}
        </CustomCard>
      </div>
    </section>
  );
};

export default AdministrativeUnitDetail;
