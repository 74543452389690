import {Button, Collapse, Space, Typography} from 'antd';
import React from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import DrawerCustom from "../../../../drawer/Drawer";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import './index.scss'
import {isEmpty} from "lodash";
import {formatOriginTimev3} from '../../../../../utils/converDate';

type HistoryCensorshipProps = {
  isOpened: boolean
  closeForm: () => void
  arrNewApprove: any
  arrEditApprove: any
  dataOptions: any
}

const HistoryCensorship = ({
                             isOpened, closeForm, arrNewApprove,
                             arrEditApprove,
                             dataOptions
                           }: HistoryCensorshipProps) => {
  const {Panel} = Collapse;
  const {Title, Text} = Typography;


  const renderEditLayout = !isEmpty(arrEditApprove) && arrEditApprove?.map((item: any) => {
    const arr: any = dataOptions?.dataStatus?.filter((option: any) => Number(option.id) === Number(item?.publishStatus))
    return (
      <Panel
        header="Duyệt chỉnh sửa"
        key="1"
        className="collapse-exam-detail--header">
        <div>
          <div className="list-item"><Title level={5} className="list-item--title">Nội dung gửi duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{item?.action}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Ngày gửi duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{formatOriginTimev3(item?.createdDate)}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Nội dung duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{`${item.status ? 'Duyệt' : "Không duyệt"} - <${item?.description}>`}</Text>
            </div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Người duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{item?.actionByName}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Ngày duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{formatOriginTimev3(item?.approvedDate)}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Ngày xuất bản</Title>
            <div className="list-item--description">
              <Text className="content">{formatOriginTimev3(item?.publishDate)}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Tình trạng</Title>
            <div className="list-item--description">
              <Text className="content">{arr?.[0]?.name}</Text></div>
          </div>
        </div>
      </Panel>
    )
  })

  const renderNewLayout = !isEmpty(arrNewApprove) && arrNewApprove?.map((item: any) => {
    const arr: any = dataOptions?.dataStatus?.filter((option: any) => Number(option.id) === Number(item?.publishStatus))
    return (
      <Panel
        header="Duyệt mới"
        key="2"
        className="collapse-exam-detail--header">
        <div>
          <div className="list-item"><Title level={5} className="list-item--title">Nội dung gửi duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{item?.action}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Ngày gửi duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{formatOriginTimev3(item?.createdDate)}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Nội dung duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{`${item.status ? 'Duyệt' : "Không duyệt"}- <${item?.description}>`}</Text>
            </div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Người duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{item?.actionByName}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Ngày duyệt</Title>
            <div className="list-item--description">
              <Text className="content">{formatOriginTimev3(item?.approvedDate)}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Ngày xuất bản</Title>
            <div className="list-item--description">
              <Text className="content">{formatOriginTimev3(item?.publishDate)}</Text></div>
          </div>
          <div className="list-item"><Title level={5} className="list-item--title">Tình trạng</Title>
            <div className="list-item--description">
              <Text className="content">{arr?.[0]?.name}</Text></div>
          </div>
        </div>
      </Panel>
    )
  })


  return (
    <DrawerCustom title={'Lịch sử kiểm duyệt'}
                  open={isOpened}
                  onClose={closeForm}
                  bodyStyle={{
                    padding: 0,
                  }}
                  extraNode={
                    <Space>
                      <Button
                        onClick={closeForm}
                        className="btn btn-outlined"
                      >
                        <CloseOutlined/>
                        <Typography.Text>Hủy</Typography.Text>
                      </Button>
                      <Button
                        className="btn btn-primary"
                        htmlType="submit"
                        // onClick={saveData}
                      >
                        <SaveOutlined/>
                        <Typography.Text>Lưu</Typography.Text>
                      </Button>
                    </Space>
                  }
                  widthDrawer={736}
    >
      <div>
        <CustomCard
          className="card-detail-exam"
        >
          <div>
            <Collapse defaultActiveKey={["1", "2"]} ghost className="collapse-exam-detail">
              {renderEditLayout}
              {renderNewLayout}
            </Collapse>
          </div>
        </CustomCard>
      </div>
    </DrawerCustom>
  );
};

export default HistoryCensorship;