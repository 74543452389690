import { StateCreator } from "zustand";

export interface LearningMaterialState {
  reload: boolean;
  tabLink: string;
  onReload: () => void;
  setTabLink: (tab: string) => void; // Thêm tham số kiểu cho hàm setTab
}

export const learningMaterialSlice: StateCreator<LearningMaterialState> = (
  set
) => ({
  reload: false,
  tabLink: "1",
  onReload: () => {
    set((state) => ({ reload: !state.reload }));
  },
  setTabLink: (tab: string) => {
    set(() => ({ tabLink: tab }));
  },
});
