import { CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Col, Form, Modal, Row, Space, Tooltip, Typography, message } from 'antd'
import moment from 'moment'
import { useEffect } from 'react'
import { saveLearningTemplate } from '../../../service/learning-material'
import FormInput from '../../form-input/FormInput'
import ReactQuill from 'react-quill'
import { FORMAT_REACT_QUILL } from '../../../constants'

type Props = {
  open: boolean
  codeLesson?: string
  handleClose: () => void
  blockId: number
}

export default function SaveLearningTemplate(props: Props) {
  const { open, handleClose, codeLesson, blockId } = props

  const [form] = Form.useForm()

  const validation = {
    codeTemplateLesson: {
      required: true,
      message: "Vui lòng nhập mã mẫu học liệu",
    },
    nameTemplateLesson: {
      required: true,
      message: "Vui lòng nhập tên mẫu học liệu",
    },
  };

  const randomCodeTemplateLesson = () => {
    const currentTime = moment().format('YYYYMMDDHHmmss');
    const codeLessonRandom = `hl_${currentTime}`;
    form.setFieldValue("codeTemplateLesson", codeLessonRandom);
  }

  const saveData = async (values: any) => {
    const payload = {
      blockId: blockId,
      code: values.codeTemplateLesson ?? null,
      name: values.nameTemplateLesson ?? null,
      description: values.description ?? null
    }
    await saveLearningTemplate(payload).then((res) => {
      if (res.status === 200) {
        message.success("Lưu mẫu học liệu thành công")
        handleClose()
        form.resetFields()
      }
    })
  }

  useEffect(() => {
    if (open) {
      randomCodeTemplateLesson()
    }
  }, [open])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === "save-template-lesson-modal") {

        Modal.confirm({
          title: `Xác nhận lưu mẫu học liệu`,
          content: `Xin vui lòng xác nhận lưu mẫu học liệu.`,
          onOk: () => {
            saveData(values);
          },
          okText: "Xác nhận",
          cancelText: "Đóng",
          centered: true,
          icon: <CheckCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>
      <Modal
        className="save-template-lesson-modal"
        title={"Lưu mẫu học liệu"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"450px"}
      >
        <Form
          form={form}
          layout="vertical"
          name="save-template-lesson-modal"
        >
          <Row gutter={30} className='p-6'>
            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }} className="align-center">
              <div className='flex align-center'>
                <Form.Item name="codeTemplateLesson" label="Mã mẫu học liệu"
                  className='flex-1'
                  rules={[validation.codeTemplateLesson]}>
                  <FormInput placeholder="Nhập mã mẫu hoặc tự sinh mã tự động" style={{ flex: 1 }} />
                </Form.Item>
                <Tooltip title="Tạo mã mẫu học liệu ngẫu nhiên">
                  <ReloadOutlined className="cursor-pointer" onClick={randomCodeTemplateLesson} style={{ marginLeft: '8px' }} />
                </Tooltip>
              </div>
            </Col>

            <Col md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xs={{ span: 22 }}>
              <Form.Item name="nameTemplateLesson" label="Tên mẫu học liệu" rules={[validation.nameTemplateLesson]}>
                <FormInput placeholder='Nhập tên mẫu học liệu' />
              </Form.Item>
            </Col>
            <Col md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xs={{ span: 22 }}>
              <Form.Item name="description" label="Mô tả">
                {/* <FormInput placeholder='Nhập mô tả' /> */}
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  formats={FORMAT_REACT_QUILL}
                  placeholder="Nhập text"
                  className="add-learning-materials-popup"
                />
              </Form.Item>
            </Col>
            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider >
  )
}
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ['blockquote', 'code-block'],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['formula', 'link'],
  ],
};