import { CloseOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Radio, Row, Select, Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import FileUploader from '../../../../components/file-uploader/file-uploader.component';
import FormInput from '../../../../components/form-input/FormInput';
import { FORMAT_REACT_QUILL } from '../../../../constants';
import { multimediaIconList } from '../../../../constants/multimedia-constants/multimedia-constants';
import { createLearningMaterial, getLearningMaterialDetail, updateLearningMaterial } from '../../../../service/learning-material';
import { LearningMaterialStore } from '../../../../stores/stores';
import { notifyError, notifySuccess } from '../../../../utils/notification';
import CustomAudioPlayer from '../view/AudioPlayer';
import ScormXapiViewer from '../view/ScormXapiViewer';
import TextViewer from '../view/TextViewer';
import VideoPlayer from '../view/VideoPlayer';
import "./LearningMaterialDrawer.scss";
import { useWatch } from 'antd/es/form/Form';

type Props = {
  open: boolean;
  onCloseDraw: () => void;
  multimediaType: string;
  isEdit?: boolean;
  learningMaterialId: number;
};

const { Option } = Select;

const LearningMaterialDrawer = (props: Props) => {
  const { open, onCloseDraw, multimediaType, isEdit, learningMaterialId } = props;
  const { onReload } = LearningMaterialStore()

  const [form] = Form.useForm();
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileListMain, setFileListMain] = useState<any>([]);
  const [detail, setDetail] = useState<any>(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);


  useEffect(() => {
    const fetchLearningMaterialDetail = async () => {
      try {
        const res = await getLearningMaterialDetail(learningMaterialId);
        setDetail(res?.data.data);
      } catch (error) {
        notifyError("Có lỗi xảy ra khi lấy chi tiết học liệu");
      }
    };
    if (learningMaterialId && open && isEdit) {
      fetchLearningMaterialDetail();
    }
  }, [learningMaterialId, open, isEdit]);

  useEffect(() => {
    if (isEdit && detail && open) {
      form.setFieldsValue({
        title: detail?.name,
        code: detail?.code,
        description: detail?.description,
        icon: detail?.iconType,
        text: detail?.documentText,
      });
      setFileUpload(detail?.mainKey);
    } else {
      form.resetFields();
      setFileUpload(null);
      setFileListMain([]);

    }
  }, [detail, isEdit, open, form]);

  const renderTitle = () => {
    let type = isEdit ? detail?.module?.toUpperCase() : multimediaType?.toUpperCase()

    switch (type) {
      case "VIDEO":
        return "Video";
      case "SCORM":
        return "scorm";
      case "XAPI":
        return "xAPI";
      case "PDF":
        return "PDF";
      case "AUDIO":
      case "MP3":
        return "Audio";
      case "TEXT":
        return "Văn bản";
      case "REFERENCE":
        return "Tài liệu tham khảo";
      default:
        return "";
    }
  };

  const returnAccept = () => {
    switch (multimediaType?.toUpperCase()) {
      case "VIDEO":
        return ".mp4, .mpg, .avi, .webm, .m4v";
      case "SCORM":
      case "XAPI":
        return ".zip, .rar";
      case "PDF":
        return ".pdf";
      case "AUDIO":
      case "MP3":
        return ".mp3";
      case "TEXT":
        return ".txt";
      default:
        return "";
    }
  };

  const renderNameDraw = () => {
    return isEdit ? `Sửa học liệu ${detail?.name}` : `Thêm học liệu ${renderTitle()}`;
  };

  const handleChangeFileUpload = (info: any) => {

    if (!isEmpty(info.fileList)) {
      const file = info.file.originFileObj;
      const fileUrl = URL.createObjectURL(file);
      setFileListMain(info.fileList);
      setUploadComplete(false);
      if (info.file.status === 'done') {
        setFileUpload(fileUrl)
        setUploadComplete(true);
      }

    } else {
      setFileUpload('');
      setFileListMain([]);
      setUploadComplete(false);
    }
  };

  const beforeUpload = () => false;

  const getS3Prefix = () => {
    return multimediaType === "scorm" || multimediaType === "xapi"
      ? "scorm"
      : "sequences";
  };

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const values = await form.validateFields();

      const payload = {
        module: isEdit ? detail?.module : multimediaType === "audio" ? "mp3" : multimediaType?.toLowerCase(),
        name: values.title,
        code: values.code,
        description: values.description || "",
        iconType: values.icon || 1,
        fileKey: values.video?.file.response.fileKey,
        documentText: values?.text,
      };
      if (isEdit) {
        await updateLearningMaterial(learningMaterialId, payload);
        notifySuccess("Cập nhật học liệu thành công");


      } else {
        await createLearningMaterial(payload);
        notifySuccess("Tạo học liệu thành công");
      }

      onReload();
      onCloseDraw();
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
    }
    finally {
      setIsLoading(false)
    }
  };

  const RenderDetail = (type: string) => {
    if (!uploadComplete && !isEdit) return null;
    switch (type) {
      case 'video':
        return <VideoPlayer
          url={fileUpload}
        />;
      case 'mp3':
      case 'audio':

        return <CustomAudioPlayer
          url={fileUpload}
        />;
      case 'text':
        return <TextViewer content={detail?.documentText} />;
      case 'pdf':
      case 'PDF':
        return (
          <div className="view-wrapper">
            <iframe
              allow="fullscreen"
              scrolling="yes"
              src={fileUpload}
            />
          </div>
        );
      case 'scorm':
      case 'xapi':
        return <>{isEdit ? <ScormXapiViewer
          src={fileUpload}
        />
          : <iframe
            src={fileUpload}
            allow="fullscreen"
            scrolling="yes"
            width="100%"
            height={"400px"}
          />
        }</>

      default:
        return ""
    }
  }

  const videoFormValue = Form.useWatch("video", form);

  const checkDisabledSaveBtn = () => {
    const checkFile = videoFormValue?.fileList?.length;
    const isFileRequired = multimediaType !== 'text' && multimediaType !== 'reference';
    const hasTitle = !isEmpty(form.getFieldValue("title"));
    const hasCode = !isEmpty(form.getFieldValue("code"));
    const hasFileUpload = isFileRequired ? checkFile > 0 : true;
    
    return !(hasTitle && hasCode && hasFileUpload);
  };

  const handleFormValuesChange = () => {
    setIsSaveDisabled(checkDisabledSaveBtn());
  };

  useEffect(() => {
    handleFormValuesChange();
  }, [videoFormValue]);

  return (
    <Drawer
      open={open}
      onClose={onCloseDraw}
      size={'large'}
      title={renderNameDraw()}
      extra={
        <Space>
          <Button onClick={onCloseDraw} className="btn btn-outlined">
            <CloseOutlined />
            <Typography.Text>Hủy</Typography.Text>
          </Button>
          <Button onClick={handleSave} className="btn btn-primary" htmlType="submit"
            loading={isLoading}
            disabled={isSaveDisabled}

          >
            <SaveOutlined />
            <Typography.Text>Lưu</Typography.Text>
          </Button>
        </Space>
      }
    >
      <Form
        className="add-learning-materials"
        layout="vertical"
        form={form}
        name="add-chapter"
        onValuesChange={handleFormValuesChange}
        initialValues={{icon: 1}}
      >
        <Row>
          <Col span={6} className="setup-time-title">Thông tin chung</Col>
          <Col span={18} className="learning-materials-content">
            <Form.Item
              label="Mã"
              name="code"
              rules={[
                { required: true, message: "Thông tin này bắt buộc" },
              ]}
            >
              <FormInput placeholder="Nhập mã học liệu" />
            </Form.Item>
            <Form.Item
              label="Tiêu đề"
              name="title"
              rules={[
                { required: true, message: "Thông tin này bắt buộc" },
                { max: 255, message: "Tiêu đề không vượt quá 255 kí tự" },
              ]}
            >
              <FormInput placeholder="Nhập tiêu đề" />
            </Form.Item>
            <Form.Item label="Mô tả" name="description">
              <FormInput placeholder="Nhập mô tả" />
            </Form.Item>
            <Form.Item label="Icon" name="icon">
              <Select defaultValue={1} style={{ width: 120 }}>
                {multimediaIconList.map((it) => (
                  <Option key={it.value} value={it.value}>
                    <img src={it.iconUrl} alt={it.type} style={{ width: "20px", height: "20px" }} />
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {!isEdit && multimediaType !== 'text' || isEdit && detail?.module !== "text" ? (
          <Row>
            <Col span={6} className="setup-time-title mt-1">Chọn file học liệu</Col>
            <Col span={18} className="learning-materials-content">
              <Radio.Group>
                <div className="radio-item">
                  <Typography.Title level={5}>{renderTitle()} từ thiết bị</Typography.Title>
                  <Form.Item
                    label={renderTitle()}
                    name="video"
                    className="mb-0"
                    rules={[
                      { required: true, message: "Thông tin này bắt buộc" },
                    ]}
                  >
                    <FileUploader
                      beforeUpload={beforeUpload}
                      listType="picture"
                      accept={returnAccept()}
                      maxCount={1}
                      onChange={handleChangeFileUpload}
                      onRemove={() => {
                        setFileUpload("")
                      }}
                      fileList={fileListMain}
                      prefix={getS3Prefix()}
                    >
                      <Button icon={<UploadOutlined />}>Tải {renderTitle()}</Button>
                    </FileUploader>
                  </Form.Item>
                  {multimediaType === 'xAPI' ? <p>Vui lòng tải tệp xAPI, Dung lượng tối đa 100MB</p> : null}
                  {multimediaType === 'PDF' ? <p>Vui lòng tải tệp PDF, Dung lượng tối đa 100MB</p> : null}

                  {
                    (uploadComplete && fileUpload) || isEdit ? (
                      RenderDetail(isEdit ? detail?.module : multimediaType)
                    ) : null}
                  haha===========================
                </div>
              </Radio.Group>
            </Col>
          </Row>
        ) : (
          <Row className="mb-5 mt-4">
            <Col span={6} className="setup-time-title">Văn bản</Col>
            <Col span={18} className="learning-materials-content">
              <Form.Item
                name="text"
                rules={[
                  { required: multimediaType === 'text', message: "Thông tin này bắt buộc" },
                ]}
              >
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  formats={FORMAT_REACT_QUILL}
                  placeholder="Nhập text"
                  className="add-learning-materials-popup"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Drawer>
  );
};

export default LearningMaterialDrawer;

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ['blockquote', 'code-block'],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['formula', 'link'],
  ],
};
