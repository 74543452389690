import {
  Form,
  Layout,
  message,
  Switch,
  TableColumnsType,
  TableProps
} from 'antd';
import { isEmpty } from "lodash";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageHeader from "../../components/page-header/PageHeader";
import TableData from "../../components/table-data/TableData";
import { DataType } from "../../types/listUsers";
import "./index.scss";

import FormInput from "../../components/form-input/FormInput";
import { getAccountConfiguration, saveAccountConfiguration } from "../../service/account-configuration";
import { HelpService } from "../../service/helper.service";
import { globalStore } from '../../stores/stores';
import { Permission } from '../../layout/sidebar/permission.enum';

type ListStudentsProps = {
  title?: string
}

const ListAccountConfiguration: FunctionComponent<ListStudentsProps> = ({ title }) => {
  const helpService = new HelpService();

  const [loading, setLoading] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [defaultArr, setDefaultArr] = useState<any>([])
  const [detailData, setDetailData] = useState<any>({})
  const navigate = useNavigate();
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const getDefaultConfig = async () => {
    await getAccountConfiguration().then((res) => {
      const data = res.data.payload
      setDefaultArr(JSON.parse(data))
      console.log(JSON.parse(data))
      setDetailData(res.data)
    }).catch((err) => {
      if (err.response?.status === 403) {
        navigate("/error/403");
      } else {
        console.error(err);
      }
    })
  }

  const saveData = async () => {
    const data = {
      id: detailData?.id,
      code: detailData?.code,
      payload: JSON.stringify(defaultArr)
    }
    await saveAccountConfiguration(data).then((res) => {
      if (res.status === 200) {
        message.success("Lưu thông tin thành công")
        getDefaultConfig()
      }
    })
  }

  useEffect(() => {
    getDefaultConfig();
  }, []);

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_: any, record: any, index: number) => {
        const handleChange = (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
          const arr = [...defaultArr]
          arr[index].status = checked
          setDefaultArr(arr)
        }

        return (
          [0, 1, 2, 7].includes(index) ?
            <Switch defaultChecked={record.status}
              checked={record.status}
              onChange={handleChange} />
            : null
        )
      },
      width: 30
    },
    {
      title: 'Tham số cấu hình',
      dataIndex: 'name',
      key: 'name',
      width: 20
    },
    {
      title: 'Giá trị',
      dataIndex: 'value',
      key: 'value',
      width: 20,
      render: (_: any, record: any, index: number) => {
        const handleChangeInput = (value: string, index: number) => {
          const arr = [...defaultArr]
          arr[index].value = value
          setDefaultArr(arr)
        }

        return (
          index === 0 ? null :
            <FormInput value={record.value} placeholder="Nhập giá trị" disabled={!record?.status && ![3, 4, 5, 6].includes(index)} onChange={(e) => handleChangeInput(e.target.value, index)} />
        )
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: 30
    },
  ]

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
    }));
  }, [defaultArr]);

  const listBreadcrumb = [
    {
      title: 'Quản trị cơ sở',
    },
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {

  };

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === 'createUser') {

        }
        if (name === "filterForm") {
        }
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper padding-none">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} accountConfiguration={true} cancelSave={getDefaultConfig} saveModalConfirm={saveData} />
            </div>

          </div>
        </div>
        {
          !isEmpty(defaultArr) ?
            <TableData dataTable={defaultArr} dataColumns={newColumns}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              pageSize={pageSize}
              pagination={false}
              loadingTable={loading}
              scroll={{ x: 1500, y: 600 }}
              totalRecords={totalRecords}
              handleTableChangeProps={handleTableChange}
            /> : null
        }
      </Form.Provider>
    </Layout>
  );
};

export default ListAccountConfiguration;
