import { FilePdfOutlined, FundProjectionScreenOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, DatePicker, message, notification, Space, Spin, TimePicker } from "antd";
import locale from "antd/es/locale/vi_VN";
import dayjs from 'dayjs';
import "dayjs/locale/vi";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import _ from 'lodash';
import { useEffect, useState, useTransition } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomCard from '../../../../components/custom-card/CustomCard';
import { useDirty } from '../../../../contexts/DirtyProvider';
import { getCourseRoadmap, updateCourseRoadmap } from "../../../../service/course-construct";
import { RegistrationClassTabProps } from "../registration-class";
import './route.scss';

dayjs.extend(customParseFormat);
dayjs.extend(timezone);



function RouteTab(props: RegistrationClassTabProps) {
  const { currentTab, dataDetail, setCheckChange } = props;

  const { id, classId } = useParams()
  const dateFormatAPI = 'YYYY-MM-DD';
  const dateFormatHTML = 'DD/MM/YYYY';
  const timeFormat = 'HH:mm';
  const location = useLocation();
  const [isPending, startTransition] = useTransition();
  const { setDirty } = useDirty();
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const [alertSaveChangeRoute, contextSaveChangeRoute] = notification.useNotification();
  const [dataRoute, setDataRoute] = useState<any[]>([]);
  const [dataChangeRoute, setDataChangeRoute] = useState<any[]>([]);

  const combineDateTime = (dateStr: string, timeStr: string) => {
    const date = new Date(dateStr);
    const [hours, minutes] = timeStr.split(':').map(Number);
    date.setHours(hours, minutes);
    return date.toISOString();
  };


  useEffect(() => {
    setDataRoute([]);
    alertSaveChangeRoute.destroy();
    if (props.currentTab === '2') {
      getRegisterClassRoute();
    }
  }, [props.currentTab]);

  const getRegisterClassRoute = async () => {
    startTransition(() => {
      setLoadingContent(true);

      getCourseRoadmap({ enrollClassId: classId, courseId: id }).then((res) => {
        if (res.status === 200) {
          let courseRoadmapAPI = res.data.data;
          let courseRoadmapRender: any[] = [];
          courseRoadmapAPI.forEach((section: any, i: number) => {
            let itemSectionRender = {
              sectionId: section.sectionId,
              title: section.name,
              key: JSON.stringify(section.sectionId),
              children: section.sequences.map((sequence: any) => {
                let defaultSequenceValue: any[] = [];
                let defaultSequenceTimeValue: any[] = [];
                if (sequence.roadmapStartedDate !== null && sequence.roadmapEndedDate !== null) {
                  defaultSequenceValue = [dayjs(sequence.roadmapStartedDate, dateFormatAPI), dayjs(sequence.roadmapEndedDate, dateFormatAPI)];
                  defaultSequenceTimeValue = [dayjs(sequence.roadmapStartedDate).tz('Asia/Bangkok'), dayjs(sequence.roadmapEndedDate).tz('Asia/Bangkok')];
                }
                return {
                  sequenceId: sequence.sequenceId,
                  title: sequence.name,
                  key: JSON.stringify(sequence.sequenceId),
                  roadmapEndedDate: sequence.roadmapEndedDate,
                  roadmapStartedDate: sequence.roadmapStartedDate,
                  enrollClassRoadmapid: sequence.enrollClassRoadmapid,
                  enrollClassId: location.state.id,
                  minDate: dayjs(location.state.closedDate, dateFormatAPI),
                  maxDate: dayjs(location.state.closingDate, dateFormatAPI),
                  defaultValue: defaultSequenceValue,
                  defaultTimeValue: defaultSequenceTimeValue,
                  children: sequence.units.map((unit: any) => {
                    let defaultUnitValue: any[] = [];
                    let defaultUnitTimeValue: any[] = [];
                    let defaultUnitMin: any = null;
                    let defaultUnitMax: any = null;

                    if (unit.roadmapStartedDate !== null && unit.roadmapEndedDate !== null) {
                      defaultUnitValue = [dayjs(unit.roadmapStartedDate, dateFormatAPI), dayjs(unit.roadmapEndedDate, dateFormatAPI)];
                      defaultUnitTimeValue = [dayjs(unit.roadmapStartedDate).tz('Asia/Bangkok'), dayjs(unit.roadmapEndedDate).tz('Asia/Bangkok')];
                    }

                    if (sequence.roadmapStartedDate !== null && sequence.roadmapEndedDate !== null) {
                      defaultUnitMin = dayjs(sequence.roadmapStartedDate, dateFormatAPI);
                      defaultUnitMax = dayjs(sequence.roadmapEndedDate, dateFormatAPI);
                    } else {
                      defaultUnitMin = dayjs(location.state.closedDate, dateFormatAPI);
                      defaultUnitMax = dayjs(location.state.closingDate, dateFormatAPI);
                    }

                    return {
                      unitId: unit.unitId,
                      title: unit.name,
                      key: JSON.stringify(unit.unitId),
                      roadmapEndedDate: unit.roadmapEndedDate,
                      roadmapStartedDate: unit.roadmapStartedDate,
                      enrollClassRoadmapid: unit.enrollClassRoadmapid,
                      enrollClassId: location.state.id,
                      minDate: defaultUnitMin,
                      maxDate: defaultUnitMax,
                      defaultValue: defaultUnitValue,
                      defaultTimeValue: defaultUnitTimeValue,
                    }
                  }),
                }
              }),
            }
            courseRoadmapRender.push(itemSectionRender);
            if (i === (courseRoadmapAPI.length - 1)) {
              setDataRoute(courseRoadmapRender);
            }
          });
          setLoadingContent(false);

        }
      }).catch((error) => {
        setLoadingContent(false);
      });
    });
  };
  const updateDataRoute = (changeDataRoute: any, id: number, newValue: any): boolean => {
    for (let i = 0; i < changeDataRoute.length; i++) {
      if (changeDataRoute[i].key === JSON.stringify(id)) {
        changeDataRoute[i] = { ...changeDataRoute[i], ...newValue };
        return changeDataRoute;
      }
      if (changeDataRoute[i].children) {
        const found = updateDataRoute(changeDataRoute[i].children, id, newValue);
        if (found) return true;
      }
    }
    return false;
  };
  const formatChangeRouteToApi = (itemChangeRoute: any) => {
    const getDataChangeRoute = [...dataChangeRoute];
    let checkItemChangeRouteIndex: any;
    let pushItemChangeRoute: any;
    let idItemChangeRoute: any;

    pushItemChangeRoute = {
      enrollClassRoadmapid: itemChangeRoute.enrollClassRoadmapid,
      enrollClassId: itemChangeRoute.enrollClassId,
      roadmapStartedDate: new Date(itemChangeRoute.roadmapStartedDate).toISOString(),
      roadmapEndedDate: new Date(itemChangeRoute.roadmapEndedDate).toISOString(),
      unitId: 0,
      sequenceId: 0,
      key: '',
      timeStartInDay: itemChangeRoute.timeStartInDay + ":00",
      timeEndInDay: itemChangeRoute.timeEndInDay + ":00",
    }

    if (_.has(itemChangeRoute, 'sequenceId')) {
      delete pushItemChangeRoute.unitId;
      pushItemChangeRoute.sequenceId = itemChangeRoute.sequenceId;
      pushItemChangeRoute.key = JSON.stringify(itemChangeRoute.sequenceId);
      idItemChangeRoute = itemChangeRoute.sequenceId;
      checkItemChangeRouteIndex = getDataChangeRoute.findIndex((item: any) => item.sequenceId === itemChangeRoute.sequenceId);
    }

    if (_.has(itemChangeRoute, 'unitId')) {
      delete pushItemChangeRoute.sequenceId;
      pushItemChangeRoute.unitId = itemChangeRoute.unitId;
      pushItemChangeRoute.key = JSON.stringify(itemChangeRoute.unitId);
      idItemChangeRoute = itemChangeRoute.unitId;
      checkItemChangeRouteIndex = getDataChangeRoute.findIndex((item: any) => item.unitId === itemChangeRoute.unitId);
    }

    if (checkItemChangeRouteIndex > -1) {
      getDataChangeRoute[checkItemChangeRouteIndex] = pushItemChangeRoute;
    } else {
      getDataChangeRoute.push(pushItemChangeRoute);
    }

    setDataChangeRoute(getDataChangeRoute);
  };
  const findDataRoute = (changeDataRoute: any, id: number): any => {
    for (const item of changeDataRoute) {
      if (item.key === JSON.stringify(id)) {
        return item;
      }
      if (item.children) {
        const result = findDataRoute(item.children, id);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  const formatChangeDate = (id: number, dates: any, dateStrings: [string, string], isUpdateSequence?: boolean) => {
    if (dateStrings[1] !== '') {
      let changeDataRoute = [...dataRoute];
      let newDateTimeRange = {
        defaultValue: dates,
        roadmapStartedDate: dayjs(dateStrings[0], dateFormatHTML).startOf('day').toISOString(),
        roadmapEndedDate: dayjs(dateStrings[1], dateFormatHTML).endOf('day').toISOString(),
        openingDate: dataDetail?.openingDate,
        openingTime: dataDetail?.openingTime,
      };

      if (isUpdateSequence) {
        changeDataRoute.forEach((section: any) => {
          const sequence = section.children.find((sequence: any) => sequence.sequenceId === id);
          if (sequence) {
            sequence.children.forEach((unit: any) => {
              updateDataRoute(changeDataRoute, unit.unitId, {
                minDate: dayjs(newDateTimeRange.roadmapStartedDate).startOf('day'),
                maxDate: dayjs(newDateTimeRange.roadmapEndedDate).endOf('day'),
              });
            });
          }
        });
      }

      updateDataRoute(changeDataRoute, id, newDateTimeRange);
      setDataRoute(changeDataRoute);
      formatChangeRouteToApi(findDataRoute(changeDataRoute, id));
    }
  };


  const formatChangeTime = (id: number, times: any, timeStrings: [string, string]) => {

    if (timeStrings[1] !== '') {

      let changeDataRoute = dataRoute;
      const itemChange = findDataRoute(changeDataRoute, id);

      let newDateTimeRange = {
        defaultTimeValue: times,
        roadmapStartedDate: combineDateTime(itemChange.roadmapStartedDate, timeStrings[0]),
        roadmapEndedDate: combineDateTime(itemChange.roadmapEndedDate, timeStrings[1]),
        timeStartInDay: timeStrings[0],
        timeEndInDay: timeStrings[1],
      }

      updateDataRoute(changeDataRoute, id, newDateTimeRange);
      itemChange.roadmapStartedDate = newDateTimeRange.roadmapStartedDate;
      itemChange.roadmapEndedDate = newDateTimeRange.roadmapEndedDate;
      itemChange.timeStartInDay = newDateTimeRange.timeStartInDay;
      itemChange.timeEndInDay = newDateTimeRange.timeEndInDay;
      setDataRoute(changeDataRoute);
      formatChangeRouteToApi(itemChange);
    }
  };


  const saveChangeRoute = () => {
    alertSaveChangeRoute.destroy();
    setDirty(false);

    updateCourseRoadmap(dataChangeRoute)
      .then((res) => {
        const dataRes = res.data;
        message.success(dataRes.message);
      })
      .catch((error) => {
        message.error("Lỗi khi cập nhật dữ liệu");
      });
  };

  const openSaveAlert = () => {
    alertSaveChangeRoute.open({
      message: 'Bạn có chắc chắn muốn lưu thay đổi?',
      description: 'Các thay đổi bạn thực hiện chưa được lưu',
      icon: <FundProjectionScreenOutlined />,
      duration: 0,
      btn: (
        <Space>
          <Button onClick={saveChangeRoute} type="primary">
            Lưu thay đổi
          </Button>
          <Button
            onClick={() => {
              alertSaveChangeRoute.destroy();
              setDirty(false);
            }}
          >
            Hủy bỏ
          </Button>
        </Space>
      ),
    });
  };

  useEffect(() => {

    setCheckChange(dataChangeRoute?.length)
  }, [currentTab, dataChangeRoute]);

  useEffect(() => {
    if (currentTab !== "2") {
      setDataChangeRoute([])
    }
  }, [currentTab]);

  return (
    <div className="route-tab">
      <ConfigProvider locale={locale}>
        <Spin spinning={loadingContent}>
          {contextSaveChangeRoute}
          <section className="wrapper-settings-route">
            <div className="registration-class-route">
              <CustomCard className="wrapper-settings--card">
                {!loadingContent ? dataRoute.length > 0 ? dataRoute.map((section: any) => (
                  <div key={section.sectionId} className='registration-class-route__item'>
                    <div className='registration-class-route__item__section'>
                      <div className='title'>{section.title}</div>
                      {section.children.map((sequence: any) => (
                        <div key={sequence.sequenceId} className='registration-class-route__item__sequence'>
                          <div className='top'>
                            <div className='title'>
                              <span><FundProjectionScreenOutlined /></span>
                              <span>{sequence.title}</span>
                            </div>
                            <div className='date-time flex'>
                              <DatePicker.RangePicker
                                className='mr-2'
                                allowClear={false}
                                defaultValue={sequence.defaultValue}
                                format={dateFormatHTML}
                                placeholder={["Bắt đầu", "Kết thúc"]}
                                disabledDate={(currentDate) => {
                                  return currentDate.isBefore(dayjs(dataDetail?.openingDate), 'day');
                                }}
                                onChange={(dates, dateStrings) => formatChangeDate(sequence.sequenceId, dates, dateStrings, true)}
                              />
                              <TimePicker.RangePicker
                                allowClear={false}
                                defaultValue={sequence.defaultTimeValue}
                                disabled={_.isEmpty(sequence.defaultValue)}
                                placeholder={["Bắt đầu", "Kết thúc"]}
                                format={timeFormat}
                                disabledTime={(current) => {
                                  const startTime = dayjs(sequence.roadmapStartedDate);
                                  const endTime = dayjs(sequence.roadmapEndedDate);
                                  const openingDate = dayjs(dataDetail?.openingDate);
                                  const openingTime = dayjs(dataDetail?.openingTime, 'HH:mm:ss');

                                  if (startTime.isSame(openingDate, 'day')) {
                                    const disabledHours = () => {
                                      const hours: number[] = [];
                                      for (let h = 0; h < openingTime.hour(); h++) {
                                        hours.push(h);
                                      }
                                      return hours;
                                    };

                                    const disabledMinutes = (selectedHour: number) => {
                                      const minutes: number[] = [];
                                      if (selectedHour === openingTime.hour()) {
                                        for (let m = 0; m < openingTime.minute(); m++) {
                                          minutes.push(m);
                                        }
                                      }
                                      return minutes;
                                    };

                                    return {
                                      disabledHours,
                                      disabledMinutes,
                                      disabledSeconds: () => [],
                                    };
                                  } else {
                                    const disabledHours = () => {
                                      const hours: number[] = [];
                                      for (let h = 0; h < 24; h++) {
                                        if (h < startTime.hour() || h > endTime.hour()) {
                                          hours.push(h);
                                        }
                                      }
                                      return hours;
                                    };

                                    const disabledMinutes = (selectedHour: number) => {
                                      const minutes: number[] = [];
                                      if (selectedHour === startTime.hour()) {
                                        for (let m = 0; m < startTime.minute(); m++) {
                                          minutes.push(m);
                                        }
                                      } else if (selectedHour === endTime.hour()) {
                                        for (let m = endTime.minute() + 1; m < 60; m++) {
                                          minutes.push(m);
                                        }
                                      }
                                      return minutes;
                                    };

                                    return {
                                      disabledHours,
                                      disabledMinutes,
                                      disabledSeconds: () => [],
                                    };
                                  }
                                }}
                                onChange={(times, timeStrings) => formatChangeTime(sequence.sequenceId, times, timeStrings)}
                              />
                            </div>
                          </div>
                          <div className='bottom'>
                            {sequence.children.map((unit: any) => (
                              <div key={unit.unitId} className='registration-class-route__item__unit'>
                                <div className='title'>
                                  <span><FilePdfOutlined /></span>
                                  <span>{unit.title}</span>
                                </div>
                                <div className='date-time flex'>
                                  <DatePicker.RangePicker
                                    className='mr-2'
                                    allowClear={false}
                                    defaultValue={unit.defaultValue}
                                    format={dateFormatHTML}
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    minDate={dayjs(sequence.roadmapStartedDate).startOf('day')}
                                    maxDate={dayjs(sequence.roadmapEndedDate).endOf('day')}
                                    disabledDate={(currentDate) => {
                                      return currentDate.isBefore(dayjs(dataDetail?.openingDate), 'day');
                                    }}
                                    onChange={(dates, dateStrings) => formatChangeDate(unit.unitId, dates, dateStrings, false)}
                                  />
                                  <TimePicker.RangePicker
                                    allowClear={false}
                                    defaultValue={unit.defaultTimeValue}
                                    disabled={_.isEmpty(unit.defaultValue)}
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    format={timeFormat}
                                    disabledTime={(currentDate) => {
                                      const startTime = dayjs(sequence.roadmapStartedDate);
                                      const endTime = dayjs(sequence.roadmapEndedDate);

                                      if (startTime.isValid() && endTime.isValid() && startTime.isSame(unit?.roadmapStartedDate, 'day')) {
                                        const disabledHours = () => {
                                          const hours = [];
                                          for (let h = 0; h < 24; h++) {
                                            if (h < startTime.hour() || h > endTime.hour()) {
                                              hours.push(h);
                                            }
                                          }
                                          return hours;
                                        };

                                        const disabledMinutes = (selectedHour: any) => {
                                          const minutes = [];
                                          if (selectedHour === startTime.hour()) {
                                            for (let m = 0; m < startTime.minute(); m++) {
                                              minutes.push(m);
                                            }
                                          } else if (selectedHour === endTime.hour()) {
                                            for (let m = endTime.minute() + 1; m < 60; m++) {
                                              minutes.push(m);
                                            }
                                          }
                                          return minutes;
                                        };

                                        return {
                                          disabledHours,
                                          disabledMinutes,
                                          disabledSeconds: () => [],
                                        };
                                      }

                                      return {
                                        disabledHours: () => [],
                                        disabledMinutes: () => [],
                                        disabledSeconds: () => [],
                                      };
                                    }}


                                    onChange={(times, timeStrings) => formatChangeTime(unit.unitId, times, timeStrings)}
                                  />
                                </div>
                              </div>
                            ))}

                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )) : (
                  <div>Không có dữ liệu</div>
                ) : null}
              </CustomCard>
              {!loadingContent ?
                <div className="save-button-container">
                  <Button onClick={openSaveAlert} type="primary"
                    disabled={dataChangeRoute?.length === 0}
                  >Lưu</Button>
                </div> : null}
            </div>

          </section>

        </Spin>
      </ConfigProvider>
    </div>
  );
}

export default RouteTab;
