import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, TableColumnType, Typography } from 'antd';

import FormInput from "../../../../components/form-input/FormInput";
import './filter-table.scss'
import { DownloadOutlined, DownOutlined, PlusOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { DataType } from "../../../../types/listUsers";
import { CheckboxOptionType } from "antd/lib";
import { Permission } from '../../../../layout/sidebar/permission.enum';
import { globalStore } from '../../../../stores/stores';

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  checkedList: Array<string>,
  setCheckedList: (value: string[]) => void,
  dataColumns: TableColumnType<DataType>[]
  setExportMode: (value: boolean) => void
}


const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  setExportMode
}) => {
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const hasCreatePermission = permissions?.includes(Permission.CreateStudentAccountUpdate);
  const hasExportPermission = permissions?.includes(Permission.ExportStudentAccountView);

  return (
    <>
      <div className="heading-extra">
        <Row gutter={8}>
          <Col md={{ span: !hasCreatePermission ? 7 : 5 }} 
              lg={{ span: !hasCreatePermission ? 7 : 5 }} 
              xl={{ span: !hasCreatePermission ? 7 : 5 }} 
              xs={{ span: !hasCreatePermission ? 7 : 5 }}
          >
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: !hasCreatePermission ? 14 : 9 }} 
              lg={{ span: !hasCreatePermission ? 14 : 9 }} 
              xl={{ span: !hasCreatePermission ? 14 : 9 }} 
              xs={{ span: !hasCreatePermission ? 14 : 9 }}
          >
            <div className="heading-search">
              <FormInput placeholder="Nhập tên, email để tìm kiếm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          {
            hasCreatePermission ? 
            <Col md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 7 }}>
              <div className="heading-resigter">
                <Button onClick={handleOpen} className="btn btn-primary">
                  <PlusOutlined />
                  <Typography.Text>Tạo tài khoản excel</Typography.Text>
                </Button>
              </div>
            </Col> : null
          }
          {
            hasExportPermission ?
            <Col md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} xs={{ span: 3 }}>
              <div className="heading-resigter">
                <Button className="btn" onClick={() => setExportMode(true)}>
                  <DownloadOutlined/>
                </Button>
              </div>
            </Col> : null
          }
        </Row>
      </div>
    </>
  );
};

export default FilterTable;
