import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { staticReportStore } from "../../../../../../stores/stores";
import useChartExport from "../../../../../statistical-report/for-instructors/components/chart/useChartExport";
import { Col, Empty, Image, Modal, Row, Space, TableColumnsType, TableProps, Tag, Typography } from "antd";
import StatisticalReportCard from "../component/StatisticalReportCard";
import TableData from "../../../../../../components/table-data/TableData";
import { DownloadOutlined, FileImageOutlined, PaperClipOutlined, PlayCircleOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { getChartSurveyAnswers } from "../../../../../../service/course-construct/survey-plan";

type Props = {
    selectedQuiz: number,
    quizName?: string,
    quizType?: number,
}

const QuizReportTable = (props: Props) => {
    const {selectedQuiz, quizType, quizName} = props;
    const [searchValue, setSearchValue] = useState<string>("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [statusOrder, setStatusOrder] = useState<string>("");
    const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [indexAudioVisible, setIndexAudioVisible] = useState(0);
    const [listDataAnswers, setListDataAnswers] = useState<any>([]);
    const [isModalVideoVisible, setIsModalVideoVisible] = useState(false);

    // Hook for managing chart exports
    const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

    const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

    // get type
    const getQuizType = () => {
        switch (quizType) {
            case 9:
                return "Câu hỏi dạng văn bản ngắn";
            case 12:
                return "Câu hỏi đính kèm file";
            case 13:
                return "Câu hỏi dạng ghi hình ";
            case 14:
                return "Câu hỏi dạng ghi âm";
            default:
                return "";
        }
    }

    // Show image preview
    const handlePreview = () => {
        setPreviewVisible(true);
    };

    // Show audio
    const handleShowAudio = (index: number) => {
        if(index === indexAudioVisible) {
            setIndexAudioVisible(0);
        } else {
            setIndexAudioVisible(index);
        }
    }

    // Show video
    const handleShowVideo = () => {
        setIsModalVideoVisible(true);
    }

    const handleCancel = () => {
        setIsModalVideoVisible(false);
    }

    // Filter Search
    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setSearchValue(value);
        if (value.length === 0) {
            setSearchValue("");
        }
    };

    const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === "Enter") {
            setPageNumber(1);
            fetchData();
        }
    };

    const handleSubmitSearch = () => {
        setPageNumber(1);
        fetchData();
    };

    // fetch Data
    const fetchData = async () => {
        setLoading(true);
        const dataReq = {
            quizId: selectedQuiz, 
            page: pageNumber,
            size: pageSize,
            keyword: searchValue,
        }

        await getChartSurveyAnswers(dataReq).then((res) => {
            if(res.status === 200) {
                const data = (res.data?.data?.answerText?.content || res.data?.data?.answerMedia?.content).map((item: any) => {
                    return {
                        name: item,
                    }
                });
                setListDataAnswers(data);
                setTotalElements(res.data.data?.answerText?.totalElements || res.data.data?.answerMedia?.totalElements )
                setTimeout(() => {
                    setLoading(false);
                }, 333);
            }
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        }); 
    }

    // Data columns
    const dataColumns: TableColumnsType = [
        {
          title: "",
          dataIndex: "name",
          key: "name",
          width: 20,
          render: (_: any, record, index) => {
            switch (quizType) {
                case 9:
                    return (
                        <Tag className="record-answer">{record.name}</Tag>
                    )
                case 12:
                    return (
                        <div className="record-answer type-file">
                            <div>
                                <PaperClipOutlined style={{color: "rgba(0, 0, 0, 0.45)", fontSize: "18px", marginRight: "8px"}}/>
                                <Typography.Link>{record.name}</Typography.Link>
                            </div>
                            <Space className="download-file">
                                <DownloadOutlined style={{color: "#1677FF", fontSize: "18px"}}/>
                                <Typography.Link>Tải xuống</Typography.Link>
                            </Space>
                        </div>
                    )
                // case 'image':
                //     return (
                //         <div className="record-answer type-image">
                //             <div>
                //                 <FileImageOutlined style={{color: "rgba(0, 0, 0, 0.45)", fontSize: "18px", marginRight: "8px"}}/>
                //                 <Typography.Link onClick={handlePreview}>{record.name}</Typography.Link>
                //                 <Image
                //                     style={{ display: 'none' }}
                //                     src={`https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?1`}
                //                     preview={{
                //                     visible: previewVisible,
                //                     onVisibleChange: (vis) => setPreviewVisible(vis),}}
                //                 />
                //             </div>
                            
                //         </div>
                //     )
                case 13:
                    return (
                        <div className="record-answer type-video">
                            <div>
                                <PlayCircleOutlined style={{color: "#00000073", fontSize: "18px", marginRight: "8px"}}/>
                                <Typography.Link onClick={handleShowVideo}>{record.name}</Typography.Link>
                                <Modal
                                    title={record.name}
                                    className="answer-modal-video"
                                    visible={isModalVideoVisible}
                                    onCancel={handleCancel}
                                    footer={null}
                                    width={800}
                                >
                                    <ReactPlayer
                                    url={`https://www.youtube.com/watch?v=FIwldhCVTus`}
                                    controls
                                    width="100%"
                                    />
                                </Modal>
                            </div>
                            <Space className="download-file">
                                <DownloadOutlined style={{color: "#1677FF", fontSize: "18px"}}/>
                                <Typography.Link>Tải xuống</Typography.Link>
                            </Space>
                        </div>
                    )
                case 14:
                    return (
                        <div className="record-answer type-image">
                            <div className="content-answer flex">
                                <PlayCircleOutlined onClick={() => handleShowAudio(index + 1)} style={{color: "#00000073", fontSize: "18px"}}/>
                                <Typography.Link onClick={() => handleShowAudio(index + 1)} >{record.name}</Typography.Link>
                                {indexAudioVisible === (index + 1) && (
                                    <div>
                                        <audio controls style={{height:'20px'}}>
                                            <source src={`https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3`} type="audio/mpeg" />
                                            Trình duyệt của bạn không hỗ trợ tính năng phát audio.
                                        </audio>
                                    </div>
                                )}
                            </div>
                            <Space className="download-file">
                                <DownloadOutlined style={{color: "#1677FF", fontSize: "18px"}}/>
                                <Typography.Link>Tải xuống</Typography.Link>
                            </Space>
                        </div>
                    )
                default:
                    break;
            }
          }
        },
    ];

    // Sort
    const handleTableChange: TableProps["onChange"] = (
        pagination,
        filters,
        sorter: any
    ) => {
        const data =
        sorter.order === "ascend"
            ? "asc"
            : sorter.order === "descend"
            ? "desc"
            : "";
        setStatusOrder(data);
        setFieldSortOrder(sorter.field);
    };

    // useEffect
    useEffect(() => {
        if(quizType === 9 || quizType === 12 || quizType === 13 || quizType === 14) {
            fetchData();
        }
    }, [pageNumber, pageSize, selectedQuiz]);

    useEffect(() => {
        setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
    }, [tab, exportType]);

    return (
        <>
            <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
                <Col span={24} className="col-table">
                    <StatisticalReportCard
                        chartName={getQuizType()}
                        content={
                            listDataAnswers && listDataAnswers?.length > 0 ?
                            <TableData
                                dataTable={listDataAnswers}
                                dataColumns={dataColumns}
                                setPageNumber={setPageNumber}
                                setPageSize={setPageSize}
                                pageNumber={pageNumber}
                                pageSize={pageSize}
                                pagination={true}
                                scroll={{ x: 900, y: 600 }}
                                totalRecords={totalElements}
                                loadingTable={loading}
                                handleTableChangeProps={handleTableChange}
                            />
                            :
                            <Empty className="empty-table" description="Không có dữ liệu"/>
                        }
                        isChart={false}
                        searchValue={searchValue}
                        handleChangeInput={handleChangeInput}
                        handleSearch={handleSearch}
                        handleSubmitSearch={handleSubmitSearch}
                        exportOptions={[
                        {
                            label: "Hình ảnh (PNG)",
                            action: () =>
                            handleExportImage(tab, `${quizName}`, "image/png"),
                        },
                        {
                            label: "PDF",
                            action: () =>
                            handleExportImage(
                                tab,
                                `${quizName}`,
                                "application/pdf"
                            ),
                        },
                        ]}
                    />
                </Col>
            </Row>
        </>
    )
}

export default QuizReportTable;