export interface DataType {
    id: number,
    name: string,
    code: string,
}

export const dataStatus = [
  {
    value: true,
    label: "Hoạt động"
  },
  {
    value: false,
    label: "Không hoạt động"
  }
]

export const validateFieldCreate = ["name", "code", "typeOrganization"]

export const validateFieldFilter = []
