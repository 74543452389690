import { ArrowLeftOutlined, CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Spin, Collapse, Typography } from 'antd';
import FormInput from '../../../../../../form-input/FormInput';
import './survey-answers-list.scss';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { CollapseProps } from 'antd/lib';
import { getIdIndexDB } from '../../../../../../../pages/page-builder/grapesjs-service';
import { getAllQuizBySurvey } from '../../../../../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz';
import { convertQuillToString } from '../../../../../../../utils/convertQuillToString';
import { getDetailSurveyAnswer } from '../../../../../../../service/course-construct/survey-plan';
import { QuestionTypeEnum } from '../../../../../../../constants';
import { useNavigate } from 'react-router-dom';
import { HelpService } from '../../../../../../../service/helper.service';
const { Panel } = Collapse;

type SurveyAnswersListProps = {
  handelTranferIdQuestion: (val: any) => void;
};

const SurveyAnswersList = (props: SurveyAnswersListProps) => {
  const { handelTranferIdQuestion } = props;
  const navigate = useNavigate();
  const helpService = new HelpService();
  const [itemResultQuestions, setItemResultQuestions] = useState<any[]>([]);
  const [itemFormatQuestions, setItemFormatQuestions] = useState<any[]>([]);
  const [defaultActiveKey, setDefaultActiveKey] = useState<string[]>(['1', '2', '3', '4', '5']);
  const [loadingSurveyAnswers, setLoadingSurveyAnswers] = useState<boolean>(true);
  const [headerTitle, setHeaderTitle] = useState<string>('');
  const [keyWord, setKeyWord] = useState<string>('');
  const [quizDetailId, setQuizDetailId] = useState<number>(0);
  const [recordId, setRecordId] = useState<number>(0);
  const [apiCallCount, setApiCallCount] = useState<number>(0);
  const [apiCallCountTotal, setApiCallCountTotal] = useState<number>(2);
  const [activeChild, setActiveChild] = useState<number>(0);
  const [uuidQuestions, setUuidQuestions] = useState<number[]>([
    QuestionTypeEnum.CHOOSE_TEXT,
    QuestionTypeEnum.CHOOSE_IMAGE,
    QuestionTypeEnum.CHOOSE_VIDEO,
    QuestionTypeEnum.TRUE_OR_FALSE,
    QuestionTypeEnum.DROP_DOWN,
    QuestionTypeEnum.CHOOSE_MULTI_TEXT,
    QuestionTypeEnum.CHOOSE_MULTI_IMAGE,
    QuestionTypeEnum.CHOOSE_MULTI_VIDEO,
  ]);

  const [contentQuestions, setContentQuestions] = useState<number[]>([
    QuestionTypeEnum.SHORT_TEXT,
  ]);

  const [fileQuestions, setFileQuestions] = useState<number[]>([
    QuestionTypeEnum.FILE_UPLOAD,
    QuestionTypeEnum.VIDEO,
    QuestionTypeEnum.MP3,
  ]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getIdIndexDB('current-survey', (val: any) => {
        setApiCallCount(0);
        setHeaderTitle(val.quizDetail.name);
        setQuizDetailId(val.quizDetail.id);
        setRecordId(val.record.id);
        getQuestionsAndAnswers(val.quizDetail.id, val.record.id);
      });
    }
  }, []);

  useEffect(() => {
    if (apiCallCount === apiCallCountTotal) {
      const newItemResultQuestions = itemResultQuestions;
      const newItemFormatQuestions = itemFormatQuestions;
      newItemFormatQuestions.forEach((x: any) => {
        const quizzes = x.quizzes;
        quizzes.forEach((y: any) => {
          y.answers = JSON.parse(y.answers);
          y.answers = y.answers.map((answer: any) => {
            return {
              ...answer,
              isTrue: false,
            };
          });
        });
      });
      const updatedItemFormatQuestions = newItemFormatQuestions.map((question) => {
        question.quizzes = question.quizzes.map((quiz: any) => {
          // uuidQuestions
          if (uuidQuestions.indexOf(quiz.type) > -1) {
            const matchingResult = newItemResultQuestions.find((result) => result.quizId === quiz.id);
            if (matchingResult) {
              quiz.answers = quiz.answers.map((answer: any) => {
                const matchingAnswer = matchingResult.resultJson.find(
                  (result: any) => result.uuid === answer.uuid
                );
                if (matchingAnswer) {
                  return {
                    ...answer,
                    isTrue: matchingAnswer.isTrue,
                  };
                }
                return answer;
              });
            }
            return quiz;
          }
          // contentQuestions
          if (contentQuestions.indexOf(quiz.type) > -1) {
            const matchingResult = newItemResultQuestions.find((result) => result.quizId === quiz.id);
            if (matchingResult) {
              return {
                ...quiz,
                resultJson: matchingResult.resultJson,
              };
            }
            return quiz;
          }
          // fileQuestions
          if (fileQuestions.indexOf(quiz.type) > -1) {
            const matchingResult = newItemResultQuestions.find((result) => result.quizId === quiz.id);
            if (matchingResult) {
              return {
                ...quiz,
                resultFile: matchingResult.resultFile,
              };
            }
            return quiz;
          }
          return quiz;
        });
        return question;
      });
      if (updatedItemFormatQuestions[0].quizzes.length > 0) {
        setActiveChild(updatedItemFormatQuestions[0].quizzes[0].id);
        handelTranferIdQuestion(updatedItemFormatQuestions[0].quizzes[0]);
      } else {
        setActiveChild(0);
        handelTranferIdQuestion({});
      }
      setItemFormatQuestions(updatedItemFormatQuestions);
      helpService.closeProcess();
    }
  }, [apiCallCount]);

  const getQuestionsAndAnswers = (quizDetailId: number, recordId: number) => {
    helpService.loadingProcess();
    getQuestionsList(quizDetailId);
    getAnswersList(recordId);
  }

  const getQuestionsList = async (id: any) => {
    let bodyData = {
      id: Number(id),
      keyword: keyWord,
    };
    await getAllQuizBySurvey(bodyData).then((res) => {
      const data = res.data;
      setItemFormatQuestions(data);
      data.forEach((x: any, i: number) => {
        if (i === (data.length - 1)) {
          const stringArray = [];
          for (let i = 1; i <= data.length; i++) {
            stringArray.push(i.toString());
          }
          setDefaultActiveKey(stringArray);
          setLoadingSurveyAnswers(false);
          setApiCallCount(x => x + 1);
        }
      });
    });
  }

  const getAnswersList = async (id: any) => {
    await getDetailSurveyAnswer(id).then((res: any) => {
      setItemResultQuestions(res.data.data);
      setApiCallCount(x => x + 1);
    });
  };

  const handleEnterSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleActionSearch();
    }
  };

  const handleActionSearch = () => {
    setApiCallCount(0);
    getQuestionsAndAnswers(quizDetailId, recordId);
  };

  const handleConvertTranferQuestion = (id: number) => {
    setActiveChild(id);
    itemFormatQuestions.forEach((x: any) => {
      const question = x.quizzes.find((y: any) => { return y.id === id });
      if (question) {
        handelTranferIdQuestion(question);
      }
    });
  };

  return (
    <section className="survey-list-question tabs-heading editor-course-editing">
      <div className="survey-list-question__header">
        <ArrowLeftOutlined
          className="arrowleft-icon ml-4 mr-4 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <Typography.Title level={5}>{headerTitle}</Typography.Title>
      </div>
      <div className="end-question-lesson survey-list-question__content">
        <div className="survey-list-question__content__header">
          <Row gutter={[{ xs: 8, xl: 8, md: 8 }, 8]} className="mt-2">
            <Col xl={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <FormInput
                placeholder="Tìm câu hỏi"
                onChange={(e) => { setKeyWord(e.target.value) }}
                onKeyPress={handleEnterSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleActionSearch}
                  />
                }
              />
            </Col>
          </Row>
        </div>
        <div className="survey-list-question__content__collapse">
          <Spin spinning={loadingSurveyAnswers}>
            <Collapse
              bordered={false}
              defaultActiveKey={defaultActiveKey}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />
              }
            >
              {itemFormatQuestions.map((x: any, i: number) => {
                return <Panel key={i + 1} header={x.title} className='collapse__header'>
                  <div className='collapse__content'>
                    {x.quizzes.length > 0 ? x.quizzes.map((y: any, yIndex: number) => {
                      return <div
                        key={'child' + yIndex + 1}
                        className={`collapse__content__child ${activeChild === y.id ? 'active' : ''}`}
                        onClick={() => { handleConvertTranferQuestion(y.id) }}
                      >
                        {convertQuillToString(y.question)}
                      </div>;
                    }) : <div className='collapse__content__empty'>Không có dữ liệu!!!</div>}
                  </div>
                </Panel>
              })}
            </Collapse>
          </Spin>
        </div>
      </div>
    </section>
  )
}

export default SurveyAnswersList;