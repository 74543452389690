import {
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import PageHeader from "../../components/page-header/PageHeader";
import TableData from "../../components/table-data/TableData";
import { getLogsAudit } from "../../service/logs-audit";
import FilterTable from "./components/filter-table/FilterTable";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { globalStore } from "../../stores/stores";
import { Permission } from "../../layout/sidebar/permission.enum";

type LogsAuditProps = {
  title?: string;
};

const LogsAudit = ({
  title,
}: LogsAuditProps) => {

  const [dataLogs, setDataLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const navigate = useNavigate();
  const { personalInfo } = globalStore();
  const permissions = personalInfo?.permissions;

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getLogsAudit(searchParam);
      const { data } = response.data;
      setDataLogs(data?.data);
      setTotalPage(data?.count?._count?.id);
      setLoading(false);
    } catch (error: any) {
      if (error.response?.status === 403) {
        navigate("/error/403");
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchData({
      name: searchValue,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
    });
  }, [pageSize, pageNumber]);

  const dataColumnsLogsAudit: TableColumnsType<any> = [
    { 
      title: "Thời gian thực hiện",
      dataIndex: "code",
      key: "code",
      width: 10,
      render: (_: any, record) =>
        record?.created_at && (
          <Flex className="gap-1">
            <p>{moment(record?.created_at).format("DD/MM/yyyy")}</p>
            <p>{moment(record?.created_at).format("HH:mm:ss")}</p>
          </Flex>
        ),
    },
    {
      title: "Tài khoản",
      dataIndex: "name",
      key: "name",
      width: 15,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.auth_user?.email}</span>
            </div>
          </Flex>
        );
      }
    },
    {
      title: "Mô tả",
      dataIndex: "name",
      key: "name",
      width: 15,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
      },
    },
  ];

  const defaultCheckedList = dataColumnsLogsAudit.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsLogsAudit.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        take: 10,
        skip: 0,
        name: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumnsLogsAudit}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
        </div>
        <TableData
          dataTable={dataLogs}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1200, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default LogsAudit;
