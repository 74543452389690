import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import { FunctionComponent, useEffect } from "react";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { get } from "lodash";
import { staticReportStore } from "../../../../../stores/stores";

type FormFilterProps = {
    showFilter?: boolean
    clearFilter: (form: FormInstance) => void,
    onFinish?: any,
    dataOptions?: any
}

const FormFilter: FunctionComponent<FormFilterProps> = ({ onFinish, showFilter, clearFilter, dataOptions }) => {
    const [form] = useForm();
    const {setIsSelectedUniversity} = staticReportStore();
    const universitySelected = Form.useWatch("university", form);

    useEffect(() => {
      if(universitySelected?.length > 0) {
        setIsSelectedUniversity(true);
      } else {
        setIsSelectedUniversity(false);
      }
    }, [universitySelected])

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form name="filterForm" onFinish={onFinish} form={form}>
          <Row gutter={[{xs: 8, xl: 24, md: 16}, 12]}>
            <Col xl={{span: 5}} md={{span: 4}} sm={{span: 4}} xs={{span: 24}}>
              <Typography.Title level={5}>Trường</Typography.Title>
              <Form.Item name="university">
                <FormItemDropdown modeDropdown="multiple" allowClear placeholder="Tất cả trường" options={get(dataOptions, 'dataUniversity', '')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 5}} md={{span: 4}} sm={{span: 4}} xs={{span: 24}}>
              <Typography.Title level={5}>Cấp</Typography.Title>
              <Form.Item name="level">
                <FormItemDropdown modeDropdown="multiple" allowClear placeholder="Tất cả cấp" options={get(dataOptions, 'dataLevel', '')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 5}} md={{span: 4}} sm={{span: 4}} xs={{span: 24}}>
              <Typography.Title level={5}>Khối</Typography.Title>
              <Form.Item name="unit">
                <FormItemDropdown modeDropdown="multiple" allowClear placeholder="Tất cả khối" options={get(dataOptions, 'dataUnit', '')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 5}} md={{span: 4}} sm={{span: 4}} xs={{span: 24}}>
              <Typography.Title level={5}>Khóa học</Typography.Title>
              <Form.Item name="course">
                <FormItemDropdown modeDropdown="multiple" allowClear placeholder="Tất cả khóa học" options={get(dataOptions, 'dataCourse', '')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 3}} md={{span: 9}} sm={{span: 9}} xs={{span: 24}} className="flex align-end">
              <div className="group-btn--filter">
                <Button className="btn btn-primary filter-btn" htmlType="submit">
                  <FilterOutlined/>
                  <Typography.Text> Lọc </Typography.Text>
                </Button>
                <Button className="btn btn-outlined clear-filter-btn" onClick={() => clearFilter(form)}>
                  <DeleteOutlined/>
                  <Typography.Text> Xóa bộ lọc </Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default FormFilter;