import React, { useEffect, useRef, useState } from "react";
import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
  SendOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import { isEmpty, toNumber } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteNotifications,
  findHavingSchoolForStudent,
  getAllEnterprise,
  getNotification,
  reInit,
  revokeNotification,
  scheduleSendNotification,
  sendNotification,
  unScheduleNotification,
} from "../../../service/notifications";
import {
  EnterpriseProps,
  NotificationProps,
  SchoolProps,
} from "../../../types/notifications";
import { routesConfig } from "../../../config/routes";
import CustomPopover from "../../../components/popover";
import { NotificationStatus } from "../../../constants/notifications";
import CustomModal from "../../../components/modal/custom-modal";
import moment from "moment";
import { headerStore } from "../../../stores/stores";
import "moment/locale/vi";
import { viLocale } from "../../../utils/pickerType";
import dayjs, { Dayjs } from "dayjs";

moment.locale("vi");

function NotificationDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const onReloadHeader = headerStore((state) => state.onReloadHeader);
  const modalRef = useRef<{ open: () => void; close: () => void }>(null);
  const notificationId = toNumber(params?.id);
  const isDisabled = true;
  const [form] = Form.useForm();
  const [scheduleForm] = Form.useForm();

  const [notification, setNotification] = useState<NotificationProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [schools, setSchools] = useState<EnterpriseProps[]>([]);
  const [schoolsForSv, setSchoolForSv] = useState<SchoolProps[]>([]);

  const forQtcs = Form.useWatch("forQtcs", form);
  const forGv = Form.useWatch("forGv", form);
  const forSv = Form.useWatch("forSv", form);

  const schoolsOpt = [
    {
      label: "Tất cả cơ sở đào tạo",
      value: "ALL ENTERPRISES",
    },
  ].concat(
    schools?.map((school: any) => ({
      label: school?.name,
      value: school?.uuid,
    }))
  );

  const schoolsSvOpt = forSv?.find(
    (item: string) => item === "ALL" || item === "all"
  )
    ? [
      {
        label: "Tất cả sinh viên",
        value: "ALL",
      },
    ].concat(
      schoolsForSv?.map((school: any) => ({
        label: school?.name,
        value: school?.id,
      }))
    )
    : [
      {
        label: "Tất cả cơ sở đào tạo",
        value: "ALL ENTERPRISES",
      },
    ].concat(
      schoolsForSv?.map((school: any) => ({
        label: school?.name,
        value: school?.id,
      }))
    );

  useEffect(() => {
    const getEnterprise = async () => {
      const res = await getAllEnterprise({
        page: 1,
        size: 100,
      });
      setSchools(res.data.data?.content);
    };
    getEnterprise();
  }, []);

  useEffect(() => {
    const getHavingSchool = async () => {
      const res = await findHavingSchoolForStudent();
      setSchoolForSv(res.data.data);
    };
    getHavingSchool();
  }, []);

  useEffect(() => {
    if (notificationId) {
      const getNotificationDetail = async () => {
        try {
          setLoading(true);
          const res = await getNotification(notificationId);
          const notify: NotificationProps = res.data.data;
          setNotification(notify);
          form.setFieldsValue({
            systemChecked: notify?.isForQtht,
            isForQtcs: !isEmpty(notify?.forQtcs),
            isForGv: !isEmpty(notify?.forGv),
            isForSv: !isEmpty(notify?.forSv),
            forQtcs: notify?.forQtcs?.map((item: EnterpriseProps) => item.uuid),
            forGv: notify?.forGv?.map((item: EnterpriseProps) => item.uuid),
            forSv: notify?.forSv?.map((item: EnterpriseProps) => item.uuid),
            sendToAll: notify?.sendToAll,
          });
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
      getNotificationDetail();
    }
  }, [notificationId, refresh]);

  const breadcrumb = [
    {
      title: <Link to={routesConfig.notifications}>Danh sách thông báo</Link>,
    },
    {
      title: notification?.title,
    },
  ];

  const displayStatus = (status: number | string) => {
    switch (status) {
      case NotificationStatus.DRAFT:
        return (
          <Flex align="center">
            <span className="dot blue"></span>Nháp
          </Flex>
        );
      case NotificationStatus.SENT:
        return (
          <Flex align="center">
            <span className="dot green"></span> Đã gửi
          </Flex>
        );
      case NotificationStatus.RE_CALL:
        return (
          <Flex align="center">
            <span className="dot orange"></span>Thu hồi
          </Flex>
        );
      default:
        return "_";
    }
  };

  const deleteNotification = async () => {
    Modal.confirm({
      type: "warning",
      icon: <InfoCircleOutlined />,
      title: <span className="font-weight-5">Xác nhận xoá thông báo</span>,
      content: (
        <span>
          Thông báo đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá
          thông báo?
        </span>
      ),
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        if (notificationId) {
          try {
            setLoadingDelete(true);
            await deleteNotifications({
              ids: [notificationId],
            });
            message.success("Xoá thông báo thành công!");
            navigate(routesConfig.notifications);
            onReloadHeader();
          } catch (err) {
            console.error(err);
          } finally {
            setLoadingDelete(false);
          }
        }
      },
    });
  };

  const handleCancelSchedule = () => {
    Modal.confirm({
      type: "warning",
      icon: <InfoCircleOutlined />,
      title: (
        <span className="font-weight-5">Xác nhận huỷ thời gian hẹn gửi</span>
      ),
      content: (
        <span>
          Hệ thống sẽ huỷ thời gian hẹn gửi bạn đã cài đặt. Bạn có chắn chắc
          muốn huỷ thời gian hẹn gửi?
        </span>
      ),
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        if (notificationId) {
          try {
            await unScheduleNotification(notificationId);
            message.success("Huỷ lịch hẹn thành công!");
            setRefresh(!refresh);
          } catch (err) {
            console.error(err);
          }
        }
      },
    });
  };

  const handleSendNotify = (value?: any) => {
    Modal.confirm({
      type: "info",
      icon: <InfoCircleOutlined className="text-primary" />,
      title: <span className="font-weight-5">Xác nhận gửi thông báo</span>,
      content: (
        <span>
          Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhập
          đầy đủ trước khi gửi
        </span>
      ),
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        if (notificationId) {
          try {
            if (isEmpty(value?.scheduledSendDate)) {
              await sendNotification(notificationId);
              message.success("Gửi thông báo thành công!");
            } else {
              await scheduleSendNotification({
                id: notificationId,
                scheduledSendDate: new Date(
                  value?.scheduledSendDate
                ).toISOString(),
              });
              message.success("Hẹn giờ gửi thông báo thành công!");
              scheduleForm.resetFields();
              modalRef.current?.close();
            }
            setRefresh(!refresh);
          } catch (err) {
            console.error(err);
          }
        }
      },
    });
  };

  const handleRevokeNotification = () => {
    Modal.confirm({
      type: "info",
      icon: <InfoCircleOutlined />,
      title: (
        <span className="font-weight-5">Xác nhận thu hồi gửi thông báo</span>
      ),
      content: <span>Bạn có chắc chắn muốn thu hồi gửi thông báo?</span>,
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        if (notificationId) {
          try {
            await revokeNotification(notificationId);
            message.success("Thu hồi thông báo thành công!");
            setRefresh(!refresh);
          } catch (err) {
            console.error(err);
          }
        }
      },
    });
  };

  const displayDetailEnterprise = (uuids: string[], isSv?: boolean) => {
    if (isEmpty(uuids)) return "";

    if (isSv) {
      return uuids.map((uuid: string) => {
        const enterprise: SchoolProps | undefined = schoolsForSv?.find(
          (item: SchoolProps) => item.id === uuid
        );
        if (!isEmpty(enterprise)) return <p key={uuid}>{enterprise?.name}</p>;

        return "Tất cả các cở sở đào tạo";
      });
    }

    return uuids.map((uuid: string) => {
      const enterprise: EnterpriseProps | undefined = schools?.find(
        (item: EnterpriseProps) => item.uuid === uuid
      );
      if (!isEmpty(enterprise)) return <p key={uuid}>{enterprise?.name}</p>;

      return "Tất cả các cở sở đào tạo";
    });
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  const disabledDateTime = (current: Dayjs | null) => {
    const now = dayjs();
    if (current && current.isSame(now, "day")) {
      const disabledHours = Array.from({ length: 24 }, (_, i) => i).splice(0, now.hour());
      const disabledMinutes = current.hour() === now.hour() ? Array.from({ length: 60 }, (_, i) => i).splice(0, now.minute()) : [];
      const disabledSeconds = current.hour() === now.hour() && current.minute() === now.minute() ? Array.from({ length: 60 }, (_, i) => i).splice(0, now.second()) : [];
      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
        disabledSeconds: () => disabledSeconds,
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  const handleInitialNotification = async () => {
    if (notificationId) {
      try {
        setLoadingDelete(true);
        await reInit(notificationId);
        setRefresh(!refresh);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingDelete(false);
      }
    }
  };

  const displayActionByStatus = (status: number) => {
    switch (status) {
      case NotificationStatus.SENT:
        return (
          <Flex gap={8} onClick={handleRevokeNotification}>
            <Button danger>Thu hồi gửi thông báo</Button>
          </Flex>
        );
      case NotificationStatus.DRAFT:
        return (
          <Flex gap={8}>
            <Button
              icon={<EditOutlined />}
              onClick={() =>
                navigate(`${routesConfig.notifications}/${notificationId}/edit`)
              }
            >
              Chỉnh sửa
            </Button>
            <Button
              icon={<DeleteOutlined />}
              danger
              loading={loadingDelete}
              onClick={deleteNotification}
            >
              Xoá
            </Button>
            <CustomPopover
              title={false}
              rootClassName="notification-action"
              placement="bottom"
              content={
                <div style={{ width: 140 }}>
                  <Flex
                    gap={8}
                    className="action-item"
                    onClick={handleSendNotify}
                  >
                    <SendOutlined /> Gửi ngay
                  </Flex>
                  {notification?.scheduledSendDate ? (
                    <Flex
                      gap={8}
                      className="action-item text-danger"
                      onClick={handleCancelSchedule}
                    >
                      <StopOutlined /> Huỷ lịch hẹn
                    </Flex>
                  ) : (
                    <CustomModal
                      ref={modalRef}
                      title={
                        <p className="font-weight-5">Đặt lịch gửi thông báo</p>
                      }
                      closable
                      rootClassName="modal-schedule"
                      footer={false}
                      maskClosable={false}
                      content={
                        <Form
                          form={scheduleForm}
                          className="modal-schedule-content"
                          onFinish={handleSendNotify}
                        >
                          <div className="break-line"></div>
                          <label htmlFor="">
                            <span className="text-danger mr-1">*</span>
                            Thời gian gửi thông báo
                          </label>

                          <Form.Item
                            name="scheduledSendDate"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Vui lòng chọn thời gian gửi thông báo",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-full mt-1"
                              showTime
                              showNow={false}
                              placeholder="Chọn thời gian"
                              locale={viLocale}
                              disabledDate={disabledDate}
                              disabledTime={disabledDateTime}
                            />
                          </Form.Item>
                          <Flex justify="flex-end" gap={8} className="mt-5">
                            <Button onClick={() => modalRef.current?.close()}>
                              Huỷ
                            </Button>
                            <Button className="btn-primary" htmlType="submit">
                              Đặt lịch
                            </Button>
                          </Flex>
                        </Form>
                      }
                    >
                      <Flex gap={8} className="action-item">
                        <ClockCircleOutlined /> Đặt lịch
                      </Flex>
                    </CustomModal>
                  )}
                </div>
              }
            >
              <Button className="btn-primary">
                {notification?.scheduledSendDate
                  ? `Hẹn gửi lúc ${moment(
                    notification?.scheduledSendDate
                  ).format("HH:mm:ss - DD/MM/YYYY")}`
                  : "Gửi thông báo"}
                <DownOutlined />
              </Button>
            </CustomPopover>
          </Flex>
        );
      case NotificationStatus.RE_CALL:
        return (
          <Flex gap={8}>
            <Button
              loading={loadingDelete}
              onClick={handleInitialNotification}
              icon={<ReloadOutlined className="icon-revert" />}
              className="btn-primary"
            >
              Khởi tạo lại
            </Button>
          </Flex>
        );
      default:
        break;
    }
  };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="notifications-page w-full">
          <div className="notifications-page-header w-full">
            <Breadcrumb items={breadcrumb} className="mb-1" />
            <div className="flex justify-between align-center">
              <div className="flex gap-8 align-center">
                <ArrowLeftOutlined
                  className="arrowleft-icon"
                  onClick={() => navigate(routesConfig.notifications)}
                />
                <h3 className="page-title">{notification?.title}</h3>
              </div>
              {displayActionByStatus(notification?.status as number)}
            </div>
          </div>
          <Spin spinning={loading}>
            <Form form={form}>
              <div className="notifications-detail-content">
                <p className="content-title font-weight-5 text-20">
                  {notification?.title}
                </p>
                <p className="text-14 secondary-color">
                  Nội dung chi tiết thông báo
                </p>
                <div className="break-line mt-2 mb-2" />
                <Row>
                  <Col span={7}>
                    <p className="font-weight-5 text-14">Mã thông báo</p>
                  </Col>
                  <Col span={17}>
                    <p className="text-14">{notification?.id}</p>
                  </Col>
                </Row>
                <div className="break-line mt-2 mb-2" />
                <Row>
                  <Col span={7}>
                    <p className="font-weight-5 text-14">Trạng thái</p>
                  </Col>
                  <Col span={17}>
                    <div className="text-14 flex align-center">
                      {displayStatus(notification?.status as number)}
                    </div>
                  </Col>
                </Row>
                <div className="break-line mt-2 mb-2" />
                <Row>
                  <Col span={7}>
                    <p className="font-weight-5 text-14">
                      Đối tượng nhận thông báo
                    </p>
                  </Col>
                  <Col span={17}>
                    <Row>
                      <Col span={24} className="flex align-center gap-8">
                        <Form.Item name="sendToAll" valuePropName="checked">
                          <Switch disabled={isDisabled} className="mr-1" />
                        </Form.Item>
                        Toàn bộ người dùng trên hệ thống
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="flex align-center gap-8 mt-1">
                        <Form.Item name="systemChecked" valuePropName="checked">
                          <Switch disabled={isDisabled} className="mr-1" />
                        </Form.Item>
                        Quản trị hệ thống
                      </Col>
                    </Row>
                    <Row className="mt-1" align="middle">
                      <Col span={8} className="flex align-center gap-8">
                        <Form.Item name="isForQtcs" valuePropName="checked">
                          <Switch disabled={isDisabled} className="mr-1" />
                        </Form.Item>
                        Quản trị cơ sở
                      </Col>
                      <Col span={16}>
                        <Tooltip title={displayDetailEnterprise(forQtcs)}>
                          <Form.Item name="forQtcs">
                            <Select
                              options={schoolsOpt}
                              mode="multiple"
                              maxTagCount="responsive"
                              disabled={isDisabled}
                              className="w-full"
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row className="mt-1" align="middle">
                      <Col span={8} className="flex align-center gap-8">
                        <Form.Item name="isForGv" valuePropName="checked">
                          <Switch disabled={isDisabled} className="mr-1" />
                        </Form.Item>
                        Giảng viên
                      </Col>
                      <Col span={16}>
                        <Tooltip title={displayDetailEnterprise(forGv)}>
                          <Form.Item name="forGv">
                            <Select
                              options={schoolsOpt}
                              mode="multiple"
                              maxTagCount="responsive"
                              disabled={isDisabled}
                              className="w-full"
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row className="mt-1" align="middle">
                      <Col span={8} className="flex align-center gap-8">
                        <Form.Item name="isForSv" valuePropName="checked">
                          <Switch disabled={isDisabled} className="mr-1" />
                        </Form.Item>
                        Sinh viên
                      </Col>
                      <Col span={16}>
                        <Tooltip title={displayDetailEnterprise(forSv, true)}>
                          <Form.Item name="forSv">
                            <Select
                              options={schoolsSvOpt}
                              mode="multiple"
                              maxTagCount="responsive"
                              disabled={isDisabled}
                              className="w-full"
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="notifications-detail-content">
                {/* <p className="notification-title text-20 font-weight-5">
                  {notification?.message}
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${notification?.message || ""}`,
                  }}
                />
              </div>
            </Form>
          </Spin>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default NotificationDetail;
