import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  TabsProps,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import { FormInstance } from "antd/lib";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useWatch } from "antd/es/form/Form";
import {
  getConfigBlockQuizAction,
  getConfigBlockQuizEvaluate,
  getConfigBlockQuizGeneral,
  getEnterpriseByCourseId,
  getMoocScoringRules,
  postConfigBlockQuizAction,
  postConfigBlockQuizEvaluate,
  postConfigBlockQuizGeneral
} from "../../../../service/course-construct/settingLessonCourse";
import "./index.scss";
import { useAddLesson } from "../../../../stores/stores";

var indexDB = require("idbcache").default;

interface DataType {
  key: string;
  numberPoint: string;
  letterPoint: string;
  classification: string;
}

interface SettingLessonCourseProps {
  form: FormInstance;
  courseId?: string;
  setSettingLessonModal: (value: boolean) => void;
  generalSettingValue?: any
  actionSettingValue?: any
  evaluateSettingValue?: any
}

export default function SettingLessonCourse({ courseId, generalSettingValue, actionSettingValue, evaluateSettingValue, form, setSettingLessonModal }: SettingLessonCourseProps) {
  const [keyActive, setKeyActive] = useState<string>("1");
  const [resetModal, setResetModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rules, setRules] = useState<any>([]);
  const [courseBlockId, setCourseBlockId] = useState<number>(0);
  const { settingPopupQuestion, setSettingPopupQuestion, updateTimeToPlayFromOutside, setDefaultTimeToPlay } = useAddLesson(state => ({
    settingPopupQuestion: state.settingPopupQuestion,
    setSettingPopupQuestion: state.setSettingPopupQuestion,
    updateTimeToPlayFromOutside: state.updateTimeToPlayFromOutside,
    setDefaultTimeToPlay: state.setDefaultTimeToPlay,
  }));

  // Display checkbox general setting
  const checkDefaultQuestion = useWatch("defaultQuestion", form);
  const checkShowFeedback = useWatch("showFeedback", form);
  const checkFormatQuiz = useWatch("formatQuiz", form);
  const checkResultOfEvaluationCheckbox = useWatch("showResultOfEvaluation", form);
  const checkFeedbackContentCheckbox = useWatch("showFeedbackContent", form);
  const checkCorrectAnswerCheckbox = useWatch("showCorrectAnswer", form);

  // Display checkbox action setting
  const checkNumberActionEvaluateCheckbox = useWatch("numberActionEvaluateCheckbox", form);
  const checkTimeBetweenExecutionsCheckbox = useWatch("timeBetweenExecutionsCheckbox", form);
  const checkCompletionTimeCheckbox = useWatch("completionTimeCheckbox", form);
  const checkExecutionTimeExpiredCheckbox = useWatch("executionTimeExpired", form);
  const checkExecutionTimeExpiredOptionCheckbox = useWatch("executionTimeExpiredOption", form) === "1";
  const checkTimeForQuizCheckbox = useWatch("timeForQuizCheckbox", form);

  // Display switch evaluate setting
  const checkAutomaticScoringSwitch = useWatch("automaticScoring", form);
  const checkResultsCompletionSwitch = useWatch("showResultsCompletion", form);
  const checkrecordResultsSwitch = useWatch("recordResultsSwitch", form);
  const checkTypeScore = useWatch("typeScore", form);

  useEffect(() => {
    form.setFieldsValue({
      formatQuiz: checkDefaultQuestion ? 2 : (checkFormatQuiz ? checkFormatQuiz : 0),
    });
    indexDB.get("courseBlockId").then((val: any) => {
      if (val) {
        setCourseBlockId(JSON.parse(val));
      }
    });
  }, [checkDefaultQuestion])

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Thang điểm số",
      dataIndex: "numberPoint",
      key: "numberPoint",
    },
    {
      title: "Thang điểm chữ",
      dataIndex: "letterPoint",
      key: "letterPoint",
    },

    {
      title: "Xếp loại",
      dataIndex: "classification",
      key: "classification",
    },
  ];

  const feedBackOptions = [
    { id: "0", name: "Sau khi trả lời câu hỏi" },
    { id: "1", name: "Sau khi hoàn thành đánh giá" },
  ];

  const resultCheckboxOptions = [
    { id: "0", name: "Tất cả câu hỏi" },
    { id: "1", name: "Chỉ câu hỏi đã trả lời" },
    { id: "2", name: "Chỉ câu hỏi trả lời đúng" },
    { id: "3", name: "Chỉ câu hỏi trả lời sai" },
  ];

  const pointScaleOptions = [
    { id: 0, name: "Thang điểm số" },
    { id: 1, name: "Thang điểm chữ" },
    { id: 2, name: "Thang điểm xếp loại" },
  ];

  const minNumberPoint = [
    { id: 0, name: "0.5" },
    { id: 1, name: "1" },
    { id: 2, name: "1.5" },
    { id: 3, name: "2" },
    { id: 4, name: "2.5" },
    { id: 5, name: "3" },
    { id: 6, name: "3.5" },
    { id: 7, name: "4" },
    { id: 8, name: "4.5" },
    { id: 9, name: "5" },
    { id: 10, name: "5.5" },
    { id: 11, name: "6" },
    { id: 12, name: "6.5" },
    { id: 13, name: "7" },
    { id: 14, name: "7.5" },
    { id: 15, name: "8" },
    { id: 16, name: "8.5" },
    { id: 17, name: "9" },
    { id: 18, name: "9.5" },
    { id: 19, name: "10" },
  ];

  const minTextPoint = [
    { id: 0, name: "F" },
    { id: 1, name: "D" },
    { id: 2, name: "D+" },
    { id: 3, name: "C" },
    { id: 4, name: "C+" },
    { id: 5, name: "B" },
    { id: 6, name: "B+" },
    { id: 7, name: "A" },
  ];

  const minClassify = [
    { id: 0, name: "Kém (Không đạt)" },
    { id: 1, name: "Yếu" },
    { id: 2, name: "Trung bình yếu" },
    { id: 3, name: "Trung bình" },
    { id: 4, name: "Trung bình khá" },
    { id: 5, name: "Khá" },
    { id: 6, name: "Khá giỏi" },
    { id: 7, name: "Giỏi" },
  ];

  const data: DataType[] = rules.map((item: any) => (
    {
      key: item.id.toString(),
      numberPoint: `${item.scoreNumberMin} - ${item.scoreNumberMax}`,
      letterPoint: item.scoreText,
      classification: item.classify
    }));

  // Get rules by enterprise
  const getEnterprise = async (courseId: string) => {
    try {
      const { data } = await getEnterpriseByCourseId(courseId);
      if (data) {
        getScoringRule(data.data);
      }
    } catch (err) { }
  }

  const getScoringRule = async (enterpriseUuid: string) => {
    try {
      const { data } = await getMoocScoringRules(enterpriseUuid);
      if (data) {
        setRules(data.data)
      }
    } catch (err) { }
  }

  // Rule Modal


  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  // Setting Modal
  const handleCancelSetting = () => {
    setSettingLessonModal(false);
    setResetModal(!resetModal);
  }

  // Tabs
  const onTabClick = (key: string) => {
    setKeyActive(key);
  }

  // Call api setting
  const postGeneralSetting = async (values: any) => {
    try {
      await postConfigBlockQuizGeneral(values).then((res) => {
        if (res.status === 200) {
          message.success("Lưu cài đặt thành công!");
          setSettingLessonModal(false);
          setResetModal(!resetModal);
        }
      })
    } catch (err) { }
  }

  const postBlockQuizAction = async (values: any) => {
    try {
      await postConfigBlockQuizAction(values).then((res) => {
        if (res.status === 200) {
          message.success("Lưu cài đặt thành công!");
          setSettingLessonModal(false);
        }
        setResetModal(!resetModal);

        // Cập nhật timeToPlay và defaultTimeToPlay trong store
        if (values.timeForQuiz && checkTimeForQuizCheckbox) {
          updateTimeToPlayFromOutside(values.timeForQuiz);
          setDefaultTimeToPlay(values.timeForQuiz);
        }
      })
    } catch (err) { }
  }

  const postBlockQuizEvaluate = async (values: any) => {
    try {
      await postConfigBlockQuizEvaluate(values).then((res) => {
        if (res.status === 200) {
          message.success("Lưu cài đặt thành công!");
          setSettingLessonModal(false);
        }
        setResetModal(!resetModal);
      })
    } catch (err) { }
  }

  // onFinish setting
  const onFinishSetting = (values: any) => {
    if (keyActive) {
      if (keyActive === "1") {
        const dataSetting = {
          courseBlockId: courseBlockId,
          randomQuiz: values.randomQuestion,
          sequentiallyQuiz: values.defaultQuestion,
          showQuizScore: values.showMaxPoint,
          showHintQuiz: values.showHintQuiz,
          showFeedback: values.showFeedback,
          showResultOfEvaluation: values.showFeedback ? values.showResultOfEvaluation : false,
          resultOfEvaluationOption: (values.showFeedback && values.showResultOfEvaluation) ? values.resultOfEvaluationOption : null,
          showFeedbackContent: values.showFeedback ? values.showFeedbackContent : false,
          feedbackContentOption: (values.showFeedback && values.showFeedbackContent) ? values.feedbackContentOption : null,
          showCorrectAnswer: values.showFeedback ? values.showCorrectAnswer : false,
          correctAnswerOption: (values.showFeedback && values.showCorrectAnswer) ? values.correctAnswerOption : null,
          formatQuiz: values.formatQuiz,
        }
        postGeneralSetting(dataSetting);
      } else if (keyActive === "2") {
        const dataSetting = {
          courseBlockId: courseBlockId,
          numberActionEvaluate: (values.numberActionEvaluate && checkNumberActionEvaluateCheckbox) ? values.numberActionEvaluate : null,
          timeBetweenExecutions: (values.timeBetweenExecutions && checkTimeBetweenExecutionsCheckbox) ? values.timeBetweenExecutions : null,
          completionTime: (values.completionTime && checkCompletionTimeCheckbox) ? values.completionTime : null,
          executionTimeExpired: (checkCompletionTimeCheckbox) ? values.executionTimeExpired : null,
          executionTimeExpiredOption: (checkCompletionTimeCheckbox && checkExecutionTimeExpiredCheckbox) ? values.executionTimeExpiredOption : null,
          executionTimeExpiredValue: (values.executionTimeExpiredValue && checkCompletionTimeCheckbox && checkExecutionTimeExpiredCheckbox && checkExecutionTimeExpiredOptionCheckbox) ? values.executionTimeExpiredValue : null,
          timeForQuiz: (values.timeForQuiz && checkTimeForQuizCheckbox && values.defaultQuestion) ? values.timeForQuiz : null,
        }
        postBlockQuizAction(dataSetting);
      } else {
        const dataSetting = {
          courseBlockId: courseBlockId,
          automaticScoring: values.automaticScoring,
          typeScore: Number(values.typeScore),
          minimumScore: Number(values.minimumScore),
          scoreFactor: values.scoreFactor ? values.scoreFactor : null,
          recordResults: checkrecordResultsSwitch ? Number(values.recordResults) : null,
          showResultsCompletion: values.automaticScoring ? values.showResultsCompletion : false,
          showResultsCompletionOption: (values.showResultsCompletionOption && checkAutomaticScoringSwitch && checkResultsCompletionSwitch) ? values.showResultsCompletionOption : null,
        }
        postBlockQuizEvaluate(dataSetting);
      }
    }
  }

  // items tab
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: "Cài đặt chung",
      children: (
        <Form layout="horizontal" className="form-general-setting" form={form} onFinish={onFinishSetting}>
          <Typography.Title level={5} className="title-label">
            Thiết lập hiển thị
          </Typography.Title>

          <Row gutter={[4,4]} className="row-form-setting">
            <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Ngẫu nhiên hóa thứ tự câu hỏi
              </Typography.Text>
              <Form.Item name="randomQuestion" valuePropName="checked">
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Trả lời tuần tự câu hỏi
              </Typography.Text>
              <Form.Item name="defaultQuestion" valuePropName="checked">
                <Switch defaultChecked onChange={(value) => {
                  setSettingPopupQuestion({
                    defaultQuestion: value,
                  })
                }} />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Hiển thị điểm tối đa mỗi câu hỏi
              </Typography.Text>
              <Form.Item name="showMaxPoint" valuePropName="checked">
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Hiển thị hướng dẫn trả lời câu hỏi
              </Typography.Text>
              <Form.Item name="showHintQuiz" valuePropName="checked">
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Hiển thị phản hồi
              </Typography.Text>
              <Form.Item name="showFeedback" valuePropName="checked">
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-setting">
              <Form.Item name="showResultOfEvaluation" valuePropName="checked">
                <Checkbox disabled={!checkShowFeedback}>
                  <Typography.Text className="text-label">
                    Hiển thị kết quả đánh giá
                  </Typography.Text>
                </Checkbox>
              </Form.Item>

              <Space className="display-dropdown">
                <Form.Item name="resultOfEvaluationOption">
                  <FormItemDropdown disabled={!checkShowFeedback || !checkResultOfEvaluationCheckbox}
                    options={resultCheckboxOptions} />
                </Form.Item>
              </Space>
            </Col>

            <Col span={24} className="col-form-setting">
              <Form.Item name="showFeedbackContent" valuePropName="checked">
                <Checkbox disabled={!checkShowFeedback}>
                  <Typography.Text className="text-label">
                    Hiển thị phản hồi
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
              <Space className="display-dropdown">
                <Form.Item name="feedbackContentOption">
                  <FormItemDropdown disabled={!checkShowFeedback || !checkFeedbackContentCheckbox}
                    options={resultCheckboxOptions} />
                </Form.Item>
              </Space>
            </Col>

            <Col span={24} className="col-form-setting">
              <Form.Item name="showCorrectAnswer" valuePropName="checked">
                <Checkbox disabled={!checkShowFeedback}>
                  <Typography.Text className="text-label">
                    Hiển thị đáp án đúng
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
              <Space className="display-dropdown">
                <Form.Item name="correctAnswerOption">
                  <FormItemDropdown disabled={!checkShowFeedback || !checkCorrectAnswerCheckbox}
                    options={resultCheckboxOptions} />
                </Form.Item>
              </Space>
            </Col>


            <Col span={24} className="col-form-checkbox">
              <Typography.Title level={5} className="title-label">
                Thiết lập bố cục
              </Typography.Title>
              <Form.Item name="formatQuiz">
                <Radio.Group className="wrapper-radio">
                  <Space direction="vertical">
                    <Radio value={0} disabled={checkDefaultQuestion}>Cơ bản</Radio>
                    <Typography.Text className="des-label">
                      Hiển thị tất cả câu hỏi trên một trang
                    </Typography.Text>
                    <Radio value={1} disabled={checkDefaultQuestion}>Theo từng phần</Radio>
                    <Typography.Text className="des-label">
                      Hiển thị mỗi trang một phần
                    </Typography.Text>
                    <Radio value={2}>Theo từng câu hỏi</Radio>
                    <Typography.Text className="des-label">
                      Hiển thị mỗi trang một câu hỏi
                    </Typography.Text>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24} className="btn-submit-form">
              <Button className="btn btn-default" onClick={handleCancelSetting}>
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" onClick={() => form.submit()}>
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      key: "2",
      label: "Cài đặt thực hiện",
      children: (
        <Form layout="horizontal" className="form-action-setting" form={form} onFinish={onFinishSetting}>
          <Typography.Title level={5} className="title-label">
            Giới hạn thực hiện đánh giá
          </Typography.Title>

          <Row gutter={[4,4]} className="row-form-setting">
            <Col span={24} className="col-form-setting">
              <Form.Item name="numberActionEvaluateCheckbox" valuePropName="checked">
                <Checkbox>
                  <Typography.Text className="text-label">
                    Giới hạn lượt thực hiện đánh giá
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item name="numberActionEvaluate">
                <InputNumber
                  disabled={!checkNumberActionEvaluateCheckbox}
                  min={1} max={500} addonAfter={"lần"}
                  formatter={value => `${value}`.replace('.', '')}
                />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-setting">
              <Form.Item name="timeBetweenExecutionsCheckbox" valuePropName="checked">
                <Checkbox>
                  <Typography.Text className="text-label">
                    Thời gian chờ thực hiện giữa các lượt thực hiện
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item name="timeBetweenExecutions">
                <InputNumber
                  disabled={!checkTimeBetweenExecutionsCheckbox}
                  min={1} max={500} addonAfter={"phút"}
                  formatter={value => `${value}`.replace('.', '')}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="col-form-setting">
              <Form.Item name="completionTimeCheckbox" valuePropName="checked">
                <Checkbox>
                  <Typography.Text className="text-label">
                    Giới hạn thời gian hoàn thành đánh giá
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item name="completionTime">
                <InputNumber
                  disabled={!checkCompletionTimeCheckbox}
                  min={1} max={500} addonAfter={"phút"}
                  formatter={value => `${value}`.replace('.', '')}
                />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-radio">
              <Form.Item name="executionTimeExpired" valuePropName="checked">
                <Checkbox disabled={!checkCompletionTimeCheckbox}>
                  <Typography.Text className="text-label">
                    Cài đặt hết thời gian thực hiện
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item className="group-radio" name="executionTimeExpiredOption">
                <Radio.Group disabled={!checkExecutionTimeExpiredCheckbox || !checkCompletionTimeCheckbox}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Radio className="wrapper-radio" value={"0"}>Tự động gửi đánh giá</Radio>
                    </Col>
                    <Col span={24} className="time-extension">
                      <Radio className="wrapper-radio" value={"1"}>Gia hạn thời gian</Radio>
                      <Form.Item name="executionTimeExpiredValue">
                        <InputNumber
                          disabled={!(checkExecutionTimeExpiredCheckbox && checkExecutionTimeExpiredOptionCheckbox) || !checkCompletionTimeCheckbox}
                          min={1} max={500} addonAfter={"phút"} formatter={value => `${value}`.replace('.', '')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Radio className="wrapper-radio" value={"2"}>Hủy bài đánh giá</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Typography.Title level={5} className="title-label">
              Giới hạn trả lời câu hỏi{" "}
            </Typography.Title>

            <Col span={24} className="col-form-setting">
              <Form.Item name="timeForQuizCheckbox" valuePropName="checked">
                <Checkbox disabled={!checkDefaultQuestion}>
                  <Typography.Text className="text-label">
                    Thiết lập thời gian thực hiện mặc định
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item name="timeForQuiz">
                <InputNumber
                  disabled={!checkTimeForQuizCheckbox || !checkDefaultQuestion}
                  min={1} max={500} addonAfter={"giây"}
                  formatter={value => `${value}`.replace('.', '')}
                />
              </Form.Item>
            </Col>

            <Col className="btn-submit-form">
              <Button
                onClick={handleCancelSetting}
                className="btn btn-default"
              >
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                onClick={() => form.submit()}
              >
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      key: "3",
      label: "Cài đặt đánh giá",
      children: (
        <Form layout="horizontal"
          className="form-evaluate-setting"
          form={form}
          onFinish={onFinishSetting}>
          <Typography.Title level={5} className="title-label">
            Cài đặt chấm điểm
          </Typography.Title>

          <Row gutter={[4,4]} className="row-form-setting">
            <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Bật chế độ chấm điểm tự động
              </Typography.Text>
              <Form.Item name="automaticScoring" hasFeedback>
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            {/* <Col span={24} className="col-form-setting">
              <Space>
                <Typography.Text className="text-label">
                  Thang điểm
                </Typography.Text>
                <QuestionCircleOutlined style={{ color: "#1677FF" }} />
                <Typography.Text className="text-label" style={{ color: "#1677FF", cursor: "pointer" }}
                  onClick={showModal}>
                  Quy tắc
                </Typography.Text>
              </Space>
              <Space className="display-dropdown">
                <Form.Item name="typeScore">
                  <FormItemDropdown options={pointScaleOptions} />
                </Form.Item>
              </Space>
            </Col> */}

            {/* <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Kết quả đạt tối thiểu
              </Typography.Text>
              <Space className="display-dropdown">
                <Form.Item name="minimumScore">
                  <FormItemDropdown
                    options={(checkTypeScore && checkTypeScore === "0" ? minNumberPoint : (checkTypeScore && checkTypeScore === 1 ? minTextPoint : minClassify))}
                  />
                </Form.Item>
              </Space>
            </Col> */}

            {/* <Col span={24} className="col-form-setting">
              <Typography.Text className="text-label">
                Hệ số điểm
              </Typography.Text>
              <Space className="display-dropdown">
                <Form.Item name="scoreFactor">
                  <InputNumber min={1} max={500} formatter={value => `${value}`.replace('.', '')} />
                </Form.Item>
              </Space>
            </Col> */}

            <Col span={24} className="col-form-setting col-radio">
              <Typography.Text className="text-label">
                Bật ghi nhận kết quả vào thành tích
              </Typography.Text>
              <Form.Item name="recordResultsSwitch" valuePropName="checked">
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            <Col span={24} className="col-form-setting">
              <Form.Item name="recordResults" className="ml-6">
                <Radio.Group className="wrapper-radio" disabled={!checkrecordResultsSwitch}>
                  <Space direction="vertical">
                    <Radio value={"0"}>Ghi nhận kết quả đầu tiên</Radio>
                    <Radio value={"1"}>Ghi nhận kết quả cuối cùng</Radio>
                    <Radio value={"2"}>Ghi nhận kết quả cao nhất</Radio>
                    <Radio value={"3"}>Ghi nhận kết quả trung bình</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}
              className={`col-form-setting col-checkbox ${!checkAutomaticScoringSwitch ? "col-hidden" : ""}`}>
              <Typography.Text className="text-label">
                Bật hiển thị kết quả chấm điểm tự động sau khi hoàn thành
              </Typography.Text>
              <Form.Item name="showResultsCompletion" valuePropName="checked">
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            <Col span={24} className={`col-form-setting ${!checkAutomaticScoringSwitch ? "col-hidden" : ""}`}>
              <Form.Item className="ml-6" name="showResultsCompletionOption">
                <Checkbox.Group style={{ width: "100%" }} disabled={!checkResultsCompletionSwitch}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Checkbox value="0">
                        Hiển thị tổng số câu trả lời đúng
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="1">Hiển thị điểm tổng kết</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="2">
                        Hiển thị số câu trả lời đúng theo từng phần
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="3">
                        Hiển thị điểm theo từng phần
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="4">
                        Hiển thị thời gian thực hiện
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="5">Hiển thị thời gian hoàn tất</Checkbox>
                    </Col>
                    {/* <Col span={24}>
                      <Checkbox value="6">
                        Hiển thị nút Xem chi tiết đáp án
                      </Checkbox>
                    </Col> */}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>

            <Col span={24} className="btn-submit-form">
              <Button
                onClick={handleCancelSetting}
                className="btn btn-default"
              >
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                onClick={() => form.submit()}
              >
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  useEffect(() => {
    if (courseId) {
      getEnterprise(courseId);
    }
  }, [courseId])

  useEffect(() => {
    if (generalSettingValue) {
      // set general setting value
      form.setFieldsValue({
        randomQuestion: generalSettingValue.randomQuiz ? generalSettingValue.randomQuiz : false,
        defaultQuestion: generalSettingValue.sequentiallyQuiz ? generalSettingValue.sequentiallyQuiz : false,
        showMaxPoint: generalSettingValue.showQuizScore ? generalSettingValue.showQuizScore : false,
        showHintQuiz: generalSettingValue.showHintQuiz ? generalSettingValue.showHintQuiz : false,
        showFeedback: generalSettingValue.showFeedback ? generalSettingValue.showFeedback : false,
        showResultOfEvaluation: generalSettingValue.showResultOfEvaluation ? generalSettingValue.showResultOfEvaluation : false,
        resultOfEvaluationOption: generalSettingValue.resultOfEvaluationOption ? generalSettingValue.resultOfEvaluationOption : null,
        showFeedbackContent: generalSettingValue.showFeedbackContent ? generalSettingValue.showFeedbackContent : false,
        feedbackContentOption: generalSettingValue.feedbackContentOption ? generalSettingValue.feedbackContentOption : null,
        showCorrectAnswer: generalSettingValue.showCorrectAnswer ? generalSettingValue.showCorrectAnswer : false,
        correctAnswerOption: generalSettingValue.correctAnswerOption ? generalSettingValue.correctAnswerOption : null,
        formatQuiz: generalSettingValue.formatQuiz ? Number(generalSettingValue.formatQuiz) : 1,
      });
    } else {
      // set default general setting value
      form.setFieldsValue({
        randomQuestion: false,
        defaultQuestion: false,
        showMaxPoint: false,
        showHintQuiz: false,
        showFeedback: false,
        showResultOfEvaluation: false,
        resultOfEvaluationOption: "0",
        showFeedbackContent: false,
        feedbackContentOption: "0",
        showCorrectAnswer: false,
        correctAnswerOption: "0",
        formatQuiz: 1,
      });
    }

    if (actionSettingValue) {
      // set action setting value
      form.setFieldsValue({
        numberActionEvaluateCheckbox: !!actionSettingValue.numberActionEvaluate,
        numberActionEvaluate: actionSettingValue.numberActionEvaluate ? actionSettingValue.numberActionEvaluate : null,
        timeBetweenExecutionsCheckbox: !!actionSettingValue.timeBetweenExecutions,
        timeBetweenExecutions: actionSettingValue.timeBetweenExecutions ? actionSettingValue.timeBetweenExecutions : null,
        completionTimeCheckbox: !!actionSettingValue.completionTime,
        completionTime: actionSettingValue.completionTime ? actionSettingValue.completionTime : null,
        executionTimeExpired: actionSettingValue.executionTimeExpired ? actionSettingValue.executionTimeExpired : false,
        executionTimeExpiredOption: actionSettingValue.executionTimeExpiredOption ? actionSettingValue.executionTimeExpiredOption : "0",
        executionTimeExpiredValue: actionSettingValue.executionTimeExpiredValue ? actionSettingValue.executionTimeExpiredValue : null,
        timeForQuizCheckbox: actionSettingValue.timeForQuiz ? true : false,
        timeForQuiz: actionSettingValue.timeForQuiz ? actionSettingValue.timeForQuiz : null,
      })
    } else {
      // set default action setting value
      form.setFieldsValue({
        numberActionEvaluateCheckbox: null,
        numberActionEvaluate: null,
        timeBetweenExecutionsCheckbox: null,
        timeBetweenExecutions: null,
        completionTimeCheckbox: null,
        completionTime: null,
        executionTimeExpired: null,
        executionTimeExpiredOption: "0",
        executionTimeExpiredValue: null,
        timeForQuizCheckbox: null,
        timeForQuiz: null,
      })
    }

    if (evaluateSettingValue) {
      // set evaluate setting value
      form.setFieldsValue({
        automaticScoring: evaluateSettingValue.automaticScoring ? evaluateSettingValue.automaticScoring : false,
        typeScore: evaluateSettingValue.typeScore ? evaluateSettingValue.typeScore : "0",
        minimumScore: evaluateSettingValue.minimumScore ? evaluateSettingValue.minimumScore : "0",
        scoreFactor: evaluateSettingValue.scoreFactor ? evaluateSettingValue.scoreFactor : null,
        recordResultsSwitch: !!evaluateSettingValue.recordResults,
        recordResults: evaluateSettingValue.recordResults ? evaluateSettingValue.recordResults : "0",
        showResultsCompletion: evaluateSettingValue.automaticScoring ? evaluateSettingValue.showResultsCompletion : false,
        showResultsCompletionOption: (evaluateSettingValue.automaticScoring && evaluateSettingValue.showResultsCompletionOptions) ? evaluateSettingValue.showResultsCompletionOptions : null,
      })
    } else {
      // set default evaluate setting value
      form.setFieldsValue({
        automaticScoring: false,
        typeScore: "0",
        minimumScore: null,
        scoreFactor: null,
        recordResultsSwitch: false,
        recordResults: "0",
        showResultsCompletion: false,
        showResultsCompletionOption: null,
      })
    }
  }, [courseBlockId]);

  return (
    <>
      <div className="display-setting-content">
        <Row>
          <Col className="col-menu-setting">
            <Tabs defaultActiveKey="1" tabPosition={"left"} items={itemsTab}
              onTabClick={onTabClick} />
          </Col>
        </Row>
      </div>
      <Modal
        title="Quy tắc"
        open={isModalOpen}
        width={920}
        className="rule-modal"
        onCancel={handleCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </Modal>
    </>
  );
}
