import { AxiosResponse } from "axios"
import { axiosConfigV2 } from "../../../config/api/configApiv2"

export const getMoocUniversityInfoById: (id: number, type: string) => Promise<AxiosResponse<any>> = (id, type) => {
    return axiosConfigV2.get(`/university-information-portfolio/${id}?type=${type}`)
}
export const getAllUniversityInfoByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/university-information-portfolio/get-all-by-filter`, data)
}

export const addMoocUniversityInfo: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/university-information-portfolio`, data)
}

export const updateMoocUniversityInfo: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/university-information-portfolio`, data)
}

export const deleteMoocUniversityInfo: (id: number, type: string) => Promise<AxiosResponse<any>> = (id, type) => {
    return axiosConfigV2.delete(`/university-information-portfolio/${id}?type=${type}`)
}