// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-exam-list .table-header--left .ant-breadcrumb {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/library/bank-exam/components/bank-exam-list/bank-exam-list.scss"],"names":[],"mappings":"AAAA;EAGM,WAAW,EAAA","sourcesContent":[".bank-exam-list {\n  .table-header--left {\n    .ant-breadcrumb {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
